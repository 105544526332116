const CreatePickupIcon = ({type="cpick", size='regular', black = false}) => {
    const renderThis = () => {
        switch(size) {
            case "small":
                return (
                    <span className="fa-stack" style={{fontSize: "8px"}}>
                        <i className="fa-solid fa-up-right-from-square fa-stack-2x" style={{color: color(type)}}></i>
                        <i className={`fa-solid fa-circle-plus fa-stack-1x fa-inverse`} style={{top: '-15px', left: '-15px', color: color(type), fontSize: '15px'}}></i>
                    </span>
                )
            default:
                return (
                    <span className="fa-stack" style={{fontSize: "9px"}}>
                        <i className="fa-solid fa-up-right-from-square fa-stack-2x" style={{color: color(type)}}></i>
                        <i className={`fa-solid fa-circle-plus fa-stack-1x fa-inverse`} style={{top: '-15px', left: '-15px', color: color(type), fontSize: '15px'}}></i>
                    </span>
                )
        }
    }

    const color = (type) => {
        switch(type) {
            case "scoll": return black ? "#000000" : "#333333"
            default: return black ? "#000000" : "#333333"
        }
    }

    return renderThis()
}

export default CreatePickupIcon
