import useSWR from 'swr'
import axios from '../lib/axios'
import { useEffect } from 'react'
import { t } from 'i18next'
import Swal from 'sweetalert2'
import { DEFAULT_ROUTES } from '../constants/constants'
import { isNil } from 'lodash'

export const useAuth = ({ middleware, redirectIfAuthenticated } = {}) => {

    const { data: user, error, mutate, isLoading } = useSWR('/api/user', () =>
        axios
            .get('/api/user')
            .then((res) => {
                return res.data
            })
            .catch(error => {
                if (error.response.status !== 409) {
                    throw error
                }

                window.location.replace('/verify-email')
            }),


    )

    const csrf = () => axios.get('/sanctum/csrf-cookie')



    const login = async ({ setErrors, setStatus, ...props }) => {
        await csrf()

        setErrors([])
        setStatus(null)

        axios
            .post('/login', props)
            .then(() => {
                localStorage.removeItem('impersonate');
                localStorage.removeItem('impersonatee_id');
                localStorage.removeItem('impersonatee_role');
                localStorage.removeItem('impersonator_id');
                mutate()
            })
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(error.response.data.errors)
            })
    }

    const switchUser = async ({ setErrors, setStatus, ...props }) => {
        await csrf()

        setErrors([]);
        setStatus(null);

        return axios
          .post(`/switch-user/${props.counterpartId}`, props)
          .then(() => {
            localStorage.removeItem('filters')

            mutate()

            window.location.pathname = DEFAULT_ROUTES[props.counterpartRole]
        })
          .catch((error) => {
            if (error.response.status !== 422) throw error;

            setErrors(error.response.data.errors);
          });
    }

    const forgotPassword = async ({ setErrors, setStatus, email }) => {
        await csrf()

        setErrors([])
        setStatus(null)

        axios
            .post('/forgot-password', { email })
            .then(response => setStatus(response.data.status))
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(error.response.data.errors)
            })
    }

    const resetPassword = async ({
        setErrors,
        setStatus,
        token,
        email,
        password,
        password_confirmation
    }) => {
        await csrf()

        setErrors([])
        setStatus(null)

        axios
            .post('/reset-password', { token, email, password, password_confirmation })
            .then(response => {
                setStatus(response.data.status)
            })
            .catch(({ response }) => {
                if (response.status !== 422) {
                    throw error
                }

                setErrors(response.data.errors)
            })
    }

    const loginImpersonate = async ({ setErrors, setStatus, ...props }) => {
        await csrf();

        setErrors([]);
        setStatus(null);

        return axios
          .get(`/login-impersonate/${props.id}`, props)
          .then(() => {
            localStorage.setItem('impersonate', true)
            localStorage.removeItem('showDeimpersonationMessage')
            localStorage.setItem('showImpersonationMessage', true)
            localStorage.setItem('impersonatee_name', props.impersonatee_name)
            localStorage.setItem('impersonatee_id', props.id)
            localStorage.setItem('impersonatee_role', props.role)
            localStorage.setItem('impersonator_id', props.impersonator_id)
            localStorage.setItem("helper_site", props.site_id)
            localStorage.setItem("helper_company", props.company_id)

            sessionStorage.setItem("imp_uid", props.id)
            sessionStorage.setItem("imp_name", props.impersonatee_name)
            sessionStorage.setItem("imp_sid", props.impersonatee_site_id)
            sessionStorage.setItem("imp_cid", props.impersonatee_company_id)

            localStorage.setItem("impersonatorFilters", localStorage.getItem('filters') ?? [{}])
            localStorage.removeItem('filters')

            mutate()
            if(
                props.role === 'service_provider'
                || props.role === 'tpl_driver'
            ) {
                window.location.pathname = redirectIfAuthenticated
            } else {
                window.location.pathname = DEFAULT_ROUTES[props.role]
            }
          })
          .catch((error) => {
            if (error.response.status !== 422) throw error;

            setErrors(error.response.data.errors);
          });
    }

    const resendEmailVerification = ({ setStatus }) => {
        axios
            .post('/email/verification-notification')
            .then(response => setStatus(response.data.status))
    }

    const logout = async (isExternal = false, role = null) => {
        if (!error) {
            await axios.post('/logout').then(() => {
                localStorage.clear();
                mutate()
            })
        }

        if (isExternal) {
            switch(role) {
                case "tpl_driver":
                    window.location.pathname = '/3pl'
                    break
                case "service_provider":
                    window.location.pathname = '/broker-sales'
                    break
                default:
                    window.location.pathname = '/login'
            }
        } else {
            window.location.pathname = '/login'
        }
    }

    const logoutImpersonate = async ({ setErrors, setStatus, user = null, ...props }) => {
        await csrf();

        setErrors([])
        setStatus(null)

        await axios
          .get("/logout-impersonate", props)
          .then(() => {
            localStorage.removeItem('impersonate');
            localStorage.removeItem('showImpersonationMessage')
            localStorage.removeItem('impersonatee_id');
            localStorage.removeItem('impersonatee_role');
            localStorage.removeItem('impersonator_id');
            localStorage.setItem('showDeimpersonationMessage', true)
            localStorage.setItem("site", localStorage.getItem("helper_site"));
            localStorage.setItem("company_id", localStorage.getItem("helper_company"));
            localStorage.removeItem("helper_site");
            localStorage.removeItem("helper_company");
            localStorage.setItem('filters', localStorage.getItem('impersonatorFilters') ?? [{}])
            localStorage.removeItem('impersonatorFilters')
            sessionStorage.clear()

            mutate();
            if(user) {
                switch(user.role) {
                    case "customers_user":
                        window.location.href = `/customers/${user.customer_id}?tab=customer-users`
                        break
                    case "vendor_user":
                        window.location.href = `/vendors/${user.service_provider_id}?tab=vendor-users`
                        break
                    default:
                        window.location.pathname = '/users'
                }
            } else {
                window.location.pathname = '/users'
            }
          })
          .catch((error) => {
            Swal.fire({
                icon: 'error',
                title: "Failed To Deimpersonate",
                text: "An error occured when attempting to deimpersonate. Please force a logout by pressing the button below.",
                showDenyButton: true,
                showConfirmButton: false,
                denyButtonText: t('force_logout'),
                allowEscapeKey: false,
                allowOutsideClick: false
            }).then((result) => {
                if(result.isDenied) {
                    logout()
                }
            })
          });
    };
    useEffect(() => {
        if (middleware === 'auth.code') {
            switch(user?.role) {
                case "tpl_driver":
                case "service_provider":
                    window.location.replace(redirectIfAuthenticated)
                    break
                default:
            }
        }

        if (middleware === 'guest' && redirectIfAuthenticated && user) {
            window.location.replace(redirectIfAuthenticated)
        }

        if (
            window.location.pathname === '/verify-email'
            && user?.email_verified_at
        ) {
            window.location.replace(redirectIfAuthenticated)
        }

        if (middleware === 'auth' && error) {
            logout()
        }
    }, [user, error])

    return {
        user,
        isLoading,
        login,
        forgotPassword,
        resetPassword,
        resendEmailVerification,
        logout,
        loginImpersonate,
        logoutImpersonate,
        switchUser,
        csrf,
    }
}
