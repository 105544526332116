import React, { useState, useEffect } from 'react'
import { TextField } from '@mui/material'

const StackItems = ({i, stacks, toShowPdf, handleComplete, handleUncomplete, stackCount, setStacks, activeStep, enabled, completed, setDisabled}) => {

    const [qty, setQty] = useState('')

    useEffect(() => {
      setQty(i.stack_item_qty)
    }, [i])

    useEffect(() => {
        if(stackCount > 0) {
            handleComplete()
            setDisabled(false)
        } else {
            setDisabled(true)
            handleUncomplete()
        }
    }, [stackCount])

    const handleZero = (e) => {
        if (e.target.value === "") {
            setQty(0)
            setStacks(prev => {
                    const update = [...prev]
                    const find = update[activeStep].stack_items[0].filter((item) => item.id === i.id)
                    find[0].stack_item_qty = 0
                    return update
                })
        }
    }

    const deleteZero = (e) => {
        if (qty === 0) {
            setQty('')
            setStacks(prev => {
                const update = [...prev]
                const find = update[activeStep].stack_items[0].filter((item) => item.id === i.id)
                find[0].stack_item_qty = 0
                return update
            })
        }
    }

    const handleQty = (e) => {
        const regex = /^(-?\d*)((\.(\d{0,2})?)?)$/i
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') setQty(0)
            else {

                setQty(e.target.value)

                setStacks(prev => {
                    const update = [...prev]
                    const find = update[activeStep].stack_items[0].filter((item) => item.id === i.id)
                    find[0].stack_item_qty = e.target.value === '' ? 0 : parseInt(e.target.value)
                    return update
                })
                localStorage.setItem('stacks', JSON.stringify(stacks))
                localStorage.setItem('activeStep', activeStep + 1)
                localStorage.setItem('completed', JSON.stringify(completed))
                localStorage.setItem('enabled', JSON.stringify(enabled))
                localStorage.setItem('stackCount', stackCount === "" ? 0 : stackCount)
            }
        }
    }

    return (
        <div className="flex items-center gap-10 justify-center p-5 border border-gray-300 min-w-full sm:min-w-[360px]">
            <p>{i.stack_item_code}</p>
            <TextField type="text" variant='standard' value={qty} onChange={e => handleQty(e)} onBlur={e => handleZero(e)} onFocus={e => deleteZero(e)} inputProps={{ inputMode: 'numeric' }} />
        </div>
    )
}

export default StackItems
