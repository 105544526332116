import React, { useState, useEffect, useMemo } from 'react'

import { Tooltip, Chip, Box, Checkbox, ListItemText, ListItemIcon, LinearProgress } from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import InputLabel from '@mui/material/InputLabel'
import { useTranslation } from "react-i18next"
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { Link, useSearchParams } from 'react-router-dom'
import { useDebounce } from "use-debounce"
import { includes, isEqual } from 'lodash'

import AppLayout from '../../components/Layouts/AppLayout'
import Loading from '../../components/Loading'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import ServerTable from '../ServerTable'
import { useAuth } from '../../hooks/auth'
import { handleAxiosError } from '../../helpers/helper'
import { buildUrl } from '../../helpers/apiHelper'
import brokerTransactionStatusAPI from '../../services/api/BrokerTransactions/brokerTransactionStatusAPI'
import salesOrderStatusAPI from '../../services/api/SalesOrders/salesOrderStatusAPI'


const Shipments = () => {

    const { t } = useTranslation()
    const { choosesite, setChoosesite, config, pusher, logisticsTypes } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })
    const [searchParams, setSearchParams] = useSearchParams()

    const [isLoading, setIsLoading] = useState(false)
    const [salesOrders, setSalesOrders] = useState([])
    const [salesOrderDates, setSalesOrderDates] = useState([])
    const [searchStatuses, setSearchStatuses] = useState([])

    const [random, setRandom] = useState(null)
    const [openItems, setOpenItems] = useState(false)
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)

    /* filters */
    const [searchStatus, setsearchStatus] = useState(() => {
        if (!searchParams.has('get') && localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.shipments_status || []
        } else {
            return []
        }
    })
    const [searchStatusId, setsearchStatusId] = useState(() => {
        if (!searchParams.has('get') && localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.shipments_status_id || []
        } else {
            return []
        }
    })
    const [searchDate, setsearchDate] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.shipments_requested_delivery_date || ""
        } else {
            return ""
        }
    })

    const [soNumber, setSoNumber] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.shipments_no || ""
        } else {
            return ""
        }
    })
    const [customerNo, setCustomerNo] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.shipments_customer_no || ""
        } else {
            return ""
        }
    })
    const [customerName, setCustomerName] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.shipments_customer_name || ""
        } else {
            return ""
        }
    })
    const [type, setType] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.shipments_type || ""
        } else {
            return ""
        }
    })
    const [logisticsTypeId, setLogisticsTypeId] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.shipments_logistics_type || ""
        } else {
            return ""
        }
    })

    const [debouncedValue] = useDebounce(soNumber, 500);
    const [debouncedValue1] = useDebounce(customerNo, 500);
    const [debouncedValue2] = useDebounce(customerName, 500);

    const isAllSelectedShipTo = searchStatuses.length > 0 && searchStatus.length === searchStatuses.length;

    const [model, setModel] = useState({
        actions: true,
        customer_name: true,
        customer_no: true,
        driver_name: false,
        forklift_driver_name: false,
        requested_delivery_date: true,
        ship_address_name: false,
        so_number: true,
        status_name: true,
        type: true
    })

    useEffect(() => {
        getTableView()
    }, [])

    const getTableView = async () => {
        setIsLoading(true)
        await axios.get(`/api/get-table-view?view_name=shipments_table&user_id=${user?.id}`, config)
            .then(res => {
                const model = res.data
                if (Object.keys(model?.success?.data).length > 0) {
                    setModel(model.success.data)
                }
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (localStorage.getItem('prev_site') !== null) {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('prev_site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('prev_site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${localStorage.getItem('prev_site')}`)
        }

        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('site')}`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${localStorage.getItem('site')}`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)
        if (choosesite) {

            channeldelete.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${localStorage.getItem('site')}`, data => {
                setRandom(Math.random())
            })

            channelcreate.bind(`${localStorage.getItem('client_id')}-salesorder-created-event-site-${localStorage.getItem('site')}`, data => {
                setRandom(Math.random())
            })

            channelupdate.bind(`${localStorage.getItem('client_id')}-salesorder-updated-event-site-${localStorage.getItem('site')}`, data => {
                setRandom(Math.random())
            })
        }

        setChoosesite(localStorage.getItem('site'))

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${localStorage.getItem('site')}`)
        })
    }, [choosesite])

    const getSalesOrderDates = (salesOrders) => {

        let dates = [];

        salesOrders.forEach(order => {
            let newFutureDate = order.requested_delivery_date;

            let newFutureDateExists = dates.includes(newFutureDate);

            if (!newFutureDateExists) {
                dates.push(newFutureDate);
            }
        });

        dates.sort();

        setSalesOrderDates(dates);
    }

    const getStatuses = async () => {
        if(user?.role != 'vendor_user') {
            getSalesOrderStatuses()
        } else {
            getBrokerTransactionStatuses()
        }
    }

    const getBrokerTransactionStatuses = async () => {
        await brokerTransactionStatusAPI.getBrokerTransactionStatuses()
            .then((data) => {
                const statuses = data
                    .map((status) => ({
                        id: status.id,
                        so_status: status.bt_status,
                        so_name: status.bt_name,
                        so_description: status.bt_description
                    }))
                    .filter((status) => status.id != 1)
                setSearchStatuses(statuses)
            })
    }

    const getSalesOrderStatuses = async () => {
        await salesOrderStatusAPI.getSalesOrdersStatuses()
            .then((data) => {
                const statuses = data
                setSearchStatuses(statuses)

                if (statuses.length > 0 && searchParams.has('get')) {
                    let objects = []
                    let ids = []

                    switch (searchParams.get('get')) {
                        case 'active':
                            ids = [1, 2, 3, 4, 5, 6, 7, 8]
                            objects = statuses.filter((status) => includes(ids, status.id))
                            break
                        case 'inactive':
                            ids = [9, 10, 11, 12]
                            objects = statuses.filter((status) => includes(ids, status.id))
                            break
                        default:
                            ids = []
                            objects = []
                    }

                    setsearchStatusId(ids)
                    setsearchStatus(objects)
                }
            })
    }

    const handleClearDate = () => {
        setsearchDate('')

        getSalesOrderDates(salesOrders)
    }


    const handleClearType = () => {
        setType('')
    }

    const handleClearLogisticsTypeId = () => {
        setLogisticsTypeId('')
    }

    const handleSearchNo = (e) => {
        setSoNumber(e.target.value)
    }

    const handleCustomerhNo = (e) => {
        setCustomerNo(e.target.value)
    }

    const handleCustomerName = (e) => {
        setCustomerName(e.target.value)
    }

    useEffect(() => {
        getStatuses()
    }, [])

    useEffect(() => {
        if (choosesite) {
            getSalesOrders()
        }
        createFilterArray(soNumber, customerNo, customerName, searchDate, searchStatus, type, logisticsTypeId, searchStatusId)
    }, [choosesite, searchDate, searchStatus, type, logisticsTypeId, page, pageSize, debouncedValue, debouncedValue1, debouncedValue2, random])


    const getSalesOrders = async () => {
        setIsLoading(true)
        let date
        if (searchDate == '') {
            date = ''
        } else {
            date = dayjs(searchDate).format('YYYY-MM-DD')
        }

        let url = buildUrl(
            '/api/list-orders',
            {
                where: {
                    order_type: 'sales',
                    site_id: choosesite,
                    customer_no: customerNo,
                    customer_name: customerName,
                    so_number: soNumber,
                    requested_delivery_date: date,
                    page: page + 1,
                    page_size: pageSize
                }
            }
        )

        if(user?.role == 'vendor_user') {
            url = url.concat(
                `&bt_status_id=${searchStatusId}`,
                '&include_broker=true',
                `&logistics_type_id=${logisticsTypeId}`
            );
        } else {
            url = url.concat(
                `&status_id=${searchStatusId}`,
                '&rejected=true',
                `&type=${type}`,
            )
        }

        await axios
            .get(url, config)
            .then(res => {
                const data = res.data?.data
                const count = res.data?.total
                setSalesOrders(data)
                getSalesOrderDates(data)
                setTotal(count)
            })
            .catch(({ response }) => {
                console.error(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const assembleColumns = () => {
        const columns = []

        const soNumberColumn = {
            field: 'so_number',
            headerName: t('sales_order_no'),
            flex: 0.7,
            renderCell: (params) => <SoNumber params={params} />,
            minWidth: 200,
            cellClassName: 'relative'
        }

        const statusNameColumn = {
            field: 'status_name',
            headerName: t('status'),
            valueGetter: (value, row) => {
                return user?.role != 'vendor_user'
                    ? value?.row?.so_status_name ?? ''
                    : value?.row?.broker_transaction?.bt_status_name ?? ''
            },
            flex: 0.7,
            renderCell: (params) => <Status params={params} user={user} t={t} />,
            minWidth: 200
        }

        const customerNoColumn = {
            field: 'customer_no',
            headerName: t('customer_no'),
            flex: 0.7,
            minWidth: 200
        }

        const customerNameColumn = {
            field: 'customer_name',
            headerName: t('customer_name'),
            flex: 1,
            minWidth: 200
        }

        const forkliftDriverNameColumn = {
            field: 'forklift_driver_name',
            headerName: t('forklift_driver'),
            flex: 1,
            minWidth: 200,
            hide: true
        }

        const driverNameColumn = {
            field: user?.role == 'vendor_user' ? 'special_driver_name' : 'driver_name',
            headerName: t('driver'),
            flex: 1,
            minWidth: 200,
            hide: true
        }

        const shipAddressNameColumn = {
            field: 'ship_address_name',
            headerName: t('shipToAdd'),
            flex: 1,
            minWidth: 200,
            hide: true
        }

        const requestedDeliveryDateColumn = {
            field: 'requested_delivery_date',
            headerName: t('requested_delivery_date'),
            flex: 0.7,
            renderCell: (params) => <ShowDate params={params} />,
            minWidth: 200
        }

        const typeColumn = user?.role == 'vendor_user' ? {
            field: 'type',
            headerName: t('logistics_type'),
            valueGetter: (value, row) => {
                return t(value?.row?.broker_transaction?.logistics_type_key) ?? ''
            },
            flex: 0.7,
            minWidth: 200
        } : {
            field: 'type',
            headerName: t('type'),
            flex: 0.7,
            renderCell: (params) => {
                return (
                    <div>
                        {params?.row?.type === 'CUSTOMER PICKUP' ? t('customer_pickup')
                            : params?.row?.type === '3PL' ? t('3PL')
                            : params?.row?.type === 'BROKERED' ? t('brokered')
                            : t('standard')
                        }
                    </div>
                )
            },
            minWidth: 200
        }

        const actionsColumn = {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => <TodaysSalesOrderAction row={params?.row} user={user} config={config} setIsLoading={setIsLoading} />,
            minWidth: 200
        }

        user?.role == 'vendor_user'
            ? columns.push(
                soNumberColumn,
                customerNoColumn,
                customerNameColumn,
                driverNameColumn,
                shipAddressNameColumn,
                requestedDeliveryDateColumn,
                statusNameColumn,
                typeColumn,
                actionsColumn
            )
            : columns.push(
                soNumberColumn,
                customerNoColumn,
                customerNameColumn,
                forkliftDriverNameColumn,
                driverNameColumn,
                shipAddressNameColumn,
                requestedDeliveryDateColumn,
                statusNameColumn,
                typeColumn,
                actionsColumn
            )

        return columns
    }

    const columns = assembleColumns()

    const handleItems = (event) => {
        const { target: { value } } = event

        let duplicateRemoved = [];

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
                duplicateRemoved.push(item);
            }
        })

        let ids = []

        value.forEach((item) => {
            if (ids.findIndex((o) => o.id === item.id) >= 0) {
                ids = ids.filter((x) => x.id === item.id);
            } else {
                ids.push(item.id);
            }
        })


        if (value[value.length - 1] === "all") {
            setsearchStatus(searchStatus.length === searchStatuses.length ? [] : searchStatuses)
            setsearchStatusId(searchStatus.length === searchStatuses.length ? [] : searchStatuses.map(i => i.id))
            return;
        }

        searchParams.delete('get')
        setSearchParams(searchParams)
        setsearchStatus(duplicateRemoved)
        setsearchStatusId(ids)
    }

    const handleClearItem = () => {
        searchParams.delete('get')
        setSearchParams(searchParams)
        setsearchStatus([])
        setsearchStatusId([])
    }

    const handleDeleteItem = (e, id) => {
        const shouldDelete = searchStatus.find((x) => x.id === id)
        let shipToLocationId = []
        if (shouldDelete) {
            const filtered = searchStatus.filter((x) => x.id !== shouldDelete.id)
            searchParams.delete('get')
            setSearchParams(searchParams)
            if (filtered.length > 0) {
                filtered.forEach((stl) => {
                    if (stl.hasOwnProperty('id')) searchStatusId.push(stl.id)
                })
                shipToLocationId = filtered.map((filter) => filter.id)
                setsearchStatus(filtered)
                setsearchStatusId(shipToLocationId)
            } else {
                setsearchStatus([])
                setsearchStatusId([])
                setOpenItems(false)
            }
        }
    }

    const createFilterArray = (shipmentsNo, shipmentsCustomerNo, shipmentsCustomerName, shipmentsRequestedDeliveryDate, shipmentsStatus, shipmentsType, shipmentsLogisticsType, shipmentsStatusId) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].shipments_no = shipmentsNo
        filters[0].shipments_customer_no = shipmentsCustomerNo
        filters[0].shipments_customer_name = shipmentsCustomerName
        filters[0].shipments_requested_delivery_date = shipmentsRequestedDeliveryDate
        filters[0].shipments_status = shipmentsStatus
        filters[0].shipments_type = shipmentsType
        filters[0].shipments_logistics_type = shipmentsLogisticsType
        filters[0].shipments_status_id = shipmentsStatusId

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <div className='flex justify-between'>
                    <div className='p-5 pt-0 w-full'>
                        <div className='pb-5 mb-2 rounded-md bg-white'>
                            <div className='border-b pb-5'>
                                <div className='flex justify-between items-end w-full'>
                                    <div className='px-5 pt-5 w-full'>
                                        <div className='flex justify-between items-center search'>
                                            <input type="text" placeholder={t('search_by_sales_no')} value={soNumber} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={handleSearchNo} />
                                            <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                        </div>
                                    </div>
                                    <div className='pt-5 w-full'>
                                        <div className='flex justify-between items-center search'>
                                            <input type="text" placeholder={t('search_by_customer_no')} value={customerNo} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={handleCustomerhNo} />
                                            <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                        </div>
                                    </div>
                                    <div className='px-5 pt-5 w-full'>
                                        <div className='flex justify-between items-center search'>
                                            <input type="text" placeholder={t('search_by_customer_name')} value={customerName} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={handleCustomerName} />
                                            <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-between items-end w-full'>
                                    <div className='px-5 pt-5 w-full'>
                                        <FormControl sx={{ width: 'inherit' }}>
                                            <InputLabel id="demo-simple-select-standard-label">{t('search_by_request_delivery_date')}</InputLabel>
                                            <Select
                                                value={searchDate}
                                                onChange={e => setsearchDate(e.target.value)}
                                                label="Search Past Or Present Date"
                                                sx={{
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                    ".MuiSelect-iconOutlined": { display: searchDate ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                                }}
                                                endAdornment={searchDate ? (<IconButton sx={{ visibility: searchDate ? "visible" : "hidden", padding: '0' }} onClick={handleClearDate}><ClearIcon /></IconButton>) : false}
                                            >
                                                {
                                                    salesOrderDates.length < 1 ?
                                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                        :
                                                        salesOrderDates.map((date) => (
                                                            <MenuItem key={date} value={date}>{date}</MenuItem>
                                                        ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className='pt-5 w-full'>
                                        <FormControl sx={{ width: 'inherit' }}>
                                            <InputLabel id="demo-simple-select-standard-label">{t('search_by_status')}</InputLabel>

                                            <Select
                                                multiple
                                                value={searchStatus}
                                                open={openItems}
                                                onOpen={() => setOpenItems(true)}
                                                onClose={() => setOpenItems(false)}
                                                onChange={handleItems}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((x) => (
                                                            <Chip
                                                                key={`i_option-${x.id}`}
                                                                label={x.so_name}
                                                                onMouseDown={(e) => e.stopPropagation()}
                                                                onDelete={(e) => handleDeleteItem(e, x.id)}
                                                            />
                                                        ))}
                                                    </Box>
                                                )}
                                                sx={{
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                    ".MuiSelect-iconOutlined": { display: (searchStatus.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                                }}
                                                endAdornment={searchStatus ? (<IconButton sx={{ visibility: (searchStatus.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearItem}><ClearIcon /></IconButton>) : false}
                                            >
                                                {
                                                    searchStatuses?.length > 0 ?
                                                        <MenuItem value="all">
                                                            <ListItemIcon>
                                                                <Checkbox checked={isAllSelectedShipTo} indeterminate={searchStatus.length > 0 && searchStatus.length < searchStatuses.length} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={t('select_all')} />
                                                        </MenuItem>
                                                        :
                                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                                }
                                                {
                                                    searchStatuses.map((it) =>
                                                        <MenuItem value={it} key={it.id}>
                                                            <Checkbox checked={searchStatus?.findIndex((i) => i.id === it.id) >= 0} />
                                                            <ListItemText primary={it.so_name} />
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>

                                    {user?.role != 'vendor_user' ? (
                                        <div className='px-5 pt-5 w-full'>
                                            <FormControl sx={{ width: 'inherit' }}>
                                                <InputLabel id="demo-simple-select-standard-label">{t('search_by_type')}</InputLabel>
                                                <Select
                                                    value={type}
                                                    onChange={e => setType(e.target.value)}
                                                    sx={{
                                                        boxShadow: 'none',
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: 'none !important'
                                                        },
                                                        background: '#F5F5F5',
                                                        borderRadius: '6px',
                                                        ".MuiSelect-iconOutlined": {
                                                            display: type ? 'none !important' : ''
                                                        },
                                                        "&.Mui-focused .MuiIconButton-root": {
                                                            color: 'rgba(0,0,0,.42)'
                                                        }
                                                    }}
                                                    endAdornment={type ? (<IconButton sx={{ visibility: type ? "visible" : "hidden", padding: '0' }} onClick={handleClearType}><ClearIcon /></IconButton>) : false}
                                                >
                                                    <MenuItem value={'SHIPMENT'}>{t('standard')}</MenuItem>
                                                    <MenuItem className='pickup' value={'CUSTOMER PICKUP'}>{t('customer_pickup')}</MenuItem>
                                                    <MenuItem className='tpl' value={'3PL'}>{t('3PL')}</MenuItem>
                                                    <MenuItem className="brokered" value={'BROKERED'}>{t('brokered')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    ) : (
                                        <div className='px-5 pt-5 w-full'>
                                            <FormControl sx={{ width: 'inherit' }}>
                                                <InputLabel id="demo-simple-select-standard-label">{t('search_by_x', {x: t('logistics_type')})}</InputLabel>
                                                <Select
                                                    value={logisticsTypeId}
                                                    onChange={e => setLogisticsTypeId(e.target.value)}
                                                    sx={{
                                                        boxShadow: 'none',
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: 'none !important'
                                                        },
                                                        background: '#F5F5F5',
                                                        borderRadius: '6px',
                                                        ".MuiSelect-iconOutlined": {
                                                            display: logisticsTypeId ? 'none !important' : ''
                                                        },
                                                        "&.Mui-focused .MuiIconButton-root": {
                                                            color: 'rgba(0,0,0,.42)'
                                                        }
                                                    }}
                                                    endAdornment={logisticsTypeId ? (<IconButton sx={{ visibility: logisticsTypeId ? "visible" : "hidden", padding: '0' }} onClick={handleClearLogisticsTypeId}><ClearIcon /></IconButton>) : false}
                                                >
                                                    {logisticsTypes?.map((logisticsType, index) => (
                                                        <MenuItem key={`logtype-${index}`} value={logisticsType.id}>{t(logisticsType.key)}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='pt-3'>
                                <ServerTable columns={columns} items={salesOrders} total={total} setPage={setPage} setPageSize={setPageSize} page={page} pageSize={pageSize} columnVisibilityModel={model} setModel={setModel} view='shipments' />
                            </div>
                        </div>
                    </div>
                </div>
            </AppLayout>
        </>
    )
}

export default Shipments

const Status = ({ params, user, t }) => {
    const render = () => {
        return user?.role != 'vendor_user' ? (
            <>
                {
                    params.row.so_status_id == 4 ?
                        params.row.forklift_driver_id ?
                            <Tooltip disableInteractive title={params.row.forklift_driver_name} placement='bottom'>
                                <div>{params.row.so_status_name}</div>
                            </Tooltip>
                            :
                            <>{params.row.so_status_name}</>
                        :
                        params.row.so_status_id == 7 ?
                            params.row.driver_id ?
                                <Tooltip disableInteractive title={params.row.driver_name} placement='bottom'>
                                    <div>{params.row.so_status_name}</div>
                                </Tooltip>
                                :
                                <>{params.row.so_status_name}</>
                            :
                            <>{params.row.so_status_name}</>
                }

            </>

        ) : (
            <>{params.row?.broker_transaction?.bt_status_name}</>
        )
    }

    return render()
}

const TodaysSalesOrderAction = (params) => {

    const { t } = useTranslation()

    const { row, user, config, setIsLoading } = params
    const uploaded_driver_bol = row?.uploaded_driver_bol

    const deleteShipment = async () => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_salesOrder') + row.so_number + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }

        setIsLoading(true)

        await axios
            .delete(`/api/sales-orders/${row.id}`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                        customClass: 'success',
                        showCloseButton: true,
                        iconColor: '#00B78E',
                        text: data.success.message
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPodUrl = () => {
        if(uploaded_driver_bol)
            return uploaded_driver_bol?.document_url

        if(row?.broker_transaction) {
            if(row.broker_transaction?.uploaded_bol)
                return row.broker_transaction.uploaded_bol.document_url
        } else {
            if(row?.uploaded_bol)
                return row.uploaded_bol.document_url
        }

        return `${process.env.REACT_APP_BACKEND_URL}/api/broker-transactions/${row.broker_transaction?.id ?? row.broker_transaction}/proof-of-delivery?stream=view&party=supplier&CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`
    }

    const renderActions = () => {
        return user?.role == 'vendor_user' ? (
            <>
                <div className='flex justify-between'>
                    <Tooltip disableInteractive title="BOL/POD" placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <a href={getPodUrl()} target="_blank">
                                <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200">
                                    <i className="fa-solid fa-print"></i>
                                </span>
                            </a>
                        </div>
                    </Tooltip>

                    <Tooltip disableInteractive title={t('open')} placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <Link to={`/sales-orders/${row.broker_transaction?.id ?? row.broker_transaction}`} >
                                <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200">
                                    <i className="fa-solid fa-eye"></i>
                                </span>
                            </Link>
                        </div>
                    </Tooltip>
                </div>
            </>
        ) : (
            <>
                <div className='flex justify-between'>
                    <Tooltip disableInteractive title="BOL/POD" placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <a href={process.env.REACT_APP_BACKEND_URL + `/api/sales-orders/${row.id}/proof-of-delivery?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`} target="_blank"><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-print"></i></span></a>
                        </div>
                    </Tooltip>

                    {row.so_status_id === 1 || row.so_status_id === 3 ?
                        (<Tooltip disableInteractive title={t('update')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <Link to={`/sales-order/${row.id}`} ><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-pencil"></i></span></Link>
                            </div>
                        </Tooltip>)
                        :
                        (<Tooltip disableInteractive title={t('open')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <Link to={`/sales-order/${row.id}`} ><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-eye"></i></span></Link>
                            </div>
                        </Tooltip>)
                    }

                    {(row.so_status_id < 10 || !isEqual(row.so_status, 'invoiced')) ? (
                        <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={deleteShipment}><i className="fa-solid fa-trash"></i></span>
                            </div>
                        </Tooltip>
                    ) : (
                        ''
                    )}
                </div>
            </>
        )
    }

    return renderActions()
}

export const ShowDate = (params) => {
    return (
        <div>
            <p>{dayjs(params.params.row.requested_delivery_date).format("YYYY-MM-DD")}</p>
        </div>
    )
}

export const SoNumber = (params) => {
    const { t } = useTranslation()

    return (
        <div>
            {
                params.params.row.type == 'CUSTOMER PICKUP' ?
                    <Tooltip disableInteractive title={t('customer_pickup')}>
                        <div style={{ position: 'absolute', top: 0, left: 0, border: '7.5px solid', borderColor: `rgb(211, 211, 30) transparent transparent rgb(211, 211, 30)` }}></div>
                    </Tooltip>
                    :
                    params.params.row.type == '3PL' ?
                        <Tooltip disableInteractive title={t('3PL')}>
                            <div style={{ position: 'absolute', top: 0, left: 0, border: '7.5px solid', borderColor: `rgb(76, 175, 80) transparent transparent rgb(76, 175, 80)` }}></div>
                        </Tooltip>
                        :
                        params.params.row.type == 'BROKERED' ?
                            <Tooltip disableInteractive title={t('brokered')}>
                                <div style={{ position: 'absolute', top: 0, left: 0, border: '7.5px solid', borderColor: `rgb(255,25,25) transparent transparent rgb(255,25,25)` }}></div>
                            </Tooltip>
                            : null
            }

            <p>{params.params.row.so_number}</p>
        </div>
    )
}
