import CustomCard from "../CustomCard"
import { useTranslation } from "react-i18next"
import { IconButton, Stack, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import CustomCardContent from "./utils/CustomCardContent"

export default function ActiveCollections({data = [], showActions = false, isLoading = false, isUpdating = false}) {
    const { t } = useTranslation()

    return (
        <CustomCard
            title={t('active_collections')}
            showActions={showActions}
            actions={
                showActions ? (
                    <Stack direction='row' spacing={2}>
                        <Tooltip title={`${t('view_x', {x: t('active_collections')})}`} arrow placement="top">
                            <Link to={'/collections?get=active'}>
                                <IconButton aria-label={`view-active-collections-button`} size='small'>
                                    <i className="fa-solid fa-eye fa-sm"></i>
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </Stack>
                ) : (
                    ''
                )
            }
            isLoading={isLoading}
            isUpdating={isUpdating}
        >
            <CustomCardContent
                qty={data?.all_active_collections_count ?? 0}
                supplementaryQty={data?.current_month_active_collections_count ?? 0}
                pct={data?.diff_pct ?? 0}
                isShipmentRelated={false}
            />
        </CustomCard>
    )
}
