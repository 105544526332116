const Create3PLSalesOrderIcon = ({size='regular', black = false}) => {
    const renderThis = () => {
        switch(size) {
            case "small":
                return (
                    <span className="fa-stack" style={{fontSize: "8px"}}>
                        <i className="fa-solid fa-truck-fast fa-stack-2x" style={{color: black ? "#000000" : "#333333"}}></i>
                        <i className={`fa-solid fa-circle-plus fa-stack-1x fa-inverse`} style={{top: '-15px', left: '-15px', color: black ? "#000000" : "#333333", fontSize: '15px'}}></i>
                    </span>
                )
            default:
                return (
                    <span className="fa-stack" style={{fontSize: "9px"}}>
                        <i className="fa-solid fa-truck-fast fa-stack-2x" style={{color: black ? "#000000" : "#333333"}}></i>
                        <i className={`fa-solid fa-circle-plus fa-stack-1x fa-inverse`} style={{top: '-15px', left: '-15px', color: black ? "#000000" : "#333333", fontSize: '15px'}}></i>
                    </span>
                )
        }
    }

    return renderThis()
}

export default Create3PLSalesOrderIcon
