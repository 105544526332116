import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
    Autocomplete,
    Box,
    Checkbox,
    Chip,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Modal,
    Select,
    TextField,
    Tooltip
} from '@mui/material'
import Swal from 'sweetalert2'
import {Link, useSearchParams} from 'react-router-dom'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"

import TableShared from '../../components/TableShared'
import AddButton from '../../components/AddButton'
import Loading from '../../components/Loading'
import {useStateContext} from '../../context/ContextProvider'
import axios from '../../lib/axios'
import style from '../../styles/style'
import {useAuth} from "../../hooks/auth"
import {handleAxiosError, handleDeleteSelectionObjectPlus, isValid} from '../../helpers/helper'
import { includes } from 'lodash'

const VendorUsers = ({ vendorId, vendorCompanyId, vendorSiteId }) => {
    const { user } = useAuth({ middleware: 'guest ' })

    const { config, pusher, choosesite, setChoosesite } = useStateContext()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false)

    const [searchName, setSearchName] = useState('')
    const [vendorUsers, setVendorUsers] = useState([])

    const [purchaseAddresses, setPurchaseAddresses] = useState([])

    /* Pusher Channels (those that need to be bound and then rebound) */
    const [channelPurchaseAddressDeleted, setChannelPurchaseAddressDeleted] = useState(null)
    const [channelPurchaseAddressUpdated, setChannelPurchaseAddressUpdated] = useState(null)
    const [channelPurchaseAddressCreated, setChannelPurchaseAddressCreated] = useState(null)

    /* Form */
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [selectedPurchaseAddresses, setSelectedPurchaseAddresses] = useState([])
    const [selectedPurchaseAddressIds, setSelectedPurchaseAddressIds] = useState([])

    const [openPurchaseAddresses, setOpenPurchaseAddresses] = useState(false)

    const allPurchaseAddressesSelected = purchaseAddresses.length > 0 && selectedPurchaseAddresses.length === purchaseAddresses.length

    useEffect(() => {
        const channelVendorUserDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-vendoruser-deleted`)
        const channelVendorUserCreated = pusher.subscribe(`${localStorage.getItem('client_id')}-vendoruser-created`)
        const channelVendorUserUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-vendoruser-updated`)

        const cpad = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)
        const cpac = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-created`)
        const cpau = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)

        setChannelPurchaseAddressDeleted(cpad)
        setChannelPurchaseAddressCreated(cpac)
        setChannelPurchaseAddressUpdated(cpau)

        {/* Vendor User events */}
        channelVendorUserDeleted.bind(`${localStorage.getItem('client_id')}-vendoruser-deleted-event`, data => {
            if(Number(vendorId) == Number(data.service_provider_id)) {
                getVendorUsers()
            }
        })

        channelVendorUserCreated.bind(`${localStorage.getItem('client_id')}-vendoruser-created-event`, data => {
            if(Number(vendorId) == Number(data.service_provider_id)) {
                getVendorUsers()
            }
        })

        channelVendorUserUpdated.bind(`${localStorage.getItem('client_id')}-vendoruser-updated-event`, data => {
            if(Number(vendorId) == Number(data.service_provider_id)) {
                getVendorUsers()
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendoruser-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendoruser-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendoruser-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)
        })
    }, [])

    {/* Apparently there is a risk where you may incorrectly bind events. In our case, outdated props are used. */}
    useEffect(() => {
        {/* Purchase Address events */}
        if(channelPurchaseAddressDeleted && channelPurchaseAddressDeleted.bind) {
            channelPurchaseAddressDeleted.unbind(`${localStorage.getItem('client_id')}-purchaseaddress-deleted-event`)
            channelPurchaseAddressDeleted.bind(`${localStorage.getItem('client_id')}-purchaseaddress-deleted-event`, data => {
                if(Number(vendorId) == Number(data.vendor_id)) {
                    handleClearPurchaseAddresses()
                    getPurchaseAddresses()
                }
            })
        }

        if(channelPurchaseAddressCreated && channelPurchaseAddressCreated.bind) {
            channelPurchaseAddressCreated.unbind(`${localStorage.getItem('client_id')}-purchaseaddress-created-event`)
            channelPurchaseAddressCreated.bind(`${localStorage.getItem('client_id')}-purchaseaddress-created-event`, data => {
                if(Number(vendorId) == Number(data.vendor_id)) {
                    getPurchaseAddresses()
                }
            })
        }

        if(channelPurchaseAddressUpdated && channelPurchaseAddressUpdated.bind) {
            channelPurchaseAddressUpdated.unbind(`${localStorage.getItem('client_id')}-purchaseaddress-updated-event`)
            channelPurchaseAddressUpdated.bind(`${localStorage.getItem('client_id')}-purchaseaddress-updated-event`, data => {
                if(Number(vendorId) == Number(data.vendor_id)) {
                    handleClearPurchaseAddresses()
                    getPurchaseAddresses()
                }
            })
        }

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)
        })
    }, [vendorId])

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '13' && open) {
                createVendorUser()
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [open, name, email, selectedPurchaseAddressIds]);

    useEffect(() => {
        getVendorUsers()
        getPurchaseAddresses()
    }, [vendorId])

    {/* Clears all inputs when closing the window */}
    useEffect(() => {
        if(!open) {
            setName('')
            setEmail('')
            setSelectedPurchaseAddresses([])
            setSelectedPurchaseAddressIds([])
        }
    }, [open])

    const getVendorUsers = async () => {
        setIsLoading(true)
        await axios.get(`/api/vendor-users?service_provider_id=${vendorId}&with=purchaseAddresses`, config)
            .then(res => {
                const data = res.data
                setVendorUsers(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseAddresses = async () => {
        setIsLoading(true)
        await axios.get(`/api/purchase-addresses?vendor_id=${vendorId}`, config)
            .then(res => {
                const data = res.data
                setPurchaseAddresses(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    {/* Handlers */}

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(!open)
        setName('')
        setEmail('')
        setSelectedPurchaseAddresses([])
        setSelectedPurchaseAddressIds([])
    }

    const handlePurchaseAddress = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = [];

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
                duplicateRemoved.push(item);
            }
        })

        let ids = []

        value.forEach((item) => {
            if (ids.findIndex((o) => o.id === item.id) >= 0) {
                ids = ids.filter((x) => x.id === item.id);
            } else {
                ids.push(item.id);
            }
        })

        if (value[value.length - 1] === "all") {
            setSelectedPurchaseAddresses(selectedPurchaseAddresses.length === purchaseAddresses.length ? [] : purchaseAddresses);
            setSelectedPurchaseAddressIds(selectedPurchaseAddresses.length === purchaseAddresses.length ? [] : purchaseAddresses.map(i => i.id))
            return;
        }
        setSelectedPurchaseAddresses(duplicateRemoved)
        setSelectedPurchaseAddressIds(ids)
    }

    const handleClearPurchaseAddresses = () => {
        setSelectedPurchaseAddresses([])
        setSelectedPurchaseAddressIds([])
    }

    const createVendorUser = async () => {
        setIsLoading(true)
        const formData = {}

        formData['name'] = name
        formData['email'] = email
        formData['purchase_addresses'] = selectedPurchaseAddressIds
        formData['company_id'] = vendorCompanyId
        formData['site_id'] = vendorSiteId || localStorage.getItem('site')
        formData['service_provider_id'] = vendorId

        await axios.post(`/api/vendor-users`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })

            handleClose()
        })
        .catch(({ response }) => {
            handleAxiosError({response: response})
        })
        .finally(() => {
            setIsLoading(false)
        })
    }

    const columns = [
        {
            field: 'id',
            headerName: t('id'),
            flex: 0.5
        },
        {
            field: 'name',
            headerName: t('name'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'email',
            headerName: t('email'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'purchase_locations_name',
            headerName: t('purchase_addresses'),
            flex: 1,
            renderCell: (params) => <PurchaseAddressesCell params={params} />,
            minWidth: 200,
            sortable: false
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => <VendorUserActions params={params} config={config} vendorId={vendorId} setIsLoading={setIsLoading} />,
            minWidth: 200
        }
    ]

    const filtered = vendorUsers?.filter(data => {
        if (searchName === null) {
            return data
        }

        if (data?.name?.toLocaleLowerCase().includes(searchName?.toLocaleLowerCase())) {
            return data
        }
    })

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <div className='p-5'>
                <div className='pb-5 bg-white mb-2 rounded-md'>
                    <div className='flex justify-between items-center p-5 border-b'>
                        <p style={{fontWeight: 600, fontSize: '16px'}}>{t('vendor_users')}</p>
                        <Tooltip disableInteractive title={t('create_vendor_user')} placement='bottom'>
                            <div>
                                <AddButton onClick={handleOpen}><i className="fa-solid fa-plus"></i></AddButton>
                            </div>
                        </Tooltip>
                    </div>
                    <div className='w-full border-b pb-5'>
                        <div className='flex justify-between items-end w-1/2'>
                            <div className='px-5 pt-5 w-full'>
                                <div className='flex justify-between items-center search'>
                                    <input type="text" value={searchName} placeholder={t('search_by_name')} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSearchName(e.target.value)} />
                                    <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pt-3'>
                        <TableShared items={filtered} columns={columns} /* noRowsLabel={t('no_results')} */ />
                    </div>
                </div>

            </div>

            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <div className='flex justify-between items-center p-5 pr-0 pt-0 pb-0' style={{ backgroundColor: '#336195', borderRadius: '5px 5px 0 0' }}>
                        <div className='flex gap-4 items-baseline'>
                            <div style={{ transform: "rotate(45deg)" }} className="font-semibold text-white">
                                <button onClick={handleClose}><i className="fa-solid fa-plus"></i></button>
                            </div>
                            <p className='text-xl roboto font-semibold text-white'>{t('create_vendor_user')}</p>
                        </div>
                        <button type="button" onClick={createVendorUser} className='text-white px-4 py-6 uppercase self-end roboto bg-zinc-900 hover:bg-zinc-700 disabled:bg-zinc-400'>
                            {t('create')}
                        </button>
                    </div>

                    <div className='p-5'>
                        <form className='flex justify-center flex-col items-start mt-2'>
                            <TextField
                                type="text"
                                variant='standard'
                                label={t('name')}
                                sx={{ marginBottom: '20px' }}
                                className='w-full mb-5 px-0 pt-0'
                                name="name"
                                value={name}
                                onChange={(e) => { setName(e.target.value) }}
                                required
                            />

                            <TextField
                                type="text"
                                variant='standard'
                                label={t('email')}
                                sx={{ marginBottom: '20px' }}
                                className='w-full mb-5 px-0 pt-0'
                                name="email"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                                required
                            />

                            <FormControl
                                variant="standard"
                                sx={{ width: '100%', marginBottom: '20px' }}
                                focused={openPurchaseAddresses}
                            >
                                <InputLabel>{t('purchase_addresses')} *</InputLabel>
                                <Select
                                    multiple
                                    value={selectedPurchaseAddresses}
                                    open={openPurchaseAddresses}
                                    onOpen={() => setOpenPurchaseAddresses(true)}
                                    onClose={() => setOpenPurchaseAddresses(false)}
                                    onChange={handlePurchaseAddress}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((x) => (
                                                <Chip
                                                    key={`stl_option-${x.id}`}
                                                    label={x.name}
                                                    onMouseDown={(e) => e.stopPropagation()}
                                                    onDelete={(e) => handleDeleteSelectionObjectPlus(e, x.id, selectedPurchaseAddresses, setSelectedPurchaseAddresses, setSelectedPurchaseAddressIds, setOpenPurchaseAddresses)}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    sx={{
                                        ".MuiSelect-iconStandard": {
                                            display: (selectedPurchaseAddresses.length > 0) ? 'none !important' : ''
                                        },
                                        "&.Mui-focused .MuiIconButton-root": {
                                            color: 'rgba(0,0,0,.42)'
                                        }
                                    }}
                                    endAdornment={selectedPurchaseAddresses ? (<IconButton sx={{ visibility: (selectedPurchaseAddresses.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearPurchaseAddresses}><ClearIcon /></IconButton>) : false}
                                >
                                    {
                                        purchaseAddresses?.length > 0 ?
                                            <MenuItem value="all">
                                                <ListItemIcon>
                                                    <Checkbox checked={allPurchaseAddressesSelected} indeterminate={selectedPurchaseAddresses.length > 0 && selectedPurchaseAddresses.length < purchaseAddresses.length} />
                                                </ListItemIcon>
                                                <ListItemText primary={t('select_all')} />
                                            </MenuItem>
                                            :
                                            <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                    }
                                    {
                                        purchaseAddresses.map((item) =>
                                            <MenuItem value={item} key={item.id}>
                                                <Checkbox checked={selectedPurchaseAddresses?.findIndex((i) => i.id === item.id) >= 0} />
                                                <ListItemText primary={item.name} />
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </form>
                    </div>
                </Box>
            </Modal >
        </>
    )
}

export default VendorUsers

const PurchaseAddressesCell = (params) => {
    return (
        <>
            {
                params.params.row.purchase_addresses.map((i, index) => <div key={i.id}>{i.name}{index + 1 < params.params.row.purchase_addresses.length ? "," : ""}</div>)
            }
        </>
    )
}

const VendorUserActions = (params) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { t } = useTranslation()
    const { user, loginImpersonate } = useAuth({ middleware: 'guest' })
    const { setCompany_id, setChoosesite } = useStateContext()
    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState(null)

    const deleteItems = async (id) => {
        const isConfirm = await Swal.fire({
            title: t('delete_vendor_user') + params.params.row.name + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        await axios
            .delete(`/api/vendor-users/${id}`, params.config).then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
            }).catch(({ response }) => {
                handleAxiosError({response: response})
            })
    }

    const impersonate = async (id, role, company_id, site_id, name) => {
        searchParams.delete('tab')
        setSearchParams(searchParams)

        await loginImpersonate({
            id: id,
            impersonator_id: user?.id,
            impersonatee_name: name,
            impersonatee_site_id: site_id,
            impersonatee_company_id: company_id,
            role: role,
            site_id: localStorage.getItem('site'),
            company_id: localStorage.getItem('company_id'),
            setErrors,
            setStatus,
        }).then((data) => {
            setCompany_id(company_id)
            setChoosesite(localStorage.getItem('site'))
        }).catch(({ response }) => {
            setSearchParams({ tab: 'customer-users' })
        })
    }

    return (
        <>
            <div className='flex justify-between'>
                {
                    (
                        includes(['master_admin', 'office_manager'], user?.role)
                        && (localStorage.getItem('impersonate') === null)
                        && (params.params.row.role_slug !== "master_admin")
                    ) ?
                        <Tooltip disableInteractive title={t('impersonate')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => impersonate(params.params.row.id, 'customers_user', params.params.row.company_id, params.params.row.site_id, params.params.row.name)}><i className="fa-solid fa-user"></i></span>
                            </div>
                        </Tooltip> : ''
                }
                <Tooltip disableInteractive title={t('change_password')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <Link to={`/users/change-password/${params.params.row.id}`}>
                            <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-key"></i></span>
                        </Link>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={t('edit')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <Link to={`/vendor-user/${params.params.row.id}`}><span style={{ cursor: 'pointer' }}
                            className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i
                                className="fa-solid fa-pencil"></i></span></Link>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteItems(params.params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}

