import React, { useState, useEffect } from 'react'

import { BarChart } from '@mui/x-charts/BarChart';
import { useTranslation } from 'react-i18next';
import { round } from 'lodash';


export default function BarChartData({ items }) {

    const { t } = useTranslation()

    const [data, setData] = useState([{
        id: 0,
        value: 0,
        month: '',
        year: ''
    }])

    useEffect(() => {
        if (items?.length > 0) setData(items)
        else setData([{
            id: 0,
            value: 0,
            month: '',
            year: ''
        }])
    }, [items])

    // const [data, setData] = useState([{
    //     id: 0,
    //     value: 51,
    //     month: "Jan",
    //     year: 2022
    // },
    // {
    //     id: 2,
    //     value: 34,
    //     month: "May",
    //     year: 2023
    // }])

    const series = [
        {
            type: 'bar',
            stack: 'total',
            data: data?.map(item => item.value),
            valueFormatter: (value) => value?.toString(),
        }
    ]

    return (
        <BarChart
            width={750}
            height={300}
            series={series}
            margin={{ top: 10 }}
            xAxis={[{
                data: data?.map(item => item.month + "/" + item.year),
                scaleType: 'band',
                label: t('month_year')
            }]}
            yAxis={[{
                scaleType: 'linear',
                label: t('quantity'),
                valueFormatter: (qty) => qty > 1000 ? `${round(qty / 1000, 0)}k` : qty
            }]}
        />

    );
}
