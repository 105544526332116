import React, { isValidElement } from 'react';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useTranslation } from "react-i18next"
import { Typography, Card, CardContent, Stack, Skeleton } from '@mui/material';
import Loading from './Loading';

export default function CustomCard({
    children,
    title = 'Card Title',
    actions = null,
    showActions = false,
    isUpdating = false,
    isLoading = false,
    isChart = false
}) {
    const { t } = useTranslation()

    const renderActions = () => {
        if(isValidElement(actions)) {
            return actions
        } else {
            return null
        }
    }

    return (
        <>
            <Card sx={{minHeight: isChart ? '332px' : '152px', boxShadow: 'none'}}>
                <CardContent sx={{padding: '24px !important', position: 'relative' }}>
                    {isUpdating ? (
                        <Loading position='absolute' overflow='hidden' />
                    ) : (
                        ''
                    )}
                    <Grid container sx={{minHeight: isChart ? '332px' : '152px'}}>
                        <Grid xs={12}>
                            <Stack direction='row' justifyContent='space-between' sx={{minWidth: 0, height: '100%'}}>
                                <Typography sx={{ fontSize: 16, fontWeight: '500' }} color="text.primary" noWrap>
                                    {title}
                                </Typography>
                                {showActions ? (
                                    renderActions()
                                ) : (
                                    ''
                                )}
                            </Stack>
                        </Grid>

                        {/* CONTENT */}
                        <Grid xs={12}>
                            {isLoading ? (
                                <Skeleton variant='rectangular' sx={{width: '100%'}} height={isChart ? 268 : 101} />
                            ) : (
                                children
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}
