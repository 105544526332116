import React from 'react'

import SalesOrderButtons from './SalesOrderButtons'

const BrokeredSalesOrderButtons = ({ status, update, release, reopen, generateInvoice, disabled, blocked }) => {
    {/* At least for now these twp seroes pf buttons should be the same so I'm reusing this component. */}
    return (
        <SalesOrderButtons status={status} update={update} release={release} reopen={reopen} generateInvoice={generateInvoice} disabled={disabled} blocked={blocked} />
    )
}

export default BrokeredSalesOrderButtons
