import React, { useEffect, useState } from 'react'

import { useDrop } from 'react-dnd'
import Swal from 'sweetalert2'
import Popover from '@mui/material/Popover'
import CallMadeIcon from '@mui/icons-material/CallMade'
import CallReceivedIcon from '@mui/icons-material/CallReceived'
import { CircularProgress } from '@mui/material'


import { COLUMN_NAMES } from '../../constants/constants'
import Driver from './Driver'
import axios from '../../lib/axios'
import { useStateContext } from '../../context/ContextProvider'
import { useTranslation } from 'react-i18next'
import { isEqual, includes } from 'lodash'

const Drivers = ({ id, driver, shipments, collections, getShipments, getCollections }) => {

    const { t } = useTranslation();
    const { config, pusher } = useStateContext()
    const [possibleLoads, setPossibleLoads] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [index, setIndex] = useState(0)

    const [driverItems, setDriverItems] = useState([])


    useEffect(() => {
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)

        const channelupdatePurchase = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)

        channelupdate.bind(`${localStorage.getItem('client_id')}-salesorder-updated-event-site-${localStorage.getItem('site')}`, data => {
            if(includes(['SHIPMENT', 'BROKERED'], data?.order_type)) {
                getItems(id)
            }
        })

        channelupdatePurchase.bind(`${localStorage.getItem('client_id')}-purchaseorder-updated-event-site-${localStorage.getItem('site')}`, data => {
            if(data?.order_type === "COLLECTION") {
                getItems(id)
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)
        })
    }, [])

    const getItems = async (id) => {
        setIsLoading(true)
        await axios.get(`/api/driver/${id}/list-loads`, config) /* &so_status_id=6 */
            .then(res => {
                const salesOrder = res.data
                setDriverItems(salesOrder)
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (id) getItems(id)
    }, [id])


    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handlePopoverClose = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl)

    useEffect(() => {
        setIsLoading(false)
    }, [driverItems])

    useEffect(() => {
        checkForPossibleLoads(driverItems)
    }, [shipments, collections, driverItems])


    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
            accept: [COLUMN_NAMES.SHIPMENTS, COLUMN_NAMES.COLLECTIONS],
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            }),
            drop: (item) => updateStatus(item)
        }),
        [driverItems],
    )

    const isActive = canDrop && isOver
    let st = { backgroundColor: 'transparent', opacity: '', zIndex: 0 }
    if (isActive) {
        st = { backgroundColor: '#f6f6f6' }
    }

    const updateStatus = async (item) => {

        setIsLoading(true)
        const formData = {}
        let type = ''
        let type_req = ''

        if (item.order_type === "SHIPMENTS") {
            type = "sales-orders"
            type_req = 'sales'
        }
        else {
            type = "purchase-orders"
            type_req = 'purchase'
        }
        formData['driver_1_id'] = item.driver_id
        formData['driver_2_id'] = id
        formData['driver_id'] = id
        formData['order_type'] = type_req
        formData['order_id'] = item.id
        formData['load_order_no'] = index

        await axios.post(`/api/${type}/${item.id}/assign-driver`, formData, config)
            .then(({ data }) => {
                isEqual(item.order_type, "SHIPMENTS") ? getShipments() : getCollections()
            })
            .catch(({ response }) => {
                if (response.status === 422) {
                    Swal.fire({
                        text: response.data.description,
                        icon: "error",
                        customClass: 'error',
                        showCloseButton: true,
                        iconColor: '#FF0000'
                    })
                } else {
                    Swal.fire({
                        text: response.data.message,
                        icon: "error",
                        customClass: 'error',
                        showCloseButton: true,
                        iconColor: '#FF0000'
                    })
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const checkForPossibleLoads = (driverItems) => {
        var filteredShipments = shipments.filter((item) => item.driver_id == null)
        var filteredCollections = collections.filter((item) => item.driver_id == null)
        var shipToNames = driverItems?.map(i => i.address)
        var possibleShipments = filteredShipments?.filter(i => shipToNames?.includes(i.address))
        var possibleCollections = filteredCollections?.filter(i => shipToNames?.includes(i.address))


        setPossibleLoads(possibleShipments.concat(possibleCollections))
    }

    return (
        <>
            <div className='rounded-md h-fit w-[48%] max-[1200px]:w-full bg-white'>
                <div className='border-b flex justify-between items-baseline relative p-5' >
                    <div className='flex gap-3 justify-start items-center'>
                        <div className='text-[#939393]'><i className="fa-solid fa-truck"></i></div>
                        <p className='text-[16px] font-[600] text-[#535353]'>{driver.name}</p>
                    </div>
                    {possibleLoads.length > 0 ?
                        <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} className='bg-green-500 rounded-full text-center w-[22px] h-[22px] flex items-center justify-center'>
                            <p className='text-xs text-white font-bold'>{possibleLoads.length}</p>
                            <Popover id="mouse-over-popover" sx={{ pointerEvents: 'none', borderRadius: 'none' }} open={open} anchorEl={anchorEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} transformOrigin={{ vertical: 'top', horizontal: 'right', }} onClose={handlePopoverClose} disableRestoreFocus >
                                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', borderRadius: 'none' }} className='p-2 text-white'>
                                    {
                                        possibleLoads.map((item) =>
                                        (
                                            <div key={item.id} className='flex justify-between items-center gap-20 pb-1 pt-1'>
                                                <div className='flex gap-2'>
                                                    <p className='p-2 text-md'>{item.so_number || item.po_number}</p>
                                                    <p className='p-2 text-md'>{item.customer_name || item.vendor_name}</p>
                                                </div>
                                                <div className='p-2 rounded-full bg-white'>
                                                    {item.order_type === "SHIPMENTS" ? <div style={{ color: '#336195' }}><CallMadeIcon /></div> : <div style={{ color: '#b7472a' }}><CallReceivedIcon /></div>}
                                                </div>
                                            </div>
                                        )
                                        )
                                    }
                                </div>
                            </Popover>
                        </div>
                        :
                        ''
                    }
                </div>
                <div ref={drop} className='relative mb-5' style={st}>
                    {isLoading ? <div style={style} className='flex justify-center items-center rounded-b-md whole'><CircularProgress /></div> : null}
                    {driverItems?.length > 0 ? driverItems?.map((item, index) => <Driver id={id}
                        driverItems={driverItems}
                        setDriverItems={setDriverItems}
                        setIsLoading={setIsLoading}
                        key={item.order_number}
                        shipments={item}
                        type={item.order_type}
                        index={/* item.load_order_no */index}
                        setIndex={setIndex} />) :
                        <div className='p-4 flex justify-center'>{t('no_data')}</div>}
                </div>
            </div>
        </>
    )
}

export default Drivers


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    bgcolor: '#ffffff'
}
