import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Box, Modal, TextField, Tooltip } from '@mui/material'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import update from 'immutability-helper'

import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import AppLayout from '../../components/Layouts/AppLayout'
import TableSharedCustom from '../../components/TableSharedCustom'
import Loading from '../../components/Loading'
import AddButton from '../../components/AddButton'
import style from '../../styles/style'
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from '@mui/material/Select';
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import ClearIcon from "@mui/icons-material/Clear";
import { useAuth } from "../../hooks/auth";

const ProductionTypes = () => {

    const { t } = useTranslation()
    const { companies, choosesite, setChoosesite, config, company_id, pusher } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })

    const [searchCode, setSearchCode] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.production_type_code || ''
        } else {
            return ''
        }
    })
    const [productionTypes, setProductionTypes] = useState([])
    const [code, setCode] = useState('')
    const [description, setDescription] = useState('')
    const [companyId, setCompanyId] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false)

    const [model, setModel] = useState({
        actions: true,
        code: true,
        company_name: true,
        description: true
    })

    useEffect(() => {
        getTableView()
    }, [])

    const getTableView = async () => {
        setIsLoading(true)
        await axios.get(`/api/get-table-view?view_name=production_types_table&user_id=${user?.id}`, config)
            .then(res => {
                const model = res.data
                if (Object.keys(model?.success?.data).length > 0) {
                    setModel(model.success.data)
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-productiontype-deleted`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-productiontype-created`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-productiontype-updated`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-productiontype-deleted-event`, data => {
            setProductionTypes((prev) => {
                const deletedType = prev.find((i) => i.id === data.id)
                const typesUpdated = prev.filter((i) => i.id !== deletedType.id)
                return typesUpdated
            })
        })

        channelcreate.bind(`${localStorage.getItem('client_id')}-productiontype-created-event`, data => {
            switch (user?.role) {
                case "master_admin":
                    getProductionType(data.id, 'created')
                    break
                case "office_manager":
                case "production_supervisor":
                    if (Number(data.company_id) == Number(user?.company_id)) {
                        getProductionType(data.id, 'created')
                    }
                    break
                default:
            }
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-productiontype-updated-event`, data => {
            switch (user?.role) {
                case "master_admin":
                    getProductionType(data.id, 'updated')
                    break
                case "office_manager":
                case "production_supervisor":
                    if (Number(data.company_id) == Number(user?.company_id)) {
                        getProductionType(data.id, 'updated')
                    } else {
                        setProductionTypes((prev) => {
                            const deletedType = prev.find((i) => i.id === data.id)
                            const typesUpdated = prev.filter((i) => i.id !== deletedType.id)
                            return typesUpdated
                        })
                    }
                    break
                default:
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productiontype-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productiontype-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productiontype-updated`)
        })
    }, [choosesite])

    useEffect(() => {
        setChoosesite(localStorage.getItem('site'))
    }, [])

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '13') {
                if (open) createProductionType()
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [open, code, description]);

    const getProductionType = async (id, state) => {
        setIsLoading(true)
        await axios.get(`/api/production-types/${id}`, config)
            .then(res => {
                const item = res.data
                if (state === 'created') setProductionTypes((prev) => [...prev, item])

                if (state === 'updated') setProductionTypes((prev) => {
                    const index = prev?.findIndex((i) => i.id === id)
                    if (index < 0) {
                        const items = [...prev, item]
                        return items
                    } else {
                        return update(prev,
                            { [index]: { $set: item } }
                        )
                    }
                })
                setIsLoading(false)
            })

    }

    useEffect(() => {
        getProductionTypes()
    }, [company_id])

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const getProductionTypes = async () => {
        setIsLoading(true)
        await axios.get(`/api/production-types`, config)
            .then(res => {
                const types = res.data
                setProductionTypes(types)
                setIsLoading(false)
            })
    }

    const createProductionType = async () => {
        setIsLoading(true)
        const formData = {}

        formData['code'] = code
        formData['description'] = description
        // get company ID from select dropdown if logged in as master admin or from the local storage if logged in as office manager
        formData['company_id'] = (user?.role === "master_admin") ? companyId : localStorage.getItem('company_id')


        await axios.post(`/api/production-types`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            setCode('')
            setDescription('')
            setCompanyId('')
            setIsLoading(false)
            handleClose()
        }).catch(({ response }) => {
            if (response.status === 422) {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            }
            setIsLoading(false)
        })
    }


    const columns = [
        {
            field: 'code',
            headerName: t('code'),
            flex: 1
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1
        },
        {
            field: 'company_name',
            headerName: t('company'),
            flex: 1,
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => <TypesActions params={params} config={config} setIsLoading={setIsLoading} />
        }
    ]

    const filtered = productionTypes.filter(data => {
        if (searchCode === null)
            return data
        if (data.code.toLocaleLowerCase().includes(searchCode.toLocaleLowerCase()))
            return data
    })


    const handleChangeCompanys = (event) => {
        setCompanyId(event.target.value)
    }

    const handleClearCompanies = () => {
        setCompanyId('')
    }

    const createFilterArray = (productionTypeCode) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].production_type_code = productionTypeCode

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    useEffect(() => {
        createFilterArray(searchCode)
    }, [searchCode])


    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout showFooter={!open}>
                <div className='p-5 pt-0'>
                    <div className='pb-5 bg-white mb-2 rounded-md'>
                        <div className='flex justify-between items-center p-5 border-b'>
                            <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('production_types_list')}</p>
                            <Tooltip disableInteractive title={t('create_production_type')} placement='bottom'>
                                <div>
                                    <AddButton onClick={handleOpen}><i className="fa-solid fa-plus"></i></AddButton>
                                </div>
                            </Tooltip>
                        </div>
                        <div className='w-full pb-5 border-b'>
                            <div className='flex justify-between items-end w-1/2'>
                                <div className='px-5 pt-5 w-full'>
                                    <div className='flex justify-between items-center search'>
                                        <input type="text" placeholder={t('search_by_code')} value={searchCode} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSearchCode(e.target.value)} />
                                        <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='pt-3'>
                            <TableSharedCustom items={filtered} columns={columns} columnVisibilityModel={model} setModel={setModel} view='production_types' />
                        </div>
                    </div>

                </div>
            </AppLayout>

            <Modal open={open} onClose={handleClose}>
                <Box sx={{...style, overflowX: 'hidden'}}>

                    <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                        <div className='flex items-center justify-between w-full leading-none'>
                            <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t('create_production_type')}</p>
                            <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                                <button onClick={handleClose}><i className="fa-solid fa-plus"></i></button>
                            </div>
                        </div>
                    </div>

                    <div className='w-full bg-white h-auto min-h-full'>
                        <div className='bg-white p-6 w-full h-fit'>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('code')} *</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={code} onChange={(e) => { setCode(e.target.value) }} />
                                </div>
                            </div>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('description')} *</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={description} onChange={(e) => { setDescription(e.target.value) }} />
                                </div>
                            </div>


                            {
                                (user?.role === "master_admin") ?
                                    (
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('company')} *</p>
                                            <div className='w-full'>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <Select value={companyId} onChange={handleChangeCompanys}
                                                        sx={{
                                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                        }}>
                                                        {companies.map((item, index) => <MenuItem key={index}
                                                            value={item.id}>{item.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )
                            }

                        </div>
                        <div className='w-full flex justify-end px-6'>
                            <button type="button" onClick={createProductionType} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#DBA34B] hover:bg-[#CF7300] disabled:bg-[#B8B7BC]'>
                                <span className='pr-2'><i className="fa-solid fa-plus"></i></span> {t('create')}
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default ProductionTypes

const TypesActions = (params) => {

    const { t } = useTranslation()

    const deleteProductionTypes = async (id) => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_pt') + params.params.row.code + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        await axios.delete(`/api/production-types/${id}`, params.config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
        })
    }


    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('edit')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <Link to={`${params.params.row.id}`}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-pencil"></i></span></Link>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteProductionTypes(params.params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
