import React, { useState, useEffect } from 'react'

import { Modal, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import InfoData from './InfoData'
import { useAuth } from '../../hooks/auth'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'


const PalletContacts = ({ open, handleClose, userId }) => {

    const { t } = useTranslation()
    const { user } = useAuth({ middleware: 'guest' })
    const { config } = useStateContext()

    const [insightUser, setInsightUser] = useState([])

    useEffect(() => {
        if (user?.role === 'customers_user') getContacts()
    }, [])


    const getContacts = async () => {
        await axios.get(`/api/insights-users/${user?.id}`, config)
            .then(res => {
                const users = res.data
                setInsightUser(users)
            })
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <div className='flex justify-between items-center rounded-sm p-5 pr-0 pt-0 pb-0' style={{ backgroundColor: '#336195' }}>
                    <div className='flex gap-4 justify-between items-baseline w-full'>
                        <p className='text-xl roboto font-semibold text-white'>{insightUser?.company_name} {t('contacts')}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-semibold text-white">
                            <button onClick={handleClose}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                    <div className='px-5 py-9'></div>
                </div>
                <div className='p-5'>
                    <form className='flex justify-center flex-col items-start mt-2'>
                        {
                            insightUser?.account_manager_id ?
                                <InfoData title={t('account_manager')} name={insightUser?.account_manager_info?.name} phone={insightUser?.account_manager_info?.phone} email={insightUser?.account_manager_info?.email} />
                                :
                                null
                        }

                        {
                            insightUser?.accounting_contact_id ?
                                <InfoData title={t('accounting_contact')} name={insightUser?.accounting_contact_info?.name} phone={insightUser?.accounting_contact_info?.phone} email={insightUser?.accounting_contact_info?.email} />
                                :
                                null
                        }

                        {
                            insightUser?.customer_service_contact_id ?
                                <InfoData title={t('customer_service')} name={insightUser?.customer_service_contact_info?.name} phone={insightUser?.customer_service_contact_info?.phone} email={insightUser?.customer_service_contact_info?.email} />
                                :
                                null
                        }

                        {
                            insightUser.operations_manager_contact_id ?
                                <InfoData title={t('operations_manager')} name={insightUser?.operations_manager_contact_info?.name} phone={insightUser?.operations_manager_contact_info?.phone} email={insightUser?.operations_manager_contact_info?.email} />
                                :
                                null
                        }

                        {
                            insightUser.account_manager_id == null && insightUser.accounting_contact_id == null && insightUser.customer_service_contact_id == null && insightUser.operations_manager_contact_id == null ?
                                <div className='text-center text-3xl roboto w-full'>{t('no_data')}</div>
                                :
                                null
                        }
                    </form>
                </div>
            </Box>
        </Modal>
    )
}

export default PalletContacts

const style = {
    minWidth: "460px",
    maxWidth: "640px",
    width: "50%",
    height: "auto",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#ffffff',
    border: 'transparent',
    borderRadius: '5px',
    boxShadow: 24,
    zIndex: "1600"
}
