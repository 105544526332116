import React, { useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTranslation } from "react-i18next"
import { Link, IconButton, Box, Typography, Stack } from '@mui/material'
import CustomCard from "../../CustomCard"
import { capitalize } from "lodash"
import { useStateContext } from '../../../context/ContextProvider';

{/* An example of what the received data should look like. */}
const dummyData = [
    {
        name: 'Customer A',
        current_year_revenue: 15000,
        last_year_revenue: 12000
    },
    {
        name: 'Customer B',
        current_year_revenue: 20000,
        last_year_revenue: 25000
    },
    {
        name: 'Customer C',
        current_year_revenue: 10000,
        last_year_revenue: 12500
    },
    {
        name: 'Customer D',
        current_year_revenue: 5000,
        last_year_revenue: 7500
    },
    {
        name: 'Customer E',
        current_year_revenue: 10000,
        last_year_revenue: 11000
    },
    {
        name: 'Customer Z',
        current_year_revenue: 9999,
        last_year_revenue: 10000
    },
    {
        name: 'Balam Industries',
        current_year_revenue: 12000,
        last_year_revenue: 9000
    },
    {
        name: 'Arquebus Corporation',
        current_year_revenue: 25000,
        last_year_revenue: 30000
    },
    {
        name: 'RLF',
        current_year_revenue: 7000,
        last_year_revenue: 5000
    },
    {
        name: 'PCA',
        current_year_revenue: 12500,
        last_year_revenue: 15000
    },
];

const DataChart = ({dataset}) => {
    const { t } = useTranslation()
    const { currencySymbol } = useStateContext()

    const currencyFormatter = (value) => `${currencySymbol}${value}`;

    return (
        <BarChart
            dataset={dataset}
            xAxis={[{
                scaleType: 'band',
                dataKey: 'name',
                categoryGapRatio: 0.7,
                barGapRatio: 0.3
            }]}
            leftAxis={null}
            series={[
                { dataKey: 'current_year_revenue', label: capitalize(t('revenue_this_x', {x: t('year')})), color: '#838383', valueFormatter: currencyFormatter },
                { dataKey: 'last_year_revenue', label: capitalize(t('revenue_last_x', {x: t('year')})), color: '#E6E8EC', valueFormatter: currencyFormatter },
            ]}
            height={308}
            margin={{
                left: 10,
                right: 10,
                top: 60,
                bottom: 20,
            }}
            slotProps={{
                legend: {
                    direction: 'column',
                    position: { vertical: 'top', horizontal: 'right' },
                    padding: {top: 10},
                    itemMarkWidth: 12,
                    itemMarkHeight: 12,
                    markGap: 10,
                    itemGap: 10,
                    labelStyle: {
                        fontSize: 12,
                    },
                },
            }}
        />
    )
}

const NoDataChart = ({dataset, showDummyData}) => {
    const { t } = useTranslation()

    return showDummyData ? (
        <DataChart dataset={dataset} />
    ) : (
        <Box
            height={308}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Typography variant='body2'>{t('no_data')}</Typography>
        </Box>
    )
}

export default function TopCustomersByRevenueXChart({data, showActions = false, isLoading = false, isUpdating = false}) {
    const { t } = useTranslation()

    const [showDummyData, setShowDummyData] = useState(false)

    const toggleShowDummyData = () => setShowDummyData(!showDummyData);

    return (
        <CustomCard
            title={t('top_x_customers_by_revenue', {x: 10})}
            showActions={showActions}
            actions={
                showActions ? (
                    <Stack direction='row' spacing={2}>
                        {/* <Tooltip title={`${showDummyData ? 'Hide' : 'Show'} Dummy Data`} arrow placement="left">
                            <Link to={'/'} onClick={toggleShowDummyData}>
                                <IconButton aria-label={`view-${'/'}`} size='small'>
                                    <i className={`fa-solid ${showDummyData ? 'fa-eye-slash' : 'fa-eye'} fa-sm`}></i>
                                </IconButton>
                            </Link>
                        </Tooltip> */}

                        <Link to={'/'}>
                            <IconButton aria-label={`view-${'/'}`} size='small'>
                                <i className={`fa-solid fa-ellipsis-vertical fa-sm`}></i>
                            </IconButton>
                        </Link>
                    </Stack>
                ) : (
                    ''
                )
            }
            isLoading={isLoading}
            isUpdating={isUpdating}
            isChart
        >
            {data.length >= 1 ? (
                <DataChart dataset={showDummyData ? dummyData : data} />
            ) : (
                <NoDataChart dataset={dummyData} showDummyData={showDummyData} />
            )}
        </CustomCard>
    )
}
