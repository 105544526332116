import React, { useState, useEffect, useRef } from 'react'
import { Modal, Box, FormControl, Button, CircularProgress } from '@mui/material'
import { useStateContext } from '../../context/ContextProvider'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import SignaturePad from 'react-signature-canvas'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2';
import styles from './Assigned.module.css'
import axios from '../../lib/axios'
import SignatureModalBox from '../Layouts/SignatureModalBox'
import { has } from 'lodash'
import Loading from '../Loading'

const UserSignatureModal = ({ open, setOpen, userId, userHasSignature, setUserHasSignature, color = '#336195' }) => {

    const { t } = useTranslation()
    const { config } = useStateContext()

    const [canSave, setCanSave] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [signature, setSignature] = useState({})

    const sigRef = useRef({})

    /* EFFECTS */

    useEffect(() => {
        if(
            open
            && userHasSignature
            && !has(signature, 'signature_url')) getUserSignature(userId)
    }, [open, userHasSignature, signature])

    /* CRUDs */

    const getUserSignature = async (id) => {
        setIsLoading(true)

        await axios.get(`/api/users/${id}/signature`, config).then(({ data }) => {
            setSignature(data)
        }).catch(({ response }) => {
            Swal.fire({
                text: response.data.error.description,
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const storeUserSignature = async (e, id) => {
        setIsLoading(true)

        const formData = new FormData()

        formData.append('file', signature, 'user-signature.png')

        await axios.post(`/api/users/${id}/store-signature`, formData, config).then(({ data }) => {
            Swal.fire({
                text: data.success.message,
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
            })
            setOpen(false)
            setSignature(data.success.created)
            setUserHasSignature(true)
            setCanSave(false)
        }).catch(({ response }) => {
            Swal.fire({
                text: response.data.error.description,
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const destroyUserSignature = async (e, id) => {
        setIsLoading(true)

        await axios.delete(`/api/users/${id}/signature`, config).then(({ data }) => {
            Swal.fire({
                text: data.success.message,
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
            })
            setOpen(false)
            setSignature(null)
            setUserHasSignature(false)
            setCanSave(false)
        }).catch(({ response }) => {
            Swal.fire({
                text: response.data.error.description,
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const updateUserSignature = async (e, id) => {
        setIsLoading(true)

        const formData = new FormData()

        formData.append('file', signature, 'user-signature.png')

        await axios.post(`/api/users/${id}/signature`, formData, config).then(({ data }) => {
            Swal.fire({
                text: data.success.message,
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
            })
            setOpen(false)
            setSignature(data.success.updated)
            setUserHasSignature(true)
            setCanSave(false)
        }).catch(({ response }) => {
            Swal.fire({
                text: response.data.error.description,
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
        }).finally(() => {
            setIsLoading(false)
        })
    }

    /* UTILITIES */

    const handleToggleModal = () => {
        setOpen(!open)
    }

    const handleClear = () => {
        sigRef.current.clear()
        setCanSave(false)
    }

    const handleOnEnd = () => {
        const canvas = sigRef.current.getCanvas()
        canvas.toBlob((blob) => {
            setSignature(blob)
        })
        setCanSave(true)
    }

    const handleClickReplace = () => {
        console.log('Clicked Replace')
    }

    return (
        <Modal open={open} onClose={handleToggleModal}>
            <SignatureModalBox>
                <div className='p-3.5' style={{ backgroundColor: color, borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between'>
                        <div className='flex gap-4'>
                            <span><i className="fa-solid fa-pen text-xl text-white"></i></span>
                            <p className='text-xl roboto font-semibold text-white'>{t('signature')}</p>
                        </div>
                        <IconButton sx={{color: 'white'}} onClick={handleToggleModal}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                </div>
                <div>
                    <div className='p-5'>
                        <form className='flex justify-center flex-col items-start mt-2'>
                            <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }}>
                                <Box position='relative' width='420px' height='236px'>
                                    {isLoading ? (
                                        <Loading position='absolute' />
                                    ) : (
                                        ''
                                    )}

                                    {userHasSignature ? (
                                        <img
                                            style={{ width: '100%', height: 'auto' }}
                                            src={signature?.signature_url}
                                        />
                                    ) : (
                                        <SignaturePad
                                            ref={sigRef}
                                            onEnd={handleOnEnd}
                                            canvasProps={{style: {width: "100%", height: "100%", minHeight: "236px"}}}
                                        />
                                    )}
                                </Box>
                            </FormControl>
                        </form>
                        <div className='flex items-center justify-between'>
                            {/* Delete the signature */}
                            {userHasSignature ? (
                                <Button variant="contained" disabled={isLoading} className={styles['check-documents-button']} onClick={(e) => destroyUserSignature(e, userId)}>{t('delete')}</Button>
                            ) : (
                                ''
                            )}

                            {/* Update the signature */}
                            {/* {userHasSignature ? (
                                <Button variant="contained" className={styles['check-documents-button']} onClick={handleClickReplace}>{t('replace')}</Button>
                            ) : (
                                ''
                            )} */}

                            {/* Clear the signature */}
                            {!userHasSignature ? (
                                <Button variant="contained" disabled={isLoading} className={styles['check-documents-button']} onClick={handleClear}>{t('clear')}</Button>
                            ) : (
                                ''
                            )}

                            {/* Store the signature */}
                            {!userHasSignature ? (
                                <Button
                                    variant="contained"
                                    disabled={!canSave || isLoading}
                                    onClick={(e) => storeUserSignature(e, userId)}
                                    sx={{
                                        backgroundColor: '#4caf50',
                                        "&:hover": {
                                            backgroundColor: '#5b8948'
                                        },
                                        "&:disabled": {
                                            backgroundColor: "[hsla(0,0%,100%,.12)]"
                                        }
                                    }}
                                >{t('save')}</Button>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </SignatureModalBox>
        </Modal>
    )
}

export default UserSignatureModal
