import React, { useEffect, useState } from 'react'

import { Link, useNavigate, useParams } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import axios from '../../../lib/axios'
import Swal from 'sweetalert2'
import { Box, TextField } from '@mui/material'

import Loading from '../../../components/Loading'
import { useStateContext } from '../../../context/ContextProvider'

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from '@mui/material/Select';
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import ClearIcon from "@mui/icons-material/Clear";
import { useAuth } from "../../../hooks/auth";

const UpdateProductionType = () => {

    const { t } = useTranslation()
    const { companies, config } = useStateContext()
    const params = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const { user } = useAuth({ middleware: 'guest' })

    /* fields */
    const [code, setCode] = useState('')
    const [description, setDescription] = useState('')
    const [companyId, setCompanyId] = useState('')

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate(-1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    useEffect(() => {
        getProductionType(params.id)
    }, [params.id])

    const getProductionType = async (id) => {
        setIsLoading(true)
        await axios.get(`/api/production-types/${id}`, config)
            .then(res => {
                const productionType = res.data
                setCode(productionType.code)
                setDescription(productionType.description)
                setCompanyId(productionType.company_id)
                setIsLoading(false)
            })
    }

    const updateItem = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formData = {}

        formData['code'] = code
        formData['description'] = description
        // get company ID from select dropdown if logged in as master admin or from the local storage if logged in as office manager
        formData['company_id'] = (user?.role === "master_admin") ? companyId : localStorage.getItem('company_id')

        await axios.put(`/api/production-types/${params.id}`, formData, config).then(({ data }) => {

            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            setIsLoading(false)
        }).catch(({ response }) => {
            if (response.status === 422) {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            }
            setIsLoading(false)
        })
    }

    const handleChangeCompanys = (event) => {
        setCompanyId(event.target.value)
    }

    const handleClearCompanies = () => {
        setCompanyId('')
    }

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box>
                <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t('update_production_type')} - {code}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <Link to='/production-types'><i className="fa-solid fa-plus"></i></Link>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-white h-full'>
                    <div className='bg-white p-6 w-full h-fit'>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('code')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={code} onChange={(e) => { setCode(e.target.value) }} />
                            </div>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('description')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={description} onChange={(e) => { setDescription(e.target.value) }} />
                            </div>
                        </div>


                        {
                            (user?.role === "master_admin") ?
                                (
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('company')} *</p>
                                        <div className='w-full'>
                                            <FormControl sx={{ width: '100%' }}>
                                                <Select value={companyId} onChange={handleChangeCompanys}
                                                    sx={{
                                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                    }}>
                                                    {companies.map((item, index) => <MenuItem key={index}
                                                        value={item.id}>{item.name}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )
                        }

                    </div>
                    <div className='w-full flex justify-end px-6 pb-14'>
                        <button type="button" onClick={updateItem} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC]'>
                            <span className='pr-2'><i className="fa-solid fa-clock-rotate-left"></i></span> {t('update')}
                        </button>
                    </div>
                </div>


            </Box>
        </>
    )
}

export default UpdateProductionType
