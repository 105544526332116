import React, { useState, useEffect, Suspense, useMemo } from 'react'

import { Tooltip } from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import InputLabel from '@mui/material/InputLabel';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'
import Loading from '../../components/Loading'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'
import { Link, useNavigate } from 'react-router-dom'
import { useDebounce } from "use-debounce"
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

import AppLayout from '../../components/Layouts/AppLayout'
import AddButton from '../../components/AddButton'
import axios from '../../lib/axios'
import { useStateContext } from '../../context/ContextProvider'
import ServerTable from '../../components/ServerTable'
import { useAuth } from '../../hooks/auth'
import { handleAxiosError } from '../../helpers/helper';
import { includes } from 'lodash';

const CountsList = () => {

    const { t } = useTranslation();
    const { config, pusher, choosesite, setChoosesite } = useStateContext()
    const navigate = useNavigate()
    const { user } = useAuth({ middleware: 'guest' })

    const userHasPermissions = (targets = []) => {
        return user?.permissions.some((permission) => targets.includes(permission.name))
    }

    const [random, setRandom] = useState(null)
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.inventory_document_no || ''
        } else {
            return ''
        }
    })
    const [searchPeriod, setSearchPeriod] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.period || ''
        } else {
            return ''
        }
    })
    const [valueStartDate, setValueStartDate] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            const day = filter[0]?.start_date || null
            return day == null ? null : dayjs(day)
        } else {
            return null
        }
    })
    const [valueEndDate, setValueEndDate] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            const day = filter[0]?.end_date || null
            return day == null ? null : dayjs(day)
        } else {
            return null
        }
    })

    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [debouncedValue] = useDebounce(search, 500)


    const [model, setModel] = useState({
        actions: true,
        approved_date: true,
        end_date: true,
        inventory_document_no: true,
        period: true,
        start_date: true
    })


    useEffect(() => {

        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-inventory-deleted`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-inventory-created`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-inventory-updated`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-inventory-deleted-event`, data => {

            setRandom(Math.random())
        })

        channelcreate.bind(`${localStorage.getItem('client_id')}-inventory-created-event`, data => {
            setRandom(Math.random())
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-inventory-updated-event`, data => {
            setRandom(Math.random())
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-inventory-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-inventory-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-inventory-updated`)
        })
    }, [])

    /* effects */
    useEffect(() => {
        setChoosesite(localStorage.getItem('site'))
        getTableView()
    }, [])

    const getTableView = async () => {
        setIsLoading(true)
        await axios.get(`/api/get-table-view?view_name=inventory_lists_table&user_id=${user?.id}`, config)
            .then(res => {
                const model = res.data
                if (Object.keys(model?.success?.data).length > 0) {
                    setModel(model.success.data)
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (choosesite) {
            getItems(choosesite)
        }
    }, [choosesite, searchPeriod, page, pageSize, debouncedValue, valueStartDate, valueEndDate, random])



    const createFilterArray = (inventoryDocumentNo, period, startDate, endDate) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].inventory_document_no = inventoryDocumentNo
        filters[0].period = period
        filters[0].start_date = startDate
        filters[0].end_date = endDate

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    useEffect(() => {
        createFilterArray(search, searchPeriod, valueStartDate, valueEndDate)
    }, [search, searchPeriod, valueStartDate, valueEndDate])



    const getItems = async (choosesite) => {
        setIsLoading(true)
        const date = dayjs(valueStartDate).format('YYYY-MM-DD')
        const end = dayjs(valueEndDate).format('YYYY-MM-DD')
        await axios.get(`/api/inventories?inventory_document_no=${search}&period=${searchPeriod}&page=${page + 1}&page_size=${pageSize}&site_id=${choosesite}&start_date=${date}&end_date=${end}`, config)
            .then(res => {
                const items = res.data?.data
                const total = res.data?.total
                setItems(items)
                setTotal(total)
                setIsLoading(false)
            })
    }

    /* methods */
    const handleOpen = () => {
        navigate('/enter-counts')
    }

    const handleChangePeriod = (e) => {
        setSearchPeriod(e.target.value)
    }

    const handleClearPeriod = () => {
        setSearchPeriod('')
    }

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }




    const columns = [
        {
            field: 'inventory_document_no',
            headerName: t('inventory_document_no'),
            flex: 1
        },
        {
            field: 'period',
            headerName: t('period'),
            flex: 1,
            renderCell: (params) => <div className='capitalize'>{t(params.row.period)}</div>
        },
        {
            field: 'start_date',
            headerName: t('start_date'),
            flex: 1
        },
        {
            field: 'end_date',
            headerName: t('end_date'),
            flex: 1
        },
        {
            field: 'approved_date',
            headerName: t('approved'),
            flex: 1
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 1,
            cellClassName: 'padding-0',
            renderCell: (params) => <Actions params={params} setIsLoading={setIsLoading} config={config} navigate={navigate} user={user} />
        }
    ]


    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <div className='p-5 pt-0'>
                    <div className='pb-5 bg-white mb-2 rounded-md'>
                        <div className='flex justify-between items-center p-5 border-b '>
                            <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('inventory_sheets_list')}</p>
                            {
                                (
                                    includes(['master_admin', 'office_manager'], user?.role)
                                    || userHasPermissions(['inventory-sheets-update'])
                                ) ? (
                                    <Tooltip disableInteractive title={t('create_inventory_sheet')} placement='bottom'>
                                        <div>
                                            <AddButton onClick={handleOpen}><i className="fa-solid fa-plus"></i></AddButton>
                                        </div>
                                    </Tooltip>
                                ) : null
                            }

                        </div>
                        <div className='flex justify-between items-end w-full pb-5 border-b'>
                            <div className='px-5 pt-5 w-full'>
                                <div className='flex justify-between items-center search'>
                                    <input type="text" placeholder={t('search_by_inventory')} value={search} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={handleSearch} />
                                    <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                </div>
                            </div>
                            <div className='px-5 pl-0 pt-5 w-full '>
                                <FormControl sx={{ width: 'inherit' }}>
                                    <InputLabel id="demo-simple-select-standard-label">{t('search_by_period')}</InputLabel>
                                    <Select
                                        defaultValue=''
                                        value={searchPeriod}
                                        onChange={handleChangePeriod}
                                        label="Search role"
                                        sx={{
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                            ".MuiSelect-iconOutlined": { display: searchPeriod ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                        }}
                                        endAdornment={searchPeriod ? (<IconButton sx={{ visibility: searchPeriod ? "visible" : "hidden", padding: '0' }} onClick={handleClearPeriod}><ClearIcon /></IconButton>) : false}
                                    >
                                        <MenuItem value="day">{t('day')}</MenuItem>
                                        <MenuItem value="week">{t('week')}</MenuItem>
                                        <MenuItem value="month">{t('month')}</MenuItem>
                                        <MenuItem value="quarter">{t('quarter')}</MenuItem>
                                        <MenuItem value="year">{t('year')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='px-5 pt-5 w-full'>
                                <FormControl sx={{ width: 'inherit' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker format="YYYY-MM-DD" value={valueStartDate} onChange={(newValue) => setValueStartDate(newValue != null ? dayjs(newValue).format('YYYY-MM-DD') : null)}
                                            sx={{ width: '100%', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px', }}
                                            label={t('search_by_start')}
                                            slotProps={{
                                                actionBar: {
                                                    actions: ['accept', 'clear']
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </div>
                            <div className='px-5 pt-5 w-full'>
                                <FormControl sx={{ width: 'inherit' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker format="YYYY-MM-DD" value={valueEndDate} onChange={(newValue) => setValueEndDate(newValue != null ? dayjs(newValue).format('YYYY-MM-DD') : null)}
                                            sx={{ width: '100%', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px' }}
                                            label={t('search_by_end')}
                                            slotProps={{
                                                actionBar: {
                                                    actions: ['accept', 'clear']
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </div>
                        </div>
                        <div className='pt-3'>
                            <ServerTable items={items} columns={columns} page={page} total={total} pageSize={pageSize} setPage={setPage} setPageSize={setPageSize} columnVisibilityModel={model} setModel={setModel} view='inventory_lists' />
                        </div>
                    </div>

                </div>
            </AppLayout>
        </>
    )
}

export default CountsList

const Actions = ({ params, navigate, setIsLoading, config, user }) => {

    const userHasPermissions = (targets = []) => {
        return user?.permissions.some((permission) => targets.includes(permission.name))
    }

    const { t } = useTranslation()

    const deleteCounts = async (id) => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_inventory') + params.row.inventory_document_no + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        await axios.delete(`/api/inventories/${id}`, params.config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                text: data.success.message
            })
        }).catch(({ response }) => {
            handleAxiosError({response: response})
        })
    }

    const handleDuplicate = async () => {
        navigate('/enter-counts', { state: { data: params.row } })
    }

    return (
        <>
            <div className='flex justify-between'>
                {
                    (
                        includes(['master_admin', 'office_manager'], user?.role)
                        || userHasPermissions(['inventory-sheets-update'])
                    ) ? (
                        <Tooltip disableInteractive title={t('duplicate')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => handleDuplicate(params.row.id)}><i className="fa-solid fa-copy"></i></span>
                            </div>
                        </Tooltip>
                    ) : null
                }
                <Tooltip disableInteractive title={t('print')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <a target='_blank' href={process.env.REACT_APP_BACKEND_URL + `/api/inventories/${params.row.id}/inventory-sheet?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}>
                            <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-print"></i></span>
                        </a>
                    </div>
                </Tooltip>
                {
                    (
                        params.row.status == 'approved'
                        || (
                            !includes(['master_admin', 'office_manager'], user?.role)
                            && !userHasPermissions(['inventory-sheets-update'])
                        )
                    ) ?
                        <Tooltip disableInteractive title={t('open')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <Link to={`/enter-counts/${params.row.id}`}>
                                    <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200">
                                        <i className="fa-solid fa-eye"></i>
                                    </span>
                                </Link>
                            </div>
                        </Tooltip>
                        :
                        <Tooltip disableInteractive title={t('edit')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <Link to={`/enter-counts/${params.row.id}`}>
                                    <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200">
                                        <i className="fa-solid fa-pencil"></i>
                                    </span>
                                </Link>
                            </div>
                        </Tooltip>
                }
                {
                    (
                        includes(['master_admin', 'office_manager'], user?.role)
                        || userHasPermissions(['inventory-sheets-update'])
                    ) ? (
                        <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteCounts(params.row.id)}><i className="fa-solid fa-trash"></i></span>
                            </div>
                        </Tooltip>
                    ) : null
                }
            </div>
        </>
    )
}
