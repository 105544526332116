import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, ListItemText, Checkbox, ListItemIcon, Chip, FormHelperText, Autocomplete } from '@mui/material'
import Swal from 'sweetalert2'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"

import Loading from '../../components/Loading'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import { useAuth } from "../../hooks/auth";
import { handleAxiosError, handleDeleteSelectionObjectPlus, isValid } from '../../helpers/helper'
import { includes, isNull } from 'lodash'

const UpdateInsightsUser = () => {
    const { user } = useAuth({ middleware: 'guest ' })

    const { config, pusher, choosesite, setChoosesite } = useStateContext()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingContacts, setIsLoadingContacts] = useState(true)
    const params = useParams()
    const navigate = useNavigate()


    const [shipToLocations, setShipToLocations] = useState([])
    const [vendors, setVendors] = useState([])
    const [purchaseLocations, setPurchaseLocations] = useState([])
    const [contacts, setContacts] = useState([])
    const [customerId, setCustomerId] = useState('')

    /* Pusher Channels (those that need to be bound and then rebound) */
    const [channelDeleteVendor, setChannelDeleteVendor] = useState(null)
    const [channelUpdateVendor, setChannelUpdateVendor] = useState(null)
    const [channelCreateVendor, setChannelCreateVendor] = useState(null)
    const [channelDeletePurchaseAddress, setChannelDeletePurchaseAddress] = useState(null)
    const [channelUpdatePurchaseAddress, setChannelUpdatePurchaseAddress] = useState(null)
    const [channelCreatePurchaseAddress, setChannelCreatePurchaseAddress] = useState(null)
    const [channelDeleteShipAddress, setChannelDeleteShipAddress] = useState(null)
    const [channelUpdateShipAddress, setChannelUpdateShipAddress] = useState(null)
    const [channelCreateShipAddress, setChannelCreateShipAddress] = useState(null)

    /* Form */
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [shipToLocation, setShipToLocation] = useState([])
    const [shipToLocationId, setShipToLocationId] = useState([])
    const [errorShipToLocation, setErrorShipToLocation] = useState(false)
    const [vendor, setVendor] = useState('')
    const [vendorName, setVendorName] = useState('')
    const [purchaseLocation, setPurchaseLocation] = useState([])
    const [purchaseLocationId, setPurchaseLocationId] = useState([])
    const [errorPurchaseLocation, setErrorPurchaseLocation] = useState(false)
    const [accountManager, setAccountManager] = useState({})
    const [accountManagerId, setAccountManagerId] = useState('')
    const [accountingContact, setAccountingContact] = useState({})
    const [accountingContactId, setAccountingContactId] = useState('')
    const [customerService, setCustomerService] = useState({})
    const [customerServiceId, setCustomerServiceId] = useState('')
    const [operationsManager, setOperationsManager] = useState({})
    const [operationsManagerId, setOperationsManagerId] = useState('')
    const [companyId, setCompanyId] = useState('')
    const [siteId, setSiteId] = useState('')

    const [openShipToLocations, setOpenShipToLocations] = useState(false)
    const [openPurchaseLocations, setOpenPurchaseLocations] = useState(false)

    useEffect(() => {
        setChoosesite(localStorage.getItem('site'))
        getInsightsUser()

        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-insightsuser-deleted`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-insightsuser-updated`)

        const channeldeleteShipAddress = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-deleted`)
        const channelcreateShipAddress = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-created`)
        const channelupdateShipAddress = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)

        const channeldeleteVendor = pusher.subscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)
        const channelcreateVendor = pusher.subscribe(`${localStorage.getItem('client_id')}-vendor-created`)
        const channelupdateVendor = pusher.subscribe(`${localStorage.getItem('client_id')}-vendor-updated`)

        const channeldeletePurchaseAddress = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)
        const channelcreatePurchaseAddress = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-created`)
        const channelupdatePurchaseAddress = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)

        setChannelDeleteVendor(channeldeleteVendor)
        setChannelUpdateVendor(channelupdateVendor)
        setChannelCreateVendor(channelcreateVendor)
        setChannelDeletePurchaseAddress(channeldeletePurchaseAddress)
        setChannelUpdatePurchaseAddress(channelupdatePurchaseAddress)
        setChannelCreatePurchaseAddress(channelcreatePurchaseAddress)
        setChannelDeleteShipAddress(channeldeleteShipAddress)
        setChannelUpdateShipAddress(channelupdateShipAddress)
        setChannelCreateShipAddress(channelcreateShipAddress)

        channeldelete.bind(`${localStorage.getItem('client_id')}-insightsuser-deleted-event`, data => {
            if(Number(data.id) == Number(params.id)) {
                navigate(`/customers/${data.customer_id}?tab=customer-users`)
            }
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-insightsuser-updated-event`, data => {
            if(Number(data.id) == Number(params.id)) {
                getInsightsUser()
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-insightsuser-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-insightsuser-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-customer-deleted`)
        })
    }, [])

    {/* Apparently there is a risk where you may incorrectly bind events. In our case, outdated props are used. */}
    useEffect(() => {
        {/* Ship To Address events */}
        if(channelDeleteShipAddress && channelDeleteShipAddress.bind) {
            channelDeleteShipAddress.unbind(`${localStorage.getItem('client_id')}-shipaddress-deleted-event`)
            channelDeleteShipAddress.bind(`${localStorage.getItem('client_id')}-shipaddress-deleted-event`, data => {
                if(Number(customerId) == Number(data.customer_id)) {
                    handleClearShipTo()
                    getShipToLocations(customerId)
                }
            })
        }

        if(channelCreateShipAddress && channelCreateShipAddress.bind) {
            channelCreateShipAddress.unbind(`${localStorage.getItem('client_id')}-shipaddress-created-event`)
            channelCreateShipAddress.bind(`${localStorage.getItem('client_id')}-shipaddress-created-event`, data => {
                if(Number(customerId) == Number(data.customer_id)) {
                    getShipToLocations(customerId)
                }
            })
        }

        if(channelUpdateShipAddress && channelUpdateShipAddress.bind) {
            channelUpdateShipAddress.unbind(`${localStorage.getItem('client_id')}-shipaddress-updated-event`)
            channelUpdateShipAddress.bind(`${localStorage.getItem('client_id')}-shipaddress-updated-event`, data => {
                if(Number(customerId) == Number(data.customer_id)) {
                    handleClearShipTo()
                    getShipToLocations(customerId)
                }
            })
        }

        {/* Vendor events */}
        if(channelDeleteVendor && channelDeleteVendor.bind) {
            channelDeleteVendor.unbind(`${localStorage.getItem('client_id')}-vendor-deleted-event`)
            channelDeleteVendor.bind(`${localStorage.getItem('client_id')}-vendor-deleted-event`, data => {
                if(Number(companyId) == Number(data.company_id)) {
                    if(Number(vendor) == Number(data.id)) handleClearVendor();
                    getVendors(companyId)
                }
            })
        }

        if(channelCreateVendor && channelCreateVendor.bind) {
            channelCreateVendor.unbind(`${localStorage.getItem('client_id')}-vendor-created-event`)
            channelCreateVendor.bind(`${localStorage.getItem('client_id')}-vendor-created-event`, data => {
                if(Number(companyId) == Number(data.company_id)) {
                    getVendors(companyId)
                }
            })
        }

        if(channelUpdateVendor && channelUpdateVendor.bind) {
            channelUpdateVendor.unbind(`${localStorage.getItem('client_id')}-vendor-updated-event`)
            channelUpdateVendor.bind(`${localStorage.getItem('client_id')}-vendor-updated-event`, data => {
                if(Number(companyId) == Number(data.company_id)) {
                    if(Number(vendor) == Number(data.id)) handleClearVendor();
                    getVendors(companyId)
                }
            })
        }

        {/* Purchase Address events */}
        if(channelDeletePurchaseAddress && channelDeletePurchaseAddress.bind) {
            channelDeletePurchaseAddress.unbind(`${localStorage.getItem('client_id')}-purchaseaddress-deleted-event`)
            channelDeletePurchaseAddress.bind(`${localStorage.getItem('client_id')}-purchaseaddress-deleted-event`, data => {
                if(Number(vendor) == Number(data.vendor_id)) {
                    handleClearPurchase()
                    getPurchaseLocations(vendor)
                }
            })
        }

        if(channelCreatePurchaseAddress && channelCreatePurchaseAddress.bind) {
            channelCreatePurchaseAddress.unbind(`${localStorage.getItem('client_id')}-purchaseaddress-created-event`)
            channelCreatePurchaseAddress.bind(`${localStorage.getItem('client_id')}-purchaseaddress-created-event`, data => {
                if(Number(vendor) == Number(data.vendor_id)) {
                    getPurchaseLocations(vendor)
                }
            })
        }

        if(channelUpdatePurchaseAddress && channelUpdatePurchaseAddress.bind) {
            channelUpdatePurchaseAddress.unbind(`${localStorage.getItem('client_id')}-purchaseaddress-updated-event`)
            channelUpdatePurchaseAddress.bind(`${localStorage.getItem('client_id')}-purchaseaddress-updated-event`, data => {
                if(Number(vendor) == Number(data.vendor_id)) {
                    handleClearPurchase()
                    getPurchaseLocations(vendor)
                }
            })
        }
    }, [companyId, customerId, vendor])

    useEffect(() => {
        if(isValid(customerId)) {
            const channelCustomerDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-customer-deleted`)

            channelCustomerDeleted.bind(`${localStorage.getItem('client_id')}-customer-deleted-event`, data => {
                if(Number(data.id) == Number(customerId)) {
                    navigate('/customers')
                }
            })
        }

        return (() => {
            if(isValid(customerId)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-customer-deleted`)
            }
        })
    }, [customerId])

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate(-1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    const isAllSelectedShipTo = shipToLocations.length > 0 && shipToLocation.length === shipToLocations.length
    const isAllSelectedPurchase = purchaseLocations.length > 0 && purchaseLocation.length === purchaseLocations.length


    useEffect(() => {
        if (customerId) {
            getShipToLocations(customerId)
        }
    }, [customerId])

    useEffect(() => {
        if (companyId) {
            getVendors(companyId)
        }
    }, [companyId])

    useEffect(() => {
        if (vendor) {
            getPurchaseLocations(vendor)
        }
    }, [vendor])


    const getInsightsUser = async (id = null) => {
        setIsLoading(true)

        await axios.get(`/api/insights-users/${isNull(id) ? params.id : id}`, config)
            .then(res => {
                const user = res.data
                setName(user.name)
                setEmail(user.email)
                setShipToLocation(user.ship_to_locations_info || [])
                setShipToLocationId(user.ship_to_locations_info?.map((shipToLocation) => shipToLocation.id))
                setVendor(user.vendor_id || '')
                setVendorName(user.vendor_name || '')
                setPurchaseLocation(user.purchase_locations_info || [])
                setPurchaseLocationId(user.purchase_locations_info?.map((purchaseLocation) => purchaseLocation.id))
                setAccountManager(user.account_manager_info || {})
                setAccountManagerId(user.account_manager_info?.id || '')
                setAccountingContact(user.accounting_contact_info || {})
                setAccountingContactId(user.accounting_contact_info?.id || '')
                setCustomerService(user.customer_service_contact_info || {})
                setCustomerServiceId(user.customer_service_contact_info?.id || '')
                setOperationsManager(user.operations_manager_contact_info || {})
                setOperationsManagerId(user.operations_manager_contact_info?.id || '')
                setCustomerId(user.customer_id)
                setSiteId(user.site_id)
                setCompanyId(user.company_id)

                return getContacts(user.site_id)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getShipToLocations = async (customerId) => {
        setIsLoading(true)
        await axios.get(`/api/ship-addresses?customer_id=${customerId}`, config)
            .then(res => {
                const users = res.data
                setShipToLocations(users)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getVendors = async (companyId) => {
        setIsLoading(true)
        await axios.get(`/api/vendors?company_id=${companyId}`, config)
            .then(res => {
                const users = res.data
                setVendors(users)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseLocations = async (vendorId) => {
        setIsLoading(true)
        await axios.get(`/api/purchase-addresses?vendor_id=${vendorId}`, config)
            .then(res => {
                const users = res.data
                setPurchaseLocations(users)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getContacts = async (siteId) => {
        setIsLoading(true)
        setIsLoadingContacts(true)
        await axios.get(`api/insights-contacts?site_id=${siteId}`, config)
            .then(res => {
                const users = res.data
                setContacts(users)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoadingContacts(false)
                setIsLoading(false)
            })
    }


    const handleShipToLocation = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = [];

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
                duplicateRemoved.push(item);
            }
        })

        let ids = []

        value.forEach((item) => {
            if (ids.findIndex((o) => o.id === item.id) >= 0) {
                ids = ids.filter((x) => x.id === item.id);
            } else {
                ids.push(item.id);
            }
        })

        if (value[value.length - 1] === "all") {
            setShipToLocation(shipToLocation.length === shipToLocations.length ? [] : shipToLocations);
            setShipToLocationId(shipToLocation.length === shipToLocations.length ? [] : shipToLocations.map(i => i.id))
            setErrorShipToLocation(false)
            return;
        }
        setShipToLocation(duplicateRemoved)
        setShipToLocationId(ids)
        setErrorShipToLocation(false)
    }

    const handleClearShipTo = () => {
        setShipToLocation([])
        setShipToLocationId([])
    }

    const handlePurchaseLocation = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = [];

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
                duplicateRemoved.push(item);
            }
        })

        let ids = []

        value.forEach((item) => {
            if (ids.findIndex((o) => o.id === item.id) >= 0) {
                ids = ids.filter((x) => x.id === item.id);
            } else {
                ids.push(item.id);
            }
        })

        if (value[value.length - 1] === "all") {
            setPurchaseLocation(purchaseLocation.length === purchaseLocations.length ? [] : purchaseLocations);
            setPurchaseLocationId(purchaseLocation.length === purchaseLocations.length ? [] : purchaseLocations.map(i => i.id))
            setErrorPurchaseLocation(false)
            return;
        }
        setPurchaseLocation(duplicateRemoved)
        setPurchaseLocationId(ids)
        setErrorPurchaseLocation(false)
    }


    const handleClearPurchase = () => {
        setPurchaseLocation([])
        setPurchaseLocationId([])
    }

    const handleClearVendor = () => {
        setVendor('')
        setVendorName('')
        setPurchaseLocation([])
        setPurchaseLocationId([])
    }

    const updateInsightsUser = async (e) => {
        e.preventDefault();

        if (shipToLocationId.length < 1) {
            setErrorShipToLocation(true)
        } else if (vendor && purchaseLocationId.length < 1) {
            setErrorPurchaseLocation(true)
        } else {
            setIsLoading(true)
            const formData = {}

            formData['name'] = name
            formData['email'] = email
            formData['ship_to_locations'] = shipToLocationId
            formData['vendor_id'] = vendor
            formData['purchase_locations'] = purchaseLocationId
            formData['account_manager_id'] = accountManagerId
            formData['accounting_contact_id'] = accountingContactId
            formData['customer_service_contact_id'] = customerServiceId
            formData['operations_manager_contact_id'] = operationsManagerId
            formData['company_id'] = companyId
            formData['site_id'] = siteId
            formData['customer_id'] = customerId

            await axios.put(`/api/insights-users/${params.id}`, formData, config)
                .then(({ data }) => {
                    Swal.fire({
                        icon: "success",
                        customClass: 'success',
                        showCloseButton: true,
                        iconColor: '#00B78E',
                        text: data.success.message
                    })

                })
                .catch(({ response }) => {
                    handleAxiosError({response: response})
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const vendorOptions = vendors?.map(option => ({ label: option.name, id: option.id }))

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box>
                <div className='flex justify-between items-center p-5 pr-0 pt-0 pb-0' style={{ backgroundColor: '#336195', borderRadius: '5px 5px 0 0' }}>
                    <div className='flex gap-4 items-baseline'>
                        <div style={{ transform: "rotate(45deg)" }} className="font-semibold text-white">
                            <Link to={`/customers/${customerId}?tab=customer-users`}><i className="fa-solid fa-plus"></i></Link>
                        </div>
                        <p className='text-xl roboto font-semibold text-white'>{t('update_customers_user')} - {name}</p>
                    </div>
                    <button type="button" onClick={updateInsightsUser} className='text-white px-4 py-6 uppercase self-end roboto bg-zinc-900 hover:bg-zinc-700 disabled:bg-zinc-400'>
                        {t('edit')}
                    </button>
                </div>

                <div className='p-5'>
                    <form className='flex justify-center flex-col items-start mt-2'>

                        <TextField type="text" variant='standard' label={t('name')} sx={{ marginBottom: '20px' }} className='w-full mb-5 px-0 pt-0' name="name" value={name} onChange={(e) => { setName(e.target.value) }} required />

                        <TextField type="text" variant='standard' label={t('email')} sx={{ marginBottom: '20px' }} className='w-full mb-5 px-0 pt-0' name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} required />

                        <FormControl error={errorShipToLocation} variant="standard" sx={{ width: '100%', marginBottom: '20px' }} focused={openShipToLocations}>
                            <InputLabel>{t('ship_to')} *</InputLabel>
                            <Select
                                multiple
                                value={shipToLocation}
                                open={openShipToLocations}
                                onOpen={() => setOpenShipToLocations(true)}
                                onClose={() => setOpenShipToLocations(false)}
                                onChange={handleShipToLocation}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((x) => (
                                            <Chip
                                                key={`stl_option-${x.id}`}
                                                label={x.name}
                                                onMouseDown={(e) => e.stopPropagation()}
                                                onDelete={(e) => handleDeleteSelectionObjectPlus(e, x.id, shipToLocation, setShipToLocation, setShipToLocationId, setOpenShipToLocations)}
                                            />
                                        ))}
                                    </Box>
                                )}
                                sx={{ ".MuiSelect-iconStandard": { display: (shipToLocation.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' } }}
                                endAdornment={shipToLocation ? (<IconButton sx={{ visibility: (shipToLocation.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearShipTo}><ClearIcon /></IconButton>) : false}
                            >
                                {
                                    shipToLocations?.length > 0 ?
                                        <MenuItem value="all">
                                            <ListItemIcon>
                                                <Checkbox checked={isAllSelectedShipTo} indeterminate={shipToLocation.length > 0 && shipToLocation.length < shipToLocations.length} />
                                            </ListItemIcon>
                                            <ListItemText primary={t('select_all')} />
                                        </MenuItem>
                                        :
                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                }
                                {
                                    shipToLocations.map((item) =>
                                        <MenuItem value={item} key={item.id}>
                                            <Checkbox checked={shipToLocation?.findIndex((i) => i.id === item.id) >= 0} />
                                            <ListItemText primary={item.name} />
                                        </MenuItem>
                                    )
                                }
                            </Select>

                            {errorShipToLocation ? (
                                <FormHelperText>{t('please_choose_at_least_x_y', {
                                    x: 1,
                                    y: t('ship_to_location')
                                })}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>

                        {/* Vendor */}
                        <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }}>
                            <Autocomplete
                                disablePortal
                                id="vendor-multiselect-box"
                                options={vendorOptions}
                                value={vendor ? { label: vendorName ?? '', id: vendor } : null}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                sx={{
                                    width: '100%',
                                    background: 'transparent',
                                    '.MuiOutlinedInput-input': {
                                        padding: '0 !important'
                                    },
                                    boxShadow: 'none',
                                    '.MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #EEEFF2 !important'
                                    }
                                }}
                                onChange={(a, b) => { setVendor(b?.id || ''); setVendorName(b?.label || ''); setPurchaseLocation([]); setPurchaseLocationId([]) }}
                                renderInput={(params) => <TextField {...params} variant="standard" label={t('vendor')} />}
                                renderOption={(props, option) => (
                                    <li {...props} key={`vendor-${option.id}`}>
                                        {option.label}
                                    </li>
                                )}
                            />
                        </FormControl>

                        <FormControl error={errorPurchaseLocation} variant="standard" sx={{ width: '100%', marginBottom: '20px' }} focused={openPurchaseLocations}>
                            <InputLabel>{t('purchase_addresses')}</InputLabel>
                            <Select
                                multiple
                                disabled={!vendor}
                                value={purchaseLocation}
                                open={openPurchaseLocations}
                                onOpen={() => setOpenPurchaseLocations(true)}
                                onClose={() => setOpenPurchaseLocations(false)}
                                onChange={handlePurchaseLocation}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((x) => (
                                            <Chip
                                                key={`stl_option-${x.id}`}
                                                label={x.name}
                                                onMouseDown={(e) => e.stopPropagation()}
                                                onDelete={(e) => handleDeleteSelectionObjectPlus(e, x.id, purchaseLocation, setPurchaseLocation, setPurchaseLocationId, setOpenPurchaseLocations)}
                                            />
                                        ))}
                                    </Box>
                                )}
                                sx={{ ".MuiSelect-iconStandard": { display: (purchaseLocation.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' } }}
                                endAdornment={purchaseLocation ? (<IconButton sx={{ visibility: (purchaseLocation.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearPurchase}><ClearIcon /></IconButton>) : false}
                            >
                                {
                                    purchaseLocations?.length > 0 ?
                                        <MenuItem value="all">
                                            <ListItemIcon>
                                                <Checkbox checked={isAllSelectedPurchase} indeterminate={purchaseLocation.length > 0 && purchaseLocation.length < purchaseLocations.length} />
                                            </ListItemIcon>
                                            <ListItemText primary={t('select_all')} />
                                        </MenuItem>
                                        :
                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                }
                                {
                                    purchaseLocations.map((item) =>
                                        <MenuItem value={item} key={item.id}>
                                            <Checkbox checked={purchaseLocation?.findIndex((i) => i.id === item.id) >= 0} />
                                            <ListItemText primary={item.name} />
                                        </MenuItem>
                                    )
                                }
                            </Select>

                            {errorPurchaseLocation ? (
                                <FormHelperText>{t('please_choose_at_least_x_y', {
                                    x: 1,
                                    y: t('purchase_location')
                                })}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>

                        <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }}>
                            <InputLabel id="demo-simple-select-standard-label">{t('account_manager')}</InputLabel>
                            <Select
                                value={!isLoadingContacts ? accountManagerId ?? '' : ''}
                                onChange={(e) => { setAccountManagerId(e.target.value) }}
                                sx={{
                                    ".MuiSelect-iconStandard": {
                                        display: accountManagerId ? 'none !important' : ''
                                    },
                                    "&.Mui-focused .MuiIconButton-root": {
                                        color: 'rgba(0,0,0,.42)'
                                    }
                                }}
                                endAdornment={accountManagerId ? (<IconButton sx={{ visibility: accountManagerId ? "visible" : "hidden", padding: '0' }} onClick={e => setAccountManagerId('')}><ClearIcon /></IconButton>) : false}
                            >
                                {
                                    (accountManager?.id && !includes(contacts.map((c) => c.id), accountManager?.id)) ? (
                                        <MenuItem disabled value={accountManager.id}>{accountManager.name}</MenuItem>
                                    ) : null
                                }
                                {
                                    contacts.filter((i) => i.role === 9).map((item) =>
                                        <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>

                        <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }}>
                            <InputLabel id="demo-simple-select-standard-label">{t('accounting_contact')}</InputLabel>
                            <Select
                                value={!isLoadingContacts ? accountingContactId ?? '' : ''}
                                onChange={(e) => { setAccountingContactId(e.target.value) }}
                                sx={{
                                    ".MuiSelect-iconStandard": {
                                        display: accountingContactId ? 'none !important' : ''
                                    },
                                    "&.Mui-focused .MuiIconButton-root": {
                                        color: 'rgba(0,0,0,.42)'
                                    }
                                }}
                                endAdornment={accountingContactId ? (<IconButton sx={{ visibility: accountingContactId ? "visible" : "hidden", padding: '0' }} onClick={e => setAccountingContactId('')}><ClearIcon /></IconButton>) : false}
                            >
                                {
                                    (accountingContact?.id && !includes(contacts.map((c) => c.id), accountingContact?.id)) ? (
                                        <MenuItem disabled value={accountingContact.id}>{accountingContact.name}</MenuItem>
                                    ) : null
                                }
                                {
                                    contacts.filter((i) => i.role === 10).map((item) =>
                                        <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>

                        <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }}>
                            <InputLabel id="demo-simple-select-standard-label">{t('customer_service')}</InputLabel>
                            <Select
                                value={!isLoadingContacts ? customerServiceId ?? '' : ''}
                                onChange={(e) => { setCustomerServiceId(e.target.value) }}
                                sx={{
                                    ".MuiSelect-iconStandard": {
                                        display: customerServiceId ? 'none !important' : ''
                                    },
                                    "&.Mui-focused .MuiIconButton-root": {
                                        color: 'rgba(0,0,0,.42)' }
                                }}
                                endAdornment={customerServiceId ? (<IconButton sx={{ visibility: customerServiceId ? "visible" : "hidden", padding: '0' }} onClick={e => setCustomerServiceId('')}><ClearIcon /></IconButton>) : false}
                            >
                                {
                                    (customerService?.id && !includes(contacts.map((c) => c.id), customerService?.id)) ? (
                                        <MenuItem disabled value={customerService.id}>{customerService.name}</MenuItem>
                                    ) : null
                                }
                                {
                                    contacts.filter((i) => i.role === 11).map((item) =>
                                        <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>

                        <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }}>
                            <InputLabel id="demo-simple-select-standard-label">{t('operations_manager')}</InputLabel>
                            <Select
                                value={!isLoadingContacts ? operationsManagerId ?? '' : ''}
                                onChange={(e) => { setOperationsManagerId(e.target.value) }}
                                sx={{
                                    ".MuiSelect-iconStandard": {
                                        display: operationsManagerId ? 'none !important' : ''
                                    },
                                    "&.Mui-focused .MuiIconButton-root": {
                                        color: 'rgba(0,0,0,.42)' }
                                }}
                                endAdornment={operationsManagerId ? (<IconButton sx={{ visibility: operationsManagerId ? "visible" : "hidden", padding: '0' }} onClick={e => setOperationsManagerId('')}><ClearIcon /></IconButton>) : false}
                            >
                                {
                                    (operationsManager?.id && !includes(contacts.map((c) => c.id), operationsManager?.id)) ? (
                                        <MenuItem disabled value={operationsManager.id}>{operationsManager.name}</MenuItem>
                                    ) : null
                                }
                                {
                                    contacts.filter((i) => i.role === 12).map((item) =>
                                        <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>

                    </form>
                </div>
            </Box>
        </>
    )
}

export default UpdateInsightsUser
