import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import AppLayout from '../../components/Layouts/AppLayout'
import axios from '../../lib/axios'
import AssignedShipments from '../../components/forklifter/AssignedShipments'
import AssignedCollections from '../../components/forklifter/AssignedCollections'
import update from 'immutability-helper';
import Loading from '../../components/Loading'
import { isValid } from '../../helpers/helper'

const ForkliftDashboard = ({
    userId,
    siteId,
    config,
    pusher,
    choosesite,
    setOpenLatestMessage,
}) => {
    const { t } = useTranslation()

    const [shipments, setShipments] = useState([])
    const [collections, setCollections] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if(isValid(siteId)) {
            const shipmentChannelDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${siteId}`)
            const shipmentChannelUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${siteId}`)

            const collectionChannelDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${siteId}`)
            const collectionChannelUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${siteId}`)

            // Bind shipment channel pushes

            shipmentChannelDelete.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${siteId}`, data => {
                setShipments((prev) => {
                    const deletedShipment = prev.find((i) => i.id === data.id)
                    const shipmentsUpdated = deletedShipment ? prev.filter((i) => i.id !== deletedShipment.id) : prev
                    return shipmentsUpdated
                })
            })

            shipmentChannelUpdate.bind(`${localStorage.getItem('client_id')}-salesorder-updated-event-site-${siteId}`, data => {
                if (Number(data.current_forklifter) == Number(userId)) {
                    getShipment(data.id, 'updated', data?.message_localized)
                } else {
                    setShipments((prev) => {
                        const deletedShipment = prev.find((i) => i.id === data.id)
                        const shipmentsUpdated = deletedShipment ? prev.filter((i) => i.id !== deletedShipment.id) : prev
                        return shipmentsUpdated
                    })
                }
            })

            // Bind collection channel pushes

            collectionChannelDelete.bind(`${localStorage.getItem('client_id')}-purchaseorder-deleted-event-site-${siteId}`, data => {
                setCollections((prev) => {
                    const deletedCollection = prev.find((i) => i.id === data.id)
                    const collectionsUpdated = deletedCollection ? prev.filter((i) => i.id !== deletedCollection.id) : prev
                    return collectionsUpdated
                })
            })

            collectionChannelUpdate.bind(`${localStorage.getItem('client_id')}-purchaseorder-updated-event-site-${siteId}`, data => {
                if (Number(data.current_forklifter) === Number(userId)) {
                    getCollection(data.id, 'updated', data?.message_localized)
                } else {
                    setCollections((prev) => {
                        const deletedCollection = prev.find((i) => i.id === data.id)
                        const collectionsUpdated = deletedCollection ? prev.filter((i) => i.id !== deletedCollection.id) : prev
                        return collectionsUpdated
                    })
                }
            })
        }

        return (() => {
            if(isValid(siteId)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${siteId}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${siteId}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${siteId}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${siteId}`)
            }
        })
    }, [siteId])

    const getShipments = async () => {
        setIsLoading(true)
        await axios.get(`/api/forklift-driver/${userId}/list-shipments`, config)
            .then(res => {
                const data = res.data
                setShipments(data)
                setIsLoading(false)
            })
    }

    const getShipment = async (id, event, message) => {
        setIsLoading(false)
        await axios.get(`/api/sales-orders/${id}`, config)
            .then(res => {
                const shipment = res.data
                if (event === 'updated' && Number(shipment.forklift_driver_id) == Number(userId)) {
                    if (shipment.so_status !== "assigned_to_fork" && shipment.so_status !== "loading") {
                        setShipments((prev) => {
                            const updatedShipment = prev.find((i) => i.id === id)
                            if (updatedShipment !== undefined) {
                                const shipmentsUpdated = prev.filter((i) => i.id !== updatedShipment.id)
                                return shipmentsUpdated
                            }
                            return prev
                        })
                    } else {
                        if (shipment.so_status === "assigned_to_fork" || shipment.so_status === "loading") {
                            setShipments((prev) => {
                                const shipmentIndex = prev.findIndex((i) => i.id === id)

                                if (shipmentIndex > -1) {
                                    return update(prev, {
                                        [shipmentIndex]: { $set: shipment }
                                    })
                                } else {
                                    return update(prev,
                                        {
                                            $push: [shipment]
                                        }
                                    )
                                }
                            })
                        } else {
                            setShipments((prev) => {
                                const shipmentIndex = prev.findIndex((i) => i.id === id)

                                return update(prev, {
                                    [shipmentIndex]: { $set: shipment }
                                })
                            })
                        }
                    }
                    if (["assigned_to_fork", "loading"].includes(shipment.so_status)) {
                        sessionStorage.setItem('available_loads_latest_msg', message)
                        setOpenLatestMessage(true)
                    }
                }
                setIsLoading(false)
            })
            .catch(({ response }) => {
                // This accounts for cases when a Dispatcher reassigns a load to another Driver: checks if the reassigned load is shown in the state and filters it out if so
                setShipments((prev) => {
                    const updatedShipment = prev.find((i) => i.id === id)
                    if (updatedShipment !== undefined) {
                        const shipmentsUpdated = prev.filter((i) => i.id !== updatedShipment.id)
                        return shipmentsUpdated
                    }
                    return prev
                })
                setIsLoading(false)
            })
    }

    const getCollections = async () => {
        setIsLoading(true)
        await axios.get(`/api/forklift-driver/${userId}/list-collections`, config)
            .then(res => {
                const data = res.data
                setCollections(data)
                setIsLoading(false)
            })
    }

    const getCollection = async (id, event, message) => {
        setIsLoading(true)
        await axios.get(`/api/purchase-orders/${id}`, config)
            .then(res => {
                const collection = res.data
                if (event === 'updated' && Number(collection.forklift_driver_id) == Number(userId)) {
                    if (collection.po_status !== "assigned_to_fork" && collection.po_status !== "unloading") {
                        setCollections((prev) => {
                            const updatedCollection = prev.find((i) => i.id === id)
                            if (updatedCollection !== undefined) {
                                const collectionsUpdated = prev.filter((i) => i.id !== updatedCollection.id)
                                return collectionsUpdated
                            }
                            return prev
                        })
                    } else {
                        if (collection.po_status === "assigned_to_fork" || collection.po_status === "unloading") {

                            setCollections((prev) => {

                                const collectionIndex = prev.findIndex((i) => i.id === id)

                                if (collectionIndex > -1) {
                                    return update(prev, {
                                        [collectionIndex]: { $set: collection }
                                    })
                                } else {
                                    return update(prev,
                                        {
                                            $push: [collection]
                                        }
                                    )
                                }
                            })
                        } else {
                            setCollections((prev) => {
                                const collectionIndex = prev.findIndex((i) => i.id === id)

                                return update(prev, {
                                    [collectionIndex]: { $set: collection }
                                })
                            })
                        }
                    }
                    if (["assigned_to_fork", "unloading"].includes(collection.po_status)) {
                        sessionStorage.setItem('available_loads_latest_msg', message)
                        setOpenLatestMessage(true)
                    }
                }
                setIsLoading(false)
            })
            .catch(({ response }) => {
                // This accounts for cases when a Dispatcher reassigns a load to another Driver: checks if the reassigned load is shown in the state and filters it out if so
                setCollections((prev) => {
                    const updatedCollection = prev.find((i) => i.id === id)
                    if (updatedCollection !== undefined) {
                        const collectionsUpdated = prev.filter((i) => i.id !== updatedCollection.id)
                        return collectionsUpdated
                    }
                    return prev
                })
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (choosesite) {
            getShipments()
            getCollections()
        }
    }, [choosesite])

    return (
        <>
            {isLoading ? <Loading /> : null}
            <AppLayout>
                <div className='flex flex-col justify-between'>
                    <div className='p-5 w-full'>
                        <div className='bg-white mb-2 rounded-md'>
                            <div className='p-5 flex justify-between items-center border-b'>
                                <div className='flex justify-start gap-4 items-center'>
                                    <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('shipments')}</p>
                                </div>
                            </div>
                            <div className='pt-3'>
                                <AssignedShipments
                                    shipments={shipments ?? []}
                                    setOpenLatestMessage={setOpenLatestMessage}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='p-5 w-full'>
                        <div className='bg-white mb-2 rounded-md'>
                            <div className='p-5 flex justify-between items-center border-b'>
                                <div className='flex justify-start gap-4 items-center'>
                                    <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('collections')}</p>
                                </div>
                            </div>
                            <div className='pt-3'>
                                <AssignedCollections
                                    collections={collections ?? []}
                                    setOpenLatestMessage={setOpenLatestMessage}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </AppLayout>
        </>
    )
}

export default ForkliftDashboard
