import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Box, Button, FormControl, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import { Link, useNavigate, useParams } from "react-router-dom"
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { isEqual, includes } from "lodash"

import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import { useAuth } from '../../hooks/auth'
import Loading from '../../components/Loading'
import Period from '../../components/inventory/Period'
import DateRange from '../../components/inventory/DateRange'
import InventoryDocumentNoField from '../../components/inventory/InventoryDocumentNoField'
import CountDate from '../../components/inventory/CountDate'
import Items from '../../components/inventory/Items'
import AddButton from '../../components/AddButton'

const UpdateCount = () => {

    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const params = useParams()
    const { user } = useAuth({ middleware: 'guest' })
    const { config, pusher, allSites } = useStateContext()
    const navigate = useNavigate()
    const site = allSites?.find(i => localStorage.getItem('site') == i.id)
    const [count, setCount] = useState(10000)
    const [listOfItems, setListOfItems] = useState([])
    const [listOfUoM, setListOfUoM] = useState([])
    const [disabled, setDisabled] = useState(false)

    const userHasPermissions = (targets = []) => {
        return user?.permissions.some((permission) => targets.includes(permission.name))
    }

    const initialItems = [{
        "id": 9999,
        "item_no": '',
        "item_id": '',
        "description": '',
        "unit_price": '',
        "qty": '',
        "unit_of_measure_id": ''
    }]

    /* form */
    const [period, setPeriod] = useState('week')
    const [start, setStart] = useState(dayjs())
    const [end, setEnd] = useState(dayjs().add(7, "day"))
    const [documentNo, setDocumentNo] = useState('')
    const [countDate, setCountDate] = useState(dayjs())
    const [items, setItems] = useState(initialItems)
    const [testSubject, setTestSubject] = useState([])

    const [status, setStatus] = useState('')

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate(-1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    useEffect(() => {

        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-inventory-deleted`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-inventory-created`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-inventory-updated`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-inventory-deleted-event`, data => {
            navigate(-1)
        })

        channelcreate.bind(`${localStorage.getItem('client_id')}-inventory-created-event`, data => {
            getCountSheet()
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-inventory-updated-event`, data => {
            getCountSheet()
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-inventory-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-inventory-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-inventory-updated`)
        })
    }, [])

    /* hooks */
    useEffect(() => {
        getItems()
        getCountSheetAlone()
        getCountSheet()
        getUoM()
    }, [])


    /* api request */
    const getCountSheet = async () => {
        setIsLoading(true)
        await axios.get(`/api/inventories/${params.id}?with=inventoryItems`, config)
            .then(res => {
                const i = res.data
                setPeriod(i?.period)
                setStart(dayjs(i?.start_date))
                setEnd(dayjs(i?.end_date))
                setDocumentNo(i?.inventory_document_no)
                setCountDate(dayjs(i?.count_date))
                setItems(i?.inventory_items)
                setStatus(i?.status)
                if (
                    Boolean(i?.approved_date)
                    || (
                        !includes(['master_admin', 'office_manager'], user?.role)
                        && !userHasPermissions(['inventory-sheets-update'])
                    )
                ) {
                    setDisabled(true)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getCountSheetAlone = async () => {
        setIsLoading(true)
        await axios.get(`/api/inventories/${params.id}?with=inventoryItems`, config)
            .then(res => {
                const i = res.data
                setTestSubject(i)
            })
    }

    const getItems = async () => {
        setIsLoading(true)
        await axios.get(`/api/items?blocked=0&inventory_item=1`, config)
            .then(res => {
                const items = res.data
                setListOfItems(items)
                setIsLoading(false)
            })
    }

    const getUoM = async () => {
        setIsLoading(true)
        await axios.get(`/api/units-of-measure`, config)
            .then(res => {
                const uom = res.data
                setListOfUoM(uom)
                setIsLoading(false)
            })
    }

    /* methods */
    const handleChangePeriod = (e) => {
        setPeriod(e.target.value)
        if (e.target.value == 'day') setEnd(start)
        if (e.target.value == 'week') setEnd(start.add(7, "day"))
        if (e.target.value == 'month') setEnd(start.add(1, "month"))
        if (e.target.value == 'quarter') setEnd(start.add(3, "month"))
        if (e.target.value == 'year') setEnd(start.add(1, "year"))
    }

    const handleStart = (newValue) => {
        setStart(newValue)
        if (period == 'day') setEnd(newValue)
        if (period == 'week') setEnd(newValue.add(7, "day"))
        if (period == 'month') setEnd(newValue.add(1, "month"))
        if (period == 'quarter') setEnd(newValue.add(3, "month"))
        if (period == 'year') setEnd(newValue.add(1, "year"))
    }

    const handleEnd = (newValue) => {
        setEnd(newValue)
    }

    const handleCountDate = (newValue) => {
        setCountDate(newValue)
    }

    const handleAddItem = () => {

        let counter = 0
        let msgItem = ''
        let msgQty = ''
        let msgBoth = t('please_item_qty')
        items.forEach(element => {
            if (element.item_no == '') {
                counter += 1
                msgItem = t('please_select_item')
            }
            if (element.qty == '') {
                counter += 1
                msgQty = t('please_add_qty')
            }
        })

        if (counter > 0) {
            if (msgItem !== '' && msgQty === '') {
                Swal.fire({
                    icon: "warning",
                    text: msgItem,
                    customClass: 'warning2',
                    showCloseButton: true,
                    iconColor: '#FFCA28'
                })
            }
            if (msgQty !== '' && msgItem === '') {
                Swal.fire({
                    icon: "warning",
                    text: msgQty,
                    customClass: 'warning2',
                    showCloseButton: true,
                    iconColor: '#FFCA28'
                })
            }
            if (msgQty !== '' && msgItem !== '') {
                Swal.fire({
                    icon: "warning",
                    text: msgBoth,
                    customClass: 'warning2',
                    showCloseButton: true,
                    iconColor: '#FFCA28'
                })
            }


            return
        }

        const item = {
            "id": count,
            "item_id": '',
            "item_no": '',
            "description": '',
            "unit_price": '',
            "qty": '',
            "unit_of_measure_id": ''
        }

        setItems((prev) => [...prev, item])
        setCount(prev => prev + 1)
    }

    const handleSelectItem = (value, id) => {
        const itemsCopy = [...items]
        const item = itemsCopy.find(item => item.id === id)

        item.item_id = value.id
        item.item_no = value.item_no
        item.description = value.description
        item.unit_price = value.unit_price
        item.unit_of_measure_id = value.unit_of_measure_id

        setItems(itemsCopy)
    }


    const calculateTotalQuantity = () => {
        let total = 0
        items.forEach(element => {
            if (element.qty !== '') total += parseInt(element.qty)
        })
        return total
    }

    const handleQty = (value, id) => {
        const itemsCopy = [...items]
        const item = itemsCopy.find(item => item.id === id)

        item.qty = value

        setItems(itemsCopy)
    }

    /* const handleSelectUoM = (value, id) => {
        const itemsCopy = [...items]
        const item = itemsCopy.find(item => item.id === id)

        item.unit_of_measure_id = value.unit_of_measure_id

        setItems(itemsCopy)
        console.log(itemsCopy);
    } */

    const approveSheet = async (e) => {

        e.preventDefault();
        setIsLoading(true)
        const formData = {}

        formData['period'] = period
        formData['start_date'] = dayjs(start).format('YYYY-MM-DD')
        formData['end_date'] = dayjs(end).format('YYYY-MM-DD')
        formData['count_date'] = dayjs(countDate).format('YYYY-MM-DD')
        formData['inventory_document_no'] = documentNo
        formData['inventory_items'] = items
        formData['user_id'] = user.id
        formData['site_id'] = localStorage.getItem('site')
        formData['company_id'] = localStorage.getItem('company_id')

        await axios.put(`/api/inventories/${params.id}/approve`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })

            setIsLoading(false)
            navigate('/inventory-sheets')
        }).catch(({ response }) => {
            Swal.fire({
                text: response.data.message,
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
            setIsLoading(false)
        })
    }

    const updateSheet = async (e) => {

        e.preventDefault();
        setIsLoading(true)
        const formData = {}

        formData['period'] = period
        formData['start_date'] = dayjs(start).format('YYYY-MM-DD')
        formData['end_date'] = dayjs(end).format('YYYY-MM-DD')
        formData['count_date'] = dayjs(countDate).format('YYYY-MM-DD')
        formData['inventory_document_no'] = documentNo
        formData['inventory_items'] = items
        formData['user_id'] = user.id
        formData['site_id'] = localStorage.getItem('site')
        formData['company_id'] = localStorage.getItem('company_id')

        await axios.put(`/api/inventories/${params.id}`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            getCountSheetAlone()
            setIsLoading(false)
        }).catch(({ response }) => {
            Swal.fire({
                text: response.data.message,
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
            setIsLoading(false)
        })

    }

    const handleCloseModal = async () => {

        if (status == 'approved') navigate('/inventory-sheets')

        else {

            if (!isEqual(items, testSubject?.inventory_items) || period != testSubject?.period ||
                !start.isSame(dayjs(testSubject?.start_date)) || !end.isSame(dayjs(testSubject?.end_date)) ||
                !countDate.isSame(dayjs(testSubject?.count_date)) || documentNo != testSubject?.inventory_document_no) {
                const isConfirm = await Swal.fire({
                    title: t('close_modal'),
                    text: t('lose_data'),
                    icon: 'warning',
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000',
                    reverseButtons: true,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('yes'),
                    cancelButtonText: t('cancel')
                }).then((result) => {
                    return result.isConfirmed
                })

                if (!isConfirm) {
                    return
                }

                navigate('/inventory-sheets')
            }
            else navigate('/inventory-sheets')
        }

    }


    const columns = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 1,
            renderCell: (params) => <ItemNo params={params} listOfItems={listOfItems} handleSelectItem={handleSelectItem} setListOfItems={setListOfItems} approved={disabled} />
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.5,
            renderCell: (params) => <Description params={params} t={t} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 1,
            renderCell: (params) => <UnitPrice params={params} t={t} />
        },
        {
            field: 'qty',
            headerName: t('qty'),
            flex: 1,
            renderCell: (params) => <Qty params={params} t={t} listOfItems={listOfItems} handleQty={handleQty} approved={disabled} />
        },
        {
            field: 'unit_of_measure_id',
            headerName: t('unit_of_measure'),
            flex: 1,
            renderCell: (params) => <UoM params={params} listOfUoM={listOfUoM} />
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => <Actions params={params} items={items} setItems={setItems} setListOfItems={setListOfItems} approved={disabled} />
        }
    ]

    return (
        <>
            {isLoading ? <Loading position='fixed' /> : ''}
            <Box sx={{ background: '#F5F5FD', minHeight: '100vh' }}>
                <div className='flex justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#5b5ea6] text-[35px] mr-4'>•</span> {t('inventory_count_sheet')} - {documentNo} | {site?.code} - {site?.name}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <button onClick={handleCloseModal}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>

                <div className='p-5 flex justify-between gap-3 flex-col lg:flex-row'>
                    <div className='bg-white p-6 pt-0 rounded-md w-full h-fit lg:w-2/5'>
                        <div className='pb-11 pt-1'>
                            <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('general_information')}</p>
                        </div>
                        <div className='w-full flex'>
                            <Period t={t} period={period} handleChangePeriod={handleChangePeriod} disabled={disabled}/>
                        </div>
                        <div className='w-full flex'>
                            <div className='lg:flex gap-2 w-full'>
                                <DateRange t={t} start={start} end={end} handleStart={handleStart} handleEnd={handleEnd} status='start' disabled={disabled} />
                                {period != 'day' ? (
                                    <DateRange t={t} start={start} end={end} handleStart={handleStart} handleEnd={handleEnd} status='end' disabled={disabled} />
                                ) : null}
                            </div>
                        </div>
                        <div className='w-full flex'>
                            <InventoryDocumentNoField t={t} documentNo={documentNo} setDocumentNo={setDocumentNo} disabled={disabled}/>
                        </div>
                        <div className='w-full flex'>
                            <CountDate t={t} countDate={countDate} handleCountDate={handleCountDate} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='w-full lg:w-3/5 h-fit'>
                        <div className='bg-white pt-0 rounded-md w-full mb-3'>
                            <div className='pt-1 pl-3'>
                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('items')}</p>
                            </div>
                            <div className='flex justify-end p-5 pt-0 border-b'>
                                <Tooltip disableInteractive title={t('add_item')} placement='bottom'>
                                    <div>
                                        <AddButton disabled={disabled} onClick={handleAddItem}><i className="fa-solid fa-plus"></i></AddButton>
                                    </div>
                                </Tooltip>
                            </div>
                            <div className='border-b'>
                                {
                                    items.length > 0 ?
                                        (
                                            status == 'approved'
                                            || !userHasPermissions(['inventory-sheets-update'])
                                        ) ?
                                            null
                                            :
                                            <div className='flex justify-end w-full p-2'>
                                                <button className='px-2 py-1 text-white bg-red-600 font-bold rounded-md mb-1 roboto text-xs uppercase' onClick={e => setItems([])}>{t('clear_all')}</button>
                                            </div>
                                        :
                                        null
                                }
                                <Items items={items} columns={columns} />
                            </div>
                            <div className='flex justify-end w-full p-5'>
                                <div className='w-full lg:w-1/3'>
                                    <div className='flex justify-between text-[#333333] text-[15px]'>
                                        <p>{t('totalQty')}</p>
                                        <p className='font-[500]'>{calculateTotalQuantity()}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full'>
                            {
                                (
                                    status == 'approved'
                                    || (
                                        !includes(['master_admin', 'office_manager'], user?.role)
                                        && !userHasPermissions(['inventory-sheets-update'])
                                    )
                                ) ?
                                    <a target='_blank' className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#6C6C6C] hover:bg-[#494949] disabled:bg-[#B8B7BC]' href={process.env.REACT_APP_BACKEND_URL + `/api/inventories/${params.id}/inventory-sheet?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}>
                                        <span className='pr-2'><i className="fa-solid fa-print"></i></span> {t('print')}
                                    </a>
                                    :
                                    <div className='flex gap-3 justify-between'>
                                        <a target='_blank' className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#6C6C6C] hover:bg-[#494949] disabled:bg-[#B8B7BC]' href={process.env.REACT_APP_BACKEND_URL + `/api/inventories/${params.id}/inventory-sheet?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}>
                                            <span className='pr-2'><i className="fa-solid fa-print"></i></span> {t('print')}
                                        </a>
                                        <div className='flex gap-3'>
                                            <button type="button" onClick={updateSheet} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC]'>
                                                <span className='pr-2'><i className="fa-solid fa-clock-rotate-left"></i></span> {t('update')}
                                            </button>
                                            <button type="button" onClick={approveSheet} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#00C448] hover:bg-[#019738] disabled:bg-[#B8B7BC]'>
                                                <span className='pr-2'><i className="fa-solid fa-check"></i></span> {t('aprove')}
                                            </button>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>

            </Box >
        </>
    )
}

export default UpdateCount


const ItemNo = ({ params, listOfItems, handleSelectItem, setListOfItems, approved }) => {

    const [item, setItem] = useState(' ')
    const { t } = useTranslation()
    const [exist, setExist] = useState(true)

    const handleChange = (e) => {
        setItem(prev => {
            if (prev == ' ') {
                return prev
            }
            if (prev) prev.disabled = false
            return prev
        })
        setItem(e.target.value)
        handleSelectItem(e.target.value, params.row.id)
        setListOfItems(prev => {
            const item = prev.find(i => i.id == e.target.value.id)
            item.disabled = true
            return prev
        })
    }

    useEffect(() => {
        if (listOfItems.length >= 0) {
            if (listOfItems.length >= 0) {
                if (params.row.item_id !== '') {
                    const item = listOfItems?.find(i => i.id === params.row.item_id)
                    if (Boolean(item)) {
                        setItem(item)
                        setExist(true)
                    }
                    else setExist(false)
                } else setExist(true)
            }
        }
    }, [listOfItems])

    useEffect(() => {
        setItem(prev => {
            if (prev == ' ') {
                return prev
            }
            if (prev) prev.disabled = true
            return prev
        })
    }, [item])




    return (
        <>
            {exist ?
                <FormControl variant="standard" sx={{ width: '100%' }}>
                    <Select defaultValue=' ' value={item} disabled={approved} onChange={e => handleChange(e)}>
                        <MenuItem value=' ' disabled sx={{ fontStyle: 'italic', display: 'none' }}>{t('select_item')}</MenuItem>
                        {
                            listOfItems?.map((i, index) => (
                                <MenuItem value={i} key={`itemslist-${i.id}`} disabled={i.disabled}>{i.item_no}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                :
                <TextField
                    type="text"
                    variant='standard'
                    disabled
                    className='w-full mb-5 px-0 pt-0'
                    value={params.row.item_no}
                />
            }
        </>
    )
}

const UoM = ({ params, listOfUoM }) => {

    const [uom, setUom] = useState(params.value)
    const [item, setItem] = useState([])

    useEffect(() => {
        setUom(params.value)
    }, [params.row.unit_of_measure_id])

    useEffect(() => {
        if (uom) {
            const item = listOfUoM.find(i => i.id == uom)
            setItem(item)
        }
    }, [uom, listOfUoM])

    return (

        <TextField type="text" variant='standard' disabled className='w-full mb-5 px-0 pt-0' value={item?.code || ''} />
    )
}

const Qty = ({ params, t, listOfItems, handleQty, approved }) => {
    const [qty, setQty] = useState(params.value)
    const [disabled, setDisabled] = useState(false)

    const handleQuantity = (e) => {
        const regex = /^(-?\d{0,5})$/i
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty('')
            }
            else {
                setQty(e.target.value)
                handleQty(e.target.value, params.row.id)
            }
        }
    }

    useEffect(() => {
        if (listOfItems.length >= 0) {
            if(params.row.item_id !== '') {
                const target = listOfItems?.find(i => i.id === params.row.item_id)

                if(target) {
                    setDisabled(false)
                } else {
                    setDisabled(true)
                }
            } else {
                setDisabled(false)
            }
        }
    }, [listOfItems])

    return (
        <TextField
            type="text"
            variant='standard'
            disabled={disabled || approved}
            className='w-full mb-5 px-0 pt-0'
            value={qty}
            onChange={(e) => { handleQuantity(e) }}
        />
    )
}

const Description = ({ params }) => {
    return (
        <TextField type="text" variant='standard' className='w-full mb-5 px-0 pt-0' value={params.value} disabled />
    )
}

const UnitPrice = ({ params }) => {
    return (
        <TextField type="text" variant='standard' className='w-full mb-5 px-0 pt-0' value={params.value} disabled />
    )
}

const Actions = ({ params, items, setItems, setListOfItems, approved }) => {

    const { t } = useTranslation()

    const deleteShipment = async () => {
        const i = items.filter(i => i.id !== params.row.id)
        setItems(i)
        setListOfItems(prev => {
            const item = prev.find(i => i.id == params.row.item_id)
            if (Boolean(item)) item.disabled = false
            return prev
        })
    }

    return (
        <>
            {
                approved ?
                    null
                    :
                    <>
                        <div className='flex justify-between'>
                            <Tooltip title={t('delete')} placement='bottom'>
                                <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                    <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={deleteShipment}><i className="fa-solid fa-trash"></i></span>
                                </div>
                            </Tooltip>
                        </div>
                    </>
            }</>
    )
}
