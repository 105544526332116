
const AuthCard = ({ children }) => (
    <div className="min-h-screen flex flex-col items-center justify-center pt-6 sm:pt-0 bg-hero-pattern">

        <div className="max-lg:w-full px-6 bg-transparent overflow-hidden sm:rounded-lg max-lg:mb-[300px]">
            {children}
        </div>

    </div>
)

export default AuthCard
