import React, {useEffect, useState} from 'react'

import dayjs from 'dayjs'
import {useTranslation} from 'react-i18next'

import Loading from '../../components/Loading'
import AppLayout from '../../components/Layouts/AppLayout'
import {useAuth} from '../../hooks/auth'
import PendingCollections from '../../components/customer_portal/PendingCollections'
import {useStateContext} from '../../context/ContextProvider'
import axios from '../../lib/axios'
import PieChartData from '../../components/customer_portal/PieChartData'
import FiltersCollections from '../../components/customer_portal/FiltersCollections'
import PurchaseDetails from '../../components/customer_portal/PurchaseDetails'
import SummaryByItem from '../../components/customer_portal/SummaryByItem'
import CollectionsSummaryData from '../../components/customer_portal/CollectionsSummaryData'
import BarChartData from '../../components/customer_portal/BarChartData'
import OnTimeDelivery from '../../components/customer_portal/OnTimeDelivery'
import {isUndefined} from 'lodash'
import { isValid } from '../../helpers/helper'

const CollectionDashboard = () => {

    const [isLoading, setisLoading] = useState(false)
    const { user } = useAuth({ middleware: 'guest' })
    const { config, pusher, choosesite } = useStateContext()
    const { t } = useTranslation()
    const [customerId] = useState(user?.customer_id)
    const [vendor, setVendor] = useState('')


    /* list of years */
    const [years] = useState([{
        id: dayjs().format('YYYY') - 0,
        year: dayjs().format('YYYY') - 0
    },
    {
        id: dayjs().format('YYYY') - 1,
        year: dayjs().format('YYYY') - 1
    },
    {
        id: dayjs().format('YYYY') - 2,
        year: dayjs().format('YYYY') - 2
    }
    ])
    /* list of months */
    const [months, setMonths] = useState([
        {
            id: 0,
            name: t('months.full.jan')
        },
        {
            id: 1,
            name: t('months.full.feb')
        },
        {
            id: 2,
            name: t('months.full.mar')
        },
        {
            id: 3,
            name: t('months.full.apr')
        },
        {
            id: 4,
            name: t('months.full.may')
        },
        {
            id: 5,
            name: t('months.full.jun')
        },
        {
            id: 6,
            name: t('months.full.jul')
        },
        {
            id: 7,
            name: t('months.full.aug')
        },
        {
            id: 8,
            name: t('months.full.sep')
        },
        {
            id: 9,
            name: t('months.full.oct')
        },
        {
            id: 10,
            name: t('months.full.nov')
        },
        {
            id: 11,
            name: t('months.full.dec')
        }
    ])


    const [year, setYear] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.customer_portal_purchase_year || [dayjs().format('YYYY') - 0]
        } else {
            return [dayjs().format('YYYY') - 0]
        }
    })
    const [purchaseLocation, setPurchaseLocation] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.customer_portal_purchase_location || []
        } else {
            return []
        }
    })
    const [month, setMonth] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.customer_portal_purchase_month || [months[dayjs().month()]]
        } else {
            return [months[dayjs().month()]]
        }
    })
    const [monthId, setMonthId] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.customer_portal_purchase_month_id || [dayjs().month()]
        } else {
            return [dayjs().month()]
        }
    })
    const [purchaseLocationId, setPurchaseLocationId] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.customer_portal_purchase_location_id || []
        } else {
            return []
        }
    })

    /* states */
    const [purchaseLocations, setPurchaseLocations] = useState([]) //list of location
    const [pendingPurchaseOrders, setPendingPurchaseOrders] = useState([])
    const [summaryData, setSummaryData] = useState([])
    const [collectionsByLocation, setCollectionsByLocation] = useState([])
    const [onTimeDelivery, setOnTimeDelivery] = useState({
        ontime: 0,
        total: 0,
        percentage_ontime: 0
    })
    const [quantitiesCollected, setQuantitiesCollected] = useState([])
    const [palletsbytype, setPalletsByType] = useState([])
    const [purchasebytype, setPurchaseByType] = useState([])

    /* effects */

    useEffect(() => {
        if(isValid(choosesite)) {
            const collectionChannelCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-created-site-${choosesite}`)
            const collectionChannelDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${choosesite}`)
            const collectionChannelUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${choosesite}`)
            const channelPurchaseAddressDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)

            // Bind shipment channel pushes

            collectionChannelCreate.bind(`${localStorage.getItem('client_id')}-purchaseorder-created-event-site-${choosesite}`, data => {
                if(Number(data.vendor_id) == Number(user?.vendor_id)) {
                    getCollectionSummary()
                    getOnTimeDelivery()
                    getCollectionsByLocation()
                    getQuantitiesCollected()
                    getPalletsByType()
                    getPurchaseByType()
                    /* handleEventPurchaseOrder(data.id, 'created') */
                    getPurchaseOrders()
                }
            })

            collectionChannelDelete.bind(`${localStorage.getItem('client_id')}-purchaseorder-deleted-event-site-${choosesite}`, data => {
                if(Number(data.vendor_id) == Number(user?.vendor_id)) {
                    getCollectionSummary()
                    getOnTimeDelivery()
                    getCollectionsByLocation()
                    getQuantitiesCollected()
                    getPalletsByType()
                    getPurchaseByType()
                    /* handleEventPurchaseOrder(data.id, 'deleted') */
                    getPurchaseOrders()
                }
            })

            collectionChannelUpdate.bind(`${localStorage.getItem('client_id')}-purchaseorder-updated-event-site-${choosesite}`, data => {
                if(Number(data.vendor_id) == Number(user?.vendor_id)) {
                    getCollectionSummary()
                    getOnTimeDelivery()
                    getCollectionsByLocation()
                    getQuantitiesCollected()
                    getPalletsByType()
                    getPurchaseByType()
                    /* handleEventPurchaseOrder(data.id, 'updated') */
                    getPurchaseOrders()
                }
            })

            channelPurchaseAddressDelete.bind(`${localStorage.getItem('client_id')}-purchaseaddress-deleted-event`, data => {
                if (Number(data.vendor_id) == Number(user?.vendor_id)) {
                    /* handleEventSalesOrder(data.id, 'updated') */
                    let filters = JSON.parse(localStorage.getItem('filters'))

                    filters[0].customer_portal_purchase_location = filters[0].customer_portal_purchase_location.filter((location) => location?.id !== data.id)
                    filters[0].customer_portal_purchase_location_id = filters[0].customer_portal_purchase_location_id.filter((locationId) => locationId !== data.id)

                    localStorage.setItem('filters', JSON.stringify(filters))

                    setPurchaseLocation(filters[0].customer_portal_purchase_location)
                    setPurchaseLocationId(filters[0].customer_portal_purchase_location_id)
                    setPurchaseLocations((prev) => {
                        return prev.filter((s) => s.id !== data.id)
                    })

                    getCollectionSummary()
                    getOnTimeDelivery()
                    getCollectionsByLocation()
                    getQuantitiesCollected()
                    getPalletsByType()
                    getPurchaseByType()
                    /* handleEventPurchaseOrder(data.id, 'updated') */
                    getPurchaseOrders()
                }
            })
        }

        return (() => {
            if(isValid(choosesite)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-created-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)
            }
        })
    }, [choosesite])

    useEffect(() => {
        if (user?.role === 'customers_user') {
            getInsightsUser()
        }
    }, [])

    useEffect(() => {
        if (user?.role === 'customers_user') {
            createFilterArray(year, month, purchaseLocation, monthId, purchaseLocationId)
            getCollectionSummary()
            getOnTimeDelivery()
            getCollectionsByLocation()
            getQuantitiesCollected()
            getPalletsByType()
            getPurchaseByType()
            if(vendor) getPurchaseOrders()
        }
    }, [year, month, monthId, purchaseLocation, purchaseLocationId, vendor])


    const createFilterArray = (year, month, location, month_id, location_id) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].customer_portal_purchase_year = year
        filters[0].customer_portal_purchase_month = month
        filters[0].customer_portal_purchase_location = location
        filters[0].customer_portal_purchase_month_id = month_id
        filters[0].customer_portal_purchase_location_id = location_id

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    const getInsightsUser = async () => {
        setisLoading(true)
        await axios.get(`/api/insights-users/${user?.id}`, config)
            .then(res => {
                const user = res.data
                setVendor(user?.vendor_id)
                setPurchaseLocations(user?.purchase_locations_info)

                if(purchaseLocation.length < 1 || purchaseLocationId.length < 1) {
                    setPurchaseLocation(user?.purchase_locations_info)
                    setPurchaseLocationId(user?.purchase_locations_info.map(i => i.id))
                }
                setisLoading(false)
            })

    }

    const getPurchaseOrders = async () => {
        setisLoading(true)
        await axios.get(`/api/list-orders?order_type=purchase&pending=true&address=${purchaseLocationId}&pending_filtered_year=${year}&pending_filtered_month=${monthId}`, config)
            .then(res => {
                const pending = res.data?.data
                setPendingPurchaseOrders(pending)
                setisLoading(false)
            })
    }


    const getCollectionSummary = async () => {
        setisLoading(true)
        await axios.get(`/api/fetch-collections-summary/${customerId}?filtered_month=${monthId}&filtered_year=${year}&purchase_location=${purchaseLocationId}`, config)
            .then(res => {
                const summary = res.data
                setSummaryData(summary)
                setisLoading(false)
            })
    }

    const getOnTimeDelivery = async () => {

        /* const formData = {}

        formData['filtered_month'] = month
        formData['filtered_year'] = year
        formData['purchase_location'] = purchaseLocation

        ovo je u slucaju da ne radi preko url-a*/
        setisLoading(true)
        await axios.get(`/api/fetch-collections-on-time/${customerId}?filtered_month=${monthId}&filtered_year=${year}&purchase_location=${purchaseLocationId}`, /* formData, */ config)
            .then(res => {
                const ontime = res.data
                setOnTimeDelivery(ontime)
                setisLoading(false)
            })
    }

    //Purchase Name Details
    const getCollectionsByLocation = async () => {
        setisLoading(true)
        await axios.get(`/api/fetch-collections-by-location/${customerId}?filtered_month=${monthId}&filtered_year=${year}&purchase_location=${purchaseLocationId}`, config)
            .then(res => {
                const collectionsbylocation = res.data
                setCollectionsByLocation(collectionsbylocation)
                setisLoading(false)
            })
    }

    const getQuantitiesCollected = async () => {
        setisLoading(true)
        await axios.get(`/api/fetch-quantities-collected/${customerId}?filtered_month=${monthId}&filtered_year=${year}&purchase_location=${purchaseLocationId}`, config)
            .then(res => {
                const quantitiesByItems = res.data
                setQuantitiesCollected(quantitiesByItems)
                setisLoading(false)
            })
    }


    // Pallet Type by % pie chart
    const getPalletsByType = async () => {
        setisLoading(true)
        await axios.get(`/api/fetch-collected-pallets-by-type/${customerId}?filtered_month=${monthId}&filtered_year=${year}&purchase_location=${purchaseLocationId}`, config)
            .then(res => {
                const palletsbytype = res.data
                setPalletsByType(palletsbytype)
                setisLoading(false)
            })
    }

    // summary by item type table
    const getPurchaseByType = async () => {
        setisLoading(true)
        await axios.get(`/api/fetch-purchases-by-item-type/${customerId}?filtered_month=${monthId}&filtered_year=${year}&purchase_location=${purchaseLocationId}`, config)
            .then(res => {
                const purchasebytype = res.data
                setPurchaseByType(purchasebytype)
                setisLoading(false)
            })
    }


    /* handlers */



    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>

                <div>

                    <div className='flex max-[1300px]:flex-col'>
                        <div className='max-[1300px]:w-full'>
                            <div>
                                <div className='p-5 w-full flex'>
                                    <div className='bg-white mb-2 rounded-md w-full'>
                                        <FiltersCollections
                                            setMonthId={setMonthId}
                                            setPurchaseLocationId={setPurchaseLocationId}
                                            user={user} years={years}
                                            months={months}
                                            purchaseLocations={purchaseLocations}
                                            year={year}
                                            setYear={setYear}
                                            purchaseLocation={purchaseLocation}
                                            setPurchaseLocation={setPurchaseLocation}
                                            month={month}
                                            setMonth={setMonth}
                                        />

                                    </div>
                                    <div className=' pl-5 w-[300px] max-[1300px]:w-full'>
                                        <div className='bg-white rounded-md'>
                                            <OnTimeDelivery title={t('on_time_collection')} total={onTimeDelivery} />
                                        </div>
                                    </div>
                                </div>
                                <div className='p-5 w-[790px] max-[1300px]:w-full'>
                                    <div className='bg-white rounded-md'>
                                        <div className=''>
                                            <div className='flex justify-start items-center p-5'>
                                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('units_collected_month_year')}</p>
                                            </div>
                                        </div>
                                        <BarChartData items={quantitiesCollected} />
                                    </div>
                                </div>
                                <div className='flex'>
                                    <div className='p-5 w-[790px] max-[1300px]:w-full'>
                                        <div className='bg-white rounded-md'>
                                            <div>
                                                <div className='flex justify-start items-center p-5'>
                                                    <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('pallet_type_by_pct')}</p>
                                                </div>
                                            </div>
                                            {/* <div className='text-center text-lg p-5'>
                                                No data for selected Date and Ship to Location.
                                            </div> */}
                                            <PieChartData items={palletsbytype} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='w-full'>
                            <div className='flex'>
                                <div className='p-5 w-full'>
                                    <div className='bg-white mb-2 rounded-md'>
                                        <PurchaseDetails purchaseLocation={collectionsByLocation} />
                                    </div>
                                </div>
                                <div className='p-5 w-full'>
                                    <div className='bg-white mb-2 rounded-md'>
                                        <SummaryByItem items={purchasebytype} />
                                    </div>
                                </div>
                            </div>
                            <div className='p-5 w-full'>
                                <div className='bg-white mb-2 rounded-md h-fit'>
                                    <CollectionsSummaryData items={summaryData} />
                                </div>
                            </div>
                            <div className='p-5 w-full'>
                                <div className='bg-white mb-2 rounded-md '>
                                    <div className='p-5 border-b'>
                                        <div className='flex justify-start items-center gap-4 '>
                                            <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('pending_collections')}</p>
                                        </div>
                                    </div>
                                    <div className='pt-3'>
                                        <PendingCollections items={pendingPurchaseOrders} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </AppLayout>
        </>
    )
}

export default CollectionDashboard
