import * as React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Tooltip from '@mui/material/Tooltip'
import TablePagination from "@mui/material/TablePagination";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from "react-i18next"
import { useNavigate, useLocation } from 'react-router-dom'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { getComparator, stableSort } from '../../helpers/DailyBoardTableHelpers'

function Row(props) {
    const { row, user, setIsLoading } = props;
    const [open, setOpen] = React.useState(false)

    const cellStyling = {
        padding: '8px 16px'
    }

    return (
        <React.Fragment >
            <TableRow sx={{ '& > *': { borderBottom: 'unset !important' } }}>
                <TableCell sx={{ ...cellStyling, position: 'relative', fontWeight: '500' }}>
                    {row.no}
                </TableCell>
                <TableCell sx={{ ...cellStyling, fontStyle: 'italic' }}>{row.customer_name}</TableCell>
                <TableCell sx={{ ...cellStyling }}>{<DateCell date={row.start_date} />}</TableCell>
                <TableCell sx={{ ...cellStyling }}>{<DateCell date={row.due_date} />}</TableCell>
                <TableCell sx={{ ...cellStyling }}>
                    <StatusCell statusId={row.status?.id} statusName={row.status?.name} />
                </TableCell>
                <TableCell sx={{ ...cellStyling }}>
                    {
                        <ProductionOrderActions id={row.id} status={row.status?.id} user={user} />
                    }
                </TableCell>
                <TableCell sx={{ ...cellStyling }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell style={{ padding: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '20%' }}>{t('item_no')}</TableCell>
                                        <TableCell sx={{ width: '60%' }}>{t('description')}</TableCell>
                                        <TableCell sx={{ width: '20%' }}>{t('qty_to_produce')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/* {row.production_order_items.map((historyRow) => (
                                        <TableRow key={historyRow.id}>
                                            <TableCell sx={{ ...cellStyling }}>{historyRow.item_no}</TableCell>
                                            <TableCell sx={{ ...cellStyling }}>{historyRow.description}</TableCell>
                                            <TableCell sx={{ ...cellStyling }}>{historyRow.qty_to_produce}</TableCell>
                                        </TableRow>
                                    ))} */}
                                    <TableRow>
                                        <TableCell sx={{ ...cellStyling }}>{row.item?.item_no}</TableCell>
                                        <TableCell sx={{ ...cellStyling }}>{row.item?.description}</TableCell>
                                        <TableCell sx={{ ...cellStyling }}>{row.qty_to_produce}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </React.Fragment>
    );
}

const SortableTableHeaderRow = (props) => {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {/* Production Order No. cell */}
                <TableCell
                    sx={{minWidth: '120px'}}
                    sortDirection={orderBy === 'no' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'no'}
                        direction={orderBy === 'no' ? order : 'asc'}
                        onClick={createSortHandler('no')}>
                        {t('production_order_no')}
                        {orderBy === 'no' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Customer Name cell */}
                <TableCell
                    sx={{minWidth: '120px'}}
                    sortDirection={orderBy === 'customer_name' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'customer_name'}
                        direction={orderBy === 'customer_name' ? order : 'asc'}
                        onClick={createSortHandler('customer_name')}>
                        {t('customer')}
                        {orderBy === 'customer_name' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Start Date cell */}
                <TableCell
                    sx={{minWidth: '120px'}}
                    sortDirection={orderBy === 'start_date' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'start_date'}
                        direction={orderBy === 'start_date' ? order : 'asc'}
                        onClick={createSortHandler('start_date')}>
                        {t('start_date')}
                        {orderBy === 'start_date' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Due Date cell */}
                <TableCell
                    sx={{minWidth: '120px'}}
                    sortDirection={orderBy === 'due_date' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'due_date'}
                        direction={orderBy === 'due_date' ? order : 'asc'}
                        onClick={createSortHandler('due_date')}>
                        {t('due_date')}
                        {orderBy === 'due_date' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Status cell */}
                <TableCell
                    sx={{width: '120px'}}
                    sortDirection={orderBy === 'status_id' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'status_id'}
                        direction={orderBy === 'status_id' ? order : 'asc'}
                        onClick={createSortHandler('status_id')}>
                        {t('status')}
                        {orderBy === 'status_id' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell sx={{ color: '#88909C !important', fontWeight: '400' }}>{t('actions')}</TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
    )
}

export default function CollapsibleTable({ data, user }) {

    const { t } = useTranslation()

    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(10);
    const [orderBy, setOrderBy] = React.useState('created_at')
    const [order, setOrder] = React.useState('desc')

    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }

    const handleRequestSort = (event, property) => {
        if (order === 'desc' && orderBy === property) {
            setOrderBy('created_at')
        } else {
            const isAsc = orderBy === property && order === 'asc'
            setOrder(isAsc ? 'desc' : 'asc')
            setOrderBy(property)
        }
    }

    const visibleOrders = React.useMemo(
        () =>
            stableSort(data, getComparator(order, orderBy)).slice(
                pg * rpg,
                pg * rpg + rpg,
            ),
        [data, order, orderBy, pg, rpg],
    )

    return (
        <div>
            <div style={{ width: '100%', mb: 2, border: 'none' }}>
                <TableContainer>
                    <Table aria-label="collapsible table" stickyHeader>
                        <SortableTableHeaderRow
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {data.length <= 0 ? <TableRow><TableCell colSpan={7} sx={{ textAlign: 'center' }}>{t('no_data')}</TableCell></TableRow> : null}
                            {visibleOrders?.map((row) => (
                                <Row key={row.id} row={row} user={user} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className='table-pagination'
                    labelRowsPerPage={t('rowsPerPage')}
                    rowsPerPageOptions={[10, 15]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rpg}
                    page={pg}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
}

const ProductionOrderActions = ({ id, status = 0, user }) => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const [open, setOpen] = React.useState(false)

    const openPopup = () => {
        setOpen(!open)
    }

    const userHasPermissions = (targets = []) => {
        return user?.permissions.some((permission) => targets.includes(permission.name))
    }

    return (
        <div className='flex gap-1'>
            {
                (
                    (
                        ['master_admin', 'office_manager'].includes(user?.role)
                        || userHasPermissions(['production-orders-update'])
                    )
                    && [1, 2].includes(status)
                ) ? (
                    <Tooltip disableInteractive title={t('update')} placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <button onClick={() => navigate(`/production-order/${id}`, { state: { prevPathname: location.pathname } })}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-pencil"></i></span></button>
                        </div>
                    </Tooltip>
                ) : (
                    <Tooltip disableInteractive title={t('open')} placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <button onClick={() => navigate(`/production-order/${id}`, { state: { prevPathname: location.pathname } })}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-eye"></i></span></button>
                        </div>
                    </Tooltip>
                )
            }
        </div>
    )
}

const StatusCell = ({ statusId = 0, statusName = null }) => {
    return (
        <>
            {
                statusId == 1 ?
                    <div className='w-full bg-[#D2ECFE] rounded-md p-1 text-[#0073C1] text-center font-[500]'>{statusName}</div>
                    :
                    statusId == 2 ?
                        <div className='w-full bg-[#FFD48F] rounded-md p-1 text-[#9D6000] text-center font-[500]'>{statusName}</div>
                        :
                        statusId == 3 ?
                            <div className='w-full bg-[#D2ECFE] rounded-md p-1 text-[#0073C1] text-center font-[500]'>{statusName}</div>
                            :
                            ''
            }
        </>
    )
}

export const DateCell = ({ date }) => {

    const date1 = new Date(dayjs(date).format("YYYY-MM-DD"))
    const date2 = new Date(dayjs().format("YYYY-MM-DD"))

    return (
        <div>
            {
                date1 < date2 ? <p style={{ color: '#F00', padding: '5px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p> :
                    date1 == date2 ? <p style={{ color: 'blue', padding: '5px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p> :
                        date1 > date2 ? <p style={{ color: '#008E00', padding: '5px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p> :
                            <p style={{ color: 'blue', padding: '5px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p>
            }
        </div>
    )
}
