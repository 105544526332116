import React from 'react'
import dayjs from 'dayjs'

const Footer = () => {
  return (
    <div className='w-full flex justify-between px-5 py-2 bg-slate-100 fixed bottom-0 left-0' style={{ zIndex: '200000' }}>
        <p>Pallet Insights © {dayjs().year()}</p>
        <p>All-In-One Pallet Business Management.</p>
    </div>
  )
}

export default Footer
