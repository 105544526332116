import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Box, TextField, Button, Tooltip } from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import Loading from '../../components/Loading'
import { useNavigate, Link, useParams } from "react-router-dom"

import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import OrderInfo from '../../components/forklifter/OrderInfo'
import StackStepper from '../../components/forklifter/StackStepper'
import { useAuth } from '../../hooks/auth'
import StackStepperGradeCode from '../../components/forklifter/StackStepperGradeCode'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { handleAxiosError } from '../../helpers/helper'

const DigitalGradeSheet = () => {
    const { t } = useTranslation()
    const params = useParams()
    const { config, configFile, setCompany_id: setCompanyId, setChoosesite: setSite, pusher } = useStateContext()
    const navigate = useNavigate()
    const { user } = useAuth({ middleware: 'guest' })

    const [order, setOrder] = useState([])
    const [unloadedDate, setUnloadedDate] = useState(() => {
        if(sessionStorage.getItem(`unloadedDate-${params.id}`) === null) {
            return dayjs()
        } else {
            return dayjs(sessionStorage.getItem(`unloadedDate-${params.id}`))
        }
    })

    useEffect(() => {

        if (localStorage.getItem('prev_site') !== null) {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('prev_site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('prev_site')}`)
        }

        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('site')}`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)


        channeldelete.bind(`${localStorage.getItem('client_id')}-purchaseorder-deleted-event-site-${localStorage.getItem('site')}`, data => {
            navigate('/available-loads', {
                replace: true,
            })
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-purchaseorder-updated-event-site-${localStorage.getItem('site')}`, data => {
            if(user.role === 'forklift_driver') {
                checkOrder()
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)
        })
    }, [])


    useEffect(() => {
        getOrder()
    }, [])

    const checkOrder = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/purchase-orders/${params.id}`, config)
            .then(res => {
                const data = res.data
                if (data.po_status_id !== 8) {
                    navigate('/available-loads', {
                        replace: true,
                    })
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getOrder = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/purchase-orders/${params.id}`, config)
            .then(res => {
                const data = res.data
                setOrder(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const [isLoading, setIsLoading] = useState(false)

    return (
        <>
            {isLoading ? <Loading position='fixed' /> : ''}
            <Box>
                <div className='flex justify-between items-center p-5 py-3' style={{ backgroundColor: '#b7472a' }}>
                    <div className='flex gap-6 items-center'>
                        <p className='text-xl roboto text-white'>{t('grade_sheet')} - <span className='font-bold'>{order?.po_number}</span> </p>
                    </div>
                    <div>
                        {
                            user.role === 'forklift_driver' ?
                                <Link to="/available-loads">
                                    <IconButton sx={{ color: 'white' }}>
                                        <ClearIcon />
                                    </IconButton>
                                </Link>
                                :
                                <Link to={`/purchase-order/${params.id}`}>
                                    <IconButton sx={{ color: 'white' }}>
                                        <ClearIcon />
                                    </IconButton>
                                </Link>
                        }
                    </div>
                </div>
                <OrderInfo order={order} order_id={params.id} unloadedDate={unloadedDate} setUnloadedDate={setUnloadedDate} />
                <StackStepper order={order} order_id={params.id} setIsLoading={setIsLoading} unloadedDate={unloadedDate} />
                {/* <StackStepperGradeCode order={order} setIsLoading={setIsLoading} order_id={params.id} /> */}
            </Box >
        </>
    )
}


export default DigitalGradeSheet
