import React, { useEffect, useState, useLayoutEffect, useRef } from "react"

import { useTranslation } from 'react-i18next'
import { Box, Switch, Tab, TextField, Tooltip, Stack, RadioGroup, FormControlLabel, Radio, Typography, Button, IconButton, Autocomplete } from "@mui/material"
import ClearIcon from "@mui/icons-material/Clear"
import Swal from 'sweetalert2'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useParams, Link, useSearchParams, useNavigate, useLocation } from "react-router-dom"
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import Loading from "../../components/Loading"

import { useStateContext } from '../../context/ContextProvider'
import axios from "../../lib/axios"
import { areAllValid, extractErrorMessage, isValid, handleAxiosError, deleteZero, handleZero, handleNumber, handleInteger } from "../../helpers/helper"
import Materials from "../../components/inventory/Items"
import AddButton from "../../components/AddButton"
import { useDebounce } from "use-debounce"
import { isNull, debounce, startsWith, includes, round, toNumber, toString, random as lorandom, isNumber } from "lodash"
import dayjs from "dayjs"
import { useAuth } from "../../hooks/auth"

const UpdateItems = () => {

    const params = useParams()
    const navigate = useNavigate()
    const { state } = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const { t } = useTranslation()
    const { config, configFile, currencySymbol, pusher, palletTypes } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })

    const [value, setValue] = useState(() => {
        if (
            searchParams.has('tab')
            && ['general', 'dimensions', 'planning', 'bom'].includes(searchParams.get('tab'))
        ) {
            return searchParams.get('tab')
        } else {
            return 'general'
        }
    })

    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingItemTypes, setIsLoadingItemTypes] = useState(true)
    const [isLoadingGradeCodes, setIsLoadingGradeCodes] = useState(true)
    const [isLoadingUnitsOfMeasure, setIsLoadingUnitsOfMeasure] = useState(true)
    const [isLoadingProductionTypes, setIsLoadingProductionTypes] = useState(true)
    const [isLoadingPotentialSubAssemblies, setIsLoadingPotentialSubAssemblies] = useState(false)

    const [unitsOfMeasure, setUnitsOfMeasure] = useState([])
    const [companyProductionTypes, setCompanyProductionTypes] = useState([])
    const [itemTypes, setItemTypes] = useState(() => {
        if (localStorage.getItem('item_types') !== null) {
            const itypes = JSON.parse(localStorage.getItem('item_types'))
            return itypes
        } else {
            return []
        }
    })
    const [count, setCount] = useState(10000)
    const [materialsList, setMaterialsList] = useState([])

    const [itemNo, setItemNo] = useState('')
    const [description, setDescription] = useState('')
    const [additionalDesc, setAdditionalDesc] = useState('')
    const [unitOfMeasureId, setUnitOfMeasureId] = useState('')
    const [gradeCodeId, setGradeCodeId] = useState('')
    const [unitPrice, setUnitPrice] = useState(0)
    const [blockedSO, setBlockedSO] = useState(false)
    const [blockedPO, setBlockedPO] = useState(false)
    const [blocked, setBlocked] = useState(false)
    const [minimumStockQuantity, setMinimumStockQuantity] = useState('')
    const [negativeInventory, setNegativeInventory] = useState(false)
    const [stockoutWarning, setStockoutWarninig] = useState(false)
    const [companyId, setCompanyId] = useState('')
    const [productionTypeId, setProductionTypeId] = useState('')
    const [gradeCodes, setGradeCodes] = useState([])
    const [disableBoth, setDisableBoth] = useState(false)
    const [inventoryItem, setInventoryItem] = useState(false)
    const [startingPerpetualInventory, setStartingPerpetualInventory] = useState(0)
    const [changedStartingPerpetualInventory, setChangedStartingPerpetualInventory] = useState(false)
    const [startingPerpetualInventoryUpdatedAt, setStartingPerpetualInventoryUpdatedAt] = useState('')
    const [currentPerpetualInventoryUpdatedAt, setCurrentPerpetualInventoryUpdatedAt] = useState('')

    const [itemTypeId, setItemTypeId] = useState('')
    const [itemTypeKey, setItemTypeKey] = useState('')
    const [palletTypeId, setPalletTypeId] = useState('')
    /* const [palletTypeKey, setPalletTypeKey] = useState('') */
    const [initialItemType, setInitialItemType] = useState({})
    const [measurementSystem, setMeasurementSystem] = useState('imperial')

    const [thicknessValue, setThicknessValue] = useState(1)
    const [thicknessUnit, setThicknessUnit] = useState('in')
    const [widthValue, setWidthValue] = useState(1)
    const [widthUnit, setWidthUnit] = useState('in')
    const [lengthValue, setLengthValue] = useState(1)
    const [lengthUnit, setLengthUnit] = useState('in')
    const [boardFeet, setBoardFeet] = useState(0.007)

    const [bomNo, setBomNo] = useState('')
    const [bomDescription, setBomDescription] = useState('')
    const [bomUnitOfMeasure, setBomUnitOfMeasure] = useState('')

    const [materials, setMaterials] = useState([])
    const [totalCost, setTotalCost] = useState(0)
    const [totalQty, setTotalQty] = useState(0)

    const [documents, setDocuments] = useState([])
    const [documentFileInputKey, setDocumentFileInputKey] = useState(Math.random())
    const documentInputRef = useRef(null)
    const documentSizeLimit = 10 // in MBs

    useEffect(() => {
        const itemDocumentChannelUpload = pusher.subscribe(`${localStorage.getItem('client_id')}-item-${params.id}-document-event`)

        itemDocumentChannelUpload.bind(`${localStorage.getItem('client_id')}-item-${params.id}-document-event`, data => {
            if(data.is_deleted) {
                setDocuments((prev) => {
                    const deletedDoc = prev.find((i) => i.id === data.id)
                    const docsUpdated = deletedDoc ? prev.filter((i) => i.id !== deletedDoc.id) : prev
                    return docsUpdated
                })
            } else {
                getDocuments()
            }
        })

        /* UNIT OF MEASURE pusher events */

        const channelUnitOfMeasureDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-unitofmeasure-deleted`)
        const channelUnitOfMeasureCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-unitofmeasure-created`)
        const channelUnitOfMeasureUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-unitofmeasure-updated`)

        channelUnitOfMeasureDelete.bind(`${localStorage.getItem('client_id')}-unitofmeasure-deleted-event`, data => {
            getUnitsOfMeasure()
        })

        channelUnitOfMeasureCreate.bind(`${localStorage.getItem('client_id')}-unitofmeasure-created-event`, data => {
            getUnitsOfMeasure()
        })

        channelUnitOfMeasureUpdate.bind(`${localStorage.getItem('client_id')}-unitofmeasure-updated-event`, data => {
            getUnitsOfMeasure()
        })

        /* GRADE CODE pusher events */

        const channelGradeCodeDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-gradecode-deleted`)
        const channelGradeCodeCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-gradecode-created`)
        const channelGradeCodeUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-gradecode-updated`)

        channelGradeCodeDelete.bind(`${localStorage.getItem('client_id')}-gradecode-deleted-event`, data => {
            getGradeCodes()
        })

        channelGradeCodeCreate.bind(`${localStorage.getItem('client_id')}-gradecode-created-event`, data => {
            getGradeCodes()
        })

        channelGradeCodeUpdate.bind(`${localStorage.getItem('client_id')}-gradecode-updated-event`, data => {
            getGradeCodes()
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-item-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-item-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-item-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-item-${params.id}-document-event`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-unitofmeasure-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-unitofmeasure-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-unitofmeasure-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-gradecode-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-gradecode-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-gradecode-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productiontype-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productiontype-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productiontype-updated`)
        })
    }, [])

    useEffect(() => {
        if(isValid(companyId)) {
            const channelProductionTypeDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-productiontype-deleted`)
            const channelProductionTypeCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-productiontype-created`)
            const channelProductionTypeUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-productiontype-updated`)

            channelProductionTypeDelete.bind(`${localStorage.getItem('client_id')}-productiontype-deleted-event`, data => {
                getProductionTypes(companyId)
            })

            channelProductionTypeCreate.bind(`${localStorage.getItem('client_id')}-productiontype-created-event`, data => {
                getProductionTypes(companyId)
            })

            channelProductionTypeUpdate.bind(`${localStorage.getItem('client_id')}-productiontype-updated-event`, data => {
                getProductionTypes(companyId)
            })
        }

        return (() => {
            if(isValid(companyId)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productiontype-deleted`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productiontype-created`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productiontype-updated`)
            }
        })
    }, [companyId])

    useEffect(() => {
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-item-created`)
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-item-deleted`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-item-updated`)

        channelcreate.bind(`${localStorage.getItem('client_id')}-item-created-event`, data => {
            if(Number(data.company_id) == Number(localStorage.getItem('company_id'))) {
                getPotentialSubAssemblies(itemTypeKey)
            }
        })

        channeldelete.bind(`${localStorage.getItem('client_id')}-item-deleted-event`, data => {
            if (Number(data.id) == Number(params.id)) {
                navigate('/items')
            } else if (Number(data.company_id) == Number(localStorage.getItem('company_id'))) {
                getPotentialSubAssemblies(itemTypeKey)
            }
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-item-updated-event`, data => {
            if (Number(data.id) == Number(params.id)) {
                getItem()
            } else if (Number(data.company_id) == Number(localStorage.getItem('company_id'))) {
                getPotentialSubAssemblies(itemTypeKey)
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-item-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-item-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-item-updated`)
        })
    }, [itemTypeKey])

    /* KEY EVENT */
    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate('/items')
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    useEffect(() => {
        getGradeCodes()
        getUnitsOfMeasure()
        getItemTypes()
    }, [])

    useEffect(() => {
        if([isLoadingItemTypes, isLoadingGradeCodes, isLoadingUnitsOfMeasure].every((x) => !x)) {
            getItem()
        }
    }, [isLoadingItemTypes, isLoadingGradeCodes, isLoadingUnitsOfMeasure])

    useEffect(() => {
        if(isValid(companyId)) {
            getProductionTypes(companyId)
        }
    }, [companyId])

    useEffect(() => {
        setSearchParams({ tab: value })
    }, [value])

    useEffect(() => {
        if(measurementSystem === 'imperial') {
            setBoardFeet(
                (thicknessValue * widthValue * lengthValue)
                / (lengthUnit === 'in' ? 144 : 12)
            )
        }
    }, [measurementSystem, widthValue, widthUnit, lengthValue, lengthUnit, thicknessValue, thicknessUnit])

    useEffect(() => {
        calculateTotalCost()
        calculateTotalQuantity()
        if(materials.length <= 0) {
            setBomUnitOfMeasure(unitOfMeasureId)
        }
    }, [materials])

    useEffect(() => {
        if(isValid(itemTypeKey)) {
            getPotentialSubAssemblies(itemTypeKey)
        }
    }, [itemTypeKey])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const handleBlocked = (event) => {
        setBlocked(event.target.checked);
        setDisableBoth(event.target.checked)
        setBlockedPO(event.target.checked)
        setBlockedSO(event.target.checked)
    }
    const handleBlockedPO = (event) => {
        setBlockedPO(event.target.checked);
    }
    const handleBlockedSO = (event) => {
        setBlockedSO(event.target.checked);
    }

    const handleQty = (e) => {
        const regex = /^(-?\d*)((\.(\d{0,2})?)?)$/i
        if (regex.test(e.target.value)) {
            if (e.target.value === '-') setMinimumStockQuantity(1)
            else setMinimumStockQuantity(e.target.value)
        }
    }

    const handleClearUnitOfMeasureClick = () => {
        setUnitOfMeasureId('')
    }

    const handleChangeUnitOfMeasure = (e) => {
        setUnitOfMeasureId(e.target.value)
        if(bomUnitOfMeasure === '' || materials.length <= 0) {
            setBomUnitOfMeasure(e.target.value)
        }
    }

    const handleChangeUnitOfMeasureAutocomplete = (e, value) => {
        e.preventDefault()

        setUnitOfMeasureId(value)
        if(bomUnitOfMeasure === '' || materials.length <= 0) {
            setBomUnitOfMeasure(value)
        }
    }

    const handleClearGradeCodeClick = () => {
        setGradeCodeId('')
    }
    const handleClearProductionTypeClick = () => {
        setProductionTypeId('')
    }

    const handleClearAllMaterialLines = (e) => {
        e.preventDefault()

        /**
         * For some reason, this got triggered by pressing Enter inside any of the enabled Lines inputs.
         * event.detail should be at least 1 to indicate that there has been a click and NOT a key press
         */
        if(e.detail > 0) {
            setMaterials([])
            setMaterialsList(prev => {
                const disabledListMaterials = prev.filter(m => m.disabled)
                disabledListMaterials.forEach(material => {
                    material.disabled = false
                });
                return prev
            })
            setTotalCost(0)
            setTotalQty(0)
        }
    }

    const handleChangeItemType = (e) => {
        setItemTypeId(e.target.value)
        const itemType = itemTypes.find((itype) => itype.id === e.target.value)
        if(itemType) {
            setItemTypeKey(itemType.key)
        }
    }

    const handleChangePalletType = (e) => {
        setPalletTypeId(e.target.value)
        /* const palletType = palletTypes.find((ptype) => ptype.id === e.target.value)
        if(palletType) {
            setPalletTypeKey(palletType.key)
        } */
    }

    const handleChangeMeasurementSystem = (e) => {
        setMeasurementSystem(e.target.value)
        if(e.target.value === 'imperial') {
            setWidthValue((prev) => round(prev / 2.54, 3))
            setWidthUnit('in')

            const oldLengthUnit = lengthUnit
            setLengthValue((prev) => {
                switch(oldLengthUnit) {
                    case 'cm':
                        return round(prev / 2.54, 3)
                    case 'm':
                        return round(prev / 0.3048, 3)
                }
            })
            setLengthUnit((prev) => {
                if(prev === 'cm') {
                    return 'in'
                } else {
                    return 'ft'
                }
            })

            setThicknessValue((prev) => round(prev / 2.54, 3))
            setThicknessUnit('in')
        } else {
            setWidthValue((prev) => round(prev * 2.54, 3))
            setWidthUnit('cm')

            const oldLengthUnit = lengthUnit
            setLengthValue((prev) => {
                switch(oldLengthUnit) {
                    case 'in':
                        return round(prev * 2.54, 3)
                    case 'ft':
                        return round(prev * 0.3048, 3)
                }
            })
            setLengthUnit((prev) => {
                if(prev === 'in') {
                    return 'cm'
                } else {
                    return 'm'
                }
            })

            setThicknessValue((prev) => round(prev * 2.54, 3))
            setThicknessUnit('cm')
        }
    }

    const handleAddMaterial = (e) => {
        e.preventDefault()

        if(e.detail > 0) {
            let counter = 0
            let msgMaterial = ''
            let msgQty = ''
            let msgBoth = t('please_select_material_and_enter_qty')
            materials.forEach(element => {
                if (element.item_no == '') {
                    counter += 1
                    msgMaterial = t('please_select_material')
                }
                if (element.qty_per_assembly == '' || parseInt(element.qty_per_assembly) < 1) {
                    counter += 1
                    msgQty = t('please_enter_qty')
                }
            })

            if (counter > 0) {
                if (msgMaterial !== '' && msgQty === '') {
                    Swal.fire({
                        icon: "warning",
                        text: msgMaterial,
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FFCA28'
                    })
                }
                if (msgQty !== '' && msgMaterial === '') {
                    Swal.fire({
                        icon: "warning",
                        text: msgQty,
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FFCA28'
                    })
                }
                if (msgQty !== '' && msgMaterial !== '') {
                    Swal.fire({
                        icon: "warning",
                        text: msgBoth,
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FFCA28'
                    })
                }


                return
            }

            const material = {
                "id": count,
                "item_id": '',
                "item_no": '',
                "unit_of_measure_code": '',
                "description": '',
                "unit_price": 0,
                "qty_per_assembly": 0,
                "scrap": 0,
            }

            setMaterials((prev) => [...prev, material])
            setCount(prev => prev + 1)
        }
    }

    const handleSelectMaterial = (value, id) => {
        const materialsCopy = [...materials]
        const material = materialsCopy.find(m => m.id === id)

        material.item_id = value.id
        material.item_no = value.item_no
        material.unit_of_measure_code = value.unit_of_measure?.code ?? ''
        material.description = value.description
        material.unit_price = value.unit_price

        setMaterials(materialsCopy)
    }

    const handleMaterialQty = (value, id) => {
        const materialsCopy = [...materials]
        const material = materialsCopy.find(m => m.id === id)

        material.qty_per_assembly = value

        setMaterials(materialsCopy)

        calculateTotalQuantity()
    }

    const handleMaterialScrap = (value, id) => {
        const materialsCopy = [...materials]
        const material = materialsCopy.find(m => m.id === id)

        material.scrap = value

        setMaterials(materialsCopy)
    }

    const handleMaterialUnitPrice = (value, id) => {
        const materialsCopy = [...materials]
        const material = materialsCopy.find(m => m.id === id)

        material.unit_price = value

        setMaterials(materialsCopy)
    }

    const calculateTotalCost = () => {
        let tcost = 0
        materials.forEach(material => {
            tcost += parseInt(material.qty_per_assembly) * parseFloat(material.unit_price)
        })
        setTotalCost(round(tcost, 2))
    }

    const calculateTotalQuantity = () => {
        let tqty = 0
        materials.forEach(material => {
            tqty += parseInt(material.qty_per_assembly)
        })
        setTotalQty(tqty)
    }

    const handleChangeLengthUnit = (e) => {
        const unit = e.target.value

        // This operates under the assumption that the old and the new values are in the same measurement system
        switch(unit) {
            case 'in':
                setLengthValue((prev) => round(prev * 12, 2))
                break;
            case 'ft':
                setLengthValue((prev) => round(prev * 0.0833333333, 2))
                break;
            case 'cm':
                setLengthValue((prev) => round(prev * 100, 2))
                break;
            case 'm':
                setLengthValue((prev) => round(prev * 0.01, 2))
                break;
            default:
        }
    }

    const handleAddDocumentClick = () => {
        documentInputRef.current?.click()
    }

    {/* API */}

    const getItem = async (id = null) => {
        setIsLoading(true)

        await axios.get(`/api/items/${isNull(id) ? params.id : id}?with=documents,billOfMaterial,billOfMaterialLines&site_id=${localStorage.getItem('site')}`, config)
            .then(res => {
                const item = res.data
                if(
                    localStorage.getItem('company_id') === null
                    || (Number(item.company_id) !== Number(localStorage.getItem('company_id')))
                ) {
                    navigate('/items', {
                        replace: true
                    })
                    return
                }
                setItemNo(item.item_no)
                setDescription(item.description)
                setAdditionalDesc(item.description2 || '')

                if(item.grade_code_id) {
                    const gradeCodeExists = gradeCodes.find((gc) => gc.id == item.grade_code_id)
                    if(!gradeCodeExists) {
                        setGradeCodes((prev) => [
                            ...prev,
                            {
                                id: item.grade_code_id,
                                code: item.grade_code || '',
                                disabled: true
                            }
                        ])
                    }
                }
                setGradeCodeId(item.grade_code_id || '')

                if(item.unit_of_measure_id) {
                    const unitOfMeasureExist = unitsOfMeasure.find((uom) => uom.id == item.unit_of_measure_id)
                    if(!unitOfMeasureExist) {
                        setUnitsOfMeasure((prev) => [
                            ...prev,
                            {
                                id: item.unit_of_measure_id,
                                code: item.unit_of_measure_name || '',
                                disabled: true
                            }
                        ])
                    }
                }
                setUnitOfMeasureId(item.unit_of_measure_id)

                setUnitPrice(item.unit_price || 0)
                setBlockedSO(item.blocked_for_sales ? true : false)
                setBlockedPO(item.blocked_for_purchases ? true : false)
                setBlocked(item.blocked ? true : false)
                setDisableBoth(item.blocked ? true : false)
                setMinimumStockQuantity(item.minimum_stock_quantity || '')
                setStockoutWarninig(item.can_issue_stockout_warnings ? true : false)
                setInventoryItem(item.inventory_item ? true : false)
                setStartingPerpetualInventory(item.starting_perpetual_inventory || 0)
                setStartingPerpetualInventoryUpdatedAt(item?.starting_perpetual_inventory_updated_at ? item?.starting_perpetual_inventory_updated_at : '')
                setCurrentPerpetualInventoryUpdatedAt(item?.current_perpetual_inventory_updated_at ? item?.current_perpetual_inventory_updated_at : '')
                setCompanyId(item.company_id)
                setProductionTypeId(item.production_type_id || '')
                setItemTypeId(item.item_type_id || '')
                setPalletTypeId(item.pallet_type?.id ?? item.pallet_type)

                const retrievedMeasurementSystem = item.item_dimensions?.measurement_system ?? 'imperial'
                setMeasurementSystem(retrievedMeasurementSystem)
                if(retrievedMeasurementSystem === 'imperial') {
                    setThicknessUnit(item.item_dimensions?.thickness_unit ?? 'in')
                    setWidthUnit(item.item_dimensions?.width_unit ?? 'in')
                    setLengthUnit(item.item_dimensions?.length_unit ?? 'in')
                    setThicknessValue(item.item_dimensions?.thickness_value ? item.item_dimensions?.thickness_value : 1)
                    setWidthValue(item.item_dimensions?.width_value ? item.item_dimensions?.width_value : 1)
                    setLengthValue(item.item_dimensions?.length_value ? item.item_dimensions?.length_value : 1)
                    setBoardFeet(item.item_dimensions?.board_feet ?? 0.007)
                } else {
                    setThicknessUnit(item.item_dimensions?.thickness_unit ?? 'cm')
                    setWidthUnit(item.item_dimensions?.width_unit ?? 'cm')
                    setLengthUnit(item.item_dimensions?.length_unit ?? 'cm')
                    setThicknessValue(item.item_dimensions?.thickness_value ? item.item_dimensions?.thickness_value: 2.54)
                    setWidthValue(item.item_dimensions?.width_value ? item.item_dimensions?.width_value : 2.54)
                    setLengthValue(item.item_dimensions?.length_value ? item.item_dimensions?.length_value : 2.54)
                }

                setItemTypeKey(item.item_type_key || '')
                setInitialItemType({
                    id: item.item_type_id || '',
                    key: item.item_type_key || ''
                })
                setDocuments(item.documents)

                setBomNo(item.bill_of_material?.bom_no ?? '')
                setBomDescription(item.bill_of_material?.description ?? '')
                setBomUnitOfMeasure(item.bill_of_material?.unit_of_measure_id ?? item.unit_of_measure_id)
                setMaterials(item.bill_of_material_lines?.map((bomLine) => ({
                    "id": bomLine.id,
                    "item_id": bomLine.sub_assembly_id,
                    "item_no": bomLine.sub_assembly_no,
                    "description": bomLine.description,
                    "unit_price": bomLine.unit_price,
                    "qty_per_assembly": bomLine.qty_per_assembly,
                    "unit_of_measure_code": bomLine.sub_assembly_unit_of_measure_code,
                    "scrap": bomLine.scrap,
                })) ?? [])
            })
            .catch(({ response }) => {
                if (response.status === 404) {
                    navigate('/items', {
                        replace: true
                    })
                }

                Swal.fire({
                    text: extractErrorMessage(response),
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPotentialSubAssemblies = async (typeKey = null) => {
        setIsLoadingPotentialSubAssemblies(true)

        await axios.get(`/api/items/${params.id}/potential-sub-assemblies?item_type_key=${typeKey}`, config)
            .then(res => {
                const potentialSubAssemblies = res.data
                setMaterialsList(potentialSubAssemblies)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoadingPotentialSubAssemblies(false)
            })
    }

    const getUnitsOfMeasure = async () => {
        setIsLoadingUnitsOfMeasure(true)

        await axios.get(`/api/units-of-measure`, config)
            .then(res => {
                const uom = res.data
                setUnitsOfMeasure(uom)
            })
            .finally(() => {
                setIsLoadingUnitsOfMeasure(false)
            })
    }
    const getGradeCodes = async () => {
        setIsLoadingGradeCodes(true)

        await axios.get(`/api/grade-codes`, config)
            .then(res => {
                const gcs = res.data
                setGradeCodes(gcs)
            })
            .finally(() => {
                setIsLoadingGradeCodes(false)
            })
    }
    const getProductionTypes = async (companyId = null) => {
        setIsLoadingProductionTypes(true)

        await axios.get(`/api/production-types?company_id=${isNull(companyId) ? localStorage.getItem('company_id') : companyId}`, config)
            .then(res => {
                const pts = res.data
                setCompanyProductionTypes(pts)
            })
            .finally(() => {
                setIsLoadingProductionTypes(false)
            })
    }
    const getItemTypes = async () => {
        setIsLoadingItemTypes(true)

        await axios.get(`/api/item-types`, config)
            .then(res => {
                const itypes = res.data
                setItemTypes(itypes)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoadingItemTypes(false)
            })
    }

    const handleAddDocument = async (e) => {
        if(!e.target.files) {
            return
        }

        if(e.target.files[0].size > (documentSizeLimit * 1000 * 1024)) {
            Swal.fire({
                text: t('selected_file_exceeds_x_mbs', {x: documentSizeLimit}),
                icon: "warning",
                customClass: 'warning2',
                showCloseButton: true,
                iconColor: '#FEB806'
            })

            setDocumentFileInputKey(Math.random())
            return
        }


        setIsLoading(true)

        // 🚩 do the file upload here normally...
        const formData = new FormData()

        formData.append('document', e.target.files[0])

        await axios.post(`/api/items/${params.id}/store-item-documents`, formData, configFile)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.message
                })
                documentInputRef.current.value = ""
            }).catch(({ response }) => {
                handleAxiosError({response: response})
            }).finally(() => {
                setDocumentFileInputKey(Math.random())
                setIsLoading(false)
            })
    }

    const deleteDocument = async (e, id) => {
        e.preventDefault()

        const isConfirm = await Swal.fire({
            title: t('delete_this_document'),
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            setIsLoading(true)
            return result.isConfirmed
        })

        if (!isConfirm) {
            setIsLoading(false)
            return
        }

        await axios.delete(`/api/item-documents/${id}`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
            }).catch(({ response }) => {
                handleAxiosError({response: response})
            }).finally(() => {
                setIsLoading(false)
            })
    }

    const updateItem = async (e) => {
        e.preventDefault();

        if(
            materials.length <= 0
            && (
                bomNo !== ''
                || bomDescription !== ''
            )
        ) {
            const understandsRiskBillOfMaterial = await Swal.fire({
                title: t('caution'),
                text: t('bom_data_loss_update_warning'),
                icon: 'warning',
                customClass: 'warning2',
                showCloseButton: true,
                iconColor: '#FEB806',
                reverseButtons: true,
                showCancelButton: true,

                confirmButtonText: t('yes'),
                cancelButtonText: t('negatory')
            }).then((result) => {
                return result.isConfirmed
            })

            if(!understandsRiskBillOfMaterial) {
                return
            }
        }

        if(
            (includes(['lumber', 'pallet', 'component'], initialItemType.key) && initialItemType.id !== itemTypeId)
            && !(
                (itemTypeKey === 'lumber' && includes(['pallet', 'component'], initialItemType.key))
                || (includes(['pallet', 'component'], itemTypeKey) && includes(['pallet', 'component'], initialItemType.key))
            )
        ) {
            const understandsRiskItemType = await Swal.fire({
                title: t('caution'),
                text: t('updated_item_type_warning'),
                icon: 'warning',
                customClass: 'warning2',
                showCloseButton: true,
                iconColor: '#FEB806',
                reverseButtons: true,
                showCancelButton: true,

                confirmButtonText: t('yes'),
                cancelButtonText: t('negatory')
            }).then((result) => {
                return result.isConfirmed
            })
            if(!understandsRiskItemType) {
                return
            }
        }

        setIsLoading(true)

        const formData = {}

        const itemDimensions = {}
        switch(itemTypeKey) {
            case 'lumber':
                itemDimensions.measurement_system = measurementSystem
                itemDimensions.thickness_value = toString(thicknessValue)
                itemDimensions.thickness_unit = thicknessUnit
                itemDimensions.width_value = toString(widthValue)
                itemDimensions.width_unit = widthUnit
                itemDimensions.length_value = toString(lengthValue)
                itemDimensions.length_unit = lengthUnit
                break;
            case 'pallet':
            case 'component':
                itemDimensions.measurement_system = measurementSystem
                itemDimensions.width_value = toString(widthValue)
                itemDimensions.width_unit = widthUnit
                itemDimensions.length_value = toString(lengthValue)
                itemDimensions.length_unit = lengthUnit
                break;
            case 'other':
                itemDimensions.measurement_system = measurementSystem
                break;
            default:
        }

        /* formData['item_no'] = itemNo */
        formData['description'] = description
        formData['description2'] = additionalDesc
        formData['unit_price'] = unitPrice == '' ? 0 : unitPrice
        formData['unit_of_measure_id'] = unitOfMeasureId
        formData['blocked_for_sales'] = blockedSO
        formData['blocked_for_purchases'] = blockedPO
        formData['blocked'] = blocked
        formData['grade_code_id'] = gradeCodeId
        /* formData['can_hit_negative_inventory'] = negativeInventory */
        formData['can_issue_stockout_warnings'] = stockoutWarning
        formData['inventory_item'] = inventoryItem
        formData['starting_perpetual_inventory'] = startingPerpetualInventory
        formData['minumum_stock_quantity'] = minimumStockQuantity
        formData['company_id'] = companyId
        formData['production_type_id'] = productionTypeId
        formData['site_id'] = localStorage.getItem('site') ?? null
        formData['item_type_id'] = itemTypeId
        formData['pallet_type_id'] = palletTypeId
        formData['item_dimensions'] = itemDimensions
        formData['bom_no'] = bomNo
        formData['bom_description'] = bomDescription
        formData['bom_unit_of_measure_id'] = bomUnitOfMeasure
        formData['bom_lines'] = materials

        if(areAllValid(startingPerpetualInventoryUpdatedAt, currentPerpetualInventoryUpdatedAt) && changedStartingPerpetualInventory) {
            const areSameDay = dayjs(startingPerpetualInventoryUpdatedAt).isSame(currentPerpetualInventoryUpdatedAt, 'day')
            if(areSameDay) {
                const areSame = dayjs(startingPerpetualInventoryUpdatedAt).isSame(currentPerpetualInventoryUpdatedAt)
                if(!areSame) {
                    const isConfirm = await Swal.fire({
                        title: t('caution'),
                        text: t('calculated_inventory_warning'),
                        icon: 'warning',
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FEB806',
                        reverseButtons: true,
                        showCancelButton: true,

                        confirmButtonText: t('yes'),
                        cancelButtonText: t('negatory')
                    }).then((result) => {
                        return result.isConfirmed
                    })
                    formData['should_override_perpetual_inventory'] = isConfirm ? true : false
                } else {
                    formData['should_override_perpetual_inventory'] = true
                }
            } else {
                formData['should_override_perpetual_inventory'] = true
            }
        } else {
            formData['should_override_perpetual_inventory'] = false
        }

        await axios.put(`/api/items/${params.id}`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            setChangedStartingPerpetualInventory(false)
        }).catch(({ response }) => {
            if (response.status === 422) {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const getDocuments = async () => {
        setIsLoading(true)
        await axios.get(`/api/item-documents?item_id=${params.id}`, config)
            .then(res => {
                const data = res.data
                setDocuments(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const materialsColumns = [
        {
            field: 'item_no',
            headerName: t('item_number_abbr') + ' *',
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialNo params={params} t={t} materialsList={materialsList} setMaterialsList={setMaterialsList} handleSelectMaterial={handleSelectMaterial} />
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialDescription params={params} t={t} />
        },
        {
            field: 'qty_per_assembly',
            headerName: t('qty_per_assembly') + ' *',
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialQtyPerAssembly params={params} t={t} materialsList={materialsList} handleMaterialQty={handleMaterialQty}  />
        },
        {
            field: 'unit_of_measure_code',
            headerName: t('unit_of_measure'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialUnitOfMeasure params={params} t={t} />
        },
        {
            field: 'scrap',
            headerName: `${t('scrap')} (%)`,
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialScrap params={params} t={t} materialsList={materialsList} handleMaterialScrap={handleMaterialScrap} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialUnitPrice params={params} t={t} materialsList={materialsList} currencySymbol={currencySymbol} handleMaterialUnitPrice={handleMaterialUnitPrice} />
        },
        {
            field: 'total_price',
            headerName: t('total_price'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialTotalPrice params={params} t={t} currencySymbol={currencySymbol} calculateTotalCost={calculateTotalCost} />,
            valueGetter: (params) => params.row.qty_per_assembly * params.row.unit_price,
            sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2)
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            cellClassName: 'padding-0',
            renderCell: (params) => <MaterialActions params={params} materials={materials} setMaterials={setMaterials} setMaterialsList={setMaterialsList} />
        }
    ]

    const hasEnabledMaterials = materialsList.find((mlitem) => mlitem.disabled !== true)

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box>

                <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t('update_item')} - {itemNo}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <Link to='/items'><i className="fa-solid fa-plus"></i></Link>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-white h-auto min-h-full'>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" variant='scrollable'>
                                <Tab sx={{ textTransform: 'none' }} value="general" label={t('general')} icon={<i className="fa-solid fa-circle-info"></i>} iconPosition='start' />
                                <Tab sx={{ textTransform: 'none' }} value="dimensions" label={t('dimensions')} icon={<i className="fa-solid fa-pen-ruler"></i>} iconPosition='start' />
                                <Tab sx={{ textTransform: 'none' }} value="planning" label={t('planning')} icon={<i className="fa-solid fa-box-open"></i>} iconPosition='start' />
                                <Tab sx={{ textTransform: 'none' }} value="bom" label={t('bill_of_materials')} icon={<i className="fa-solid fa-table"></i>} iconPosition='start' />
                            </TabList>
                        </Box>
                        <div className='w-full bg-white h-full'>
                            <TabPanel value="general">
                                <div>
                                    <form onSubmit={(e) => e.preventDefault()} className='flex justify-center flex-col items-start mt-2'>
                                        {/* ITEM TYPE */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('item_type')} *</p>
                                            <div className='w-full'>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <Select
                                                        value={!isLoadingItemTypes ? itemTypeId ?? '' : ''}
                                                        onChange={handleChangeItemType}
                                                        sx={{
                                                            width: '100%',
                                                            background: '#FCFCFC',
                                                            '.MuiOutlinedInput-input': {
                                                                padding: '9px !important'
                                                            },
                                                            boxShadow: 'none',
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #EEEFF2 !important'
                                                            }
                                                        }}
                                                    >
                                                        <MenuItem disabled value={''} sx={{display: 'none'}}><em>{t('item_type')}</em></MenuItem>
                                                        {itemTypes.map((itemType, index) =>
                                                            <MenuItem
                                                                key={`itypes-${index}`}
                                                                value={itemType.id}
                                                            >
                                                                    {t(itemType.key)}
                                                            </MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>

                                        {/* PALLET TYPE */}
                                        {itemTypeKey === 'pallet' ? (
                                            <div className='w-full pb-4'>
                                                <p className="text-[14px] text-[#718096]">{t('pallet_type')} *</p>
                                                <div className='w-full'>
                                                    <FormControl sx={{ width: '100%' }}>
                                                        <Select
                                                            value={palletTypeId}
                                                            onChange={handleChangePalletType}
                                                            sx={{
                                                                width: '100%',
                                                                background: '#FCFCFC',
                                                                '.MuiOutlinedInput-input': {
                                                                    padding: '9px !important'
                                                                },
                                                                boxShadow: 'none',
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    border: '1px solid #EEEFF2 !important'
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem disabled value={''} sx={{display: 'none'}}><em>{t('pallet_type')}</em></MenuItem>
                                                            {palletTypes.map((palletType, index) =>
                                                                <MenuItem
                                                                    key={`ptypes-${index}`}
                                                                    value={palletType.id}
                                                                >
                                                                        {t(palletType.key)}
                                                                </MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        ) : null}

                                        {/* ITEM NO. */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('no')} *</p>
                                            <div className='w-full'>
                                                <TextField
                                                    sx={{
                                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                    }}
                                                    type="text" className='w-full' name="item_no" value={itemNo} onChange={(e) => { setItemNo(e.target.value) }} disabled />
                                            </div>
                                        </div>

                                        {/* DESCRIPTION */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('description')} *</p>
                                            <div className='w-full'>
                                                <TextField
                                                    sx={{
                                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                    }}
                                                    type="text" className='w-full' name="description" value={description} onChange={(e) => { setDescription(e.target.value) }} />
                                            </div>
                                        </div>

                                        {/* DESCRIPTION 2 */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('description2')}</p>
                                            <div className='w-full'>
                                                <TextField
                                                    sx={{
                                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                    }}
                                                    type="text" className='w-full' name="description_2" value={additionalDesc} onChange={(e) => { setAdditionalDesc(e.target.value) }} />
                                            </div>
                                        </div>

                                        {/* GRADE CODE */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('grade_code')}</p>
                                            <div className='w-full'>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="general-gc-autocomplete"
                                                        sx={{
                                                            width: '100%',
                                                            background: '#FCFCFC',
                                                            '.MuiOutlinedInput-input': {
                                                                padding: '0 !important'
                                                            },
                                                            boxShadow: 'none',
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #EEEFF2 !important'
                                                            }
                                                        }}
                                                        value={gradeCodeId ? { id: gradeCodeId, label: gradeCodes?.find((grade) => grade.id === gradeCodeId )?.code || '' } : null}
                                                        options={gradeCodes.length > 0 ? gradeCodes?.map((option) => ({ id: option.id, label: option.code, disabled: option.disabled })) : []}
                                                        getOptionDisabled={(option) => !!option.disabled }
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        renderOption={(props, option) => {
                                                            return (
                                                                <li {...props} key ={`general-gc-${option.id}`}>
                                                                    {option.label}
                                                                </li>
                                                            )
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        onChange={(a, b) => {a.preventDefault(); setGradeCodeId(b?.id || '')} }
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>

                                        {/* UNIT OF MEASURE */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('unit_of_measure')} *</p>
                                            <div className='w-full'>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <Autocomplete
                                                        disablePortal
                                                        disableClearable
                                                        id="general-uom-autocomplete"
                                                        sx={{
                                                            width: '100%',
                                                            background: '#FCFCFC',
                                                            '.MuiOutlinedInput-input': {
                                                                padding: '0 !important'
                                                            },
                                                            boxShadow: 'none',
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #EEEFF2 !important'
                                                            }
                                                        }}
                                                        value={unitOfMeasureId ? { id: unitOfMeasureId, label: unitsOfMeasure?.find((unit) => unit.id === unitOfMeasureId )?.code || '' } : null}
                                                        options={unitsOfMeasure.length > 0 ? unitsOfMeasure?.map((option) => ({ id: option.id, label: option.code, disabled: option.disabled })) : []}
                                                        getOptionDisabled={(option) => !!option.disabled }
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        renderOption={(props, option) => {
                                                            return (
                                                                <li {...props} key ={`general-uom-${option.id}`}>
                                                                    {option.label}
                                                                </li>
                                                            )
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        onChange={(a, b) => handleChangeUnitOfMeasureAutocomplete(a, b?.id || '') }
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>

                                        {/* UNIT PRICE */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('unit_price') + ` (${currencySymbol})`}</p>
                                            <div className='w-full'>
                                                <TextField
                                                    sx={{
                                                        width: '100%',
                                                        background: '#FCFCFC',
                                                        '.MuiOutlinedInput-input': {
                                                            padding: '9px !important'
                                                        },
                                                        boxShadow: 'none',
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: '1px solid #EEEFF2 !important'
                                                        }
                                                    }}
                                                    type="text"
                                                    className='w-full'
                                                    name="unit_price"
                                                    value={unitPrice}
                                                    onChange={(e) => handleNumber({
                                                        event: e,
                                                        setter: setUnitPrice
                                                    })}
                                                    onBlur={e => handleZero({
                                                        event: e,
                                                        setter: setUnitPrice
                                                    })}
                                                    onFocus={e => deleteZero({
                                                        event: e,
                                                        setter: setUnitPrice,
                                                        value: unitPrice
                                                    })}
                                                />
                                            </div>
                                        </div>

                                        { /* DOCUMENTS */ }
                                        <div
                                            className='w-full h-fit p-6 pb-4 pt-0 mb-4 rounded-md'
                                            style={{
                                                boxShadow: `0px 1px 2px 0px #1018280F, 0px 1px 2px 0px #1018280F, 0px -1px 4px 0px #1018280F`
                                            }}
                                        >
                                            <div className='pb-6 pt-1'>
                                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('documents')}</p>
                                            </div>

                                            <div className='w-full pb-4 flex flex-col align-center gap-1'>
                                                <span style={{textAlign: 'center'}}>
                                                    <Button onClick={handleAddDocumentClick} variant="contained" component="label" sx={{ backgroundColor: '#607d8b', "&:hover": { backgroundColor: '#6c8794' } }}>
                                                        <div className='flex items-center gap-3'><i className="fa-solid fa-cloud-arrow-up"></i> <p>{t('upload')}</p> </div>
                                                    </Button>
                                                </span>
                                                <div className='w-[120px] text-[12px] text-[#6c8794] self-center'><p>{documentSizeLimit}MB max</p> </div>
                                                <input ref={documentInputRef} key={`file_input_key-${documentFileInputKey}`} hidden type="file" name="add-document" onChange={e => handleAddDocument(e)} />
                                            </div>
                                            {
                                                documents?.map((document, index) => (
                                                    <div className='pt-5 flex justify-between gap-2' key={`doc${index}`}>
                                                        <p className='text-blue-900 underline line-clamp-3'><a href={document?.document_url} target="_blank">{document?.document_name}</a></p>
                                                        <div className='flex gap-2 items-center'>
                                                            <a href={document?.document_url} target="_blank" className='text-neutral-500'><Tooltip disableInteractive title={t('view')} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></a>
                                                            {['master_admin', 'office_manager'].includes(user?.role) ? (
                                                                <button className='text-neutral-500' onClick={e => deleteDocument(e, document.id)}><Tooltip disableInteractive title={t('delete')} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('blocked')} </p>
                                            <div className='w-full'>
                                                <Switch checked={blocked} onChange={handleBlocked} inputProps={{ 'aria-label': 'controlled' }} />
                                            </div>
                                        </div>
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('blockedSales')} </p>
                                            <div className='w-full'>
                                                <Switch disabled={disableBoth} checked={blockedSO} onChange={handleBlockedSO} inputProps={{ 'aria-label': 'controlled' }} />
                                            </div>
                                        </div>
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('blockedPurchase')} </p>
                                            <div className='w-full'>
                                                <Switch disabled={disableBoth} checked={blockedPO} onChange={handleBlockedPO} inputProps={{ 'aria-label': 'controlled' }} />
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </TabPanel>
                            <TabPanel value="dimensions">
                                <div>
                                    <form onSubmit={(e) => e.preventDefault()} className='flex justify-center flex-col items-start mt-2'>
                                        {/* MEASUREMENT SYSTEMS */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('measurement_system')}</p>
                                            <div className='w-full'>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="measurement-system-radio-buttons-group"
                                                        name="measurement-system-radio-buttons-group"
                                                        value={measurementSystem}
                                                        onChange={handleChangeMeasurementSystem}
                                                    >
                                                        <FormControlLabel value={'imperial'} control={<Radio />} label={t('imperial')} />
                                                        <FormControlLabel value={'metric'} control={<Radio />} label={t('metric')} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>

                                        {/* DIMENSIONS */}
                                        <div className='w-full md:w-1/2 lg:w-1/3 pb-4'>
                                            <Stack spacing={2}>
                                                {/* THICKNESS */}
                                                {includes(['lumber'], itemTypeKey) ? (
                                                    <Stack direction='row' alignItems='center' spacing={1}>
                                                        <Typography
                                                            color={"#718096"}
                                                            sx={{
                                                                minWidth: '70px',
                                                                width: '15%'
                                                            }}
                                                        >
                                                            {t('thickness')}
                                                        </Typography>

                                                        <TextField
                                                            sx={{
                                                                background: '#FCFCFC',
                                                                '.MuiOutlinedInput-input': {
                                                                    padding: '9px !important'
                                                                },
                                                                boxShadow: 'none',
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    border: '1px solid #EEEFF2 !important'
                                                                },
                                                                flexGrow: '1'
                                                            }}
                                                            name="thickness"
                                                            value={thicknessValue}
                                                            onChange={(e) => handleNumber({
                                                                event: e,
                                                                setter: setThicknessValue
                                                            })}
                                                            onBlur={e => handleZero({
                                                                event: e,
                                                                setter: setThicknessValue
                                                            })}
                                                            onFocus={e => deleteZero({
                                                                event: e,
                                                                setter: setThicknessValue,
                                                                value: thicknessValue
                                                            })}
                                                        />

                                                        <FormControl
                                                            sx={{
                                                                minWidth: '70px',
                                                                width: '15%'
                                                            }}
                                                        >
                                                            <Select
                                                                autoWidth
                                                                value={thicknessUnit}
                                                                onChange={(e) => { setThicknessUnit(e.target.value) }}
                                                                sx={{
                                                                    background: '#FCFCFC',
                                                                    '.MuiOutlinedInput-input': {
                                                                        padding: '9px !important'
                                                                    },
                                                                    boxShadow: 'none',
                                                                    '.MuiOutlinedInput-notchedOutline': {
                                                                        border: '1px solid #EEEFF2 !important'
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem value={measurementSystem === 'imperial' ? 'in' : 'cm'}>
                                                                    {measurementSystem === 'imperial' ? 'in' : 'cm'}
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Stack>
                                                ) : null}

                                                {/* WIDTH */}
                                                {includes(['lumber', 'pallet', 'component'], itemTypeKey) ? (
                                                    <Stack direction='row' alignItems='center' spacing={1}>
                                                        <Typography
                                                            color={"#718096"}
                                                            sx={{
                                                                minWidth: '70px',
                                                                width: '15%'
                                                            }}
                                                        >
                                                            {t('width')}
                                                        </Typography>

                                                        <TextField
                                                            sx={{
                                                                background: '#FCFCFC',
                                                                '.MuiOutlinedInput-input': {
                                                                    padding: '9px !important'
                                                                },
                                                                boxShadow: 'none',
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    border: '1px solid #EEEFF2 !important'
                                                                },
                                                                flexGrow: '1'
                                                            }}
                                                            name="width"
                                                            value={widthValue}
                                                            onChange={(e) => handleNumber({
                                                                event: e,
                                                                setter: setWidthValue
                                                            })}
                                                            onBlur={e => handleZero({
                                                                event: e,
                                                                setter: setWidthValue
                                                            })}
                                                            onFocus={e => deleteZero({
                                                                event: e,
                                                                setter: setWidthValue,
                                                                value: widthValue
                                                            })}
                                                        />

                                                        <FormControl
                                                            sx={{
                                                                minWidth: '70px',
                                                                width: '15%'
                                                            }}
                                                        >
                                                            <Select
                                                                autoWidth
                                                                value={widthUnit}
                                                                onChange={(e) => { setWidthUnit(e.target.value) }}
                                                                sx={{
                                                                    background: '#FCFCFC',
                                                                    '.MuiOutlinedInput-input': {
                                                                        padding: '9px !important'
                                                                    },
                                                                    boxShadow: 'none',
                                                                    '.MuiOutlinedInput-notchedOutline': {
                                                                        border: '1px solid #EEEFF2 !important'
                                                                    },
                                                                    flexGrow: '1'
                                                                }}
                                                            >
                                                                <MenuItem value={measurementSystem === 'imperial' ? 'in' : 'cm'}>
                                                                    {measurementSystem === 'imperial' ? 'in' : 'cm'}
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Stack>
                                                ) : null}

                                                {/* HEIGHT */}
                                                {includes(['lumber', 'pallet', 'component'], itemTypeKey) ? (
                                                    <Stack direction='row' alignItems='center' spacing={1}>
                                                        <Typography
                                                            color={"#718096"}
                                                            sx={{
                                                                minWidth: '70px',
                                                                width: '15%'
                                                            }}
                                                        >
                                                            {t('length')}
                                                        </Typography>

                                                        <TextField
                                                            sx={{
                                                                background: '#FCFCFC',
                                                                '.MuiOutlinedInput-input': {
                                                                    padding: '9px !important'
                                                                },
                                                                boxShadow: 'none',
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    border: '1px solid #EEEFF2 !important'
                                                                },
                                                                flexGrow: '1'
                                                            }}
                                                            name="length"
                                                            value={lengthValue}
                                                            onChange={(e) => handleNumber({
                                                                event: e,
                                                                setter: setLengthValue
                                                            })}
                                                            onBlur={e => handleZero({
                                                                event: e,
                                                                setter: setLengthValue
                                                            })}
                                                            onFocus={e => deleteZero({
                                                                event: e,
                                                                setter: setLengthValue,
                                                                value: lengthValue
                                                            })}
                                                        />

                                                        <FormControl
                                                            sx={{
                                                                minWidth: '70px',
                                                                width: '15%'
                                                            }}
                                                        >
                                                            <Select
                                                                autoWidth
                                                                value={lengthUnit}
                                                                onChange={(e) => setLengthUnit(e.target.value) }
                                                                sx={{
                                                                    background: '#FCFCFC',
                                                                    '.MuiOutlinedInput-input': {
                                                                        padding: '9px !important'
                                                                    },
                                                                    boxShadow: 'none',
                                                                    '.MuiOutlinedInput-notchedOutline': {
                                                                        border: '1px solid #EEEFF2 !important'
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem value={measurementSystem === 'imperial' ? 'in' : 'cm'}>
                                                                    {measurementSystem === 'imperial' ? 'in' : 'cm'}
                                                                </MenuItem>
                                                                <MenuItem value={measurementSystem === 'imperial' ? 'ft' : 'm'}>
                                                                    {measurementSystem === 'imperial' ? 'ft' : 'm'}
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Stack>
                                                ) : null}

                                                {/* BOARD FEET */}
                                                {(includes(['lumber'], itemTypeKey) && measurementSystem === 'imperial') ? (
                                                    <Stack direction='row' alignItems='center' spacing={1}>
                                                        <Typography
                                                            color={"#718096"}
                                                            textAlign='right'
                                                            sx={{
                                                                flexGrow: 1
                                                            }}
                                                        >
                                                            {t('total')}
                                                        </Typography>

                                                        <Typography
                                                            fontWeight='500'
                                                            textAlign='right'
                                                            fontSize='21px'
                                                            lineHeight='21px'
                                                            sx={{
                                                                minWidth: '120px'
                                                            }}
                                                        >
                                                            {round((boardFeet), 3)}
                                                        </Typography>

                                                        <Typography
                                                            color={"#718096"}
                                                        >
                                                            {t('board_feet')}
                                                        </Typography>
                                                    </Stack>
                                                ) : null}
                                            </Stack>
                                        </div>
                                    </form>
                                </div>
                            </TabPanel>
                            <TabPanel value="planning">
                                <div>
                                    <form onSubmit={(e) => e.preventDefault()} className='flex justify-center flex-col items-start mt-2'>
                                        {/* <div className="mb-5 flex gap-2 items-center">
                                        <label htmlFor="negative_inventory" className='text-sm'>{t('negative_inventory')}</label>
                                        <Checkbox checked={negativeInventory} onChange={e => setNegativeInventory(e.target.checked ? true : false)} />
                                    </div> */}

                                        {/* <TextField type="text" variant='standard' label={t('minimum_stock_quantity')} sx={{ marginBottom: '20px' }} className='w-full mb-5 px-0 pt-0' name="minimum_stock_quantity" value={minimumStockQuantity} onChange={e => handleQty(e)} onBlur={e => handleZeroRest(e)} />

                                <div className="mb-5 flex gap-2 items-center">
                                    <Tooltip disableInteractive  title={t('info-stock')} placement='bottom'>
                                        <i className="fa-solid fa-circle-info"></i>
                                    </Tooltip>
                                    <label htmlFor="stockout_warning" className='text-sm'>{t('stockout_warning')}</label>
                                    <Checkbox checked={stockoutWarning} onChange={e => setStockoutWarninig(e.target.checked ? true : false)} />
                                </div> */}

                                        <div className='w-full flex gap-5 items-center pb-4'>
                                            <p className="text-[14px] text-[#718096]">
                                                <Tooltip disableInteractive title={t('info_inventory')} placement='bottom'>
                                                    <i className="fa-solid fa-circle-info"></i>
                                                </Tooltip>
                                                <span> {t('inventory_item')}</span>
                                            </p>
                                            <Checkbox checked={inventoryItem} onChange={e => setInventoryItem(e.target.checked ? true : false)} />
                                        </div>

                                        {inventoryItem ? (
                                            <div className='w-full pb-4'>
                                                <p className="text-[14px] text-[#718096]">{t('starting_perpetual_inventory')}</p>
                                                <Stack direction='row'>
                                                    <div className='w-1/2'>
                                                        <TextField
                                                            sx={{
                                                                width: '100%',
                                                                background: '#FCFCFC',
                                                                '.MuiOutlinedInput-root': {
                                                                    borderTopRightRadius: '0px',
                                                                    borderBottomRightRadius: '0px'
                                                                },
                                                                '.MuiOutlinedInput-input': {
                                                                    padding: '9px !important'
                                                                },
                                                                boxShadow: 'none',
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    border: '1px solid #EEEFF2 !important'
                                                                }
                                                            }}
                                                            type="text"
                                                            className='w-full'
                                                            name="starting_perpetual_inventory"
                                                            value={startingPerpetualInventory}
                                                            onChange={(e) => {
                                                                handleInteger({
                                                                    event: e,
                                                                    setter: setStartingPerpetualInventory,
                                                                    allowNegatives: true
                                                                });
                                                                setChangedStartingPerpetualInventory(true)
                                                            }}
                                                            onBlur={e => handleZero({
                                                                event: e,
                                                                setter: setStartingPerpetualInventory
                                                            })}
                                                            onFocus={e => deleteZero({
                                                                event: e,
                                                                setter: setStartingPerpetualInventory,
                                                                value: startingPerpetualInventory
                                                            })}
                                                        />
                                                    </div>

                                                    <div className='w-1/2'>
                                                        <FormControl sx={{ width: '100%' }}>
                                                            <Select
                                                                value={unitOfMeasureId}
                                                                displayEmpty
                                                                disabled
                                                                sx={{
                                                                    width: '100%',
                                                                    background: '#FCFCFC',
                                                                    borderTopLeftRadius: '0px',
                                                                    borderBottomLeftRadius: '0px',
                                                                    ".MuiSelect-icon": {
                                                                        display: 'none !important'
                                                                    },
                                                                    '.MuiOutlinedInput-input': {
                                                                        padding: '9px !important'
                                                                    },
                                                                    boxShadow: 'none',
                                                                    '.MuiOutlinedInput-notchedOutline': {
                                                                        border: '1px solid #EEEFF2 !important'
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>{t('unit_of_measure')}</em>
                                                                </MenuItem>
                                                                {unitsOfMeasure.map((item, index) => <MenuItem key={index} value={item.id}>{item.code}</MenuItem>)}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Stack>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('production_type')}</p>
                                            <div className='w-full'>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="planning-pt-autocomplete"
                                                        sx={{
                                                            width: '100%',
                                                            background: '#FCFCFC',
                                                            '.MuiOutlinedInput-input': {
                                                                padding: '0 !important'
                                                            },
                                                            boxShadow: 'none',
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #EEEFF2 !important'
                                                            }
                                                        }}
                                                        value={productionTypeId ? { id: productionTypeId, label: companyProductionTypes?.find((type) => type.id === productionTypeId )?.code || '' } : null}
                                                        options={companyProductionTypes.length > 0 ? companyProductionTypes?.map((option) => ({ id: option.id, label: option.code })) : []}
                                                        renderOption={(props, option) => {
                                                            return (
                                                                <li {...props} key ={`planning-pt-${option.id}`}>
                                                                    {option.label}
                                                                </li>
                                                            )
                                                        }}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        onChange={(a, b) => {a.preventDefault(); setProductionTypeId(b?.id || '')} }
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </TabPanel>
                            <TabPanel value="bom">
                                <div>
                                    <form onSubmit={(e) => e.preventDefault()} className='flex justify-center flex-col items-start mt-2'>
                                        <div
                                            className='bg-white p-6 pb-[12px] pt-0 rounded-md w-full h-fit w-full'
                                            style={{
                                                boxShadow: `0px 1px 2px 0px #1018280F, 0px 1px 2px 0px #1018280F, 0px -1px 4px 0px #1018280F`
                                            }}
                                        >
                                            <div className='pb-6 pt-1 flex gap-3 items-center'>
                                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('general_information')}</p>
                                                {materials.length <= 0 ? (
                                                    <span style={{ color: '#0000006f', fontStyle: 'italic' }} className='text-[12px]'><i className="fa-solid fa-circle-info text-[#0000006f] mr-[5px]"></i>{t('please_add_line_to_start_defining_bom')}</span>
                                                ) : null}
                                            </div>

                                            <div className='w-full pb-4'>
                                                <p className="text-[14px] text-[#718096]">{t('no')} {materials.length > 0 ? '*' : null}</p>
                                                <div className='w-full'>
                                                    <TextField
                                                        disabled={materials.length <= 0}
                                                        sx={{
                                                            width: '100%',
                                                            background: '#FCFCFC',
                                                            '.MuiOutlinedInput-input': {
                                                                padding: '9px !important'
                                                            },
                                                            boxShadow: 'none',
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #EEEFF2 !important'
                                                            }
                                                        }}
                                                        type="text"
                                                        className='w-full'
                                                        name="bom_no"
                                                        value={bomNo}
                                                        onChange={(e) => { setBomNo(e.target.value) }}
                                                    />
                                                </div>
                                            </div>

                                            <div className='w-full pb-4'>
                                                <p className="text-[14px] text-[#718096]">{t('description')} {materials.length > 0 ? '*' : null}</p>
                                                <div className='w-full'>
                                                    <TextField
                                                        disabled={materials.length <= 0}
                                                        sx={{
                                                            width: '100%',
                                                            background: '#FCFCFC',
                                                            '.MuiOutlinedInput-input': {
                                                                padding: '9px !important'
                                                            },
                                                            boxShadow: 'none',
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #EEEFF2 !important'
                                                            }
                                                        }}
                                                        type="text"
                                                        className='w-full'
                                                        name="bom_description"
                                                        value={bomDescription}
                                                        onChange={(e) => { setBomDescription(e.target.value) }}
                                                    />
                                                </div>
                                            </div>

                                            <div className='w-full pb-4'>
                                                <p className="text-[14px] text-[#718096]">{t('unit_of_measure')} {materials.length > 0 ? '*' : null}</p>
                                                <div className='w-full'>
                                                    <FormControl sx={{ width: '100%' }}>
                                                        <Autocomplete
                                                            disablePortal
                                                            disableClearable
                                                            disabled={materials.length <= 0}
                                                            sx={{
                                                                width: '100%',
                                                                background: '#FCFCFC',
                                                                '.MuiOutlinedInput-input': {
                                                                    padding: '0 !important'
                                                                },
                                                                boxShadow: 'none',
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    border: '1px solid #EEEFF2 !important'
                                                                }
                                                            }}
                                                            id="bom-uom-autocomplete"
                                                            value={bomUnitOfMeasure ? { id: bomUnitOfMeasure, label: unitsOfMeasure?.find((unit) => unit.id === bomUnitOfMeasure )?.code || '' } : null}
                                                            options={unitsOfMeasure?.map((option) => ({ id: option.id, label: option.code }))}
                                                            renderOption={(props, option) => {
                                                                return (
                                                                    <li {...props} key ={`bom-uom-${option.id}`}>
                                                                        {option.label}
                                                                    </li>
                                                                )
                                                            }}
                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                            renderInput={(params) => <TextField {...params} autoFocus={true} />}
                                                            onChange={(a, b) => setBomUnitOfMeasure(b?.id || '') }
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className='bg-white p-6 pb-[12px] pt-0 mt-6 rounded-md w-full h-fit w-full'
                                            style={{
                                                boxShadow: `0px 1px 2px 0px #1018280F, 0px 1px 2px 0px #1018280F, 0px -1px 4px 0px #1018280F`
                                            }}
                                        >
                                            <div className='pt-1'>
                                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('lines')}</p>
                                            </div>

                                            <div className='flex justify-end p-5 pt-0 border-b'>
                                                <Tooltip disableInteractive title={!hasEnabledMaterials ? t('no_sub_assemblies_found') : t('add_new_line')} placement='left'>
                                                    <span>
                                                        <AddButton disabled={isLoadingPotentialSubAssemblies || !hasEnabledMaterials} onClick={handleAddMaterial}><i className="fa-solid fa-plus"></i></AddButton>
                                                    </span>
                                                </Tooltip>
                                            </div>

                                            {/* MATERIALS */}
                                            <div className='relative border-b border-l border-r rounded'>
                                                {isLoadingPotentialSubAssemblies ? (
                                                    <Loading position="absolute" />
                                                ) : null}

                                                {
                                                    materials.length > 0 ?
                                                        <div className='flex justify-end w-full p-2'>
                                                            <button disabled={isLoadingPotentialSubAssemblies} className='px-2 py-1 text-white bg-red-600 font-bold rounded-md mb-1 roboto text-xs uppercase' onClick={handleClearAllMaterialLines}>{t('clear_all')}</button>
                                                        </div>
                                                        :
                                                        null
                                                }

                                                <Materials items={materials} columns={materialsColumns} />
                                            </div>

                                            <div className='flex justify-end w-full p-5'>
                                                <div className='flex flex-col gap-y-4 w-full sm:w-1/3'>
                                                    <div className='flex justify-between items-center'>
                                                        <p className="font-[400] text-[#88909C] text-[14px]">{t('total_cost')}</p>
                                                        <p className='font-[500] text-[16px]'>{currencySymbol}{totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                                    </div>
                                                    <div className='flex justify-between items-center'>
                                                        <p className="font-[400] text-[#88909C] text-[14px]">{t('totalQty')}</p>
                                                        <p className='font-[500] text-[16px]'>{totalQty}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </TabPanel>
                        </div>
                    </TabContext>
                    <div className='w-full flex justify-end px-6 pb-14'>
                        <button type="button" onClick={updateItem} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC]'>
                            <span className='pr-2'><i className="fa-solid fa-clock-rotate-left"></i></span> {t('update')}
                        </button>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default UpdateItems

const MaterialNo = ({ params, t, materialsList, setMaterialsList, handleSelectMaterial }) => {
    const [material, setMaterial] = useState(' ')
    const [exists, setExists] = useState(true)

    const handleChange = (e) => {
        setMaterial(prev => {
            if (prev == ' ') {
                return prev
            }
            if (prev) {
                prev.disabled = false
            }
            return prev
        })
        setMaterial(e.target.value)
        handleSelectMaterial(e.target.value, params.row.id)
        setMaterialsList(prev => {
            const target = prev.find(m => m.id == e.target.value.id)
            target.disabled = true
            return prev
        })
    }

    useEffect(() => {
        if (materialsList.length >= 0) {
            if(params.row.item_id !== '') {
                const target = materialsList?.find((m) => m.id === params.row.item_id)

                if(target) {
                    target.disabled = true
                    setMaterial(target)

                    setExists(true)
                } else {
                    setMaterial(' ')
                    setExists(false)
                }
            } else {
                setExists(true)
            }
        }
    }, [materialsList])

    return (
        <>
            {exists ? (
                <FormControl variant="standard" sx={{ width: '100%' }}>
                    <Select defaultValue=' ' value={material} onChange={e => handleChange(e)}>
                        <MenuItem value=' ' disabled sx={{ fontStyle: 'italic', display: 'none' }}>{t('select_item')}</MenuItem>
                        {
                            materialsList?.map((m, index) => (
                                <MenuItem value={m} key={`materialslist-${m.id}`} disabled={m.disabled}>{m.item_no}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            ) : (
                <TextField
                    type="text"
                    variant='standard'
                    disabled
                    className='w-full mb-5 px-0 pt-0'
                    value={params.row.item_no}
                />
            )}
        </>
    )
}

const MaterialDescription = ({ params, t }) => {
    return (
        <TextField
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={params.value}
            disabled
        />
    )
}

const MaterialQtyPerAssembly = ({ params, t, materialsList, handleMaterialQty }) => {
    const [qty, setQty] = useState(params.value)
    const [debouncedQty] = useDebounce(qty, 250)
    const [disabled, setDisabled] = useState(false)

    const handleQty = (e) => {
        const regex = /^(-?\d{0,5})$/i
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty('')
            } else {
                setQty(e.target.value)
            }
        }
    }

    useEffect(() => {
        if(debouncedQty && isFinite(debouncedQty)) {
            handleMaterialQty(debouncedQty, params.row.id)
        } else {
            handleMaterialQty(0, params.row.id)
        }
    }, [debouncedQty])

    useEffect(() => {
        if (materialsList.length >= 0) {
            if(params.row.item_id !== '') {
                const target = materialsList?.find((m) => m.id === params.row.item_id)

                if(target) {
                    setDisabled(false)
                } else {
                    setDisabled(true)
                }
            } else {
                setDisabled(false)
            }
        }
    }, [materialsList])

    return (
        <TextField
            disabled={params.row.item_id === '' || disabled}
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={qty}
            onChange={(e) => { handleQty(e) }}
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const MaterialUnitOfMeasure = ({ params, t }) => {
    return (
        <TextField
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={params.value}
            disabled
        />
    )
}

const MaterialScrap = ({ params, t, materialsList, handleMaterialScrap }) => {
    const [scrap, setScrap] = useState(params.value)
    const [debouncedScrap] = useDebounce(scrap, 250)
    const [disabled, setDisabled] = useState(false)

    const handleScrap = (e) => {
        const regex = /^(-?\d*)((\.(\d{0,2})?)?)$/i
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setScrap('')
            } else if(e.target.value > 100) {
                Swal.fire({
                    icon: "warning",
                    text: t('scrap_cannot_exceed_x_pct', {x: 100}),
                    customClass: 'warning2',
                    showCloseButton: true,
                    iconColor: '#FFCA28'
                })
                setScrap(100)
            } else {
                setScrap(e.target.value)
            }
        }
    }

    useEffect(() => {
        if(debouncedScrap && isFinite(debouncedScrap)) {
            handleMaterialScrap(debouncedScrap, params.row.id)
        } else {
            handleMaterialScrap(0, params.row.id)
        }
    }, [debouncedScrap])

    useEffect(() => {
        if (materialsList.length >= 0) {
            if(params.row.item_id !== '') {
                const target = materialsList?.find((m) => m.id === params.row.item_id)

                if(target) {
                    setDisabled(false)
                } else {
                    setDisabled(true)
                }
            } else {
                setDisabled(false)
            }
        }
    }, [materialsList])

    return (
        <TextField
            disabled={params.row.item_id === '' || disabled}
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={scrap}
            onChange={(e) => { handleScrap(e) }}
            onBlur={e => handleZero({
                event: e,
                setter: setScrap
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setScrap,
                value: scrap
            })}
        />
    )
}

const MaterialUnitPrice = ({ params, t, materialsList, currencySymbol, handleMaterialUnitPrice }) => {
    const [unitPrice, setUnitPrice] = useState(params.value)
    const [debouncedUnitPrice] = useDebounce(unitPrice, 250)
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        setUnitPrice(params.value)
    }, [params.value])

    useEffect(() => {
        if(debouncedUnitPrice && isFinite(debouncedUnitPrice)) {
            handleMaterialUnitPrice(debouncedUnitPrice, params.row.id)
        } else {
            handleMaterialUnitPrice(0, params.row.id)
        }
    }, [debouncedUnitPrice])

    useEffect(() => {
        if (materialsList.length >= 0) {
            if(params.row.item_id !== '') {
                const target = materialsList?.find((m) => m.id === params.row.item_id)

                if(target) {
                    setDisabled(false)
                } else {
                    setDisabled(true)
                }
            } else {
                setDisabled(false)
            }
        }
    }, [materialsList])

    const handleUnitPrice = (e) => {
        const regex = /^(-?\d*)((\.(\d{0,30})?)?)$/i
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setUnitPrice('')
            } else {
                setUnitPrice(e.target.value)
            }
        }
    }

    return (
        <div className='flex items-center'>
            <span style={{lineHeight: '32px', padding: '4px 0 5px', color: (params.row.item_id === '' || disabled) ? 'rgba(0, 0, 0, 0.38)' : 'inherit'}}>{currencySymbol}</span>
            <TextField
                disabled={params.row.item_id === '' || disabled}
                type="text"
                variant='standard'
                className='w-full mb-5 px-0 pt-0'
                value={unitPrice}
                onChange={(e) => { handleUnitPrice(e) }}
                onBlur={e => handleZero({
                    event: e,
                    setter: setUnitPrice
                })}
                onFocus={e => deleteZero({
                    event: e,
                    setter: setUnitPrice,
                    value: unitPrice
                })}
            />
        </div>
    )
}

const MaterialTotalPrice = ({ params, t, currencySymbol, calculateTotalCost }) => {
    const [totalPrice, setTotalPrice] = useState(0)

    useEffect(() => {
        setTotalPrice(round(params.row.qty_per_assembly * params.row.unit_price, 2))
        calculateTotalCost()
    }, [params.row.qty_per_assembly, params.row.unit_price])

    return (
        <>{currencySymbol}{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const MaterialActions = ({ params, materials, setMaterials, setMaterialsList }) => {

    const { t } = useTranslation()

    const deleteMaterial = async () => {
        const target = materials.filter(m => m.id !== params.row.id)
        setMaterials(target)
        setMaterialsList(prev => {
            const material = prev.find(m => m.id == params.row.item_id)
            if (Boolean(material)) {
                material.disabled = false
            }
            return prev
        })
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={deleteMaterial}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
