import React from 'react';
import { useTranslation } from "react-i18next"
import { Typography, Stack, Divider, Chip } from '@mui/material';

const PctChip = ({pct = 100}) => {
    return (
        <Chip size='small' label={`${pct >= 0 ? '+' : ''}${pct}%`} variant='outlined' sx={{borderColor: pct >= 0 ? '#12B76A' : '#F04438', fontWeight: '600', fontSize: '12px'}} />
    )
}

export default function SalesRevenueCustomCardContent({curr_revenue = 0, prev_revenue = 0, pct = 100}) {
    const { t } = useTranslation()

    return (
        <div style={{position: 'relative'}}>
            <Stack>
                <Typography sx={{ fontSize: '10px', fontWeight: '500', color: '#667085' }}>{t('current_x', {x: t('year')})}</Typography>
                <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>{curr_revenue}</Typography>
                <Divider
                    sx={{
                        "&::before, &::after": {
                            borderColor: pct >= 0 ? '#12B76A' : '#F04438',
                            borderStyle: 'dashed',
                            borderWidth: '1px'
                        },
                    }}
                    textAlign='right'
                >
                    <PctChip pct={pct} />
                </Divider>
                <Typography sx={{ fontSize: '10px', fontWeight: '500', color: '#667085' }}>{t('last_x', {x: t('year')})}</Typography>
                <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>{prev_revenue}</Typography>
            </Stack>
        </div>

    );
}
