import React from 'react'

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"


const DateRange = ({ t, start, end, handleStart, handleEnd, status, disabled }) => {

    return (

        <div className='w-full'>
            {
                status == 'start' ?
                    <div className='w-full pb-4'>
                        <p className="text-[14px] text-[#718096]">{t('start_date')} *</p>
                        <div className='w-full'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["MobileDatePicker"]} >
                                    <MobileDatePicker
                                        value={start}
                                        disabled={disabled}
                                        views={["year", "month", "day"]}
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        onChange={(newValue) => handleStart(newValue)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                    </div>
                    :
                    <div className='w-full pb-4'>
                        <p className="text-[14px] text-[#718096]">{t('end_date')} *</p>
                        <div className='w-full'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["MobileDatePicker"]} >
                                    <MobileDatePicker
                                        value={end}
                                        disabled={disabled}
                                        views={["year", "month", "day"]}
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        minDate={start}
                                        maxDate={end}
                                        onChange={(newValue) => handleEnd(newValue)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                    </div>
            }
        </div >
    )
}

export default DateRange
