import React, { useState, useEffect } from 'react'

import { useParams, useNavigate } from "react-router-dom"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from '../../lib/axios'
import Swal from 'sweetalert2'
import { Box, TextField } from '@mui/material'
import { isNull } from 'lodash'

import Loading from '../../components/Loading'
import { useStateContext } from '../../context/ContextProvider'

const UpdateGradeCodes = () => {

    const { t } = useTranslation()
    const { config, pusher } = useStateContext()
    const params = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    /* fields */
    const [code, setCode] = useState('')
    const [description, setDescription] = useState('')

    useEffect(() => {
        getGradeCode()
    }, [])

    useEffect(() => {
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-gradecode-deleted`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-gradecode-updated`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-gradecode-deleted-event`, data => {
            if(Number(data.id) == Number(params.id)) {
                navigate('/grade-codes')
            }
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-gradecode-updated-event`, data => {
            if(Number(data.id) == Number(params.id)) {
                getGradeCode()
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-gradecode-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-gradecode-updated`)
        })
    }, [])

    /* KEY EVENT */
    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate(-1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    const getGradeCode = async (id = null) => {
        setIsLoading(true)
        await axios.get(`/api/grade-codes/${isNull(id) ? params.id : id}`, config)
            .then(res => {
                const salesOrder = res.data
                setCode(salesOrder.code)
                setDescription(salesOrder.description)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const updateItem = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formData = {}

        formData['code'] = code
        formData['description'] = description


        await axios.put(`/api/grade-codes/${params.id}`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            setIsLoading(false)
        }).catch(({ response }) => {
            if (response.status === 422) {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            }
            setIsLoading(false)
        })
    }

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box>

                <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t('update_grade_code')} - {code}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <Link to='/grade-codes'><i className="fa-solid fa-plus"></i></Link>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-white h-full'>
                    <div className='bg-white p-6 w-full h-fit'>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('code')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={code} onChange={(e) => { setCode(e.target.value) }} />
                            </div>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('description')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={description} onChange={(e) => { setDescription(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex justify-end px-6 pb-14'>
                        <button type="button" onClick={updateItem} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC]'>
                            <span className='pr-2'><i className="fa-solid fa-clock-rotate-left"></i></span> {t('update')}
                        </button>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default UpdateGradeCodes
