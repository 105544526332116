import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { ShowDate } from '../DailyBoardTablePurchase'
import AddButton from '../AddButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TablePagination from "@mui/material/TablePagination";
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress';
import { useStateContext } from '../../context/ContextProvider'
import Swal from 'sweetalert2'
import styles from '../driver/Assigned.module.css'
import axios from '../../lib/axios'
import UnloadingTruckIcon from '../UnloadingTruckIcon'
import { useNavigate } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'

function Row(props) {
    const { t } = useTranslation()

    const { row } = props;
    const [open, setOpen] = React.useState(false)
    const [highlight, setHighlight] = React.useState(false)
    const { config } = useStateContext()


    const highlightRowHandler = (highlight = false) => {
        setHighlight(highlight)
    }

    const clickAcceptShipmentButtonHandler = () => {
        setHighlight(true)
    }

    const clickUnloadCollectionButtonHandler = () => {
        setHighlight(true)
    }

    return (
        <React.Fragment >
            <TableRow selected={highlight} sx={{ '& > *': { borderBottom: 'unset !important' } }}>
                <TableCell sx={{ padding: '8px 16px' }}>
                    {
                        row.po_status === 'assigned_to_fork' ? (
                            <AcceptCollectionButton onClick={clickAcceptShipmentButtonHandler} highlightRow={highlightRowHandler} id={row.id} config={config} />
                        ) : (
                            <UnloadCollectionButton onClick={clickUnloadCollectionButtonHandler} highlightRow={highlightRowHandler} id={row.id} config={config} />
                        )
                    }
                </TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.po_number}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.vendor_name}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{<ShowDate date={row.requested_collection_date} />}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.pickup_trailer_no}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.po_status_name}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>
                    {
                        <ForklifterAssignedCollectionsActions id={row.id} />
                    }
                </TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell style={{ padding: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow sx={{ fontSize: "0.8rem" }}>
                                        <TableCell sx={{ width: '15%', fontSize: "inherit" }}>{t('item_no')}</TableCell>
                                        <TableCell sx={{ width: '25%', fontSize: "inherit" }}>{t('description')}</TableCell>
                                        <TableCell sx={{ width: '20%', fontSize: "inherit" }}>{t('qty_ordered')}</TableCell>
                                        <TableCell sx={{ width: '20%', fontSize: "inherit" }}>{t('qty_unloaded')}</TableCell>
                                        <TableCell sx={{ width: '20%', fontSize: "inherit" }}>{t('vendor_count')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.purchase_order_items.map((historyRow) => (
                                        <TableRow key={historyRow.id}>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.item_no}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.description}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_ordered}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_unloaded}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.vendor_count}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </React.Fragment>
    );
}

const AcceptCollectionButton = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const clickAcceptCollectionButtonHandler = () => {
        props.onClick()

        Swal.fire({
            text: t('accept_load'),
            icon: 'warning',
            customClass: 'warning2',
            iconColor: '#FEB806',
            reverseButtons: true,
            showDenyButton: true,
            showCloseButton: true,
            confirmButtonText: t('accept'),
            denyButtonText: t('negatory'),
        }).then((result) => {
            if (result.isConfirmed) {
                props.highlightRow()
                acceptCollectionHandler(props.id, props.config)
                navigate(`/collection/grade-sheet/${props.id}`, {
                    replace: true
                })

            } else if (result.isDenied || result.isDismissed) {
                props.highlightRow()
            }
        })
    }

    const acceptCollectionHandler = async (id, config) => {

        await axios.put(`/api/forklift-driver/accept-collection/${id}`, config)
            .then(({ data }) => {

            }).catch(({ response }) => {
                Swal.fire({
                    text: response.data.error.description,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            })
    }

    return (
        <AddButton hasTooltip={true} tooltipTitle={t('accept_collection')} onClick={clickAcceptCollectionButtonHandler}>
            <span className="flex items-center justify-center">
                <i className="fa-solid fa-truck" style={{ color: "#b7472a" }}></i>
            </span>
        </AddButton>
    )
}

const UnloadCollectionButton = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const clickUnloadCollectionButtonHandler = () => {
        navigate(`/collection/grade-sheet/${props.id}`, {
            replace: true
        })
    }

    return (
        <AddButton hasTooltip={true} tooltipTitle={t('view_load')} onClick={clickUnloadCollectionButtonHandler}>
            <span className="flex items-center justify-center">
                <UnloadingTruckIcon />
            </span>
        </AddButton>
    )
}

export default function CollapsibleTable({ collections }) {
    const { t } = useTranslation()

    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(10);

    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }

    return (
        <>
            <div style={{ width: '100%', mb: 2, border: 'none' }}>
                <TableContainer>
                    <Table aria-label="collapsible table" stickyHeader>
                        <TableHead>
                            <TableRow sx={{ fontSize: "0.8rem" }}>
                                <TableCell sx={{ width: '5%', fontSize: "inherit" }} />
                                <TableCell sx={{ width: '15%', fontSize: "inherit" }}>{t('purchase_order_no')}</TableCell>
                                <TableCell sx={{ width: '15%', fontSize: "inherit" }}>{t('vendor_name')}</TableCell>
                                <TableCell sx={{ width: '15%', fontSize: "inherit" }}>{t('requested_collection_date')}</TableCell>
                                <TableCell sx={{ width: '15%', fontSize: "inherit", minWidth: '160px' }}>{t('pickup_trailer_no')}</TableCell>
                                <TableCell sx={{ width: '15%', fontSize: "inherit" }}>{t('status')}</TableCell>
                                <TableCell sx={{ width: '15%', fontSize: "inherit" }}>{t('actions')}</TableCell>
                                <TableCell sx={{ width: '5%', fontSize: "inherit" }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {collections.length <= 0 ? <TableRow><TableCell colSpan={8} sx={{ textAlign: 'center' }}>{t('no_data')}</TableCell></TableRow> : null}
                            {collections?.slice(pg * rpg, pg *
                                rpg + rpg).map((row) => (
                                    <Row key={row.id} row={row} />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className='table-pagination'
                    labelRowsPerPage={t('rowsPerPage')}
                    rowsPerPageOptions={[10, 15]}
                    component="div"
                    count={collections.length}
                    rowsPerPage={rpg}
                    page={pg}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>
    );
}

const ForklifterAssignedCollectionsActions = ({ id }) => {

    const { t } = useTranslation()

    return (
        <>
            <div className='flex gap-1'>
                <a href={process.env.REACT_APP_BACKEND_URL + `/api/purchase-orders/${id}/unload-sheet?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}
                    target="_blank" download>
                    <button className='p-1.5 rounded text-neutral-500 hover:bg-neutral-100'>
                        <div>
                            <i className="fa-solid fa-eye"></i><span style={{ 'marginLeft': '7px' }}>{t('view_x', { x: t('unload_sheet') })}</span>
                        </div>
                    </button>
                </a>
            </div>
        </>
    )
}
