import { Tooltip } from '@mui/material'

const AddButton = ({ type = 'button', className, hasTooltip = false, tooltipTitle = "Placeholder Tooltip Title", disabled = false, tooltipPlacement = "bottom", ...props }) => {
    if(hasTooltip) {
        return (
            <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
                {disabled ? (
                    <div>
                        <button
                            type={type}
                            disabled={disabled}
                            className={`inline-flex items-center justify-center px-3 py-3 bg-[#F5F5F5] border-0 rounded-md font-semibold text-lg text-[#333333] tracking-widest hover:bg-[#D8D8D8] disabled:bg-[#f5f5f5] disabled:text-[#b1b1b1] focus:outline-none disabled:opacity-50 transition ease-in-out duration-150 ${className}`}
                            {...props}
                        />
                    </div>

                ) : (
                    <button
                        type={type}
                        disabled={disabled}
                        className={`inline-flex items-center justify-center px-3 py-3 bg-[#F5F5F5] border-0 rounded-md font-semibold text-lg text-[#333333] tracking-widest hover:bg-[#D8D8D8] disabled:bg-[#f5f5f5] disabled:text-[#b1b1b1] focus:outline-none disabled:opacity-50 transition ease-in-out duration-150 ${className}`}
                        {...props}
                    />
                )}

            </Tooltip>
        )
    } else {
        return (
            <button
                type={type}
                disabled={disabled}
                className={`inline-flex items-center justify-center px-3 py-3 bg-[#F5F5F5] border-0 rounded-md font-semibold text-lg text-[#333333] tracking-widest hover:bg-[#D8D8D8] disabled:[#f5f5f5] disabled:text-[#b1b1b1] focus:outline-none disabled:opacity-50 transition ease-in-out duration-150 ${className}`}
                {...props}
            />
        )
    }
}

export default AddButton
