import * as React from 'react'
import { useState, useEffect } from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import Swal from 'sweetalert2'
import { Box, Tooltip, FormControl, Select, MenuItem } from '@mui/material'
import { Link } from 'react-router-dom'
import axios from '../../lib/axios'

import AddButton from '../../components/AddButton'
import SalesContractItemsTable from '../../components/customers_vendors/SalesContractItemsTable'
import { useStateContext } from '../../context/ContextProvider'
import AddItem from '../../components/customers_vendors/AddItem'
import Loading from '../../components/Loading'
import { isNull } from 'lodash'
import { isValid } from '../../helpers/helper'

const UpdateSalesContracts = () => {

    const { t } = useTranslation()
    const params = useParams()
    const { config, pusher } = useStateContext()
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const [customerId, setCustomerId] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [shipToAddress, setShipToAddress] = useState('')
    const [shipToAddressName, setShipToAddressName] = useState('')
    const [address, setAddress] = useState([])
    const [items, setItems] = useState([])
    const [openItem, setOpenItem] = useState(false)

    useEffect(() => {
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-salescontract-deleted`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-salescontract-updated`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-salescontract-deleted-event`, data => {
            if(Number(data.id) == Number(params.id)) {
                navigate(`/customers/${data.customer_id}?tab=sales-contracts`)
            }
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-salescontract-updated-event`, data => {
            if(Number(data.id) == Number(params.id)) {
                setItems([])
                getSalesContract()
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salescontract-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salescontract-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-customer-deleted`)
        })
    }, [])

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate(-1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    useEffect(() => {
        getSalesContract(params.id)
    }, [])

    useEffect(() => {
        if (shipToAddress) {
            getShipAddress(shipToAddress)
        }
    }, [shipToAddress])

    useEffect(() => {
        if(isValid(customerId)) {
            const channelCustomerDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-customer-deleted`)

            channelCustomerDeleted.bind(`${localStorage.getItem('client_id')}-customer-deleted-event`, data => {
                if(Number(data.id) == Number(customerId)) {
                    navigate('/customers')
                }
            })
        }

        return (() => {
            if(isValid(customerId)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-customer-deleted`)
            }
        })
    }, [customerId])


    /* requests */
    const getSalesContract = async (id = null) => {
        setIsLoading(true)
        await axios.get(`/api/sales-contracts/${isNull(id) ? params.id : id}`, config)
            .then(res => {
                const salesContract = res.data
                setCustomerId(salesContract?.customer_id)
                setCustomerName(salesContract?.customer_name)
                setShipToAddress(salesContract?.ship_address_id)
                setShipToAddressName(salesContract?.ship_to_name)
                setItems(salesContract?.sales_contract_items)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getShipAddress = async (id) => {
        setIsLoading(true)
        await axios.get(`/api/ship-addresses/${id}`, config)
            .then(res => {
                const shipTo = res.data
                setAddress(shipTo)
                setIsLoading(false)
            })
    }

    const updateSalesContract = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formData = {}

        formData['customer_id'] = customerId
        formData['ship_address_id'] = shipToAddress
        formData['sales_contract_items'] = items

        await axios.put(`/api/sales-contracts/${params.id}`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            setIsLoading(false)
        }).catch(({ response }) => {
            Swal.fire({
                text: response.data.message,
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
            setIsLoading(false)
        })
    }

    /* handlers */

    const handleCloseItem = () => setOpenItem(!openItem)

    const updateItem = (id, quantity, unit_price) => {
        const itemsCopy = [...items]
        const item = itemsCopy.find(item => item.id === id)

        item.quantity = quantity
        item.unit_price = unit_price
        item.total_cost = quantity * unit_price

        setItems(itemsCopy)
    }

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box sx={{ background: '#F5F5FD', minHeight: '100vh' }}>

                <div className='flex justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#242F40] text-[35px] mr-4'>•</span> {t('editSalesContract')} - {params.id}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <Link to={`/customers/${customerId}?tab=sales-contracts`}><i className="fa-solid fa-plus"></i></Link>
                        </div>
                    </div>
                </div>
                <div className='p-5 pb-12 flex justify-between gap-3 flex-col lg:flex-row'>
                    <div className='bg-white p-6 pt-0 rounded-md w-full lg:w-2/5 h-fit edit-view'>
                        <div className='pb-11 pt-1'>
                            <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('general_information')}</p>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('customer')} *</p>
                            <div className='w-full'>
                                <FormControl sx={{ width: '100%' }}>
                                    <Select value={customerId} disabled
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}>
                                        <MenuItem value={customerId}>{customerName}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('shipToAdd')} *</p>
                            <div className='w-full'>
                                <FormControl sx={{ width: '100%' }}>
                                    <Select value={shipToAddress} disabled
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}>
                                        <MenuItem value={shipToAddress}>{shipToAddressName}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {
                            shipToAddress ?
                                <div className='w-full p-4 address'>
                                    <div className='w-full'>{address.address}</div>
                                    {shipToAddress ? <div className='w-full'>{address?.zip} {address?.city}, {address?.state}</div> : <div className='w-full'></div>}
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className='w-full lg:w-3/5 h-fit'>
                        <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                            <div className='pt-1 pl-5'>
                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('salesContractItems')}</p>
                            </div>
                            <div className='flex justify-end p-5 pt-0 border-b'>
                                <Tooltip disableInteractive title={t('addsalesContractItems')} placement='bottom'>
                                    <div>
                                        <AddButton onClick={handleCloseItem}><i className="fa-solid fa-plus"></i></AddButton>
                                    </div>
                                </Tooltip>
                            </div>
                            <div>
                                {
                                    items.length > 0 ?
                                        <div className='flex justify-end w-full p-2'>
                                            <button className='px-2 py-1 text-white bg-red-600 font-bold rounded-md mb-1 roboto text-xs uppercase' onClick={e => setItems([])}>{t('clear_all')}</button>
                                        </div>
                                        :
                                        null
                                }
                                <SalesContractItemsTable items={items} setItems={setItems} itemsLength={items.length} handler={updateItem} />
                            </div>
                        </div>
                        <div className='w-full flex justify-end pb-14'>
                            <button type="button" onClick={updateSalesContract} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC]'>
                                <span className='pr-2'><i className="fa-solid fa-clock-rotate-left"></i></span> {t('update')}
                            </button>
                        </div>
                    </div>
                </div>
                <AddItem open={openItem} handleClose={handleCloseItem} items={items} setItems={setItems} />
            </Box>
        </>
    )
}

export default UpdateSalesContracts
