import React from 'react'

import { Modal, Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CallMadeIcon from '@mui/icons-material/CallMade'
import CallReceivedIcon from '@mui/icons-material/CallReceived'

const ViewMarkerOrder = ({ order, open, setOpen }) => {

    const { t } = useTranslation()

    const handleCloseModal = (event, reason) => {
        setOpen(false)
    }

    return (
        <Modal open={open} onClose={(event, reason) => handleCloseModal(event, reason)}>
            <Box sx={style}>
                <div className='p-5' style={{ backgroundColor: order?.load_type === "shipment" ? '#336195' : '#b7472a', borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-baseline justify-between w-full'>
                        <p className='text-[18px] font-[600] text-white'>{order.shipment?.so_number || order.collection?.po_number} <span style={{ paddingLeft: '7px' }}>{order?.name}</span></p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-white">
                            <button onClick={(event, reason) => handleCloseModal(event, reason)}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='p-5 text-[#718096]'>
                        <div className='pb-5'>
                            <p className='pb-4'>{t('status')}: <span className='font-bold'>{order.shipment?.so_status_name || order.collection?.po_status_name}</span></p>
                            <p>{t('address')}: <span className='font-bold'>{order?.address}, {order?.city}, {order?.state}</span></p>
                        </div>
                        <div className='pb-3'>
                            <hr />
                        </div>
                        <div className='pb-10'>
                            <p className='font-bold py-4'>{t('items')}</p>
                            {
                                order?.load_type === "shipment" ?
                                    order?.shipment?.items?.map((item) => (
                                        <div className='flex justify-between py-3 items-baseline gap-2' key={item.id}>
                                            <p className='w-[70%]'>{item.item_no}, {item.description}</p>
                                            <p className="w-[30%]">{t('quantity_ordered')}: {item.qty_ordered}</p>
                                        </div>
                                    ))
                                    :
                                    order?.collection?.items?.map((item) => (
                                        <div className='flex justify-between py-3 items-baseline gap-2' key={item.id}>
                                            <p className='w-[70%]'>{item.item_no}, {item.description}</p>
                                            <p className="w-[30%]">{t('quantity_ordered')}: {item.qty_ordered}</p>
                                        </div>
                                    ))
                            }
                        </div>

                        <div className='w-full flex gap-2 justify-between'>
                            <button type="button" onClick={handleCloseModal} className='w-full text-[14px] text-[#919399] rounded-sm px-4 py-2 bg-[#F3F5F7] hover:bg-[#E6E6E6] disabled:bg-[#B8B7BC] capitalize'>
                                {t('cancel')}
                            </button>
                            {/* <button type="button" className='w-full text-[14px] text-white rounded-sm px-4 py-2 bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC] capitalize'>
                                {t('learn_more')}
                            </button> */}
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default ViewMarkerOrder

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: "600px",
    height: 'fit-content',
    bgcolor: '#ffffff',
    border: 'transparent',
    borderRadius: '5px',
    boxShadow: 24,
    zIndex: "1600",
    outline: 'none'
}
