import React, { useState } from 'react'

import { Box, Modal, FormControl, Select, MenuItem, ListItemIcon, Checkbox, ListItemText, Chip } from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import { useTranslation } from 'react-i18next'
import { isNull } from 'lodash'

const AddModal = ({
    title,
    open,
    handleToggleModal,
    options,
    selections,
    setSelections,
    dataType = 'production_employee',
    color = '#336195'
}) => {

    const { t } = useTranslation()

    const everyOptionSelected = (options && selections) ? (options.length > 0 && selections.length === options.length) : false

    const [openOptions, setOpenOptions] = useState(false)

    const handleChangeSelection = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = []

        value?.forEach((option) => {
            if (duplicateRemoved.findIndex((o) => o.id === option.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === option.id)
            } else {
                duplicateRemoved.push(option)
            }
        })

        if (value[value.length - 1] === 'all') {
            setSelections(selections.length === options.length ? [] : options)
            return
        }

        setSelections(duplicateRemoved)
    }

    const handleDeleteSelection = (e, id) => {
        const shouldDelete = selections.find((x) => x.id === id)
        if (shouldDelete) {
            const filtered = selections.filter((x) => x.id !== shouldDelete.id)
            if (filtered.length > 0) {
                setSelections(filtered)
            } else {
                setSelections([])
                setOpenOptions(false)
            }
        }
    }

    const handleClose = (event, reason) => {
        if (reason !== "backdropClick") {
            handleToggleModal()
            setOpenOptions(false)
        }
    }

    const renderSelect = () => {
        switch (dataType) {
            case 'production_employee':
                return (
                    <Select
                        multiple
                        id="select-options"
                        open={openOptions}
                        onOpen={() => setOpenOptions(true)}
                        onClose={() => setOpenOptions(false)}
                        value={selections}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((x) => (
                                    <Chip
                                        key={`pe_option-${x.id}`}
                                        label={x.name}
                                        onMouseDown={(e) => e.stopPropagation()}
                                        onDelete={(e) => handleDeleteSelection(e, x.id)}
                                    />
                                ))}
                            </Box>
                        )}
                        onChange={handleChangeSelection}
                        sx={{
                            width: '100%',
                            background: '#F5F5F5',
                            boxShadow: 'none',
                            borderRadius: '6px',
                            '.MuiOutlinedInput-notchedOutline': {
                                border: 'none !important'
                            },
                            ".MuiSelect-iconOutlined": {
                                display: (selections.length > 0) ? 'none !important' : ''
                            },
                            "&.Mui-focused .MuiIconButton-root": {
                                color: 'rgba(0,0,0,.42)'
                            }
                        }}
                        endAdornment={selections ? (<IconButton sx={{ visibility: (selections.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={() => setSelections([])}><ClearIcon /></IconButton>) : false}
                    >
                        {
                            options?.length > 0 ?
                                <MenuItem value='all'>
                                    <ListItemIcon>
                                        <Checkbox checked={everyOptionSelected} indeterminate={selections.length > 0 && selections.length < options.length} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('select_all')} />
                                </MenuItem>
                                :
                                <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                        }

                        {
                            options?.map((option) =>
                                <MenuItem value={option} key={`pt${option.id}`}>
                                    <Checkbox checked={selections?.findIndex((i) => i.name === option.name) >= 0} />
                                    <ListItemText primary={option.name} />
                                </MenuItem>
                            )
                        }
                    </Select>
                )
            case 'production_item':
                return (
                    <Select
                        multiple
                        id="select-options"
                        open={openOptions}
                        onOpen={() => setOpenOptions(true)}
                        onClose={() => setOpenOptions(false)}
                        value={selections}
                        MenuProps={{
                            disableScrollLock: true,
                            marginThreshold: null,
                            style: {
                                maxHeight: "300px",
                                height: "45vh",
                            }
                        }}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((x) => (
                                    <Chip
                                        key={`pi_option-${x.id}`}
                                        label={x.item_no}
                                        onMouseDown={(e) => e.stopPropagation()}
                                        onDelete={(e) => handleDeleteSelection(e, x.id)}
                                    />
                                ))}
                            </Box>
                        )}
                        onChange={handleChangeSelection}
                        sx={{
                            width: '100%',
                            background: '#F5F5F5',
                            boxShadow: 'none',
                            borderRadius: '6px',
                            '.MuiOutlinedInput-notchedOutline': {
                                border: 'none !important'
                            },
                            ".MuiSelect-iconOutlined": {
                                display: (selections.length > 0) ? 'none !important' : ''
                            },
                            "&.Mui-focused .MuiIconButton-root": {
                                color: 'rgba(0,0,0,.42)'
                            }
                        }}
                        endAdornment={selections ? (<IconButton sx={{ visibility: (selections.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={() => setSelections([])}><ClearIcon /></IconButton>) : false}
                    >
                        {
                            options?.length > 0 ?
                                <MenuItem value='all'>
                                    <ListItemIcon>
                                        <Checkbox checked={everyOptionSelected} indeterminate={selections.length > 0 && selections.length < options.length} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('select_all')} />
                                </MenuItem>
                                :
                                <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                        }

                        {
                            options?.map((option) =>
                                <MenuItem value={option} key={`i${option.id}`}>
                                    <Checkbox checked={selections?.findIndex((i) => i.item_no === option.item_no) >= 0} />
                                    <ListItemText primary={option.item_no} />
                                </MenuItem>
                            )
                        }
                    </Select>
                )
            default:
                return ''
        }
    }

    return (
        <Modal open={open} onClose={(event, reason) => handleClose(event, reason)}>
            <Box sx={style}>
                <div className='flex justify-between items-center p-5 w-full' style={{ backgroundColor: color, borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-baseline justify-between w-full'>
                        <p className='text-[18px] font-[600] text-white'>{title}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-white">
                            <button onClick={handleToggleModal}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='p-5'>
                        <FormControl sx={{ width: '100%', marginBottom: '20px' }} focused={openOptions}>
                            {renderSelect()}
                        </FormControl>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default AddModal

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '560px',
    height: 'fit-content',
    bgcolor: '#ffffff',
    border: 'transparent',
    borderRadius: '5px',
    boxShadow: 24,
    zIndex: "1600",
    outline: 'none'
}

