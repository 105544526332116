import React from "react"
import CustomBadge from "./CustomBadge"
import { IconButton, Tooltip } from "@mui/material"
import HistoryIcon from '@mui/icons-material/History'
import { useTranslation } from "react-i18next"

const CommentHistoryButton = ({
    badgeContent = '',
    backgroundColor = '#336195',
    color = '#ffffff',
    iconColor = '#A1A1A1',
    onClick = (() => console.log('Comment History button clicked'))
}) => {
    const { t } = useTranslation()

    return (
            <CustomBadge badgeContent={badgeContent} backgroundColor={backgroundColor} color={color}>
                <Tooltip title={t('view_x', {x: t('comment_history')})} arrow placement='left'>
                    <IconButton aria-label='view-comment-history' onClick={onClick}>
                        <HistoryIcon sx={{color: iconColor}} />
                    </IconButton>
                </Tooltip>
            </CustomBadge>
    )
}

export default CommentHistoryButton
