import React, { useState, useEffect, useRef } from 'react'

import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import update from 'immutability-helper'
import { Tooltip } from '@mui/material'
import allLocales from '@fullcalendar/core/locales-all'
import i18next from 'i18next'

import AppLayout from '../../../components/Layouts/AppLayout'
import { useStateContext } from '../../../context/ContextProvider'
import axios from '../../../lib/axios'
import '../../../ShipmentCalendar.css'
import Loading from '../../../components/Loading'
import Navigation from '../../../components/sales_purchase_orders/Navigation'
import { isValid } from '../../../helpers/helper'
import { useAuth } from '../../../hooks/auth'
import { includes } from 'lodash'

const SalesOrderDailyBoardCalendar = () => {

    const { user } = useAuth({ middleware: 'guest ' })

    const { t } = useTranslation()
    const [orders, setOrders] = useState([])
    const { config, choosesite, setChoosesite, pusher } = useStateContext()
    const calendarRef = useRef([])
    const navigate = useNavigate()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(false)

    const [events, setEvents] = useState([])

    useEffect(() => {
        // Unsubscribe on unmount
        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendoruser-updated`)
        })
    }, [])

    useEffect(() => {
        if (isValid(choosesite)) {
            getOrders(choosesite)
        }
    }, [choosesite])

    useEffect(() => {
        if(isValid(choosesite)) {
            const channelSalesOrderDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
            const channelSalesOrderUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${choosesite}`)
            const channelVendorUserUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-vendoruser-updated`)

            channelSalesOrderDeleted.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${choosesite}`, data => {
                setOrders((prev) => {
                    const item = prev.find((i) => i.id === data.id)
                    const exItem = prev.filter((i) => i.id !== item.id)
                    return exItem
                })
            })

            channelSalesOrderUpdated.bind(`${localStorage.getItem('client_id')}-salesorder-updated-event-site-${choosesite}`, data => {
                getSalesOrder(data.id, 'updated')
            })

            channelVendorUserUpdated.bind(`${localStorage.getItem('client_id')}-vendoruser-updated-event`, data => {
                if(data.id == user?.id) {
                    getOrders(choosesite)
                }
            })
        }


        return (() => {
            if(isValid(choosesite)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendoruser-updated`)
            }
        })
    }, [choosesite, user])

    const getOrders = async (siteId = null) => {
        setIsLoading(true)

        setEvents([])
        await axios
            .get(`/api/list-orders?order_type=sales&site_id=${isValid(siteId) ? siteId : localStorage.getItem('site')}&type=broker&include_broker&exclude_open&pending=true`, config)
            .then(res => {
                const data = res.data?.data
                setOrders(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        setEvents([])
        orders.map((i) => {
            let new_item = {
                "order_id": i.broker_transaction?.id ?? i.id,
                "title": i.so_number,
                "date": i.requested_delivery_date + 'T' + i.requested_delivery_time_time,
                "requested_delivery_date": i.requested_delivery_date,
                "customer_name": i.customer_name,
                "ship_address": i.ship_address_name
            }


            setEvents((prev) => [...prev, new_item])
        })
    }, [orders])

    const getSalesOrder = async (id, state) => {
        setIsLoading(true)

        await axios
            .get(`/api/sales-orders/${id}`, config)
            .then(res => {
                const data = res.data

                if (state === 'created') {
                    setOrders((prev) => [...prev, data])
                }

                if (state === 'updated') {
                    setOrders((prev) => {
                        const index = prev?.findIndex((i) => i.id === id)
                        if (index < 0) {
                            const items = [...prev, data]
                            return items
                        } else {
                            if (includes(['open', 'reopened', 'invoiced', 'completed', 'rejected'], data.so_status)) {
                                return update(prev, { $splice: [[index, 1]] })
                            } else {
                                return update(prev,
                                    { [index]: { $set: data } }
                                )
                            }
                        }
                    })
                }
            })
            .finally(() => {
                setIsLoading(false)
            })

    }

    const handleEventClick = (eventInfo) => {
        localStorage.setItem('initialDateSales', eventInfo.event.extendedProps.requested_delivery_date)
        navigate(`/sales-orders/${eventInfo.event.extendedProps.order_id}`, { state: { prevPathname: location.pathname } });
    }


    return (
        <>
        {isLoading ? <Loading /> : null}
            <AppLayout>
                <Navigation route='sales-orders' type='calendar' user={user} />
                <div className='p-5 w-full'>
                    <div className='p-2 bg-white mb-2 rounded-md shipment'>
                        <FullCalendar
                            locales={allLocales}
                            locale={i18next?.resolvedLanguage ?? 'en'}
                            ref={calendarRef}
                            plugins={[dayGridPlugin, timeGridPlugin]}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay'
                            }}
                            buttonText={{
                                today: t('today'),
                                month: t('month'),
                                week: t('week'),
                                day: t('day')
                            }}
                            customButtons={{
                                today: {
                                    text: t('today'),
                                    click: () => {
                                        localStorage.removeItem('initialDateSales')
                                        let calendarApi = calendarRef.current.getApi()
                                        calendarApi.today()
                                    }
                                }
                            }}
                            initialView='dayGridMonth'
                            events={events}
                            eventContent={RenderEventContent}
                            dayMaxEvents={true}
                            eventMaxStack={1}
                            expandRows={true}
                            eventColor='#ffffff'
                            initialDate={localStorage.getItem('initialDateSales')}
                            eventClick={handleEventClick}
                        />
                    </div>
                </div>
            </AppLayout>
        </>
    )
}

export default SalesOrderDailyBoardCalendar

const RenderEventContent = (eventInfo) => {

    return (
        <Tooltip disableInteractive  placement='bottom' title={
            <div className='flex flex-col'>
              <p className='text-[16px] pb-3'>{eventInfo.event.title}</p>
              <i className='text-[14px]'>{eventInfo.event.extendedProps.customer_name}</i>
              <i className='text-[14px]'>{eventInfo.event.extendedProps.ship_address}</i>
            </div>
          }>
            <button className='w-full'>
                <div className='p-1 flex flex-col text-left bg-[#336195] text-white rounded-sm'>
                    <p className='text-xl bold p-1 roboto'>{eventInfo.event.title}</p>
                    <i>{eventInfo.event.extendedProps.customer_name}</i>
                    <i>{eventInfo.event.extendedProps.ship_address}</i>
                </div>
            </button>
        </Tooltip>
    )
}
