import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, ListItemText, Checkbox, ListItemIcon, Chip, FormHelperText, Autocomplete } from '@mui/material'
import Swal from 'sweetalert2'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"

import Loading from '../../components/Loading'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import { useAuth } from "../../hooks/auth";
import { handleAxiosError, handleDeleteSelectionObjectPlus, isValid } from '../../helpers/helper'
import { includes, isNull } from 'lodash'

const UpdateVendorUser = () => {
    const { user } = useAuth({ middleware: 'guest ' })

    const { config, pusher, choosesite, setChoosesite } = useStateContext()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const params = useParams()
    const navigate = useNavigate()

    const [purchaseAddresses, setPurchaseAddresses] = useState([])

    /* Pusher Channels (those that need to be bound and then rebound) */
    const [channelDeletePurchaseAddress, setChannelDeletePurchaseAddress] = useState(null)
    const [channelUpdatePurchaseAddress, setChannelUpdatePurchaseAddress] = useState(null)
    const [channelCreatePurchaseAddress, setChannelCreatePurchaseAddress] = useState(null)

    /* Form */
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [selectedPurchaseAddresses, setSelectedPurchaseAddresses] = useState([])
    const [selectedPurchaseAddressIds, setSelectedPurchaseAddressIds] = useState([])

    const [vendorId, setVendorId] = useState('')
    const [companyId, setCompanyId] = useState('')
    const [siteId, setSiteId] = useState('')

    const [openPurchaseAddresses, setOpenPurchaseAddresses] = useState(false)

    useEffect(() => {
        getVendorUser()

        const channelVendorUserDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-vendoruser-deleted`)
        const channelVendorUserUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-vendoruser-updated`)

        const channelPurchaseAddressDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)
        const channelPurchaseAddressCreated = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-created`)
        const channelPurchaseAddressUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)

        setChannelDeletePurchaseAddress(channelPurchaseAddressDeleted)
        setChannelUpdatePurchaseAddress(channelPurchaseAddressUpdated)
        setChannelCreatePurchaseAddress(channelPurchaseAddressCreated)

        {/* Vendor User events */}
        channelVendorUserDeleted.bind(`${localStorage.getItem('client_id')}-vendoruser-deleted-event`, data => {
            if(Number(data.id) == Number(params.id)) {
                navigate(`/vendors/${data.service_provider_id}?tab=vendor-users`)
            }
        })

        channelVendorUserUpdated.bind(`${localStorage.getItem('client_id')}-vendoruser-updated-event`, data => {
            if(Number(data.id) == Number(params.id)) {
                getVendorUser()
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendoruser-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendoruser-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)
        })
    }, [])

    {/* Apparently there is a risk where you may incorrectly bind events. In our case, outdated props are used. */}
    useEffect(() => {
        {/* Purchase Address events */}
        if(channelDeletePurchaseAddress && channelDeletePurchaseAddress.bind) {
            channelDeletePurchaseAddress.unbind(`${localStorage.getItem('client_id')}-purchaseaddress-deleted-event`)
            channelDeletePurchaseAddress.bind(`${localStorage.getItem('client_id')}-purchaseaddress-deleted-event`, data => {
                if(Number(vendorId) == Number(data.vendor_id)) {
                    handleClearPurchase()
                    getPurchaseAddresses(vendorId)
                }
            })
        }

        if(channelCreatePurchaseAddress && channelCreatePurchaseAddress.bind) {
            channelCreatePurchaseAddress.unbind(`${localStorage.getItem('client_id')}-purchaseaddress-created-event`)
            channelCreatePurchaseAddress.bind(`${localStorage.getItem('client_id')}-purchaseaddress-created-event`, data => {
                if(Number(vendorId) == Number(data.vendor_id)) {
                    getPurchaseAddresses(vendorId)
                }
            })
        }

        if(channelUpdatePurchaseAddress && channelUpdatePurchaseAddress.bind) {
            channelUpdatePurchaseAddress.unbind(`${localStorage.getItem('client_id')}-purchaseaddress-updated-event`)
            channelUpdatePurchaseAddress.bind(`${localStorage.getItem('client_id')}-purchaseaddress-updated-event`, data => {
                if(Number(vendorId) == Number(data.vendor_id)) {
                    handleClearPurchase()
                    getPurchaseAddresses(vendorId)
                }
            })
        }
    }, [vendorId])

    useEffect(() => {
        if(isValid(vendorId)) {
            const channelVendorDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)

            channelVendorDeleted.bind(`${localStorage.getItem('client_id')}-vendor-deleted-event`, data => {
                if(Number(data.id) == Number(vendorId)) {
                    navigate('/vendors')
                }
            })
        }

        return (() => {
            if(isValid(vendorId)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-customer-deleted`)
            }
        })
    }, [vendorId])

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate(-1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    const isAllSelectedPurchase = purchaseAddresses.length > 0 && selectedPurchaseAddresses.length === purchaseAddresses.length

    useEffect(() => {
        if (vendorId) {
            getPurchaseAddresses(vendorId)
        }
    }, [vendorId])


    const getVendorUser = async () => {
        setIsLoading(true)

        await axios.get(`/api/vendor-users/${params.id}?with=purchaseAddresses`, config)
            .then(res => {
                const user = res.data
                setName(user.name)
                setEmail(user.email)
                setVendorId(user.service_provider || '')
                setSelectedPurchaseAddresses(user.purchase_addresses || [])
                setSelectedPurchaseAddressIds(user.purchase_addresses?.map((purchaseAddress) => purchaseAddress.id))
                setSiteId(user.site_id)
                setCompanyId(user.company_id)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseAddresses = async (vendorId) => {
        setIsLoading(true)
        await axios
            .get(`/api/purchase-addresses?vendor_id=${vendorId}`, config)
            .then(res => {
                const data = res.data
                setPurchaseAddresses(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handlePurchaseAddress = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = [];

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
                duplicateRemoved.push(item);
            }
        })

        let ids = []

        value.forEach((item) => {
            if (ids.findIndex((o) => o.id === item.id) >= 0) {
                ids = ids.filter((x) => x.id === item.id);
            } else {
                ids.push(item.id);
            }
        })

        if (value[value.length - 1] === "all") {
            setSelectedPurchaseAddresses(selectedPurchaseAddresses.length === purchaseAddresses.length ? [] : purchaseAddresses);
            setSelectedPurchaseAddressIds(selectedPurchaseAddresses.length === purchaseAddresses.length ? [] : purchaseAddresses.map(i => i.id))
            return;
        }
        setSelectedPurchaseAddresses(duplicateRemoved)
        setSelectedPurchaseAddressIds(ids)
    }


    const handleClearPurchase = () => {
        setSelectedPurchaseAddresses([])
        setSelectedPurchaseAddressIds([])
    }

    const updateVendorUser = async (e) => {
        e.preventDefault();

        setIsLoading(true)
        const formData = {}

        formData['name'] = name
        formData['email'] = email
        formData['purchase_addresses'] = selectedPurchaseAddressIds
        formData['company_id'] = companyId
        formData['site_id'] = siteId
        formData['service_provider_id'] = vendorId

        await axios
            .put(`/api/vendor-users/${params.id}`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })

            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box>
                <div className='flex justify-between items-center p-5 pr-0 pt-0 pb-0' style={{ backgroundColor: '#336195', borderRadius: '5px 5px 0 0' }}>
                    <div className='flex gap-4 items-baseline'>
                        <div style={{ transform: "rotate(45deg)" }} className="font-semibold text-white">
                            <Link to={`/vendors/${vendorId}?tab=vendor-users`}><i className="fa-solid fa-plus"></i></Link>
                        </div>
                        <p className='text-xl roboto font-semibold text-white'>{t('update_vendor_user')} - {name}</p>
                    </div>
                    <button type="button" onClick={updateVendorUser} className='text-white px-4 py-6 uppercase self-end roboto bg-zinc-900 hover:bg-zinc-700 disabled:bg-zinc-400'>
                        {t('edit')}
                    </button>
                </div>

                <div className='p-5'>
                    <form className='flex justify-center flex-col items-start mt-2'>

                        <TextField type="text" variant='standard' label={t('name')} sx={{ marginBottom: '20px' }} className='w-full mb-5 px-0 pt-0' name="name" value={name} onChange={(e) => { setName(e.target.value) }} required />

                        <TextField type="text" variant='standard' label={t('email')} sx={{ marginBottom: '20px' }} className='w-full mb-5 px-0 pt-0' name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} required />

                        <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }} focused={openPurchaseAddresses}>
                            <InputLabel>{t('purchase_addresses')} *</InputLabel>
                            <Select
                                multiple
                                disabled={!vendorId}
                                value={selectedPurchaseAddresses}
                                open={openPurchaseAddresses}
                                onOpen={() => setOpenPurchaseAddresses(true)}
                                onClose={() => setOpenPurchaseAddresses(false)}
                                onChange={handlePurchaseAddress}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((x) => (
                                            <Chip
                                                key={`stl_option-${x.id}`}
                                                label={x.name}
                                                onMouseDown={(e) => e.stopPropagation()}
                                                onDelete={(e) => handleDeleteSelectionObjectPlus(e, x.id, selectedPurchaseAddresses, setSelectedPurchaseAddresses, setSelectedPurchaseAddressIds, setOpenPurchaseAddresses)}
                                            />
                                        ))}
                                    </Box>
                                )}
                                sx={{ ".MuiSelect-iconStandard": { display: (selectedPurchaseAddresses.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' } }}
                                endAdornment={selectedPurchaseAddresses ? (<IconButton sx={{ visibility: (selectedPurchaseAddresses.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearPurchase}><ClearIcon /></IconButton>) : false}
                            >
                                {
                                    purchaseAddresses?.length > 0 ?
                                        <MenuItem value="all">
                                            <ListItemIcon>
                                                <Checkbox checked={isAllSelectedPurchase} indeterminate={selectedPurchaseAddresses.length > 0 && selectedPurchaseAddresses.length < purchaseAddresses.length} />
                                            </ListItemIcon>
                                            <ListItemText primary={t('select_all')} />
                                        </MenuItem>
                                        :
                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                }
                                {
                                    purchaseAddresses.map((item) =>
                                        <MenuItem value={item} key={item.id}>
                                            <Checkbox checked={selectedPurchaseAddresses?.findIndex((i) => i.id === item.id) >= 0} />
                                            <ListItemText primary={item.name} />
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </form>
                </div>
            </Box>
        </>
    )
}

export default UpdateVendorUser
