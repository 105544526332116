import { MapContainer, TileLayer, useMap, Popup, Marker, Tooltip } from 'react-leaflet'
import L from 'leaflet';
import AppLayout from '../components/Layouts/AppLayout'
import 'leaflet/dist/leaflet.css'
import { useEffect, useState } from 'react'
import Loading from '../components/Loading'
import {useTranslation} from 'react-i18next'
import axios from '../lib/axios'
import { useStateContext } from '../context/ContextProvider'
import { useAuth } from '../hooks/auth'
import { Checkbox, FormControlLabel, FormGroup, List, ListSubheader, ListItemButton, ListItemText, ListItemIcon } from '@mui/material'
import MarkerClusterGroup from 'react-leaflet-cluster'
import ViewMarkerOrder from '../components/map/ViewMarkerOrder';
import CallMadeIcon from '@mui/icons-material/CallMade'
import CallReceivedIcon from '@mui/icons-material/CallReceived'
import { AttributionControl } from 'react-leaflet';
import { isValid } from '../helpers/helper';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

const Map = () => {

    const { t } = useTranslation()
    const { config, choosesite, pusher } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })


    const [geolocations, setGeolocations] = useState([])

    const [open, setOpen] = useState(false)
    const [order, setOrder] = useState([])

    const [showShipments, setShowShipments] = useState(true)
    const [showCollections, setShowCollections] = useState(true)

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if(localStorage.getItem('site')) {
            getGeolocations(localStorage.getItem('site'))
        }
    }, [])

    useEffect(() => {
        if (isValid(choosesite)) {
            const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
            const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${choosesite}`)
            const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${choosesite}`)
            const channeldeletePurchase = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${choosesite}`)
            const channelcreatePurchase = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-created-site-${choosesite}`)
            const channelupdatePurchase = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${choosesite}`)

            channeldelete.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${choosesite}`, data => {
                getGeolocations(choosesite)
            })

            channelcreate.bind(`${localStorage.getItem('client_id')}-salesorder-created-event-site-${choosesite}`, data => {
                getGeolocations(choosesite)
            })

            channelupdate.bind(`${localStorage.getItem('client_id')}-salesorder-updated-event-site-${choosesite}`, data => {
                getGeolocations(choosesite)
            })

            channeldeletePurchase.bind(`${localStorage.getItem('client_id')}-purchaseorder-deleted-event-site-${choosesite}`, data => {
                getGeolocations(choosesite)
            })

            channelcreatePurchase.bind(`${localStorage.getItem('client_id')}-purchaseorder-created-event-site-${choosesite}`, data => {
                getGeolocations(choosesite)
            })

            channelupdatePurchase.bind(`${localStorage.getItem('client_id')}-purchaseorder-updated-event-site-${choosesite}`, data => {
                getGeolocations(choosesite)
            })
        }

        return (() => {
            if(isValid(choosesite)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-created-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${choosesite}`)
            }
        })
    }, [choosesite])

    useEffect(() => {
        if(choosesite) {
            getGeolocations(choosesite)
        }
    }, [choosesite])

    const handleOpen = (order) => {
        setOrder(order)
        setOpen(true)
    }

    const handleClose = () =>  {
        setOpen(false)
        setOrder([])
    }

    const getGeolocations = async (site_id) => {
        setIsLoading(true)

        await axios.get(`/api/geolocations?site=${site_id}`, config)
        .then(res => {
            const geodata = res.data
            setGeolocations(geodata['geolocations'])
            setIsLoading(false)
        })
    }

    const filteredShipmentGeolocations = geolocations?.filter((geolocation) => geolocation.load_type === 'shipment' && (Number(geolocation.lat) != 0.0000 && Number(geolocation.lon) != 0.0000))
    const filteredCollectionGeolocations = geolocations?.filter((geolocation) => geolocation.load_type === 'collection' && (Number(geolocation.lat) != 0.0000 && Number(geolocation.lon) != 0.0000))
    const nullIslandGeolocations = geolocations?.filter((geolocation) => (Number(geolocation.lat) == 0.0000 && Number(geolocation.lon) == 0.0000)).sort((a, b) => ((a.load_type < b.load_type)) ? 1 : -1)

    const getMarkerIcon = (type) => {
        switch(type) {
            case "shipment":
                return L.icon({
                    iconUrl: require('../assets/icons/shipment_icon.png'),
                    iconSize: [24, 36],
                    iconAnchor: [12, 36]
                })
            default:
                return L.icon({
                    iconUrl: require('../assets/icons/collection_icon.png'),
                    iconSize: [24, 36],
                    iconAnchor: [12, 36]
                })
        }
    }

    const createMarkerClusterIcon = function(cluster, type = "shipments") {
        return L.divIcon({
            html: `<div><span>${cluster.getChildCount()}</span></div>`,
            className: `marker-cluster ${type}-marker-cluster`,
            iconSize: new L.point(40, 40)
        })
    }



    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout padBottom={false}>
                <div style={style}>
                    {/* Load Filters */}
                    <FormGroup
                        sx={{
                            width: 'max-content',
                            position: 'absolute',
                            zIndex: '999',
                            borderRadius: '7px',
                            backgroundColor: '#ffffff',
                            border: '1px solid #00000050',
                            margin: '10px 35px 10px 10px',
                            padding: '7px 14px',
                            right: '0'
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showShipments}
                                    onChange={() => setShowShipments(!showShipments)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    size="small"
                                />
                            }
                            label={t('shipments')}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showCollections}
                                    onChange={() => setShowCollections(!showCollections)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    size="small"
                                />
                            }
                            label={t('collections')}
                        />
                    </FormGroup>

                    {/* Null Island i.e. unlocated Loads */}
                    {nullIslandGeolocations.length > 0 ? (
                        <List
                            sx={{
                                maxHeight: '300px',
                                overflow: 'auto',
                                width: '20vw',
                                minWidth: '250px',
                                position: 'absolute',
                                zIndex: '999',
                                borderRadius: '7px',
                                backgroundColor: '#ffffff',
                                border: '1px solid #00000050',
                                margin: '10px 35px 10px 10px',
                                padding: '7px',
                                paddingTop: '0px',
                                right: '0',
                                bottom: '7.5%'
                            }}
                            component={'ul'}
                            aria-labelledby='untracable-geolocation-list'
                            subheader={
                                <ListSubheader component={'div'} id="untracable-geolocation-list-subheader">
                                    <strong>{t('loads_not_shown_on_map')}</strong>
                                </ListSubheader>
                            }
                        >
                            {nullIslandGeolocations?.map((geolocation, index) => {
                                return (
                                    <ListItemButton key={`g${index}`} onClick={() => handleOpen(geolocation)}>
                                        <ListItemText primaryTypographyProps={{fontSize: '14px', lineHeight: '1em', color: geolocation?.load_type === "shipment" ? '#336195' : '#b7472a'}} >{geolocation.name}</ListItemText>
                                        <ListItemIcon sx={{justifyContent: 'end', minWidth: '40px'}}>{geolocation?.load_type === "shipment" ? <div style={{ color: '#336195' }}><CallMadeIcon /></div> : <div style={{ color: '#b7472a' }}><CallReceivedIcon /></div>}</ListItemIcon>
                                    </ListItemButton>
                                )
                            })}
                        </List>
                    ) : (
                        ''
                    )}

                    <MapContainer
                        attributionControl={false}
                        center={[30, -80]}
                        zoom={5}
                        minZoom={3}
                        maxBounds={[
                            [85, -180],
                            [-85, 180]
                        ]}
                        maxBoundsViscosity={1}
                        scrollWheelZoom={true}
                        style={{
                            height: '100%',
                            zIndex: '1',
                            margin: '-8px'
                        }}
                    >
                        <TileLayer
                            noWrap
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <AttributionControl position="bottomleft" />

                        {showShipments ? (
                            <MarkerClusterGroup
                                iconCreateFunction={(e) => createMarkerClusterIcon(e, "shipments")}
                                maxClusterRadius={30}
                                spiderfyOnMaxZoom={true}
                                polygonOptions={{
                                    fillColor: '#ffffff',
                                    color: '#336195',
                                    weight: 5,
                                    opacity: 1,
                                    fillOpacity: 0.8,
                                }}
                                chunkedLoading
                            >
                                {filteredShipmentGeolocations?.map((geolocation, index) => {
                                    return (
                                        <Marker
                                            key={`s${index}`}
                                            position={[Number(geolocation.lat), Number(geolocation.lon)]}
                                            icon={getMarkerIcon("shipment")}
                                            eventHandlers={{
                                                click: () => {
                                                    handleOpen(geolocation);
                                                }
                                            }}
                                        >
                                            <Tooltip disableInteractive  direction='bottom'>
                                                {geolocation.name} <br /> {geolocation.address}, {geolocation.state}.
                                            </Tooltip>
                                        </Marker>
                                    )
                                })}
                            </MarkerClusterGroup>
                        ) : (
                            ''
                        )}

                        {showCollections ? (
                            <MarkerClusterGroup
                                iconCreateFunction={(e) => createMarkerClusterIcon(e, "collections")}
                                spiderfyOnMaxZoom={true}
                                polygonOptions={{
                                    fillColor: '#ffffff',
                                    color: '#b7472a',
                                    weight: 5,
                                    opacity: 1,
                                    fillOpacity: 0.8,
                                }}
                                chunkedLoading
                            >
                                {filteredCollectionGeolocations?.map((geolocation, index) => {
                                    return (
                                        <Marker
                                            key={`c${index}`}
                                            position={[Number(geolocation.lat), Number(geolocation.lon)]}
                                            icon={getMarkerIcon("collection")}
                                            eventHandlers={{
                                                click: () => {
                                                    handleOpen(geolocation);
                                                }
                                            }}
                                        >
                                            <Tooltip disableInteractive  direction='bottom'>
                                                {geolocation.name} <br /> {geolocation.address}, {geolocation.state}.
                                            </Tooltip>
                                        </Marker>
                                    )
                                })}
                            </MarkerClusterGroup>
                        ) : (
                            ''
                        )}
                    </MapContainer>
                </div>
            </AppLayout>

            <ViewMarkerOrder
                order={order}
                open={open}
                setOpen={setOpen}
            />
        </>
    )
}

export default Map

const style = {
    padding: '24px',
    paddingBottom: '0px',
    height: '82.5vh',
}
