import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"

import AuthCard from '../../components/AuthCard'
import GuestLayout from '../../components/Layouts/GuestLayout'
import InputError from '../../components/InputError'
import { useAuth } from '../../hooks/auth'
import { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import Wrapper from '../../components/Wrapper'
import Swal from 'sweetalert2'
import { isValid } from "../../helpers/helper"
import { isEmpty } from "lodash"

const ForgotPassword = () => {
    const { forgotPassword, logout } = useAuth({ middleware: 'guest' })
    const { t } = useTranslation();

    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState(null)

    useEffect(() => {
        if(isValid(status) && isEmpty(errors)) {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: status,
            });
        }
    }, [status, errors])

    const submitForm = (event) => {
        event.preventDefault()

        forgotPassword({ email, setErrors, setStatus })
    }
    return (
        <GuestLayout>
            <AuthCard>
                <Wrapper>
                    <form onSubmit={submitForm}>
                        <div className='min-w-[400px] max-[500px]:min-w-[300px]'>
                            <div className='w-full'>
                                <p className='text-[#718096] text-[18px] mb-3'>{t('reset_password2')}</p>
                                <TextField
                                    id="input-with-icon-textfield"
                                    placeholder={t('enter_email')}
                                    name='email'
                                    className='w-full'
                                    type='email'
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                    required
                                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px', }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fa-solid fa-envelope"></i>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <InputError messages={errors.email} className="mt-2" />
                            </div>
                            <div className="flex items-center w-full pt-5">
                                <button className="w-full rounded-md bg-[#DBA34B] hover:bg-[#BD7503] disabled:bg-[#B8B7BC] text-white uppercase py-3 font-[700] text-[16px]" type='submit'>{t("send_password")}</button>
                            </div>
                            <div className='flex justify-start w-full py-5'>
                                <p className="text-[14px] text-[#BA8431] cursor-pointer" onClick={() => logout()}>
                                    {t('back_to_login')}
                                </p>
                            </div>
                        </div>
                    </form>
                </Wrapper>
            </AuthCard>
        </GuestLayout>
    )
}

export default ForgotPassword
