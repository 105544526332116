import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import { FormControl, Select, MenuItem } from '@mui/material'
import Swal from 'sweetalert2'

import { useStateContext } from '../../context/ContextProvider'
import logo from '../../assets/images/small.png'
import { isValid } from '../../helpers/helper'
import axios from '../../lib/axios'
import { useTranslation } from 'react-i18next'
import { includes } from 'lodash'

const SideBar = ({ user, children }) => {
    const { t } = useTranslation()

    const { extended, setExtended, setTotal, pusher, config, company_id, choosesite, setTotalPendingProductionOrders } = useStateContext()

    const getCustomerRequests = async (cid) => {
        if (['master_admin', 'office_manager', 'customers_user'].includes(user?.role)) {
            let shipments = 0
            let collections = 0
            let url = `shipment-requests?customer_id=${user?.customer_id}&status=pending`

            if (user?.role === 'master_admin' || user?.role === 'office_manager') {
                url = `shipment-requests?company_id=${cid}&status=pending`
            }

            let url1 = `collection-requests?customer_id=${user?.customer_id}&status=pending`

            if (user?.role === 'master_admin' || user?.role === 'office_manager') {
                url1 = `collection-requests?company_id=${cid}&status=pending`
            }

            await axios.get(`/api/${url}`, config)
                .then(res => {
                    const shipment = res.data
                    shipments = shipment.length
                })
            await axios.get(`/api/${url1}`, config)
                .then(res => {
                    const collection = res.data
                    collections = collection.length
                })

            setTotal(shipments + collections)
        }
    }

    const getPendingProductionOrders = async (sid) => {
        if(['production_supervisor'].includes(user?.role)) {
            await axios.get(`/api/list-orders?order_type=production&site_id=${sid}&status_id=1&production_supervisor_id=${user?.id}`, config)
                .then(res => {
                    const total = res.data.total
                    setTotalPendingProductionOrders(total)
                })
        }
    }

    useEffect(() => {
        if (localStorage.getItem('showImpersonationMessage') !== null) {
            Swal.fire({
                icon: 'success',
                title: t('x_successful', {x: t('impersonation')}),
                text: t('you_are_impersonating_x', {x: localStorage.getItem('impersonatee_name') ?? t('another_user')}),
                showConfirmButton: true,
            }).then((result) => {
                localStorage.removeItem('showImpersonationMessage')
            })
        } else if (localStorage.getItem('showDeimpersonationMessage') !== null) {
            Swal.fire({
                icon: 'success',
                title: t('x_successful', {x: t('deimpersonation')}),
                text: t('you_are_no_longer_impersonating_x', {x: localStorage.getItem('impersonatee_name') ?? t('another_user')}),
                showConfirmButton: true,
            }).then((result) => {
                localStorage.removeItem('showDeimpersonationMessage')
                localStorage.removeItem('impersonatee_name')
            })
        }
    }, [])

    useEffect(() => {
        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipmentrequest-deleted-company-${localStorage.getItem('company_id')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipmentrequest-created-company-${localStorage.getItem('company_id')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipmentrequest-updated-company-${localStorage.getItem('company_id')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-collectionrequest-deleted-company-${localStorage.getItem('company_id')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-collectionrequest-created-company-${localStorage.getItem('company_id')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-collectionrequest-updated-company-${localStorage.getItem('company_id')}`)

            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-created-site-${localStorage.getItem('site')}`)
        })
    }, [])

    useEffect(() => {
        if(isValid(company_id)) {
            const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-shipmentrequest-deleted-company-${company_id}`)
            const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-shipmentrequest-created-company-${company_id}`)
            const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-shipmentrequest-updated-company-${company_id}`)

            const channeldelete1 = pusher.subscribe(`${localStorage.getItem('client_id')}-collectionrequest-deleted-company-${company_id}`)
            const channelcreate1 = pusher.subscribe(`${localStorage.getItem('client_id')}-collectionrequest-created-company-${company_id}`)
            const channelupdate1 = pusher.subscribe(`${localStorage.getItem('client_id')}-collectionrequest-updated-company-${company_id}`)

            channeldelete.bind(`${localStorage.getItem('client_id')}-shipmentrequest-deleted-event-company-${company_id}`, data => {
                getCustomerRequests(data.company_id)
            })

            channelcreate.bind(`${localStorage.getItem('client_id')}-shipmentrequest-created-event-company-${company_id}`, data => {
                getCustomerRequests(data.company_id)
            })

            channelupdate.bind(`${localStorage.getItem('client_id')}-shipmentrequest-updated-event-company-${company_id}`, data => {
                getCustomerRequests(data.company_id)
            })

            channeldelete1.bind(`${localStorage.getItem('client_id')}-collectionrequest-deleted-event-company-${company_id}`, data => {
                getCustomerRequests(data.company_id)

            })

            channelcreate1.bind(`${localStorage.getItem('client_id')}-collectionrequest-created-event-company-${company_id}`, data => {
                getCustomerRequests(data.company_id)
            })

            channelupdate1.bind(`${localStorage.getItem('client_id')}-collectionrequest-updated-event-company-${company_id}`, data => {
                getCustomerRequests(data.company_id)
            })
        }

        return (() => {
            if(isValid(company_id)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipmentrequest-deleted-company-${company_id}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipmentrequest-created-company-${company_id}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipmentrequest-updated-company-${company_id}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-collectionrequest-deleted-company-${company_id}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-collectionrequest-created-company-${company_id}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-collectionrequest-updated-company-${company_id}`)
            }
        })
    }, [company_id])

    useEffect(() => {
        if (isValid(choosesite)) {
            const channelProductionOrderDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${choosesite}`)
            const channelProductionOrderCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-created-site-${choosesite}`)
            const channelProductionOrderUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${choosesite}`)

            channelProductionOrderDelete.bind(`${localStorage.getItem('client_id')}-productionorder-deleted-event-site-${choosesite}`, data => {
                if(Number(user?.id) == Number(data.production_supervisor)) {
                    getPendingProductionOrders(choosesite)
                }
            })

            channelProductionOrderCreate.bind(`${localStorage.getItem('client_id')}-productionorder-created-event-site-${choosesite}`, data => {
                if(Number(user?.id) == Number(data.production_supervisor)) {
                    getPendingProductionOrders(choosesite)
                }
            })

            channelProductionOrderUpdate.bind(`${localStorage.getItem('client_id')}-productionorder-updated-event-site-${choosesite}`, data => {
                getPendingProductionOrders(choosesite)
            })
        }

        return (() => {
            if(isValid(choosesite)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-created-site-${choosesite}`)
            }
        })
    }, [choosesite])

    useEffect(() => {
        if(isValid(company_id)) {
            getCustomerRequests(company_id)
        }
    }, [company_id])

    useEffect(() => {
        if(isValid(choosesite)) {
            getPendingProductionOrders(choosesite)
        }
    }, [choosesite])

    return (
        <aside className="min-h-screen max-w-[285px] pb-10">
            <nav className="h-full flex flex-col bg-white border-r shadow-sm">
                <div className="p-4 pb-9 pt-9 flex justify-between items-center relative">
                    <img src={logo} className={`overflow-hidden transition-all ${extended ? "w-[150px]" : "w-0"}`} alt="" />
                    <div className='absolute top-[25px] right-[-20px] w-[40px] h-[40px] rounded-full bg-[#F5F5F9] flex justify-center items-center'>
                        <button onClick={() => setExtended((curr) => !curr)} className="p-1.5 rounded-full bg-white  leading-none w-[25px] h-[25px] text-sm text-[#828C99]">
                            {extended ? <i className="fa-solid fa-chevron-left"></i> : <i className="fa-solid fa-chevron-right"></i>}
                        </button>
                    </div>
                </div>

                <ul className="flex flex-col justify-between flex-1 px-3">
                    <div>
                        {children}
                    </div>
                    {includes(['master_admin', 'office_manager'], user?.role) ? (
                        <UserManualSidebar icon={<i className="fa-solid fa-book"></i>} text={t('user_manual')} />
                    ) : null}
                </ul>
            </nav>
        </aside>
    )
}

export default SideBar

export function SidebarItem({ icon, text, route }) {

    const { extended } = useStateContext()

    return (
        <Link to={route}>
            <li className={`relative flex items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group
            ${window.location.pathname == route ?
                    "bg-[#f4f4f4] text-[#484848] text-[15px]"
                    :
                    "hover:bg-[#f4f4f4] text-[#2F4E70] bg-white text-[15px]"
                }
            `}
            >
                <div className='w-[20px]'>{icon}</div>
                <span className={`capitalize overflow-hidden transition-all ${extended ? "w-52 ml-3" : "hidden"} font-[500]`} >
                    {text}
                </span>

                {!extended && (
                    <div className={`capitalize z-[200000] absolute left-full rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all  group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}>
                        {text}
                    </div>
                )}
            </li>
        </Link>
    )
}

export function UserManualSidebar({ icon, text }) {

    const { extended } = useStateContext()

    return (
        <a
            href={`https://storage.googleapis.com/laravel-media/user-manual/Pallet_Insights_User_Manual_Document.pdf`}
            target="_blank"
        >
            <li className={`relative flex items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group hover:bg-[#f4f4f4] text-[#2F4E70] bg-white text-[15px]`}>
                    <div className='w-[20px]'>{icon}</div>
                    <span className={`capitalize overflow-hidden transition-all ${extended ? "w-52 ml-3" : "hidden"} font-[500]`} >
                        {text}
                    </span>

                    {!extended && (
                        <div className={`capitalize z-[200000] absolute left-full rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all  group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}>
                            {text}
                        </div>
                    )}
            </li>
        </a>
    )
}

export function SidebarText({ text }) {

    const { extended } = useStateContext()

    return (
        <span className={`uppercase text-[#A1ACB8] text-[13px] overflow-hidden transition-all ${extended ? "flex ml-3" : "hidden"} font-[400] pt-10`} >
            {text}
        </span>
    )
}


export function SidebarDropdown({ children, text, icon, route, angle = 0, hidden, setHidden }) {

    const { extended } = useStateContext()

    return (
        <>
            <li onClick={e => setHidden(!hidden)} className={`relative flex justify-between items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group
            ${window.location.pathname == route ?
                    "bg-[#f4f4f4] text-[#484848] text-[15px]"
                    :
                    "hover:bg-[#f4f4f4] text-[#2F4E70] bg-white text-[15px]"
                }
            `}
            >
                <span style={{ transform: `rotate(${angle})` }} className="flex items-center justify-center w-[20px]">
                    {icon}
                </span>
                <span className={`capitalize overflow-hidden transition-all ${extended ? "w-52 ml-3" : "hidden"} font-[500] flex justify-between items-center`} >
                    {text}
                    {
                        hidden ?
                            <i className="fa-solid fa-chevron-right"></i>
                            :
                            <i className="fa-solid fa-chevron-up"></i>
                    }
                </span>

                {!extended && (
                    <div className={`capitalize z-[200000] absolute w-max left-full rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all  group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}>
                        {text}
                    </div>
                )}
            </li>
            <div className={`${hidden ? 'hidden' : 'flex'} flex-col ${extended ? 'pl-5' : 'pl-0'}`}>{children}</div>
        </>
    )
}

export function SidebarDropdownItem({ icon, text, route, angle = 0 }) {

    const { extended } = useStateContext()

    return (
        <Link to={route}>
            <li className={`relative flex items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group
            ${window.location.pathname == route ?
                    "bg-[#f4f4f4] text-[#697A8D] text-[15px]"
                    :
                    "hover:bg-[#f4f4f4] text-[#697A8D] bg-white text-[15px]"
                }
            `}
            >
                <div className='w-[20px]'>
                    <span style={{ transform: `rotate(${angle})` }} className="flex items-center justify-center w-[20px]">
                        {icon}
                    </span>
                </div>

                <span className={`capitalize overflow-hidden transition-all ${extended ? "w-52 ml-3" : "hidden"} font-[400]`} >
                    {text}
                </span>

                {!extended && (
                    <div className={`capitalize z-[200000] absolute left-full rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all  group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}>
                        {text}
                    </div>
                )}
            </li>
        </Link>
    )
}


export function SiteSelection({ user }) {

    const { allSites, setCompany_id, setChoosesite, company_id, getCurrency } = useStateContext()

    const [selectedSite, setSelectedSite] = useState('')

    useEffect(() => {
        if(allSites.length > 0) {
            checkUserSite()
            setIds(
                localStorage.getItem('site'),
                localStorage.getItem('site'),
                localStorage.getItem('company_id')
            )
        }
    }, [allSites])

    const checkUserSite = () => {
        if (localStorage.getItem('impersonate') !== null) {
            if (
                //user?.role !== 'master_admin' &&
                user?.role !== 'office_manager' &&
                user?.role !== 'salesperson' &&
                user?.role !== 'client'
            ) {
                localStorage.setItem('site', user?.site_id)
                localStorage.setItem('company_id', user?.company_id)
            } else {
                localStorage.setItem('site', allSites[0]?.id)
                localStorage.setItem('company_id', allSites[0]?.company_id)
            }

            if (user?.company_id !== null && Number(user?.company_id) !== Number(company_id)) {
                getCurrency(user?.company_id)
            }
        }
    }

    const setIds = (chosenSite, selectedSite, selectedCompany) => {
        setCompany_id(selectedCompany)
        setChoosesite(chosenSite)
        setSelectedSite(selectedSite)
    }

    const siteSelect = (e) => {
        /* SITE RELATED */
        localStorage.setItem('site', e)
        setSelectedSite(e)
        setChoosesite(e)

        const site = allSites?.find(i => i.id == e)

        /* COMPANY RELATED */
        localStorage.setItem('company_id', site?.company_id)
        setCompany_id(site?.company_id)
        getCurrency(site?.company_id)

        /* if (Number(site?.company_id) != Number(company_id)) {
            getCustomerRequests(site?.company_id)
        } */
    }

    return (
        <FormControl sx={{ width: '100%', paddingX: '12px' }}>
            <Select id="site_select" sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' } }} value={selectedSite} onChange={e => { siteSelect(e.target.value) }}>
                {
                    allSites.map((item) => <MenuItem sx={{ whiteSpace: 'normal' }} key={item.id} value={item.id}>{item.name}</MenuItem>)
                }
            </Select>
        </FormControl>
    )
}
