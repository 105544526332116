import React, { useEffect, useState } from 'react'

import Swal from 'sweetalert2'
import InputError from '../../components/InputError'
import { Box, InputAdornment, TextField } from '@mui/material'
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Loading from '../../components/Loading'
import { isEqual, isEmpty } from 'lodash'
import { isValid } from '../../helpers/helper'
import { useAuth } from '../../hooks/auth'
import AuthCard from '../../components/AuthCard'
import Wrapper from '../../components/Wrapper'
import GuestLayout from '../../components/Layouts/GuestLayout'

const ResetPassword = () => {

    const { t } = useTranslation();
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()

    const { resetPassword } = useAuth({
        middleware: 'guest',
        redirectIfAuthenticated: '/choosesite'
    })

    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [type, setType] = useState('password')

    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState(null)

    const navigate = useNavigate()

    useEffect(() => {
        setIsLoading(false)

        if(
            isValid(status)
            && isEmpty(errors)
        ) {
            searchParams.delete('email')
            setSearchParams(searchParams)
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: t('password_successfully_changed'),
            })
            navigate('/login', {replace: true})
        }
    }, [status, errors])

    const handleResetPassword = async (e) => {
        e.preventDefault();

        setIsLoading(true)

        resetPassword({
            token: params.token,
            email: searchParams.get('email'),
            password,
            password_confirmation: passwordConfirmation,
            setErrors,
            setStatus
        })
    }

    return (
        <>
            {isLoading ? <Loading /> : null}
            <GuestLayout>
                <AuthCard>
                    <Wrapper>
                        <form onSubmit={handleResetPassword}>
                            <div className='min-w-[400px] max-[500px]:min-w-[300px]'>
                                <div className='w-full'>
                                    <p className='text-[#718096] text-[18px] mb-3'>{t('new_password')}</p>
                                    <TextField
                                        id="new-password"
                                        name="new-password"
                                        placeholder={'••••••'}
                                        className='w-full'
                                        sx={{
                                            boxShadow: 'none',
                                            '.MuiOutlinedInput-notchedOutline': {
                                                border: 'none !important'
                                            },
                                            background: '#F5F5F5',
                                            borderRadius: '6px',
                                        }}
                                        type="password"
                                        value={password}
                                        required
                                        onChange={(e) => { setPassword(e.target.value) }}
                                        InputProps={{
                                            autoComplete: 'off'
                                        }}
                                    />
                                </div>

                                <div className='w-full pt-5' style={{paddingBottom: !isEmpty(errors) ? '12px' : '0'}}>
                                    <p className='text-[#718096] text-[18px] mb-3'>{t('confirm_new_password')}</p>
                                    <TextField
                                        id="confirm-new-password"
                                        name="confirm-new-password"
                                        placeholder={'••••••'}
                                        className='w-full'
                                        sx={{
                                            boxShadow: 'none',
                                            '.MuiOutlinedInput-notchedOutline': {
                                                border: 'none !important'
                                            },
                                            background: '#F5F5F5',
                                            borderRadius: '6px',
                                        }}
                                        type={type}
                                        value={passwordConfirmation}
                                        required
                                        onChange={(e) => { setPasswordConfirmation(e.target.value) }}
                                        InputProps={{
                                            autoComplete: 'off',
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <div className='cursor-pointer' onClick={() => setType(type == 'text' ? 'password' : 'text')}><i className={`fa-regular ${isEqual(type, 'password') ? 'fa-eye' : 'fa-eye-slash'}`}></i></div>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>

                                <div className='w-full'>
                                    <InputError messages={Object.values(errors)[0]} className="mt-2" />
                                </div>

                                <div className="flex items-center w-full pt-5">
                                    <button className="w-full rounded-md bg-[#DBA34B] hover:bg-[#BD7503] disabled:bg-[#B8B7BC] text-white uppercase py-3 font-[700] text-[16px]" type='submit'>{t("reset_password")}</button>
                                </div>
                            </div>
                        </form>
                    </Wrapper>
                </AuthCard>
            </GuestLayout>
        </>
    )
}

export default ResetPassword
