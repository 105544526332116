import React, {useEffect, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {Autocomplete, Box, FormControl, MenuItem, Modal, Select, TextField, Tooltip, Stack} from '@mui/material'
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker';
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import {useNavigate, useLocation} from "react-router-dom"
import MenuBookIcon from '@mui/icons-material/MenuBook'
import { toNumber, includes } from 'lodash';

import style from '../../styles/style'
import {useStateContext} from '../../context/ContextProvider'
import axios from '../../lib/axios'
import TableShared from '../TableShared'
import AddButton from '../AddButton'
import AddPurchaseOrderItem from './AddPurchaseOrderItem'
import { deleteZero, handleAxiosError, handleInteger, handleZero, createRegexFromString, isValid } from '../../helpers/helper'

const CreatePurchaseOrder = ({ open, handleOpen, setIsLoading, type = 'COLLECTION' }) => {

    const { t } = useTranslation()
    const { company_id, config, choosesite, currencySymbol } = useStateContext()
    const navigate = useNavigate()
    const location = useLocation()

    /* state */
    const [vendors, setVendors] = useState([])
    const [purchaseLocations, setPurchaseLocations] = useState([])
    const [forkliftDrivers, setForkliftDrivers] = useState([])
    const [tplVendors, setTplVendors] = useState([])
    const [purchaseOrderItems, setPurchaseOrderItems] = useState([])
    const [openPurchaseOrderItem, setOpenPurchaseOrderItem] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalQty, setTotalQty] = useState(0)
    const [disabledButton, setDisabledButton] = useState(true)

    const [headerTextKey, setHeaderTextKey] = useState('create_new_po')

    /* form */
    const [vendorId, setVendorId] = useState('')
    const [purchaseAddressId, setPurchaseLocation] = useState('')
    const [address, setAddress] = useState('')
    const [zip, setZip] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [pickupInstructions, setPickupInstructions] = useState('')
    const [requestedCollectionDate, setRequestedCollectionDate] = useState(dayjs().endOf('day'))
    const [requestedCollectionTime, setRequestedCollectionTime] = useState(dayjs().endOf('minute'))
    const [collectedDate, setCollectedDate] = useState(dayjs().endOf('day'))
    const [customerBOL, setCustomerBOL] = useState('')
    const [brokerBOL, setBrokerBOL] = useState('')
    const [pickupTrailerNo, setPickupTrailerNo] = useState('')
    const [dropTrailerNo, setDropTrailerNo] = useState('')
    const [comments, setComments] = useState('')
    const [PCItems, setPCItems] = useState([])
    const [forkliftDriverId, setForkliftDriverId] = useState('')
    const [tplVendorId, setTplVendorId] = useState('')
    const [unloadedDate, setUnloadedDate] = useState(dayjs())

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == 'Enter' && open) {
                createPurchaseOrder()
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [
        open,
        vendorId,
        purchaseAddressId,
        dropTrailerNo,
        pickupTrailerNo,
        customerBOL,
        brokerBOL,
        requestedCollectionDate,
        requestedCollectionTime,
        choosesite,
        purchaseOrderItems,
        comments,
        forkliftDriverId,
        tplVendorId
    ])


    /* UseEffect */
    useEffect(() => {
        if(open) {
            if(isValid(company_id)) {
                getVendors(company_id)
                switch(type) {
                    case "STREET COLLECTION":
                        getForkliftDrivers()
                        break
                    case "3PL":
                        getTplVendors(company_id)
                        break
                    default:
                }
            }
        }
    }, [company_id, open, type])

    useEffect(() => {
        if (vendorId) {
            getPurchaseLocations(vendorId)
        }
        setPurchaseOrderItems([])
    }, [vendorId])

    useEffect(() => {
        setPurchaseOrderItems([])
        if (purchaseLocations.length > 0 && vendorId && purchaseAddressId) {
            getPurchaseContractItems()
        }
    }, [purchaseAddressId])

    useEffect(() => {
        if (PCItems.length > 0) {
            handleNewItem(PCItems)
        }
    }, [PCItems])

    useEffect(() => {
        handleTotalPrice()
        handleTotalQty()
    }, [purchaseOrderItems])

    useEffect(() => {
        switch(type) {
            case "STREET COLLECTION":
                setHeaderTextKey('create_new_street_collection')
                break
            case "3PL":
                setHeaderTextKey('create_new_3pl')
                break
            default:
                setHeaderTextKey('create_new_po')
        }
    }, [type])

    /* Handlers */

    const handleTotalPrice = () => {
        const sum = purchaseOrderItems.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.total_cost), 0);
        setTotalPrice(sum);
    }

    const handleTotalQty = () => {
        const item = purchaseOrderItems.filter((item) => item.qty_ordered !== "")
        if (item) {
            const sum = item.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_ordered), 0);
            setTotalQty(sum)
        } else {
            setTotalQty(0)
        }
    }

    const handleNewItem = (e) => {
        e.map((item) => {
            let new_item = {
                "id": item.id,
                "item_no": item.item_no,
                "description": item.description,
                "qty_ordered": item.quantity,
                "qty_to_receive": 0,
                "qty_received": 0,
                "qty_unloaded": 0,
                "vendor_count": 0,
                "unit_price": item.unit_price,
                "total_cost": item.quantity * item.unit_price,
                "unit_id": item.item_id,
                "color": item.color,
                "unit_of_measure_code": item.unit_of_measure_code
            }

            setPurchaseOrderItems((prev) => [...prev, new_item])
        })
    }

    const handleToggleModal = () => {
        handleOpen()
        setVendorId('')
        setPurchaseLocation('')
        setDropTrailerNo('')
        setPickupTrailerNo('')
        setCustomerBOL('')
        setBrokerBOL('')
        setComments('')
        setRequestedCollectionDate(dayjs().endOf('day'))
        setRequestedCollectionTime(dayjs().endOf('minute'))
        setCollectedDate(dayjs().endOf('day'))
        setUnloadedDate(dayjs())
        setPurchaseOrderItems([])
        setForkliftDriverId('')
        setTplVendorId('')
        setDisabledButton(true)
    }

    const handleCustomerBOL = (e) => {
        setCustomerBOL(e.target.value);
    }

    const handleBrokerBOL = (e) => {
        setBrokerBOL(e.target.value);
    }

    const handleShipToLocation = (e) => {
        setPurchaseLocation(e.target.value)
        const item = purchaseLocations.find(item => item.id === e.target.value)
        setAddress(item.address)
        setZip(item.zip)
        setCity(item.city)
        setState(item.state)
        setPickupInstructions(item.pickup_instructions)
    }

    const handleUpdateItem = (id, quantity, unit_price) => {
        const purchaseOrderItemsCopy = [...purchaseOrderItems]
        const item = purchaseOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.unit_price = unit_price
        item.total_cost = toNumber(quantity) * unit_price

        setPurchaseOrderItems(purchaseOrderItemsCopy)
    }

    const handleUpdateItemQTY = (id, quantity, tor) => {
        const purchaseOrderItemsCopy = [...purchaseOrderItems]
        const item = purchaseOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.qty_to_receive = toNumber(tor)


        setPurchaseOrderItems(purchaseOrderItemsCopy)
    }

    const handleUpdateVendorCount = (id, quantity) => {
        const purchaseOrderItemsCopy = [...purchaseOrderItems]
        const item = purchaseOrderItemsCopy.find(item => item.id === id)

        item.vendor_count = toNumber(quantity)

        setPurchaseOrderItems(purchaseOrderItemsCopy)
    }

    const handleOpenPurchaseOrderItem = () => {
        setDisabled(true)
        setOpenPurchaseOrderItem(!openPurchaseOrderItem)
    }


    /* API Requests */

    const getTplVendors = async (id) => {
        setTplVendorId('')
        setIsLoading(true)
        await axios
            .get(`/api/vendors?company_id=${id}&shipping_code=3PL&blocked=0`, config)
            .then(res => {
                const data = res.data
                setTplVendors(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getForkliftDrivers = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/users?role=forklift_driver&site_id=${localStorage.getItem('site')}`, config)
            .then(res => {
                const data = res.data.data
                setForkliftDrivers(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseContractItems = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/purchase-contracts?vendor_id=${vendorId}&purchase_address_id=${purchaseAddressId}`, config)
            .then(res => {
                const data = res.data
                setPCItems(data[0]?.purchase_contract_items || [])
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getVendors = async (id) => {
        setVendorId('')
        setIsLoading(true)
        await axios
            .get(`/api/vendors?company_id=${id}&blocked=0`, config)
            .then(res => {
                const data = res.data
                setVendors(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseLocations = async (id) => {
        setPurchaseLocation('')
        setAddress('')
        setZip('')
        setCity('')
        setState('')
        setPickupInstructions('')
        setIsLoading(true)
        await axios
            .get(`/api/purchase-addresses?vendor_id=${id}`, config)
            .then(res => {
                const data = res.data
                setPurchaseLocations(data)
                setPurchaseLocation(data[0]?.id || '')
                setAddress(data[0]?.address || '')
                setZip(data[0]?.zip || '')
                setCity(data[0]?.city || '')
                setState(data[0]?.state || '')
                setPickupInstructions(data[0]?.pickup_instructions || '')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const createPurchaseOrder = async () => {
        setIsLoading(true)
        const formData = {}

        formData['vendor_id'] = vendorId
        formData['purchase_address_id'] = purchaseAddressId
        formData['drop_trailer_no'] = dropTrailerNo
        formData['pickup_trailer_no'] = pickupTrailerNo
        formData['customer_bol_no'] = customerBOL
        formData['broker_bol_no'] = brokerBOL
        formData['requested_collection_date'] = dayjs(requestedCollectionDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['requested_collection_time'] = dayjs(requestedCollectionTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
        /* formData['collected_date'] = dayjs(collectedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss') */
        formData['site_id'] = choosesite
        formData['purchase_order_items'] = purchaseOrderItems
        formData['po_status_id'] = 1
        formData['comments'] = comments
        formData['po_type'] = 'collection'

        formData['type'] = includes(['COLLECTION', 'STREET COLLECTION', '3PL'], type) ? type : 'COLLECTION'

        switch(type) {
            case "STREET COLLECTION":
                formData['forklift_driver_id'] = forkliftDriverId
                formData['unloaded_date'] = dayjs(unloadedDate).format('YYYY-MM-DD HH:mm:ss')
                break
            case "3PL":
                formData['tpl_vendor_id'] = tplVendorId
                break
            default:
        }


        await axios
            .post(`/api/purchase-orders`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })

                navigate("/purchase-order/" + data.success.created.id, { state: { prevPathname: location.pathname } })

                handleToggleModal()
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const columns = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.5,
            maxWidth: 140
        },
        {
            field: 'unit_of_measure_code',
            headerName: t('uom'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'qty_ordered',
            headerName: t('qty_ordered'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyOrderedCell params={params} handler={handleUpdateItem} handlerQTR={handleUpdateItemQTY} />
        },
        {
            field: 'qty_to_receive',
            headerName: t('qty_to_receive'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyToReceiveCell params={params} t={t} handlerQTR={handleUpdateItemQTY} />
        },
        {
            field: 'qty_received',
            headerName: t('quantity_received'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyReceivedCell params={params} />
        },
        {
            field: 'vendor_count',
            headerName: t('vendor_count'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <VendorCountCell params={params} handlerVC={handleUpdateVendorCount} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <UnitPriceCell params={params} />
        },
        {
            field: 'total_cost',
            headerName: t('total'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <TotalCell params={params} />
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.7,
            minWidth: 75,
            cellClassName: 'padding-0',
            renderCell: (params) => <Actions params={params} t={t} purchaseOrderItems={purchaseOrderItems} setPurchaseOrderItems={setPurchaseOrderItems} />
        },
    ]

    const optionsVendors = vendors?.map(option => ({ label: option.vendor_no + ' - ' + option.name, id: option.id }))
    const optionsTplVendors = tplVendors?.map(option => ({ label: option.vendor_no + ' - ' + option.name, id: option.id }))

    return (
        <>
            <Modal open={open} onClose={handleToggleModal}>
                <Box sx={style}>
                    <div className='flex justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                        <div className='flex items-center justify-between w-full leading-none'>
                            <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#b7472a] text-[35px] mr-4'>•</span> {t(headerTextKey)}</p>
                            <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                                <button onClick={handleToggleModal}><i className="fa-solid fa-plus"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='p-5 pb-12 flex justify-between gap-3 flex-col lg:flex-row'>
                        <div className='bg-white p-6 pt-0 rounded-md w-full lg:w-2/5 h-fit edit-view'>
                            <div className='pb-11 pt-1'>
                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('general_information')}</p>
                            </div>

                            {/* Vendor */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('vendor')} *</p>
                                <div className='w-full'>
                                    <Autocomplete
                                        disablePortal
                                        disableClearable
                                        id="vendors-autocomplete"
                                        options={optionsVendors}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '0 !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        onChange={(a, b) => { setVendorId(b?.id || ''); setDisabledButton(false) }}
                                        renderInput={(params) => <TextField {...params} autoFocus={true} />}
                                    />
                                </div>
                            </div>

                            {/* Purchase Location */}
                            <div className='w-full pb-4'>
                                <Stack
                                    direction='row'
                                    gap={1}
                                    alignItems='baseline'
                                    justifyContent='space-between'
                                >
                                    <p className="text-[14px] text-[#718096]">{t('purchaseLocation')} *</p>
                                    {(purchaseAddressId && isValid(pickupInstructions)) ? (
                                        <Tooltip
                                            title={
                                                <div style={{ whiteSpace: 'pre-line' }}>
                                                    {pickupInstructions}
                                                </div>
                                            }
                                        >
                                            <Stack
                                                direction='row'
                                                gap={1}
                                                alignItems='center'
                                                className='text-[#718096]'
                                            >
                                                <MenuBookIcon fontSize='18px'/>
                                                <span className='text-[14px] min-[420px]:block hidden' style={{textTransform: 'uppercase'}}>{t('pickup_instructions')}</span>
                                            </Stack>
                                        </Tooltip>
                                    ) : null}
                                </Stack>

                                <div className='w-full'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Select value={purchaseAddressId} onChange={(e) => { handleShipToLocation(e) }}
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}>
                                            {purchaseLocations?.map((item, index) => <MenuItem key={index} value={item.id}>{item.code} - {item.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {
                                purchaseAddressId ?
                                    <div className='w-full p-4 address mb-4'>
                                        <div className='w-full'>{address}</div>
                                        {purchaseAddressId ? <div className='w-full'>{zip} {city}, {state}</div> : <div className='w-full'></div>}
                                    </div>
                                    :
                                    null
                            }

                            {/* 3PL Vendor */}
                            {type === '3PL' ? (
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('3pl_vendor')} *</p>
                                    <div className='w-full'>
                                        <Autocomplete
                                            disablePortal
                                            disableClearable
                                            id="tpl-vendors-autocomplete"
                                            options={optionsTplVendors}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            sx={{
                                                width: '100%',
                                                background: '#FCFCFC',
                                                '.MuiOutlinedInput-input': {
                                                    padding: '0 !important'
                                                },
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #EEEFF2 !important'
                                                }
                                            }}
                                            onChange={(a, b) => { setTplVendorId(b?.id || ''); setDisabledButton(false) }}
                                            renderInput={(params) => <TextField {...params} variant="standard" />}
                                        />
                                    </div>
                                </div>
                            ) : null}


                            {/* Drop Trailer No. */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('drop_trailer_no')}</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={dropTrailerNo} onChange={(e) => { setDropTrailerNo(e.target.value) }} />
                                </div>
                            </div>

                            {/* Pickup Trailer No. */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('pickup_trailer_no')}</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="pickup_trailer_no" value={pickupTrailerNo} onChange={(e) => { setPickupTrailerNo(e.target.value) }} />
                                </div>
                            </div>

                            {/* Customer BOL */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('customer_bol')}#</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="pickup_trailer_no" value={customerBOL} onChange={(e) => { handleCustomerBOL(e) }} />
                                </div>
                            </div>

                            {/* Broker BOL */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('broker_bol')}#</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="pickup_trailer_no" value={brokerBOL} onChange={(e) => { handleBrokerBOL(e) }} />
                                </div>
                            </div>

                            {/* Requested Delivery Date */}
                            <div className='lg:flex gap-3 w-full'>
                                {/* Requested Delivery Date */}
                                <div className='w-full lg:w-1/2 pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('requested_collection_date')}</p>
                                    <div className='w-full'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                format="YYYY-MM-DD" value={requestedCollectionDate} onChange={(newValue) => setRequestedCollectionDate(newValue)} />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className='w-full lg:w-1/2 pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('requested_collection_time')}</p>
                                    <div className='w-full'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer sx={{ padding: '0' }} components={['TimePicker', 'TimePicker']}>
                                                <MobileTimePicker
                                                    sx={{
                                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                    }}
                                                    value={requestedCollectionTime} onChange={(newValue) => setRequestedCollectionTime(newValue)} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='w-full lg:w-3/5 h-fit'>
                            <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                                <div className='pt-1 pl-3'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('purchaseOrderItems')}</p>
                                </div>
                                <div className='flex justify-end p-5 pt-0 border-b'>
                                    <Tooltip disableInteractive title={t('addpurchaseOrderItems')} placement='bottom'>
                                        <div>
                                            <AddButton disabled={disabledButton} onClick={handleOpenPurchaseOrderItem}><i className="fa-solid fa-plus"></i></AddButton>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className='border-b'>
                                    {
                                        purchaseOrderItems.length > 0 ?
                                            <div className='flex justify-end w-full p-2'>
                                                <button className='px-2 py-1 text-white bg-red-600 font-bold rounded-md mb-1 roboto text-xs uppercase' onClick={e => setPurchaseOrderItems([])}>{t('clear_all')}</button>
                                            </div>
                                            :
                                            null
                                    }
                                    <TableShared columns={columns} items={purchaseOrderItems} disableColumnMenu={true} />
                                </div>

                                <div className='flex justify-end w-full p-5'>
                                    <div className='w-full lg:w-1/3'>
                                        <div className='flex justify-between text-[#333333] text-[15px] pb-4'>
                                            <p>{t('totalPrice')}</p>
                                            <p className='font-[500]'>{currencySymbol}{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                        </div>
                                        <div className='flex justify-between text-[#333333] text-[15px]'>
                                            <p>{t('totalQty')}</p>
                                            <p className='font-[500]'>{totalQty}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='w-full'>
                                <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                                    <div className='pt-1 p-5'>
                                        <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('comments')}</p>
                                    </div>
                                    <div className='w-full p-5 pt-0'>
                                        <p className="text-[14px] text-[#718096]">{t('comments_text')}</p>
                                        <TextField
                                            multiline
                                            rows={3}
                                            value={comments}
                                            onChange={e => setComments(e.target.value)}
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='w-full flex justify-end'>
                                <button type="button" onClick={createPurchaseOrder} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#DBA34B] hover:bg-[#CF7300] disabled:bg-[#B8B7BC]'>
                                    <span className='pr-2'><i className="fa-solid fa-plus"></i></span> {t('create')}
                                </button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
            <AddPurchaseOrderItem open={openPurchaseOrderItem} handleOpen={handleOpenPurchaseOrderItem} purchaseOrderItems={purchaseOrderItems} setPurchaseOrderItems={setPurchaseOrderItems} disabled={disabled} setDisabled={setDisabled} />
        </>
    )
}

export default CreatePurchaseOrder

const maxQtyDigits = 5

const QtyOrderedCell = ({ params, handler, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    const handleZeroLocal = (e) => {
        if (e.target.value === "" || e.target.value === '-') {
            setQty(0)

            handler(
                params.row.id,
                0,
                params.row.unit_price
            )
        }
    }

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value < params.row.qty_to_receive) {
                    setQty(e.target.value)

                    handlerQTR(
                        params.row.id,
                        e.target.value,
                        params.row.qty_to_receive
                    )
                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                } else {
                    setQty(e.target.value)

                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                }
            }
        }
    }

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-ordered"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={handleZeroLocal}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const QtyToReceiveCell = ({ params, t, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_ordered])

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value > params.row.qty_ordered) {
                    setQty(params.row.qty_ordered)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        params.row.qty_ordered
                    )
                    Swal.fire({
                    icon: "warning",
                    text: t('warningPurchaseOrder'),
                    customClass: 'warning2',
                    showCloseButton: true,
                    iconColor: '#FFCA28'
                })
                } else {
                    setQty(e.target.value)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        e.target.value
                    )
                }
            }
        }
    }

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-to-recieve"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const QtyReceivedCell = ({ params }) => {
    const [qty, setQty] = useState(params.value)

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-received"
            value={qty}
            onChange={(e) => handleInteger({
                event: e,
                setter: setQty,
                end: 30
            }) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
            disabled
        />
    )
}

const VendorCountCell = ({ params, handlerVC }) => {
    const [qty, setQty] = useState(params.value || 0)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.vendor_count])

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                setQty(e.target.value)
                handlerVC(
                    params.row.id,
                    e.target.value
                )
            }
        }
    }

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="vendor-count"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                qty
            })}
        />
    )
}

const UnitPriceCell = (params) => {
    const { currencySymbol } = useStateContext()

    return (
        <>{currencySymbol}{params.params.row.unit_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const TotalCell = (params) => {
    const [total, setTotal] = useState(params.params.value)
    const { currencySymbol } = useStateContext()

    useEffect(() => {
        setTotal(params.params.row.qty_ordered * params.params.row.unit_price)
    }, [params])

    return (
        <>{currencySymbol}{total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const Actions = ({ params, t, purchaseOrderItems, setPurchaseOrderItems }) => {
    const deleteItem = async (id) => {
        const filtered = purchaseOrderItems.filter(i => i.id != id)
        setPurchaseOrderItems(filtered)
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteItem(params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
