import * as React from 'react'
import { useState, useEffect } from 'react'

import { Tooltip, TextField, Box, TableSortLabel } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from "react-i18next"
import TablePagination from "@mui/material/TablePagination";
import { useStateContext } from '../../context/ContextProvider'
import { getComparator, stableSort } from '../../helpers/DailyBoardTableHelpers'
import { visuallyHidden } from '@mui/utils'
import { deleteZero, handleZero, handleNumber } from '../../helpers/helper'
import { t } from 'i18next'

function Row(props) {
    const { row } = props

    return (
        <>
            <TableRow>
                <TableCell sx={{ width: '10%', padding: '8px 16px' }}>{row.item_no}</TableCell>
                <TableCell sx={{ width: '30%', padding: '8px 16px' }}>{row.description}</TableCell>
                <TableCell sx={{ width: '20%', padding: '8px 16px' }}><HandleQty params={props} handler={props.handler} /></TableCell>
                <TableCell sx={{ width: '20%', padding: '8px 16px' }}><HandleUnitPrice params={props} handler={props.handler} /></TableCell>
                <TableCell sx={{ width: '10%', padding: '8px 16px' }}><HandleTotal params={props} /></TableCell>
                <TableCell sx={{ width: '20%', padding: '8px 16px' }}><DeleteItem params={props} setItems={props.setItems} items={props.items}  /></TableCell>
            </TableRow>
        </>
    );
}

const SortableTableHeaderRow = (props) => {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {/* Item No. cell */}
                <TableCell
                    sortDirection={orderBy === 'item_no' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'item_no'}
                        direction={orderBy === 'item_no' ? order : 'asc'}
                        onClick={createSortHandler('item_no')}>
                        {t('no')}
                        {orderBy === 'item_no' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Description cell */}
                <TableCell
                    sortDirection={orderBy === 'description' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'description'}
                        direction={orderBy === 'description' ? order : 'asc'}
                        onClick={createSortHandler('description')}>
                        {t('description')}
                        {orderBy === 'description' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Quantity cell */}
                <TableCell
                    sortDirection={orderBy === 'quantity' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'quantity'}
                        direction={orderBy === 'quantity' ? order : 'asc'}
                        onClick={createSortHandler('quantity')}>
                        {t('quantity')}
                        {orderBy === 'quantity' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Unit Price cell */}
                <TableCell
                    sortDirection={orderBy === 'unit_price' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'unit_price'}
                        direction={orderBy === 'unit_price' ? order : 'asc'}
                        onClick={createSortHandler('unit_price')}>
                        {t('unit_price')}
                        {orderBy === 'unit_price' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Total Cost cell */}
                <TableCell
                    sortDirection={orderBy === 'total_cost' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'total_cost'}
                        direction={orderBy === 'total_cost' ? order : 'asc'}
                        onClick={createSortHandler('total_cost')}>
                        {t('total_cost')}
                        {orderBy === 'total_cost' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Actions cell */}
                <TableCell sx={{ color: '#88909C !important', fontWeight: '400' }}>{t('actions')}</TableCell>
            </TableRow>
        </TableHead>
    )
}

export default function CollapsibleTable({ items, itemsLength, handler, setItems }) {

    const { t } = useTranslation()

    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [orderBy, setOrderBy] = useState('created_at')
    const [order, setOrder] = useState('desc')
    const [visiblePurchaseContractItems, setVisiblePurchaseContractItems] = useState([])
    const [isFocused, setIsFocused] = useState(false)

    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }

    const handleRequestSort = (event, property) => {
        if(order === 'desc' && orderBy === property) {
            setOrderBy('created_at')
        } else {
            const isAsc = orderBy === property && order === 'asc'
            setOrder(isAsc ? 'desc' : 'asc')
            setOrderBy(property)
        }
    }

    const sortPurchaseContractItems = () => {
        return stableSort(items, getComparator(order, orderBy)).slice(
            pg * rpg,
            pg * rpg + rpg
        )
    }

    useEffect(() => {
        setVisiblePurchaseContractItems(items)
    }, [])

    useEffect(() => {
        setOrderBy('created_at')
        setOrder('desc')
    }, [itemsLength])

    useEffect(() => {
        setVisiblePurchaseContractItems((prev) => {
            if(!isFocused) {
                return sortPurchaseContractItems()
            }

            return prev
        })
    }, [itemsLength, order, orderBy, pg, rpg, isFocused])

    return (
        <>

            <div style={{ width: '100%', mb: 2, border: 'none' }}>
                <TableContainer>
                    <Table aria-label="table">
                        <colgroup>
                            <col width="10%" />
                            <col width="30%" />
                            <col width="17.5%" />
                            <col width="17.5%" />
                            <col width="15%" />
                            <col />
                        </colgroup>
                        <SortableTableHeaderRow
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody sx={{ position: 'relative' }}>
                            {items.length <= 0 ? <TableRow><TableCell colSpan={6} sx={{ textAlign: 'center' }}>{t('no_data')}</TableCell></TableRow> : null}
                            {visiblePurchaseContractItems.map((row, index) => (
                                    <Row key={`${row.id}-i_${index}-no_${row.item_no}`} row={row} handler={handler} items={items} setItems={setItems} setIsFocused={setIsFocused} />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className='table-pagination'
                    labelRowsPerPage={t('rowsPerPage')}
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={items.length}
                    rowsPerPage={rpg}
                    page={pg}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>
    );
}


const HandleQty = ({ params, handler }) => {

    const [qty, setQty] = useState(params.row.quantity)

    const handleZero = (e) => {
        if (e.target.value === "") {
            setQty(0)
        }
    }

    const deleteZero = (e) => {
        if (qty == 0) {
            setQty('')
        }
    }

    const handleQuantity = (e) => {
        const regex = /^(-?\d*)((\.(\d{0,2})?)?)$/i
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') setQty(0)
            else {
                setQty(e.target.value.slice(0, 5))

                handler(
                    params.row.id,
                    e.target.value.slice(0, 5),
                    params.row.unit_price
                )
            }
        }
    }

    return (
        <TextField type="text" variant='standard' className='w-2/3 mb-5 px-0 pt-0' name="qty" value={qty} onChange={(e) => { handleQuantity(e) }} onBlur={e => { handleZero(e); params.setIsFocused(false) }} onFocus={e => { deleteZero(e); params.setIsFocused(true) }} />
    )

}

const HandleUnitPrice = ({ params, handler }) => {

    const [unitPrice, setUnitPrice] = useState(params.row.unit_price)
    const { currencySymbol } = useStateContext()

    useEffect(() => {
        handler(
            params.row.id,
            params.row.quantity,
            unitPrice,
        )
    }, [unitPrice])

    return (
        <div className='flex items-center'>
            <span>{currencySymbol}</span>
            <TextField
                type="text"
                variant='standard'
                className='w-2/3 mb-5 px-0 pt-0'
                name="unit_price"
                value={unitPrice}
                onChange={(e) => { handleNumber({event: e, setter: setUnitPrice, precision: 2}) }}
                onBlur={e => { handleZero({event: e, setter: setUnitPrice}); params.setIsFocused(false) }}
                onFocus={e => { deleteZero({event: e, setter: setUnitPrice, value: unitPrice}); params.setIsFocused(true) }}
            />
        </div>
    )

}

const HandleTotal = (params) => {

    const [total, setTotal] = useState(params.params.row.total_cost)
    const {  currencySymbol } = useStateContext()

    useEffect(() => {
        setTotal(params.params.row.quantity * params.params.row.unit_price)
    }, [params])


    return (
        <>{currencySymbol}{total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )

}

const DeleteItem = (params) => {

    const { t } = useTranslation()

    const deleteItem = (id) => {
        params.setItems(params.items.filter((row) => row.id !== id))
    }

    return (
        <div className='flex justify-between'>
            <Tooltip disableInteractive  title={t('delete')} placement='bottom'>
                <div style={{ color: 'rgba(0,0,0,.54)' }}>
                    <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteItem(params.params.row.id)}><i className="fa-solid fa-trash"></i></span>
                </div>
            </Tooltip>
        </div>
    )

}
