import React, {useEffect, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {
    Autocomplete,
    Box,
    Checkbox,
    Chip,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Modal,
    Select,
    TextField,
    Tooltip
} from '@mui/material'
import Swal from 'sweetalert2'
import {Link, useSearchParams} from 'react-router-dom'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"

import TableShared from '../../components/TableShared'
import AddButton from '../../components/AddButton'
import Loading from '../../components/Loading'
import {useStateContext} from '../../context/ContextProvider'
import axios from '../../lib/axios'
import style from '../../styles/style'
import {useAuth} from "../../hooks/auth"
import {handleAxiosError, handleDeleteSelectionObjectPlus} from '../../helpers/helper'
import {isNull} from 'lodash'

const InsightsUsers = ({ id, customerSiteId, customerCompanyId }) => {
    const { user } = useAuth({ middleware: 'guest ' })

    const { config, pusher, choosesite, setChoosesite } = useStateContext()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false)

    const [searchName, setSearchName] = useState('')
    const [insightsUsers, setInsightsUsers] = useState([])

    const [shipToLocations, setShipToLocations] = useState([])
    const [vendors, setVendors] = useState([])
    const [purchaseLocations, setPurchaseLocations] = useState([])
    const [contacts, setContacts] = useState([])

    /* Pusher Channels (those that need to be bound and then rebound) */
    const [channelDeleteVendor, setChannelDeleteVendor] = useState(null)
    const [channelUpdateVendor, setChannelUpdateVendor] = useState(null)
    const [channelCreateVendor, setChannelCreateVendor] = useState(null)
    const [channelDeletePurchaseAddress, setChannelDeletePurchaseAddress] = useState(null)
    const [channelUpdatePurchaseAddress, setChannelUpdatePurchaseAddress] = useState(null)
    const [channelCreatePurchaseAddress, setChannelCreatePurchaseAddress] = useState(null)
    const [channelDeleteShipAddress, setChannelDeleteShipAddress] = useState(null)
    const [channelUpdateShipAddress, setChannelUpdateShipAddress] = useState(null)
    const [channelCreateShipAddress, setChannelCreateShipAddress] = useState(null)

    /* Form */
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [shipToLocation, setShipToLocation] = useState([])
    const [shipToLocationId, setShipToLocationId] = useState([])
    const [errorShipToLocation, setErrorShipToLocation] = useState(false)
    const [vendor, setVendor] = useState('')
    const [vendorName, setVendorName] = useState('')
    const [purchaseLocation, setPurchaseLocation] = useState([])
    const [purchaseLocationId, setPurchaseLocationId] = useState([])
    const [errorPurchaseLocation, setErrorPurchaseLocation] = useState(false)
    const [accountManager, setAccountManager] = useState('')
    const [accountingContact, setAccountingContact] = useState('')
    const [customerService, setCustomerService] = useState('')
    const [operationsManager, setOperationsManager] = useState('')

    const [openShipToLocations, setOpenShipToLocations] = useState(false)
    const [openPurchaseLocations, setOpenPurchaseLocations] = useState(false)

    const isAllSelectedShipTo = shipToLocations.length > 0 && shipToLocation.length === shipToLocations.length
    const isAllSelectedPurchase = purchaseLocations.length > 0 && purchaseLocation.length === purchaseLocations.length

    useEffect(() => {
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-insightsuser-deleted`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-insightsuser-created`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-insightsuser-updated`)

        const channeldeleteShipAddress = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-deleted`)
        const channelcreateShipAddress = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-created`)
        const channelupdateShipAddress = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)

        const channeldeleteVendor = pusher.subscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)
        const channelcreateVendor = pusher.subscribe(`${localStorage.getItem('client_id')}-vendor-created`)
        const channelupdateVendor = pusher.subscribe(`${localStorage.getItem('client_id')}-vendor-updated`)

        const channeldeletePurchaseAddress = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)
        const channelcreatePurchaseAddress = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-created`)
        const channelupdatePurchaseAddress = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)

        setChannelDeleteVendor(channeldeleteVendor)
        setChannelUpdateVendor(channelupdateVendor)
        setChannelCreateVendor(channelcreateVendor)
        setChannelDeletePurchaseAddress(channeldeletePurchaseAddress)
        setChannelUpdatePurchaseAddress(channelupdatePurchaseAddress)
        setChannelCreatePurchaseAddress(channelcreatePurchaseAddress)
        setChannelDeleteShipAddress(channeldeleteShipAddress)
        setChannelUpdateShipAddress(channelupdateShipAddress)
        setChannelCreateShipAddress(channelcreateShipAddress)

        {/* Insights User events */}
        channeldelete.bind(`${localStorage.getItem('client_id')}-insightsuser-deleted-event`, data => {
            if(Number(id) == Number(data.customer_id)) {
                getInsightsUsers()
            }
        })

        channelcreate.bind(`${localStorage.getItem('client_id')}-insightsuser-created-event`, data => {
            if(Number(id) == Number(data.customer_id)) {
                getInsightsUsers()
            }
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-insightsuser-updated-event`, data => {
            if(Number(id) == Number(data.customer_id)) {
                getInsightsUsers()
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-insightsuser-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-insightsuser-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-insightsuser-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)
        })
    }, [])

    {/* Apparently there is a risk where you may incorrectly bind events. In our case, outdated props are used. */}
    useEffect(() => {
        {/* Ship To Address events */}
        if(channelDeleteShipAddress && channelDeleteShipAddress.bind) {
            channelDeleteShipAddress.unbind(`${localStorage.getItem('client_id')}-shipaddress-deleted-event`)
            channelDeleteShipAddress.bind(`${localStorage.getItem('client_id')}-shipaddress-deleted-event`, data => {
                if(Number(id) == Number(data.customer_id)) {
                    handleClearShipTo()
                    getShipToLocations(id)
                }
            })
        }

        if(channelCreateShipAddress && channelCreateShipAddress.bind) {
            channelCreateShipAddress.unbind(`${localStorage.getItem('client_id')}-shipaddress-created-event`)
            channelCreateShipAddress.bind(`${localStorage.getItem('client_id')}-shipaddress-created-event`, data => {
                if(Number(id) == Number(data.customer_id)) {
                    getShipToLocations(id)
                }
            })
        }

        if(channelUpdateShipAddress && channelUpdateShipAddress.bind) {
            channelUpdateShipAddress.unbind(`${localStorage.getItem('client_id')}-shipaddress-updated-event`)
            channelUpdateShipAddress.bind(`${localStorage.getItem('client_id')}-shipaddress-updated-event`, data => {
                if(Number(id) == Number(data.customer_id)) {
                    handleClearShipTo()
                    getShipToLocations(id)
                }
            })
        }

        {/* Vendor events */}
        if(channelDeleteVendor && channelDeleteVendor.bind) {
            channelDeleteVendor.unbind(`${localStorage.getItem('client_id')}-vendor-deleted-event`)
            channelDeleteVendor.bind(`${localStorage.getItem('client_id')}-vendor-deleted-event`, data => {
                if(Number(customerCompanyId) == Number(data.company_id)) {
                    if(Number(vendor) == Number(data.id)) handleClearVendor()
                    getVendors()
                }
            })
        }

        if(channelCreateVendor && channelCreateVendor.bind) {
            channelCreateVendor.unbind(`${localStorage.getItem('client_id')}-vendor-created-event`)
            channelCreateVendor.bind(`${localStorage.getItem('client_id')}-vendor-created-event`, data => {
                if(Number(customerCompanyId) == Number(data.company_id)) {
                    getVendors()
                }
            })
        }

        if(channelUpdateVendor && channelUpdateVendor.bind) {
            channelUpdateVendor.unbind(`${localStorage.getItem('client_id')}-vendor-updated-event`)
            channelUpdateVendor.bind(`${localStorage.getItem('client_id')}-vendor-updated-event`, data => {
                if(Number(customerCompanyId) == Number(data.company_id)) {
                    if(Number(vendor) == Number(data.id)) handleClearVendor()
                    getVendors()
                }
            })
        }

        {/* Purchase Address events */}
        if(channelDeletePurchaseAddress && channelDeletePurchaseAddress.bind) {
            channelDeletePurchaseAddress.unbind(`${localStorage.getItem('client_id')}-purchaseaddress-deleted-event`)
            channelDeletePurchaseAddress.bind(`${localStorage.getItem('client_id')}-purchaseaddress-deleted-event`, data => {
                if(Number(vendor) == Number(data.vendor_id)) {
                    handleClearPurchase()
                    getPurchaseLocations()
                }
            })
        }

        if(channelCreatePurchaseAddress && channelCreatePurchaseAddress.bind) {
            channelCreatePurchaseAddress.unbind(`${localStorage.getItem('client_id')}-purchaseaddress-created-event`)
            channelCreatePurchaseAddress.bind(`${localStorage.getItem('client_id')}-purchaseaddress-created-event`, data => {
                if(Number(vendor) == Number(data.vendor_id)) {
                    getPurchaseLocations()
                }
            })
        }

        if(channelUpdatePurchaseAddress && channelUpdatePurchaseAddress.bind) {
            channelUpdatePurchaseAddress.unbind(`${localStorage.getItem('client_id')}-purchaseaddress-updated-event`)
            channelUpdatePurchaseAddress.bind(`${localStorage.getItem('client_id')}-purchaseaddress-updated-event`, data => {
                if(Number(vendor) == Number(data.vendor_id)) {
                    handleClearPurchase()
                    getPurchaseLocations()
                }
            })
        }
    }, [id, customerCompanyId, vendor])

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '13') {
                if (open) createInsightsUser()
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [open, name, email, shipToLocationId, vendor, purchaseLocationId, accountManager, accountingContact, customerService, operationsManager]);


    useEffect(() => {
        getInsightsUsers()
        if (id) {
            getShipToLocations()

        }
        if(customerCompanyId) {
            getVendors()
        }
        if(customerSiteId) {
            getContacts(customerSiteId)
        } else {
            getContacts()
        }
    }, [id, customerCompanyId, customerSiteId])

    useEffect(() => {
        if (vendor) getPurchaseLocations()
    }, [vendor])

    useEffect(() => {
        if (choosesite) getContacts(choosesite)
    }, [choosesite])

    {/* Clears all inputs when closing the window */}
    useEffect(() => {
        if(!open) {
            setName('')
            setEmail('')
            setShipToLocation([])
            setShipToLocationId([])
            setErrorShipToLocation(false)
            setVendor('')
            setVendorName('')
            setPurchaseLocation([])
            setPurchaseLocationId([])
            setErrorPurchaseLocation(false)
            setAccountManager('')
            setAccountingContact('')
            setCustomerService('')
            setOperationsManager('')
        }
    }, [open])

    const getInsightsUsers = async (customerId = null) => {
        setIsLoading(true)
        await axios.get(`/api/insights-users?customer_id=${isNull(customerId) ? id : customerId}`, config)
            .then(res => {
                const users = res.data
                setInsightsUsers(users)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getShipToLocations = async (customerId = null) => {
        setIsLoading(true)
        await axios.get(`/api/ship-addresses?customer_id=${isNull(customerId) ? id : customerId}`, config)
            .then(res => {
                const users = res.data
                setShipToLocations(users)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getVendors = async () => {
        setIsLoading(true)
        await axios.get(`/api/vendors?company_id=${customerCompanyId}`, config)
            .then(res => {
                const users = res.data
                setVendors(users)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseLocations = async () => {
        setIsLoading(true)
        await axios.get(`/api/purchase-addresses?vendor_id=${vendor}`, config)
            .then(res => {
                const users = res.data
                setPurchaseLocations(users)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getContacts = async (siteId = null) => {
        setIsLoading(true)
        await axios.get(`/api/insights-contacts?site_id=${isNull(siteId) ? localStorage.getItem('site') : siteId}`, config)
            .then(res => {
                const users = res.data
                setContacts(users)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }


    const handleOpen = () => {
        setOpen(true)
    }


    const handleClose = () => {
        setOpen(!open)
        setName('')
        setEmail('')
        setShipToLocation([])
        setShipToLocationId([])
        setVendor('')
        setPurchaseLocation([])
        setPurchaseLocationId([])
        setAccountManager('')
        setAccountingContact('')
        setCustomerService('')
        setOperationsManager('')
    }

    const handleShipToLocation = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = [];

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
                duplicateRemoved.push(item);
            }
        })

        let ids = []

        value.forEach((item) => {
            if (ids.findIndex((o) => o.id === item.id) >= 0) {
                ids = ids.filter((x) => x.id === item.id);
            } else {
                ids.push(item.id);
            }
        })

        if (value[value.length - 1] === "all") {
            setShipToLocation(shipToLocation.length === shipToLocations.length ? [] : shipToLocations);
            setShipToLocationId(shipToLocation.length === shipToLocations.length ? [] : shipToLocations.map(i => i.id))
            setErrorShipToLocation(false)
            return;
        }
        setShipToLocation(duplicateRemoved)
        setShipToLocationId(ids)
        setErrorShipToLocation(false)
    }

    const handleClearShipTo = () => {
        setShipToLocation([])
        setShipToLocationId([])
    }

    const handlePurchaseLocation = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = [];

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
                duplicateRemoved.push(item);
            }
        })

        let ids = []

        value.forEach((item) => {
            if (ids.findIndex((o) => o.id === item.id) >= 0) {
                ids = ids.filter((x) => x.id === item.id);
            } else {
                ids.push(item.id);
            }
        })

        if (value[value.length - 1] === "all") {
            setPurchaseLocation(purchaseLocation.length === purchaseLocations.length ? [] : purchaseLocations);
            setPurchaseLocationId(purchaseLocation.length === purchaseLocations.length ? [] : purchaseLocations.map(i => i.id))
            setErrorPurchaseLocation(false)
            return;
        }
        setPurchaseLocation(duplicateRemoved)
        setPurchaseLocationId(ids)
        setErrorPurchaseLocation(false)
    }

    const handleClearPurchase = () => {
        setPurchaseLocation([])
        setPurchaseLocationId([])
    }

    const handleClearVendor = () => {
        setVendor('')
        setVendorName('')
        setPurchaseLocation([])
        setPurchaseLocationId([])
    }

    const createInsightsUser = async () => {

        /* if (shipToLocationId.length < 1) {
            setErrorShipToLocation(true)
        } else if (vendor && purchaseLocationId.length < 1) {
            setErrorPurchaseLocation(true)
        } else { */
        setIsLoading(true)
        const formData = {}

        formData['name'] = name
        formData['email'] = email
        formData['ship_to_locations'] = shipToLocationId
        formData['vendor_id'] = vendor
        formData['purchase_locations'] = purchaseLocationId
        formData['account_manager_id'] = accountManager
        formData['accounting_contact_id'] = accountingContact
        formData['customer_service_contact_id'] = customerService
        formData['operations_manager_contact_id'] = operationsManager
        formData['company_id'] = customerCompanyId
        formData['site_id'] = customerSiteId || localStorage.getItem('site')
        formData['customer_id'] = id


        await axios.post(`/api/insights-users`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })

            handleClose()
        })
        .catch(({ response }) => {
            handleAxiosError({response: response})
        })
        .finally(() => {
            setIsLoading(false)
        })
        /* } */
    }

    const columns = [
        {
            field: 'id',
            headerName: t('id'),
            flex: 0.5
        },
        {
            field: 'name',
            headerName: t('name'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'email',
            headerName: t('email'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'ship_to_locations_name',
            headerName: t('ship_to'),
            flex: 1,
            renderCell: (params) => <ShowShipToLocatoions params={params} />,
            minWidth: 200,
            sortable: false
        },
        {
            field: 'vendor_name',
            headerName: t('vendor'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'purchase_locations_name',
            headerName: t('purchase_addresses'),
            flex: 1,
            renderCell: (params) => <ShowPurchaseLocatoions params={params} />,
            minWidth: 200,
            sortable: false
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => <InsightsUserActions params={params} config={config} customer_id={id} setIsLoading={setIsLoading} />,
            minWidth: 200
        }
    ]

    const filtered = insightsUsers?.filter(data => {
        if (searchName === null)
            return data
        if (data?.name?.toLocaleLowerCase().includes(searchName?.toLocaleLowerCase()))
            return data
    })

    const vendorOptions = vendors?.map(option => ({ label: option.name, id: option.id }))

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <div className='p-5'>
                <div className='pb-5 bg-white mb-2 rounded-md'>
                    <div className='flex justify-between items-center p-5 border-b'>
                        <p style={{fontWeight: 600, fontSize: '16px'}}>{t('customer_users')}</p>
                        <Tooltip disableInteractive title={t('create_insights_user')} placement='bottom'>
                            <div>
                                <AddButton onClick={handleOpen}><i className="fa-solid fa-plus"></i></AddButton>
                            </div>
                        </Tooltip>
                    </div>
                    <div className='w-full border-b pb-5'>
                        <div className='flex justify-between items-end w-1/2'>
                            <div className='px-5 pt-5 w-full'>
                                <div className='flex justify-between items-center search'>
                                    <input type="text" value={searchName} placeholder={t('search_by_name')} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSearchName(e.target.value)} />
                                    <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pt-3'>
                        <TableShared items={filtered} columns={columns} /* noRowsLabel={t('no_results')} */ />
                    </div>
                </div>

            </div>

            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <div className='flex justify-between items-center p-5 pr-0 pt-0 pb-0' style={{ backgroundColor: '#336195', borderRadius: '5px 5px 0 0' }}>
                        <div className='flex gap-4 items-baseline'>
                            <div style={{ transform: "rotate(45deg)" }} className="font-semibold text-white">
                                <button onClick={handleClose}><i className="fa-solid fa-plus"></i></button>
                            </div>
                            <p className='text-xl roboto font-semibold text-white'>{t('create_insights_user')}</p>
                        </div>
                        <button type="button" onClick={createInsightsUser} className='text-white px-4 py-6 uppercase self-end roboto bg-zinc-900 hover:bg-zinc-700 disabled:bg-zinc-400'>
                            {t('create')}
                        </button>
                    </div>

                    <div className='p-5'>
                        <form className='flex justify-center flex-col items-start mt-2'>

                            <TextField type="text" variant='standard' label={t('name')} sx={{ marginBottom: '20px' }} className='w-full mb-5 px-0 pt-0' name="name" value={name} onChange={(e) => { setName(e.target.value) }} required />

                            <TextField type="text" variant='standard' label={t('email')} sx={{ marginBottom: '20px' }} className='w-full mb-5 px-0 pt-0' name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} required />

                            <FormControl error={errorShipToLocation} variant="standard" sx={{ width: '100%', marginBottom: '20px' }} focused={openShipToLocations}>
                                <InputLabel>{t('ship_to')} *</InputLabel>
                                <Select
                                    multiple
                                    value={shipToLocation}
                                    open={openShipToLocations}
                                    onOpen={() => setOpenShipToLocations(true)}
                                    onClose={() => setOpenShipToLocations(false)}
                                    onChange={handleShipToLocation}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((x) => (
                                                <Chip
                                                    key={`stl_option-${x.id}`}
                                                    label={x.name}
                                                    onMouseDown={(e) => e.stopPropagation()}
                                                    onDelete={(e) => handleDeleteSelectionObjectPlus(e, x.id, shipToLocation, setShipToLocation, setShipToLocationId, setOpenShipToLocations)}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    sx={{ ".MuiSelect-iconStandard": { display: (shipToLocation.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' } }}
                                    endAdornment={shipToLocation ? (<IconButton sx={{ visibility: (shipToLocation.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearShipTo}><ClearIcon /></IconButton>) : false}
                                >
                                    {
                                        shipToLocations?.length > 0 ?
                                            <MenuItem value="all">
                                                <ListItemIcon>
                                                    <Checkbox checked={isAllSelectedShipTo} indeterminate={shipToLocation.length > 0 && shipToLocation.length < shipToLocations.length} />
                                                </ListItemIcon>
                                                <ListItemText primary={t('select_all')} />
                                            </MenuItem>
                                            :
                                            <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                    }
                                    {
                                        shipToLocations.map((item) =>
                                            <MenuItem value={item} key={item.id}>
                                                <Checkbox checked={shipToLocation?.findIndex((i) => i.id === item.id) >= 0} />
                                                <ListItemText primary={item.name} />
                                            </MenuItem>
                                        )
                                    }
                                </Select>

                                {errorShipToLocation ? (
                                    <FormHelperText>{t('please_choose_at_least_x_y', {
                                        x: 1,
                                        y: t('ship_to_location')
                                    })}</FormHelperText>
                                ) : (
                                    ''
                                )}
                            </FormControl>

                            <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }}>
                                <Autocomplete
                                    disablePortal
                                    id="vendor-multiselect-box"
                                    options={vendorOptions}
                                    value={vendor ? { label: vendorName, id: vendor } : null}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{
                                        width: '100%',
                                        background: 'transparent',
                                        '.MuiOutlinedInput-input': {
                                            padding: '0 !important'
                                        },
                                        boxShadow: 'none',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            border: '1px solid #EEEFF2 !important'
                                        }
                                    }}
                                    onChange={(a, b) => { setVendor(b?.id || ''); setVendorName(b?.label || ''); setPurchaseLocation([]); setPurchaseLocationId([]) }}
                                    renderInput={(params) => <TextField {...params} variant="standard" label={t('vendor')} />}
                                    renderOption={(props, option) => (
                                        <li {...props} key={`vendor-${option.id}`}>
                                            {option.label}
                                        </li>
                                    )}
                                />
                            </FormControl>

                            <FormControl error={errorPurchaseLocation} variant="standard" sx={{ width: '100%', marginBottom: '20px' }} focused={openPurchaseLocations}>
                                <InputLabel>{t('purchase_addresses')}</InputLabel>
                                <Select
                                    multiple
                                    disabled={!vendor}
                                    value={purchaseLocation}
                                    open={openPurchaseLocations}
                                    onOpen={() => setOpenPurchaseLocations(true)}
                                    onClose={() => setOpenPurchaseLocations(false)}
                                    onChange={handlePurchaseLocation}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((x) => (
                                                <Chip
                                                    key={`stl_option-${x.id}`}
                                                    label={x.name}
                                                    onMouseDown={(e) => e.stopPropagation()}
                                                    onDelete={(e) => handleDeleteSelectionObjectPlus(e, x.id, purchaseLocation, setPurchaseLocation, setPurchaseLocationId, setOpenPurchaseLocations)}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    sx={{ ".MuiSelect-iconStandard": { display: (purchaseLocation.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' } }}
                                    endAdornment={purchaseLocation ? (<IconButton sx={{ visibility: (purchaseLocation.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearPurchase}><ClearIcon /></IconButton>) : false}
                                >
                                    {
                                        purchaseLocations?.length > 0 ?
                                            <MenuItem value="all">
                                                <ListItemIcon>
                                                    <Checkbox checked={isAllSelectedPurchase} indeterminate={purchaseLocation.length > 0 && purchaseLocation.length < purchaseLocations.length} />
                                                </ListItemIcon>
                                                <ListItemText primary={t('select_all')} />
                                            </MenuItem>
                                            :
                                            <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                    }
                                    {
                                        purchaseLocations.map((item) =>
                                            <MenuItem value={item} key={item.id}>
                                                <Checkbox checked={purchaseLocation?.findIndex((i) => i.id === item.id) >= 0} />
                                                <ListItemText primary={item.name} />
                                            </MenuItem>
                                        )
                                    }
                                </Select>

                                {errorPurchaseLocation ? (
                                    <FormHelperText>{t('please_choose_at_least_x_y', {
                                        x: 1,
                                        y: t('purchase_location')
                                    })}</FormHelperText>
                                ) : (
                                    ''
                                )}
                            </FormControl>

                            <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }}>
                                <InputLabel id="demo-simple-select-standard-label">{t('account_manager')}</InputLabel>
                                <Select
                                    value={accountManager}
                                    onChange={(e) => { setAccountManager(e.target.value) }}
                                    sx={{
                                        ".MuiSelect-iconStandard": {
                                            display: accountManager ? 'none !important' : ''
                                        },
                                        "&.Mui-focused .MuiIconButton-root": {
                                            color: 'rgba(0,0,0,.42)'
                                        }
                                    }}
                                    endAdornment={accountManager ? (<IconButton sx={{ visibility: accountManager ? "visible" : "hidden", padding: '0' }} onClick={e => setAccountManager('')}><ClearIcon /></IconButton>) : false}
                                >
                                    {
                                        contacts.filter((i) => i.role === 9).map((item) =>
                                            <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>

                            <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }}>
                                <InputLabel id="demo-simple-select-standard-label">{t('accounting_contact')}</InputLabel>
                                <Select
                                    value={accountingContact}
                                    onChange={(e) => { setAccountingContact(e.target.value) }}
                                    sx={{
                                        ".MuiSelect-iconStandard": {
                                            display: accountingContact ? 'none !important' : ''
                                        },
                                        "&.Mui-focused .MuiIconButton-root": {
                                            color: 'rgba(0,0,0,.42)'
                                        }
                                    }}
                                    endAdornment={accountingContact ? (<IconButton sx={{ visibility: accountingContact ? "visible" : "hidden", padding: '0' }} onClick={e => setAccountingContact('')}><ClearIcon /></IconButton>) : false}
                                >
                                    {
                                        contacts.filter((i) => i.role === 10).map((item) =>
                                            <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>

                            <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }}>
                                <InputLabel id="demo-simple-select-standard-label">{t('customer_service')}</InputLabel>
                                <Select
                                    value={customerService}
                                    onChange={(e) => { setCustomerService(e.target.value) }}
                                    sx={{
                                        ".MuiSelect-iconStandard": {
                                            display: customerService ? 'none !important' : ''
                                        },
                                        "&.Mui-focused .MuiIconButton-root": {
                                            color: 'rgba(0,0,0,.42)'
                                        }
                                    }}
                                    endAdornment={customerService ? (<IconButton sx={{ visibility: customerService ? "visible" : "hidden", padding: '0' }} onClick={e => setCustomerService('')}><ClearIcon /></IconButton>) : false}
                                >
                                    {
                                        contacts.filter((i) => i.role === 11).map((item) =>
                                            <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>

                            <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }}>
                                <InputLabel id="demo-simple-select-standard-label">{t('operations_manager')}</InputLabel>
                                <Select
                                    value={operationsManager}
                                    onChange={(e) => { setOperationsManager(e.target.value) }}
                                    sx={{
                                        ".MuiSelect-iconStandard": {
                                            display: operationsManager ? 'none !important' : ''
                                        },
                                        "&.Mui-focused .MuiIconButton-root": {
                                            color: 'rgba(0,0,0,.42)'
                                        }
                                    }}
                                    endAdornment={operationsManager ? (<IconButton sx={{ visibility: operationsManager ? "visible" : "hidden", padding: '0' }} onClick={e => setOperationsManager('')}><ClearIcon /></IconButton>) : false}
                                >
                                    {
                                        contacts.filter((i) => i.role === 12).map((item) =>
                                            <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>

                        </form>
                    </div>
                </Box>
            </Modal >
        </>
    )
}

export default InsightsUsers

const ShowPurchaseLocatoions = (params) => {

    return (
        <>
            {
                params.params.row.purchase_locations_info.map((i, index) => <div key={i.id}>{i.name}{index + 1 < params.params.row.purchase_locations_info.length ? "," : ""}</div>)
            }
        </>
    )
}

const ShowShipToLocatoions = (params) => {

    return (
        <>
            {
                params.params.row.ship_to_locations_info.map((i, index) => <div key={i.id}>{i.name}{index + 1 < params.params.row.ship_to_locations_info.length ? "," : ""}</div>)
            }
        </>
    )
}

const InsightsUserActions = (params) => {

    const [searchParams, setSearchParams] = useSearchParams()
    const { t } = useTranslation()
    const { user, loginImpersonate } = useAuth({ middleware: 'guest' })
    const { setCompany_id, setChoosesite } = useStateContext()
    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState(null)

    // tablicu rijesiti

    const deleteItems = async (id) => {
        const isConfirm = await Swal.fire({
            title: t('delete_insights_user') + params.params.row.name + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        await axios.delete(`/api/insights-users/${id}`, params.config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                text: data.success.message
            })
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error",
customClass: 'error',
showCloseButton: true,
iconColor: '#FF0000'
            })
        })
    }

    const impersonate = async (id, role, company_id, site_id, name) => {
        searchParams.delete('tab')
        setSearchParams(searchParams)

        await loginImpersonate({
            id: id,
            impersonator_id: user?.id,
            impersonatee_name: name,
            impersonatee_site_id: site_id,
            impersonatee_company_id: company_id,
            role: role,
            site_id: localStorage.getItem('site'),
            company_id: localStorage.getItem('company_id'),
            setErrors,
            setStatus,
        }).then((data) => {
            setCompany_id(company_id)
            setChoosesite(localStorage.getItem('site'))
        }).catch(({ response }) => {
            setSearchParams({ tab: 'customer-users' })
        })
    }


    return (
        <>
            <div className='flex justify-between'>
                {
                    (user?.role === "master_admin" || user?.role === "office_manager") && (localStorage.getItem('impersonate') === null) && (params.params.row.role_slug !== "master_admin") ?
                        <Tooltip disableInteractive title={t('impersonate')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => impersonate(params.params.row.id, 'customers_user', params.params.row.company_id, params.params.row.site_id, params.params.row.name)}><i className="fa-solid fa-user"></i></span>
                            </div>
                        </Tooltip> : ''
                }
                <Tooltip disableInteractive title={t('change_password')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <Link to={`/users/change-password/${params.params.row.id}`}>
                            <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-key"></i></span>
                        </Link>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={t('edit')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <Link to={`/customer-user/${params.params.row.id}`}><span style={{ cursor: 'pointer' }}
                            className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i
                                className="fa-solid fa-pencil"></i></span></Link>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteItems(params.params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}

