import React, {useEffect, useState} from 'react'
import AppLayout from '../../components/Layouts/AppLayout'
import {useTranslation} from 'react-i18next'
import {useStateContext} from '../../context/ContextProvider';
import {useAuth} from '../../hooks/auth';
import { Divider, List, ListItemButton, styled } from '@mui/material';
import ReportModal from '../../components/reports/ReportModal';
import axios from '../../lib/axios';
import { REPORT_CODES } from '../../constants/constants'
import Loading from '../../components/Loading';
import { isNil } from 'lodash';
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack';
import ClearIcon from "@mui/icons-material/Clear"

const CustomListItemButton = styled(ListItemButton)({
    paddingTop: "14px !important",
    paddingBottom: "14px !important",
    paddingLeft: "20px !important"
})

const Reports = () => {
    const { t } = useTranslation()
    const { config, company_id, choosesite, allSites } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })

    /* State(s) */
    const [isLoading, setIsLoading] = useState(false)
    const [loadingProgress, setLoadingProgress] = useState(0)

    const [vendors, setVendors] = useState([])
    const [customers, setCustomers] = useState([])
    const [drivers, setDrivers] = useState([])
    const [forklifters, setForklifters] = useState([])
    const [users, setUsers] = useState([])
    const [productionTypes, setProductionTypes] = useState([])
    const [productionEmployees, setProductionEmployees] = useState([])

    const [reportCode, setReportCode] = useState('')
    const [open, setOpen] = useState(false)

    /* Function(s) */
    const handleOpen = (rc = 'default') => {
        setReportCode(rc)
        setOpen(true)
    }
    const handleClose = () =>  {
        setOpen(false)
        setVendors([])
        setCustomers([])
        setDrivers([])
        setForklifters([])
        setUsers([])
        setProductionTypes([])
        setProductionEmployees([])
    }

    /* useEffect(() => {
        getData(localStorage.getItem('company_id'), localStorage.getItem('site'))
    }, [])

    useEffect(() => {
        if(company_id && choosesite) {
            getData(company_id, choosesite)
        }
    }, [company_id, choosesite])

    const getData = async (companyId = null, siteId = null) => {
        const start = Date.now()
        console.log('--- GETTING DATA')
        setIsLoading(true)

        const companyFunctions = !isNil(companyId) ? [getVendors, getCustomers, getUsers, getProductionTypes] : []
        const siteFunctions = !isNil(siteId) ? [getDrivers, getForklifters, getProductionEmployees] : []

        const functionCount = companyFunctions.length + siteFunctions.length

        try {
            let counter = 0
            for (const func of companyFunctions) {
                await func(companyId)
                counter++
                setLoadingProgress(Math.round((counter / functionCount) * 100))
            }
            for (const func of siteFunctions) {
                await func(siteId)
                counter++
                setLoadingProgress(Math.round((counter / functionCount) * 100))
            }
            setLoadingProgress(0)
        } catch (error) {
            console.log(error)
        } then {
            setIsLoading(false)
            const milis = Date.now() - start
            console.log(`--- RETRIEVED DATA. Seconds elapsed: ${Math.floor(milis / 1000)}`)
        }
    } */

    const getVendors = async (company_id) => {
        setIsLoading(true)

        await axios.get(`/api/vendors?with=purchase_addresses&company_id=${company_id}`, config)
            .then(res => {
                const vendors = res.data
                setVendors(vendors)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('vendors')
                    })
                )
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getCustomers = async (company_id) => {
        setIsLoading(true)

        await axios.get(`/api/customers?with=ship_addresses&company_id=${company_id}`, config)
            .then(res => {
                const customers = res.data
                setCustomers(customers)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('customers')
                    })
                )
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getDrivers = async (site_id) => {
        setIsLoading(true)

        await axios.get(`/api/users?role=driver&site_id=${site_id}`, config)
            .then(res => {
                const drivers = res.data.data
                setDrivers(drivers)
           })
           .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('drivers')
                    })
                )
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getForklifters = async (site_id) => {
        setIsLoading(true)

        await axios.get(`/api/users?role=forklift_driver&site_id=${site_id}`, config)
            .then(res => {
                const forklifters = res.data.data
                setForklifters(forklifters)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('forklift_drivers')
                    })
                )
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getUsers = async (company_id) => {
        setIsLoading(true)

        await axios.get(`/api/users`, config)
            .then(res => {
                const users = res.data.data
                const filteredUsers = (user?.role != 'master_admin')
                    ? users.filter((user) => (user.role_slug != 'master_admin') && (Number(user.company_id) == Number(company_id)))
                    : users
                setUsers(filteredUsers)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('users')
                    })
                )
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getProductionTypes = async (company_id) => {
        setIsLoading(true)

        await axios.get(`/api/production-types?with=items&company_id=${company_id}`, config)
            .then(res => {
                const productionTypes = res.data
                setProductionTypes(productionTypes)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('production_types')
                    })
                )
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getProductionEmployees = async (site_id) => {
        setIsLoading(true)

        await axios.get(`/api/production-employees?site=${site_id}`, config)
            .then(res => {
                const productionEmployees = res.data
                setProductionEmployees(productionEmployees)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('production_employees')
                    })
                )
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            {isLoading ? <Loading/> : ''}

            <SnackbarProvider
                maxSnack={3}
                /* preventDuplicate */
                autoHideDuration={12000}
                variant='error'
                action={(snackbarId) => (
                    <button onClick={() => closeSnackbar(snackbarId)}>
                        <ClearIcon />
                    </button>
                )}
            />
            <AppLayout>
                <div className='p-5 flex flex-row flex-wrap'>

                    {/* Collection Reports */}
                    <div className='p-3 basis-full xl:basis-1/4 lg:basis-1/3 md:basis-1/2'>
                        <div className='bg-white mb-2 rounded-md'>
                            <div className='p-5 flex gap-3 justify-between items-center'>
                                <p className='font-bold roboto color-fake'>{t('x_reports', {x: t('collection')})}</p>
                                <span style={{ color: '#b7472a', fontSize: '22px', transform: 'rotate(225deg)' }} className="flex items-center justify-center">
                                    <i className="fa-solid fa-arrow-up-long"></i>
                                </span>
                            </div>

                            <Divider />

                            <List aria-label='collection reports'>
                                {/* Completed Collections By Vendor */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => {
                                        handleOpen(REPORT_CODES.collections.COMPLETED_COLLECTIONS_BY_VENDOR);
                                        getVendors(localStorage.getItem('company_id'));
                                    }}
                                    disabled={isLoading}
                                >
                                    {t('collection_reports.ccbv')}
                                </CustomListItemButton>

                                {/* On-Site Collections Awaiting Unload */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => handleOpen(REPORT_CODES.collections.ONSITE_COLLECTIONS_AWAITING_UNLOAD)}
                                    disabled={isLoading}
                                >
                                    {t('collection_reports.oscau')}
                                </CustomListItemButton>

                                {/* On Time Collections */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => {
                                        handleOpen(REPORT_CODES.collections.ONTIME_COLLECTIONS);
                                        getVendors(localStorage.getItem('company_id'));
                                    }}
                                    disabled={isLoading}
                                >
                                    {t('collection_reports.otc')}
                                </CustomListItemButton>

                                {/* Site Collections Summary */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => handleOpen(REPORT_CODES.collections.SITE_COLLECTION_SUMMARY)}
                                    disabled={isLoading}
                                >
                                    {t('collection_reports.scs')}
                                </CustomListItemButton>
                            </List>
                        </div>
                    </div>

                    {/* Shipment Reports */}
                    <div className='p-3 basis-full xl:basis-1/4 lg:basis-1/3 md:basis-1/2'>
                        <div className='bg-white mb-2 rounded-md'>
                            <div className='p-5 flex gap-3 justify-between items-center'>
                                <p className='font-bold roboto color-fake'>{t('x_reports', {x: t('shipment')})}</p>
                                <span style={{ transform: 'rotate(45deg)', color: '#336195', fontSize: '22px' }} className="flex items-center justify-center">
                                    <i className="fa-solid fa-arrow-up-long"></i>
                                </span>
                            </div>

                            <Divider />

                            <List aria-label='shipment reports'>
                                {/* Completed Shipments */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => handleOpen(REPORT_CODES.shipments.COMPLETED_SHIPMENTS)}
                                    disabled={isLoading}
                                >
                                    {t('shipment_reports.cs')}
                                </CustomListItemButton>

                                {/* Loaded Outbound */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => handleOpen(REPORT_CODES.shipments.LOADED_OUTBOUND)}
                                    disabled={isLoading}
                                >
                                    {t('shipment_reports.lo')}
                                </CustomListItemButton>

                                {/* On Time Shipments */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => {
                                        handleOpen(REPORT_CODES.shipments.ONTIME_DELIVERIES);
                                        getCustomers(localStorage.getItem('company_id'));
                                    }}
                                    disabled={isLoading}
                                >
                                    {t('shipment_reports.otd')}
                                </CustomListItemButton>

                                {/* Site Shipments Summary (prev. known as Open Shipments) */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => handleOpen(REPORT_CODES.shipments.SITE_SHIPMENT_SUMMARY)}
                                    disabled={isLoading}
                                >
                                    {t('shipment_reports.sss')}
                                </CustomListItemButton>
                            </List>
                        </div>
                    </div>

                    {/* Activity Reports */}
                    <div className='p-3 basis-full xl:basis-1/4 lg:basis-1/3 md:basis-1/2'>
                        <div className='bg-white mb-2 rounded-md'>
                            <div className='p-5 flex gap-3 justify-between items-center'>
                                <p className='font-bold roboto color-fake'>{t('x_reports', {x: t('activity')})}</p>
                                <span style={{ color: '#4caf50', fontSize: '22px' }} className="flex items-center justify-center">
                                    <i className="fa-solid fa-chart-line"></i>
                                </span>
                            </div>

                            <Divider />

                            <List aria-label='activity reports'>
                                {/* Driver Activity */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => {
                                        handleOpen(REPORT_CODES.activities.DRIVER_ACTIVITY_REPORT);
                                        getDrivers(localStorage.getItem('site'));
                                    }}
                                    disabled={isLoading}
                                >
                                    {t('activity_reports.dar')}
                                </CustomListItemButton>

                                {/* Drivers Productivity */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => handleOpen(REPORT_CODES.activities.DRIVER_PRODUCTIVITY_REPORT)}
                                    disabled={isLoading}
                                >
                                    {t('activity_reports.dpr')}
                                </CustomListItemButton>

                                {/* Forklift Driver Activity */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => {
                                        handleOpen(REPORT_CODES.activities.FORKLIFT_DRIVER_ACTIVITY_REPORT);
                                        getForklifters(localStorage.getItem('site'));
                                    }}
                                    disabled={isLoading}
                                >
                                    {t('activity_reports.fdar')}
                                </CustomListItemButton>

                                {/* Forklift Drivers Productivity */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => handleOpen(REPORT_CODES.activities.FORKLIFT_DRIVER_PRODUCTIVITY_REPORT)}
                                    disabled={isLoading}
                                >
                                    {t('activity_reports.fdpr')}
                                </CustomListItemButton>

                                {/* Trailer History Summary By Vendor */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => {
                                        handleOpen(REPORT_CODES.activities.TRAILER_HISTORY_SUMMARY_BY_VENDOR);
                                        getVendors(localStorage.getItem('company_id'));
                                    }}
                                    disabled={isLoading}
                                >
                                    {t('activity_reports.thsbv')}
                                </CustomListItemButton>

                                {/* Trailer History Summary By Customer */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => {
                                        handleOpen(REPORT_CODES.activities.TRAILER_HISTORY_SUMMARY_BY_CUSTOMER);
                                        getCustomers(localStorage.getItem('company_id'));
                                    }}
                                    disabled={isLoading}
                                >
                                    {t('activity_reports.thsbc')}
                                </CustomListItemButton>

                                {/* System Usage By User (prev. known as Portal Usage By User) */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => {
                                        handleOpen(REPORT_CODES.activities.SYSTEM_USAGE_BY_USER);
                                        getUsers(localStorage.getItem('company_id'));
                                    }}
                                    disabled={isLoading}
                                >
                                    {t('activity_reports.subu')}
                                </CustomListItemButton>
                            </List>
                        </div>
                    </div>

                    {/* Production Reports */}
                    <div className='p-3 basis-full xl:basis-1/4 lg:basis-1/3 md:basis-1/2'>
                        <div className='bg-white mb-2 rounded-md'>
                            <div className='p-5 flex gap-3 justify-between items-center'>
                                <p className='font-bold roboto color-fake'>{t('x_reports', {x: t('production')})}</p>
                                <span style={{ color: '#795548', fontSize: '22px' }} className="flex items-center justify-center">
                                    <i className="fa-solid fa-hammer"></i>
                                </span>
                            </div>

                            <Divider />

                            <List aria-label='productivity reports'>
                                {/* Production Summary */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => handleOpen(REPORT_CODES.production.PRODUCTION_SUMMARY)}
                                    disabled={isLoading}
                                >
                                    {t('production_reports.ps')}
                                </CustomListItemButton>

                                {/* Production Activity By Day */}
                                <CustomListItemButton
                                    component="a"
                                    onClick={() => {
                                        handleOpen(REPORT_CODES.production.PRODUCTION_ACTIVITY_BY_DAY);
                                        getProductionTypes(localStorage.getItem('company_id'));
                                    }}
                                    disabled={isLoading}
                                >
                                    {t('production_reports.pabd')}
                                </CustomListItemButton>

                                <CustomListItemButton
                                    component="a"
                                    onClick={() => {
                                        handleOpen(REPORT_CODES.production.PRODUCTION_EMPLOYEE_ACTIVITY_BY_DAY);
                                        getProductionEmployees(localStorage.getItem('site'));
                                    }}
                                    disabled={isLoading}
                                >
                                    {t('production_reports.peabd')}
                                </CustomListItemButton>

                                <CustomListItemButton
                                    component="a"
                                    onClick={() => handleOpen(REPORT_CODES.production.PRODUCTION_SUMMARY_REPORT_BY_PRODUCTION_EMPLOYEE)}
                                    disabled={isLoading}
                                >
                                    {t('production_reports.psrbpe')}
                                </CustomListItemButton>
                            </List>
                        </div>
                    </div>
                </div>
            </AppLayout>

            <ReportModal
                open={open}
                setOpen={setOpen}
                reportCode={reportCode}
                companyVendors={vendors}
                companyCustomers={customers}
                companyDrivers={drivers}
                companyForklifters={forklifters}
                companyUsers={users}
                companySites={allSites}
                companyProductionTypes={productionTypes}
                companyProductionEmployees={productionEmployees}
                onClose={handleClose}
                setIsLoading={setIsLoading}
            />
        </>
    )
}

export default Reports

