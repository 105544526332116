import { Modal, Box } from "@mui/material"

const UnevenQuantitiesModal = ({ open, setOpen, continueHandler, t }) => {
    const handleToggleModal = () => {
        setOpen(!open)
    }

    const handleClickOK = (e) => {
        setOpen(false)
    }

    /* const handleClickContinue = (e) => {
        continueHandler(e, true)
        setOpen(false)
    } */

    return (
        <Modal open={open} onClose={handleToggleModal}>
            <Box sx={customModalStyle}>
                <div className='flex justify-between items-center p-5 w-full' style={{ backgroundColor: '#ff0000', borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-baseline justify-between w-full'>
                        <p className='text-[18px] font-[600] text-white'>{t('warning')}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-white">
                            <button onClick={handleToggleModal}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='p-5'>
                        <form className='flex justify-center items-start flex-col gap-4 pt-1 pb-4'>
                            <p className="text-[14px] text-[#454e5c]">{t('ordered_quantities_not_equal_warning__alt')}</p>
                            {/* <small className="text-[#454e5c] italic">{t('ordered_quantities_not_equal_warning_subtext')}</small> */}
                        </form>
                        <div className='w-full flex gap-2 justify-between'>
                            <button type="button" onClick={handleClickOK} className='w-full text-[14px] text-white rounded-sm px-4 py-2 bg-[#ff0000] hover:bg-[#c91e1e] uppercase'>
                                {t('ok')}
                            </button>
                            {/* <button type="button" onClick={handleClickContinue} className='w-full text-[14px] text-white rounded-sm px-4 py-2 bg-[#049238] hover:bg-[#006425] uppercase'>
                                {t('continue')}
                            </button> */}
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default UnevenQuantitiesModal

const customModalStyle = {
    minWidth: "360px",
    maxWidth: "540px",
    width: "50%",
    height: "auto",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#ffffff',
    border: 'transparent',
    borderRadius: '5px',
    boxShadow: 24,
    zIndex: "1600",
    outline: 'none'
}
