import React, { useEffect, useState } from 'react'

import { FormControl, InputLabel, Select, MenuItem, ListItemText, Checkbox, ListItemIcon, Box, Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import { handleDeleteSelectionPrimitive } from '../../helpers/helper'

const Filters = ({ user, years, months, shipToLocations, year, setYear, shipToLocation, setShipToLocation, month, setMonth, setMonthId, setShipToLocationId }) => {

    const { t } = useTranslation()
    const isAllSelected = years.length > 0 && year.length === years.length;
    const isAllSelectedMonth = months.length > 0 && month.length === months.length;
    const isAllSelectedShipTo = shipToLocations.length > 0 && shipToLocation.length === shipToLocations.length;

    const [openShipToLocations, setOpenShipToLocations] = useState(false)
    const [openYears, setOpenYears] = useState(false)
    const [openMonths, setOpenMonths] = useState(false)

    const handleYear = (event) => {
        const { target: { value } } = event
        if (value[value.length - 1] === "all") {
            setYear(year.length === years.length ? [] : [dayjs().format('YYYY') - 0, dayjs().format('YYYY') - 1, dayjs().format('YYYY') - 2]);
            return;
        }
        setYear(
            typeof value === "string" ? value.split(",") : value
        )
    }

    const handleClearYear = () => {
        setYear([])
    }

    const handleShipToLocation = (event) => {
        const { target: { value } } = event

        let duplicateRemoved = [];

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
                duplicateRemoved.push(item);
            }
        })

        let ids = []

        value.forEach((item) => {
            if (ids.findIndex((o) => o.id === item.id) >= 0) {
                ids = ids.filter((x) => x.id === item.id);
            } else {
                ids.push(item.id);
            }
        })

        if (value[value.length - 1] === "all") {
            setShipToLocation(shipToLocation.length === shipToLocations.length ? [] : shipToLocations);
            setShipToLocationId(shipToLocation.length === shipToLocations.length ? [] : shipToLocations.map(i => i.id))
            return;
        }
        setShipToLocation(duplicateRemoved)
        setShipToLocationId(ids)
    }

    const handleClearShipTo = () => {
        setShipToLocation([])
        setShipToLocationId([])
    }



    const handleMonth = (event) => {
        const { target: { value } } = event

        let duplicateRemoved = [];

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
                duplicateRemoved.push(item);
            }
        })

        let monthId = []

        value.forEach((item) => {
            if (monthId.findIndex((o) => o.id === item.id) >= 0) {
                monthId = monthId.filter((x) => x.id === item.id);
            } else {
                monthId.push(item.id);
            }
        })

        if (value[value.length - 1] === "all") {
            setMonth(month.length === months.length ? [] : months);
            setMonthId(month.length === months.length ? [] : months.map(i => i.id))
            return;
        }
        setMonth(duplicateRemoved)
        setMonthId(monthId)
    }

    const handleClearMonth = () => {
        setMonth([])
        setMonthId([])
    }

    const handleDeleteMonth = (e, id) => {
        const shouldDelete = month.find((x) => x.id === id)
        let monthId = []
        if (shouldDelete) {
            const filtered = month.filter((x) => x.id !== shouldDelete.id)
            if (filtered.length > 0) {
                filtered.forEach((m) => {
                    if (m.hasOwnProperty('id')) monthId.push(m.id)
                })
                setMonth(filtered)
                setMonthId(monthId)
            } else {
                setMonth([])
                setMonthId([])
                setOpenMonths(false)
            }
        }
    }

    const handleDeleteShipToLocation = (e, id) => {
        const shouldDelete = shipToLocation.find((x) => x.id === id)
        let shipToLocationId = []
        if (shouldDelete) {
            const filtered = shipToLocation.filter((x) => x.id !== shouldDelete.id)
            if (filtered.length > 0) {
                filtered.forEach((stl) => {
                    if (stl.hasOwnProperty('id')) shipToLocationId.push(stl.id)
                })
                setShipToLocation(filtered)
                setShipToLocationId(shipToLocationId)
            } else {
                setShipToLocation([])
                setShipToLocationId([])
                setOpenShipToLocations(false)
            }
        }
    }

    return (
        <div className='p-5'>
            <div className='flex justify-between gap-5'>
                <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }}>
                    <InputLabel>{t('customer')}</InputLabel>
                    <Select value={user.id} disabled>
                        <MenuItem value={user.id}>{user.name}</MenuItem>
                    </Select>
                </FormControl>


                <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }} focused={openYears}>
                    <InputLabel>{t('year')}</InputLabel>
                    <Select
                        multiple
                        defaultValue={year}
                        value={year}
                        open={openYears}
                        onOpen={() => setOpenYears(true)}
                        onClose={() => setOpenYears(false)}
                        onChange={handleYear}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((x) => (
                                    <Chip
                                        key={`y_option-${x}`}
                                        label={x}
                                        size="small"
                                        onMouseDown={(e) => e.stopPropagation()}
                                        onDelete={(e) => handleDeleteSelectionPrimitive(e, x, year, setYear, setOpenYears)}
                                    />
                                ))}
                            </Box>
                        )}
                        sx={{ ".MuiSelect-iconStandard": { display: (year.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' } }}
                        endAdornment={year ? (<IconButton sx={{ visibility: (year.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearYear}><ClearIcon /></IconButton>) : false}
                    >
                        {
                            years?.length > 0 ?
                                <MenuItem value="all">
                                    <ListItemIcon>
                                        <Checkbox checked={isAllSelected} indeterminate={year.length > 0 && year.length < years.length} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('select_all')} />
                                </MenuItem>
                                :
                                <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                        }
                        {
                            years.map((item) =>
                                <MenuItem value={item.id} key={item.id}>
                                    <Checkbox checked={year.indexOf(item.id) > -1} />
                                    <ListItemText primary={item.year} />
                                </MenuItem>

                            )
                        }
                    </Select>
                </FormControl>
            </div>
            <div className='flex justify-between gap-5'>
                <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }} focused={openShipToLocations}>
                    <InputLabel>{t('ship_to')}</InputLabel>
                    <Select
                        multiple
                        value={shipToLocation}
                        open={openShipToLocations}
                        onOpen={() => setOpenShipToLocations(true)}
                        onClose={() => setOpenShipToLocations(false)}
                        onChange={handleShipToLocation}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((x) => (
                                    <Chip
                                        key={`sta_option-${x.id}`}
                                        label={x.name}
                                        size="small"
                                        onMouseDown={(e) => e.stopPropagation()}
                                        onDelete={(e) => handleDeleteShipToLocation(e, x.id)}
                                    />
                                ))}
                            </Box>
                        )}
                        sx={{ ".MuiSelect-iconStandard": { display: (shipToLocation.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' } }}
                        endAdornment={shipToLocation ? (<IconButton sx={{ visibility: (shipToLocation.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearShipTo}><ClearIcon /></IconButton>) : false}
                    >

                        {
                            shipToLocations?.length > 0 ?
                                <MenuItem value="all">
                                    <ListItemIcon>
                                        <Checkbox checked={isAllSelectedShipTo} indeterminate={shipToLocation.length > 0 && shipToLocation.length < shipToLocations.length} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('select_all')} />
                                </MenuItem>
                                :
                                <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                        }
                        {
                            shipToLocations.map((item) =>
                                <MenuItem value={item} key={item.id}>
                                    <Checkbox checked={shipToLocation.findIndex((i) => i.id === item.id) >= 0} />
                                    <ListItemText primary={item.name} />
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>


                <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }} focused={openMonths}>
                    <InputLabel>{t('month')}</InputLabel>
                    <Select
                        multiple
                        value={month}
                        open={openMonths}
                        onOpen={() => setOpenMonths(true)}
                        onClose={() => setOpenMonths(false)}
                        onChange={handleMonth}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((x) => (
                                    <Chip
                                        key={`m_option-${x.id}`}
                                        label={x.name}
                                        size="small"
                                        onMouseDown={(e) => e.stopPropagation()}
                                        onDelete={(e) => handleDeleteMonth(e, x.id)}
                                    />
                                ))}
                            </Box>
                        )}
                        sx={{ ".MuiSelect-iconStandard": { display: (month.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' } }}
                        endAdornment={month ? (<IconButton sx={{ visibility: (month.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearMonth}><ClearIcon /></IconButton>) : false}
                    >
                        {
                            months?.length > 0 ?
                                <MenuItem value="all">
                                    <ListItemIcon>
                                        <Checkbox checked={isAllSelectedMonth} indeterminate={month.length > 0 && month.length < months.length} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('select_all')} />
                                </MenuItem>
                                :
                                <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                        }
                        {
                            months.map((item) =>
                                <MenuItem value={item} key={item.id}>
                                    <Checkbox checked={month.findIndex((i) => i.id === item.id) >= 0} />
                                    <ListItemText primary={item.name} />
                                </MenuItem>)
                        }
                    </Select>
                </FormControl>
            </div>
        </div>
    )
}

export default Filters
