import AppLayout from '../components/Layouts/AppLayout'

const Dashboard = () => {
    return (
        <AppLayout>
            Welcome, honoured guest!
        </AppLayout>
    )
}

export default Dashboard
