import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
import { includes } from "lodash"
import GuestLayout from '../components/Layouts/GuestLayout'
import AuthCard from '../components/AuthCard'
import Wrapper from '../components/Wrapper'
import { useAuth } from '../hooks/auth'
import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Loading from '../components/Loading'

const PortalCard = ({user, label, portalRole, setIsLoading}) => {
    const { switchUser } = useAuth()
    const navigate = useNavigate()

    const { role, counterpart } = user

    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState(null)

    const handleClickCard = () => {
        if(role === portalRole) {
            navigate('/', {
                replace: true
            })
        } else {
            setIsLoading(true)

            switchUser({
                counterpartId: counterpart?.id,
                counterpartRole: counterpart?.role,
                setErrors,
                setStatus
            })
            .finally(() => {
                setIsLoading(false)
            })
        }
    }

    return (
        <Card
            onClick={handleClickCard}
            sx={{ flexBasis: '100%' }}
            className={`!transition-all !duration-300 !ease-in-out text-center hover:cursor-pointer hover:bg-[#dba34b] hover:text-[#ffffff]`}
        >
            <CardContent className='!pb-[16px]'>
                <Typography variant='h5'>
                    {label}
                </Typography>
            </CardContent>
        </Card>
    )
}

const ChoosePortal = () => {
    const { t } = useTranslation()
    const { user, logout } = useAuth()

    const [isLoading, setIsLoading] = useState(false)

    const renderPortalCards = () => {
        if(includes(['customers_user', 'vendor_user'], user?.role)) {
            return (
                <div className='flex flex-col md:flex-row gap-2'>
                    <PortalCard user={user} label={t('customer')} portalRole="customers_user" setIsLoading={setIsLoading} />
                    <PortalCard user={user} label={t('vendor')} portalRole="vendor_user" setIsLoading={setIsLoading} />
                </div>
            )
        }

        return null
    }

    return (
        <>
            {isLoading && <Loading />}
            <GuestLayout>
                <AuthCard>
                    <Wrapper>
                        <div className='min-w-[500px] max-[500px]:min-w-[300px]'>
                            <div className='w-full'>
                                <p className='text-[#718096] text-[18px] mb-3'>{t('choose_portal')}</p>
                                {renderPortalCards()}
                            </div>
                            <div className='flex justify-start w-full py-5'>
                                <p className="text-[14px] text-[#BA8431] cursor-pointer" onClick={() => logout()}>
                                    {t('back_to_login')}
                                </p>
                            </div>
                        </div>
                    </Wrapper>
                </AuthCard>
            </GuestLayout>
        </>
    )
}

export default ChoosePortal
