import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';
import style from '../styles/style';

const Loading = ({width = "100%", height = "100%", position = "fixed", overflow = 'scroll', progressConfig = [], withLabel = false}) => {
  return (
    <div style={{...style, position: position, width: width, height: height, overflow: overflow}} className='flex justify-center items-center whole'>
        {/* <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}

        {withLabel ? (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress size={80} variant={progressConfig?.variant ?? 'indeterminate'} value={progressConfig?.value ?? 0} />

                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                }}
                >
                    <Typography variant="caption" component="div" color='#ffffff' fontWeight='500' fontSize='16px'>
                        {`${Math.round(progressConfig?.value ?? 0)}%`}
                    </Typography>
                </Box>
            </Box>
        ) : (
            <CircularProgress size={80} variant={progressConfig?.variant ?? 'indeterminate'} value={progressConfig?.value ?? 0} />
        )}
    </div>
  )
}

export default Loading

