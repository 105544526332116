import React, {useEffect, useState, useRef } from 'react'

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import {FormControl, FormLabel, MenuItem, Select, TextField, InputLabel, ListItemIcon, ListItemText, Checkbox, Box, Chip, Typography, Divider, Stack } from '@mui/material'

import TableShared from '../../components/TableShared'
import axios from '../../lib/axios';
import AppLayout from '../../components/Layouts/AppLayout'
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import AddModal from '../../components/production/AddModal';
import { useStateContext } from '../../context/ContextProvider';
import Swal from 'sweetalert2'
import { styled } from '@mui/material/styles'
import { useAuth } from '../../hooks/auth';
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton"
import {isEqual, isEmpty, last, includes, cloneDeep, isNil } from "lodash"
import Tooltip from "@mui/material/Tooltip";
import AddButton from '../../components/AddButton';
import { areAllValid, isValid } from '../../helpers/helper';

const CustomTextField = styled(TextField)({
    '& .MuiInputBase-input': {
        padding: "6px !important",
        textAlign: "center !important"
    }
})

const CellMimic = styled(Box)({
    position: 'absolute',
    color: '#88909C',
    backgroundColor: 'white',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'flex',
    paddingLeft: '20px',
    paddingRight: '20px',
    zIndex: '100',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
})

const EnterProductionData = () => {
    const { user } = useAuth({ middleware: "guest" })

    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation()
    const { config, choosesite, setChoosesite, pusher } = useStateContext()

    const [disabled, setDisabled] = useState(false)

    const [productionDataId, setProductionDataId] = useState(0)
    const [productionDate, setProductionDate] = useState(dayjs())
    const [shift, setShift] = useState(1)
    const [productionType, setProductionType] = useState('')
    const [openModalEmployees, setOpenModalEmployees] = useState(false)
    const [openModalItems, setOpenModalItems] = useState(false)
    const [openFilterEmployees, setOpenFilterEmployees] = useState(false)
    const [openFilterItems, setOpenFilterItems] = useState(false)
    const [showFilterRow, setShowFilterRow] = useState(false)

    const [employeesList, setEmployeesList] = useState([])
    const [itemsList, setItemsList] = useState([])

    const [items, setItems] = useState([])
    const [itemFilterOptions, setItemFilterOptions] = useState([])
    const [filteredItems, setFilteredItems] = useState([])
    const [employees, setEmployees] = useState([])
    const [employeeFilterOptions, setEmployeeFilterOptions] = useState([])
    const [filteredEmployees, setFilteredEmployees] = useState([])
    const [types, setTypes] = useState([])

    const [isUpdate, setIsUpdate] = useState(false)
    const [isSaved, setIsSaved] = useState(false)

    const [sortModel, setSortModel] = useState([
        {
            field: 'employee_name',
            sort: 'asc'
        }
    ])

    const [isHoveringOverEmployeeCell, setIsHoveringOverEmployeeCell] = useState(false)
    const [isHoveringOverTotalCell, setIsHoveringOverTotalCell] = useState(false)

    const FIRST_COLUMN = {
        field: 'employee_name',
        headerName: t('employee'),
        flex: 1,
        minWidth: 175,
        headerClassName: 'disable-highlight',
        cellClassName: 'pinned-left',
        sortable: false
    }

    const LAST_COLUMN = {
        field: 'total',
        headerName: t('total'),
        flex: 1,
        minWidth: 175,
        align: 'center',
        headerAlign: 'center',
        renderCell: ((params) => <HandleTotal params={params} />),
        cellClassName: 'pinned-right',
        valueGetter: ((params) => {
            const sessionData = JSON.parse(sessionStorage.getItem('filteredData'))
            if (!isEmpty(sessionData)) {
                const cells = sessionData.filter((cell) => cell.row_id === params.id)
                let sum = 0
                cells.forEach((cell) => {
                    sum += Number(cell.item_qty)
                })
                return sum
            } else {
                return 0
            }
        }),
        sortComparator: (v1, v2) => parseInt(v1) - parseInt(v2)
    }

    const [columns, setColumns] = useState([FIRST_COLUMN, LAST_COLUMN])
    const [filteredColumns, setFilteredColumns] = useState([])
    const [rows, setRows] = useState([])
    const [filteredRows, setFilteredRows] = useState([])

    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    const [openShifts, setOpenShifts] = useState(false)
    const [openTypes, setOpenTypes] = useState(false)

    const [datepickerDate, setDatepickerDate] = useState(dayjs())

    const [initialUpdateData, setInitialUpdateData] = useState([])

    const isAllEmployeesFiltered = employeeFilterOptions.length > 0 && filteredEmployees.length === employeeFilterOptions.length;
    const isAllItemsFiltered = itemFilterOptions.length > 0 && filteredItems.length === itemFilterOptions.length;

    const datagridBoxRef = useRef(null)

    const [cellMimicWidth, setCellMimicWidth] = useState(175)
    const [cellMimicHeight, setCellMimicHeight] = useState(56)

    useEffect(() => {
        setChoosesite(localStorage.getItem('site'))
    }, [])

    useEffect(() => {
        if (isValid(choosesite)) {
            getTypes()
        }
    }, [choosesite])

    useEffect(() => {
        if(areAllValid(choosesite, productionType)) {
            const channelProductionItemUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-productionitem-updated-company-${localStorage.getItem('company_id')}`)
            const channelProductionEmployeeDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-productionemployee-deleted`)

            channelProductionItemUpdated.bind(`${localStorage.getItem('client_id')}-productionitem-updated-event`, data => {
                if(Number(data?.company_id) == Number(localStorage.getItem('company_id'))) {
                    Swal.fire({
                        text: t('production_item_updated_message'),
                        icon: "info",
                        customClass: 'info',
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                        iconColor: '#a8d5f1'
                    })
                    setup(choosesite, productionType)
                }
            })

            channelProductionEmployeeDeleted.bind(`${localStorage.getItem('client_id')}-productionemployee-deleted-event`, data => {
                if(Number(data?.site_id) == Number(choosesite)) {
                    Swal.fire({
                        text: t('production_employee_deleted_message'),
                        icon: "info",
                        customClass: 'info',
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                        iconColor: '#a8d5f1'
                    })
                    setup(choosesite, productionType)
                }
            })
        }

        return (() => {
            if(areAllValid(choosesite, productionType)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionitem-updated-company-${localStorage.getItem('company_id')}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionemployee-deleted`)
            }
        })
    }, [choosesite, productionType])

    useEffect(() => {
        if (isValid(choosesite) && isValid(productionType)) {
            setup(choosesite, productionType)
        }
    }, [choosesite, productionType, shift, productionDate])

    useEffect(() => {
        if(areAllValid(productionDate, shift, productionType)) {
            const productionDataCreatedChannelName = `${localStorage.getItem('client_id')}-productionentry-created-site-${choosesite}-date-${dayjs(productionDate).format('YYYYMMDD')}-shift-${shift}-id-${productionType}`
            const productionDataUpdatedChannelName = `${localStorage.getItem('client_id')}-productionentry-updated-site-${choosesite}-date-${dayjs(productionDate).format('YYYYMMDD')}-shift-${shift}-id-${productionType}`
            const channelProductionDataCreated = pusher.subscribe(productionDataCreatedChannelName)
            const channelProductionDataUpdated = pusher.subscribe(productionDataUpdatedChannelName)

            channelProductionDataCreated.bind(`${localStorage.getItem('client_id')}-productionentry-created-event`, data => {
                if(Number(data?.created_by) != Number(user?.id)) {
                    setup(choosesite, productionType)
                }
            })

            channelProductionDataUpdated.bind(`${localStorage.getItem('client_id')}-productionentry-updated-event`, data => {
                if(Number(data?.updated_by) != Number(user?.id)) {
                    setup(choosesite, productionType)
                }
            })
        }

        return (() => {
            if(areAllValid(productionDate, shift, productionType)) {
                const productionDataCreatedChannelName = `${localStorage.getItem('client_id')}-productionentry-created-site-${choosesite}-date-${dayjs(productionDate).format('YYYYMMDD')}-shift-${shift}-id-${productionType}`
                const productionDataUpdatedChannelName = `${localStorage.getItem('client_id')}-productionentry-updated-site-${choosesite}-date-${dayjs(productionDate).format('YYYYMMDD')}-shift-${shift}-id-${productionType}`
                pusher.unsubscribe(productionDataCreatedChannelName)
                pusher.unsubscribe(productionDataUpdatedChannelName)
            }
        })
    }, [choosesite, productionDate, shift, productionType])

    useEffect(() => {
        if(isSaved) {
            setup(choosesite, productionType)
        }
    }, [isSaved])

    useEffect(() => {
        if (types.length > 0) {
            setProductionType(types[0]?.id)
        }
    }, [types])

    useEffect(() => {
        setColumns(() => {
            const columns = [FIRST_COLUMN, LAST_COLUMN]
            items.forEach(i => {
                columns.splice(1, 0, {
                    id: i.id,
                    field: i.item_no,
                    headerName: i.item_no,
                    flex: 1,
                    minWidth: 175,
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: ((params) => <HandleQty params={params} handler={handleQtyChange} disabled={disabled} />),
                    valueGetter: ((params) => {
                        const sessionData = JSON.parse(sessionStorage.getItem('filteredData'))

                        if (!isEmpty(sessionData)) {
                            const cellIndex = sessionData.findIndex((dataCell) => dataCell.row_id === params.id && dataCell.item_no === params.field)
                            if(cellIndex > -1) {
                                const cell = sessionData.find((dataCell) => dataCell.row_id === params.id && dataCell.item_no === params.field)
                                if(cell) {
                                    return cell.item_qty
                                } else {
                                    return 0
                                }
                            }
                        }

                        return 0
                    }),
                    sortComparator: (v1, v2) => parseInt(v1) - parseInt(v2)
                })
            })
            return columns
        })
    }, [items])

    useEffect(() => {
        setRows(() => {
            const rows = []
            employees.forEach(e => {
                rows.push({
                    id: `pd${productionDataId}eid${e.id}`,
                    employee_id: e.id,
                    employee_name: e.name,
                })
            })
            return rows
        })
    }, [employees])

    useEffect(() => {
        setData((prev) => {
            const data = [...prev]
            const employeeIds = employees.flatMap((employee) => employee.id)
            const itemCodes = items.flatMap((item) => item.id)

            setEmployeeFilterOptions(employees)
            setItemFilterOptions(items)

            employees.forEach((employee) => {
                items.forEach((item) => {
                    if (!data.find((cell) => cell.employee_id === employee.id && cell.item_code === item.id)) {
                        data.push({
                            cell_id: Math.floor(Math.random() * 999999),
                            row_id: `pd${productionDataId}eid${employee.id}`,
                            employee_id: employee.id,
                            item_code: item.id,
                            item_no: item.item_no,
                            item_qty: 0
                        })
                    }
                })
            })
            // This'll filter out any removed item/employee cells (theoretically)
            const filDeets = data.filter((cell) => employeeIds.includes(cell.employee_id) && itemCodes.includes(cell.item_code))
            sessionStorage.setItem('data', JSON.stringify(filDeets))
            return filDeets
        })
    }, [items, employees])

    useEffect(() => {
        resetFilters()
    }, [employeeFilterOptions, itemFilterOptions])

    const resetFilters = () => {
        setFilteredEmployees([])
        setFilteredItems([])
        setFilteredRows([])
        setFilteredColumns([])
        if (employeeFilterOptions) {
            if (employeeFilterOptions.length < 1) {
                setShowFilterRow(false)
            }
        } else if (itemFilterOptions) {
            if (itemFilterOptions.length < 1) {
                setShowFilterRow(false)
            }
        } else {
            setShowFilterRow(false)
        }
    }

    useEffect(() => {
        setFilteredData(data)
    }, [itemFilterOptions])

    useEffect(() => {
        setFilteredData(getFilteredData())
    }, [filteredEmployees, filteredItems])

    const setup = async (selectedSite, selectedType) => {
        setIsLoading(true)
        resetFilters()
        setColumns([FIRST_COLUMN, LAST_COLUMN])
        setRows([])

        // List of all production employees of this production type belonging to the chosen site
        const e = []
        await axios
            .get(`/api/production-employees?with=production_types&production_types=${selectedType}&site=${selectedSite}`, config)
            .then((res) => {
                const productionEmployees = res.data
                productionEmployees.forEach(employee => {
                    e.push(employee)
                });
                setEmployeesList(productionEmployees)
            })
            .catch(({ response }) => {
                console.log(response)
            })

        // List of all production items of this production type
        const i = []
        await axios
            .get(`api/items?has=production_type&production_type_id=${selectedType}&company_id=${localStorage.getItem('company_id')}`, config)
            .then((res) => {
                const productionItems = res.data
                productionItems.forEach(item => {
                    i.push(item)
                });
                setItemsList(productionItems)
            })
            .catch(({ response }) => {
                console.log(response)
            })

        getProductionData(e, i)
    }

    const getFilteredData = () => {
        const filteredEmployeeIds = filteredEmployees?.map((emp) => emp.id)
        const filteredItemIds = filteredItems?.map((item) => item.id)
        let filteredData = []

        if (!isEmpty(filteredEmployees) && isEmpty(filteredItems)) {
            const filRows = rows?.filter((row) => includes(filteredEmployeeIds, row.employee_id))
            setFilteredRows(filRows)
            const filRowIds = filRows?.map((row) => row.employee_id)
            const filCells = data?.filter((cell) => includes(filRowIds, cell.employee_id))
            setFilteredColumns([FIRST_COLUMN, ...columns.slice(1, -1), LAST_COLUMN])
            filteredData = cloneDeep(filCells)
        } else if (isEmpty(filteredEmployees) && !isEmpty(filteredItems)) {
            const filCols = columns?.filter((col) => includes(filteredItemIds, col.id))
            setFilteredColumns([FIRST_COLUMN, ...filCols, LAST_COLUMN])
            const filColIds = filCols?.map((col) => col.id)
            const filCells = data?.filter((cell) => includes(filColIds, cell.item_code))
            setFilteredRows(...rows)
            filteredData = cloneDeep(filCells)
        } else if (!isEmpty(filteredEmployees) && !isEmpty(filteredItems)) {
            const filRows = rows?.filter((row) => includes(filteredEmployeeIds, row.employee_id))
            setFilteredRows(filRows)
            const filCols = columns?.filter((col) => includes(filteredItemIds, col.id))
            setFilteredColumns([FIRST_COLUMN, ...filCols, LAST_COLUMN])
            const filRowIds = filRows?.map((row) => row.employee_id)
            const filColIds = filCols?.map((col) => col.id)
            const filCells = data?.filter((cell) => (includes(filRowIds, cell.employee_id)) && (includes(filColIds, cell.item_code)))
            filteredData = cloneDeep(filCells)
        } else {
            setFilteredRows([])
            setFilteredColumns([])
            filteredData = cloneDeep(data)
        }

        sessionStorage.setItem('filteredData', JSON.stringify(filteredData))
        return filteredData
    }

    useEffect(() => {
        if (!isEmpty(data)) {
            sessionStorage.setItem('data', JSON.stringify(data))
            setFilteredData(getFilteredData())
        }
    }, [data])

    useEffect(() => {
        if (!(isEqual(data, initialUpdateData))) {
            setIsUpdate(true)
        }
    }, [data, initialUpdateData])

    useEffect(() => {
        if (isEmpty(filteredColumns)) {
            setFilteredData(data)
        }
    }, [filteredColumns])

    useEffect(() => {
        if(isEmpty(filteredColumns)) {
            setCellMimicWidth((prev) => {
                if(!isNil(datagridBoxRef.current)) {
                    const cellWidth = Number(datagridBoxRef.current.offsetWidth / columns.length)
                    return (cellWidth > 175) ? cellWidth : 175
                }

                return prev
            })
        } else {
            setCellMimicWidth((prev) => {
                if(!isNil(datagridBoxRef.current)) {
                    const cellWidth = Number(datagridBoxRef.current.offsetWidth / filteredColumns.length)
                    return (cellWidth > 175) ? cellWidth : 175
                }

                return prev
            })
        }
    }, [columns, filteredColumns])

    const getTypes = async () => {
        await axios.get(`api/production-types?company_id=${localStorage.getItem('company_id')}`, config)
            .then(res => {
                const types = res.data
                setTypes(types)
            })
    }

    const getProductionData = async (employeeList, itemList) => {
        setIsLoading(true)
        setDisabled(true)

        await axios.get(`/api/production-data?company_id=${localStorage.getItem('company_id')}&site_id=${choosesite}&production_date=${dayjs(productionDate).format('YYYY-MM-DD')}&shift=${shift}&production_type=${productionType}`, config).then((res) => {
            const data = res.data
            if (data !== null) {
                if (data.length < 1) {
                    setEmployees([])
                    setItems([])
                    setData([])
                    setInitialUpdateData([])
                    setProductionDataId(0)
                } else {
                    const dailyProductionReport = last(data).daily_production_report
                    setProductionDataId(last(data).id)
                    setData(dailyProductionReport)
                    setInitialUpdateData(dailyProductionReport)
                    const employeeIds = [...new Set(dailyProductionReport.map((employee) => employee.employee_id))]
                    const filteredEmployees = employeeList.filter((employee) => employeeIds.includes(employee.id))
                    const itemCodes = [...new Set(dailyProductionReport.map((item) => item.item_code))]
                    const filteredItems = itemList.filter((item) => itemCodes.includes(item.id))
                    if (filteredEmployees.length < 1 || filteredItems.length < 1) {
                        setEmployees([])
                        setItems([])
                    } else {
                        setEmployees(filteredEmployees)
                        setItems(filteredItems)
                    }
                }
            } else {
                setEmployees([])
                setItems([])
                setData([])
                setInitialUpdateData([])
                setProductionDataId(0)
            }
            setDisabled(false)
            setIsSaved(false)
        }).catch(({ response }) => {
            if (response.status === 422) {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            }
            setDisabled(false)
            setProductionDataId(0)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const saveProductionData = async (e) => {
        setIsLoading(true)
        e.preventDefault()
        setDisabled(true)

        const formData = {}

        formData['production_date'] = dayjs(productionDate).format('YYYY-MM-DD')
        formData['shift'] = shift
        formData['production_type'] = productionType
        formData['daily_production_report'] = data
        formData['created_by'] = user?.id
        formData['company_id'] = localStorage.getItem('company_id')
        formData['site_id'] = choosesite

        const newData = data

        await axios.post(`/api/production-data`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            setDisabled(false)
            setProductionDataId(data.success.created.id)
            setInitialUpdateData(newData)
            setIsUpdate(false)
        }).then(() => {
            setIsSaved(true)
        }).catch(({ response }) => {
            if (response.status === 422) {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            }
            setDisabled(false)
            setProductionDataId(0)
            setInitialUpdateData([])
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const updateProductionData = async (e) => {
        setIsLoading(true)
        e.preventDefault()
        setDisabled(true)

        const formData = {}

        formData['daily_production_report'] = data

        const newData = data

        await axios.put(`/api/production-data/${productionDataId}`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            setDisabled(false)
            setInitialUpdateData(newData)
            setIsUpdate(false)
        }).catch(({ response }) => {
            if (response.status === 422) {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            }
            setDisabled(false)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleOpenModalEmployees = () => {
        setOpenModalEmployees(!openModalEmployees)
    }
    const handleOpenModalItems = () => {
        setOpenModalItems(!openModalItems)
    }
    const handleShowFilterRow = () => {
        setShowFilterRow(!showFilterRow)
    }
    const handleClearFilteredEmployeeClick = () => {
        setFilteredEmployees([])
    }
    const handleClearFilteredItemClick = () => {
        setFilteredItems([])
    }

    // The following three (3) functions need to be refactored sometime soon. Gross violation of DRY.
    const handleChangeDate = async (date) => {
        if (isUpdate) {
            const isConfirm = await Swal.fire({
                title: t('caution'),
                text: t('production_entry_unsaved_data_warning'),
                icon: 'warning',
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000',
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t('confirm'),
                cancelButtonText: t('cancel')
            }).then((result) => {
                return result.isConfirmed
            })

            if (isConfirm) {
                setProductionDate(date)
                setDatepickerDate(date)
                setIsUpdate(false)
            } else {
                setDatepickerDate(productionDate)
            }
        } else {
            setProductionDate(date)
            setDatepickerDate(date)
        }
    }
    const handleChangeShift = async (shift) => {
        if (isUpdate) {
            const isConfirm = await Swal.fire({
                title: t('caution'),
                text: t('production_entry_unsaved_data_warning'),
                icon: 'warning',
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000',
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t('confirm'),
                cancelButtonText: t('cancel')
            }).then((result) => {
                return result.isConfirmed
            })

            if (isConfirm) {
                setShift(shift)
                setOpenShifts(false)
                setIsUpdate(false)
            }
        } else {
            setShift(shift)
            setOpenShifts(false)
        }
    }
    const handleChangeType = async (type) => {
        if (isUpdate) {
            const isConfirm = await Swal.fire({
                title: t('caution'),
                text: t('production_entry_unsaved_data_warning'),
                icon: 'warning',
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000',
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t('confirm'),
                cancelButtonText: t('cancel')
            }).then((result) => {
                return result.isConfirmed
            })

            if (isConfirm) {
                setProductionType(type)
                setOpenTypes(false)
                setIsUpdate(false)
            }
        } else {
            setProductionType(type)
            setOpenTypes(false)
        }
    }

    const handleQtyChange = (id, field, qty) => {
        setData((prev) => {
            const updatedDataCellIndex = prev.findIndex((dataCell) => dataCell.row_id === id && dataCell.item_no === field)

            if (updatedDataCellIndex > -1) {
                const updatedDataCell = prev.find((dataCell) => dataCell.row_id === id && dataCell.item_no === field)
                if (updatedDataCell) {
                    const filteredCells = prev.filter((cell) => cell.cell_id !== updatedDataCell.cell_id)
                    updatedDataCell.item_qty = parseInt(qty)
                    filteredCells.push(updatedDataCell)
                    return filteredCells
                }
            }
            return prev
        })
        setIsUpdate(true)
    }

    const handleFilteredEmployees = (event) => {
        const { target: { value } } = event

        let duplicateRemoved = [];

        value.forEach((employee) => {
            if (duplicateRemoved.findIndex((o) => o.id === employee.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === employee.id);
            } else {
                duplicateRemoved.push(employee);
            }
        })

        if (value[value.length - 1] === "all") {
            setFilteredEmployees(filteredEmployees.length === employeeFilterOptions.length ? [] : employeeFilterOptions)
            return;
        }

        setFilteredEmployees(duplicateRemoved)
    }

    const handleFilteredItems = (event) => {
        const { target: { value } } = event

        let duplicateRemoved = [];

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
                duplicateRemoved.push(item);
            }
        })

        if (value[value.length - 1] === "all") {
            setFilteredItems(filteredItems.length === itemFilterOptions.length ? [] : itemFilterOptions)
            return;
        }

        setFilteredItems(duplicateRemoved)
    }

    const handleDeleteEmployeeFilter = (e, id) => {
        const shouldDelete = filteredEmployees.find((x) => x.id === id)
        if (shouldDelete) {
            const filtered = filteredEmployees.filter((x) => x.id !== shouldDelete.id)
            if (filtered.length > 0) {
                setFilteredEmployees(filtered)
            } else {
                setOpenFilterEmployees(false)
                setFilteredEmployees([])
            }
        }
    }

    const handleDeleteItemFilter = (e, id) => {
        const shouldDelete = filteredItems.find((x) => x.id === id)
        if (shouldDelete) {
            const filtered = filteredItems.filter((x) => x.id !== shouldDelete.id)
            if (filtered.length > 0) {
                setFilteredItems(filtered)
            } else {
                setOpenFilterItems(false)
                setFilteredItems([])
            }
        }
    }

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <div className='p-5 flex justify-between gap-3 flex-col lg:flex-row'>
                    <div className='bg-white rounded-md w-full h-fit'>
                        <div className='border-b p-5'>
                            <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('enter_production_data')}</p>
                        </div>
                        <div className='p-5 flex gap-3 justify-between max-[500px]:flex-col'>
                            <div className='w-full lg:w-1/2 pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('production_date')}</p>
                                <div className='w-full'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            id="select-date"
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}
                                            format="YYYY-MM-DD" disabled={disabled}
                                            value={datepickerDate}
                                            onChange={(newValue) => setDatepickerDate(newValue)}
                                            onAccept={(newValue) => handleChangeDate(newValue)}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className='w-full lg:w-1/2 pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('shift')}</p>
                                <div className='w-full'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Select
                                            value={shift}
                                            disabled={disabled}
                                            onChange={e => handleChangeShift(e.target.value)}
                                            open={openShifts}
                                            onOpen={() => setOpenShifts(true)}
                                            onClose={() => setOpenShifts(false)}
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}>
                                            <MenuItem value={1}>{t('shift')} 1</MenuItem>
                                            <MenuItem value={2}>{t('shift')} 2</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className='w-full lg:w-1/2 pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('production_type')}</p>
                                <div className='w-full'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Select
                                            value={productionType}
                                            disabled={disabled}
                                            onChange={e => handleChangeType(e.target.value)}
                                            open={openTypes}
                                            onOpen={() => setOpenTypes(true)}
                                            onClose={() => setOpenTypes(false)}
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}>
                                            {
                                                types.map((item) =>
                                                    <MenuItem value={item.id} key={item.id}>{item.code}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-5 xl:flex-row p-5 pt-0'>
                    <div className='pb-5 bg-white mb-2 rounded-md flex items-start flex-col w-full  xl:w-2/3'>
                        <div className='flex justify-between items-center p-5 border-b w-full'>
                            <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('daily_production_report')}<span style={{visibility: isUpdate ? 'visible' : 'hidden'}}>*</span></p>
                            <div className='flex gap-2 items-center'>
                                <Tooltip disableInteractive title={t('addNewE')} placement='bottom'>
                                    <div>
                                        <AddButton onClick={e => handleOpenModalEmployees()}><span style={{ paddingLeft: '2px' }}><i className="fa-solid fa-user flex justify-center"></i></span></AddButton>
                                    </div>
                                </Tooltip>
                                <Tooltip disableInteractive title={t('addNewI')} placement='bottom'>
                                    <div>
                                        <AddButton onClick={e => handleOpenModalItems()}><span style={{ paddingLeft: '2px' }}><i className="fa-solid fa-list flex justify-center"></i></span></AddButton>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <div className='flex justify-between items-end w-full border-b p-5 gap-2'>
                            <div className='w-full'>
                                <FormControl sx={{ width: 'inherit' }}>
                                    <InputLabel id="demo-simple-select-standard-label">{t('employee_name')}</InputLabel>
                                    <Select
                                        multiple
                                        disabled={employees.length < 1 || items.length < 1}
                                        value={filteredEmployees}
                                        open={openFilterEmployees}
                                        onOpen={() => setOpenFilterEmployees(true)}
                                        onClose={() => setOpenFilterEmployees(false)}
                                        onChange={handleFilteredEmployees}
                                        sx={{
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                            ".MuiSelect-iconOutlined": { display: filteredEmployees?.length > 0 ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                        }}
                                        endAdornment={filteredEmployees?.length > 0 ? (<IconButton sx={{ visibility: filteredEmployees?.length > 0 ? "visible" : "hidden", padding: '0' }} onClick={handleClearFilteredEmployeeClick}><ClearIcon /></IconButton>) : false}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((x) => (
                                                    <Chip
                                                        key={`pe_option-${x.id}`}
                                                        label={x.name}
                                                        onMouseDown={(e) => e.stopPropagation()}
                                                        onDelete={(e) => handleDeleteEmployeeFilter(e, x.id)}
                                                    />
                                                ))}
                                            </Box>
                                        )}

                                    >
                                        {
                                            employeeFilterOptions?.length > 0 ?
                                                <MenuItem value="all">
                                                    <ListItemIcon>
                                                        <Checkbox checked={isAllEmployeesFiltered} indeterminate={filteredEmployees.length > 0 && filteredEmployees.length < employeeFilterOptions.length} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={t('select_all')} />
                                                </MenuItem>
                                                :
                                                <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                        }
                                        {
                                            employeeFilterOptions.map((emp) =>
                                                <MenuItem value={emp} key={emp.id}>
                                                    <Checkbox checked={filteredEmployees?.findIndex((i) => i.id === emp.id) >= 0} />
                                                    <ListItemText primary={emp.name} />
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='w-full'>
                                <FormControl sx={{ width: 'inherit' }}>
                                    <InputLabel id="demo-simple-select-standard-label">{t('item_no')}</InputLabel>
                                    <Select
                                        multiple
                                        disabled={employees.length < 1 || items.length < 1}
                                        value={filteredItems}
                                        open={openFilterItems}
                                        onOpen={() => setOpenFilterItems(true)}
                                        onClose={() => setOpenFilterItems(false)}
                                        onChange={handleFilteredItems}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((x) => (
                                                    <Chip
                                                        key={`i_option-${x.id}`}
                                                        label={x.item_no}
                                                        onMouseDown={(e) => e.stopPropagation()}
                                                        onDelete={(e) => handleDeleteItemFilter(e, x.id)}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                        sx={{
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                            ".MuiSelect-iconOutlined": { display: filteredItems?.length ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                        }}>
                                        endAdornment={filteredItems?.length > 0 ? (<IconButton sx={{ visibility: filteredItems?.length > 0 ? "visible" : "hidden", padding: '0' }} onClick={handleClearFilteredItemClick}><ClearIcon /></IconButton>) : false}
                                        {
                                            itemFilterOptions?.length > 0 ?
                                                <MenuItem value="all">
                                                    <ListItemIcon>
                                                        <Checkbox checked={isAllItemsFiltered} indeterminate={filteredItems.length > 0 && filteredItems.length < itemFilterOptions.length} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={t('select_all')} />
                                                </MenuItem>
                                                :
                                                <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                        }
                                        {
                                            itemFilterOptions.map((item) =>
                                                <MenuItem value={item} key={item.id}>
                                                    <Checkbox checked={filteredItems?.findIndex((i) => i.id === item.id) >= 0} />
                                                    <ListItemText primary={item.item_no} />
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <Box
                            width='100%'
                            sx={{
                                '& .pinned-left': window.innerWidth > 640 ? {
                                    whiteSpace: "nowrap",
                                    position: "sticky",
                                    left: "0",
                                    background: "white",
                                    zIndex: 9999
                                } : {},
                                '& .pinned-right': window.innerWidth > 640 ? {
                                    whiteSpace: "nowrap",
                                    position: "sticky",
                                    right: "0",
                                    background: "white",
                                    zIndex: 9999
                                } : {},
                                position: 'relative'
                            }}
                            ref={datagridBoxRef}
                        >

                            {/* Employee (Name) header cell */}
                            {columns.length > 2 ? (
                                <CellMimic
                                    className='!hidden sm:!flex'
                                    width={cellMimicWidth}
                                    height={cellMimicHeight}
                                    alignItems='center'
                                    left='0'
                                    onMouseOver={(e) => setIsHoveringOverEmployeeCell(true)}
                                    onMouseLeave={(e) => setIsHoveringOverEmployeeCell(false)}
                                    onClick={() => setSortModel((prev) => {
                                        const field = 'employee_name'
                                        switch(prev[0]?.sort) {
                                            case 'asc':
                                                return [
                                                    {
                                                        field: field,
                                                        sort: 'desc'
                                                    }
                                                ]
                                            default:
                                                return [
                                                    {
                                                        field: field,
                                                        sort: 'asc'
                                                    }
                                                ]
                                        }
                                    })}
                                >
                                    <Stack direction='row' spacing={1}>
                                        <Typography fontSize='15px' fontWeight='400'>{t('employee')}</Typography>
                                        <div style={{display: isEqual(sortModel[0]?.field, 'employee_name') || isHoveringOverEmployeeCell ? 'block' : 'none' }} className='rotate-90'><i className="fa-solid fa-arrow-right-arrow-left"></i></div>
                                    </Stack>
                                </CellMimic>
                            ) : (
                                ''
                            )}

                            {/* Total header cell */}
                            {columns.length > 2 ? (
                                <CellMimic
                                    className='!hidden sm:!flex'
                                    width={cellMimicWidth}
                                    height={cellMimicHeight}
                                    alignItems='center'
                                    justifyContent='center'
                                    right='0'
                                    onMouseOver={(e) => setIsHoveringOverTotalCell(true)}
                                    onMouseLeave={(e) => setIsHoveringOverTotalCell(false)}
                                    onClick={() => setSortModel((prev) => {
                                        const field = 'total'
                                        switch(prev[0]?.sort) {
                                            case 'asc':
                                                return [
                                                    {
                                                        field: field,
                                                        sort: 'desc'
                                                    }
                                                ]
                                            default:
                                                return [
                                                    {
                                                        field: field,
                                                        sort: 'asc'
                                                    }
                                                ]
                                        }
                                    })}
                                >
                                    <Stack direction='row' spacing={1}>
                                        <Typography fontSize='15px' fontWeight='400'>{t('total')}</Typography>
                                        <div style={{display: isEqual(sortModel[0]?.field, 'total') || isHoveringOverTotalCell ? 'block' : 'none' }} className='rotate-90'><i className="fa-solid fa-arrow-right-arrow-left"></i></div>
                                    </Stack>
                                </CellMimic>
                            ) : (
                                ''
                            )}

                            <TableShared
                                columns={filteredColumns.length > 0 ? filteredColumns : columns}
                                items={filteredRows.length > 0 ? filteredRows : rows}
                                oddColumnPadding='20px'
                                flat={true}
                                headerRowFontSize='15px'
                                columnBuffer={columns.length}
                                sortModel={sortModel}
                                setSortModel={setSortModel}
                            />
                            <div className='p-5 pb-0 flex gap-3 justify-end'>
                                {productionDataId > 0 ? (
                                    <a href={process.env.REACT_APP_BACKEND_URL + `/api/production-data/${productionDataId}/export?stream=download&filetype=csv&CLIENT_ID=${localStorage.getItem('client_id')}&company_id=${localStorage.getItem('company_id')}&site_id=${choosesite}`}
                                        target="_blank" download>
                                        <Tooltip disableInteractive title={t('exportProductionReport')} placement='bottom'>
                                            <button type="button" className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#6C6C6C] hover:bg-[#494949] disabled:bg-[#B8B7BC]'>
                                                <span className='pr-2'><i className="fa-solid fa-download"></i></span> {t('export')}
                                            </button>
                                        </Tooltip>
                                    </a>
                                ) : (
                                    <></>
                                )}

                                {productionDataId > 0 ? (
                                    <button type="button" disabled={disabled} onClick={e => updateProductionData(e)} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC]'>
                                        <span className='pr-2'><i className="fa-solid fa-clock-rotate-left"></i></span> {t('update')}
                                    </button>
                                ) : (
                                    <button type="button" disabled={disabled} onClick={e => saveProductionData(e)} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#00C448] hover:bg-[#019738] disabled:bg-[#B8B7BC]'>
                                        <span className='pr-2'><i className="fa-solid fa-check"></i></span> {t('save')}
                                    </button>
                                )}
                            </div>
                        </Box>
                    </div>
                    <div className='w-full xl:w-1/3'>
                        <div className='bg-white rounded-md'>
                            <div>
                                <SummarySidebar data={filteredData} />
                            </div>
                        </div>
                    </div>
                </div>

                <AddModal
                    title={t('select_employees')}
                    open={openModalEmployees}
                    handleToggleModal={handleOpenModalEmployees}
                    options={employeesList}
                    selections={employees}
                    setSelections={setEmployees}
                    dataType='production_employee'
                />
                <AddModal
                    title={t('select_items')}
                    open={openModalItems}
                    handleToggleModal={handleOpenModalItems}
                    options={itemsList}
                    selections={items}
                    setSelections={setItems}
                    dataType='production_item'
                    color='#BD7503'
                />
            </AppLayout >
        </>
    )
}

const HandleQty = ({ params, handler, disabled }) => {
    const [qty, setQty] = useState(() => {
        const data = JSON.parse(sessionStorage.getItem('data'))
        const cell = data.find((cell) => cell.row_id === params.row.id && cell.item_no === params.field)
        return cell.item_qty
    })

    const handleZero = (e) => {
        if (e.target.value === "") {
            setQty(0)

            handler(
                params.row.id,
                params.field,
                0,
            )
        }
    }

    const deleteZero = (e) => {
        if (qty == 0) {
            setQty('')
        }
    }

    const handleQuantity = (e) => {
        const regex = /^(-?\d*)$/i
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') setQty(0)
            else {
                setQty(e.target.value.slice(0, 5))

                handler(
                    params.row.id,
                    params.field,
                    e.target.value.slice(0, 5),
                )
            }
        }
    }

    return (
        <CustomTextField
            type="text"
            variant='outlined'
            disabled={disabled}
            className='w-full mb-5 px-0 pt-0 text-center'
            name="qty"
            value={qty}
            inputProps={{
                maxLength: 5
            }}
            onChange={(e) => { handleQuantity(e) }}
            onBlur={e => handleZero(e)}
            onFocus={e => deleteZero(e)}
        />
    )
}

const HandleTotal = ({ params }) => {
    const [total, setTotal] = useState(() => {
        const sessionData = JSON.parse(sessionStorage.getItem('filteredData'))
        if (!isEmpty(sessionData)) {
            const cells = sessionData.filter((cell) => cell.row_id === params.row.id)
            let sum = 0
            cells.forEach((cell) => {
                sum += Number(cell.item_qty)
            })
            return sum
        } else {
            return 0
        }
    })

    useEffect(() => {
        setTotal((prev) => {
            const data = JSON.parse(sessionStorage.getItem('filteredData'))
            // For whatever reason, this blocks throws an exception whenever deimpersonating from the Enter Production Data view. This check aims to mitigate that.
            if (!isEmpty(data)) {
                const cells = data.filter((cell) => cell.row_id === params.row.id)
                let sum = 0
                cells.forEach((cell) => {
                    sum += isNaN(cell.item_qty) ? 0 : Number(cell.item_qty)
                })
                return sum
            }
            else {
                return 0
            }
        })
    }, [params])

    return (
        <>{total}</>
    )
}

const SummarySidebar = (data) => {
    const { t } = useTranslation()

    const [columns] = useState([
        {
            field: 'item',
            headerName: t('item'),
            flex: 1
        },
        {
            field: 'quantity',
            headerName: t('quantity'),
            flex: 1
        }
    ])
    const [rows, setRows] = useState([])
    const [filteredRows] = useState([])
    const [total, setTotal] = useState(0)

    useEffect(() => {
        const newRows = []
        let newTotal = 0
        const cells = data.data
        if (cells !== null && cells.length >= 1) {
            const codes = [...new Set(cells.map((cell) => cell.item_no))]
            codes.forEach((code) => {
                const codeCells = cells.filter((cell) => cell.item_no === code)
                let codeQuantitySum = 0
                codeCells.forEach((cell) => {
                    codeQuantitySum += isNaN(cell.item_qty) ? 0 : Number(cell.item_qty)
                })
                newRows.push({
                    id: code,
                    item: code,
                    quantity: codeQuantitySum
                })
                newTotal += Number(codeQuantitySum)
            })
        }
        setTotal(newTotal)
        setRows(newRows)
    }, [data])

    return (
        <>
            <div className='border-b p-5'>
                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('production_summary_information')}</p>
            </div>
            <TableShared
                columns={columns}
                items={filteredRows.length > 0 ? filteredRows : rows}
                /* hideFooter={true} */
                oddColumnPadding='20px'
                flat={true}
                headerRowFontSize='15px'
            />
            <div className='flex px-5 pb-5 pt-[1.15rem] border-t justify-end'>
                <p className='w-1/3 text-[#333333] text-[14px] uppercase font-bold'>{t('TOTAL')}</p>
                <p className='w-auto text-[#333333] text-[14px] uppercase font-bold pl-5'>{total}</p>
            </div>
        </>
    )
}

export default EnterProductionData
