import { buildUrl } from "../../../helpers/apiHelper";
import axios from "../../../lib/axios"

const getSalesOrdersStatuses = async (
    config,
    options = {
        with: [],
        where: {}
    }
) => {
    const baseUrl = '/api/sales-order-statuses';

    return await axios
        .get(buildUrl(baseUrl, options), config)
        .then(({ data }) => data)
        .catch(({ response }) => {
            console.error(response)
            return []
        })
}

const salesOrderStatusAPI = {
    getSalesOrdersStatuses
}

export default salesOrderStatusAPI
