import React from 'react';
import { useTranslation } from "react-i18next"
import { Typography, Stack } from '@mui/material'
import { motion } from 'framer-motion'

const PositiveCurve = ({isShipmentRelated = true}) => {
    return (
        <svg width="90" height="46" viewBox="0 0 90 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M89 1C74.7028 2.19143 73.8174 30.715 59.6667 34C47.9977 36.7089 42.0888 21.332 30.3333 23C17.5828 24.8092 13.5025 40.8325 1 45H89V1Z" fill="#ECECFD"/>
        <path d="M89 1C74.7028 2.19143 73.8174 30.715 59.6667 34C47.9977 36.7089 42.0888 21.332 30.3333 23C17.5828 24.8092 13.5025 40.8325 1 45H89V1Z" fill="url(#paint0_linear_1_4255)"/>
        <path d="M1 45C13.5025 40.8325 17.5828 24.8092 30.3333 23C42.0888 21.332 47.9976 36.7089 59.6667 34C73.8174 30.715 74.7028 2.19143 89 1" stroke={isShipmentRelated ? "#0A209F" : "#F04438"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
        <linearGradient id="paint0_linear_1_4255" x1="45" y1="1" x2="45" y2="45" gradientUnits="userSpaceOnUse">
        <stop offset="0.641167" stopColor="white" stopOpacity="0"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        </defs>
        </svg>
    )
}

const NegativeCurve = ({isShipmentRelated = true}) => {
    return (
        <svg width="90" height="46" viewBox="0 0 90 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1C15.2972 2.19143 16.1826 30.715 30.3333 34C42.0023 36.7089 47.9112 21.332 59.6667 23C72.4172 24.8092 76.4975 40.8325 89 45H1V1Z" fill="#FEF3F2"/>
        <path d="M1 1C15.2972 2.19143 16.1826 30.715 30.3333 34C42.0023 36.7089 47.9112 21.332 59.6667 23C72.4172 24.8092 76.4975 40.8325 89 45H1V1Z" fill="url(#paint0_linear_1_4269)"/>
        <path d="M1 1C15.2972 2.19143 16.1826 30.715 30.3333 34C42.0023 36.7089 47.9112 21.332 59.6667 23C72.4172 24.8092 76.4975 40.8325 89 45C13.5025" stroke={isShipmentRelated ? "#0A209F" : "#F04438"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
        <linearGradient id="paint0_linear_1_4269" x1="45" y1="1" x2="45" y2="45" gradientUnits="userSpaceOnUse">
        <stop offset="0.641167" stopColor="white" stopOpacity="0"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        </defs>
        </svg>
    )
}

const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i) => {
      const delay = 1 + i * 0.5;
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
          opacity: { delay, duration: 0.01 }
        }
      };
    }
};

const PositiveCurveMotion = ({isShipmentRelated = true}) => {
    return (
        <motion.svg
            initial="hidden"
            animate="visible"
            width="90"
            height="46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 90 46"
        >
            <motion.path
                d="M89 1C74.7028 2.19143 73.8174 30.715 59.6667 34C47.9977 36.7089 42.0888 21.332 30.3333 23C17.5828 24.8092 13.5025 40.8325 1 45H89V1Z"
                fill="#ECECFD"
            />
            <motion.path
                d="M89 1C74.7028 2.19143 73.8174 30.715 59.6667 34C47.9977 36.7089 42.0888 21.332 30.3333 23C17.5828 24.8092 13.5025 40.8325 1 45H89V1Z"
                fill="url(#paint0_linear_1_4255)"
            />
            <motion.path
                d="M1 45C13.5025 40.8325 17.5828 24.8092 30.3333 23C42.0888 21.332 47.9976 36.7089 59.6667 34C73.8174 30.715 74.7028 2.19143 89 1"
                stroke={isShipmentRelated ? "#0A209F" : "#F04438"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                variants={draw}
            />
            <motion.defs>
                <motion.linearGradient
                    id="paint0_linear_1_4255"
                    x1="45"
                    y1="1"
                    x2="45"
                    y2="45"
                    gradientUnits="userSpaceOnUse"
                >
                    <motion.stop
                        offset="0.641167"
                        stopColor="white"
                        stopOpacity="0"
                    />
                    <motion.stop
                        offset="1"
                        stopColor="white"
                    />
                </motion.linearGradient>
            </motion.defs>
        </motion.svg>
    )
}

const NegativeCurveMotion = ({isShipmentRelated = true}) => {
    return (
        <motion.svg
            initial="hidden"
            animate="visible"
            width="90"
            height="46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 90 46"
        >
            <motion.path
                d="M1 1C15.2972 2.19143 16.1826 30.715 30.3333 34C42.0023 36.7089 47.9112 21.332 59.6667 23C72.4172 24.8092 76.4975 40.8325 89 45H1V1Z"
                fill="#FEF3F2"
            />
            <motion.path
                d="M1 1C15.2972 2.19143 16.1826 30.715 30.3333 34C42.0023 36.7089 47.9112 21.332 59.6667 23C72.4172 24.8092 76.4975 40.8325 89 45H1V1Z"
                fill="url(#paint0_linear_1_4269)"
            />
            <motion.path
                d="M1 1C15.2972 2.19143 16.1826 30.715 30.3333 34C42.0023 36.7089 47.9112 21.332 59.6667 23C72.4172 24.8092 76.4975 40.8325 89 45H1V1Z"
                stroke={isShipmentRelated ? "#0A209F" : "#F04438"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                variants={draw}
            />
            <motion.defs>
                <motion.linearGradient
                    id="paint0_linear_1_4269"
                    x1="45"
                    y1="1"
                    x2="45"
                    y2="45"
                    gradientUnits="userSpaceOnUse"
                >
                    <motion.stop
                        offset="0.641167"
                        stopColor="white"
                        stopOpacity="0"
                    />
                    <motion.stop
                        offset="1"
                        stopColor="white"
                    />
                </motion.linearGradient>
            </motion.defs>
        </motion.svg>
    )
}

export default function CustomCardContent({qty = 0, supplementaryQty = null, cardType = 'created', pct = 100, isShipmentRelated = true}) {
    const { t } = useTranslation()

    return (
        <div style={{position: 'relative', height: '100%'}}>
            <Stack className='transition ease-in-out delay-150' justifyContent="space-around" alignItems={pct >= 0 ? 'start' : 'end'} sx={{height: '100%'}}>
                <Typography sx={{ fontSize: 36, fontWeight: '600', lineHeight: 'normal', zIndex: 2 }}>{qty}</Typography>

                {supplementaryQty ? (
                    <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#667085', zIndex: 2, textTransform: 'lowercase' }}>{supplementaryQty} <span style={{fontWeight: 'normal'}}>{t('x_this_y', {x: t(cardType), y: t('month')})}</span></Typography>
                ) : (
                    ''
                )}

                <div
                    style={{
                        display: 'flex',
                        gap: '4px',
                        alignItems: 'center',
                        justifyContent: pct >= 0 ? 'left' : 'right',
                        color: pct >= 0 ? '#12B76A' : '#F04438',
                        fontSize: '14px',
                        fontWeight: '700',
                        zIndex: 2
                    }}
                >
                    {pct >= 0 ? (
                        <i className="fa-solid fa-arrow-up" />
                    ) : (
                        <i className="fa-solid fa-arrow-down" />
                    )}
                    {Math.abs(pct)}% <span style={{color: '#667085'}}>{t('vs_last_month')}</span>
                </div>
            </Stack>

            <div
                style={{
                    position: 'absolute',
                    right: pct >= 0 ? '40px' : 'auto',
                    left: pct < 0 ? '39px' : 'auto',
                    top: '60px',
                    opacity: 0.25,
                    zIndex: 1,
                    transform: 'scale(2.5)'
                }}
            >
                {pct >= 0 ? (
                    <PositiveCurveMotion isShipmentRelated={isShipmentRelated} />
                ) : (
                    <NegativeCurveMotion isShipmentRelated={isShipmentRelated} />
                )}
            </div>
        </div>

    );
}
