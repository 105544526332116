import React, { useState, useEffect } from 'react'

import { Modal, Box, TextField, Button, FormHelperText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"
import { useDebounce } from "use-debounce"

import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import { handleAxiosError } from '../../helpers/helper'
import { isEmpty } from 'lodash'

const style = {
    minWidth: "460px",
    maxWidth: "640px",
    width: "50%",
    height: "auto",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#ffffff',
    border: 'transparent',
    borderRadius: '5px',
    boxShadow: 24,
    zIndex: "1600"
}

const RejectSalesOrder = ({ open, handleOpen, order, title, setIsLoading, type }) => {

    const { t } = useTranslation()
    const { config } = useStateContext()
    const navigate = useNavigate()

    const [reason, setReason] = useState('')
    const [debouncedReason] = useDebounce(reason, 250);
    const [errorReason, setErrorReason] = useState(false)

    useEffect(() => {
        if(!isEmpty(debouncedReason)) {
            setErrorReason(false)
        }
    }, [debouncedReason])

    const handleOrder = () => {
        if(type === 'shipment') {
            rejectShipment()
        } else {
            rejectCollection()
        }
    }

    const rejectShipment = async () => {
        if(isEmpty(reason)) {
            setErrorReason(true)
        } else {
            setIsLoading(true)
            const formData = {}

            formData['reject_reason'] = reason

            await axios
                .put(`/api/shipment-requests/reject-request/${order?.id}`, formData, config)
                .then(({ data }) => {
                    setIsLoading(false)
                })
                .catch(({ response }) => {
                    handleAxiosError({response: response})
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const rejectCollection = async () => {
        if(isEmpty(reason)) {
            setErrorReason(true)
        } else {
            setIsLoading(true)
            const formData = {}


            formData['reject_reason'] = reason

            await axios
                .put(`/api/collection-requests/reject-request/${order?.id}`, formData, config)
                .then(({ data }) => {
                    setIsLoading(false)
                })
                .catch(({ response }) => {
                    handleAxiosError({response: response})
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const handleClose = async () => {
        setReason('')
        setErrorReason(false)
        handleOpen()
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <div className='p-5' style={{ backgroundColor: '#dd5e59', borderRadius: '5px 5px 0 0' }}>
                    <div className='flex gap-4 items-baseline justify-between'>
                        <p className='text-xl roboto font-semibold text-white'>{title}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-semibold text-white">
                            <button onClick={handleClose}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='p-5'>
                        <form className='flex justify-center flex-col items-start mt-2'>
                            <div className='mb-4 w-full'>
                                <div>{t('rejecting_reason')} *</div>
                                <TextField error={errorReason} type="text" variant='standard' className='w-full' name="reason" value={reason} onChange={e => setReason(e.target.value)} required/>
                            </div>
                        </form>

                        {errorReason ? (
                            <FormHelperText>{t('please_state_rejection_reason')}</FormHelperText>
                        ) : (
                            ''
                        )}

                        <div className='flex justify-end gap-4'>
                            <Button variant="contained" onClick={handleOrder} sx={{ backgroundColor: '#dd5e59', "&:disabled": { backgroundColor: "[hsla(0,0%,100%,.12)]" }, "&:hover": { backgroundColor: "#dd5e59" }, width: '76px' }} >{t('confirm')}</Button>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default RejectSalesOrder
