import React from 'react'

import TableShared from '../TableShared'
import { useTranslation } from 'react-i18next'
import { useStateContext } from '../../context/ContextProvider'

const CurrencyCell = ({params, propertyName}) => {
    const { currencySymbol } = useStateContext()

    return (
        <>{currencySymbol}{params.row[propertyName].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const PurchaseDetails = ({ purchaseLocation }) => {

    const { t } = useTranslation()

    const columns = [

        {
            field: 'purchase_name',
            headerName: t('purchase_name'),
            flex: 1,
            minWidth: 140
        },
        {
            field: 'quantity_collected',
            headerName: t('quantity_collected'),
            flex: 1,
            minWidth: 140
        },
        {
            field: 'total_value',
            headerName: t('total_value'),
            flex: 1,
            renderCell: (params) => <CurrencyCell params={params} propertyName="total_value" />,
            minWidth: 140
        },
        {
            field: 'number_of_collections',
            headerName: t('number_of_collections'),
            flex: 1,
            minWidth: 140
        },
        {
            field: 'qty_per_load',
            headerName: t('qty_per_load'),
            flex: 1,
            minWidth: 140
        },
        {
            field: 'value_per_load',
            headerName: t('value_per_load'),
            flex: 1,
            renderCell: (params) => <CurrencyCell params={params} propertyName="value_per_load" />,
            minWidth: 140
        }
    ]

    return (
        <div className=''>
            <div className='p-5 border-b'>
                <div className='flex justify-start items-center gap-4 '>
                    <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('purchase_details')}</p>
                </div>
            </div>
            <div className='pt-3'>
                <TableShared breakHeaders={true} items={purchaseLocation} columns={columns} rowsPerPageOptions={[5, 10]} border='none'/>
            </div>
        </div>
    )
}

export default PurchaseDetails
