import React, { useEffect, useState } from 'react'

import { Box, Chip, Input, InputAdornment, FormControl, InputLabel, Select, IconButton, MenuItem, ListItemText, ListItemIcon, Checkbox } from '@mui/material'
import { useTranslation } from 'react-i18next'
import update from 'immutability-helper'

import AppLayout from '../../components/Layouts/AppLayout'
import Loading from '../../components/Loading'
import ShipmentsRequestTable from '../../components/customer_portal/ShipmentsRequestTable'
import CollectionRequestTable from '../../components/customer_portal/CollectionRequestTable'
import axios from '../../lib/axios'
import { useStateContext } from "../../context/ContextProvider";
import { useAuth } from '../../hooks/auth'
import dayjs from 'dayjs'
import ClearIcon from "@mui/icons-material/Clear"

import { handleDeleteSelectionObject, handleDeleteSelectionPrimitive } from '../../helpers/helper'

const MyRequests = () => {

    const { t } = useTranslation()
    const { config, pusher, company_id } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })

    const [isLoading, setIsLoading] = useState(false)
    const [shipments, setShipments] = useState([])
    const [collections, setCollections] = useState([])

    /* filter options */
    const [deliveryStatuses, setDeliveryStatuses] = useState([])
    const [collectionStatuses, setCollectionStatuses] = useState([])
    const [reqShipmentDates, setReqShipmentDates] = useState([])
    const [reqCollectionDates, setReqCollectionDates] = useState([])

    /* filters */
    const [selectedDeliveryStatuses, setSelectedDeliveryStatuses] = useState([])
    const [selectedCollectionStatuses, setSelectedCollectionStatuses] = useState([])
    const [selectedReqShipmentDates, setSelectedReqShipmentDates] = useState([])
    const [selectedReqCollectionDates, setSelectedReqCollectionDates] = useState([])
    const [searchSalesOrderNo, setSearchSalesOrderNo] = useState('')
    const [searchPurchaseOrderNo, setSearchPurchaseOrderNo] = useState('')

    const everyDeliveryStatusSelected = deliveryStatuses.length > 0 && selectedDeliveryStatuses.length === deliveryStatuses.length
    const everyCollectionStatusSelected = collectionStatuses.length > 0 && selectedCollectionStatuses.length === collectionStatuses.length
    const everyReqShipmentDateSelected = reqShipmentDates.length > 0 && selectedReqShipmentDates.length === reqShipmentDates.length
    const everyReqCollectionDateSelected = reqCollectionDates.length > 0 && selectedReqCollectionDates.length === reqCollectionDates.length

    /* filter dropdown control */
    const [openDeliveryStatuses, setOpenDeliveryStatuses] = useState(false)
    const [openCollectionStatuses, setOpenCollectionStatuses] = useState(false)
    const [openReqShipmentDates, setOpenReqShipmentDates] = useState(false)
    const [openReqCollectionDates, setOpenReqCollectionDates] = useState(false)

    useEffect(() => {
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-shipmentrequest-deleted-company-${localStorage.getItem('company_id')}`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-shipmentrequest-created-company-${localStorage.getItem('company_id')}`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-shipmentrequest-updated-company-${localStorage.getItem('company_id')}`)

        const channeldelete1 = pusher.subscribe(`${localStorage.getItem('client_id')}-collectionrequest-deleted-company-${localStorage.getItem('company_id')}`)
        const channelcreate1 = pusher.subscribe(`${localStorage.getItem('client_id')}-collectionrequest-created-company-${localStorage.getItem('company_id')}`)
        const channelupdate1 = pusher.subscribe(`${localStorage.getItem('client_id')}-collectionrequest-updated-company-${localStorage.getItem('company_id')}`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-shipmentrequest-deleted-event-company-${localStorage.getItem('company_id')}`, data => {
            if (Number(data.request_creator_id) == Number(user?.id)) {
                /* deleteShipmentRequest(data.id); */
                getRequests({ skipCollections: true })
            }
        })

        channelcreate.bind(`${localStorage.getItem('client_id')}-shipmentrequest-created-event-company-${localStorage.getItem('company_id')}`, data => {
            if (Number(data.request_creator_id) == Number(user?.id)) {
                /* getShippingRequest(data.id, 'created') */
                getRequests({ skipCollections: true })
            }
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-shipmentrequest-updated-event-company-${localStorage.getItem('company_id')}`, data => {
            if (Number(data.request_creator_id) == Number(user?.id)) {
                /* getShippingRequest(data.id, 'updated') */
                getRequests({ skipCollections: true })
            }
        })

        channeldelete1.bind(`${localStorage.getItem('client_id')}-collectionrequest-deleted-event-company-${localStorage.getItem('company_id')}`, data => {
            if (Number(data.request_creator_id) == Number(user?.id)) {
                /* deleteCollectionRequest(data.id) */
                getRequests({ skipShipments: true })
            }
        })

        channelcreate1.bind(`${localStorage.getItem('client_id')}-collectionrequest-created-event-company-${localStorage.getItem('company_id')}`, data => {
            if (Number(data.request_creator_id) == Number(user?.id)) {
                /* getCollectionRequest(data.id, 'created') */
                getRequests({ skipShipments: true })
            }
        })

        channelupdate1.bind(`${localStorage.getItem('client_id')}-collectionrequest-updated-event-company-${localStorage.getItem('company_id')}`, data => {
            if (Number(data.request_creator_id) == Number(user?.id)) {
                /* getCollectionRequest(data.id, 'updated') */
                getRequests({ skipShipments: true })
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipmentrequest-deleted-company-${company_id}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipmentrequest-created-company-${company_id}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipmentrequest-updated-company-${company_id}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-collectionrequest-deleted-company-${company_id}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-collectionrequest-created-company-${company_id}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-collectionrequest-updated-company-${company_id}`)
        })
    }, [company_id])



    useEffect(() => {
        /* getShipmentRequests()
        getCollectionRequests() */
        getRequests({ skipShipments: false, skipCollections: false })
    }, [])

    { /* shipment requests and selection handlers */ }

    const getShipmentRequests = async () => {
        setIsLoading(true)
        await axios.get(`/api/shipment-requests?user_id=${user?.id}`, config)
            .then(res => {
                const shipments = res.data
                setShipments(shipments)
                setDeliverySearchOptions(shipments)
                setIsLoading(false)
            })
    }

    const setDeliverySearchOptions = (requests) => {
        if (requests && requests.length > 0) {
            const statuses = []
            const reqDates = []

            requests.forEach(request => {
                const newStatus = request.status
                const newReqDate = request.requested_shipment_date

                const newStatusExists = statuses.some((status) => status === newStatus)
                if (!newStatusExists) statuses.push(newStatus);

                const newReqDateExists = reqDates.some((reqDate) => reqDate === newReqDate)
                if (!newReqDateExists) reqDates.push(newReqDate);
            })

            statuses.sort((a, b) => a - b)
            reqDates.sort((a, b) => a - b)


            setDeliveryStatuses(statuses)
            setReqShipmentDates(reqDates)
        }
    }

    const handleChangeDeliveryStatusSelection = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = []

        value?.forEach((status) => {
            if (duplicateRemoved.findIndex((o) => o === status) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x === status)
            } else {
                duplicateRemoved.push(status)
            }
        })

        if (value[value.length - 1] === 'all') {
            setSelectedDeliveryStatuses(selectedDeliveryStatuses.length === deliveryStatuses.length ? [] : deliveryStatuses)
            return
        }

        setSelectedDeliveryStatuses(duplicateRemoved)
    }

    const handleChangeReqShipmentDateSelection = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = []

        value?.forEach((date) => {
            if (duplicateRemoved.findIndex((o) => o === date) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x === date)
            } else {
                duplicateRemoved.push(date)
            }
        })

        if (value[value.length - 1] === 'all') {
            setSelectedReqShipmentDates(selectedReqShipmentDates.length === reqShipmentDates.length ? [] : reqShipmentDates)
            return
        }

        setSelectedReqShipmentDates(duplicateRemoved)
    }

    { /* collection requests and selection handlers */ }

    const getCollectionRequests = async () => {
        setIsLoading(true)
        await axios.get(`/api/collection-requests?user_id=${user?.id}`, config)
            .then(res => {
                const collections = res.data
                setCollections(collections)
                setCollectionSearchOptions(collections)
                setIsLoading(false)
            })
    }

    const setCollectionSearchOptions = (requests) => {
        if (requests && requests.length > 0) {
            const statuses = []
            const reqDates = []

            requests.forEach(request => {
                const newStatus = request.status
                const newReqDate = request.requested_collection_date

                const newStatusExists = statuses.some((status) => status === newStatus)
                if (!newStatusExists) statuses.push(newStatus);

                const newReqDateExists = reqDates.some((reqDate) => reqDate === newReqDate)
                if (!newReqDateExists) reqDates.push(newReqDate);
            })

            statuses.sort((a, b) => a - b)
            reqDates.sort((a, b) => a - b)

            setCollectionStatuses(statuses)
            setReqCollectionDates(reqDates)
        }
    }

    const handleChangeCollectionStatusSelection = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = []

        value?.forEach((status) => {
            if (duplicateRemoved.findIndex((o) => o === status) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x === status)
            } else {
                duplicateRemoved.push(status)
            }
        })

        if (value[value.length - 1] === 'all') {
            setSelectedCollectionStatuses(selectedCollectionStatuses.length === collectionStatuses.length ? [] : collectionStatuses)
            return
        }

        setSelectedCollectionStatuses(duplicateRemoved)
    }

    const handleChangeReqCollectionDateSelection = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = []

        value?.forEach((date) => {
            if (duplicateRemoved.findIndex((o) => o === date) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x === date)
            } else {
                duplicateRemoved.push(date)
            }
        })

        if (value[value.length - 1] === 'all') {
            setSelectedReqCollectionDates(selectedReqCollectionDates.length === reqCollectionDates.length ? [] : reqCollectionDates)
            return
        }

        setSelectedReqCollectionDates(duplicateRemoved)
    }

    const getRequests = async ({ skipShipments = false, skipCollections = false }) => {
        setIsLoading(true)

        if (skipShipments) {
            await getCollectionRequests()
                .then(() => {
                    setIsLoading(false)
                })
                .catch(({ response }) => {
                    console.log("An error occured while attempting to get requests...")
                    setIsLoading(false)
                })
        } else if (skipCollections) {
            await getShipmentRequests()
                .then(() => {
                    setIsLoading(false)
                })
                .catch(({ response }) => {
                    console.log("An error occured while attempting to get requests...")
                    setIsLoading(false)
                })
        } else {
            await getShipmentRequests()
                .then(() => {
                    getCollectionRequests()
                })
                .then(() => {
                    setIsLoading(false)
                })
                .catch(({ response }) => {
                    console.log("An error occured while attempting to get requests...")
                    setIsLoading(false)
                })
        }
    }

    const deleteShipmentRequest = (id) => {
        setShipments((prev) => {
            const item = prev.find((i) => i.id === id)
            const exItem = prev.filter((i) => i.id !== item.id)
            return exItem
        })
    }

    const deleteCollectionRequest = (id) => {
        setCollections((prev) => {
            const item = prev.find((i) => i.id === id)
            const exItem = prev.filter((i) => i.id !== item.id)
            return exItem
        })
    }

    { /* filtered delivery requests */ }

    const filteredByDeliveryStatus = shipments?.filter(data => {
        if (selectedDeliveryStatuses.length < 1) return data;
        if (selectedDeliveryStatuses.includes(data.status)) return data;
    })

    const filteredByReqShipmentDate = filteredByDeliveryStatus?.filter(data => {
        if (selectedReqShipmentDates.length < 1) return data;
        if (selectedReqShipmentDates.includes(data.requested_shipment_date)) return data;
    })

    const filteredBySoNo = filteredByReqShipmentDate?.filter(data => {
        if (searchSalesOrderNo === null || searchSalesOrderNo === '') return data;
        if (data.so_number?.toLocaleLowerCase().includes(searchSalesOrderNo.toLocaleLowerCase())) return data;
    })

    { /* filtered collection requests */ }

    const filteredByCollectionStatus = collections?.filter(data => {
        if (selectedCollectionStatuses.length < 1) return data;
        if (selectedCollectionStatuses.includes(data.status)) return data;
    })

    const filteredByReqCollectionDate = filteredByCollectionStatus?.filter(data => {
        if (selectedReqCollectionDates.length < 1) return data;
        if (selectedReqCollectionDates.includes(data.requested_collection_date)) return data;
    })

    const filteredByPoNo = filteredByReqCollectionDate?.filter(data => {
        if (searchPurchaseOrderNo === null || searchPurchaseOrderNo === '') return data;
        if (data.po_number?.toLocaleLowerCase().includes(searchPurchaseOrderNo.toLocaleLowerCase())) return data;
    })

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <div className='flex flex-col justify-between'>
                    <div className='p-5 w-full mb-5'>
                        <div className='pb-5 bg-white mb-2 rounded-md'>

                            <div className='flex justify-start p-5 border-b items-center'>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('delivery_requests')}</p>
                            </div>


                            <div className='flex justify-between items-end lg:flex-row flex-col w-full border-b pb-5'>
                                <div className='px-5 pt-5 w-full'>
                                    <FormControl sx={{ width: 'inherit' }} focused={openDeliveryStatuses}>
                                        <InputLabel id="select-delivery-statuses-label">{t('search_by_status')}</InputLabel>
                                        <Select
                                            multiple
                                            id="select-delivery-statuses"
                                            labelId="select-delivery-statuses-label"
                                            open={openDeliveryStatuses}
                                            onOpen={() => setOpenDeliveryStatuses(true)}
                                            onClose={() => setOpenDeliveryStatuses(false)}
                                            onChange={handleChangeDeliveryStatusSelection}
                                            disabled={isLoading || shipments.length < 1}
                                            value={selectedDeliveryStatuses}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((x) => (
                                                        <Chip
                                                            key={`ds_option-${x}`}
                                                            label={x}
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                            onDelete={(e) => handleDeleteSelectionPrimitive(e, x, selectedDeliveryStatuses, setSelectedDeliveryStatuses, setOpenDeliveryStatuses)}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: (selectedDeliveryStatuses.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={selectedDeliveryStatuses ? (<IconButton sx={{ visibility: (selectedDeliveryStatuses.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={() => setSelectedDeliveryStatuses([])}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                deliveryStatuses?.length > 0 ?
                                                    <MenuItem value='all'>
                                                        <ListItemIcon>
                                                            <Checkbox checked={everyDeliveryStatusSelected} indeterminate={selectedDeliveryStatuses.length > 0 && selectedDeliveryStatuses.length < deliveryStatuses.length} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('select_all')} />
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                            }
                                            {
                                                deliveryStatuses?.map((status) => (
                                                    <MenuItem key={`ds-${status}`} value={status}>
                                                        <Checkbox checked={selectedDeliveryStatuses?.findIndex((i) => i === status) >= 0} />
                                                        <ListItemText primary={status} />
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='px-5 pt-5 w-full'>
                                    <FormControl sx={{ width: 'inherit' }} focused={openReqShipmentDates}>
                                        <InputLabel id="select-shipment-dates-label">{t('search_by_request_delivery_date')}</InputLabel>
                                        <Select
                                            multiple
                                            id="select-shipment-dates"
                                            labelId="select-shipment-dates-label"
                                            open={openReqShipmentDates}
                                            onOpen={() => setOpenReqShipmentDates(true)}
                                            onClose={() => setOpenReqShipmentDates(false)}
                                            onChange={handleChangeReqShipmentDateSelection}
                                            disabled={isLoading || shipments.length < 1}
                                            value={selectedReqShipmentDates}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((x) => (
                                                        <Chip
                                                            key={`rsd_option-${x}`}
                                                            label={x}
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                            onDelete={(e) => handleDeleteSelectionPrimitive(e, x, selectedReqShipmentDates, setSelectedReqShipmentDates, setOpenReqShipmentDates)}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: (selectedReqShipmentDates.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={selectedReqShipmentDates ? (<IconButton sx={{ visibility: (selectedReqShipmentDates.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={() => setSelectedReqShipmentDates([])}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                reqShipmentDates?.length > 0 ?
                                                    < MenuItem value='all'>
                                                        <ListItemIcon>
                                                            <Checkbox checked={everyReqShipmentDateSelected} indeterminate={selectedReqShipmentDates.length > 0 && selectedReqShipmentDates.length < reqShipmentDates.length} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('select_all')} />
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                            }

                                            {
                                                reqShipmentDates?.map((date) => (
                                                    <MenuItem key={`rsd-${date}`} value={date}>
                                                        <Checkbox checked={selectedReqShipmentDates?.findIndex((i) => i === date) >= 0} />
                                                        <ListItemText primary={date} />
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='px-5 pt-5 w-full'>
                                    <div className='flex justify-between items-center search'>
                                        <input type="text" placeholder={t('search_by_sales_no')} disabled={isLoading || shipments.length < 1} value={searchSalesOrderNo} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSearchSalesOrderNo(e.target.value)} />
                                        <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                    </div>
                                </div>

                            </div>
                            <div className='pt-3'>
                                <ShipmentsRequestTable items={filteredBySoNo} setIsLoading={setIsLoading} config={config} getShipmentRequests={getShipmentRequests} />
                            </div>
                        </div>

                    </div>

                    <div className='p-5 w-full'>
                        <div className='pb-5 bg-white mb-2 rounded-md'>

                            <div className='flex justify-start gap-4 items-center p-5 border-b'>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('collection_requests')}</p>
                            </div>

                            <div className='flex justify-between items-end lg:flex-row flex-col w-full pb-5 border-b'>
                                <div className='px-5 pt-5 w-full'>
                                    <FormControl sx={{ width: 'inherit' }} focused={openCollectionStatuses}>
                                        <InputLabel id="select-collection-statuses-label">{t('search_by_status')}</InputLabel>
                                        <Select
                                            multiple
                                            id="select-collection-statuses"
                                            labelId="select-collection-statuses-label"
                                            open={openCollectionStatuses}
                                            onOpen={() => setOpenCollectionStatuses(true)}
                                            onClose={() => setOpenCollectionStatuses(false)}
                                            onChange={handleChangeCollectionStatusSelection}
                                            disabled={isLoading || collections.length < 1}
                                            value={selectedCollectionStatuses}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((x) => (
                                                        <Chip
                                                            key={`cs_option-${x}`}
                                                            label={x}
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                            onDelete={(e) => handleDeleteSelectionPrimitive(e, x, selectedCollectionStatuses, setSelectedCollectionStatuses, setOpenCollectionStatuses)}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px', ".MuiSelect-iconOutlined": { display: (selectedCollectionStatuses.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' } }}

                                            endAdornment={selectedCollectionStatuses ? (<IconButton sx={{ visibility: (selectedCollectionStatuses.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={() => setSelectedCollectionStatuses([])}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                collectionStatuses?.length > 0 ?
                                                    <MenuItem value='all'>
                                                        <ListItemIcon>
                                                            <Checkbox checked={everyCollectionStatusSelected} indeterminate={selectedCollectionStatuses.length > 0 && selectedCollectionStatuses.length < collectionStatuses.length} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('select_all')} />
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                            }

                                            {
                                                collectionStatuses?.map((status) => (
                                                    <MenuItem key={`cs-${status}`} value={status}>
                                                        <Checkbox checked={selectedCollectionStatuses?.findIndex((i) => i === status) >= 0} />
                                                        <ListItemText primary={status} />
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='px-5 pt-5 w-full'>
                                    <FormControl sx={{ width: 'inherit' }} focused={openReqCollectionDates}>
                                        <InputLabel id="select-collection-dates-label">{t('search_by_request_collection_date')}</InputLabel>
                                        <Select
                                            multiple
                                            id="select-collection-dates"
                                            labelId="select-collection-dates-label"
                                            open={openReqCollectionDates}
                                            onOpen={() => setOpenReqCollectionDates(true)}
                                            onClose={() => setOpenReqCollectionDates(false)}
                                            onChange={handleChangeReqCollectionDateSelection}
                                            disabled={isLoading || collections.length < 1}
                                            value={selectedReqCollectionDates}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((x) => (
                                                        <Chip
                                                            key={`rcd_option-${x}`}
                                                            label={x}
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                            onDelete={(e) => handleDeleteSelectionPrimitive(e, x, selectedReqCollectionDates, setSelectedReqCollectionDates, setOpenReqCollectionDates)}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: (selectedReqCollectionDates.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={selectedReqCollectionDates ? (<IconButton sx={{ visibility: (selectedReqCollectionDates.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={() => setSelectedReqCollectionDates([])}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                reqCollectionDates?.length > 0 ?
                                                    <MenuItem value='all'>
                                                        <ListItemIcon>
                                                            <Checkbox checked={everyReqCollectionDateSelected} indeterminate={selectedReqCollectionDates.length > 0 && selectedReqCollectionDates.length < reqCollectionDates.length} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('select_all')} />
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                            }

                                            {
                                                reqCollectionDates?.map((date) => (
                                                    <MenuItem key={`rcd-${date}`} value={date}>
                                                        <Checkbox checked={selectedReqCollectionDates?.findIndex((i) => i === date) >= 0} />
                                                        <ListItemText primary={date} />
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='px-5 pt-5 w-full'>
                                    <div className='flex justify-between items-center search'>
                                        <input type="text" placeholder={t('search_by_purchase_no')} disabled={isLoading || collections.length < 1} value={searchPurchaseOrderNo} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSearchPurchaseOrderNo(e.target.value)} />
                                        <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-3'>
                                <CollectionRequestTable items={filteredByPoNo} setIsLoading={setIsLoading} config={config} getCollectionRequests={getCollectionRequests} />
                            </div>
                        </div>
                    </div>
                </div>
            </AppLayout >
        </>
    )
}

export default MyRequests
