import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { TextField } from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import { isValid } from '../../helpers/helper';

const OrderInfo = ({ order, order_id, unloadedDate, setUnloadedDate }) => {
    const { t } = useTranslation()
    const [pickUpNo, setPickUpNo] = useState('')
    const [dropNo, setDropNo] = useState('')
    const [forklifter, setForklifter] = useState('')

    useEffect(() => {
        if (order) {
            setPickUpNo(order.pickup_trailer_no || '')
            setDropNo(order.drop_trailer_no || '')
            setForklifter(order.forklift_driver_name || '')
            if(isValid(order.unloaded_date)) {
                setUnloadedDate(dayjs(order.unloaded_date))
            }
        }
    }, [order])

    const handleChangeUnloadDate = (newValue) => {
        setUnloadedDate(newValue)
        sessionStorage.setItem(`unloadedDate-${order_id}`, newValue)
    }

    return (
        <div className='p-5'>
            <div className='border border-gray-300 w-full h-fit p-10 flex flex-col md:flex-row justify-center gap-12 md:gap-48'>
                <div className='roboto relative px-5'>
                    <p className='text-[#9b9b9b] pb-2'>{t('vendor')}</p>
                    <p className='pb-1'>{order?.vendor_name}</p>
                    <p className='pb-1'>{order?.address}</p>
                    <p className='pb-1'>{order?.city}, {order?.state}, {order?.zip} </p>
                    <div className='absolute top-[32px] left-0'><i className="fa-solid fa-location-dot text-[#b7472a]"></i></div>
                </div>
                <div className=''>
                    <div className='flex gap-2 roboto items-center pb-2'>
                        <p className='text-[#9b9b9b] w-[220px]'>{t('pickup_trailer_no')}</p>
                        <TextField type="text" disabled variant='standard' className='w-full' value={pickUpNo} onChange={e => setPickUpNo(e.target.value)} />
                    </div>
                    <div className='flex gap-2 roboto items-center pb-2'>
                        <p className='text-[#9b9b9b] w-[220px]'>{t('drop_trailer_no')}</p>
                        <TextField type="text" disabled variant='standard' className='w-full' value={dropNo} onChange={e => setDropNo(e.target.value)} />
                    </div>
                    <div className='flex gap-2 roboto items-center pb-2'>
                        <p className='text-[#9b9b9b] w-[220px]'>{t('forklift_driver')}:</p>
                        <TextField type="text" disabled variant='standard' className='w-full' value={forklifter} onChange={e => setForklifter(e.target.value)} />
                    </div>
                    <div className='flex gap-2 roboto items-center pb-2'>
                        <p className='text-[#9b9b9b] w-[220px]'>{t('unload_date')}:</p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker sx={{ width: '100%' }} format="YYYY-MM-DD" slotProps={{ textField: { variant: 'standard' } }} value={unloadedDate} onChange={(newValue) => handleChangeUnloadDate(newValue)} />
                        </LocalizationProvider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderInfo
