import { Box } from "@mui/material"
import React from "react"

const SignatureModalBox = React.forwardRef((props, ref) => {
    return (
        <Box
            tabIndex={-1}
            ref={ref}
            sx={style}
        >
            {props.children}
        </Box>
    )
})

export default SignatureModalBox

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '460px',
    maxWidth: '90vw',
    height: 'fit-content',
    bgcolor: '#ffffff',
    border: 'transparent',
    borderRadius: '5px',
    boxShadow: 24,
    zIndex: "1600",
    outline: 'none'
}
