import { buildUrl } from "../../helpers/apiHelper"
import axios from "../../lib/axios"

const getSites = async (
    config,
    options = {
        with: [],
        where: {}
    }
) => {
    const baseUrl = '/api/sites'

    return await axios
        .get(buildUrl(baseUrl, options), config)
        .then(({ data }) => data)
        .catch(({ response }) => {
            console.error(response)
            return []
        })
}

const siteAPI = {
    getSites
}

export default siteAPI
