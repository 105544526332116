import React, { useEffect, useState } from 'react'

import axios from '../../lib/axios'
import Swal from 'sweetalert2'
import InputError from '../../components/InputError'
import { Box, InputAdornment, TextField } from '@mui/material'
import { useTranslation } from "react-i18next"
import { useStateContext } from '../../context/ContextProvider'
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom'
import Loading from '../../components/Loading'
import { isEqual, isNil } from 'lodash'
import { handleAxiosError } from '../../helpers/helper'

const ChangePassword = () => {

    const fallbackPrevLocation = '/users'

    const { t } = useTranslation()
    const { config } = useStateContext()
    const params = useParams()

    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [typePassword, setTypePassword] = useState('password')
    const [typePasswordConfirm, setTypePasswordConfirm] = useState('password')
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                handleCloseView()
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    const changePassword = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        const formData = {}
        formData['password'] = password
        formData['password_confirmation'] = passwordConfirmation

        await axios
            .put(`/api/users/${params.id}/change-password`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    /* text: t('x_password_successfully_changed', {x: data.success.user_role}) */
                    text: t('changed_password')
                })
                navigate(-1)
                setPassword('')
                setPasswordConfirmation('')
                setErrors([])
            })
            .catch(({ response }) => {
                handleAxiosError({response: response, showDescription: true})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleCloseView = () => {
        if(location.key !== 'default') {
            navigate(-1)
        } else {
            navigate(!isNil(location.state?.prevPathname) ? location.state.prevPathname : fallbackPrevLocation)
        }
    }

    return (
        <>
            {isLoading ? <Loading /> : null}
            <Box>
                <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t('change_password')}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <button onClick={handleCloseView}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-white h-full'>
                    <div className='bg-white p-6 w-full h-fit'>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('password')} *</p>
                            <div className='w-full'>
                                <TextField
                                    id="new-password"
                                    name="new-password"
                                    className='w-full'
                                    sx={{
                                        width: '100%',
                                        background: '#FCFCFC',
                                        '.MuiOutlinedInput-input': {
                                            padding: '9px !important'
                                        },
                                        boxShadow: 'none',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            border: '1px solid #EEEFF2 !important'
                                        }
                                    }}
                                    type={typePassword}
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <div className='cursor-pointer' onClick={() => setTypePassword(typePassword == 'text' ? 'password' : 'text')}><i className={`fa-regular ${isEqual(typePassword, 'password') ? 'fa-eye' : 'fa-eye-slash'}`}></i></div>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('confirm_password')} *</p>
                            <div className='w-full'>
                                <TextField
                                    id="confirm-new-password"
                                    name="confirm-new-password"
                                    className='w-full'
                                    sx={{
                                        width: '100%',
                                        background: '#FCFCFC',
                                        '.MuiOutlinedInput-input': {
                                            padding: '9px !important'
                                        },
                                        boxShadow: 'none',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            border: '1px solid #EEEFF2 !important'
                                        }
                                    }}
                                    type={typePasswordConfirm}
                                    value={passwordConfirmation}
                                    onChange={(e) => { setPasswordConfirmation(e.target.value) }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <div className='cursor-pointer' onClick={() => setTypePasswordConfirm(typePasswordConfirm == 'text' ? 'password' : 'text')}><i className={`fa-regular ${isEqual(typePasswordConfirm, 'password') ? 'fa-eye' : 'fa-eye-slash'}`}></i></div>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full flex justify-end px-6'>
                    <button type="button" onClick={changePassword} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#DBA34B] hover:bg-[#BD7503] disabled:bg-[#B8B7BC]'>
                        {t('change_password')}
                    </button>
                </div>

            </Box>
        </>
    )
}

export default ChangePassword
