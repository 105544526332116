import Navigation from './Navigation'
import { useAuth } from '../../hooks/auth'
import Footer from './Footer'
import SideBar, { SidebarDropdown, SidebarDropdownItem, SidebarItem, SidebarText, SiteSelection } from './SideBar'
import { useTranslation } from "react-i18next"
import { useStateContext } from '../../context/ContextProvider'
import Nav from './Nav'
import { lazy, useEffect } from 'react'
import { includes } from 'lodash'
import Swal from 'sweetalert2'

const AppLayout = ({ children, backgroundColor = "#F5F5F9", showFooter = true }) => {
    const { user } = useAuth({ middleware: 'auth' })
    const { extended, menuItems, hiddenDailyBoard, setHiddenDailyBoard, hiddenAdminView, setHiddenAdminView,
        hiddenUserManagment, setHiddenUserManagment, hiddenProduction, setHiddenProduction, hiddenInventory,
        setHiddenInventory, pusher } = useStateContext()

        useEffect(() => {
            if(includes(['forklift_driver', 'driver', 'production_supervisor', 'dispatcher'], user?.role)) {
                const userPermissionsUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-user-${user?.id}-permissions-updated`)

                userPermissionsUpdated.bind(`${localStorage.getItem('client_id')}-user-permissions-updated-event`, data => {
                    console.log(data)
                    Swal.fire({
                        icon: "info",
                        iconColor: '#a8d5f1',
                        customClass: 'info',
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                        text: "Your permissions have been updated!"
                    }).then((result) => {
                        window.location.reload()
                    })
                })
            }

            return (() => {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-user-${user?.id}-permissions-updated`)
            })
        }, [user])

    const { t } = useTranslation()

    useEffect(() => {
        menuItems.sort((a, b) => a.order - b.order)
    }, [menuItems])

    return (
        <div className="min-h-screen" style={{ backgroundColor: backgroundColor }}>

            {
                !includes(['tpl_driver', 'service_provider', 'customers_user'], user?.role) ?
                    window.innerWidth < 801 ?
                        <>
                            <Navigation user={user} />
                            <div className='w-full'>
                                <main className='w-full' style={{ paddingBottom: '60px' }}>{children}</main>
                            </div>
                        </>
                        :
                        <div className='flex'>
                            <SideBar user={user}>
                                {extended ? <> <hr /> <SiteSelection user={user} /> <hr className='pb-4' /> </> : null}
                                {
                                    menuItems.map((i, index) => (
                                        i.under == 'top' ?
                                            i.parent_id == null && i.type == 'link' ?
                                                <SidebarItem key={i + index} icon={<i className={i.icon}></i>} text={t(`${i.title}`)} route={i.url} angle={i.angle} />
                                                :
                                                i.type == 'dropdown' ?
                                                    <SidebarDropdown
                                                        hidden={i.title == 'daily_board' ?
                                                            hiddenDailyBoard :
                                                            i.title == 'production' ?
                                                                hiddenProduction :
                                                                i.title == 'inventory' ?
                                                                    hiddenInventory :
                                                                    i.title == 'master_data' ?
                                                                        hiddenUserManagment :
                                                                        i.title == 'admin_view' ?
                                                                            hiddenAdminView
                                                                            :
                                                                            null

                                                        }
                                                        setHidden={i.title == 'daily_board' ?
                                                            setHiddenDailyBoard :
                                                            i.title == 'production' ?
                                                                setHiddenProduction :
                                                                i.title == 'inventory' ?
                                                                    setHiddenInventory :
                                                                    i.title == 'master_data' ?
                                                                        setHiddenUserManagment :
                                                                        i.title == 'admin_view' ?
                                                                            setHiddenAdminView
                                                                            :
                                                                            null

                                                        }
                                                        key={i + index} text={t(`${i.title}`)} icon={<i className={i.icon}></i>}>
                                                        {
                                                            menuItems.map((e, index) => (
                                                                e.parent_id == i.id ?
                                                                    <SidebarDropdownItem key={e + index} icon={<i className={e.icon}></i>} text={t(`${e.title}`)} route={e.url} angle={e.angle} />
                                                                    :
                                                                    null
                                                            ))
                                                        }
                                                    </SidebarDropdown>
                                                    :
                                                    null
                                            :
                                            null
                                    ))
                                }


                                {/* GENERAL */}
                                {/* {!['master_admin', 'office_manager', 'production_supervisor'].includes(user?.role) ? null : <SidebarText text={t('general')} />} */}
                                {menuItems.some((menuItem) => menuItem.under === 'general') && <SidebarText text={t('general')} />}

                                {
                                    menuItems.map((i, index) => (
                                        i.under == 'general' ?
                                            i.parent_id == null && i.type == 'link' ?
                                                <SidebarItem key={i + index} icon={<i className={i.icon}></i>} text={t(`${i.title}`)} route={i.url} angle={i.angle} />
                                                :
                                                i.type == 'dropdown' ?
                                                    <SidebarDropdown
                                                    hidden={i.title == 'daily_board' ?
                                                        hiddenDailyBoard :
                                                        i.title == 'production' ?
                                                            hiddenProduction :
                                                            i.title == 'inventory' ?
                                                                hiddenInventory :
                                                                i.title == 'master_data' ?
                                                                    hiddenUserManagment :
                                                                    i.title == 'admin_view' ?
                                                                        hiddenAdminView
                                                                        :
                                                                        null

                                                    }
                                                    setHidden={i.title == 'daily_board' ?
                                                        setHiddenDailyBoard :
                                                        i.title == 'production' ?
                                                            setHiddenProduction :
                                                            i.title == 'inventory' ?
                                                                setHiddenInventory :
                                                                i.title == 'master_data' ?
                                                                    setHiddenUserManagment :
                                                                    i.title == 'admin_view' ?
                                                                        setHiddenAdminView
                                                                        :
                                                                        null

                                                    }
                                                    key={i + index} text={t(`${i.title}`)} icon={<i className={i.icon}></i>}>
                                                        {
                                                            menuItems.map((e, index) => (
                                                                e.parent_id == i.id ?
                                                                    <SidebarDropdownItem key={e + index} icon={<i className={e.icon}></i>} text={t(`${e.title}`)} route={e.url} angle={e.angle} />
                                                                    :
                                                                    null
                                                            ))
                                                        }
                                                    </SidebarDropdown>
                                                    :
                                                    null
                                            :
                                            null
                                    ))
                                }

                            </SideBar>

                            <div style={{ width: `calc(100% - ${extended ? '285' : '69'}px)` }}>
                                <Nav user={user} />
                                <main className='w-full' style={{ paddingBottom: '60px' }}>{children}</main>
                            </div>

                        </div >
                    :
                    <>
                        <Navigation user={user} />
                        <div className='w-full pt-[20px]'>
                            <main className='w-full' style={{ paddingBottom: '60px' }}>{children}</main>
                        </div>
                    </>
            }

            {
                showFooter ? (
                    <Footer />
                ) : null
            }

        </div >
    )
}

export default AppLayout


