import React, { useState, useEffect } from 'react'

import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import update from 'immutability-helper'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { TouchBackend } from "react-dnd-touch-backend";

import AppLayout from '../../components/Layouts/AppLayout'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import Drivers from '../../components/dispatch/Drivers'
import ShipmentsTable from '../../components/dispatch/ShipmentsTable'
import CollectionsTable from '../../components/dispatch/CollectionsTable'
import Loading from '../../components/Loading'
import Swal from 'sweetalert2'
import { SALES_ORDER_STAUSES, PURCHASE_ORDER_STAUSES } from '../../constants/constants'
import { isValid } from '../../helpers/helper'
import { includes, isEmpty, isNull } from 'lodash'
import { Box } from '@mui/material'
import NavigationDispatch from '../../components/dispatch/NavigationDispatch'

const Dispach = () => {
    const { choosesite, setChoosesite, config, pusher } = useStateContext()
    const isMobile = window.innerWidth < 750;
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingDrivers, setIsLoadingDrivers] = useState(false)
    const [isLoadingNumber, setIsLoadingNumber] = useState(false)

    const [shipments, setShipments] = useState([])
    const [collections, setCollections] = useState([])
    const [drivers, setDrivers] = useState([])
    const [shipmentsLoading, setShipmentsLoading] = useState(false)
    const [collectionsLoading, setCollectionsLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [numberPage, setNumberPage] = useState(0)

    const [customerIds, setCustomerIds] = useState([])
    const [vendorIds, setVendorIds] = useState([])

    const handleChange = (event, value) => {
        setPage(value);
        getDrivers(value)
    }

    useEffect(() => {
        if(isValid(choosesite)) {
            getItems()
        }
    }, [choosesite])

    const getShipments = async () => {
        setShipmentsLoading(true)

        await axios
            .get(`/api/list-orders?order_type=sales&site_id=${localStorage.getItem('site')}&status_id=${SALES_ORDER_STAUSES['RELEASED']},${SALES_ORDER_STAUSES['ASSIGNED_TO_FORK']},${SALES_ORDER_STAUSES['LOADING']},${SALES_ORDER_STAUSES['LOADED']}&type=SHIPMENT,BROKERED`, config)
            .then(res => {
                const results = res.data?.data

                const cids = []
                results.forEach(shipment => {
                    const cid = shipment?.customer_id
                    if(cids.indexOf(cid) === -1) {
                        cids.push(cid)
                    }
                });
                setCustomerIds(cids)

                setShipments(results)
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setShipmentsLoading(false)
            })
    }

    const getCollections = async () => {
        setCollectionsLoading(true)

        await axios
            .get(`/api/list-orders?order_type=purchase&site_id=${localStorage.getItem('site')}&status_id=${PURCHASE_ORDER_STAUSES['RELEASED']}&type=COLLECTION`, config)
            .then(res => {
                const results = res.data?.data

                const vids = []
                results.forEach(collection => {
                    const vid = collection?.vendor_id
                    if(vids.indexOf(vid) === -1) {
                        vids.push(vid)
                    }
                });
                setVendorIds(vids)

                setCollections(results)
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setCollectionsLoading(false)
            })
    }

    const getItems = async (withShipments = true, withCollections = true) => {
        setIsLoading(true)

        if(withShipments) {
            getShipments()
        }
        if(withCollections) {
            getCollections()
        }
        await getNumber()
            .then(() => {
                return getDrivers()
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }



    const getDrivers = async (page) => {
        setIsLoadingDrivers(true)
        await axios.get(`/api/users?role=driver&site_id=${localStorage.getItem('site')}&page_size=6&page=${page}`, config) /* &page_size=6&page=${page} */
            .then(res => {
                const drivers = res.data.data
                setDrivers(drivers)
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoadingDrivers(false)
            })
    }

    const getNumber = async () => {
        setIsLoadingNumber(true)
        await axios.get(`/api/users?role=driver&site_id=${localStorage.getItem('site')}`, config)
            .then(res => {
                const drivers = res.data.data
                setNumberPage(drivers?.length)
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoadingNumber(false)
            })
    }


    useEffect(() => {

        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('site')}`)

        const channelupdatePurchase = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)
        const channeldeletePurchase = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('site')}`)

        channelupdate.bind(`${localStorage.getItem('client_id')}-salesorder-updated-event-site-${localStorage.getItem('site')}`, data => {
            if(includes(['SHIPMENT', 'BROKERED'], data?.order_type)) {
                getSalesOrder(data.id)
            }
        })
        channeldelete.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${localStorage.getItem('site')}`, data => {
            setShipments((prev) => {
                const item = prev.find((i) => i.id === data.id)
                const exItem = prev.filter((i) => i.id !== item.id)
                return exItem
            })
        })
        channelupdatePurchase.bind(`${localStorage.getItem('client_id')}-purchaseorder-updated-event-site-${localStorage.getItem('site')}`, data => {
            if(data?.order_type === "COLLECTION") {
                getPurchaseOrder(data.id)
            }
        })
        channeldeletePurchase.bind(`${localStorage.getItem('client_id')}-purchaseorder-deleted-event-site-${localStorage.getItem('site')}`, data => {
            setCollections((prev) => {
                const item = prev.find((i) => i.id === data.id)
                const exItem = prev.filter((i) => i.id !== item.id)
                return exItem
            })
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)
        })
    }, [])

    useEffect(() => {
        if(!isEmpty(customerIds)) {
            const channelShipLocationUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)

            channelShipLocationUpdated.bind(`${localStorage.getItem('client_id')}-shipaddress-updated-event`, data => {
                if(includes(customerIds, data?.customer_id)) {
                    getShipments()
                    /* getItems(true, false) */
                }
            })
        }

        return (() => {
            if(!isEmpty(customerIds)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)
            }
        })
    }, [customerIds])

    useEffect(() => {
        if(!isEmpty(vendorIds)) {
            const channelPurchaseLocationUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)

            channelPurchaseLocationUpdated.bind(`${localStorage.getItem('client_id')}-purchaseaddress-updated-event`, data => {
                if(includes(vendorIds, data?.vendor_id)) {
                    getCollections()
                    /* getItems(false, true) */
                }
            })
        }

        return (() => {
            if(!isEmpty(vendorIds)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)
            }
        })
    }, [vendorIds])

    const getSalesOrder = async (id) => {
        setShipmentsLoading(true)

        await axios.get(`/api/sales-orders/${id}`, config)
            .then(res => {
                const salesOrder = res.data
                if(includes(['SHIPMENT', 'BROKERED'], salesOrder?.type)) {
                    if (!includes(["loaded", "released", "assigned_to_fork", "loading"], salesOrder.so_status)) {
                        setShipments((prev) => {
                            const updatedShipment = prev.find((i) => i.id === id)
                            if (updatedShipment !== undefined) {
                                const shipmentsUpdated = prev.filter((i) => i.id !== updatedShipment.id)
                                return shipmentsUpdated
                            }
                            return prev
                        })
                    } else {
                        setShipments((prev) => {
                            const index = prev?.findIndex((i) => i.id === id)
                            if (index < 0) {
                                const items = [...prev, salesOrder]
                                return items
                            } else {
                                return update(prev,
                                    { [index]: { $set: salesOrder } }
                                )
                            }
                        })
                    }
                }
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setShipmentsLoading(false)
            })
    }

    const getPurchaseOrder = async (id) => {
        setCollectionsLoading(true)

        await axios.get(`/api/purchase-orders/${id}`, config)
            .then(res => {
                const purchaseOrder = res.data
                if(purchaseOrder?.type === "COLLECTION") {
                    if (purchaseOrder.po_status !== "released") {
                        setCollections((prev) => {
                            const updatedCollection = prev.find((i) => i.id === id)
                            if (updatedCollection !== undefined) {
                                const collectionsUpdated = prev.filter((i) => i.id !== updatedCollection.id)
                                return collectionsUpdated
                            }
                            return prev
                        })
                    } else {
                        setCollections((prev) => {
                            const index = prev?.findIndex((i) => i.id === id)
                            if (index < 0) {
                                const items = [...prev, purchaseOrder]
                                return items
                            } else {
                                return update(prev,
                                    { [index]: { $set: purchaseOrder } }
                                )
                            }
                        })
                    }
                }
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setCollectionsLoading(false)
            })
        }

    return (
        <>
            {/* {isLoading ? <Loading /> : null} */}
            <AppLayout>
                <NavigationDispatch route='dispatch' type='list' />


                <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                    <div className='flex gap-5 p-5 max-[1000px]:flex-col'>
                        <div /* className='w-full lg:w-1/2' */ className='w-1/2'>
                            <ShipmentsTable items={shipments.filter((item) => item.driver_id === null)} setIsLoading={setShipmentsLoading} isLoading={shipmentsLoading} getShipments={getShipments} />
                            <CollectionsTable items={collections.filter((item) => item.driver_id === null)} setIsLoading={setCollectionsLoading} isLoading={collectionsLoading} getCollections={getCollections} />
                        </div>
                        <div /* className='w-full lg:w-1/2' */ className='w-1/2'>
                            {(isLoadingDrivers || isLoadingNumber) ? (
                                <Box position='relative' width='100%' height='100%'>
                                    <Loading position='absolute' />
                                </Box>
                            ) : (
                                <>
                                    <div className='flex gap-2 flex-wrap mb-10'>
                                        {
                                            drivers.map((item, index) => <Drivers shipments={shipments} collections={collections} id={item.id} driver={item} key={item.id} index={index} getShipments={getShipments} getCollections={getCollections} />)
                                        }
                                    </div>
                                    <div className='w-full flex justify-end'>
                                        <Stack spacing={2}>
                                            <Pagination count={Math.ceil(numberPage / 6)} page={page} onChange={handleChange} />
                                        </Stack>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </DndProvider>
            </AppLayout>
        </>
    )
}

export default Dispach
