import { Modal, Box, IconButton, Typography, Stack, Chip } from "@mui/material";
import HistoryIcon from '@mui/icons-material/History'
import ClearIcon from "@mui/icons-material/Clear"
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useState } from "react";
import { lowerCase, isEmpty } from "lodash";

const CommentStackItem = ({commented_at, commenter, content, count, index}) => {
    const formatedDatetime = dayjs(commented_at).format('MM/DD/YYYY - HH:mm A')

    const num = count - index

    return (
        <Stack spacing={1}>
            <Stack
                direction='row'
                alignItems='center'
                spacing={1}
                sx={{
                    color: '#A6A6A6',
                    fontSize: '11px',
                    fontWeight: '400',
                    lineHeight: '16.5px',
                }}
            >
                <HistoryIcon
                    sx={{
                        color: 'inherit',
                        fontSize: 'inherit',
                        fontWeight: 'inherit',
                        lineHeight: 'inherit',
                    }}
                />
                <Typography
                    sx={{
                        color: 'inherit',
                        fontSize: 'inherit',
                        fontWeight: 'inherit',
                        lineHeight: 'inherit',
                    }}
                >
                    {formatedDatetime}
                </Typography>
            </Stack>

            <Box
                sx={{
                    marginLeft: '5px !important',
                    paddingTop: '14px',
                    paddingBottom: '14px',
                    borderLeft: num > 1 ? '0.5px solid #DEDEDE' : 'none'
                }}
            >
                <Stack spacing={2} sx={{paddingLeft: '14px !important'}}>
                    {/* Commenter */}
                    <Stack
                        direction='row'
                        alignItems='center'
                        spacing={1}
                    >
                        {/* Commenter Name */}
                        <Typography
                            sx={{
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '21px',
                                color: '#333333'
                            }}
                        >
                            {num}. {commenter?.name}
                        </Typography>

                        {/* Commenter Role */}
                        <Chip
                            label={commenter?.role_name}
                            size="small"
                            sx={{
                                fontSize: '10px',
                                color: '#A6A6A6',
                                backgroundColor: '#FAFAFA'
                            }}
                        />
                    </Stack>

                    <Typography
                            sx={{
                                fontWeight: '300',
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#333333'
                            }}
                        >
                            {content}
                        </Typography>
                </Stack>
            </Box>
        </Stack>
    )
}

const CommentHistoryModal = ({open, setOpen, comments = []}) => {
    const { t } = useTranslation()

    const [search, setSearch] = useState('')

    const filteredComments = comments.filter((comment => {
        const s = lowerCase(search)
        if(!isEmpty(s)) {
            const content = lowerCase(comment.content)
            const name = lowerCase(comment.user?.name)
            const role = lowerCase(comment.user?.role_name)

            return (
                content.includes(s)
                || name.includes(s)
                || role.includes(s)
            )
        } else {
            return true
        }
    }))

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box sx={style}>
                <Stack>
                    {/* Header */}
                    <div style={{ backgroundColor: 'transparent'}}>
                        <div className='flex items-center justify-between'>
                            <Typography
                                component='h6'
                                sx={{
                                    fontWeight: '600',
                                    fontSize: '18px',
                                    lineHeight: '24px',
                                    color: '#333333',
                                    textTransform: 'capitalize'
                                }}
                            >
                                {t('comment_history')}
                            </Typography>
                            <IconButton sx={{ color: '#333333' }} onClick={() => setOpen(false)}>
                                <ClearIcon />
                            </IconButton>
                        </div>
                    </div>

                    {/* Search */}
                    <div className='w-full mt-[24px]'>
                        <div className='flex gap-x-2.5 justify-between items-center search'>
                            <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                            <input type="text" placeholder={t('search')} value={search} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place roboto' onChange={(e) => setSearch(e.target.value)} />
                        </div>
                    </div>

                    {/* Comments */}
                    <Stack
                        spacing={1}
                        maxHeight='351px'
                        padding='21px'
                        overflow='scroll'
                        borderLeft='1px solid #FAFAFA'
                        borderRight='1px solid #FAFAFA'
                        borderBottom='1px solid #FAFAFA'
                    >
                        {
                            filteredComments?.map((comment, index) => (
                                <CommentStackItem
                                    commented_at={comment?.created_at}
                                    commenter={comment?.user}
                                    content={comment?.content}
                                    count={filteredComments.length}
                                    index={index}
                                    key={`comment-${comment?.id}-${index}`}
                                />
                            ))
                        }
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    )
}

export default CommentHistoryModal

const style = {
    padding: '21px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: "596px",
    height: 'fit-content',
    bgcolor: '#ffffff',
    border: 'transparent',
    borderRadius: '5px',
    boxShadow: 24,
    zIndex: "1600",
    outline: 'none'
}
