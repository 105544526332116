import React from 'react'

import { Modal, Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CallMadeIcon from '@mui/icons-material/CallMade'
import CallReceivedIcon from '@mui/icons-material/CallReceived'

const ViewOrder = ({ order, open, handleOpen }) => {

    const { t } = useTranslation()

    return (
        <Modal open={open} onClose={handleOpen}>
            <Box sx={style}>

                <div className='flex justify-between items-center p-5 w-full' style={{ backgroundColor: order.order_type === "SHIPMENTS" ? '#336195' : '#b7472a', borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-baseline justify-between w-full'>
                        <p className='text-[18px] font-[600] text-white'>{order.so_number || order.po_number} {order.customer_name || order.vendor_name}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-white">
                            <button onClick={handleOpen}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='p-5 text-[#718096]'>
                        <div className='pb-5'>
                            <p>{t('status')}: <span className='font-bold'>{order.so_status_name || order.po_status_name}</span></p>
                            <p>{t('address')}: <span className='font-bold'>{order.address}, {order.city}, {order.state}</span></p>
                            {
                                order.order_type === "SHIPMENTS" ?
                                    <>
                                        <p>{t('trailer_no')} : <span className='font-bold'>{order.drop_trailer_no}</span></p>
                                        <p>{t('customer_po_no')} : <span className='font-bold'>{order.customer_po_no}</span></p>
                                    </>
                                    :
                                    <>
                                        <p>{t('trailer_no')} : <span className='font-bold'>{order.pickup_trailer_no}</span></p>
                                        <p>{t('customer_bol')} : <span className='font-bold'>{order.customer_bol_no}</span></p>
                                    </>
                            }
                        </div>
                        <div className='pb-3'>
                            <hr />
                        </div>
                        <div className='pb-10'>
                            <p className='font-bold py-4'>{t('items')}</p>
                            {
                                order.order_type === "SHIPMENTS" ?
                                    order.sales_order_items.map((item) => (
                                        <div className='flex justify-between py-3 items-baseline gap-2' key={item.id}>
                                            <p className='w-[70%]'>{item.item_no}, {item.description}</p>
                                            <p className='w-[30%]'>{t('quantity_ordered')}: {item.qty_ordered}</p>
                                        </div>
                                    ))
                                    :
                                    order.purchase_order_items.map((item) => (
                                        <div className='flex justify-between py-3 items-baseline gap-2' key={item.id}>
                                            <p className='w-[70%]'>{item.item_no}, {item.description}</p>
                                            <p className='w-[30%]'>{t('quantity_ordered')}: {item.qty_ordered}</p>
                                        </div>
                                    ))
                            }
                        </div>
                        <div className='w-full flex gap-2 justify-between'>
                            <button type="button" onClick={handleOpen} className='w-full text-[14px] text-[#919399] rounded-sm px-4 py-2 bg-[#F3F5F7] hover:bg-[#E6E6E6] disabled:bg-[#B8B7BC] capitalize'>
                                {t('cancel')}
                            </button>
                            {/* <button type="button" className='w-full text-[14px] text-white rounded-sm px-4 py-2 bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC] capitalize'>
                                {t('learn_more')}
                            </button> */}
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default ViewOrder

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: "600px",
    height: 'fit-content',
    bgcolor: '#ffffff',
    border: 'transparent',
    borderRadius: '5px',
    boxShadow: 24,
    zIndex: "1600",
    outline: 'none'
}
