import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import update from 'immutability-helper'

import AppLayout from '../../components/Layouts/AppLayout'
import Loading from '../../components/Loading'
import RequestedShipments from '../../components/customer_portal/RequestedShipments'
import RequestedCollections from '../../components/customer_portal/RequestedCollections'
import axios from '../../lib/axios'
import { useStateContext } from "../../context/ContextProvider";
import { useAuth } from '../../hooks/auth'

const CustomerRequests = () => {

    const { t } = useTranslation()
    const { config, pusher, company_id } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })

    const [isLoading, setIsLoading] = useState(false)
    const [shipments, setShipments] = useState([])
    const [collections, setCollections] = useState([])

    useEffect(() => {
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-shipmentrequest-deleted-company-${localStorage.getItem('company_id')}`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-shipmentrequest-created-company-${localStorage.getItem('company_id')}`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-shipmentrequest-updated-company-${localStorage.getItem('company_id')}`)

        const channeldelete1 = pusher.subscribe(`${localStorage.getItem('client_id')}-collectionrequest-deleted-company-${localStorage.getItem('company_id')}`)
        const channelcreate1 = pusher.subscribe(`${localStorage.getItem('client_id')}-collectionrequest-created-company-${localStorage.getItem('company_id')}`)
        const channelupdate1 = pusher.subscribe(`${localStorage.getItem('client_id')}-collectionrequest-updated-company-${localStorage.getItem('company_id')}`)

        if (company_id) {
            channeldelete.bind(`${localStorage.getItem('client_id')}-shipmentrequest-deleted-event-company-${localStorage.getItem('company_id')}`, data => {
                deleteShipmentRequest(data.id)
            })

            channelcreate.bind(`${localStorage.getItem('client_id')}-shipmentrequest-created-event-company-${localStorage.getItem('company_id')}`, data => {
                getShippingRequest(data.id, 'created')
            })

            channelupdate.bind(`${localStorage.getItem('client_id')}-shipmentrequest-updated-event-company-${localStorage.getItem('company_id')}`, data => {
                getShipments(data.company_id)
            })

            channeldelete1.bind(`${localStorage.getItem('client_id')}-collectionrequest-deleted-event-company-${localStorage.getItem('company_id')}`, data => {
                deleteCollectionRequest(data.id);
            })

            channelcreate1.bind(`${localStorage.getItem('client_id')}-collectionrequest-created-event-company-${localStorage.getItem('company_id')}`, data => {
                getCollectionRequest(data.id, 'created')
            })

            channelupdate1.bind(`${localStorage.getItem('client_id')}-collectionrequest-updated-event-company-${localStorage.getItem('company_id')}`, data => {
                getCollections(data.company_id)
            })
        }

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipmentrequest-deleted-company-${company_id}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipmentrequest-created-company-${company_id}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipmentrequest-updated-company-${company_id}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-collectionrequest-deleted-company-${company_id}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-collectionrequest-created-company-${company_id}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-collectionrequest-updated-company-${company_id}`)
        })
    }, [company_id])

    useEffect(() => {
        if (company_id) {
            getShipments(company_id)
            getCollections(company_id)
        } else {
            getShipments(localStorage.getItem('company_id'))
            getCollections(localStorage.getItem('company_id'))
        }
    }, [company_id])

    const getShippingRequest = async (id, state) => {
        setIsLoading(true)
        await axios.get(`/api/shipment-requests/${id}`, config)
            .then(res => {
                const shipment = res.data
                if (state === 'created') setShipments((prev) => [...prev, shipment])
                setIsLoading(false)
            })
    }

    const getCollectionRequest = async (id, state) => {
        setIsLoading(true)
        await axios.get(`/api/collection-requests/${id}`, config)
            .then(res => {
                const collection = res.data
                if (state === 'created') setCollections((prev) => [...prev, collection])
                setIsLoading(false)
            })
    }


    const getShipments = async (cid) => {
        let url = `shipment-requests?customer_id=${user?.customer_id}&status=pending`

        if (user?.role === 'master_admin' || user?.role === 'office_manager') {
            url = `shipment-requests?company_id=${cid}&status=pending`
        }

        setIsLoading(true)
        await axios.get(`/api/${url}`, config)
            .then(res => {
                const orders = res.data
                setShipments(orders)
                setIsLoading(false)
            })
    }

    const getCollections = async (cid) => {
        let url = `collection-requests?customer_id=${user?.customer_id}&status=pending`

        if (user?.role === 'master_admin' || user?.role === 'office_manager') {
            url = `collection-requests?company_id=${cid}&status=pending`
        }
        setIsLoading(true)
        await axios.get(`/api/${url}`, config)
            .then(res => {
                const orders = res.data
                setCollections(orders)
                setIsLoading(false)
            })
    }

    const deleteShipmentRequest = (id) => {
        setShipments((prev) => {
            const item = prev.find((i) => i.id === id)
            const exItem = prev.filter((i) => i.id !== item.id)
            return exItem
        })
    }

    const deleteCollectionRequest = (id) => {
        setCollections((prev) => {
            const item = prev.find((i) => i.id === id)
            const exItem = prev.filter((i) => i.id !== item.id)
            return exItem
        })
    }

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <div className='p-5 pt-0 w-full'>
                    <div className=' bg-white mb-2 rounded-md'>
                        <div className='p-5 border-b'>
                            <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('requested_shipments')}</p>
                        </div>
                        <div>
                            <RequestedShipments items={shipments} />
                        </div>

                    </div>
                </div>

                <div className='p-5 w-full'>
                    <div className=' bg-white mb-2 rounded-md'>
                        <div className='p-5 border-b'>
                            <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('requested_collections')}</p>
                        </div>
                        <div>
                            <RequestedCollections items={collections} />
                        </div>
                    </div>
                </div>
            </AppLayout>
        </>
    )
}

export default CustomerRequests
