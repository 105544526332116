import React, { useState, useEffect } from "react"
import axios from "../../lib/axios"
import { useAuth } from "../../hooks/auth"
import { useTranslation } from "react-i18next"
import { useStateContext } from "../../context/ContextProvider"
import Loading from "../../components/Loading"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import AppLayout from "../../components/Layouts/AppLayout"
import { Box, IconButton, Stack, Typography, Tooltip, Card, CardContent, FormControlLabel, Checkbox, FormControl, FormLabel, FormGroup } from "@mui/material"
import ActiveShipments from "../../components/admin_dashboard/ActiveShipments"
import ActiveCollections from "../../components/admin_dashboard/ActiveCollections"
import ShippedItemsTotal from "../../components/admin_dashboard/ShippedItemsTotal"
import CollectedItemsTotal from "../../components/admin_dashboard/CollectedItemsTotal"
import SalesRevenue from "../../components/admin_dashboard/SalesRevenue"
import { isNil, includes, isEmpty, lowerCase } from "lodash"
import TopCustomersByRevenueXChart from "../../components/admin_dashboard/charts/TopCustomersByRevenueXChart"
import SalesGrowthPerMonthXChart from "../../components/admin_dashboard/charts/SalesGrowthPerMonthXChart"
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack';
import ClearIcon from "@mui/icons-material/Clear"
import { isValid } from "../../helpers/helper"
import InventoryStatusScrollableRow from "../../components/admin_dashboard/InventoryStatusScrollableRow"
import { Link } from "react-router-dom"

const SectionHeader = ({
    headerText = 'Header Text',
    subheaderText = '',
    sx = null,
    showCollapseButton = false,
    collapseSection = false,
    toggleCollapseSection = () => {}
}) => {
    return (
        <Stack sx={sx} direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' alignItems='center' spacing={1}>
                <Typography component='h6' sx={{ fontSize: '16px', lineHeight: '20px', textTransform: 'uppercase', fontWeight: '500', color: '#716a6d' }}>{headerText}</Typography>
                {!isEmpty(subheaderText) ? (
                    <Typography fontWeight='300' fontSize='16px' lineHeight='20px' letterSpacing='0.3px' component="div">
                        <em>{subheaderText}</em>
                    </Typography>
                ) : (
                    ''
                )}
            </Stack>
            {showCollapseButton ? (
                <IconButton onClick={toggleCollapseSection}>
                    <i className={`transition duration-150 ease-in-out fa-solid fa-angle-up ${collapseSection ? 'rotate-180' : 'rotate-0'}`}></i>
                </IconButton>
            ) : (
                ''
            )}
        </Stack>
    )
}

const AdminDashboard = () => {
    /* Hooks */
    const { user } = useAuth({ middleware: 'guest ' })
    const { t } = useTranslation()
    const { config, choosesite, company_id, pusher, menuItems, clientIsBroker, clientIsBrokerOnly } = useStateContext()

    /* States */
    const [isLoading, setIsLoading] = useState(true)
    const [loadingProgress, setLoadingProgress] = useState(0)

    const [isUpdatingActiveShipments, setIsUpdatingActiveShipments] = useState(false)
    const [isUpdatingActiveCollections, setIsUpdatingActiveCollections] = useState(false)
    const [isUpdatingShippedItems, setIsUpdatingShippedItems] = useState(false)
    const [isUpdatingCollectedItems, setIsUpdatingCollectedItems] = useState(false)
    const [isUpdatingSalesRevenue, setIsUpdatingSalesRevenue] = useState(false)
    const [isUpdatingTopCustomers, setIsUpdatingTopCustomers] = useState(false)
    const [isUpdatingSalesGrowth, setIsUpdatingSalesGrowth] = useState(false)
    const [isUpdatingInventoryItems, setIsUpdatingInventoryItems] = useState(false)

    const [activeShipments, setActiveShipments] = useState([])
    const [activeCollections, setActiveCollections] = useState([])
    const [shippedItemsTotal, setShippedItemsTotal] = useState([])
    const [collectedItemsTotal, setCollectedItemsTotal] = useState([])
    const [salesRevenue, setSalesRevenue] = useState([])
    const [topCustomersByRevenue, setTopCustomersByRevenue] = useState([])
    const [salesGrowth, setSalesGrowth] = useState([])
    const [inventoryItems, setInventoryItems] = useState([])

    /* const [salesOrdersActive, setSalesOrdersActive] = useState(false) */
    const [purchaseOrdersActive, setPurchaseOrdersActive] = useState(false)
    const [itemsActive, setItemsActive] = useState(false)

    const [collapseGeneralInformationSection, setCollapseGeneralInformationSection] = useState(false)
    const [collapseSalesInformationSection, setCollapseSalesInformationSection] = useState(false)
    const [collapseInventoryStatusSection, setCollapseInventoryStatusSection] = useState(false)

    const [checkedItemTypes, setCheckedItemTypes] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.admin_dashboard_inventory_item_type || {
                pallet: false,
                lumber: false,
                component: false,
                all: true
            }
        } else {
            return {
                pallet: false,
                lumber: false,
                component: false,
                all: true
            }
        }
    })

    /* Effects */

    /* useEffect(() => {
        getAllData(localStorage.getItem('site'))

        subscribeToPusherBySite(localStorage.getItem('site'))

        return (() => {
            unsubscribeToPusherBySite(localStorage.getItem('site'))
        })
    }, []) */

    useEffect(() => {
        return (() => {
            unsubscribeToPusherBySite(localStorage.getItem('site'))
            unsubscribeToPusherByCompany(localStorage.getItem('company_id'))
        })
    }, [])

    useEffect(() => {
        if(menuItems.length > 0) {
            /* const salesOrdersMenu = menuItems.find((mi) => mi.url === '/sales-order') */
            const purchaseOrdersMenu = menuItems.find((mi) => mi.url === '/purchase-order')
            const itemsMenu = menuItems.find((mi) => mi.url === '/items')

            /* setSalesOrdersActive(salesOrdersMenu?.is_active == 1 ? true : false) */
            setPurchaseOrdersActive(purchaseOrdersMenu?.is_active == 1 ? true : false)
            setItemsActive(itemsMenu?.is_active == 1 ? true : false)
        }
    }, [menuItems])

    useEffect(() => {
        if (isValid(choosesite)) {
            getAllData(choosesite)

            subscribeToPusherBySite(choosesite)
        }

        return (() => {
            if (isValid(choosesite)) {
                unsubscribeToPusherBySite(choosesite)
            }
        })
    }, [choosesite])

    useEffect(() => {
        if (isValid(company_id)) {
            subscribeToPusherByCompany(company_id)
        }

        return (() => {
            if(isValid(company_id)) {
                unsubscribeToPusherByCompany(company_id)
            }
        })
    }, [company_id])

    /* Functions */

    const subscribeToPusherBySite = (siteId) => {
        /* Shipment Subscriptions */
        const channelShipmentCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${siteId}`)
        const channelShipmentDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${siteId}`)
        const channelShipmentStatusChange = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-status-changed-site-${siteId}`)

        /* Collection Subscriptions */
        const channelCollectionCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-created-site-${siteId}`)
        const channelCollectionDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${siteId}`)
        const channelCollectionStatusChange = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-status-changed-site-${siteId}`)

        /* Production Entry Subscription */
        const channelProductionEntryCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-productionentry-created-site-${siteId}`)
        const channelProductionEntryUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-productionentry-updated-site-${siteId}`)

        /* Shipment Binds */
        channelShipmentCreate.bind(`${localStorage.getItem('client_id')}-salesorder-created-event-site-${siteId}`, data => {
            if(lowerCase(data.order_type) == 'broker' && !clientIsBroker) {
                return
            }

            getData(
                choosesite,
                getActiveShipments
            )
        })
        channelShipmentDelete.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${siteId}`, data => {
            if(lowerCase(data.order_type) == 'broker' && !clientIsBroker) {
                return
            }

            getData(
                choosesite,
                getActiveShipments,
                getShippedItemsTotal,
                getSalesRevenue,
                getTopCustomersByRevenue,
                getSalesGrowth
            )
        })
        channelShipmentStatusChange.bind(`${localStorage.getItem('client_id')}-salesorder-status-changed`, data => {
            if(data.is_rejected) {
                getData(
                    choosesite,
                    getActiveShipments,
                )
            } else {
                /**
                 * This sort of assumes that an order has been undone because it compares the ID of the previous status and the current status.
                 * If the previous status is higher than the current one, then it is safe to assume that we are looking at an Undo.
                 */
                if(
                    data.prev_status_name == 'shipped'
                    && (data.prev_status_id > data.status_id)
                ) {
                    getData(
                        choosesite,
                        getActiveShipments,
                        getShippedItemsTotal,
                        getInventoryItems
                    )
                } else {
                    switch(data.status_name) {
                        case "shipped":
                            getData(
                                choosesite,
                                getActiveShipments,
                                getShippedItemsTotal,
                                getInventoryItems
                            )
                            break
                        case "completed":
                            if(lowerCase(data.order_subtype) == 'broker' && clientIsBroker) {
                                getData(
                                    choosesite,
                                    getActiveShipments,
                                    getShippedItemsTotal,
                                    getInventoryItems
                                )
                            }
                            break
                        default:
                    }
                }
            }
        })

        /* Collection Binds */
        channelCollectionCreate.bind(`${localStorage.getItem('client_id')}-purchaseorder-created-event-site-${siteId}`, data => {
            getData(
                choosesite,
                getActiveCollections
            )
        })
        channelCollectionDelete.bind(`${localStorage.getItem('client_id')}-purchaseorder-deleted-event-site-${siteId}`, data => {
            getData(
                choosesite,
                getActiveCollections,
                getCollectedItemsTotal,
            )
        })
        channelCollectionStatusChange.bind(`${localStorage.getItem('client_id')}-purchaseorder-status-changed`, data => {
            if(data.is_rejected) {
                getData(
                    choosesite,
                    getActiveCollections,
                )
            } else {
                /**
                 * This sort of assumes that an order has been undone because it compares the ID of the previous status and the current status.
                 * If the previous status is higher than the current one, then it is safe to assume that we are looking at an Undo.
                 */
                if(
                    data.prev_status_name == 'completed'
                    && (data.prev_status_id > data.status_id)
                ) {
                    getData(
                        choosesite,
                        getActiveCollections,
                        getCollectedItemsTotal,
                        getInventoryItems
                    )
                } else {
                    switch(data.status_name) {
                        case "completed":
                            getData(
                                choosesite,
                                getActiveCollections,
                                getCollectedItemsTotal,
                                getInventoryItems
                            )
                            break
                        default:
                    }
                }
            }
        })

        /* Production Entry Binds */
        channelProductionEntryCreate.bind(`${localStorage.getItem('client_id')}-productionentry-created-event`, data => {
            getData(
                choosesite,
                getInventoryItems
            )
        })
        channelProductionEntryUpdate.bind(`${localStorage.getItem('client_id')}-productionentry-updated-event`, data => {
            getData(
                choosesite,
                getInventoryItems
            )
        })
    }

    const subscribeToPusherByCompany = (companyId) => {
        /* Shipment Subscriptions */
        const channelShipmentStatusChangeCompany = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-status-changed-company-${companyId}`)

        /* Collection Subscriptions */
        const channelCollectionStatusChangeCompany = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-status-changed-company-${companyId}`)

        /* Inventory Item Subscriptions */
        const channelInventoryItemCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-inventoryitem-created-company-${companyId}`)
        const channelInventoryItemUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-inventoryitem-updated-company-${companyId}`)

        /* Shipment Binds */
        channelShipmentStatusChangeCompany.bind(`${localStorage.getItem('client_id')}-salesorder-status-changed`, data => {
            switch(data.status_name) {
                case "invoiced":
                    getData(
                        choosesite,
                        getActiveShipments,
                        getShippedItemsTotal,
                        getSalesRevenue,
                        getTopCustomersByRevenue,
                        getSalesGrowth,
                    )
                    break
                default:
            }
        })

        /* Collection Binds */
        channelCollectionStatusChangeCompany.bind(`${localStorage.getItem('client_id')}-purchaseorder-status-changed`, data => {
            switch(data.status_name) {
                case "invoiced":
                    getData(
                        choosesite,
                        getActiveCollections,
                        getCollectedItemsTotal
                    )
                    break
                default:
            }
        })

        /* Inventory Item Binds */
        channelInventoryItemUpdate.bind(`${localStorage.getItem('client_id')}-inventoryitem-updated-event`, data => {
            getData(
                choosesite,
                getInventoryItems
            )
        })

        channelInventoryItemCreate.bind(`${localStorage.getItem('client_id')}-inventoryitem-created-event`, data => {
            getData(
                choosesite,
                getInventoryItems
            )
        })
    }

    const unsubscribeToPusherBySite = (siteId) => {
        pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${siteId}`)
        pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${siteId}`)
        pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-status-changed-site-${siteId}`)
        pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-created-site-${siteId}`)
        pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${siteId}`)
        pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-status-changed-site-${siteId}`)
        pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionentry-created-site-${siteId}`)
        pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionentry-updated-site-${siteId}`)
    }

    const unsubscribeToPusherByCompany = (companyId) => {
        pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-status-changed-company-${companyId}`)
        pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-status-changed-company-${companyId}`)
        pusher.unsubscribe(`${localStorage.getItem('client_id')}-inventoryitem-updated-company-${companyId}`)
        pusher.unsubscribe(`${localStorage.getItem('client_id')}-inventoryitem-created-company-${companyId}`)
    }

    const handleChangeItemType = (e) => {
        setCheckedItemTypes({
            ...checkedItemTypes,
            [e.target.name]: e.target.checked
        })
    }

    const getActiveShipments = async (siteId) => {
        setIsUpdatingActiveShipments(true)

        await axios.get(`/api/active-shipments?site=${siteId}`, config)
            .then(res => {
                const data = res.data
                setActiveShipments(data)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('active_shipments')
                    })
                )
            })
            .finally(() => {
                setIsUpdatingActiveShipments(false)
            })
    }

    const getActiveCollections = async (siteId) => {
        setIsUpdatingActiveCollections(true)

        await axios.get(`/api/active-collections?site=${siteId}`, config)
            .then(res => {
                const data = res.data
                setActiveCollections(data)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('active_collections')
                    })
                )
            })
            .finally(() => {
                setIsUpdatingActiveCollections(false)
            })
    }

    const getShippedItemsTotal = async (siteId) => {
        setIsUpdatingShippedItems(true)

        await axios.get(`/api/total-shipped-items?site=${siteId}`, config)
            .then(res => {
                const data = res.data
                setShippedItemsTotal(data)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('total_no_shipped_items')
                    })
                )
            })
            .finally(() => {
                setIsUpdatingShippedItems(false)
            })
    }

    const getCollectedItemsTotal = async (siteId) => {
        setIsUpdatingCollectedItems(true)

        await axios.get(`/api/total-collected-items?site=${siteId}`, config)
            .then(res => {
                const data = res.data
                setCollectedItemsTotal(data)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('total_no_collected_items')
                    })
                )
            })
            .finally(() => {
                setIsUpdatingCollectedItems(false)
            })
    }

    const getSalesRevenue = async (siteId) => {
        setIsUpdatingSalesRevenue(true)

        await axios.get(`/api/sales-revenue?site=${siteId}`, config)
            .then(res => {
                const data = res.data
                setSalesRevenue(data)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('sales_revenue')
                    })
                )
            })
            .finally(() => {
                setIsUpdatingSalesRevenue(false)
            })
    }

    const getTopCustomersByRevenue = async (siteId) => {
        setIsUpdatingTopCustomers(true)

        await axios.get(`/api/top-customers-by-revenue?site=${siteId}`, config)
            .then(res => {
                const data = res.data
                setTopCustomersByRevenue(data)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('top_x_customers_by_revenue', {x: 10})
                    })
                )
            })
            .finally(() => {
                setIsUpdatingTopCustomers(false)
            })
    }

    const getSalesGrowth = async (siteId) => {
        setIsUpdatingSalesGrowth(true)

        await axios.get(`/api/sales-growth?site=${siteId}`, config)
            .then(res => {
                const data = res.data
                setSalesGrowth(data)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('sales_growth_per_x', {x: t('month')})
                    })
                )
            })
            .finally(() => {
                setIsUpdatingSalesGrowth(false)
            })
    }

    const getInventoryItems = async (siteId) => {
        setIsUpdatingInventoryItems(true)

        await axios.get(`/api/inventory-status?site=${siteId}`, config)
            .then(res => {
                const data = res.data
                setInventoryItems(data)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('inventory_status')
                    })
                )
            })
            .finally(() => {
                setIsUpdatingInventoryItems(false)
            })
    }

    // Will accept a variable number of functions. An alternative to getAllData wherein not all the data is required.
    const getData = (siteId = null, ...args) => {
        const functions = !isNil(choosesite)
            ? [
                ...args
            ] : []

        try {
            for (const func of functions) {
                func(siteId)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getDataAsync = async (siteId = null, ...args) => {
        const functions = !isNil(choosesite)
            ? [
                ...args
            ] : []

        try {
            for (const func of functions) {
                await func(siteId)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getAllData = async (siteId = null) => {
        setIsLoading(true)

        // List of functions to be executed (for high treason)
        const functions = !isNil(choosesite)
            ? [
                getTopCustomersByRevenue,
                getSalesGrowth,
                getSalesRevenue,
                getInventoryItems,
                getShippedItemsTotal,
                getCollectedItemsTotal,
                getActiveShipments,
                getActiveCollections,
            ]
            : []

        const functionCount = functions.length

        try {
            let counter = 0
            for (const func of functions) {
                await func(siteId)
                counter++
                setLoadingProgress(Math.round((counter / functionCount) * 100))
            }
        } catch (error) {
            console.log(error)
        } finally {
            setTimeout(() => {
                setIsLoading(false)
                setLoadingProgress(0)
            }, "500")
        }
    }

    const createFilterArray = (checkedItemTypes) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].admin_dashboard_inventory_item_type = checkedItemTypes

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    useEffect(() => {
        createFilterArray(checkedItemTypes)
    }, [checkedItemTypes])

    return (
        <>
            {isLoading ? (
                <Loading
                    progressConfig={{
                        variant: 'determinate',
                        value: loadingProgress
                    }}
                    withLabel
                />
            ) : (
                ''
            )}

            <SnackbarProvider
                maxSnack={3}
                /* preventDuplicate */
                autoHideDuration={12000}
                variant='error'
                action={(snackbarId) => (
                    <button onClick={() => closeSnackbar(snackbarId)}>
                        <ClearIcon />
                    </button>
                )}
            />

            <AppLayout backgroundColor="#f5f5f9">
                <Box className='p-5' sx={{ flexGrow: 1 }}>
                    <Stack spacing={2}>
                        {/* GENERAL INFORMATION */}
                        {/* (salesOrdersActive || purchaseOrdersActive) */ true &&
                            <>
                                <SectionHeader
                                    headerText={t('general_information')}
                                    showCollapseButton={true}
                                    collapseSection={collapseGeneralInformationSection}
                                    toggleCollapseSection={() => setCollapseGeneralInformationSection(!collapseGeneralInformationSection)}
                                />

                                <Box display={collapseGeneralInformationSection ? 'none' : 'inline'}>
                                    <Grid container spacing={2}>
                                        {/* Active Shipments */}
                                        {/* salesOrdersActive */ true &&
                                            <Grid xs={12} sm={6} md={3} lg>
                                                <ActiveShipments
                                                    data={activeShipments}
                                                    showActions
                                                    isLoading={isLoading}
                                                    isUpdating={!isLoading && isUpdatingActiveShipments}
                                                    clientIsBrokerOnly={clientIsBrokerOnly}
                                                />
                                            </Grid>
                                        }

                                        {/* Active Collections */}
                                        {purchaseOrdersActive &&
                                            <Grid xs={12} sm={6} md={3} lg>
                                                <ActiveCollections
                                                    data={activeCollections}
                                                    showActions
                                                    isLoading={isLoading}
                                                    isUpdating={!isLoading && isUpdatingActiveCollections}
                                                />
                                            </Grid>
                                        }


                                        {/* Total No. of Shipped Items */}
                                        {/* salesOrdersActive */ true &&
                                            <Grid xs={12} sm={6} md={3} lg>
                                                <ShippedItemsTotal
                                                    data={shippedItemsTotal}
                                                    isLoading={isLoading}
                                                    isUpdating={!isLoading && isUpdatingShippedItems}
                                                />
                                            </Grid>
                                        }

                                        {/* Total No. of Collected Items */}
                                        {purchaseOrdersActive &&
                                            <Grid xs={12} sm={6} md={3} lg>
                                                <CollectedItemsTotal
                                                    data={collectedItemsTotal}
                                                    isLoading={isLoading}
                                                    isUpdating={!isLoading && isUpdatingCollectedItems}
                                                />
                                            </Grid>
                                        }

                                        {/* Sales Revenue */}
                                        {/* salesOrdersActive */ true &&
                                            <Grid xs={12} lg>
                                                <SalesRevenue
                                                    data={salesRevenue}
                                                    isLoading={isLoading}
                                                    isUpdating={!isLoading && isUpdatingSalesRevenue}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                            </>
                        }

                        {/* SALES INFORMATION */}
                        {/* salesOrdersActive */ true &&
                            <>
                                <SectionHeader
                                    headerText={t('sales_information')}
                                    showCollapseButton={true}
                                    collapseSection={collapseSalesInformationSection}
                                    toggleCollapseSection={() => setCollapseSalesInformationSection(!collapseSalesInformationSection)}
                                />

                                <Box display={collapseSalesInformationSection ? 'none' : 'inline'}>
                                    <Grid container spacing={2}>
                                        {/* Top 10 Customers By Revenue */}
                                        <Grid xs={12} md={12} lg>
                                            <TopCustomersByRevenueXChart
                                                data={topCustomersByRevenue}
                                                /* showActions */
                                                isLoading={isLoading}
                                                isUpdating={!isLoading && isUpdatingTopCustomers}
                                            />
                                        </Grid>

                                        {/* Sales Growth Per Month */}
                                        <Grid xs={12} md={12} lg>
                                            <SalesGrowthPerMonthXChart
                                                data={salesGrowth}
                                                /* showActions */
                                                isLoading={isLoading}
                                                isUpdating={!isLoading && isUpdatingSalesGrowth}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        }

                        {/* INVENTORY STATUS */}
                        {!clientIsBrokerOnly ? (
                            <div>
                                <Stack direction='row' alignItems='center' spacing={1}>
                                    <SectionHeader
                                        headerText={t('inventory_status')}
                                        subheaderText={!isLoading && inventoryItems.length <= 0 ? t('no_inventory_items_defined') : ''}
                                        showCollapseButton={!isLoading && inventoryItems.length > 0}
                                        sx={{
                                            height: isLoading || (!isLoading && inventoryItems.length <= 0) ? '40px' : 'auto',
                                            flexGrow: isLoading || (!isLoading && inventoryItems.length > 0) ? '1' : '0'
                                        }}
                                        collapseSection={collapseInventoryStatusSection}
                                        toggleCollapseSection={() => setCollapseInventoryStatusSection(!collapseInventoryStatusSection)}
                                    />
                                    {(!isLoading && inventoryItems.length <= 0 && itemsActive) ? (
                                        <Tooltip disableInteractive title={t('add_new_inventory_item')} placement='right' arrow>
                                            <Link to={'/items'}>
                                                <IconButton disabled={isLoading || isUpdatingInventoryItems}>
                                                    <i className={`fa-solid fa-plus`}></i>
                                                </IconButton>
                                            </Link>
                                        </Tooltip>
                                    ) : (
                                        ''
                                    )}
                                </Stack>

                                <Card
                                    sx={{
                                        display: (collapseInventoryStatusSection || (!isLoading && inventoryItems.length <= 0)) ? 'none' : 'inline'
                                    }}
                                >
                                    <CardContent sx={{padding: '24px', paddingBottom: '16px !important'}}>
                                        <FormControl component="fieldset" variant="standard" sx={{marginBottom: '14px'}} focused={false}>
                                            <FormLabel componnet="legend" sx={{marginBottom: '14px', fontWeight: '500', color: '#000000'}}>{t('inventory_status_by_category')}</FormLabel>
                                            <FormGroup row={true}>
                                                <FormControlLabel
                                                    label={t('pallets')}
                                                    control={<Checkbox disabled={checkedItemTypes.all} checked={checkedItemTypes.all || checkedItemTypes.pallet} onChange={handleChangeItemType} name='pallet' />}
                                                />
                                                <FormControlLabel
                                                    label={t('lumber')}
                                                    control={<Checkbox disabled={checkedItemTypes.all} checked={checkedItemTypes.all || checkedItemTypes.lumber} onChange={handleChangeItemType} name='lumber' />}
                                                />
                                                <FormControlLabel
                                                    label={t('components')}
                                                    control={<Checkbox disabled={checkedItemTypes.all} checked={checkedItemTypes.all || checkedItemTypes.component} onChange={handleChangeItemType} name='component' />}
                                                />
                                                <FormControlLabel
                                                    label={t('all_inventory')}
                                                    control={<Checkbox checked={checkedItemTypes.all} onChange={handleChangeItemType} name='all' />}
                                                />
                                            </FormGroup>
                                        </FormControl>

                                        <InventoryStatusScrollableRow
                                            data={inventoryItems.filter((item) => {
                                                if(checkedItemTypes.all) {
                                                    return item
                                                } else {
                                                    switch(item.item?.item_type_key) {
                                                        case "pallet":
                                                            return checkedItemTypes.pallet
                                                        case "lumber":
                                                            return checkedItemTypes.lumber
                                                        case "component":
                                                            return checkedItemTypes.component
                                                        default:
                                                            return null
                                                    }
                                                }
                                            })}
                                            isLoading={isLoading}
                                            isUpdating={!isLoading && isUpdatingInventoryItems}
                                            overflow={true}
                                        />
                                    </CardContent>
                                </Card>
                            </div>
                        ) : null}
                    </Stack>
                </Box>
            </AppLayout>
        </>
    )
}
export default AdminDashboard
