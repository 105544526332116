import CustomCard from "../CustomCard"
import { useTranslation } from "react-i18next"
import { Link, IconButton } from '@mui/material'
import SalesRevenueCustomCardContent from "./utils/SalesRevenueCustomCardContent"

export default function SalesRevenue({data = [], showActions = false, isLoading = false, isUpdating = false}) {
    const { t } = useTranslation()

    return (
        <CustomCard
            title={t('sales_revenue')}
            showActions={showActions}
            actions={
                showActions ? (
                    <Link to={'/'}>
                        <IconButton aria-label={`view-${'/'}`} size='small'>
                            <i className="fa-solid fa-eye fa-sm"></i>
                        </IconButton>
                    </Link>
                ) : (
                    ''
                )

            }
            isLoading={isLoading}
            isUpdating={isUpdating}
        >
            <SalesRevenueCustomCardContent
                curr_revenue={data?.current_year_sales_revenue ?? 0}
                prev_revenue={data?.prev_year_sales_revenue ?? 0}
                pct={data?.diff_pct ?? 0}
            />
        </CustomCard>
    )
}
