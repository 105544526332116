import CustomCard from "../CustomCard"
import { useTranslation } from "react-i18next"
import { Link, IconButton, Stack } from '@mui/material'
import CustomCardContent from "./utils/CustomCardContent"

export default function CollectedItemsTotal({data = [], showActions = false, isLoading = false, isUpdating = false}) {
    const { t } = useTranslation()

    return (
        <CustomCard
            title={t('total_no_collected_items')}
            showActions={showActions}
            actions={
                showActions ? (
                    <Stack direction='row' spacing={2}>
                        <Link to={'/'}>
                            <IconButton aria-label={`view-active-collections-button`} size='small'>
                                <i className="fa-solid fa-eye fa-sm"></i>
                            </IconButton>
                        </Link>
                    </Stack>
                ) : (
                    ''
                )
            }
            isLoading={isLoading}
            isUpdating={isUpdating}
        >
            <CustomCardContent
                qty={data?.all_collected_items_count ?? 0}
                supplementaryQty={data?.current_month_collected_items_count ?? 0}
                pct={data?.diff_pct ?? 0}
                cardType="collected"
                isShipmentRelated={false}
            />
        </CustomCard>
    )
}
