export const buildUrl = (
    baseUrl = '',
    options = {
        with: [],
        where: {}
    }
) => {
    let url = baseUrl
    let withUrlBit = ''
    let whereUrlBit = ''

    withUrlBit = options?.with ? options?.with.join(',') : '';

    const whereKeys = options?.where ? Object.keys(options?.where) : [];
    for (let i = 0; i < whereKeys.length; i++) {
        const key = whereKeys[i];
        const value = options?.where[key];

        if(i === 0) {
            whereUrlBit = whereUrlBit.concat(`${key}=${value}`);
            continue;
        }

        if(whereKeys.length >= 1) {
            whereUrlBit = whereUrlBit.concat(`&${key}=${value}`);
        }
    }

    if(
        withUrlBit.length > 0
        && whereUrlBit.length > 0
    ) {
        url = url.concat(`?with=${withUrlBit}`, `&${whereUrlBit}`);
    } else if (withUrlBit.length > 0) {
        url = url.concat(`?with=${withUrlBit}`);
    } else if (whereUrlBit.length > 0) {
        url = url.concat(`?${whereUrlBit}`);
    }

    return url;
}
