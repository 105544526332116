import React from 'react'
import logo from '../assets/images/small.png'

const Wrapper = ({ children }) => {
    return (
        <div className='bg-white rounded-3xl'>
            <div className='flex w-full justify-center items-center pt-24'>
                <img className='w-[250px]' src={logo} alt="logo" />
            </div>
            <div className='p-[40px] max-[600px]:p-[20px]'>
                {children}
            </div>
        </div>
    )
}

export default Wrapper
