import React from 'react'

import TableShared from '../TableShared'
import { useTranslation } from 'react-i18next'
import { useStateContext } from '../../context/ContextProvider'
import { LinearProgress } from '@mui/material'

const SalesByItem = ({ items, isUpdating = false }) => {

    const { t } = useTranslation()

    const columns = [
        {
            field: 'quantity_shipped',
            headerName: t('quantity_shipped'),
            flex: 1,
            minWidth: 140
        },
        {
            field: 'total_value',
            headerName: t('total_value'),
            flex: 1,
            renderCell: (params) => <Total params={params} />,
            minWidth: 140
        },
        {
            field: 'item_per',
            headerName: t('item_per'),
            flex: 1,
            minWidth: 140
        }
    ]

    columns.unshift(
        localStorage.getItem('client_id').includes('pallet-book')
            ? {
                field: 'description',
                headerName: t('description'),
                flex: 1,
                minWidth: 140
            } : {
                field: 'item_no',
                headerName: t('item_no'),
                flex: 1,
                minWidth: 140
            }
    )

    return (
        <div>
            <div>
                <div className='flex p-5 justify-start items-center gap-4 border-b'>
                    <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('sales_by_item')}</p>
                </div>
            </div>
            {isUpdating && <LinearProgress />}
            <div className='pt-3'>
                <TableShared breakHeaders={true} items={items} columns={columns} rowsPerPageOptions={[5, 10]} border='none'/>
            </div>
        </div>
    )
}

export default SalesByItem

const Total = (params) => {
    const { currencySymbol } = useStateContext()

    return (
        <>{currencySymbol}{params.params.row.total_value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}
