import React, { useState, useEffect } from 'react'

import { useTranslation } from "react-i18next"
import { Navigate, useLocation } from "react-router-dom"

import { useStateContext } from '../context/ContextProvider'
import GuestLayout from '../components/Layouts/GuestLayout'
import AuthCard from '../components/AuthCard'
import Loading from '../components/Loading'
import axios from '../lib/axios'
import Wrapper from '../components/Wrapper'
import { useAuth } from '../hooks/auth'


const ChooseSite = () => {
    const { choosesite, setChoosesite, config, setCompany_id, getCurrency } = useStateContext()
    const { t } = useTranslation()
    const [choosedSite, setChoosedSite] = useState('')
    const location = useLocation()
    const [disabeled, setDisabeled] = useState(true)
    const [buttonDisabeled, setButtonDisabeled] = useState(false)
    const [allSites, setAllSites] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const { user, logout } = useAuth()

    useEffect(() => {
        getAllSites()
    }, [])

    const getAllSites = async () => {
        setIsLoading(true)

        await axios
            .get(`/api/sites?with=company`, config)
            .then(res => {
                const sites = res.data
                setAllSites(sites)
                if (sites.length == 1) {
                    localStorage.setItem('site', sites[0].id)
                    localStorage.setItem('company_id', sites[0].company_id)
                    setChoosesite(localStorage.getItem('site'))
                    setCompany_id(localStorage.getItem('company_id'))
                    getCurrency(sites[0].company_id)
                }
            })
            .catch(error => {
                if (error.response.status !== 422) throw error
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const setSite = () => {
        localStorage.setItem('site', choosedSite)
        setChoosesite(choosedSite)
    }

    const setCompanyId = (e) => {
        localStorage.setItem('company_id', e)
        setCompany_id(e)
    }

    const renderOnSiteChosen = () => {
        if(user?.counterpart) {
            return <Navigate to="/choose-portal" state={{ from: location }} replace />
        }

        return <Navigate to="/" state={{ from: location }} replace />
    }

    if (choosesite == false)
        return (
            <>
                {isLoading ? <Loading /> : null}
                <GuestLayout>
                    <AuthCard>
                        <Wrapper>
                            <div className='min-w-[400px] max-[500px]:min-w-[300px]'>
                                <div className='w-full'>
                                    <p className='text-[#718096] text-[18px] mb-3'>{t('choosesite')}</p>
                                    <select className='disabled:text-[#9B9B9B] font-[400] text-[14px] max-lg:max-w-full max-w-[400px] rounded-md w-full bg-[#f5f5f5] border-0 focus:ring-0 p-4 place' name="choosesite" id="choosesite" onChange={(e) => { setChoosedSite(e.target.value); setDisabeled(false); setButtonDisabeled(true); setCompanyId(e.target.selectedOptions[0].attributes.company.value); getCurrency(e.target.selectedOptions[0].attributes.company.value) }}>
                                        <option value="false" className='italic text-[#9B9B9B]' disabled={buttonDisabeled}>{t('choosesite')}</option>
                                        {allSites.map((item, index) => <option key={index} value={item.id} company={item.company_id}>{item?.company?.name} - {item.name}</option>)}
                                    </select>
                                </div>
                                <div className="w-full pt-5">
                                    <button className="w-full rounded-md bg-[#DBA34B] hover:bg-[#BD7503] disabled:bg-[#B8B7BC] text-white uppercase py-3 font-[700] text-[16px]" disabled={disabeled} onClick={() => setSite()}>{t("go")}</button>
                                </div>
                                <div className='flex justify-start w-full py-5'>
                                    <p className="text-[14px] text-[#BA8431] cursor-pointer" onClick={() => logout()}>
                                        {t('back_to_login')}
                                    </p>
                                </div>
                            </div>
                        </Wrapper>
                    </AuthCard>
                </GuestLayout>
            </>
        )
    else {
        return renderOnSiteChosen()
    }

}

export default ChooseSite
