import React, { useState, useEffect } from 'react'

import { useTranslation } from "react-i18next"

import { useStateContext } from '../context/ContextProvider'
import GuestLayout from '../components/Layouts/GuestLayout'
import AuthCard from '../components/AuthCard'
import Button from '../components/Button'
import { useAuth } from '../hooks/auth'

const Logout = () => {

    const { t } = useTranslation()
    const { logout, user } = useAuth({ middleware: 'guest' })

    return (
        <>
            <GuestLayout>
                <AuthCard>
                    <p className="text-white font-bold text-3xl museosans700 mb-2 uppercase text-center">{t('to_visit_this_page')}</p>

                    <div>
                        <div>
                            <div className='flex items-center gap-2 justify-center'>
                                <Button className={`ml-3`} onClick={() => logout(true, user?.role)}>{t("logout")}</Button>
                            </div>
                        </div>
                    </div>
                </AuthCard>
            </GuestLayout>
        </>
    )
}

export default Logout
