import React from 'react'
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from '../hooks/auth';
import { includes } from 'lodash';

const RequireLogout = () => {
    const { user } = useAuth({ middleware: 'guest' })
    const location = useLocation()

    return (
        (
            user
            && !includes(['tpl_driver', 'service_provider', 'vendor_user'], user?.role)
        )
            ? <Navigate to="/logout" state={{ from: location }} replace />
            : <Outlet />
    );
}

export default RequireLogout
