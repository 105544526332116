import React from 'react'
import { LinearProgress } from '@mui/material'

const OnTimeDelivery = ({ title, total, isUpdating = false }) => {
    return (
        <div>
            <div>
                <div className='flex justify-start items-center border-b p-5'>
                    <p style={{ fontWeight: 600, fontSize: '16px' }}>{title}</p>
                </div>
            </div>
            {isUpdating && <LinearProgress />}
            <div className='flex justify-between p-5'>
                <p className='text-xl'>{total.ontime} / {total.total}</p>
                <p className='font-bold text-xl'>{total.percentage_ontime}%</p>
            </div>
        </div>
    )
}

export default OnTimeDelivery
