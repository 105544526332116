import React, { useState } from "react";
import CustomCard from "../../CustomCard"
import { useTranslation } from "react-i18next"
import { Link, IconButton, Tooltip, Box, Typography, Stack } from '@mui/material'
import { LineChart } from '@mui/x-charts/LineChart';
import { capitalize, lowerCase } from "lodash"

{/* An example of what the received data should look like. */}
const dummyData = {
    1: {
        month_name_full: "January",
        month_name_abbr: "Jan",
        growth_pct: -20
    },
    2: {
        month_name_full: "February",
        month_name_abbr: "Feb",
        growth_pct: 0
    },
    3: {
        month_name_full: "March",
        month_name_abbr: "Mar",
        growth_pct: 10
    },
    4: {
        month_name_full: "April",
        month_name_abbr: "Apr",
        growth_pct: 20
    },
    5: {
        month_name_full: "May",
        month_name_abbr: "May",
        growth_pct: 15
    },
    6: {
        month_name_full: "June",
        month_name_abbr: "Jun",
        growth_pct: 0
    },
    7: {
        month_name_full: "July",
        month_name_abbr: "Jul",
        growth_pct: -10
    },
    8: {
        month_name_full: "August",
        month_name_abbr: "Aug",
        growth_pct: 10
    },
    9: {
        month_name_full: "September",
        month_name_abbr: "Sep",
        growth_pct: 10
    },
    10: {
        month_name_full: "October",
        month_name_abbr: "Oct",
        growth_pct: -5
    },
    11: {
        month_name_full: "November",
        month_name_abbr: "Nov",
        growth_pct: 15
    },
    12: {
        month_name_full: "December",
        month_name_abbr: "Dec",
        growth_pct: 30
    }
}

const DataChart = ({dataset}) => {
    const { t } = useTranslation()

    const monthFormatter = (month) => t(`months.abbr.${lowerCase(month)}`);
    const growthFormatter = (growth) => `${growth}%`;

    return (
        <LineChart
            dataset={Object.values(dataset)}
            xAxis={[{
                scaleType: 'band',
                dataKey: 'month_name_abbr',
                valueFormatter: monthFormatter
            }]}
            yAxis={[{
                dataKey: 'growth_pct',
                valueFormatter: growthFormatter
            }]}
            series={[
                { dataKey: 'growth_pct', label: capitalize(t('growth_this_x', {x: t('month')})), curve: 'linear', color: '#12B76A', valueFormatter: growthFormatter }
            ]}
            height={308}
            margin={{
                left: 50,
                right: 10,
                top: 50,
                bottom: 20,
            }}
            slotProps={{
                legend: {
                    hidden: true
                },
            }}
            sx={{
                '& .MuiLineElement-root': {
                    strokeWidth: 3
                }
            }}
        />
    )
}

const NoDataChart = ({dataset, showDummyData}) => {
    const { t } = useTranslation()

    return showDummyData ? (
        <DataChart dataset={dataset} />
    ) : (
        <Box
            height={308}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Typography variant='body2'>{t('no_data')}</Typography>
        </Box>
    )
}

export default function SalesGrowthPerMonthXChart({data, showActions = false, isLoading = false, isUpdating = false}) {
    const { t } = useTranslation()

    const [showDummyData, setShowDummyData] = useState(false)

    const toggleShowDummyData = () => setShowDummyData(!showDummyData);

    return (
        <CustomCard
            title={t('sales_growth_per_x', {x: t('month')})}
            showActions={showActions}
            actions={
                showActions ? (
                    <Stack direction='row' spacing={2}>
                        {/* <Tooltip title={`${showDummyData ? 'Hide' : 'Show'} Dummy Data`} arrow placement="left">
                            <Link to={'/'} onClick={toggleShowDummyData}>
                                <IconButton aria-label={`view-${'/'}`} size='small'>
                                    <i className={`fa-solid ${showDummyData ? 'fa-eye-slash' : 'fa-eye'} fa-sm`}></i>
                                </IconButton>
                            </Link>
                        </Tooltip> */}

                        <Link to={'/'}>
                            <IconButton aria-label={`view-${'/'}`} size='small'>
                                <i className={`fa-solid fa-ellipsis-vertical fa-sm`}></i>
                            </IconButton>
                        </Link>
                    </Stack>
                ) : (
                    ''
                )
            }
            isLoading={isLoading}
            isUpdating={isUpdating}
            isChart
        >
            {Object.keys(data).length >= 1 ? (
                <DataChart dataset={showDummyData ? dummyData : data} />
            ) : (
                <NoDataChart dataset={dummyData} showDummyData={showDummyData} />
            )}
        </CustomCard>
    )
}
