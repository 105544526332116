import * as React from "react"
import { useState, useEffect } from "react"

import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import Button from "@mui/material/Button"
import StepButton from '@mui/material/StepButton'
import StepLabel from '@mui/material/StepLabel'
import { styled } from '@mui/material/styles'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import Swal from 'sweetalert2'
import { useTranslation } from "react-i18next"
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf'
import "react-pdf/dist/esm/Page/TextLayer.css"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import { useNavigate } from "react-router-dom"
import { Tooltip } from '@mui/material'

import StackItemsGradeCode from "./StackItemsGradeCode"
import { useStateContext } from "../../context/ContextProvider"
import axios from "../../lib/axios"
import { useAuth } from "../../hooks/auth"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.min.js',
    import.meta.url,
).toString();



const StackStepperGradeCode = ({ order, setIsLoading, order_id }) => {

    const { config } = useStateContext()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { user } = useAuth({ middleware: 'guest' })

    const [activeStep, setActiveStep] = useState(0)
    const [completed, setCompleted] = useState({})
    const [enabled, setEnabled] = useState([{
        0: false, 1: true, 2: true, 3: true, 4: true, 5: true, 6: true, 7: true, 8: true, 9: true, 10: true, 11: true, 12: true,
        13: true, 14: true, 15: true, 16: true, 17: true, 18: true, 19: true, 20: true, 21: true, 22: true, 23: true, 24: true,
        25: true, 26: true, 27: true, 28: true, 29: true, 30: true, 31: true, 32: false
    }])
    const [gradeCodes, setGradeCodes] = useState([])
    const [stacks, setStacks] = useState([])
    const [stackCount, setStackCount] = useState(0)
    const [disabled, setDisabled] = useState(true)

    const getItems = async () => {
        setIsLoading(true)
        await axios.get(`/api/grade-codes`, config)
            .then(res => {
                const items = res.data
                setGradeCodes(items)
                setIsLoading(false)
            })
    }


    useEffect(() => {
        getItems()
    }, [])

    useEffect(() => {
        if (localStorage.getItem('Gstacks')) {
            const stacks = JSON.parse(localStorage.getItem('Gstacks'))
            const activeStep = JSON.parse(localStorage.getItem('GactiveStep'))
            const completed = JSON.parse(localStorage.getItem('Gcompleted'))
            const enabled = JSON.parse(localStorage.getItem('Genabled'))
            const stackCount = JSON.parse(localStorage.getItem('GstackCount'))
            if (stacks[0].order_id === order.id) {
                for (let i = 0; i < stacks.length - 1; i++) {
                    gradeCodes.map((item) => {
                        const filter = stacks[i].stack_items[0].find(it => it.stack_item_code === item.code)
                        if (filter) {
                            return
                        } else {
                            stacks[i].stack_items[0].push({
                                "id": item.id,
                                "stack_item_code": item.code,
                                "stack_item_qty": 0
                            })
                        }
                    })
                }
                setStacks(stacks)
                setActiveStep(activeStep)
                setCompleted(completed)
                setEnabled(enabled)
                setStackCount(stackCount)
            } else {
                setStacks(
                    [{
                        "id": 1,
                        "order_id": order.id,
                        "stack_no": "ST100",
                        "enabled": true,
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 2,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 3,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 4,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 5,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 6,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 7,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 8,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 9,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 10,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 11,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 12,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 13,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 14,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 15,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 16,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 17,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 18,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 19,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 20,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 21,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 22,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 23,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 24,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 25,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 26,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 27,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 28,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 29,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 30,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 31,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    },
                    {
                        "id": 32,
                        "order_id": order.id,
                        "stack_no": "ST101",
                        "stack_items": [
                            gradeCodes.map((i) => (
                                {
                                    "id": i.id,
                                    "stack_item_code": i.code,
                                    "stack_item_qty": 0
                                }
                            ))
                        ],
                    }]
                )
            }
        }
        else if (order.unloads_data) {
            setStacks(JSON.parse(order.unloads_data))
            for (let i = 0; i < JSON.parse(order.unloads_data).length - 1; i++) {
                for (let item of JSON.parse(order.unloads_data)[i].stack_items[0]) {
                    if (parseInt(item.stack_item_qty) > 0) {
                        const newCompleted = completed;
                        newCompleted[i] = true;
                        setCompleted(newCompleted)
                        setEnabled(prev => {
                            const newEnabled = [...prev];
                            newEnabled[0][i + 1] = false
                            return newEnabled
                        })
                    }
                }
            }
        }
        else {
            setStacks(
                [{
                    "id": 1,
                    "order_id": order.id,
                    "stack_no": "ST100",
                    "enabled": true,
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 2,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 3,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 4,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 5,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 6,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 7,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 8,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 9,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 10,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 11,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 12,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 13,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 14,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 15,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 16,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 17,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 18,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 19,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 20,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 21,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 22,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 23,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 24,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 25,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 26,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 27,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 28,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 29,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 30,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 31,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                },
                {
                    "id": 32,
                    "order_id": order.id,
                    "stack_no": "ST101",
                    "stack_items": [
                        gradeCodes.map((i) => (
                            {
                                "id": i.id,
                                "stack_item_code": i.code,
                                "stack_item_qty": 0
                            }
                        ))
                    ],
                }]
            )
        }
    }, [gradeCodes])


    const complete = async () => {
        setIsLoading(true)

        const formData = {}

        formData['unloads_data'] = stacks

        await axios.put(`/api/forklift-driver/${order_id}/proceed-collection`, formData, config).then(({ data }) => {

            navigate('/available-loads', {
                replace: true,
            })

            localStorage.removeItem('Gstacks')
            localStorage.removeItem('GactiveStep')
            localStorage.removeItem('GstackCount')
            localStorage.removeItem('Genabled')
            localStorage.removeItem('Gcompleted')

            setIsLoading(false)
        }).catch(({ response }) => {
            Swal.fire({
                text: response.data.error.description,
                icon: "error",
customClass: 'error',
showCloseButton: true,
iconColor: '#FF0000'
            })
            setIsLoading(false)
        })
    }

    const completeOffice = async () => {
        setIsLoading(true)

        const formData = {}

        formData['unloads_data'] = stacks

        await axios.put(`/api/forklift-driver/${order_id}/proceed-collection`, formData, config).then(({ data }) => {

            navigate(`/purchase-order/${order_id}`, {
                replace: true,
            })

            localStorage.removeItem('Gstacks')
            localStorage.removeItem('GactiveStep')
            localStorage.removeItem('GstackCount')
            localStorage.removeItem('Genabled')
            localStorage.removeItem('Gcompleted')

            setIsLoading(false)
        }).catch(({ response }) => {
            Swal.fire({
                text: response.data.error.message,
                icon: "error",
customClass: 'error',
showCloseButton: true,
iconColor: '#FF0000'
            })
            setIsLoading(false)
        })
    }

    const toShowPdf = async () => {

        const formData = {}


        formData['unloads_data'] = stacks

        await axios.put(`/api/forklift-driver/${order_id}/dgs-summary-store`, formData, config).then(({ data }) => {

        }).catch(({ response }) => {

        })
    }


    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted)
        handleEnable()
    }

    const handleUncomplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = false;
        setCompleted(newCompleted)
        localStorage.setItem('Gcompleted', JSON.stringify(newCompleted))
        localStorage.setItem('GactiveStep', activeStep)
    }

    const handleEnable = () => {
        setEnabled(prev => {
            const newEnabled = [...prev];
            newEnabled[0][activeStep + 1] = false
            return newEnabled
        })
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        if (activeStep === 32) {
            toShowPdf()
        }

        /* handleComplete() */
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleStep = (step) => () => {
        setActiveStep(step)
        if (activeStep === 32) {
            toShowPdf()
        }

        /* handleComplete() */
    }

    const handleSummary = () => {
        setActiveStep(32)
        if (activeStep === 32) {
            toShowPdf()
        }

        /* handleComplete() */
    }

    useEffect(() => {
        setStackCount(0)
        stacks[activeStep]?.stack_items[0].map((i) =>
            setStackCount(prev => parseInt(prev) + parseInt(i.stack_item_qty))
        )
        getTotal()
    }, [stacks, activeStep])


    const getTotal = (id) => {

        let total = 0

        for (let i = 0; i < stacks.length - 1; i++) {
            for (let item of stacks[i].stack_items[0]) {

                if (item.stack_item_code === id) {
                    total = parseInt(total) + parseInt(item.stack_item_qty)
                }

            }
        }
        return total

    }

    const getTotalTrailer = () => {

        let total = 0

        for (let i = 0; i < stacks.length - 1; i++) {
            for (let item of stacks[i].stack_items[0]) {
                total = parseInt(total) + parseInt(item.stack_item_qty)
            }
        }
        return total

    }


    return (

        <div className='p-5'>
            <div className='border border-gray-300 w-full h-fit p-10 flex justify-around gap-10'>
                <Box sx={{ width: "100%" }}>
                    <div>
                        <Button onClick={handleSummary} sx={{ backgroundColor: '#54618e', borderRadius: '5px', "&:hover": { backgroundColor: '#54618e' } }}>
                            <p className="flex items-baseline gap-2 text-white" style={{ padding: '5px 10px' }}>{t('summary')}</p>
                        </Button>
                    </div>

                    <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={<ColorlibConnector />}
                        sx={{
                            overflow: 'auto',
                            height: '140px',
                            padding: '20px',
                            paddingX: '0px'
                        }}>
                        {steps.map((label, index) => {
                            return (
                                <Step key={label} completed={completed[index]} disabled={enabled[0][index]} >
                                    <StepButton color="inherit" onClick={handleStep(index)}>
                                        <StepLabel className="w-max" StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>

                    <>
                        {
                            activeStep === steps.length - 1 ?
                                <div className="flex justify-end">
                                    <p className="font-bold border border-slate-300 p-3 m-1">{t('trailer_count')}: {getTotalTrailer()}</p>
                                </div>
                                :
                                <div className="flex justify-end">
                                    <p className="font-bold border border-slate-300 p-3 m-1">{t('stack_count')}: {stackCount}</p>
                                </div>
                        }

                        {
                            activeStep === steps.length - 1 ?
                                ""
                                :
                                <div>
                                    <p className="text-xl roboto pb-3">{t('enter_items')} <span className="font-bold">{t('stack')} {activeStep + 1}.</span></p>
                                </div>
                        }

                        <div className="flex flex-wrap mt-2">
                            {stacks[activeStep]?.stack_items[0].map((i) =>
                                <StackItemsGradeCode key={i.id} i={i} toShowPdf={toShowPdf} setStacks={setStacks} setDisabled={setDisabled} stackCount={stackCount} completed={completed} enabled={enabled} setStackCount={setStackCount} stacks={stacks} handleComplete={handleComplete} handleUncomplete={handleUncomplete} activeStep={activeStep} />
                            )}
                        </div>

                        {
                            activeStep === steps.length - 1 ?
                                <div className="flex gap-5">
                                    <div className="w-1/2 flex justify-center">
                                        <DocumentWrapper>
                                            <Document file={process.env.REACT_APP_BACKEND_URL + `/api/purchase-orders/${order_id}/unload-sheet?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}>
                                                <Page pageNumber={1} renderTextLayer={false} />
                                            </Document>
                                        </DocumentWrapper>
                                    </div>
                                    <div className="w-1/2">
                                        <p className="text-3xl roboto font-bold pb-4">{t('items_summary_table')}</p>
                                        <hr className="mb-4" />
                                        <div className="flex gap-2 w-full text-xl text-[#666666] roboto pb-4">
                                            <p className="w-1/2">{t('items')}</p>
                                            <p className="w-1/2">{t('quantity')}</p>
                                        </div>
                                        <hr className="mb-2" />
                                        {
                                            gradeCodes.map((item) => (
                                                <div key={item.id}>
                                                    <div className="flex gap-2 w-full pb-2">
                                                        <p className="w-1/2">{item.code}</p>
                                                        <p className="w-1/2">{getTotal(item.code)}</p>
                                                    </div>
                                                    <hr className="mb-2" />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                :
                                null
                        }

                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2, justifyContent: "space-between" }}>
                            <Button onClick={handleBack} sx={{ visibility: activeStep == 0 ? 'hidden' : 'visible', backgroundColor: '#54618e', borderRadius: '5px', "&:disabled": { backgroundColor: '#ececef' }, "&:hover": { backgroundColor: '#54618e' } }}>
                                <p className="flex items-baseline gap-2 text-white" style={{ padding: '5px 10px' }}><i className="fa-solid fa-arrow-left"></i> {t('previous_stack')} </p>
                            </Button>
                            <Box sx={{ flex: "1 1 auto" }} />


                            {activeStep === steps.length - 1 ?
                                user.role === 'forklift_driver' ?
                                    <Button onClick={complete} sx={{ backgroundColor: '#d0f3b9', borderRadius: '5px', "&:hover": { backgroundColor: '#d0f3b9' } }}>
                                        <p className="flex items-baseline gap-2 text-black" style={{ padding: '5px 10px' }}>{t('trailer_unloaded')} <i className="fa-solid fa-check"></i> </p>
                                    </Button>
                                    :
                                    <Button onClick={completeOffice} sx={{ backgroundColor: '#d0f3b9', borderRadius: '5px', "&:hover": { backgroundColor: '#d0f3b9' } }}>
                                        <p className="flex items-baseline gap-2 text-black" style={{ padding: '5px 10px' }}>{t('update_counts')} <i className="fa-solid fa-check"></i> </p>
                                    </Button>
                                :
                                disabled ?
                                    <Tooltip disableInteractive  title={t('enter_stack') + (activeStep + 1) + t('to_move')} placement="top">
                                        <div>
                                            <Button onClick={handleNext} disabled={disabled} sx={{ backgroundColor: '#ececef', borderRadius: '5px', "&:hover": { backgroundColor: '#54618e' } }}>
                                                <p className="flex items-baseline gap-2 text-white" style={{ padding: '5px 10px' }}>{t('next_stack')} <i className="fa-solid fa-arrow-right"></i></p>
                                            </Button>
                                        </div>
                                    </Tooltip>
                                    :
                                    <Button onClick={handleNext} disabled={disabled} sx={{ backgroundColor: '#54618e', borderRadius: '5px', "&:hover": { backgroundColor: '#54618e' } }}>
                                        <p className="flex items-baseline gap-2 text-white" style={{ padding: '5px 10px' }}>{t('next_stack')} <i className="fa-solid fa-arrow-right"></i></p>
                                    </Button>

                            }
                        </Box>
                    </>

                </Box>
            </div>
        </div>
    )
}

export default StackStepperGradeCode

const DocumentWrapper = styled("div")({
    maxHeight: "600px",
    overflowY: "auto"
});

const ColorlibStepIconRoot = styled('div')(({ ownerState }) => ({
    backgroundColor: '#dbdbdb',
    zIndex: 1,
    color: '#515151',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    border: '1px solid #515151',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#ffffff',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            '#dafdc2',
    }),
    ...(ownerState.icon == '33' && {
        backgroundColor:
            '#ffffff',
    })
}));

function ColorlibStepIcon(props) {

    const { active, completed, className, icon } = props


    return (
        <ColorlibStepIconRoot ownerState={{ completed, active, icon }} className={className}>
            {icon}
        </ColorlibStepIconRoot>
    );
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#dbdbdb',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#dafdc2',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const steps = [
    "Stack 1",
    "Stack 2",
    "Stack 3",
    "Stack 4",
    "Stack 5",
    "Stack 6",
    "Stack 7",
    "Stack 8",
    "Stack 9",
    "Stack 10",
    "Stack 11",
    "Stack 12",
    "Stack 13",
    "Stack 14",
    "Stack 15",
    "Stack 16",
    "Stack 17",
    "Stack 18",
    "Stack 19",
    "Stack 20",
    "Stack 21",
    "Stack 22",
    "Stack 23",
    "Stack 24",
    "Stack 25",
    "Stack 26",
    "Stack 27",
    "Stack 28",
    "Stack 29",
    "Stack 30",
    "Stack 31",
    "Stack 32",
    "Summary"
]








