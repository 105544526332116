import React, {useRef, useState} from 'react'

import {useDrag, useDrop} from "react-dnd"
import CallMadeIcon from '@mui/icons-material/CallMade'
import CallReceivedIcon from '@mui/icons-material/CallReceived'

import {COLUMN_NAMES} from '../../constants/constants'
import ViewOrder from './ViewOrder'
import {useStateContext} from '../../context/ContextProvider'
import { Badge, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Driver = ({ id, type, shipments, index, driverItems, setDriverItems, setIndex }) => {

    const { t } = useTranslation()
    const ref = useRef(null)
    const [open, setOpen] = useState(false)
    const { config } = useStateContext()


    const handleOpen = () => {
        setOpen(!open)
    }



    const [, drop] = useDrop(
        () => ({
            accept: [COLUMN_NAMES.SHIPMENTS, COLUMN_NAMES.COLLECTIONS],
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            }),
            /* hover(item, monitor) {

                 if (!ref.current) {
                     return;
                 }
                 setIndex(index)
                 const dragIndex = item.load_order_no;
                 const hoverIndex = index;

                 console.log("index od ordera", dragIndex);
                 console.log("index na kojem polju se nalazim trenutno", hoverIndex);
                 console.log("item koji sam zagrabio", item);
                 console.log("id od drivera od itema: ", item.driver_id);
                 console.log("driver na kojeg postavljam: ", id);
                 // Don't replace driverItems with themselves
                 if (dragIndex === hoverIndex) {
                     return;
                 }
                 // Determine rectangle on screen
                 const hoverBoundingRect = ref.current?.getBoundingClientRect();
                 // Get vertical middle
                 const hoverMiddleY =
                     (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                 // Determine mouse position
                 const clientOffset = monitor.getClientOffset();
                 // Get pixels to the top
                 const hoverClientY = clientOffset.y - hoverBoundingRect.top;
                 // Only perform the move when the mouse has crossed half of the driverItems height
                 // When dragging downwards, only move when the cursor is below 50%
                 // When dragging upwards, only move when the cursor is above 50%
                 // Dragging downwards
                 if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                     return;
                 }
                 // Dragging upwards
                 if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                     return;
                 }

                 // Time to actually perform the action
                 moveCard(dragIndex, hoverIndex)
                 // Note: we're mutating the monitor item here!
                 // Generally it's better to avoid mutations,
                 // but it's good here for the sake of performance
                 // to avoid expensive index searches.

                 item.load_order_no = hoverIndex

             }, */
        }),
        [driverItems],
    )


    const moveCard = (dragIndex, hoverIndex) => {
        const dragItem = driverItems[dragIndex]

        if (dragItem) {
            setDriverItems((prevState) => {
                const coppiedStateArray = [...prevState];

                // remove item by "hoverIndex" and put "dragItem" instead
                const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

                // remove item by "dragIndex" and put "prevItem" instead
                coppiedStateArray.splice(dragIndex, 1, prevItem[0]);

                return coppiedStateArray;
            })
        }
    }

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: type,
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
            item: () => {
                return { ...shipments, index: index }
            }
        }),
        [],
    )

    drag(drop(ref));


    return (
        <div style={{position: 'relative'}}>
            {shipments.type == 'BROKERED' ? (
                <Tooltip disableInteractive title={t('brokered')}>
                    <div style={{ position: 'absolute', top: 0, left: 0, border: '7.5px solid', borderColor: `rgb(255,25,25) transparent transparent rgb(255,25,25)` }}></div>
                </Tooltip>
            ) : null}

            <div
                ref={shipments.so_status_id == '8' || shipments.po_status_id == '5' ? null : ref}
                onClick={handleOpen}
                style={{ opacity: isDragging ? 0.5 : 1 }}
                className={`p-4 flex border-b justify-between items-center hover:bg-[#f6f6f6] ${shipments.so_status_id == '8' ? '' : 'cursor-pointer'}`}
            >
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    {shipments.customer_name || shipments.vendor_name}
                </div>
                {
                    shipments.order_type === 'SHIPMENTS' ?
                        shipments.so_status_id != '8' ?
                            <span style={{ color: '#336195' }}>
                                {Number(shipments.so_status_id) < 6 ? (
                                    <Tooltip title={t('shipment_not_loaded_warning_dispatch')}>
                                        <Badge
                                            badgeContent={<i className="fa-solid fa-triangle-exclamation fa-lg" style={{color: "#db1c24"}}></i>}
                                            sx={{'& .MuiBadge-badge': {
                                                top: '-2px !important',
                                                left: '-6px !important'
                                            }}}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left'
                                            }}
                                        >
                                            <CallMadeIcon />
                                        </Badge>
                                    </Tooltip>
                                ) : (
                                    <CallMadeIcon />
                                )}
                            </span>
                            :
                            <span className='arrow-up' style={{ color: '#336195' }}>
                                <CallMadeIcon />
                            </span>
                        :
                        shipments.po_status_id != '5' ?
                            <span style={{ color: '#b7472a' }}>
                                <CallReceivedIcon />
                            </span>
                            :
                            <span className='arrow-down' style={{ color: '#b7472a' }}>
                                <CallReceivedIcon />
                            </span>
                }
            </div>
            <ViewOrder order={shipments} open={open} handleOpen={handleOpen} />
        </div>
    )
}

export default Driver


