import React from 'react'

import TableShared from '../TableShared'
import { useTranslation } from 'react-i18next'
import { useStateContext } from '../../context/ContextProvider'
import { LinearProgress } from '@mui/material'

const ShipToDetails = ({ shipToLocation, isUpdating = false }) => {

    const { t } = useTranslation()

    const columns = [

        {
            field: 'ship_to_name',
            headerName: t('ship_to_name'),
            flex: 1,
            minWidth: 140
        },
        {
            field: 'quantity_shipped',
            headerName: t('quantity_shipped'),
            flex: 1,
            minWidth: 140
        },
        {
            field: 'total_value',
            headerName: t('total_value'),
            flex: 1,
            renderCell: (params) => <Total params={params} />,
            minWidth: 140
        },
        {
            field: 'number_of_shipments',
            headerName: t('number_of_shipments'),
            flex: 1,
            minWidth: 140
        }
    ]

    return (
        <div>
            <div>
                <div className='flex p-5 justify-start items-center border-b'>
                    <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('ship_to_details')}</p>
                </div>
            </div>
            {isUpdating && <LinearProgress />}
            <div className='pt-3'>
                <TableShared breakHeaders={true} items={shipToLocation} columns={columns} rowsPerPageOptions={[5, 10]} border='none'/>
            </div>
        </div>
    )
}

export default ShipToDetails


const Total = (params) => {
    const { currencySymbol } = useStateContext()

    return (
        <>{currencySymbol}{params.params.row.total_value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}
