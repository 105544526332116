import React, { useState, useEffect, useRef } from 'react'
import { Modal, Box, FormControl, Button } from '@mui/material'
import { useStateContext } from '../../context/ContextProvider'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import SignaturePad from 'react-signature-canvas'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2';
import styles from './Assigned.module.css'
import axios from '../../lib/axios'
import { isNil } from 'lodash'
import SignatureModalBox from '../Layouts/SignatureModalBox'

const OrderSignatureModal = ({ open, setOpen, orderId = null, orderType = null, color = '#336195', setIsLoading }) => {

    const { t } = useTranslation()
    const { config, setChoosesite: setSite } = useStateContext()

    const [canSave, setCanSave] = useState(false)
    const [signature, setSignature] = useState(null)

    const sigRef = useRef({})

    useEffect(() => {
        setSite(localStorage.getItem('site'))
    }, [])

    const handleToggleModal = () => {
        setOpen(!open)
        setCanSave(false)
    }

    const handleClear = () => {
        sigRef.current.clear()
        setCanSave(false)
    }

    const handleOnEnd = () => {
        const canvas = sigRef.current.getCanvas()
        canvas.toBlob((blob) => {
            setSignature(blob)
        })
        setCanSave(true)
    }

    const storeOrderSignature = async (e, id, type) => {
        if(!isNil(id) && !isNil(type)) {
            setIsLoading(true)

            const formData = new FormData()

            formData.append('file', signature, 'signature.png')
            formData.append('order_id', id)
            formData.append('order_type', type)

            await axios.post(`/api/store-signature`, formData, config).then(({ data }) => {
                setSignature(null)
                setOpen(false)
            }).catch(({ response }) => {
                Swal.fire({
                    text: response.data.error.description,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            }).finally(() => {
                setIsLoading(false)
            })
        } else {
            Swal.fire({
                title: 'Frontend Error',
                text: 'The signature modal might not be set up correctly. Check Order ID and Order Type.',
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
        }
    }

    return (
        <Modal open={open} onClose={handleToggleModal}>
            <SignatureModalBox>
                <div className='p-3.5' style={{ backgroundColor: color, borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between'>
                        <div className='flex gap-4'>
                            <span><i className="fa-solid fa-pen text-xl text-white"></i></span>
                            <p className='text-xl roboto font-semibold text-white'>{t('signature')}</p>
                        </div>
                        <IconButton sx={{color: 'white'}} onClick={handleToggleModal}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                </div>
                <div>
                    <div className='p-5'>
                        <form className='flex justify-center flex-col items-start mt-2'>
                            <FormControl variant="standard" sx={{ width: '100%', marginBottom: '20px' }}>
                                <SignaturePad
                                    ref={sigRef}
                                    onEnd={handleOnEnd}
                                    canvasProps={{style: {width: "100%", height: "100%", minHeight: "236px"}}}
                                />
                            </FormControl>
                        </form>
                        <div className='flex items-center justify-between'>
                            <Button variant="contained" className={styles['check-documents-button']} onClick={handleClear}>{t('clear')}</Button>
                            <Button
                                variant="contained"
                                disabled={!canSave}
                                onClick={(e) => storeOrderSignature(e, orderId, orderType)}
                                sx={{
                                    backgroundColor: '#4caf50',
                                    "&:hover": {
                                        backgroundColor: '#5b8948'
                                    },
                                    "&:disabled": {
                                        backgroundColor: "[hsla(0,0%,100%,.12)]"
                                    }
                                }}
                            >{t('save')}</Button>
                        </div>
                    </div>
                </div>
            </SignatureModalBox>
        </Modal>
    )
}

export default OrderSignatureModal
