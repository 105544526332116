import React, {useEffect, useState} from 'react'
import {pieArcClasses, PieChart} from '@mui/x-charts/PieChart';


const PieChartData = ({ items }) => {

    // const data = [
    //     { id: 0, value: 10, percentage: '30%', label: '40x48 Core A', color: `#a74038` },
    //     { id: 1, value: 15, percentage: '17%', label: 'Scrap Cores', color: `#384553` }
    // ];

    const [data, setData] = useState([{
        id: 0,
        value: 0,
        percentage: '',
        label: '',
        color: ''
    }])

    useEffect(() => {
        if (items?.length > 0) setData(items)
        else setData([{
            id: 0,
            value: 0,
            percentage: '',
            label: '',
            color: ''
        }])
    }, [items])

    const [screenSize, setScreenSize] = useState(window.innerWidth)
    const [width, setWidth] = useState(150)

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(window.innerWidth)
        }
        window.addEventListener('resize', updateDimension)

        if(screenSize < 1300) {
            if(screenSize < 1024) {
                setWidth(100)
            } else {
                setWidth(150)
            }
        } else {
            setWidth(150)
        }

    }, [screenSize])


    return (
        <div className='flex'>
            <PieChart
                series={[{
                    data,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray'  },
                    arcLabel: (item) => `${item.value}(${item.percentage})`,
                    arcLabelMinAngle: 25,
                    outerRadius: width,
                }]}
                legend={{ hidden: true }}
                sx={{ [`& .${pieArcClasses.faded}`]: { fill: 'gray' } }} height={300} width={400}
                margin={{
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0
                }}
            />
            <div className='max-w-[280px]'>
                {
                    data.map((item) =>
                        <div className='flex gap-5 w-full mb-2 mr-1 items-center' key={item.id}>
                            <div className={`min-w-[24px] max-w-[24px] min-h-[24px] max-h-[24px]`} style={{ backgroundColor: item.color }}></div>
                            <p>
                                {item.label}
                            </p>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default PieChartData
