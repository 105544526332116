import React, { useState, useEffect } from 'react'

import { Modal, Box, FormControl, Select, InputLabel, MenuItem, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import Swal from 'sweetalert2';

import axios from '../../lib/axios'
import { useStateContext } from '../../context/ContextProvider'
import Loading from '../Loading';


const AssignForklifter = ({ open, setOpen, orderId, forklifterId, type = "sales" }) => {

    const { t } = useTranslation()
    const { choosesite, setChoosesite, config } = useStateContext()

    const [forklifters, setForklifters] = useState([])
    const [forklifter, setForklifter] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setChoosesite(localStorage.getItem('site'))
    }, [])

    useEffect(() => {
        if (open) {
            getForklifters()
        }
    }, [open])

    useEffect(() => {
        if (forklifter) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [forklifter])

    useEffect(() => {
        if (forklifters && forklifters.length > 0) {
            if (forklifterId) {
                setForklifter(forklifterId)
            }
        }
    }, [forklifters])

    const getForklifters = async () => {
        setIsLoading(true)
        await axios.get(`/api/users?role=forklift_driver&site_id=${choosesite}`, config)
            .then(res => {
                const users = res.data.data
                setForklifters(users)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }


    const handleToggleModal = () => {
        setOpen(!open)
        setForklifter('')
    }

    const handleClear = () => {
        setForklifter('')
    }

    const assignForklifter = async (e) => {
        e.preventDefault()
        const formData = {}

        formData['forklifter_id'] = forklifter

        await axios.post(`/api/${type}-orders/${orderId}/assign-forklifter`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            setOpen(false)
        }).catch(({ response }) => {
            if (response.status === 422) {
                Swal.fire({
                    text: response.data.error.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            } else {
                Swal.fire({
                    text: response.data.error.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            }
        })
    }



    return (
        <>
        {isLoading ? <Loading position='fixed' /> : ''}
        <Modal open={open} onClose={handleToggleModal}>
            <Box sx={style}>
                <div className='flex justify-between items-center p-5 w-full' style={{ backgroundColor: '#DBA34B', borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-baseline justify-between w-full'>
                        <p className='text-[18px] font-[600] text-white'>{t('select_forklift_driver')}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-white">
                            <button onClick={handleToggleModal}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='p-5'>
                        <form className='flex justify-center flex-col items-start pt-1 pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('select_forklift_driver')}</p>
                            <Select value={forklifter} onChange={e => { setForklifter(e.target.value) }}
                                sx={{
                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' },
                                    ".MuiSelect-iconOutlined": { display: forklifter ? 'none !important' : '' }
                                }}
                                endAdornment={forklifter ? (<IconButton sx={{ visibility: forklifter ? "visible" : "hidden", padding: '0' }} onClick={handleClear}><ClearIcon /></IconButton>) : false}
                            >
                                {
                                    forklifters.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)
                                }
                            </Select>
                        </form>
                        <div className='w-full flex gap-2 justify-between'>
                            <button type="button" onClick={handleToggleModal} className='w-full text-[14px] text-[#919399] rounded-sm px-4 py-2 bg-[#F3F5F7] hover:bg-[#E6E6E6] disabled:bg-[#B8B7BC] capitalize'>
                                {t('cancel')}
                            </button>
                            <button type="button" onClick={assignForklifter} disabled={disabled} className='w-full text-[14px] text-white rounded-sm px-4 py-2 bg-[#DBA34B] hover:bg-[#CF7300] disabled:bg-[#B8B7BC] capitalize'>
                                {t('select')}
                            </button>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
        </>
    )
}

export default AssignForklifter

const style = {
    minWidth: "360px",
    maxWidth: "540px",
    width: "50%",
    height: "auto",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#ffffff',
    border: 'transparent',
    borderRadius: '5px',
    boxShadow: 24,
    zIndex: "1600",
    outline: 'none'
}
