import React, { useState, useEffect } from 'react'
import { useAuth } from '../hooks/auth'
import DriverDashboard from './driver/DriverDashboard'
import ForkliftDashboard from './forklift_driver/ForkliftDashboard'
import Dashboard from './Dashboard'
import { useStateContext } from '../context/ContextProvider'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import { isNil } from 'lodash'

const AvailableLoads = () => {
    const location = useLocation()
    const { user } = useAuth({ middleware: 'guest' })
    const { choosesite, setChoosesite, config, pusher } = useStateContext()

   const [openLatestMessage, setOpenLatestMessage] = useState(false)

    const [userId, setUserId] = useState(() => {
        if (sessionStorage.getItem('imp_uid') !== null) {
            const uid = sessionStorage.getItem('imp_uid')
            sessionStorage.removeItem('imp_uid')
            return uid
        } else {
            return user?.id
        }
    })

    const [siteId, setSiteId] = useState(() => {
        if (sessionStorage.getItem('imp_sid') !== null) {
            const sid = sessionStorage.getItem('imp_sid')
            sessionStorage.removeItem('imp_sid')
            return sid
        } else {
            return user?.site_id
        }
    })

    const renderThis = (role) => {
        switch (role) {
            case "driver":
                return <DriverDashboard
                    userId={userId}
                    siteId={siteId}
                    config={config}
                    pusher={pusher}
                    choosesite={choosesite}
                    setOpenLatestMessage={setOpenLatestMessage}
                />
            case "forklift_driver":
                return <ForkliftDashboard
                    userId={userId}
                    siteId={siteId}
                    config={config}
                    pusher={pusher}
                    choosesite={choosesite}
                    setOpenLatestMessage={setOpenLatestMessage}
                />
            default:
                return <Dashboard />
        }
    }

    useEffect(() => {
        if (openLatestMessage) {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: sessionStorage.getItem('available_loads_latest_msg')
            }).then(() => {
                setOpenLatestMessage(false)
                sessionStorage.removeItem('available_loads_latest_msg')
            })
        }
    }, [openLatestMessage])

    return (
        <>
            {renderThis(user?.role)}
        </>
    )
}

export default AvailableLoads
