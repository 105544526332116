import * as React from 'react'
import { useState, useEffect } from 'react'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useTranslation } from "react-i18next"
import { Tooltip } from '@mui/material'
import TablePagination from "@mui/material/TablePagination"
import { t } from 'i18next'
import { visuallyHidden } from '@mui/utils'
import dayjs from 'dayjs'

import { getComparator, stableSort } from '../../helpers/DailyBoardTableHelpers'
import RejectSalesOrder from '../sales_purchase_orders/RejectSalesOrder'
import CreatePurchaseOrderCustomerUser from '../sales_purchase_orders/CreatePurchaseOrderCustomerUser'
import { useStateContext } from '../../context/ContextProvider'

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false)

    return (
        <React.Fragment >
            <TableRow sx={{ '& > *': { borderBottom: 'unset !important' } }}>
                <TableCell sx={{ padding: '8px 16px' }}>{<ShowDate date={row.requested_collection_date} />}</TableCell>
                <TableCell sx={{ padding: '8px 16px', fontStyle: 'italic' }}>{row.vendor_name}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.purchase_location_name}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.customer_bol_no}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.broker_bol_no}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.pickup_trailer_no}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>
                    {
                        <TodaysSalesOrderAction order={row} />
                    }
                </TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell style={{ padding: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '20%' }}>{t('item_no')}</TableCell>
                                        <TableCell sx={{ width: '50%' }}>{t('description')}</TableCell>
                                        <TableCell sx={{ width: '10%' }}>{t('quantity_ordered')}</TableCell>
                                        <TableCell sx={{ width: '10%' }}>{t('qty_to_receive')}</TableCell>
                                        <TableCell sx={{ width: '10%' }}>{t('quantity_received')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.purchase_order_items.map((historyRow) => (
                                        <TableRow key={historyRow.id}>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.item_no}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.description}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_ordered}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_to_receive}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_received}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </React.Fragment>
    );
}

const SortableTableHeaderRow = (props) => {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {/* Requested Delivery Date cell */}
                <TableCell
                    sortDirection={orderBy === 'requested_collection_date' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'requested_collection_date'}
                        direction={orderBy === 'requested_collection_date' ? order : 'asc'}
                        onClick={createSortHandler('requested_collection_date')}>
                        {t('requested_collection_date')}
                        {orderBy === 'requested_collection_date' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                {/* Shipped Date cell */}
                <TableCell
                    sortDirection={orderBy === 'vendor' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'vendor'}
                        direction={orderBy === 'vendor' ? order : 'asc'}
                        onClick={createSortHandler('vendor')}>
                        {t('vendor')}
                        {orderBy === 'vendor' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                {/* customer cell */}
                <TableCell
                    sortDirection={orderBy === 'purchase_location' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'purchase_location'}
                        direction={orderBy === 'purchase_location' ? order : 'asc'}
                        onClick={createSortHandler('purchase_location')}>
                        {t('purchase_location')}
                        {orderBy === 'purchase_location' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                {/* customer_bol_no cell */}
                <TableCell
                    sortDirection={orderBy === 'customer_bol_no' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'customer_bol_no'}
                        direction={orderBy === 'customer_bol_no' ? order : 'asc'}
                        onClick={createSortHandler('customer_bol_no')}>
                        {t('customer_bol')}
                        {orderBy === 'customer_bol_no' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                {/* Sales Order No. cell */}
                <TableCell
                    sortDirection={orderBy === 'broker_bol_no' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'broker_bol_no'}
                        direction={orderBy === 'broker_bol_no' ? order : 'asc'}
                        onClick={createSortHandler('broker_bol_no')}>
                        {t('broker_bol')}
                        {orderBy === 'broker_bol_no' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                <TableCell
                    sortDirection={orderBy === 'pickup_trailer_no' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}

                        active={orderBy === 'pickup_trailer_no'}
                        direction={orderBy === 'pickup_trailer_no' ? order : 'asc'}
                        onClick={createSortHandler('pickup_trailer_no')}>
                        {t('pickup_trailer_no')}
                        {orderBy === 'pickup_trailer_no' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>

                </TableCell>
                <TableCell sx={{ color: '#88909C !important', fontWeight: '400' }}>{t('actions')}</TableCell>

                <TableCell />
            </TableRow>
        </TableHead>
    )
}

export default function CollapsibleTable({ items }) {

    const { t } = useTranslation()

    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(10);
    const [shipments, setShipments] = useState([])
    const [orderBy, setOrderBy] = useState('created_at')
    const [order, setOrder] = useState('desc')


    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }

    const handleRequestSort = (event, property) => {
        if (order === 'desc' && orderBy === property) {
            setOrderBy('created_at')
        } else {
            const isAsc = orderBy === property && order === 'asc'
            setOrder(isAsc ? 'desc' : 'asc')
            setOrderBy(property)
        }
    }

    const visibleShipments = React.useMemo(
        () =>
            stableSort(items, getComparator(order, orderBy)).slice(
                pg * rpg,
                pg * rpg + rpg,
            ),
        [items, order, orderBy, pg, rpg],
    )

    return (
        <>

            <div>
                <TableContainer>
                    <Table aria-label="collapsible table" stickyHeader>
                        <colgroup>
                            <col width="15%" />
                            <col width="15%" />
                            <col width="20%" />
                            <col width="20%" />
                            <col width="10%" />
                            <col width="25%" />
                            <col width="10%" />
                            <col />
                        </colgroup>
                        <SortableTableHeaderRow
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {items.length <= 0 ? <TableRow><TableCell colSpan={8} sx={{ textAlign: 'center' }}>{t('no_data')}</TableCell></TableRow> : null}
                            {visibleShipments?.map((row) => (
                                <Row key={row.id} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className='table-pagination'
                    labelRowsPerPage={t('rowsPerPage')}
                    rowsPerPageOptions={[10, 15]}
                    component="div"
                    count={items.length}
                    rowsPerPage={rpg}
                    page={pg}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>
    );
}

const TodaysSalesOrderAction = ({ order }) => {

    const { config } = useStateContext()
    const [open, setOpen] = useState(false)
    const [openSmall, setOpenSmall] = useState(false)

    const handleOpen = async () => {
        setIsLoading(true)

        /* await axios.put(`/api/collection-requests/accept-request/${order?.id}`, config).then(({ data }) => {
            setIsLoading(false)
        }).catch(({ response }) => {
            setIsLoading(false)
        }) */

        setOpen(!open)
    }

    const handleOpenSmall = () => {
        setOpenSmall(!openSmall)
    }

    const [isLoading, setIsLoading] = useState(false)

    return (
        <>
            <div className='flex gap-2'>
                <Tooltip disableInteractive title="Accept Collection" placement='bottom'>
                    <div style={{ color: '#8fcd92', fontSize: '20px', cursor: 'pointer' }} onClick={handleOpen}>
                        <i className="fa-solid fa-check"></i>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title="Reject Collection" placement='bottom'>
                    <div style={{ color: '#ff6b6b', fontSize: '20px', cursor: 'pointer' }} onClick={handleOpenSmall}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                </Tooltip>
            </div>
            <CreatePurchaseOrderCustomerUser open={open} handleOpen={handleOpen} order={order} setIsLoading={setIsLoading} />
            <RejectSalesOrder type={'collection'} open={openSmall} handleOpen={handleOpenSmall} order={order} setIsLoading={setIsLoading} title={t('reject_collection')} />
        </>
    )
}

export const ShowDate = ({ date }) => {

    const date1 = new Date(dayjs(date).format("YYYY-MM-DD"))
    const date2 = new Date(dayjs().format("YYYY-MM-DD"))

    return (
        <div>
            {
                date1 < date2 ? <p style={{ color: '#F00', padding: '5px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p> :
                    date1 == date2 ? <p style={{ color: 'blue', padding: '5px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p> :
                        date1 > date2 ? <p style={{ color: '#008E00', padding: '5px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p> :
                            <p style={{ color: 'blue', padding: '5px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p>
            }
        </div>
    )
}
