import React, { useEffect, useState, useLayoutEffect, useRef } from "react"

import { useTranslation } from 'react-i18next'
import { Box, InputLabel, Modal, Switch, Tab, TextField, Tooltip, ListItemText, ListItemIcon, Chip, Stack, RadioGroup, FormControlLabel, Radio, Typography, Button, IconButton, Autocomplete } from "@mui/material"
import Swal from 'sweetalert2'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import ClearIcon from "@mui/icons-material/Clear";
import { Link, useLocation } from "react-router-dom"

import AppLayout from '../../components/Layouts/AppLayout'
import { useStateContext } from '../../context/ContextProvider'
import TableSharedCustom from '../../components/TableSharedCustom'
import Loading from "../../components/Loading"
import AddButton from "../../components/AddButton"
import style from '../../styles/style'
import axios from "../../lib/axios"
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox';
import { useAuth } from "../../hooks/auth"
import { debounce, includes, isNull, round, startsWith, toString, random as lorandom, lowerCase } from "lodash"
import { extractErrorMessage, handleAxiosError, isValid, deleteZero, handleZero, handleNumber, handleInteger, extractErrorDescription } from "../../helpers/helper"
import Materials from "../../components/inventory/Items"
import { useDebounce } from "use-debounce"

const Items = () => {

    const { user } = useAuth({ middleware: 'guest' })
    const { t } = useTranslation()
    const { state } = useLocation()
    const { config, configFile, pusher, currencySymbol, company_id, palletTypes } = useStateContext()

    /* state */
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState([])

    const [count, setCount] = useState(10000)
    const [materials, setMaterials] = useState([])
    const [materialsList, setMaterialsList] = useState([])

    const [searchCode, setSearchCode] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.items_no || ''
        } else {
            return ''
        }
    })
    const [searchName, setSearchName] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.items_description || ''
        } else {
            return ''
        }
    })
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState('general')
    const [random, setRandom] = useState(null)
    const [unitsOfMeasure, setUnitsOfMeasure] = useState([])
    const [companyProductionTypes, setCompanyProductionTypes] = useState([])
    const [gradeCodes, setGradeCodes] = useState([])
    const [itemTypes, setItemTypes] = useState([])
    const [blockedOptions, setBlockedOptions] = useState([{ id: 1, name: "Blocked" }, { id: 2, name: "Blocked for sales" }, { id: 3, name: "Blocked for purchase" }])

    const [disableBoth, setDisableBoth] = useState(false)

    /* fields */
    const [itemNo, setItemNo] = useState('')
    const [description, setDescription] = useState('')
    const [additionalDesc, setAdditionalDesc] = useState('')
    const [unitOfMeasureId, setUnitOfMeasureId] = useState('')
    const [gradeCodeId, setGradeCodeId] = useState('')
    const [unitPrice, setUnitPrice] = useState(0)
    const [blockedSO, setBlockedSO] = useState(false)
    const [blockedPO, setBlockedPO] = useState(false)
    const [blocked, setBlocked] = useState(false)
    const [minimumStockQuantity, setMinimumStockQuantity] = useState(1)
    const [negativeInventory, setNegativeInventory] = useState(false)
    const [stockoutWarning, setStockoutWarninig] = useState(false)
    const [productionTypeId, setProductionTypeId] = useState('')
    const [inventoryItem, setInventoryItem] = useState(false)
    const [startingPerpetualInventory, setStartingPerpetualInventory] = useState(0)

    const [documents, setDocuments] = useState([])
    const [documentFileInputKey, setDocumentFileInputKey] = useState(Math.random())
    const documentInputRef = useRef(null)
    const documentLimit = 3
    const documentSizeLimit = 10 // in MBs

    const [itemTypeId, setItemTypeId] = useState('')
    const [itemTypeKey, setItemTypeKey] = useState('')
    const [palletTypeId, setPalletTypeId] = useState('')
    /* const [palletTypeKey, setPalletTypeKey] = useState('') */
    const [measurementSystem, setMeasurementSystem] = useState('imperial')

    const [thicknessValue, setThicknessValue] = useState(1)
    const [thicknessUnit, setThicknessUnit] = useState('in')
    const [widthValue, setWidthValue] = useState(1)
    const [widthUnit, setWidthUnit] = useState('in')
    const [lengthValue, setLengthValue] = useState(1)
    const [lengthUnit, setLengthUnit] = useState('in')
    const [boardFeet, setBoardFeet] = useState(0.007)

    const [bomNo, setBomNo] = useState('')
    const [bomDescription, setBomDescription] = useState('')
    const [bomUnitOfMeasure, setBomUnitOfMeasure] = useState('')

    const [totalCost, setTotalCost] = useState(0)
    const [totalQty, setTotalQty] = useState(0)

    const [selectedBlocked, setSelectedBlocked] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.items_blocked || []
        } else {
            return []
        }
    })
    const [selectedBlockedId, setSelectedBlockedId] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.items_blocked_id || []
        } else {
            return []
        }
    })
    const [soBlocked, setSoBlocked] = useState(0)
    const [poBlocked, setPoBlocked] = useState(0)
    const [allBlocked, setAllBlocked] = useState(0)

    const [selectedTypes, setSelectedTypes] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.items_types || []
        } else {
            return []
        }
    })
    const [selectedTypeIds, setSelectedTypeIds] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.items_type_ids || []
        } else {
            return []
        }
    })

    const isAllSelectedShipTo = blockedOptions.length > 0 && selectedBlocked.length === blockedOptions.length
    const isAllSelectedTypes = itemTypes.length > 0 && selectedTypes.length === itemTypes.length

    const [openBlocks, setOpenBlocks] = useState(false)
    const [openTypes, setOpenTypes] = useState(false)

    const [model, setModel] = useState({
        actions: true,
        company_name: true,
        description: true,
        description2: false,
        item_type_key: true,
        grade_code: false,
        item_no: true,
        unit_of_measure_name: false,
        unit_price: true
    })

    useEffect(() => {
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-item-deleted`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-item-created`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-item-updated`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-item-deleted-event`, data => {
            setMaterials((prev) => {
                return prev.filter((m) => m.item_id !== data.id)
            })
            setRandom(Math.random())
        })

        channelcreate.bind(`${localStorage.getItem('client_id')}-item-created-event`, data => {
            setRandom(Math.random())
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-item-updated-event`, data => {
            setRandom(Math.random())
        })

        /* UNIT OF MEASURE pusher events */

        const channelUnitOfMeasureDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-unitofmeasure-deleted`)
        const channelUnitOfMeasureCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-unitofmeasure-created`)
        const channelUnitOfMeasureUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-unitofmeasure-updated`)

        channelUnitOfMeasureDelete.bind(`${localStorage.getItem('client_id')}-unitofmeasure-deleted-event`, data => {
            getUnitsOfMeasure()
        })

        channelUnitOfMeasureCreate.bind(`${localStorage.getItem('client_id')}-unitofmeasure-created-event`, data => {
            getUnitsOfMeasure()
        })

        channelUnitOfMeasureUpdate.bind(`${localStorage.getItem('client_id')}-unitofmeasure-updated-event`, data => {
            getUnitsOfMeasure()
        })

        /* GRADE CODE pusher events */

        const channelGradeCodeDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-gradecode-deleted`)
        const channelGradeCodeCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-gradecode-created`)
        const channelGradeCodeUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-gradecode-updated`)

        channelGradeCodeDelete.bind(`${localStorage.getItem('client_id')}-gradecode-deleted-event`, data => {
            getGradeCodes()
        })

        channelGradeCodeCreate.bind(`${localStorage.getItem('client_id')}-gradecode-created-event`, data => {
            getGradeCodes()
        })

        channelGradeCodeUpdate.bind(`${localStorage.getItem('client_id')}-gradecode-updated-event`, data => {
            getGradeCodes()
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-item-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-item-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-item-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-unitofmeasure-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-unitofmeasure-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-unitofmeasure-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-gradecode-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-gradecode-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-gradecode-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productiontype-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productiontype-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productiontype-updated`)
        })
    }, [])

    useEffect(() => {
        if(isValid(company_id)) {
            const channelProductionTypeDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-productiontype-deleted`)
            const channelProductionTypeCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-productiontype-created`)
            const channelProductionTypeUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-productiontype-updated`)

            channelProductionTypeDelete.bind(`${localStorage.getItem('client_id')}-productiontype-deleted-event`, data => {
                getProductionTypes(company_id)
            })

            channelProductionTypeCreate.bind(`${localStorage.getItem('client_id')}-productiontype-created-event`, data => {
                getProductionTypes(company_id)
            })

            channelProductionTypeUpdate.bind(`${localStorage.getItem('client_id')}-productiontype-updated-event`, data => {
                getProductionTypes(company_id)
            })
        }

        return (() => {
            if(isValid(company_id)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productiontype-deleted`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productiontype-created`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productiontype-updated`)
            }
        })
    }, [company_id])

    useEffect(() => {
        if(unitOfMeasureId !== '') {
            const unitOfMeasureExists = unitsOfMeasure.find((uom) => uom.id == unitOfMeasureId)

            if(!unitOfMeasureExists) {
                setUnitOfMeasureId('')
            }
        }
    }, [unitsOfMeasure, unitOfMeasureId])

    useEffect(() => {
        if(gradeCodeId !== '') {
            const gradeCodeExists = gradeCodes.find((gc) => gc.id == gradeCodeId)

            if(!gradeCodeExists) {
                setGradeCodeId('')
            }
        }
    }, [gradeCodes, gradeCodeId])

    useEffect(() => {
        if(productionTypeId !== '') {
            const productionTypeExists = companyProductionTypes.find((pt) => pt.id == productionTypeId)

            if(!productionTypeExists) {
                setProductionTypeId('')
            }
        }
    }, [companyProductionTypes, productionTypeId])

    useEffect(() => {
        getTableView()
    }, [])

    const getTableView = async () => {
        setIsLoading(true)
        await axios.get(`/api/get-table-view?view_name=items_table&user_id=${user?.id}`, config)
            .then(res => {
                const model = res.data
                if (Object.keys(model?.success?.data).length > 0) {
                    setModel(model.success.data)
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.key == 'Enter' && open) {
                !e.target.id.toLowerCase().includes('autocomplete') && createItem()
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [
        open,
        itemTypeId, palletTypeId, itemNo, description, additionalDesc, unitPrice, gradeCodeId, unitOfMeasureId, blockedSO, blockedPO, blocked, documents, stockoutWarning,
        measurementSystem,
        inventoryItem, startingPerpetualInventory, productionTypeId, minimumStockQuantity,
        bomNo, bomDescription, bomUnitOfMeasure, materials
    ]);

    const handleChangePerson = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = [];

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
                duplicateRemoved.push(item);
            }
        })

        let ids = []

        value.forEach((item) => {
            if (ids.findIndex((o) => o.id === item.id) >= 0) {
                ids = ids.filter((x) => x.id === item.id);
            } else {
                ids.push(item.id);
            }
        })

        if (value[value.length - 1] === "all") {
            setSelectedBlocked(selectedBlocked.length === blockedOptions.length ? [] : blockedOptions);
            setSelectedBlockedId(selectedBlocked.length === blockedOptions.length ? [] : blockedOptions.map(i => i.id))
            return;
        }
        setSelectedBlocked(duplicateRemoved)
        setSelectedBlockedId(ids)
    }

    const handleClearBlocked = () => {
        setSelectedBlocked([])
        setSelectedBlockedId([])
    }

    const handleChangeType = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = [];

        value.forEach((type) => {
            if (duplicateRemoved.findIndex((o) => o.id === type.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === type.id);
            } else {
                duplicateRemoved.push(type);
            }
        })

        let ids = []

        value.forEach((type) => {
            if (ids.findIndex((o) => o.id === type.id) >= 0) {
                ids = ids.filter((x) => x.id === type.id);
            } else {
                ids.push(type.id);
            }
        })

        if (value[value.length - 1] === "all") {
            setSelectedTypes(selectedTypes.length === itemTypes.length ? [] : itemTypes);
            setSelectedTypeIds(selectedTypes.length === itemTypes.length ? [] : itemTypes.map(i => i.id))
            return;
        }
        setSelectedTypes(duplicateRemoved)
        setSelectedTypeIds(ids)
    }

    const handleClearTypes = () => {
        setSelectedTypes([])
        setSelectedTypeIds([])
    }

    /* methods */

    useEffect(() => {
        if (selectedBlockedId.includes(1)) setAllBlocked(1)
        else setAllBlocked(0)
        if (selectedBlockedId.includes(2)) setSoBlocked(1)
        else setSoBlocked(0)
        if (selectedBlockedId.includes(3)) setPoBlocked(1)
        else setPoBlocked(0)
    }, [selectedBlockedId])

    useEffect(() => {
        if(isValid(company_id)) {
            getItems(company_id)
        }
    }, [company_id, allBlocked, soBlocked, poBlocked, selectedTypeIds, random])

    useEffect(() => {
        if(isValid(company_id)) {
            getProductionTypes(company_id)
        }
    }, [company_id])

    useEffect(() => {
        if(measurementSystem === 'imperial') {
            setBoardFeet(
                (thicknessValue * widthValue * lengthValue)
                / (lengthUnit === 'in' ? 144 : 12)
            )
        }
    }, [measurementSystem, widthValue, widthUnit, lengthValue, lengthUnit, thicknessValue, thicknessUnit])

    useEffect(() => {
        if(items.length > 0 && isValid(itemTypeKey)) {
            setMaterialsList((prev) => {
                switch(itemTypeKey) {
                    case "pallet":
                        return items.filter((item) => item.item_type_key && includes(['component', 'lumber', 'other'], item.item_type_key))
                    case "component":
                        return items.filter((item) => item.item_type_key && includes(['lumber', 'other'], item.item_type_key))
                    case "lumber":
                        return items.filter((item) => item.item_type_key && includes(['other'], item.item_type_key))
                    case "other":
                        return []
                    default:
                        return prev
                }
            })
        } else {
            setMaterialsList([])
        }
    }, [items, itemTypeKey])

    useEffect(() => {
        calculateTotalCost()
        calculateTotalQuantity()
        if(materials.length <= 0) {
            setBomUnitOfMeasure(unitOfMeasureId)
        }
    }, [materials])

    useEffect(() => {
        getUnitsOfMeasure()
        getGradeCodes()
        getItemTypes()
    }, [])

    const resetModalStates = () => {
        setValue('general')
        setItemNo('')
        setDescription('')
        setAdditionalDesc('')
        setUnitOfMeasureId('')
        setGradeCodeId('')
        setUnitPrice(0)
        setBlockedSO(false)
        setBlockedPO(false)
        setBlocked(false)
        setMinimumStockQuantity(1)
        setStockoutWarninig(false)
        setInventoryItem(false)
        setStartingPerpetualInventory(0)
        setProductionTypeId('')
        setItemTypeId('')
        setItemTypeKey('')
        setPalletTypeId('')
        /* setPalletTypeKey('') */
        setThicknessValue(1)
        setThicknessUnit('in')
        setWidthValue(1)
        setWidthUnit('in')
        setLengthValue(1)
        setLengthUnit('in')
        setBoardFeet(0.007)
        setMaterials([])
        setBomNo('')
        setBomDescription('')
        setBomUnitOfMeasure('')
        setTotalCost(0)
        setTotalQty(0)
        setDocuments([])
    }

    const handleOpen = () => setOpen(true)
    const handleClose = () => {
        setOpen(false)
        resetModalStates()
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const handleBlocked = (event) => {
        setBlocked(event.target.checked);
        setDisableBoth(event.target.checked)
        setBlockedPO(event.target.checked)
        setBlockedSO(event.target.checked)
    }
    const handleBlockedPO = (event) => {
        setBlockedPO(event.target.checked);
    }
    const handleBlockedSO = (event) => {
        setBlockedSO(event.target.checked);
    }

    const handleQty = (e) => {
        const regex = /^(-?\d*)((\.(\d{0,2})?)?)$/i
        if (regex.test(e.target.value)) {
            if (e.target.value === '-') setMinimumStockQuantity(1)
            else setMinimumStockQuantity(e.target.value)
        }
    }

    const handleDeleteBlock = (e, id) => {
        const shouldDelete = selectedBlocked.find((x) => x.id === id)
        let blockedId = []
        if (shouldDelete) {
            const filtered = selectedBlocked.filter((x) => x.id !== shouldDelete.id)
            if (filtered.length > 0) {
                filtered.forEach((b) => {
                    if (b.hasOwnProperty('id')) blockedId.push(b.id)
                })
                setSelectedBlocked(filtered)
                setSelectedBlockedId(blockedId)
            } else {
                setSelectedBlocked([])
                setSelectedBlockedId([])
                setOpenBlocks(false)
            }
        }
    }

    const handleDeleteType = (e, id) => {
        const shouldDelete = selectedTypes.find((x) => x.id === id)
        let typeIds = []
        if (shouldDelete) {
            const filtered = selectedTypes.filter((x) => x.id !== shouldDelete.id)
            if (filtered.length > 0) {
                filtered.forEach((b) => {
                    if (b.hasOwnProperty('id')) {
                        typeIds.push(b.id)
                    }
                })
                setSelectedTypes(filtered)
                setSelectedTypeIds(typeIds)
            } else {
                setSelectedTypes([])
                setSelectedTypeIds([])
                setOpenTypes(false)
            }
        }
    }

    const handleChangeItemType = (e) => {
        setItemTypeId(e.target.value)
        const itemType = itemTypes.find((itype) => itype.id === e.target.value)
        if(itemType) {
            setItemTypeKey(itemType.key)
        }
    }

    const handleChangePalletType = (e) => {
        setPalletTypeId(e.target.value)
        /* const palletType = palletTypes.find((ptype) => ptype.id === e.target.value)
        if(palletType) {
            setPalletTypeKey(palletType.key)
        } */
    }

    const handleChangeMeasurementSystem = (e) => {
        setMeasurementSystem(e.target.value)
        if(e.target.value === 'imperial') {
            setWidthValue((prev) => round(prev / 2.54, 3))
            setWidthUnit('in')

            const oldLengthUnit = lengthUnit
            setLengthValue((prev) => {
                switch(oldLengthUnit) {
                    case 'cm':
                        return round(prev / 2.54, 3)
                    case 'm':
                        return round(prev / 0.3048, 3)
                }
            })
            setLengthUnit((prev) => {
                if(prev === 'cm') {
                    return 'in'
                } else {
                    return 'ft'
                }
            })

            setThicknessValue((prev) => round(prev / 2.54, 3))
            setThicknessUnit('in')
        } else {
            setWidthValue((prev) => round(prev * 2.54, 3))
            setWidthUnit('cm')

            const oldLengthUnit = lengthUnit
            setLengthValue((prev) => {
                switch(oldLengthUnit) {
                    case 'in':
                        return round(prev * 2.54, 3)
                    case 'ft':
                        return round(prev * 0.3048, 3)
                }
            })
            setLengthUnit((prev) => {
                if(prev === 'in') {
                    return 'cm'
                } else {
                    return 'm'
                }
            })

            setThicknessValue((prev) => round(prev * 2.54, 3))
            setThicknessUnit('cm')
        }
    }

    const handleAddDocumentClick = () => {
        documentInputRef.current?.click()
    }

    const handleAddDocument = async (e) => {
        if (e.target.files) {

            const document = e.target.files[0]
            if(document) {
                // Duplicate check
                if(documents.find((d) => d.name === document.name)) {
                    Swal.fire({
                        text: t('file_already_added'),
                        icon: "warning",
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FEB806'
                    })

                    setDocumentFileInputKey(Math.random())
                    return
                }

                // Size check
                if(document.size > (documentSizeLimit * 1000 * 1024)) {
                    Swal.fire({
                        text: t('selected_file_exceeds_x_mbs', {x: documentSizeLimit}),
                        icon: "warning",
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FEB806'
                    })

                    setDocumentFileInputKey(Math.random())
                    return
                }

                document['id'] = lorandom(1, 999999, false)
                // Required in order to be inspected after adding
                document['url'] = URL.createObjectURL(e.target.files[0])

                setDocuments((prev) => [...prev, document])
            }
        }

        setDocumentFileInputKey(Math.random())
    }

    const deleteDocs = async (e, id) => {
        e.preventDefault();

        const foundDoc = documents.find((document) => document.id === id)
        if(foundDoc) {
            setDocuments((prev) => prev.filter((document) => document.id !== foundDoc.id))
        }
    }

    const getItems = async (companyId = null) => {
        setIsLoading(true)

        await axios.get(`/api/items?a=index&blocked_for_sales=${soBlocked}&blocked_for_purchases=${poBlocked}&blocked=${allBlocked}&company_id=${isNull(companyId) ? localStorage.getItem('company_id') : companyId}&item_type=${selectedTypeIds.join(',')}`, config)
            .then(res => {
                const items = res.data
                setItems(items)
            })
            .catch(({ response }) => {
                Swal.fire({
                    text: extractErrorMessage(response),
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getItemTypes = async () => {
        setIsLoading(true)

        await axios.get(`/api/item-types`, config)
            .then(res => {
                const itypes = res.data
                setItemTypes(itypes)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getProductionTypes = async (companyId = null) => {
        setIsLoading(true)

        await axios.get(`/api/production-types?company_id=${isNull(companyId) ? localStorage.getItem('company_id') : companyId}`, config)
            .then(res => {
                const types = res.data
                setCompanyProductionTypes(types)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getUnitsOfMeasure = async () => {
        setIsLoading(true)
        await axios.get(`/api/units-of-measure`, config)
            .then(res => {
                const uom = res.data
                setUnitsOfMeasure(uom)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getGradeCodes = async () => {
        setIsLoading(true)
        await axios.get(`/api/grade-codes`, config)
            .then(res => {
                const uom = res.data
                setGradeCodes(uom)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const createItem = async () => {
        if(
            materials.length <= 0
            && (
                bomNo !== ''
                || bomDescription !== ''
            )
        ) {
            const understandsRiskBillOfMaterial = await Swal.fire({
                title: t('caution'),
                text: t('bom_data_loss_create_warning'),
                icon: 'warning',
                customClass: 'warning2',
                showCloseButton: true,
                iconColor: '#FEB806',
                reverseButtons: true,
                showCancelButton: true,

                confirmButtonText: t('yes'),
                cancelButtonText: t('negatory')
            }).then((result) => {
                return result.isConfirmed
            })

            if(!understandsRiskBillOfMaterial) {
                return
            }
        }

        setIsLoading(true)

        const formData = {}

        let itemCreated = false;

        const itemDimensions = {}
        switch(itemTypeKey) {
            case 'lumber':
                itemDimensions.measurement_system = measurementSystem
                itemDimensions.thickness_value = toString(thicknessValue)
                itemDimensions.thickness_unit = thicknessUnit
                itemDimensions.width_value = toString(widthValue)
                itemDimensions.width_unit = widthUnit
                itemDimensions.length_value = toString(lengthValue)
                itemDimensions.length_unit = lengthUnit
                break;
            case 'pallet':
            case 'component':
                itemDimensions.measurement_system = measurementSystem
                itemDimensions.width_value = toString(widthValue)
                itemDimensions.width_unit = widthUnit
                itemDimensions.length_value = toString(lengthValue)
                itemDimensions.length_unit = lengthUnit
                break;
            case 'other':
                itemDimensions.measurement_system = measurementSystem
                break;
            default:
        }

        formData['item_no'] = itemNo
        formData['description'] = description
        formData['description2'] = additionalDesc
        formData['unit_price'] = unitPrice == '' ? 0 : unitPrice
        formData['unit_of_measure_id'] = unitOfMeasureId
        formData['blocked_for_sales'] = blockedSO
        formData['blocked_for_purchases'] = blockedPO
        formData['blocked'] = blocked
        formData['grade_code_id'] = gradeCodeId
        /*  formData['can_hit_negative_inventory'] = negativeInventory */
        formData['can_issue_stockout_warnings'] = stockoutWarning
        formData['inventory_item'] = inventoryItem
        formData['starting_perpetual_inventory'] = startingPerpetualInventory
        formData['minumum_stock_quantity'] = minimumStockQuantity
        formData['company_id'] = localStorage.getItem('company_id')
        formData['production_type_id'] = productionTypeId
        formData['site_id'] = localStorage.getItem('site') ?? null
        formData['item_type_id'] = itemTypeId
        formData['pallet_type_id'] = palletTypeId
        formData['item_dimensions'] = itemDimensions
        formData['bom_no'] = bomNo
        formData['bom_description'] = bomDescription
        formData['bom_unit_of_measure_id'] = bomUnitOfMeasure
        formData['bom_lines'] = materials

        await axios.post(`/api/items`, formData, config)
            .then(({ data }) => {
                itemCreated = true

                const documentFormData = new FormData()
                documents.forEach((document, index) => {
                    // Regex patten credited to apsis0215 on regex101.com
                    const regex = /[^a-zA-Z0-9\+(\-){1,}]{1,}/g
                    const regexifiedDocumentName = lowerCase(document.name.replace(regex, '-'))

                    documentFormData.append(`${regexifiedDocumentName}`, document)
                })

                const itemId = data.success.created.id
                return axios.post(`/api/items/${itemId}/store-item-documents`, documentFormData, configFile)
            })
            .then(() => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: documents.length > 0 ? t('item_successfully_created_and_documents_successfully_uploaded') : t('item_successfully_created')
                })
                handleClose()
            })
            .catch(({ response }) => {
                if(itemCreated) {
                    Swal.fire({
                        icon: "warning",
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: 'FEB806',
                        title: t('item_successfully_created_but_documents_could_not_be_uploaded'),
                        text: extractErrorDescription(response)
                    })
                    handleClose()
                } else {
                    handleAxiosError({response: response})
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    /* data managment */

    const itemsFiltered = items?.filter(data => {
        if (searchCode === null)
            return data
        if (data.item_no.toLocaleLowerCase().includes(searchCode.toLocaleLowerCase()))
            return data
    })

    const filtered = itemsFiltered?.filter(data => {
        if (searchName === null)
            return data
        if (data.description.toLocaleLowerCase().includes(searchName.toLocaleLowerCase()))
            return data
    })

    const columns = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 1
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1
        },
        {
            field: 'description2',
            headerName: t('description2'),
            flex: 1
        },
        {
            field: 'item_type_key',
            headerName: t('item_type'),
            valueGetter: (value) => {
                return t(value?.value) ?? ''
            },
            flex: 1
        },
        {
            field: 'grade_code',
            headerName: t('grade_code'),
            flex: 1
        },
        {
            field: 'unit_of_measure_name',
            headerName: t('unit_of_measure'),
            flex: 1
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 1,
            renderCell: (params) => <UnitPrice params={params} />
        },
        {
            field: 'company_name',
            headerName: t('company'),
            flex: 1
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => <ItemsActions params={params} unitsOfMeasure={unitsOfMeasure} gradeCodes={gradeCodes} companyProductionTypes={companyProductionTypes} setIsLoading={setIsLoading} config={config} materialsList={materialsList} />
        }
    ]

    const columns2 = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 1
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1
        },
        {
            field: 'description2',
            headerName: t('description2'),
            flex: 1
        },
        {
            field: 'item_type_key',
            headerName: t('item_type'),
            valueGetter: (value) => {
                return t(value?.value) ?? ''
            },
            flex: 1
        },
        {
            field: 'grade_code',
            headerName: t('grade_code'),
            flex: 1,
            hide: true
        },
        {
            field: 'unit_of_measure_name',
            headerName: t('unit_of_measure'),
            flex: 1,
            hide: true
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 1,
            renderCell: (params) => <UnitPrice params={params} />
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => <ItemsActions params={params} unitsOfMeasure={unitsOfMeasure} gradeCodes={gradeCodes} companyProductionTypes={companyProductionTypes} setIsLoading={setIsLoading} config={config} materialsList={materialsList} />
        }
    ]

    const materialsColumns = [
        {
            field: 'item_no',
            headerName: t('item_number_abbr') + ' *',
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialNo params={params} t={t} materialsList={materialsList} setMaterialsList={setMaterialsList} handleSelectMaterial={handleSelectMaterial} />
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialDescription params={params} t={t} />
        },
        {
            field: 'qty_per_assembly',
            headerName: t('qty_per_assembly') + ' *',
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialQtyPerAssembly params={params} t={t} handleMaterialQty={handleMaterialQty}  />
        },
        {
            field: 'unit_of_measure_code',
            headerName: t('unit_of_measure'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialUnitOfMeasure params={params} t={t} />
        },
        {
            field: 'scrap',
            headerName: `${t('scrap')} (%)`,
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialScrap params={params} t={t} handleMaterialScrap={handleMaterialScrap} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialUnitPrice params={params} t={t} currencySymbol={currencySymbol} handleMaterialUnitPrice={handleMaterialUnitPrice} />
        },
        {
            field: 'total_price',
            headerName: t('total_price'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialTotalPrice params={params} t={t} currencySymbol={currencySymbol} calculateTotalCost={calculateTotalCost} />,
            valueGetter: (params) => params.row.qty_per_assembly * params.row.unit_price,
            sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2)
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            cellClassName: 'padding-0',
            renderCell: (params) => <MaterialActions params={params} materials={materials} setMaterials={setMaterials} setMaterialsList={setMaterialsList} />
        }
    ]

    const handleClearUnitOfMeasure = () => {
        setUnitOfMeasureId('')
    }

    const handleChangeUnitOfMeasure = (e) => {
        setUnitOfMeasureId(e.target.value)
        if(bomUnitOfMeasure === '' || materials.length <= 0) {
            setBomUnitOfMeasure(e.target.value)
        }
    }

    const handleChangeUnitOfMeasureAutocomplete = (e, value) => {
        e.preventDefault()

        setUnitOfMeasureId(value)
        if(bomUnitOfMeasure === '' || materials.length <= 0) {
            setBomUnitOfMeasure(value)
        }
    }

    const handleClearGradeCodeClick = () => {
        setGradeCodeId('')
    }

    const handleClearProductionTypeClick = () => {
        setProductionTypeId('')
    }

    const handleClearAllMaterialLines = (e) => {
        e.preventDefault()

        /**
         * For some reason, this got triggered by pressing Enter inside any of the enabled Lines inputs.
         * event.detail should be at least 1 to indicate that there has been a click and NOT a key press
         */
        if(e.detail > 0) {
            setMaterials([])
            setMaterialsList(prev => {
                const disabledListMaterials = prev.filter(m => m.disabled)
                disabledListMaterials.forEach(material => {
                    material.disabled = false
                });
                return prev
            })
            setTotalCost(0)
            setTotalQty(0)
        }
    }

    const createFilterArray = (no, description, blocked, blockedId, types, typeIds) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].items_no = no
        filters[0].items_description = description
        filters[0].items_blocked = blocked
        filters[0].items_blocked_id = blockedId
        filters[0].items_types = types
        filters[0].items_type_ids = typeIds

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    useEffect(() => {
        createFilterArray(searchCode, searchName, selectedBlocked, selectedBlockedId, selectedTypes, selectedTypeIds)
    }, [searchCode, searchName, selectedBlocked, selectedBlockedId, selectedTypes, selectedTypeIds])

    const handleAddMaterial = (e) => {
        e.preventDefault()

        if(e.detail > 0) {
            let counter = 0
            let msgMaterial = ''
            let msgQty = ''
            let msgBoth = t('please_select_material_and_enter_qty')
            materials.forEach(element => {
                if (element.item_no == '') {
                    counter += 1
                    msgMaterial = t('please_select_material')
                }
                if (element.qty_per_assembly == '' || parseInt(element.qty_per_assembly) < 1) {
                    counter += 1
                    msgQty = t('please_enter_qty')
                }
            })

            if (counter > 0) {
                if (msgMaterial !== '' && msgQty === '') {
                    Swal.fire({
                        icon: "warning",
                        text: msgMaterial,
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FFCA28'
                    })
                }
                if (msgQty !== '' && msgMaterial === '') {
                    Swal.fire({
                        icon: "warning",
                        text: msgQty,
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FFCA28'
                    })
                }
                if (msgQty !== '' && msgMaterial !== '') {
                    Swal.fire({
                        icon: "warning",
                        text: msgBoth,
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FFCA28'
                    })
                }


                return
            }

            const material = {
                "id": count,
                "item_id": '',
                "item_no": '',
                "unit_of_measure_code": '',
                "description": '',
                "unit_price": 0,
                "qty_per_assembly": 0,
                "scrap": 0,
            }

            setMaterials((prev) => [...prev, material])
            setCount(prev => prev + 1)
        }
    }

    const handleSelectMaterial = (value, id) => {
        const materialsCopy = [...materials]
        const material = materialsCopy.find(m => m.id === id)

        material.item_id = value.id
        material.item_no = value.item_no
        material.unit_of_measure_code = value.unit_of_measure_name ?? ''
        material.description = value.description
        material.unit_price = value.unit_price

        setMaterials(materialsCopy)
    }

    const handleMaterialQty = (value, id) => {
        const materialsCopy = [...materials]
        const material = materialsCopy.find(m => m.id === id)

        material.qty_per_assembly = value

        setMaterials(materialsCopy)

        calculateTotalQuantity()
    }

    const handleMaterialScrap = (value, id) => {
        const materialsCopy = [...materials]
        const material = materialsCopy.find(m => m.id === id)

        material.scrap = value

        setMaterials(materialsCopy)
    }

    const handleMaterialUnitPrice = (value, id) => {
        const materialsCopy = [...materials]
        const material = materialsCopy.find(m => m.id === id)

        material.unit_price = value

        setMaterials(materialsCopy)
    }

    const calculateTotalCost = () => {
        let tcost = 0
        materials.forEach(material => {
            tcost += parseInt(material.qty_per_assembly) * parseFloat(material.unit_price)
        })
        setTotalCost(round(tcost, 2))
    }

    const calculateTotalQuantity = () => {
        let tqty = 0
        materials.forEach(material => {
            tqty += parseInt(material.qty_per_assembly)
        })
        setTotalQty(tqty)
    }

    const hasEnabledMaterials = materialsList.find((mlitem) => mlitem.disabled !== true)

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout showFooter={!open}>
                <div className='p-5 pt-0'>
                    <div className='pb-5 bg-white mb-2 rounded-md'>
                        <div className='flex items-center justify-between border-b p-5'>
                            <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('items_list')}</p>
                            <Tooltip disableInteractive title={t('create_item')} placement='bottom'>
                                <div>
                                    <AddButton onClick={handleOpen}><i className="fa-solid fa-plus"></i></AddButton>
                                </div>
                            </Tooltip>
                        </div>
                        <div className='border-b pb-5'>
                            {/* ITEM NO. & DESCRIPTION */}
                            <div className='flex justify-between items-end w-full'>
                                <div className='pl-5 pr-2.5 pt-5 w-full'>
                                    <div className='flex justify-between items-center search'>
                                        <input type="text" placeholder={t('searchCode')} value={searchCode} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSearchCode(e.target.value)} />
                                        <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                    </div>
                                </div>

                                <div className='pl-2.5 pr-5 w-full'>
                                    <div className='flex justify-between items-center search'>
                                        <input type="text" placeholder={t('searchName')} value={searchName} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSearchName(e.target.value)} />
                                        <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                    </div>
                                </div>
                            </div>

                            {/* BLOCKED & ITEM TYPE */}
                            <div className='flex justify-between items-end w-full'>
                                <div className='pl-5 pr-2.5 pt-5 w-full'>
                                    <FormControl sx={{ width: '100%' }} focused={openBlocks}>
                                        <InputLabel>{t('search_by_blocked')}</InputLabel>
                                        <Select
                                            multiple
                                            value={selectedBlocked}
                                            open={openBlocks}
                                            onOpen={() => setOpenBlocks(true)}
                                            onClose={() => setOpenBlocks(false)}
                                            onChange={handleChangePerson}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((x) => (
                                                        <Chip
                                                            key={`b_option-${x.id}`}
                                                            label={x.name}
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                            onDelete={(e) => handleDeleteBlock(e, x.id)}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: (selectedBlocked.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={selectedBlocked ? (<IconButton sx={{ visibility: (selectedBlocked.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearBlocked}><ClearIcon /></IconButton>) : false}
                                        >

                                            {
                                                blockedOptions?.length > 0 ?
                                                    <MenuItem value="all">
                                                        <ListItemIcon>
                                                            <Checkbox checked={isAllSelectedShipTo} indeterminate={selectedBlocked.length > 0 && selectedBlocked.length < blockedOptions.length} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('select_all')} />
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                            }
                                            {
                                                blockedOptions.map((item) =>
                                                    <MenuItem value={item} key={item.id}>
                                                        <Checkbox checked={selectedBlocked?.findIndex((i) => i.id === item.id) >= 0} />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='pl-2.5 pr-5 w-full'>
                                    <FormControl sx={{ width: '100%' }} focused={openTypes}>
                                        <InputLabel>{t('search_by_item_type')}</InputLabel>
                                        <Select
                                            multiple
                                            value={selectedTypes}
                                            open={openTypes}
                                            onOpen={() => setOpenTypes(true)}
                                            onClose={() => setOpenTypes(false)}
                                            onChange={handleChangeType}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((x) => (
                                                        <Chip
                                                            key={`it_option-${x.id}`}
                                                            label={x.name}
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                            onDelete={(e) => handleDeleteType(e, x.id)}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            sx={{
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: 'none !important'
                                                },
                                                background: '#F5F5F5',
                                                borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": {
                                                    display: (selectedTypes.length > 0) ? 'none !important' : ''
                                                },
                                                "&.Mui-focused .MuiIconButton-root": {
                                                    color: 'rgba(0,0,0,.42)'
                                                }
                                            }}
                                            endAdornment={selectedTypes ? (<IconButton sx={{ visibility: (selectedTypes.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearTypes}><ClearIcon /></IconButton>) : false}
                                        >

                                            {
                                                itemTypes?.length > 0 ?
                                                    <MenuItem value="all">
                                                        <ListItemIcon>
                                                            <Checkbox checked={isAllSelectedTypes} indeterminate={selectedTypes.length > 0 && selectedTypes.length < itemTypes.length} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('select_all')} />
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                            }
                                            {
                                                itemTypes.map((type) =>
                                                    <MenuItem value={type} key={type.id}>
                                                        <Checkbox checked={selectedTypes?.findIndex((i) => i.id === type.id) >= 0} />
                                                        <ListItemText primary={t(type.key)} />
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className="pt-3">
                            <TableSharedCustom items={filtered} columns={user?.role === 'master_admin' ? columns : columns2} columnVisibilityModel={model} setModel={setModel} view='items' />
                        </div>
                    </div>

                </div>
            </AppLayout>

            <Modal open={open} onClose={handleClose}>
                <Box sx={{...style, overflowX: 'hidden'}}>

                    <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                        <div className='flex items-center justify-between w-full leading-none'>
                            <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t('create_item')}</p>
                            <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                                <button onClick={handleClose}><i className="fa-solid fa-plus"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='w-full bg-white h-auto min-h-full'>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example" variant='scrollable'>
                                    <Tab sx={{ textTransform: 'none' }} value="general" label={t('general')} icon={<i className="fa-solid fa-circle-info"></i>} iconPosition='start' />
                                    <Tab sx={{ textTransform: 'none' }} value="dimensions" label={t('dimensions')} icon={<i className="fa-solid fa-pen-ruler"></i>} iconPosition='start' />
                                    <Tab sx={{ textTransform: 'none' }} value="planning" label={t('planning')} icon={<i className="fa-solid fa-box-open"></i>} iconPosition='start' />
                                    <Tab sx={{ textTransform: 'none' }} value="bom" label={t('bill_of_materials')} icon={<i className="fa-solid fa-table"></i>} iconPosition='start' />
                                </TabList>
                            </Box>
                            <TabPanel value="general">
                                <div>
                                    <form onSubmit={(e) => e.preventDefault()} className='flex justify-center flex-col items-start mt-2'>
                                        {/* ITEM TYPE */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('item_type')} *</p>
                                            <div className='w-full'>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <Select
                                                        value={itemTypeId}
                                                        onChange={handleChangeItemType}
                                                        sx={{
                                                            width: '100%',
                                                            background: '#FCFCFC',
                                                            '.MuiOutlinedInput-input': {
                                                                padding: '9px !important'
                                                            },
                                                            boxShadow: 'none',
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #EEEFF2 !important'
                                                            }
                                                        }}
                                                    >
                                                        <MenuItem disabled value={''} sx={{display: 'none'}}><em>{t('item_type')}</em></MenuItem>
                                                        {itemTypes.map((itemType, index) =>
                                                            <MenuItem
                                                                key={`itypes-${index}`}
                                                                value={itemType.id}
                                                            >
                                                                    {t(itemType.key)}
                                                            </MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>

                                        {/* PALLET TYPE */}
                                        {itemTypeKey === 'pallet' ? (
                                            <div className='w-full pb-4'>
                                                <p className="text-[14px] text-[#718096]">{t('pallet_type')} *</p>
                                                <div className='w-full'>
                                                    <FormControl sx={{ width: '100%' }}>
                                                        <Select
                                                            value={palletTypeId}
                                                            onChange={handleChangePalletType}
                                                            sx={{
                                                                width: '100%',
                                                                background: '#FCFCFC',
                                                                '.MuiOutlinedInput-input': {
                                                                    padding: '9px !important'
                                                                },
                                                                boxShadow: 'none',
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    border: '1px solid #EEEFF2 !important'
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem disabled value={''} sx={{display: 'none'}}><em>{t('pallet_type')}</em></MenuItem>
                                                            {palletTypes.map((palletType, index) =>
                                                                <MenuItem
                                                                    key={`ptypes-${index}`}
                                                                    value={palletType.id}
                                                                >
                                                                        {t(palletType.key)}
                                                                </MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        ) : null}

                                        {/* ITEM NO. */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('no')} *</p>
                                            <div className='w-full'>
                                                <TextField
                                                    sx={{
                                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                    }}
                                                    type="text" className='w-full' name="item-no-input" value={itemNo} onChange={(e) => { setItemNo(e.target.value) }} autoFocus />
                                            </div>
                                        </div>

                                        {/* DESCRIPTION */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('description')} *</p>
                                            <div className='w-full'>
                                                <TextField
                                                    sx={{
                                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                    }}
                                                    type="text" className='w-full' name="description-input" value={description} onChange={(e) => { setDescription(e.target.value) }} />
                                            </div>
                                        </div>

                                        {/* DESCRIPTION 2 */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('description2')}</p>
                                            <div className='w-full'>
                                                <TextField
                                                    sx={{
                                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                    }}
                                                    type="text" className='w-full' name="description-2-input" value={additionalDesc} onChange={(e) => { setAdditionalDesc(e.target.value) }} />
                                            </div>
                                        </div>

                                        {/* GRADE CODE */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('grade_code')}</p>
                                            <div className='w-full'>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="general-gc-autocomplete"
                                                        sx={{
                                                            width: '100%',
                                                            background: '#FCFCFC',
                                                            '.MuiOutlinedInput-input': {
                                                                padding: '0 !important'
                                                            },
                                                            boxShadow: 'none',
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #EEEFF2 !important'
                                                            }
                                                        }}
                                                        value={gradeCodeId ? { id: gradeCodeId, label: gradeCodes?.find((grade) => grade.id === gradeCodeId )?.code || '' } : null}
                                                        options={gradeCodes?.map((option) => ({ id: option.id, label: option.code }))}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        renderOption={(props, option) => {
                                                            return (
                                                                <li {...props} key ={`general-gc-${option.id}`}>
                                                                    {option.label}
                                                                </li>
                                                            )
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        onChange={(a, b) => {a.preventDefault(); setGradeCodeId(b?.id || '')} }
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>

                                        {/* UNIT OF MEASURE */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('unit_of_measure')} *</p>
                                            <div className='w-full'>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <Autocomplete
                                                        disablePortal
                                                        disableClearable
                                                        id="general-uom-autocomplete"
                                                        sx={{
                                                            width: '100%',
                                                            background: '#FCFCFC',
                                                            '.MuiOutlinedInput-input': {
                                                                padding: '0 !important'
                                                            },
                                                            boxShadow: 'none',
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #EEEFF2 !important'
                                                            }
                                                        }}
                                                        value={unitOfMeasureId ? { id: unitOfMeasureId, label: unitsOfMeasure?.find((unit) => unit.id === unitOfMeasureId )?.code || '' } : null}
                                                        options={unitsOfMeasure?.map((option) => ({ id: option.id, label: option.code }))}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        renderOption={(props, option) => {
                                                            return (
                                                                <li {...props} key ={`general-uom-${option.id}`}>
                                                                    {option.label}
                                                                </li>
                                                            )
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        onChange={(a, b) => handleChangeUnitOfMeasureAutocomplete(a, b?.id || '') }
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>

                                        {/* UNIT PRICE */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('unit_price') + ` (${currencySymbol})`}</p>
                                            <div className='w-full'>
                                                <TextField
                                                    sx={{
                                                        width: '100%',
                                                        background: '#FCFCFC',
                                                        '.MuiOutlinedInput-input': {
                                                            padding: '9px !important'
                                                        },
                                                        boxShadow: 'none',
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: '1px solid #EEEFF2 !important'
                                                        }
                                                    }}
                                                    type="text"
                                                    className='w-full'
                                                    name="unit_price"
                                                    value={unitPrice}
                                                    onChange={(e) => handleNumber({
                                                        event: e,
                                                        setter: setUnitPrice
                                                    })}
                                                    onBlur={e => handleZero({
                                                        event: e,
                                                        setter: setUnitPrice
                                                    })}
                                                    onFocus={e => deleteZero({
                                                        event: e,
                                                        setter: setUnitPrice,
                                                        value: unitPrice
                                                    })}
                                                />
                                            </div>
                                        </div>

                                        { /* DOCUMENTS */ }
                                        <div
                                            className='w-full h-fit p-6 pb-4 pt-0 mb-4 rounded-md'
                                            style={{
                                                boxShadow: `0px 1px 2px 0px #1018280F, 0px 1px 2px 0px #1018280F, 0px -1px 4px 0px #1018280F`
                                            }}
                                        >
                                            <div className='pb-6 pt-1'>
                                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('documents')}</p>
                                            </div>

                                            <div className='w-full pb-4 flex flex-col align-center gap-1'>
                                                <Tooltip disableInteractive title={documents.length >= documentLimit ? t('cannot_upload_more_than_x_documents_at_a_time', {x: documentLimit}) : ''} arrow>
                                                    <span style={{textAlign: 'center'}}>
                                                        <Button disabled={documents.length >= documentLimit} onClick={handleAddDocumentClick} variant="contained" component="label" sx={{ backgroundColor: '#607d8b', "&:hover": { backgroundColor: '#6c8794' } }}>
                                                            <div className='flex items-center gap-3'><i className="fa-solid fa-cloud-arrow-up"></i> <p>{t('upload')}</p> </div>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                                <div className='w-[120px] text-[12px] text-[#6c8794] self-center'><p>{documentSizeLimit}MB max</p> </div>
                                                <input ref={documentInputRef} key={`file_input_key-${documentFileInputKey}`} hidden type="file" name="add-document" onChange={e => handleAddDocument(e)} />
                                            </div>
                                            {
                                                documents?.map((document, index) => (
                                                    <div className='pt-5 flex justify-between gap-2' key={`doc${index}`}>
                                                        <p className='text-blue-900 underline line-clamp-3'><a href={document.url} target="_blank">{document.name}</a></p>
                                                        <div className='flex gap-2 items-center'>
                                                            <a href={document.url} target="_blank" className='text-neutral-500'><Tooltip disableInteractive title={t('view')} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></a>
                                                            {['master_admin', 'office_manager'].includes(user?.role) ? (
                                                                <button className='text-neutral-500' onClick={e => deleteDocs(e, document.id)}><Tooltip disableInteractive title={t('delete')} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('blocked')} </p>
                                            <div className='w-full'>
                                                <Switch checked={blocked} onChange={handleBlocked} inputProps={{ 'aria-label': 'controlled' }} />
                                            </div>
                                        </div>
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('blockedSales')} </p>
                                            <div className='w-full'>
                                                <Switch disabled={disableBoth} checked={blockedSO} onChange={handleBlockedSO} inputProps={{ 'aria-label': 'controlled' }} />
                                            </div>
                                        </div>
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('blockedPurchase')} </p>
                                            <div className='w-full'>
                                                <Switch disabled={disableBoth} checked={blockedPO} onChange={handleBlockedPO} inputProps={{ 'aria-label': 'controlled' }} />
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </TabPanel>
                            <TabPanel value="dimensions">
                                <div>
                                    <form onSubmit={(e) => e.preventDefault()} className='flex justify-center flex-col items-start mt-2'>
                                        {/* MEASUREMENT SYSTEMS */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('measurement_system')}</p>
                                            <div className='w-full'>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="measurement-system-radio-buttons-group"
                                                        name="measurement-system-radio-buttons-group"
                                                        value={measurementSystem}
                                                        onChange={handleChangeMeasurementSystem}
                                                    >
                                                        <FormControlLabel value={'imperial'} control={<Radio />} label={t('imperial')} />
                                                        <FormControlLabel value={'metric'} control={<Radio />} label={t('metric')} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>

                                        {/* DIMENSIONS */}
                                        <div className='w-full md:w-1/2 lg:w-1/3 pb-4'>
                                            <Stack spacing={2}>
                                                {/* THICKNESS */}
                                                {includes(['lumber'], itemTypeKey) ? (
                                                    <Stack direction='row' alignItems='center' spacing={1}>
                                                        <Typography
                                                            color={"#718096"}
                                                            sx={{
                                                                minWidth: '70px',
                                                                width: '15%'
                                                            }}
                                                        >
                                                            {t('thickness')}
                                                        </Typography>

                                                        <TextField
                                                            sx={{
                                                                background: '#FCFCFC',
                                                                '.MuiOutlinedInput-input': {
                                                                    padding: '9px !important'
                                                                },
                                                                boxShadow: 'none',
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    border: '1px solid #EEEFF2 !important'
                                                                },
                                                                flexGrow: '1'
                                                            }}
                                                            name="thickness"
                                                            value={thicknessValue}
                                                            onChange={(e) => handleNumber({
                                                                event: e,
                                                                setter: setThicknessValue
                                                            })}
                                                            onBlur={e => handleZero({
                                                                event: e,
                                                                setter: setThicknessValue
                                                            })}
                                                            onFocus={e => deleteZero({
                                                                event: e,
                                                                setter: setThicknessValue,
                                                                value: thicknessValue
                                                            })}
                                                        />

                                                        <FormControl
                                                            sx={{
                                                                minWidth: '70px',
                                                                width: '15%'
                                                            }}
                                                        >
                                                            <Select
                                                                autoWidth
                                                                value={thicknessUnit}
                                                                onChange={(e) => { setThicknessUnit(e.target.value) }}
                                                                sx={{
                                                                    background: '#FCFCFC',
                                                                    '.MuiOutlinedInput-input': {
                                                                        padding: '9px !important'
                                                                    },
                                                                    boxShadow: 'none',
                                                                    '.MuiOutlinedInput-notchedOutline': {
                                                                        border: '1px solid #EEEFF2 !important'
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem value={measurementSystem === 'imperial' ? 'in' : 'cm'}>
                                                                    {measurementSystem === 'imperial' ? 'in' : 'cm'}
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Stack>
                                                ) : null}

                                                {/* WIDTH */}
                                                {includes(['lumber', 'pallet', 'component'], itemTypeKey) ? (
                                                    <Stack direction='row' alignItems='center' spacing={1}>
                                                        <Typography
                                                            color={"#718096"}
                                                            sx={{
                                                                minWidth: '70px',
                                                                width: '15%'
                                                            }}
                                                        >
                                                            {t('width')}
                                                        </Typography>

                                                        <TextField
                                                            sx={{
                                                                background: '#FCFCFC',
                                                                '.MuiOutlinedInput-input': {
                                                                    padding: '9px !important'
                                                                },
                                                                boxShadow: 'none',
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    border: '1px solid #EEEFF2 !important'
                                                                },
                                                                flexGrow: '1'
                                                            }}
                                                            name="width"
                                                            value={widthValue}
                                                            onChange={(e) => handleNumber({
                                                                event: e,
                                                                setter: setWidthValue
                                                            })}
                                                            onBlur={e => handleZero({
                                                                event: e,
                                                                setter: setWidthValue
                                                            })}
                                                            onFocus={e => deleteZero({
                                                                event: e,
                                                                setter: setWidthValue,
                                                                value: widthValue
                                                            })}
                                                        />

                                                        <FormControl
                                                            sx={{
                                                                minWidth: '70px',
                                                                width: '15%'
                                                            }}
                                                        >
                                                            <Select
                                                                autoWidth
                                                                value={widthUnit}
                                                                onChange={(e) => { setWidthUnit(e.target.value) }}
                                                                sx={{
                                                                    background: '#FCFCFC',
                                                                    '.MuiOutlinedInput-input': {
                                                                        padding: '9px !important'
                                                                    },
                                                                    boxShadow: 'none',
                                                                    '.MuiOutlinedInput-notchedOutline': {
                                                                        border: '1px solid #EEEFF2 !important'
                                                                    },
                                                                    flexGrow: '1'
                                                                }}
                                                            >
                                                                <MenuItem value={measurementSystem === 'imperial' ? 'in' : 'cm'}>
                                                                    {measurementSystem === 'imperial' ? 'in' : 'cm'}
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Stack>
                                                ) : null}

                                                {/* HEIGHT */}
                                                {includes(['lumber', 'pallet', 'component'], itemTypeKey) ? (
                                                    <Stack direction='row' alignItems='center' spacing={1}>
                                                        <Typography
                                                            color={"#718096"}
                                                            sx={{
                                                                minWidth: '70px',
                                                                width: '15%'
                                                            }}
                                                        >
                                                            {t('length')}
                                                        </Typography>

                                                        <TextField
                                                            sx={{
                                                                background: '#FCFCFC',
                                                                '.MuiOutlinedInput-input': {
                                                                    padding: '9px !important'
                                                                },
                                                                boxShadow: 'none',
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    border: '1px solid #EEEFF2 !important'
                                                                },
                                                                flexGrow: '1'
                                                            }}
                                                            name="length"
                                                            value={lengthValue}
                                                            onChange={(e) => handleNumber({
                                                                event: e,
                                                                setter: setLengthValue
                                                            })}
                                                            onBlur={e => handleZero({
                                                                event: e,
                                                                setter: setLengthValue
                                                            })}
                                                            onFocus={e => deleteZero({
                                                                event: e,
                                                                setter: setLengthValue,
                                                                value: lengthValue
                                                            })}
                                                        />

                                                        <FormControl
                                                            sx={{
                                                                minWidth: '70px',
                                                                width: '15%'
                                                            }}
                                                        >
                                                            <Select
                                                                autoWidth
                                                                value={lengthUnit}
                                                                onChange={(e) => setLengthUnit(e.target.value) }
                                                                sx={{
                                                                    background: '#FCFCFC',
                                                                    '.MuiOutlinedInput-input': {
                                                                        padding: '9px !important'
                                                                    },
                                                                    boxShadow: 'none',
                                                                    '.MuiOutlinedInput-notchedOutline': {
                                                                        border: '1px solid #EEEFF2 !important'
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem value={measurementSystem === 'imperial' ? 'in' : 'cm'}>
                                                                    {measurementSystem === 'imperial' ? 'in' : 'cm'}
                                                                </MenuItem>
                                                                <MenuItem value={measurementSystem === 'imperial' ? 'ft' : 'm'}>
                                                                    {measurementSystem === 'imperial' ? 'ft' : 'm'}
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Stack>
                                                ) : null}

                                                {/* BOARD FEET */}
                                                {(includes(['lumber'], itemTypeKey) && measurementSystem === 'imperial') ? (
                                                    <Stack direction='row' alignItems='center' spacing={1}>
                                                        <Typography
                                                            color={"#718096"}
                                                            textAlign='right'
                                                            sx={{
                                                                flexGrow: 1
                                                            }}
                                                        >
                                                            {t('total')}
                                                        </Typography>

                                                        <Typography
                                                            fontWeight='500'
                                                            textAlign='right'
                                                            fontSize='21px'
                                                            lineHeight='21px'
                                                            sx={{
                                                                minWidth: '120px'
                                                            }}
                                                        >
                                                            {round((boardFeet), 3)}
                                                        </Typography>

                                                        <Typography
                                                            color={"#718096"}
                                                        >
                                                            {t('board_feet')}
                                                        </Typography>
                                                    </Stack>
                                                ) : null}
                                            </Stack>
                                        </div>
                                    </form>
                                </div>
                            </TabPanel>
                            <TabPanel value="planning">
                                <div>
                                    <form onSubmit={(e) => e.preventDefault()} className='flex justify-center flex-col items-start mt-2'>
                                        {/* <div className="mb-5 flex gap-2 items-center">
                                        <label htmlFor="negative_inventory" className='text-sm'>{t('negative_inventory')}</label>
                                        <Checkbox checked={negativeInventory} onChange={e => setNegativeInventory(e.target.checked ? true : false)} />
                                    </div> */}

                                        {/*  <TextField type="text" variant='standard' label={t('minimum_stock_quantity')} sx={{ marginBottom: '20px' }} className='w-full mb-5 px-0 pt-0' name="minimum_stock_quantity" value={minimumStockQuantity} onChange={e => handleQty(e)} onBlur={e => handleZeroRest(e)} />

                                    <div className="mb-5 flex gap-2 items-center">
                                        <Tooltip disableInteractive  title={t('info-stock')} placement='bottom'>
                                            <i className="fa-solid fa-circle-info"></i>
                                        </Tooltip>
                                        <label htmlFor="stockout_warning" className='text-sm'>{t('stockout_warning')}</label>
                                        <Checkbox checked={stockoutWarning} onChange={e => setStockoutWarninig(e.target.checked ? true : false)} />
                                    </div> */}

                                        <div className='w-full flex gap-5 items-center pb-4'>
                                            <p className="text-[14px] text-[#718096]">
                                                <Tooltip disableInteractive title={t('info_inventory')} placement='bottom'>
                                                    <i className="fa-solid fa-circle-info"></i>
                                                </Tooltip>
                                                <span> {t('inventory_item')}</span>
                                            </p>
                                            <Checkbox checked={inventoryItem} onChange={e => setInventoryItem(e.target.checked ? true : false)} />
                                        </div>

                                        {inventoryItem ? (
                                            <div className='w-full pb-4'>
                                                <p className="text-[14px] text-[#718096]">{t('starting_perpetual_inventory')}</p>
                                                <Stack direction='row'>
                                                    <div className='w-1/2'>
                                                        <TextField
                                                            sx={{
                                                                width: '100%',
                                                                background: '#FCFCFC',
                                                                '.MuiOutlinedInput-root': {
                                                                    borderTopRightRadius: '0px',
                                                                    borderBottomRightRadius: '0px'
                                                                },
                                                                '.MuiOutlinedInput-input': {
                                                                    padding: '9px !important'
                                                                },
                                                                boxShadow: 'none',
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    border: '1px solid #EEEFF2 !important'
                                                                }
                                                            }}
                                                            type="number"
                                                            className='w-full'
                                                            name="starting_perpetual_inventory"
                                                            value={startingPerpetualInventory}
                                                            onChange={(e) => handleInteger({
                                                                event: e,
                                                                setter: setStartingPerpetualInventory,
                                                                allowNegatives: true
                                                            })}
                                                            onBlur={e => handleZero({
                                                                event: e,
                                                                setter: setStartingPerpetualInventory
                                                            })}
                                                            onFocus={e => deleteZero({
                                                                event: e,
                                                                setter: setStartingPerpetualInventory,
                                                                value: startingPerpetualInventory
                                                            })}
                                                        />
                                                    </div>

                                                    <div className='w-1/2'>
                                                        <FormControl sx={{ width: '100%' }}>
                                                            <Select
                                                                value={unitOfMeasureId}
                                                                displayEmpty
                                                                disabled
                                                                sx={{
                                                                    width: '100%',
                                                                    background: '#FCFCFC',
                                                                    borderTopLeftRadius: '0px',
                                                                    borderBottomLeftRadius: '0px',
                                                                    ".MuiSelect-icon": {
                                                                        display: 'none !important'
                                                                    },
                                                                    '.MuiOutlinedInput-input': {
                                                                        padding: '9px !important'
                                                                    },
                                                                    boxShadow: 'none',
                                                                    '.MuiOutlinedInput-notchedOutline': {
                                                                        border: '1px solid #EEEFF2 !important'
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>{t('unit_of_measure')}</em>
                                                                </MenuItem>
                                                                {unitsOfMeasure.map((item) => <MenuItem key={`planning-uom-${item.id}`} value={item.id}>{item.code}</MenuItem>)}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Stack>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('production_type')}</p>
                                            <div className='w-full'>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="planning-pt-autocomplete"
                                                        sx={{
                                                            width: '100%',
                                                            background: '#FCFCFC',
                                                            '.MuiOutlinedInput-input': {
                                                                padding: '0 !important'
                                                            },
                                                            boxShadow: 'none',
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #EEEFF2 !important'
                                                            }
                                                        }}
                                                        value={productionTypeId ? { id: productionTypeId, label: companyProductionTypes?.find((type) => type.id === productionTypeId )?.code || '' } : null}
                                                        options={companyProductionTypes?.map((option) => ({ id: option.id, label: option.code }))}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        renderOption={(props, option) => {
                                                            return (
                                                                <li {...props} key ={`planning-pt-${option.id}`}>
                                                                    {option.label}
                                                                </li>
                                                            )
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        onChange={(a, b) => {a.preventDefault(); setProductionTypeId(b?.id || '')} }
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </TabPanel>
                            <TabPanel value="bom">
                                <div>
                                    <form onSubmit={(e) => e.preventDefault()} className='flex justify-center flex-col items-start mt-2'>
                                        <div
                                            className='bg-white p-6 pb-[12px] pt-0 rounded-md w-full h-fit w-full'
                                            style={{
                                                boxShadow: `0px 1px 2px 0px #1018280F, 0px 1px 2px 0px #1018280F, 0px -1px 4px 0px #1018280F`
                                            }}
                                        >
                                            <div className='pb-6 pt-1 flex gap-3 items-center'>
                                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('general_information')}</p>
                                                {materials.length <= 0 ? (
                                                    <span style={{ color: '#0000006f', fontStyle: 'italic' }} className='text-[12px]'><i className="fa-solid fa-circle-info text-[#0000006f] mr-[5px]"></i>{t('please_add_line_to_start_defining_bom')}</span>
                                                ) : null}
                                            </div>

                                            <div className='w-full pb-4'>
                                                <p className="text-[14px] text-[#718096]">{t('no')} {materials.length > 0 ? '*' : null}</p>
                                                <div className='w-full'>
                                                    <TextField
                                                        disabled={materials.length <= 0}
                                                        sx={{
                                                            width: '100%',
                                                            background: '#FCFCFC',
                                                            '.MuiOutlinedInput-input': {
                                                                padding: '9px !important'
                                                            },
                                                            boxShadow: 'none',
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #EEEFF2 !important'
                                                            }
                                                        }}
                                                        type="text"
                                                        className='w-full'
                                                        name="bom_no"
                                                        value={bomNo}
                                                        onChange={(e) => { setBomNo(e.target.value) }}
                                                    />
                                                </div>
                                            </div>

                                            <div className='w-full pb-4'>
                                                <p className="text-[14px] text-[#718096]">{t('description')} {materials.length > 0 ? '*' : null}</p>
                                                <div className='w-full'>
                                                    <TextField
                                                        disabled={materials.length <= 0}
                                                        sx={{
                                                            width: '100%',
                                                            background: '#FCFCFC',
                                                            '.MuiOutlinedInput-input': {
                                                                padding: '9px !important'
                                                            },
                                                            boxShadow: 'none',
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #EEEFF2 !important'
                                                            }
                                                        }}
                                                        type="text"
                                                        className='w-full'
                                                        name="bom_description"
                                                        value={bomDescription}
                                                        onChange={(e) => { setBomDescription(e.target.value) }}
                                                    />
                                                </div>
                                            </div>

                                            <div className='w-full pb-4'>
                                                <p className="text-[14px] text-[#718096]">{t('unit_of_measure')} {materials.length > 0 ? '*' : null}</p>
                                                <div className='w-full'>
                                                    <FormControl sx={{ width: '100%' }}>
                                                        <Autocomplete
                                                            disablePortal
                                                            disableClearable
                                                            disabled={materials.length <= 0}
                                                            id="bom-uom-autocomplete"
                                                            sx={{
                                                                width: '100%',
                                                                background: '#FCFCFC',
                                                                '.MuiOutlinedInput-input': {
                                                                    padding: '0 !important'
                                                                },
                                                                boxShadow: 'none',
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    border: '1px solid #EEEFF2 !important'
                                                                }
                                                            }}
                                                            value={bomUnitOfMeasure ? { id: bomUnitOfMeasure, label: unitsOfMeasure?.find((unit) => unit.id === bomUnitOfMeasure )?.code || '' } : null}
                                                            options={unitsOfMeasure?.map((option) => ({ id: option.id, label: option.code }))}
                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                            renderOption={(props, option) => {
                                                                return (
                                                                    <li {...props} key ={`bom-uom-${option.id}`}>
                                                                        {option.label}
                                                                    </li>
                                                                )
                                                            }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                            onChange={(a, b) => setBomUnitOfMeasure(b?.id || '') }
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className='bg-white p-6 pb-[12px] pt-0 mt-6 rounded-md w-full h-fit w-full'
                                            style={{
                                                boxShadow: `0px 1px 2px 0px #1018280F, 0px 1px 2px 0px #1018280F, 0px -1px 4px 0px #1018280F`
                                            }}
                                        >
                                            <div className='pt-1'>
                                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('lines')}</p>
                                            </div>

                                            <div className='flex justify-end p-5 pt-0 border-b'>
                                                <Tooltip disableInteractive title={!hasEnabledMaterials ? t('no_sub_assemblies_found') : t('add_new_line')} placement='left'>
                                                    <div>
                                                        <AddButton disabled={!hasEnabledMaterials} onClick={handleAddMaterial}><i className="fa-solid fa-plus"></i></AddButton>
                                                    </div>
                                                </Tooltip>
                                            </div>

                                             {/* MATERIALS */}
                                             <div className='border-b border-l border-r rounded'>
                                                {
                                                    materials.length > 0 ?
                                                        <div className='flex justify-end w-full p-2'>
                                                            <button className='px-2 py-1 text-white bg-red-600 font-bold rounded-md mb-1 roboto text-xs uppercase' onClick={handleClearAllMaterialLines}>{t('clear_all')}</button>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <Materials items={materials} columns={materialsColumns} random={random} />
                                            </div>

                                            <div className='flex justify-end w-full p-5'>
                                                <div className='flex flex-col gap-y-4 w-full sm:w-1/3'>
                                                    <div className='flex justify-between items-center'>
                                                        <p className="font-[400] text-[#88909C] text-[14px]">{t('total_cost')}</p>
                                                        <p className='font-[500] text-[16px]'>{currencySymbol}{totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                                    </div>
                                                    <div className='flex justify-between items-center'>
                                                        <p className="font-[400] text-[#88909C] text-[14px]">{t('totalQty')}</p>
                                                        <p className='font-[500] text-[16px]'>{totalQty}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </TabPanel>
                        </TabContext>
                        <div className='w-full flex justify-end px-6 pb-14'>
                            <button type="button" onClick={createItem} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#DBA34B] hover:bg-[#CF7300] disabled:bg-[#B8B7BC]'>
                                <span className='pr-2'><i className="fa-solid fa-plus"></i></span> {t('create')}
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
};

export default Items;

const ItemsActions = (params) => {

    const { t } = useTranslation()

    const deleteItems = async (id) => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_item') + params.params.row.item_no + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        await axios.delete(`/api/items/${id}`, params.config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
        })
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('edit')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <Link to={`${params.params.row.id}`}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-pencil"></i></span></Link>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteItems(params.params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}

const UnitPrice = (params) => {
    const { currencySymbol } = useStateContext()

    return (
        <>{currencySymbol}{params.params.row.unit_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const MaterialNo = ({ params, t, materialsList, setMaterialsList, handleSelectMaterial }) => {
    const [material, setMaterial] = useState(' ')

    const handleChange = (e) => {
        setMaterial(prev => {
            if (prev == ' ') {
                return prev
            }
            if (prev) {
                prev.disabled = false
            }
            return prev
        })
        setMaterial(e.target.value)
        handleSelectMaterial(e.target.value, params.row.id)
        setMaterialsList(prev => {
            const target = prev.find(m => m.id == e.target.value.id)
            target.disabled = true
            return prev
        })
    }

    useEffect(() => {
        if (materialsList.length > 0) {
            setMaterial(() => {
                const target = materialsList?.find(m => m.id === params.row.item_id)

                if(target) {
                    target.disabled = true
                    return target
                }

                return ' '
            })
        }
    }, [materialsList])

    return (
        <FormControl variant="standard" sx={{ width: '100%' }}>
            <Select defaultValue=' ' value={material} onChange={e => handleChange(e)}>
                <MenuItem value=' ' disabled sx={{ fontStyle: 'italic', display: 'none' }}>{t('select_item')}</MenuItem>
                {
                    materialsList?.map((m, index) => (
                        <MenuItem value={m} key={`materialslist-${m.id}`} disabled={m.disabled}>{m.item_no}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

const MaterialDescription = ({ params, t }) => {
    return (
        <TextField
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={params.value}
            disabled
        />
    )
}

const MaterialQtyPerAssembly = ({ params, t, handleMaterialQty }) => {
    const [qty, setQty] = useState(params.value)
    const [debouncedQty] = useDebounce(qty, 250)

    const handleQty = (e) => {
        const regex = /^(-?\d{0,5})$/i
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty('')
            } else {
                setQty(e.target.value)
            }
        }
    }

    useEffect(() => {
        if(debouncedQty && isFinite(debouncedQty)) {
            handleMaterialQty(debouncedQty, params.row.id)
        } else {
            handleMaterialQty(0, params.row.id)
        }
    }, [debouncedQty])

    return (
        <TextField
            disabled={params.row.item_id === ''}
            type="text" variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={qty}
            onChange={(e) => { handleQty(e) }}
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const MaterialUnitOfMeasure = ({ params, t }) => {
    return (
        <TextField
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={params.value}
            disabled
        />
    )
}

const MaterialScrap = ({ params, t, handleMaterialScrap }) => {
    const [scrap, setScrap] = useState(params.value)
    const [debouncedScrap] = useDebounce(scrap, 250)

    const handleScrap = (e) => {
        const regex = /^(-?\d*)((\.(\d{0,2})?)?)$/i
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setScrap('')
            } else if(e.target.value > 100) {
                Swal.fire({
                    icon: "warning",
                    text: t('scrap_cannot_exceed_x_pct', {x: 100}),
                    customClass: 'warning2',
                    showCloseButton: true,
                    iconColor: '#FFCA28'
                })
                setScrap(100)
            } else {
                setScrap(e.target.value)
            }
        }
    }

    useEffect(() => {
        if(debouncedScrap && isFinite(debouncedScrap)) {
            handleMaterialScrap(debouncedScrap, params.row.id)
        } else {
            handleMaterialScrap(0, params.row.id)
        }
    }, [debouncedScrap])

    return (
        <TextField
            disabled={params.row.item_id === ''}
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={scrap}
            onChange={(e) => { handleScrap(e) }}
            onBlur={e => handleZero({
                event: e,
                setter: setScrap
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setScrap,
                value: scrap
            })}
        />
    )
}

const MaterialUnitPrice = ({ params, t, currencySymbol, handleMaterialUnitPrice }) => {
    const [unitPrice, setUnitPrice] = useState(params.value)
    const [debouncedUnitPrice] = useDebounce(unitPrice, 250)

    useEffect(() => {
        setUnitPrice(params.value)
    }, [params.value])

    useEffect(() => {
        if(debouncedUnitPrice && isFinite(debouncedUnitPrice)) {
            handleMaterialUnitPrice(debouncedUnitPrice, params.row.id)
        } else {
            handleMaterialUnitPrice(0, params.row.id)
        }
    }, [debouncedUnitPrice])

    const handleUnitPrice = (e) => {
        const regex = /^(-?\d*)((\.(\d{0,30})?)?)$/i
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setUnitPrice('')
            } else {
                setUnitPrice(e.target.value)
            }
        }
    }

    return (
        <div className='flex items-center'>
            <span style={{lineHeight: '32px', padding: '4px 0 5px', color: params.row.item_id === '' ? 'rgba(0, 0, 0, 0.38)' : 'inherit'}}>{currencySymbol}</span>
            <TextField
                disabled={params.row.item_id === ''}
                type="text"
                variant='standard'
                className='w-full mb-5 px-0 pt-0'
                value={unitPrice}
                onChange={(e) => { handleUnitPrice(e) }}
                onBlur={e => handleZero({
                    event: e,
                    setter: setUnitPrice
                })}
                onFocus={e => deleteZero({
                    event: e,
                    setter: setUnitPrice,
                    value: unitPrice
                })}
            />
        </div>
    )
}

const MaterialTotalPrice = ({ params, t, currencySymbol, calculateTotalCost }) => {
    const [totalPrice, setTotalPrice] = useState(0)

    useEffect(() => {
        setTotalPrice(round(params.row.qty_per_assembly * params.row.unit_price, 2))
        calculateTotalCost()
    }, [params.row.qty_per_assembly, params.row.unit_price])

    return (
        <>{currencySymbol}{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const MaterialActions = ({ params, materials, setMaterials, setMaterialsList }) => {

    const { t } = useTranslation()

    const deleteMaterial = async () => {
        const target = materials.filter(m => m.id !== params.row.id)
        setMaterials(target)
        setMaterialsList(prev => {
            const material = prev.find(m => m.id == params.row.item_id)
            if (Boolean(material)) {
                material.disabled = false
            }
            return prev
        })
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={deleteMaterial}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
