const UnloadingTruckIcon = ({shouldAnimate = true, size='regular', black = false}) => {
    const renderThis = () => {
        switch(size) {
            case "small":
                return (
                    <span className="fa-stack" style={{fontSize: "8px"}}>
                        <i className="fa-solid fa-truck fa-stack-2x" style={{color: black ? "#000000" : "#b7472a"}}></i>
                        <i className={`fa-solid fa-arrow-left fa-stack-1x fa-inverse ${shouldAnimate ? 'arrow-left' : ''}`} style={{top: '-2px', left: '-2.5px'}}></i>
                    </span>
                )
            default:
                return (
                    <span className="fa-stack" style={{fontSize: "9px"}}>
                        <i className="fa-solid fa-truck fa-stack-2x" style={{color: black ? "#000000" : "#b7472a"}}></i>
                        <i className={`fa-solid fa-arrow-left fa-stack-1x fa-inverse ${shouldAnimate ? 'arrow-left' : ''}`} style={{top: '-2px', left: '-2.5px'}}></i>
                    </span>
                )
        }
    }

    return renderThis()
}

export default UnloadingTruckIcon
