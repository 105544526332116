import React, {Children, useEffect, useRef, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {Box, Button, TextField, Tooltip} from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import TableShared from '../TableShared'
import axios from '../../lib/axios'
import Loading from '../Loading'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import {Link, useNavigate, useParams} from "react-router-dom"

import {useStateContext} from '../../context/ContextProvider'
import styles from './Assigned.module.css'
import OrderSignatureModal from './OrderSignatureModal'
import {useAuth} from '../../hooks/auth'
import {DEFAULT_ROUTES} from '../../constants/constants'
import RejectLoad from './RejectLoad'
import CommentHistoryModal from '../CommentHistoryModal'
import CommentHistoryButton from '../CommentHistoryButton'
import { extractErrorMessage, handleAxiosError, isValid, isValidRFC2822 } from '../../helpers/helper'
import { includes, startsWith } from 'lodash'

const ProceedCollection = () => {
    const { t } = useTranslation()
    const params = useParams()
    const { config, configFile, setCompany_id: setCompanyId, setChoosesite: setSite, pusher } = useStateContext()
    const navigate = useNavigate()
    const { user, logout } = useAuth({ middleware: 'guest' })

    const [collection, setCollection] = useState([])
    const [errorSender, setErrorSender] = useState(false)
    const [errorTrailer, setErrorTrailer] = useState(false)
    const [errorMail, setErrorMail] = useState(false)

    /* Form Inputs */

    const [no, setNo] = useState('')
    const [collectionDate, setCollectionDate] = useState(dayjs())
    const [addressName, setAddressName] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zip, setZip] = useState('')
    const [deliveryNo, setDeliveryNo] = useState('')
    const [items, setItems] = useState([])
    const [comments, setComments] = useState('')
    const [commentHistory, setCommentHistory] = useState([])

    const [senderName, setSenderName] = useState('')
    const [senderEmail, setSenderEmail] = useState('')
    const [pickupNo, setPickupNo] = useState('')

    const inputRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false)
    const [isOrderInTransit, setIsOrderInTransit] = useState(false)
    const [orderSignatureModalOpen, setOrderSignatureModalOpen] = useState(false)
    const [rejectionModalOpen, setRejectionModalOpen] = useState(false)
    const [openCommentHistory, setOpenCommentHistory] = useState(false)
    const [siteButton, setSiteButton] = useState(false)
    const [blocked, setBlocked] = useState(false)

    /* Signature */
    const [signature, setSignature] = useState(null)

    /* Documents */

    const [driverDocs, setDriverDocs] = useState([])
    const [hasDocs, setHasDocs] = useState(false)

    useEffect(() => {
        if (driverDocs.length < 1) {
            setHasDocs(false)
        }
    }, [driverDocs])

    useEffect(() => {
        const collectionChannelDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('site')}`)
        const collectionChannelUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)
        const orderDocumentChannelUpload = pusher.subscribe(`${localStorage.getItem('client_id')}-purchase-${params.id}-document-event`)

        // Bind collection channel pushes

        collectionChannelDelete.bind(`${localStorage.getItem('client_id')}-purchaseorder-deleted-event-site-${localStorage.getItem('site')}`, data => {
            if (Number(data.current_driver) == Number(user?.id)) {
                Swal.fire({
                    icon: "info",
                    customClass: 'info',
                    showCloseButton: true,
                    iconColor: '#a8d5f1',
                    text: data.message_localized ?? data.message
                }).then((result) => {
                    if (includes(['tpl_driver', 'service_provider'], user?.role)) {
                        logout(true, user?.role)
                    } else {
                        navigate('/available-loads', {
                            replace: true
                        })
                    }
                })

                navigate('/available-loads', {
                    replace: true
                })
            }
        })

        collectionChannelUpdate.bind(`${localStorage.getItem('client_id')}-purchaseorder-updated-event-site-${localStorage.getItem('site')}`, data => {
            if (Number(data.current_driver) == Number(user?.id)) {
                getData(true)
            } else {
                if (
                    data.retrieved
                    && Number(data.id) == Number(params.id)
                ) {
                    Swal.fire({
                        icon: "info",
                        customClass: 'info',
                        showCloseButton: true,
                        iconColor: '#a8d5f1',
                        text: (isValid(data.identifier) && startsWith(data.identifier, 'PO'))
                            ? t('x_no_has_been_retrieved', {
                                x: t('purchase_order'),
                                no: data.identifier
                            }) : t('x_has_been_retrieved', {
                                x: t('purchase_order')
                            })
                    }).then((result) => {
                        if (includes(['tpl_driver', 'service_provider'], user?.role)) {
                            logout(true, user?.role)
                        } else {
                            navigate('/available-loads', {
                                replace: true
                            })
                        }
                    })
                }
            }
        })

        orderDocumentChannelUpload.bind(`${localStorage.getItem('client_id')}-purchase-${params.id}-document-event`, data => {
            if(data.is_deleted) {
                setDriverDocs((prev) => {
                    const deletedDoc = prev.find((i) => i.id === data.id)
                    const docsUpdated = deletedDoc ? prev.filter((i) => i.id !== deletedDoc.id) : prev
                    return docsUpdated
                })
            } else {
                getDriverDocs()
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-retrieved-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchase-${params.id}-document-event`)
        })
    }, [])

    useEffect(() => {
        getData()
    }, [params.id])


    useEffect(() => {
        getSignature()
    }, [orderSignatureModalOpen])

    const getData = async (dueToPusherEvent = false) => {
        await getCollection(dueToPusherEvent)
            .then(() => {
                return getSignature()
            })
            .then(() => {
                return getDriverDocs()
            })
            .catch(({ response }) => {
                console.log(response)

                navigate(DEFAULT_ROUTES[user?.role], {
                    replace: true
                })
            })
    }

    const getCollection = async (dueToPusherEvent = false) => {
        setIsLoading(true)
        await axios
            .get(`/api/purchase-orders/${params.id}`, config)
            .then(res => {
                const data = res.data
                if (!["assigned_to_driver", "in_transit"].includes(data.po_status)) {
                    navigate(DEFAULT_ROUTES[user?.role], {
                        replace: true
                    })
                } else {
                    setNo(data.po_number)
                    setCollectionDate(data.collection_date)
                    setAddressName(data.purchase_address_name ?? data.vendor_name)
                    setAddress(data.address)
                    setCity(data.city)
                    setState(data.state)
                    setZip(data.zip)
                    setPickupNo(data.pickup_trailer_no ?? '')
                    setDeliveryNo(data.drop_trailer_no ?? '')
                    setSenderName(data.sender_name ?? '')
                    setSenderEmail(data.vendor_email ?? '')
                    setItems(data.purchase_order_items)
                    setCommentHistory(data.comment_history)
                    setCollection(data)
                    if (data.po_status_id == 5) setSiteButton(true)
                    if (data.po_status_id == 5) setBlocked(true)
                    if (data.po_status_id == 6) setBlocked(true)
                    if (!dueToPusherEvent) setComments('')
                }
            })
            .catch(({ response }) => {
                Swal.fire({
                    text: extractErrorMessage(response),
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
        // TODO: check if the shipment is also eligible for completion (presumably you'd check if a signature is retrieved)
    }

    const saveCollection = async (e, shouldNavigate = true) => {
        e.preventDefault()
        setIsLoading(true)

        if (senderEmail != '' && !isValidRFC2822(senderEmail)) {
            setErrorMail(true)
            setIsLoading(false)
            return
        } else {
            setErrorMail(false)
        }

        if(!pickupNo) {
            setErrorTrailer(true)
            setIsLoading(false)
            return
        }

        if(!senderName) {
            setErrorSender(true)
            setIsLoading(false)
            return
        }

        const formData = {}

        formData['drop_trailer_no'] = deliveryNo
        formData['pickup_trailer_no'] = pickupNo
        formData['sender_name'] = senderName
        formData['vendor_email'] = senderEmail
        formData['comments'] = comments

        await axios
            .put(`/api/purchase-orders/${params.id}/proceed-collection`, formData, config)
            .then(({ data }) => {
                if (shouldNavigate) {
                    navigate('/available-loads', {
                        replace: true,
                        state: {
                            message: data.success.message,
                            severity: 'success',
                        }
                    })
                }
                setErrorTrailer(false)
                setErrorSender(false)
                setComments('')
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const inTransitCollection = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        if(senderEmail != '' && !isValidRFC2822(senderEmail)) {
            setErrorMail(true)
            setIsLoading(false)
            return
        } else {
            setErrorMail(false)
        }

        if(!pickupNo) {
            setErrorTrailer(true)
            setIsLoading(false)
            return
        }

        if(!senderName) {
            setErrorSender(true)
            setIsLoading(false)
            return
        }

        await saveCollection(e, false)

        setBlocked(true)
        await axios
            .put(`/api/driver/accept-collection/${params.id}`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                setSiteButton(true)
            })
            .catch(({ response }) => {
                Swal.fire({
                    text: extractErrorMessage(response),
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const onSiteCollection = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setBlocked(true)

        const formData = {}

        formData['comments'] = comments

        await axios
            .put(`api/driver/mark-collection-as-on-site/${params.id}`, formData, config)
            .then(({ data }) => {
                navigate('/available-loads', {
                    replace: true,
                    state: {
                        message: data.success.message,
                        severity: 'success',
                    }
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    /* Document uploading and retrieval */

    const handleUploadClick = () => {
        inputRef.current?.click()
    }

    const getDriverDocs = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/order-documents?order_id=${params.id}&order_type=purchase&document_type=drivers_document`, config)
            .then(res => {
                const files = res.data
                setDriverDocs(files)
                if (files.length < 1) {
                    setHasDocs(false)
                } else {
                    setHasDocs(true)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const deleteDriverDoc = async (id, name) => {
        const isConfirm = await Swal.fire({
            title: t('delete_this_document'),
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            setIsLoading(true)
            return result.isConfirmed
        })

        if (!isConfirm) {
            setIsLoading(false)
            return
        }

        await axios
            .delete(`/api/order-documents/${id}`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                /* getDriverDocs() */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleFileChange = async (e, type) => {
        if (!e.target.files) {
            return;
        }

        setIsLoading(true)

        // 🚩 do the file upload here normally...
        const formData = new FormData()

        formData.append('file', e.target.files[0])
        formData.append('order_id', params.id)
        formData.append('order_type', 'purchase')
        formData.append('document_type', type)

        await axios
            .post(`/api/store-documents`, formData, configFile)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                        customClass: 'success',
                        showCloseButton: true,
                        iconColor: '#00B78E',
                    text: data.message
                })
                inputRef.current.value = ""
                /* getDriverDocs() */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    /* Signature uploading and retrieval */

    const getSignature = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/signature?order_id=${params.id}&order_type=purchase`, config)
            .then(res => {
                const signature = res.data
                if (signature.length === 0) {
                    setSignature(null)
                } else {
                    setSignature(signature)
                    setIsOrderInTransit(true)
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleSignatureClick = () => {
        if (!hasDocs) {
            Swal.fire({
                title: t('check_documents'),
                text: t('check_documents_text'),
                showDenyButton: true,
                confirmButtonText: t('yes'),
                denyButtonText: t('negatory'),
                customClass: {
                    popup: styles['check-documents-popup'],
                    title: styles['check-documents-title'],
                    htmlContainer: styles['check-documents-html-container'],
                    actions: styles['check-documents-actions'],
                    confirmButton: styles['check-documents-button'],
                    denyButton: styles['check-documents-button'],
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    handleUploadClick();
                } else if (result.isDenied || result.isDismissed) {
                    setOrderSignatureModalOpen(!orderSignatureModalOpen)
                }
            })
        }
        else {
            setOrderSignatureModalOpen(!orderSignatureModalOpen)
        }
    }

    const columns = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 1
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'qty_ordered',
            headerName: t('qty_ordered'),
            renderHeader: () => (
                <div className='flex flex-col xl:flex-row gap-1 leading-[14px] text-[#88909C] font-[400]'>
                    <span>{t('quantity')}</span>
                    <span>{t('ordered')}</span>
                </div>
            ), flex: 1,
            align: 'center',
            headerAlign: 'center',
            minWidth: 200
        },
        {
            field: 'qty_received',
            headerName: t('quantity_received'),
            renderHeader: () => (
                <div className='flex flex-col xl:flex-row gap-1 leading-[14px] text-[#88909C] font-[400]'>
                    <span>{t('quantity')}</span>
                    <span>{t('received')}</span>
                </div>
            ), flex: 1,
            align: 'center',
            headerAlign: 'center',
            minWidth: 200
        },
        {
            field: 'vendor_count',
            headerName: t('vendor_count'),
            renderHeader: () => (
                <div className='flex flex-col xl:flex-row gap-1 leading-[14px] text-[#88909C] font-[400]'>
                    <span>{t('vendor')}</span>
                    <span>{t('count')}</span>
                </div>
            ),
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            minWidth: 200
        },
    ]

    return (
        <>
            {isLoading ? <Loading position='fixed' /> : ''}
            <Box>
                <div className='flex justify-between items-center p-5 pr-0 pt-0 pb-0 top-0 bottom-0 left-0 sticky md:static z-50 max-[560px]:pl-0' style={{ backgroundColor: '#b7472a' }}>
                    <div className='flex gap-4 items-center'>
                        <Link to="/available-loads">
                            <IconButton sx={{ color: 'white' }}>
                                <ClearIcon />
                            </IconButton>
                        </Link>
                        <p className='text-xl roboto font-semibold text-white max-[560px]:text-xs'>{collection.vendor_name} </p>
                    </div>
                    <div className='flex'>
                        {
                            siteButton ?
                                <button type="button" onClick={onSiteCollection} className='text-white px-7 py-5 uppercase self-end roboto transition duration-150 bg-transparent hover:bg-zinc-600 disabled:bg-[hsla(0,0%,100%,.12)] max-[560px]:text-xs max-[560px]:py-3 max-[560px]:px-2'>
                                    <i className="fa-solid fa-warehouse mr-1.5"></i> {t('collection_on_site')}
                                </button>
                                :

                                (isOrderInTransit ?
                                    <>
                                        < button type="button" onClick={saveCollection} className='text-white px-7 py-5 uppercase self-end roboto transition duration-150 bg-transparent hover:bg-zinc-600 disabled:bg-[hsla(0,0%,100%,.12)] max-[560px]:text-xs max-[560px]:py-3 max-[560px]:px-2' >
                                            {t('save')}
                                        </button>
                                        <button type="button" onClick={inTransitCollection} className='text-white px-7 py-5 uppercase self-end roboto transition duration-150 bg-[#4caf50] hover:bg-[#5b8948] disabled:bg-[hsla(0,0%,100%,.12)] max-[560px]:text-xs max-[560px]:py-3 max-[560px]:px-2'>
                                            <i className="fa-solid fa-check mr-1.5"></i> {t('collection_in_transit')}
                                        </button>
                                    </>
                                    :
                                    < button type="button" onClick={saveCollection} className='text-white px-7 py-5 uppercase self-end roboto transition duration-150 bg-transparent hover:bg-zinc-600 disabled:bg-[hsla(0,0%,100%,.12)] max-[560px]:text-xs max-[560px]:py-3 max-[560px]:px-2' >
                                        {t('save')}
                                    </button>
                                )


                        }
                    </div>
                </div>

                <div className='w-full flex-col md:flex-row flex gap-3'>
                    <div className='w-auto md:w-4/12 md:mr-0 m-5 py-2 flex flex-col shadow-md gap-y-1'>
                        {/* Sales Order No. */}
                        <StaticFormRow icon='envelope' label={t('purchase_order_no')} value={no} />

                        {/* Shipment Date */}
                        <StaticFormRow icon='calendar-days' label={t('collection_date')} value={dayjs(collectionDate).format("YYYY-MM-DD")} />

                        {/* Ship To */}
                        <StaticFormRow icon='location-dot' label={t('collected_from')} value={addressName}>
                            {/* Address, City, State, Zip */}
                            <div className='flex flex-col pt-5 pb-[5px]'>
                                <span>{address}</span>
                                <span>{city}, {state}, {zip}</span>
                            </div>
                        </StaticFormRow>

                        {/* DRop Trailer No. */}
                        <div className='w-full flex flex-col p-3'>
                            <div className='flex flex-col'>
                                <div className='w-full flex flex-row items-end gap-4'>
                                    <div className='w-1/12 text-center'>
                                        <i className="fa-solid fa-truck text-xl" style={{ color: '#b7472a' }}></i>
                                    </div>
                                    <div className="w-11/12 flex flex-col">
                                        <TextField
                                            label={t('drop_trailer_no')}
                                            type="text"
                                            variant='standard'
                                            className='w-full'
                                            id="pickup_trailer_no"
                                            name="pickup_trailer_no"
                                            value={deliveryNo}
                                            onChange={e => setDeliveryNo(e.target.value)}
                                            disabled={blocked}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Pickup Trailer No. */}
                        <div className='w-full flex flex-col p-3'>
                            <div className='flex flex-col'>
                                <div className='w-full flex flex-row items-end gap-4'>
                                    <div className='w-1/12 text-center'>
                                        <i className="fa-solid fa-truck text-xl" style={{ color: '#b7472a' }}></i>
                                    </div>
                                    <div className="w-11/12 flex flex-col">
                                        <TextField
                                            label={t('pickup_trailer_no')}
                                            error={errorTrailer}
                                            helperText={errorTrailer ? t('please_fill_ptn') : ""}
                                            type="text"
                                            variant='standard'
                                            className='w-full'
                                            id="pickup_trailer_no"
                                            name="pickup_trailer_no"
                                            value={pickupNo}
                                            onChange={e => { setPickupNo(e.target.value); setErrorTrailer(false) }}
                                            disabled={blocked}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Sender Name */}
                        <div className='w-full flex flex-col p-3'>
                            <div className='flex flex-col'>
                                <div className='w-full flex flex-row items-end gap-4'>
                                    <div className='w-1/12 text-center'>
                                        <i className="fa-solid fa-user-check text-xl" style={{ color: '#b7472a' }}></i>
                                    </div>
                                    <div className="w-11/12 flex flex-col">
                                        <TextField
                                            label={t('sender_name')}
                                            helperText={errorSender ? t('please_fill_sn') : ""}
                                            error={errorSender}
                                            type="text"
                                            variant='standard'
                                            className='w-full'
                                            id="receiver_name"
                                            name="receiver_name"
                                            value={senderName}
                                            onChange={e => { setSenderName(e.target.value); setErrorSender(false) }}
                                            disabled={blocked}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Receiver Email */}
                        <div className='w-full flex flex-col p-3'>
                            <div className='flex flex-col'>
                                <div className='w-full flex flex-row items-end gap-4'>
                                    <div className='w-1/12 text-center'>
                                        <i className="fa-solid fa-envelope text-xl" style={{ color: '#b7472a' }}></i>
                                    </div>
                                    <div className="w-11/12 flex flex-col">

                                        <TextField
                                            label={t('sender_email')}
                                            type="email"
                                            error={errorMail}
                                            helperText={errorMail ? t('please_enter_valid_email') : ""}
                                            variant='standard'
                                            className='w-full'
                                            id="vendor_email"
                                            name="vendor_email"
                                            value={senderEmail}
                                            onChange={e => {setSenderEmail(e.target.value); ; setErrorMail(false) }}
                                            disabled={blocked}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Signature */}
                        <div className='w-full flex flex-col p-3'>
                            <div className='flex flex-col'>
                                <div className='w-full flex flex-row items-end gap-4'>
                                    <div className='w-1/12 text-center'>
                                        <i className="fa-solid fa-pen text-xl" style={{ color: '#b7472a' }}></i>
                                    </div>
                                    <div className="w-11/12">
                                        {signature !== null ? (
                                            <img
                                                style={{ width: '100%', height: 'auto' }}
                                                src={signature.signature_url} />
                                        ) :
                                            (<TextField
                                                label={t('signature')}
                                                type="text"
                                                variant='standard'
                                                className='w-full'
                                                name="signature"
                                                value={''}
                                                onClick={handleSignatureClick}
                                            />)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-auto md:w-8/12 md:ml-0 m-5 flex justify-between gap-3'>
                        <div className='w-full'>
                            {/* Items */}
                            <div className='p-2 mb-2 w-full'>
                                <div className='flex justify-start items-center p-4' style={{ backgroundColor: '#b7472a', borderRadius: '5px 5px 0 0' }}>
                                    <p className='text-lg font-normal tracking-wide text-white roboto'>{t('items')}</p>
                                </div>
                                <TableShared
                                    columns={columns}
                                    items={items}
                                    rowsPerPageOptions={[5, 10]}
                                    density='compact'
                                    disableColumnMenu={true} />
                            </div>

                            {/* Documents and comments */}
                            <div className='flex-col sm:flex-row flex justify-between'>
                                <div className='p-2 mb-2 w-auto sm:w-1/2'>
                                    <div className='flex justify-between items-center p-4 text-white' style={{ backgroundColor: '#b7472a', borderRadius: '5px 5px 0 0' }}>
                                        <p className='text-lg font-normal tracking-wide roboto'>{t('comments')}</p>
                                        <CommentHistoryButton
                                            badgeContent={commentHistory?.length ?? ''}
                                            backgroundColor='#ffffff'
                                            color='#b7472a'
                                            onClick={() => setOpenCommentHistory(true)}
                                        />
                                    </div>
                                    <div className='shadow-md p-4'>
                                        <div className='pt-2.5'>
                                            <TextField
                                                label={t('enter_comments')}
                                                multiline
                                                rows={3}
                                                variant="filled"
                                                value={comments}
                                                onChange={e => setComments(e.target.value)}
                                                className='w-full'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='p-2 mb-2 w-auto sm:w-1/2'>
                                    <div className='flex justify-between items-center p-4 text-white' style={{ backgroundColor: '#b7472a', borderRadius: '5px 5px 0 0' }}>
                                        <p className='text-lg font-normal tracking-wide roboto'>{t('documents')}</p>
                                        <Button
                                            sx={{
                                                backgroundColor: '#607d8b',
                                                "&:hover": {
                                                    backgroundColor: '#6c8794'
                                                }
                                            }}
                                            onClick={handleUploadClick}
                                            variant='contained'
                                            size="small"
                                            endIcon={<i className="fa-solid fa-cloud-arrow-up" style={{ fontSize: "14px" }}></i>}>
                                            {t('upload')}
                                        </Button>
                                        <input hidden type="file" name="fileUpload" ref={inputRef} onChange={e => handleFileChange(e, 'drivers_document')} />
                                    </div>
                                    <div className='shadow-md p-4'>
                                        {
                                            driverDocs.length > 0 ? <span className='text-sm text-slate-600'>{t('driver_documents')}</span> : null
                                        }
                                        <>
                                            {
                                                driverDocs?.map((item, index) => (
                                                    <div className='pt-3 flex gap-2.5 justify-between' key={index}>
                                                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} className='w-10/12'>
                                                            <Tooltip disableInteractive  title={item.document_name} placement='bottom-start'>
                                                                <span className='text-blue-900 underline'>{item.document_name}</span>
                                                            </Tooltip>
                                                        </div>

                                                        <div className='flex w-2/12 justify-items-center justify-around'>
                                                            <a href={item.document_url} target="_blank" download><button className='text-neutral-500'><Tooltip disableInteractive  title={t('download')} placement='bottom'><i className="fa-solid fa-cloud-arrow-down"></i></Tooltip></button></a>
                                                            <button className='text-neutral-500' onClick={e => deleteDriverDoc(item.id, item.document_name)}><Tooltip disableInteractive  title={t('delete')} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {user?.role !== 'service_provider' && (
                    <div className='flex justify-end sticky bottom-0 border-t border-gray-300' style={{ backgroundColor: '#ffffff' }}>
                        <button type="button" onClick={e => setRejectionModalOpen(true)} className='text-[#dd5e59] px-7 py-5 uppercase self-end roboto transition duration-150 bg-transparent hover:bg-zinc-200 disabled:bg-[hsla(0,0%,100%,.12)] max-[560px]:text-xs max-[560px]:py-3 max-[560px]:px-2'>
                            {t('reject_load')} <i className="text-[#dd5e59] fa-solid fa-rotate-left ml-1.5"></i>
                        </button>
                    </div>
                )}
            </Box >
            <OrderSignatureModal open={orderSignatureModalOpen} setOpen={setOrderSignatureModalOpen} setIsLoading={setIsLoading} orderId={params.id} orderType="purchase" color='#b7472a' />
            <RejectLoad open={rejectionModalOpen} setOpen={setRejectionModalOpen} setIsLoading={setIsLoading} orderId={params.id} orderType="purchase" />
            <CommentHistoryModal
                open={openCommentHistory}
                setOpen={setOpenCommentHistory}
                comments={commentHistory}
            />
        </>
    )
}

const StaticFormRow = ({
    icon = 'question',
    label,
    value,
    color = "#b7472a",
    children
}) => {
    const childrenCount = Children.toArray(children).length

    return (
        <div className='w-full flex flex-col p-3'>
            <div className='flex flex-col'>
                <div className='w-full flex flex-row items-end gap-4'>
                    <div className='w-1/12 text-center'>
                        <i className={`fa-solid fa-${icon} text-xl`} style={{ color: color }}></i>
                    </div>
                    <div className={`w-11/12 flex flex-col ${childrenCount < 1 ? 'border-b border-gray-300' : ''}`}>
                        <span className='text-sm text-slate-600'>{label}</span>
                        <div style={{ minHeight: "1.4375em", padding: "4px 0 5px" }}>{value}</div>
                    </div>
                </div>
                {
                    childrenCount < 1 ? (
                        ''
                    ) : (
                        <div className='w-full flex flex-row items-center gap-4'>
                            <div className='w-1/12'></div>
                            <div className='w-11/12 border-b border-gray-300'>
                                {children}
                            </div>
                        </div>
                    )
                }

            </div>
        </div>
    )
}

export default ProceedCollection
