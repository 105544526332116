import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Tooltip, TextField, Autocomplete, Chip, ListItem, List } from '@mui/material'

import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import update from 'immutability-helper'

import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import AppLayout from '../../components/Layouts/AppLayout'
import TableSharedCustom from '../../components/TableSharedCustom'
import Loading from '../../components/Loading'
import AddButton from '../../components/AddButton'
import { useAuth } from '../../hooks/auth'
import { includes, isNil } from 'lodash'


const ProductionEmployees = () => {

    const { user } = useAuth({ middleware: 'guest' })

    const { t } = useTranslation()
    const { choosesite, setChoosesite, config, pusher } = useStateContext()

    const [initialSiteId, setInitialSiteId] = useState(() => {
        {/*
         * To break it down: if an session storage item imp_sid (representing the impersonatee's site ID), use that one.
         * If not, check if the user has a site ID themself. This need not necessarily be the case as office managers and master admins (among other, less important roles) do NOT have a site ID.
         * Lastly, set it to whatever the value of the site item is in the localStorage if all else fails.
         */}
        return sessionStorage.getItem('imp_sid') !== null ? sessionStorage.getItem('imp_sid') : (
            user?.site_id !== null ? user?.site_id : localStorage.getItem('site')
        )
    })

    const [random, setRandom] = useState(null)
    const [searchNo, setSearchNo] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.production_employee_no || ''
        } else {
            return ''
        }
    })
    const [searchName, setSearchName] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.production_employee_name || ''
        } else {
            return ''
        }
    })
    const [productionEmployees, setProductionEmployees] = useState([])
    const [productionTypes, setProductionTypes] = useState([])
    const [productionTypeId, setProductionTypeId] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.production_employee_type_id || ''
        } else {
            return ''
        }
    })
    const [productionTypeName, setProductionTypeName] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.production_employee_type_name || ''
        } else {
            return ''
        }
    })

    const [isLoading, setIsLoading] = useState(false)
    const [model, setModel] = useState({
        actions: true,
        company_name: true,
        name: true,
        production_employee_no: true,
        production_types: true,
        site_name: true
    })

    useEffect(() => {
        getTableView()
    }, [])

    const getTableView = async () => {
        setIsLoading(true)
        await axios.get(`/api/get-table-view?view_name=production_employees_table&user_id=${user?.id}`, config)
            .then(res => {
                const model = res.data
                if (Object.keys(model?.success?.data).length > 0) {
                    setModel(model.success.data)
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-productionemployee-deleted`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-productionemployee-created`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-productionemployee-updated`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-productionemployee-deleted-event`, data => {
            setRandom(Math.random())
        })

        channelcreate.bind(`${localStorage.getItem('client_id')}-productionemployee-created-event`, data => {
            setRandom(Math.random())
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-productionemployee-updated-event`, data => {
            setRandom(Math.random())
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionemployee-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionemployee-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionemployee-updated`)
        })
    }, [])

    useEffect(() => {
        setChoosesite(initialSiteId)
        getProductionTypes()
    }, [])

    useEffect(() => {
        if (choosesite && !isNil(choosesite) && !includes(['undefined', 'null'], choosesite)) {
            getProductionEmployees(choosesite)
        }
    }, [choosesite, initialSiteId, productionTypeId, random])

    const createFilterArray = (productionEmployeeNo, productionEmployeeName, productionTypeId, productionTypeName) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].production_employee_no = productionEmployeeNo
        filters[0].production_employee_name = productionEmployeeName
        filters[0].production_employee_type_id = productionTypeId
        filters[0].production_employee_type_name = productionTypeName

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    useEffect(() => {
        createFilterArray(searchNo, searchName, productionTypeId, productionTypeName)
    }, [searchName, searchNo, productionTypeId, productionTypeName])

    const getProductionEmployee = async (id, state) => {
        setIsLoading(true)
        await axios.get(`/api/production-employees/${id}?with=production_types`, config)
            .then(res => {
                const employee = res.data
                if (state === 'created') setProductionEmployees((prev) => [...prev, employee])

                if (state === 'updated') setProductionEmployees((prev) => {
                    const index = prev?.findIndex((i) => i.id === id)
                    if (index < 0) {
                        const employees = [...prev, employee]
                        return employees
                    } else {
                        return update(prev,
                            { [index]: { $set: employee } }
                        )
                    }
                })
                setIsLoading(false)
            })

    }

    const getProductionEmployees = async (siteId) => {
        setIsLoading(true)
        await axios.get(`/api/production-employees?with=production_types&production_types=${productionTypeId}&site=${siteId}`, config)
            .then(res => {
                const employees = res.data
                setProductionEmployees(employees)
                setIsLoading(false)
            })
    }

    const columns = [
        {
            field: 'production_employee_no',
            headerName: t('no'),
            flex: 0.7
        },
        {
            field: 'name',
            headerName: t('name'),
            flex: 1
        },
        {
            field: 'production_types',
            headerName: t('production_types'),
            flex: 1,
            renderCell: (params) => <HandleTypes params={params} />,
            sortable: false
        },
        {
            field: 'company_name',
            headerName: t('company'),
            flex: 1
        },
        {
            field: 'site_name',
            headerName: t('site'),
            flex: 1
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => <EmployeesActions params={params} config={config} setIsLoading={setIsLoading} />
        }
    ]

    const filteredByNo = productionEmployees?.filter(data => {
        if (searchNo === null)
            return data
        if (data?.production_employee_no?.toLocaleLowerCase().includes(searchNo?.toLocaleLowerCase()))
            return data
    })

    /* Alternative title: filteredByName */
    const filtered = filteredByNo?.filter(data => {
        if (searchName === null)
            return data
        if (data?.name?.toLocaleLowerCase().includes(searchName?.toLocaleLowerCase()))
            return data
    })

    const getProductionTypes = async () => {
        setIsLoading(true)
        await axios.get(`/api/production-types`, config)
            .then(res => {
                const types = res.data
                setProductionTypes(types)
                setIsLoading(false)
            })
    }

    const options = productionTypes?.map(option => ({ label: option.code, id: option.id }))

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <div className='p-5 pt-0'>
                    <div className='pb-5 bg-white mb-2 rounded-md'>
                        <div className='flex justify-between items-center p-5 border-b'>
                            <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('production_employees_list')}</p>
                            <Tooltip disableInteractive title={t('create_production_employee')} placement='bottom'>
                                <Link to={'create'}><AddButton><i className="fa-solid fa-plus"></i></AddButton></Link>
                            </Tooltip>
                        </div>
                        <div className='flex justify-between items-end w-full pb-5 border-b'>
                            <div className='px-5 pt-5 w-full'>
                                <div className='flex justify-between items-center search'>
                                    <input type="text" placeholder={t('search_by_employee_no')} value={searchNo} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSearchNo(e.target.value)} />
                                    <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                </div>
                            </div>
                            <div className='pt-5 w-full'>
                                <div className='flex justify-between items-center search'>
                                    <input type="text" placeholder={t('search_by_name')} value={searchName} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSearchName(e.target.value)} />
                                    <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                </div>
                            </div>
                            <div className='px-5 pt-5 w-full'>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={options}
                                    value={productionTypeId ? { label: productionTypeName, id: productionTypeId } : null}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: '100%', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px', }}
                                    onChange={(a, b) => { setProductionTypeId(b?.id); setProductionTypeName(b?.label) }}
                                    renderInput={(params) => <TextField {...params} label={t('searchProductionType')} />}
                                />
                            </div>

                        </div>
                        <div className='pt-3'>
                        <TableSharedCustom items={filtered} columns={columns} dynamicRowHeight={true} columnVisibilityModel={model} setModel={setModel} view='production_employees' />
                    </div>
                    </div>

                </div>
            </AppLayout>
        </>
    )
}

export default ProductionEmployees

const EmployeesActions = (params) => {

    const { t } = useTranslation()

    const deleteProductionEmployee = async (id) => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_pe') + params.params.row.production_employee_no + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        await axios.delete(`/api/production-employees/${id}`, params.config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                text: data.success.message
            })
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error",
customClass: 'error',
showCloseButton: true,
iconColor: '#FF0000'
            })
        })
    }


    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('edit')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <Link to={`${params.params.row.id}/edit`}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-pencil"></i></span></Link>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteProductionEmployee(params.params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}

const HandleTypes = ({ params }) => {
    const [types, setTypes] = useState([])

    useEffect(() => {
        if (params.value !== null && params.value !== undefined) {
            setTypes(params.value)
        }
    }, [params])

    return (
        <List sx={{ display: 'inline-flex', flexWrap: 'wrap', width: '100%' }} dense={true}>
            {types.map((data) => {
                return (
                    <ListItem sx={{ padding: '4px', width: 'max-content' }} key={`e${params.row.id}t${data.id}`}>
                        <Chip size='small' color='primary' label={data.code} variant='outlined' />
                    </ListItem>
                )
            })}
        </List>
    )

}
