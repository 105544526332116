import React, { useEffect, useState } from "react"

import { useTranslation } from 'react-i18next'
import { Autocomplete, TextField } from "@mui/material"

import AppLayout from '../../components/Layouts/AppLayout'
import { useStateContext } from '../../context/ContextProvider'
import TableSharedCustom from '../../components/TableSharedCustom'
import Loading from "../../components/Loading"
import axios from "../../lib/axios"
import { useAuth } from "../../hooks/auth"


const ProductionItems = () => {

    const { t } = useTranslation()
    const { config, pusher } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })

    const [random, setRandom] = useState(null)
    /* state */
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState([])
    const [productionTypes, setProductionTypes] = useState([])
    const [searchCode, setSearchCode] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.production_items_code || ''
        } else {
            return ''
        }
    })
    const [productionTypeId, setProductionTypeId] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.production_items_type_id || ''
        } else {
            return ''
        }
    })
    const [productionTypeName, setProductionTypeName] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.production_items_type_name || ''
        } else {
            return ''
        }
    })

    const [model, setModel] = useState({
        item_no: true,
        production_type: true
    })

    useEffect(() => {
        getTableView()
    }, [])

    const getTableView = async () => {
        setIsLoading(true)
        await axios.get(`/api/get-table-view?view_name=production_items_table&user_id=${user?.id}`, config)
            .then(res => {
                const model = res.data
                if (Object.keys(model?.success?.data).length > 0) {
                    setModel(model.success.data)
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {

        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-item-deleted`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-item-created`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-item-updated`)


        channeldelete.bind(`${localStorage.getItem('client_id')}-item-deleted-event`, data => {
            setRandom(Math.random())
        })

        channelcreate.bind(`${localStorage.getItem('client_id')}-item-created-event`, data => {
            setRandom(Math.random())
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-item-updated-event`, data => {
            setRandom(Math.random())
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-item-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-item-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-item-updated`)
        })
    }, [])


    /* methods */

    useEffect(() => {
        getProductionTypes()
    }, [])

    useEffect(() => {
        getItems()
    }, [productionTypeId, random])

    const getProductionTypes = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/production-types`, config)
            .then(res => {
                const types = res.data
                setProductionTypes(types)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getItems = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/items?has=production_type&production_type_id=${productionTypeId}`, config)
            .then(res => {
                const items = res.data
                setItems(items)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    /* data managment */

    const filtered = items.filter(data => {
        if (searchCode === null)
            return data
        if (data.item_no.toLocaleLowerCase().includes(searchCode.toLocaleLowerCase()))
            return data
    })


    const columns = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 1,
            maxWidth: 210,
            minWidth: 140
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1,
            minWidth: 700
        },
        {
            field: 'production_type',
            headerName: t('production_type'),
            flex: 1,
            minWidth: 210
        }
    ]

    const createFilterArray = (productionItemsCode, productionTypeId, productionTypeName) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].production_items_code = productionItemsCode
        filters[0].production_items_type_id = productionTypeId
        filters[0].production_items_type_name = productionTypeName

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    useEffect(() => {
        createFilterArray(searchCode, productionTypeId, productionTypeName)
    }, [searchCode, productionTypeId, productionTypeName])

    const options = productionTypes?.map(option => ({ label: option.code, id: option.id }))

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <div className='p-5 pt-0'>
                    <div className='pb-5 bg-white mb-2 rounded-md'>
                        <div className='flex justify-between items-center p-5 border-b'>
                            <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('production_items_list')}</p>
                        </div>
                        <div className='flex justify-between items-end w-full border-b pb-5'>
                            <div className='px-5 pt-5 w-full'>
                                <div className='flex justify-between items-center search'>
                                    <input type="text" placeholder={t('searchCode')} value={searchCode} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSearchCode(e.target.value)} />
                                    <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                </div>
                            </div>
                            <div className='px-5 pl-5 pt-5 w-full'>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={options}
                                    value={productionTypeId ? { label: productionTypeName, id: productionTypeId } : null}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px', width: '100%' }}
                                    onChange={(a, b) => { setProductionTypeId(b?.id); setProductionTypeName(b?.label) }}
                                    renderInput={(params) => <TextField {...params} label={t('searchProductionType')} />}
                                />
                            </div>
                        </div>
                        <div className="pt-3">
                            <TableSharedCustom items={filtered} columns={columns} columnVisibilityModel={model} setModel={setModel} view='production_items' />
                        </div>
                    </div>

                </div>
            </AppLayout>
        </>
    )
};

export default ProductionItems;
