import { Link } from 'react-router-dom'

const NavLink = ({ active = false, children, ...props }) => (
    <Link
        {...props}
        className={`h-full roboto inline-flex items-center text-sm font-medium leading-5 focus:outline-none transition duration-150 ease-in-out ${
            active
                ? 'border-transparent text-gray-500 hover:text-gray-700 focus:text-gray-700 focus:border-gray-300'
                : 'border-transparent text-gray-500 hover:text-gray-700 focus:text-gray-700 focus:border-gray-300'
        }`}>
        {children}
    </Link>
)

export default NavLink
