import CustomCard from "../CustomCard"
import { useTranslation } from "react-i18next"
import { Link, IconButton } from '@mui/material'
import CustomCardContent from "./utils/CustomCardContent"

export default function ShippedItemsTotal({data = [], showActions = false, isLoading = false, isUpdating = false}) {
    const { t } = useTranslation()

    return (
        <CustomCard
            title={t('total_no_shipped_items')}
            showActions={showActions}
            actions={
                showActions ? (
                    <Link to={'/'}>
                        <IconButton aria-label={`view-${'/'}`} size='small'>
                            <i className="fa-solid fa-eye fa-sm"></i>
                        </IconButton>
                    </Link>
                ) : (
                    ''
                )
            }
            isLoading={isLoading}
            isUpdating={isUpdating}
        >
            <CustomCardContent
                qty={data?.all_shipped_items_count ?? 0}
                supplementaryQty={data?.current_month_shipped_items_count ?? 0}
                cardType="shipped"
                pct={data?.diff_pct ?? 0}
            />
        </CustomCard>
    )
}
