/* This code is a React component that displays a list of sites in a data grid.
It uses the React Hooks useState and useEffect to set the initial state of
the component and to perform an API call when the component mounts. It also uses
the useTranslation hook to get translations for the header name, as well as
the useAuth hook to get authentication information. The API call is
made using axios, and it gets data from an endpoint */

import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import AppLayout from '../../components/Layouts/AppLayout'
import { useStateContext } from '../../context/ContextProvider'
import TableSharedCustom from '../../components/TableSharedCustom'
import { useAuth } from '../../hooks/auth'
import axios from '../../lib/axios'
import Loading from '../../components/Loading'


const Sites = () => {

    const { t } = useTranslation()
    const { allSites, config } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })
    const [isLoading, setIsLoading] = useState(false)
    const [model, setModel] = useState({
        address: true,
        city: true,
        code: true,
        companyName: true,
        name: true,
        phone: true,
        state: true,
        zip: true
    })

    useEffect(() => {
        getTableView()
    }, [])

    const getTableView = async () => {
        setIsLoading(true)
        await axios.get(`/api/get-table-view?view_name=sites_table&user_id=${user?.id}`, config)
            .then(res => {
                const model = res.data
                if (Object.keys(model?.success?.data).length > 0) {
                    setModel(model.success.data)
                }
                setIsLoading(false)
            })
    }


    const columns = [
        {
            field: 'code',
            headerName: t('code'),
            flex: 0.5
        },
        {
            field: 'name',
            headerName: t('name'),
            flex: 1
        },
        {
            field: 'companyName',
            headerName: t('company'),
            flex: 1,
            valueGetter: (params) => {
                return params.row.company.name;
            }
        },
        {
            field: 'address',
            headerName: t('address'),
            flex: 1
        },
        {
            field: 'city',
            headerName: t('city'),
            flex: 1
        },
        {
            field: 'state',
            headerName: t('state'),
            flex: 0.5
        },
        {
            field: 'zip',
            headerName: t('zip'),
            flex: 0.5
        },
        {
            field: 'phone',
            headerName: t('contact'),
            flex: 1
        }
    ]



    return (
        <>
        {isLoading ? <Loading /> : null}
        <AppLayout>

            <div className='p-5 pt-0'>
                <div>
                    <TableSharedCustom items={allSites} columns={columns} columnVisibilityModel={model} setModel={setModel} view='sites'/>
                </div>
            </div>
        </AppLayout>
        </>
    )

}

export default Sites

