const descendingComparator = (a, b, orderBy) => {
    if(b[orderBy] < a[orderBy]) {
        return -1
    }
    if(b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const stableSort = (array, comparator) => {
    const stabilizeThis = array.map((el, index) => [el, index]);
    stabilizeThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if(order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizeThis.map((el) => el[0])
}

export {
    getComparator,
    stableSort
}
