import React from 'react'

import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import { includes } from 'lodash'

import { useAuth } from '../../hooks/auth'

const TPLOrderButtons = ({ status, update, release, reopen, generateInvoice, disabled, blocked, complete3PLShipment }) => {

    const { t } = useTranslation()
    const { user } = useAuth({ middleware: 'guest' })

    return (
        <>
            {
                status < 9 ?
                    <button type="button" disabled={disabled} onClick={update} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC]'>
                        <span className='pr-2'><i className="fa-solid fa-clock-rotate-left"></i></span> {t('update')}
                    </button>
                    :
                    null
            }
            {
                status == 1 || status == 3 ?
                    blocked ?
                        <Tooltip disableInteractive title={t('please_have_item')}>
                            <button type="button" disabled onClick={release} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#049238] hover:bg-[#006425] disabled:bg-[#B8B7BC]'>
                                <span className='pr-2'><i className="fa-solid fa-check"></i></span> {t('release')}
                            </button>
                        </Tooltip>
                        :
                        <button type="button" onClick={release} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#049238] hover:bg-[#006425] disabled:bg-[#B8B7BC]'>
                            <span className='pr-2'><i className="fa-solid fa-check"></i></span> {t('release')}
                        </button>
                    :
                    null
            }
            {
                status == 2 || status > 3 && status < 9 ?
                    <button type="button" onClick={reopen} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#FD9B00] hover:bg-[#DB8600] disabled:bg-[#B8B7BC]'>
                        <span className='pr-2'><i className="fa-solid fa-file-import"></i></span> {t('reopen')}
                    </button>
                    :
                    null

            }
            {
                status == 9 ?
                    !includes(['master_admin', 'office_manager'], user?.role) ?
                        null
                        :
                        <button type="button" title={t('complete_shipment')} onClick={complete3PLShipment} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#00C448] hover:bg-[#019738] disabled:bg-[#B8B7BC]'>
                            <span className='pr-2'><i className="fa-solid fa-check"></i></span> {t('complete_shipment')}
                        </button>
                    :
                    null
            }
            {
                status == 11 ?
                    user?.role !== 'master_admin' ?
                        null
                        :
                        <button type="button" title={t('generate_invoice_in_quickbooks')} onClick={generateInvoice} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#007EA7] hover:bg-[#01546F] disabled:bg-[#B8B7BC]'>
                            <span className='pr-2'><i className="fa-regular fa-file-lines"></i></span> {t('generate_invoice')}
                        </button>
                    :
                    null
            }
        </>
    )
}

export default TPLOrderButtons
