import React, { useState } from 'react'
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

const TableShared = ({
    items,
    columns,
    rowsPerPageOptions = [10, 15],
    density = "standard",
    disableColumnMenu = false,
    hideSortingButton = false,
    breakHeaders = false,
    dynamicRowHeight = false,
    hideFooter = false,
    oddColumnPadding = '10px',
    headerRowFontSize = '0.875rem',
    flat = false,
    columnGrouping = [],
    border = '',
    columnBuffer = 3,
    sortModel = undefined,
    setSortModel = () => void 0,
    noRowsLabel = null
}) => {

    const [pageSize, setPageSize] = useState(rowsPerPageOptions[0])
    const { t } = useTranslation()

    const localizedTextsMap = {
        toolbarColumns: t('custom_view'),
        columnMenuUnsort: t('unsort'),
        columnMenuSortAsc: t('sortAsc'),
        columnMenuSortDesc: t('sortDesc'),
        columnMenuFilter: t('filter'),
        columnMenuHideColumn: t('hide'),
        columnMenuShowColumns: t('showCol'),
        MuiTablePagination: {
            labelRowsPerPage: t('rowsPerPage'),
            labelDisplayedRows: ({ from, to, count }) =>
                `${from} - ${to} ${t('of')} ${count}`,

        },
        noRowsLabel: noRowsLabel ? noRowsLabel : t('noRows')
    }

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <DataGrid
                    sx={{
                        '& .MuiTablePagination-toolbar': {
                            color: '#88909C !important', fontWeight: '400'
                        },
                        '& .MuiDataGrid-columnHeaderTitle': breakHeaders ? {
                            lineHeight: '21px',
                            wordBreak: 'break-word',
                            whiteSpace: 'pre-line',
                            color: '#88909C !important',
                            fontWeight: '400'
                        } : { color: '#88909C !important', fontWeight: '400' },
                        '& .MuiButtonBase-root': hideSortingButton ? {
                            display: 'none'
                        } : {},
                        '& .MuiDataGrid-cell': {
                            paddingLeft: `${oddColumnPadding}`,
                            paddingRight: `${oddColumnPadding}`,
                            borderRadius: '0px !important'
                        },
                        '& .MuiDataGrid-columnHeader': {
                            paddingLeft: `${oddColumnPadding}`,
                            paddingRight: `${oddColumnPadding}`,
                            fontSize: `${headerRowFontSize}`
                        },
                        '& .MuiTablePagination-root': {
                            overflowX: 'hidden'
                        },
                        '&, [class^=MuiDataGrid]': {
                            borderRadius: flat ? '0px' : '4px',
                            /* border: 'none' */
                        },
                        border: 'none',
                        background: 'white'

                    }}
                    disableColumnMenu
                    autoHeight
                    rows={items}
                    columns={columns}
                    pageSize={pageSize}
                    density={density}
                    components={{
                        ColumnSortedAscendingIcon: () => <div className='rotate-90'><i className="fa-solid fa-arrow-right-arrow-left"></i></div>,
                        ColumnSortedDescendingIcon: () => <div className='rotate-90'><i className="fa-solid fa-arrow-right-arrow-left"></i></div>,
                    }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={rowsPerPageOptions}
                    getRowHeight={() => dynamicRowHeight ? 'auto' : null}
                    getEstimatedRowHeight={() => 52}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true, columnGrouping: columnGrouping.length > 0 ? true : false }}
                    columnGroupingModel={columnGrouping.length > 0 ? [
                        ...columnGrouping
                    ] : []}
                    localeText={localizedTextsMap}
                    hideFooter={hideFooter}
                    columnBuffer={columnBuffer}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                />
            </Box>
        </div>
    )
}

export default TableShared
