import React, { useState, useEffect } from 'react'

import { Modal, Box, TextField, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"
import ClearIcon from "@mui/icons-material/Clear";

import { useStateContext } from '../../context/ContextProvider'
import { useAuth } from '../../hooks/auth'
import axios from '../../lib/axios'
import { handleAxiosError } from '../../helpers/helper'

const style = {
    width: '460px',
    maxWidth: '90vw',
    height: 'fit-content',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#ffffff',
    border: 'transparent',
    borderRadius: '5px',
    boxShadow: 24,
    zIndex: "1600"
}

const RejectLoad = ({
    open,
    setOpen,
    orderId,
    orderType,
    color = '#dd5e59',
    setIsLoading,
    is3PL = false,
    isBroker = false
}) => {

    const { logout } = useAuth({ middleware: 'guest' })
    const { t, i18n } = useTranslation()
    const { config, setChoosesite: setSite } = useStateContext()
    const navigate = useNavigate()

    const [reasons, setReasons] = useState([])
    const [reason, setReason] = useState('')
    const [errorReason, setErrorReason] = useState(false)

    useEffect(() => {
        setSite(localStorage.getItem('site'))
        if (orderType === 'sales') {
            getShipmentRejectReasons()
        } else {
            getCollectionRejectReasons()
        }
    }, [])

    const handleToggleModal = () => {
        setOpen(!open)
        setErrorReason(false)
    }

    const handleConfirmation = () => {
        if (orderType === 'sales') {
            rejectShipment()
        } else {
            rejectCollection()
        }
    }

    const getShipmentRejectReasons = async () => {
        setIsLoading(true)

        await axios.get(`/api/shipment-rejection-reasons`, config)
            .then((response) => {
                const r = response.data
                setReasons(r)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getCollectionRejectReasons = async () => {
        setIsLoading(true)

        await axios.get(`/api/collection-rejection-reasons`, config)
            .then((response) => {
                const r = response.data
                setReasons(r)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const rejectShipment = async () => {
        setIsLoading(true)

        if (!reason) {
            setErrorReason(true)
        } else {
            const formData = {}

            formData['reject_reason'] = reason

            await axios.put(`/api/sales-orders/${orderId}/reject-load`, formData, config).then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success?.message
                }).then((result) => {
                    (is3PL || isBroker) ? logout(true, is3PL ? 'tpl_driver' : 'service_provider') : navigate('/available-loads', {replace: true})
                })
            }).catch(({ response }) => {
                handleAxiosError({response: response})
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }

    const rejectCollection = async () => {
        setIsLoading(true)

        if (!reason) {
            setErrorReason(true)
        } else {
            const formData = {}

            formData['reject_reason'] = reason

            await axios.put(`/api/purchase-orders/${orderId}/reject-load`, formData, config).then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success?.message
                }).then((result) => {
                    (is3PL || isBroker) ? logout(true, is3PL ? 'tpl_driver' : 'service_provider') : navigate('/available-loads', {replace: true})
                })
            }).catch(({ response }) => {
                handleAxiosError({response: response})
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }

    return (
        <Modal open={open} onClose={handleToggleModal}>
            <Box sx={style}>
                <div className='p-5' style={{ backgroundColor: color, borderRadius: '5px 5px 0 0' }}>
                    <div className='flex gap-4 items-baseline justify-between'>
                        <p className='text-xl roboto font-semibold text-white'>{t('reject_load_question')}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-semibold text-white">
                            <button onClick={handleToggleModal}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='p-5'>
                        <form className='flex justify-center flex-col items-start mt-2'>
                            <div className='mb-4 w-full'>
                                <FormControl error={errorReason} variant="standard" className='w-full'>
                                    <InputLabel id="select-reason-label">{t('reason_for_rejection')}</InputLabel>
                                    <Select
                                        labelId='select-reason-label'
                                        id="select-reason"
                                        value={reason}
                                        MenuProps={{
                                            disableScrollLock: true,
                                            marginThreshold: null,
                                            style: {
                                                maxHeight: "300px",
                                                height: "45vh",
                                            }
                                        }}
                                        onChange={e => { setReason(e.target.value); setErrorReason(false) }}
                                        sx={{ ".MuiSelect-iconStandard": { display: reason ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' } }}
                                        endAdornment={reason ? (<IconButton sx={{ visibility: reason ? "visible" : "hidden", padding: '0' }} onClick={() => { setReason(''); }}><ClearIcon /></IconButton>) : false}
                                    >
                                        {reasons.map((reason, index) => (
                                            <MenuItem key={`sr${index}`} value={reason.key}>
                                                {i18n.exists(reason.key) ? t(reason.key) : reason.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errorReason ? (
                                        <FormHelperText>{t('please_choose_the_reason')}</FormHelperText>
                                    ) : (
                                        ''
                                    )}
                                </FormControl>
                            </div>
                        </form>
                        <div className='flex justify-end gap-4'>
                            <Button
                                variant="contained"
                                disabled={reason.length < 1}
                                onClick={handleConfirmation}
                                sx={{ backgroundColor: 'red', "&:disabled": { backgroundColor: "[hsla(0,0%,100%,.12)]" }, "&:hover": { backgroundColor: "red" }, width: 'fit-content' }}
                            >
                                {t('confirm')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default RejectLoad
