import * as React from 'react'
import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useTranslation } from "react-i18next"
import TablePagination from "@mui/material/TablePagination";
import { t } from 'i18next'
import dayjs from 'dayjs'
import CircularProgress from '@mui/material/CircularProgress';
import { useDrag, useDrop } from "react-dnd"
import Swal from 'sweetalert2'
import CallMadeIcon from '@mui/icons-material/CallMade'
import { Stack, TableSortLabel } from '@mui/material'
import { getComparator, stableSort } from '../../helpers/DailyBoardTableHelpers'
import { visuallyHidden } from '@mui/utils';
import { SALES_ORDER_STAUSES } from '../../constants/constants'
import Tooltip from '@mui/material/Tooltip'


import { COLUMN_NAMES } from "../../constants/constants";
import axios from '../../lib/axios'
import { useStateContext } from '../../context/ContextProvider'


function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false)

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: COLUMN_NAMES.SHIPMENTS,
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
            item: () => {
                return { ...row, index: 0 }
            }
        }),
        [],
    )

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset !important' }, cursor: 'pointer' }} ref={drag}>
                <TableCell sx={{ padding: '8px 16px', position: 'relative' }}>
                    {row.type == 'BROKERED' ? (
                        <Tooltip disableInteractive title={t('brokered')}>
                            <div style={{ position: 'absolute', top: 0, left: 0, border: '7.5px solid', borderColor: `rgb(255,25,25) transparent transparent rgb(255,25,25)` }}></div>
                        </Tooltip>
                    ) : null}
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        {row.so_number}
                        {
                            row.so_status_id != SALES_ORDER_STAUSES['LOADED'] ? (
                                <Tooltip title={t('shipment_not_loaded_warning_dispatch')}>
                                    <span className='p-1.5'><i className="fa-solid fa-triangle-exclamation" style={{color: "#db1c24"}}></i></span>
                                </Tooltip>
                            ) : (
                                ''
                            )
                        }
                    </div>
                </TableCell>
                <TableCell sx={{ width: '17.5%', padding: '8px 16px' }}>{row.customer_name}</TableCell>
                <TableCell sx={{ width: '20%', padding: '8px 16px' }}>{row.address}</TableCell>
                <TableCell sx={{ width: '20%', padding: '8px 16px' }}>{row.city}, {row.state}</TableCell>
                <TableCell sx={{ width: '32.5%', padding: '8px 16px' }}>{<ShowDate date={row.requested_delivery_date} />}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell style={{ padding: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ padding: '16px' }}>
                            <div className='flex justify-between'>
                                <p>{t('customer_po_no')} : {row.customer_po_no}</p>
                                <p>{t('trailer_no')} : {row.drop_trailer_no || '-'}</p>
                            </div>
                            <div>
                                <div className='pb-2 pt-[20px]'><p className='font-bold text-[16px]'>{t('items')}</p></div>
                                <div className='pb-2'>
                                    <hr />
                                </div>
                                <div>
                                    {
                                        row.sales_order_items.map((item, index) => (
                                            <div key={index} className='flex justify-between py-2'>
                                                <p>{item.item_no}, {item.description}</p>
                                                <p>{t('quantity_ordered')}: {item.qty_ordered}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </>
    );
}

const SortableTableHeaderRow = (props) => {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (

        <TableHead>

            <TableRow>
                {/* Sales Order No. cell */}
                <TableCell
                    sortDirection={orderBy === 'so_number' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'so_number'}
                        direction={orderBy === 'so_number' ? order : 'asc'}
                        onClick={createSortHandler('so_number')}>
                        {t('no')}
                        {orderBy === 'so_number' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Customer Name cell */}
                <TableCell
                    sortDirection={orderBy === 'customer_name' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'customer_name'}
                        direction={orderBy === 'customer_name' ? order : 'asc'}
                        onClick={createSortHandler('customer_name')}>
                        {t('customer')}
                        {orderBy === 'customer_name' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Ship Address Name cell */}
                <TableCell
                    sortDirection={orderBy === 'address' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'address'}
                        direction={orderBy === 'address' ? order : 'asc'}
                        onClick={createSortHandler('address')}>
                        {t('address')}
                        {orderBy === 'address' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* State cell */}
                <TableCell
                    sortDirection={orderBy === 'state' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'state'}
                        direction={orderBy === 'state' ? order : 'asc'}
                        onClick={createSortHandler('state')}>
                        {t('state')}
                        {orderBy === 'state' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* Shipment Date cell */}
                <TableCell
                    sortDirection={orderBy === 'requested_delivery_date' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'requested_delivery_date'}
                        direction={orderBy === 'requested_delivery_date' ? order : 'asc'}
                        onClick={createSortHandler('requested_delivery_date')}>
                        {t('requested_delivery_date')}
                        {orderBy === 'requested_delivery_date' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
    )
}

export default function CollapsibleTable({ items, isLoading, setIsLoading, getShipments }) {

    const { t } = useTranslation()

    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [orderBy, setOrderBy] = useState('created_at')
    const [order, setOrder] = useState('desc')

    const [loading, setLoading] = useState(true)
    const { config } = useStateContext()
    const [tableOpen, setTableOpen] = useState(true)

    const handleRequestSort = (event, property) => {
        if (order === 'desc' && orderBy === property) {
            setOrderBy('created_at')
        } else {
            const isAsc = orderBy === property && order === 'asc'
            setOrder(isAsc ? 'desc' : 'asc')
            setOrderBy(property)
        }
    }

    const visibleShipments = React.useMemo(
        () =>
            stableSort(items, getComparator(order, orderBy)).slice(
                pg * rpg,
                pg * rpg + rpg,
            ),
        [items, order, orderBy, pg, rpg],
    )

    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
            accept: COLUMN_NAMES.SHIPMENTS,
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop()
            }),
            drop: (item) => updateStatus(item)
        }),
        [],
    )


    const updateStatus = async (item) => {

        setIsLoading(true)
        const formData = {}
        let type = ''

        if (item.order_type === "SHIPMENTS") {
            type = "sales-orders"
        }
        else {
            type = "purchase-orders"
        }

        formData['order_id'] = item.id
        formData['driver_1_id'] = item.driver_id
        formData['driver_id'] = null

        await axios.post(`/api/${type}/${item.id}/assign-driver`, formData, config)
            .then(({ data }) => {
                getShipments()
            }).catch(({ response }) => {
                if (response.status === 422) {
                    Swal.fire({
                        text: response.data.error.description,
                        icon: "error",
                        customClass: 'error',
                        showCloseButton: true,
                        iconColor: '#FF0000'
                    })
                } else {
                    Swal.fire({
                        text: response.data.error.description,
                        icon: "error",
                        customClass: 'error',
                        showCloseButton: true,
                        iconColor: '#FF0000'
                    })
                }
            }).finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        checkLoading()
    }, [items])

    const checkLoading = () => {
        if (items.length > 0) {
            setLoading(false)
        }
        else setTimeout(() => {
            setLoading(false)
        }, 2000);
    }

    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }

    const isActive = canDrop && isOver
    let backgroundColor = 'transparent'
    if (isActive) {
        backgroundColor = '#f6f6f6'
    }


    return (
        <div className='mb-5'>
            <div className='flex justify-between bg-white border-b p-5 items-center'>
                <div className='flex gap-3 items-center'>
                    <p className='font-[600] text-[16px] text-[#535353] flex justify-start gap-2 items-center'><span style={{ color: '#336195' }}> <CallMadeIcon /> </span> {t('shipments')}</p>
                    <p className='text-[10px] font-[400] text-[#B9B9B9] uppercase'>{t('dnd')}</p>
                </div>
                <div>
                    <button onClick={() => setTableOpen(!tableOpen)} className='text-[#939393]'>{tableOpen ? <i className="fa-solid fa-angle-up"></i> : <i className="fa-solid fa-angle-down"></i>}</button>
                </div>
            </div>
            <div style={{ width: '100%', border: 'none', background: 'white', borderRadius: '8px', display: `${tableOpen ? 'block' : 'none'}` }}>
                <TableContainer>
                    <Table aria-label="collapsible table" stickyHeader ref={drop}>
                        <colgroup>
                            <col width="17.5%" />
                            <col width="17.5%" />
                            <col width="17.5%" />
                            <col width="17.5%" />
                            <col width="17.5%" />
                            <col />
                        </colgroup>
                        <SortableTableHeaderRow
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody sx={{ position: 'relative', backgroundColor }}>
                            {isLoading ? (
                                <TableRow sx={{
                                    overflow: 'hidden',
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    opacity: '0.5',
                                    zIndex: '100',
                                    backgroundColor: isLoading ? '#000000' : 'transparent'
                                }}>
                                    <TableCell colSpan={6}>
                                        <div className='w-full h-full flex justify-center'>
                                            <CircularProgress sx={{ alignSelf: 'center' }} />
                                        </div>
                                    </TableCell>
                                </TableRow>) : null}
                            {items.length <= 0 ? loading ?
                                <TableRow><TableCell colSpan={6} sx={{ textAlign: 'center' }}><CircularProgress /></TableCell></TableRow> :
                                <TableRow><TableCell colSpan={6} sx={{ textAlign: 'center' }}>{t('no_data')}</TableCell></TableRow> : null}
                            {visibleShipments?.map((row) => (
                                <Row key={row.id} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className='table-pagination'
                    labelRowsPerPage={t('rowsPerPage')}
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={items.length}
                    rowsPerPage={rpg}
                    page={pg}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
}

const ShowDate = ({ date }) => {

    const date1 = new Date(dayjs(date).format("YYYY-MM-DD"))
    const date2 = new Date(dayjs().format("YYYY-MM-DD"))

    return (
        <div>
            {
                date1 < date2 ? <p style={{ color: '#F00', padding: '5px', paddingLeft: '0', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p> :
                    date1 == date2 ? <p style={{ color: 'blue', padding: '5px', paddingLeft: '0', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p> :
                        date1 > date2 ? <p style={{ color: '#008E00', padding: '5px', paddingLeft: '0', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p> :
                            <p style={{ color: 'blue', padding: '5px', paddingLeft: '0', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("MMMM D, YYYY")}</p>
            }
        </div>
    )
}
