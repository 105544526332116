import * as React from 'react'
import { useState, useEffect } from 'react'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useTranslation } from "react-i18next"
import { Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import TablePagination from "@mui/material/TablePagination";
import { t } from 'i18next'
import dayjs from 'dayjs'
import CircularProgress from '@mui/material/CircularProgress';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop'
import { visuallyHidden } from '@mui/utils'

import { getComparator, stableSort } from '../../helpers/DailyBoardTableHelpers'

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false)

    return (
        <React.Fragment >
            <TableRow sx={{ '& > *': { borderBottom: 'unset !important' } }}>
                <TableCell sx={{ padding: '8px 16px '}}>{row.po_number}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.requested_collection_date}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.purchase_address_name}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.customer_bol_no}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.broker_bol_no}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.pickup_trailer_no}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.po_status_name}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell style={{ padding: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '20%' }}>{t('item_no')}</TableCell>
                                        <TableCell sx={{ width: '50%' }}>{t('description')}</TableCell>
                                        <TableCell sx={{ width: '10%' }}>{t('quantity_ordered')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.purchase_order_items.map((historyRow) => (
                                        <TableRow key={historyRow.id}>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.item_no}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.description}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_ordered}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </React.Fragment>
    );
}

const SortableTableHeaderRow = (props) => {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {/* Purchase Order NO. cell */}
                <TableCell
                    sortDirection={orderBy === 'po_number' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'po_number'}
                        direction={orderBy === 'po_number' ? order : 'asc'}
                        onClick={createSortHandler('po_number')}>
                        {t('purchase_order_no')}
                        {orderBy === 'po_number' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                <TableCell
                    sortDirection={orderBy === 'requested_collection_date' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'requested_collection_date'}
                        direction={orderBy === 'requested_collection_date' ? order : 'asc'}
                        onClick={createSortHandler('requested_collection_date')}>
                        {t('requested_collection_date')}
                        {orderBy === 'requested_collection_date' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                <TableCell
                    sortDirection={orderBy === 'purchase_address_name' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'purchase_address_name'}
                        direction={orderBy === 'purchase_address_name' ? order : 'asc'}
                        onClick={createSortHandler('purchase_address_name')}>
                        {t('purchase_name')}
                        {orderBy === 'purchase_address_name' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                <TableCell
                    sortDirection={orderBy === 'customer_bol_no' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'customer_bol_no'}
                        direction={orderBy === 'customer_bol_no' ? order : 'asc'}
                        onClick={createSortHandler('customer_bol_no')}>
                        {t('customer_bol')}
                        {orderBy === 'customer_bol_no' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                <TableCell
                    sortDirection={orderBy === 'broker_bol_no' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'broker_bol_no'}
                        direction={orderBy === 'broker_bol_no' ? order : 'asc'}
                        onClick={createSortHandler('broker_bol_no')}>
                        {t('broker_bol')}
                        {orderBy === 'broker_bol_no' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                <TableCell
                    sortDirection={orderBy === 'pickup_trailer_no' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'pickup_trailer_no'}
                        direction={orderBy === 'pickup_trailer_no' ? order : 'asc'}
                        onClick={createSortHandler('pickup_trailer_no')}>
                        {t('pickup_trailer_no')}
                        {orderBy === 'pickup_trailer_no' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                <TableCell
                    sortDirection={orderBy === 'po_status' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'po_status'}
                        direction={orderBy === 'po_status' ? order : 'asc'}
                        onClick={createSortHandler('po_status')}>
                        {t('status')}
                        {orderBy === 'po_status' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                <TableCell />
            </TableRow>
        </TableHead>
    )
}

export default function CollapsibleTable({ items }) {

    const { t } = useTranslation()

    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [collections, setCollections] = useState([])
    const [orderBy, setOrderBy] = useState('created_at')
    const [order, setOrder] = useState('desc')


    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }

    const handleRequestSort = (event, property) => {
        if (order === 'desc' && orderBy === property) {
            setOrderBy('created_at')
        } else {
            const isAsc = orderBy === property && order === 'asc'
            setOrder(isAsc ? 'desc' : 'asc')
            setOrderBy(property)
        }
    }

    const visibleShipments = React.useMemo(
        () =>
            stableSort(items, getComparator(order, orderBy)).slice(
                pg * rpg,
                pg * rpg + rpg,
            ),
        [items, order, orderBy, pg, rpg],
    )

    return (
        <>

            <Paper sx={{ width: '100%', mb: 2, boxShadow: 0 }}>
                <TableContainer>
                    <Table aria-label="collapsible table" stickyHeader>
                        <colgroup>
                            <col width="14%" />
                            <col width="14%" />
                            <col width="14%" />
                            <col width="14%" />
                            <col width="14%" />
                            <col width="14%" />
                            <col width="20%" />
                            <col width="10%" />
                            <col />
                        </colgroup>
                        <SortableTableHeaderRow
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {items.length <= 0 ? <TableRow><TableCell colSpan={8} sx={{ textAlign: 'center' }}>{t('no_data')}</TableCell></TableRow> : null}
                            {visibleShipments?.map((row) => (
                                <Row key={row.id} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className='table-pagination'
                    labelRowsPerPage={t('rowsPerPage')}
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={items.length}
                    rowsPerPage={rpg}
                    page={pg}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}


const TodaysSalesOrderAction = ({ id, status, forklifterId }) => {

    const { t } = useTranslation()

    const [open, setOpen] = useState(false)

    const openPopup = () => {
        setOpen(!open)
    }

    return (
        <>
            <div className='flex gap-1'>
                <Tooltip disableInteractive  title="BOL/POD" placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <a href={process.env.REACT_APP_BACKEND_URL + `/api/purchase-orders/${id}/proof-of-delivery?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`} target="_blank"><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-print"></i></span></a>
                    </div>
                </Tooltip>
                {status === 1 || status === 3 ?
                    (<Tooltip disableInteractive  title={t('update')} placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <Link to={`${id}`}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-pencil"></i></span></Link>
                        </div>
                    </Tooltip>)
                    :
                    (<Tooltip disableInteractive  title={t('open')} placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <Link to={`${id}`}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-eye"></i></span></Link>
                        </div>
                    </Tooltip>)
                }
                {status === 2 || status === 4 ?
                    (<Tooltip disableInteractive  title={t('assign_forklifter')} placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <span style={{ cursor: 'pointer' }} onClick={openPopup} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><VerticalAlignTopIcon /></span>
                        </div>
                    </Tooltip>)
                    :
                    ''
                }
            </div>
        </>
    )
}

export const ShowDate = ({ date }) => {

    const date1 = new Date(dayjs(date).format("YYYY-MM-DD"))
    const date2 = new Date(dayjs().format("YYYY-MM-DD"))

    return (
        <div>
            {
                date1 < date2 ? <p style={{ color: 'red', border: '1px solid red', padding: '5px', borderRadius: '15px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("YYYY-MM-DD")}</p> :
                    date1 == date2 ? <p style={{ color: 'blue', border: '1px solid blue', padding: '5px', borderRadius: '15px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("YYYY-MM-DD")}</p> :
                        date1 > date2 ? <p style={{ color: 'green', border: '1px solid green', padding: '5px', borderRadius: '15px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("YYYY-MM-DD")}</p> :
                            <p style={{ color: 'blue', border: '1px solid blue', padding: '5px', borderRadius: '15px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("YYYY-MM-DD")}</p>
            }
        </div>
    )
}
