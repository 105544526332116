import React from "react";
import { Badge } from "@mui/material";

const CustomBadge = ({children, badgeContent = '', overlap = 'circular', backgroundColor = "#336195", color = '#ffffff'}) => {
    const badgeStyle = {
        "& .MuiBadge-badge": {
            backgroundColor: backgroundColor,
            color: color,
            top: '21%',
            right: '21%'
        }
    }

    return (
        <Badge sx={badgeStyle} badgeContent={badgeContent} overlap={overlap}>
            {children}
        </Badge>
    )
}

export default CustomBadge
