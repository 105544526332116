import React, { useRef, useState } from 'react'

import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'

import AppLayout from '../../components/Layouts/AppLayout'
import Button from '../../components/Button';
import axios from '../../lib/axios'
import { useStateContext } from '../../context/ContextProvider';
import { handleAxiosError } from '../../helpers/helper';
import { Stack } from '@mui/material';
import Loading from '../../components/Loading';

const Import = () => {

    const { t } = useTranslation()
    const { config } = useStateContext()

    /* state */
    const [isLoading, setIsLoading] = useState(false)

    const [usersFileName, setUsersFileName] = useState()
    const hiddenUsersFileInput = useRef(null)
    const [displayUsers, setDisplayUsers] = useState('none')

    const [customersFileName, setCustomersFileName] = useState()
    const hiddenCustomersFileInput = useRef(null)
    const [displayCustomers, setDisplayCustomers] = useState('none')

    const [vendorsFileName, setVendorsFileName] = useState()
    const hiddenVendorsFileInput = useRef(null)
    const [displayVendors, setDisplayVendors] = useState('none')

    const [itemsFileName, setItemsFileName] = useState()
    const hiddenItemsFileInput = useRef(null)
    const [displayItems, setDisplayItems] = useState('none')

    /* methods */
    const handleClickUsers = event => {
        hiddenUsersFileInput.current.click()
    }

    const handleClickCustomers = (e) => {
        hiddenCustomersFileInput.current.click()
    }

    const handleClickVendors = (e) => {
        hiddenVendorsFileInput.current.click()
    }

    const handleClickItems = (e) => {
        hiddenItemsFileInput.current.click()
    }

    const handleDeleteUsers = event => {
        setDisplayUsers('none')
        hiddenUsersFileInput.current.value = ""
    }

    const handleDeleteCustomers = (e) => {
        setDisplayCustomers('none')
        hiddenCustomersFileInput.current.value = ""
    }

    const handleDeleteVendors = (e) => {
        setDisplayVendors('none')
        hiddenVendorsFileInput.current.value = ""
    }

    const handleDeleteItems = (e) => {
        setDisplayItems('none')
        hiddenItemsFileInput.current.value = ""
    }

    const handleChangeUsers = event => {
        const fileUploaded = event.target.files[0];
        setDisplayUsers('block')
        setUsersFileName(fileUploaded.name)
        importFile(fileUploaded, 'users')
        hiddenUsersFileInput.current.value = ""
    }

    const handleChangeCustomers = (e) => {
        const fileUploaded = e.target.files[0]
        setDisplayCustomers('block')
        setCustomersFileName(fileUploaded.name)
        importFile(fileUploaded, 'customers')
        hiddenCustomersFileInput.current.value = ""
    }

    const handleChangeVendors = (e) => {
        const fileUploaded = e.target.files[0]
        setDisplayVendors('block')
        setVendorsFileName(fileUploaded.name)
        importFile(fileUploaded, 'vendors')
        hiddenVendorsFileInput.current.value = ""
    }

    const handleChangeItems = (e) => {
        const fileUploaded = e.target.files[0]
        setDisplayItems('block')
        setItemsFileName(fileUploaded.name)
        importFile(fileUploaded, 'items')
        hiddenItemsFileInput.current.value = ""
    }

    const importFile = async (fileUploaded, model) => {
        setIsLoading(true)

        const formData = new FormData()

        formData.append('file', fileUploaded)

        await axios
            .post(process.env.REACT_APP_BACKEND_URL + `/api/${model}/import`, formData, config)
            .then((data) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.data.success.message
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response, showDescription: response.status != 422 ? true : false})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <div className='p-5'>
                    <Stack direction='column' gap={3}>
                        <div className='pb-5 bg-white mb-2 rounded-md'>
                            <div className='flex justify-start items-center gap-4 p-5'>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('import_x', {x: t('users')})}</p>
                            </div>
                            <div className='px-5 pt-5 w-full'>
                                <div className='flex gap-2'><p className='pb-2 text-sky-700' style={{display: displayUsers}}>{usersFileName}</p> <button onClick={handleDeleteUsers} className='pb-2 text-red-600' style={{display: displayUsers}}>x</button></div>
                                <Button disabled={isLoading} onClick={handleClickUsers} className="uppercase roboto" sx={{ backgroundColor: '#607d8b', "&:hover": {backgroundColor: '#6c8794'} }}>{t('import_x', {x: t('users')})}</Button>
                            </div>
                            <input type="file" ref={hiddenUsersFileInput} onChange={handleChangeUsers} style={{ display: 'none' }} />
                        </div>

                        <div className='pb-5 bg-white mb-2 rounded-md'>
                            <div className='flex justify-start items-center gap-4 p-5'>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('import_x', {x: t('customers')})}</p>
                            </div>
                            <div className='px-5 pt-5 w-full'>
                                <div className='flex gap-2'><p className='pb-2 text-sky-700' style={{display: displayCustomers}}>{customersFileName}</p> <button onClick={handleDeleteCustomers} className='pb-2 text-red-600' style={{display: displayCustomers}}>x</button></div>
                                <Button disabled={isLoading} onClick={handleClickCustomers} className="uppercase roboto" sx={{ backgroundColor: '#607d8b', "&:hover": {backgroundColor: '#6c8794'} }}>{t('import_x', {x: t('customers')})}</Button>
                            </div>
                            <input type="file" ref={hiddenCustomersFileInput} onChange={handleChangeCustomers} style={{ display: 'none' }} />
                        </div>

                        <div className='pb-5 bg-white mb-2 rounded-md'>
                            <div className='flex justify-start items-center gap-4 p-5'>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('import_x', {x: t('vendors')})}</p>
                            </div>
                            <div className='px-5 pt-5 w-full'>
                                <div className='flex gap-2'><p className='pb-2 text-sky-700' style={{display: displayVendors}}>{vendorsFileName}</p> <button onClick={handleDeleteVendors} className='pb-2 text-red-600' style={{display: displayVendors}}>x</button></div>
                                <Button disabled={isLoading} onClick={handleClickVendors} className="uppercase roboto" sx={{ backgroundColor: '#607d8b', "&:hover": {backgroundColor: '#6c8794'} }}>{t('import_x', {x: t('vendors')})}</Button>
                            </div>
                            <input type="file" ref={hiddenVendorsFileInput} onChange={handleChangeVendors} style={{ display: 'none' }} />
                        </div>

                        <div className='pb-5 bg-white mb-2 rounded-md'>
                            <div className='flex justify-start items-center gap-4 p-5'>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('import_x', {x: t('items')})}</p>
                            </div>
                            <div className='px-5 pt-5 w-full'>
                                <div className='flex gap-2'><p className='pb-2 text-sky-700' style={{display: displayItems}}>{itemsFileName}</p> <button onClick={handleDeleteItems} className='pb-2 text-red-600' style={{display: displayItems}}>x</button></div>
                                <Button disabled={isLoading} onClick={handleClickItems} className="uppercase roboto" sx={{ backgroundColor: '#607d8b', "&:hover": {backgroundColor: '#6c8794'} }}>{t('import_x', {x: t('items')})}</Button>
                            </div>
                            <input type="file" ref={hiddenItemsFileInput} onChange={handleChangeItems} style={{ display: 'none' }} />
                        </div>
                    </Stack>
                </div>
            </AppLayout>
        </>
    )

}

export default Import
