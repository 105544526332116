import React, { useState, useEffect, forwardRef, useRef } from 'react'
import { useTranslation } from "react-i18next"
import { ShowDate } from '../DailyBoardTableSales'
import AddButton from '../AddButton'
import { Box, Button, IconButton, CircularProgress, TextField, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Collapse } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useStateContext } from '../../context/ContextProvider'
import Swal from 'sweetalert2'
import styles from '../driver/Assigned.module.css'
import axios from '../../lib/axios'
import update from 'immutability-helper';
import LoadingTruckIcon from '../LoadingTruckIcon'
import { useAuth } from '../../hooks/auth'
import CommentHistoryModal from '../CommentHistoryModal'
import CommentHistoryButton from '../CommentHistoryButton'
import { isEqual } from 'lodash'

const HandleDropTrailerNo = ({ dropTrailerNo, disabled, changeDropTrailerNoHandler }) => {
    const [no, setNo] = useState(dropTrailerNo)

    const onChangeHandler = (val) => {
        setNo(val);
    }

    const onBlurHandler = (val) => {
        if (String(val) != String(dropTrailerNo)) {
            changeDropTrailerNoHandler(val);
        }
    }

    const onEnterPressHandler = (event) => {
        if (event.key === 'Enter' && String(event.target.value) != String(dropTrailerNo)) {
            changeDropTrailerNoHandler(event.target.value);
        }
    }

    return (
        <TextField
            type="text"
            variant='standard'
            disabled={disabled}
            className='w-full mb-5 px-0 pt-0'
            name="no"
            value={no}
            onChange={(e) => { onChangeHandler(e.target.value); }}
            onKeyDown={(e) => { onEnterPressHandler(e); }}
            onBlur={(e) => { onBlurHandler(e.target.value); }}
        />
    )
}

const HandleQtyLoaded = ({ itemId, qtyOrdered, qtyLoaded, disabled, changeQuantityHandler, proceedShipmentHandler }) => {
    const { t } = useTranslation()

    const [qty, setQty] = useState(qtyLoaded)
    const [helperText, setHelperText] = useState('')
    const [error, setError] = useState(false)

    const onFocusHandler = (val) => {
        if (val == 0) {
            setQty('')
        }
    }

    const onChangeHandler = (val) => {
        const regex = /^(-?\d*)((\.(\d{0,2})?)?)$/i
        if (regex.test(val)) {
            if (val < 0 || val === '-' || val === '') {
                setQty(0)

                changeQuantityHandler(
                    itemId,
                    0,
                )
            } else {
                const qtyOrderedLength = qtyOrdered.length
                const q = (val.length > 1 && val.charAt(0) === '0') ? val.slice(1, val.length) : val.slice(0, val.length)

                if (q.slice(0, qtyOrderedLength) <= qtyOrdered) {
                    setHelperText('')
                    setError(false)

                    setQty(q.slice(0, qtyOrderedLength))

                    changeQuantityHandler(
                        itemId,
                        q.slice(0, qtyOrderedLength)
                    )
                } else {
                    setQty(qtyOrdered)
                    setHelperText(t('cannot_exceed_qty_ordered'))
                    setError(true)

                    changeQuantityHandler(
                        itemId,
                        qtyOrdered,
                    )
                }
            }
        }
    }

    const onBlurHandler = (val) => {
        if (val < 0 || val === '-' || val === '') {
            setQty(0)
        }

        /* if (Number(val) != Number(qtyLoaded)) {
            proceedShipmentHandler();
        } */
        setHelperText('')
        setError(false)
    }

    const onEnterPressHandler = (event) => {
        if (event.key === 'Enter' && Number(event.target.value) != Number(qtyLoaded)) {
            proceedShipmentHandler();
        }
    }

    return (
        <div>
            <TextField
                type="text"
                variant='standard'
                disabled={disabled}
                className='w-full mb-5 px-0 pt-0'
                name="qty"
                value={qty}
                onChange={(e) => { onChangeHandler(e.target.value); }}
                onBlur={(e) => { onBlurHandler(e.target.value); }}
                onFocus={(e) => { onFocusHandler(e.target.value); }}
                onKeyDown={(e) => { onEnterPressHandler(e); }}
                helperText={helperText}
                error={error} />
        </div>
    )
}

const HandleComments = forwardRef(function HandleComments(props, ref) {
    const { t } = useTranslation()

    const onChangeHandler = (val) => {
        props.changeCommentsHandler(val);
    }

    return (
        <TextField
            label={t('enter_comments')}
            multiline
            rows={3}
            variant="filled"
            inputRef={ref}
            onChange={(e) => { onChangeHandler(e.target.value); }}
            className='w-full'
            disabled={props.disabled}
        />
    )
})

function Row(props) {
    const { t } = useTranslation()

    const { row, setOpenLatestMessage } = props;
    const [open, setOpen] = React.useState(false)
    const [highlight, setHighlight] = React.useState(false)
    const { config } = useStateContext()
    const { user } = useAuth({ middleware: 'guest ' })

    const [dropTrailerNo, setDropTrailerNo] = useState(row.drop_trailer_no)
    const [comments, setComments] = useState('')
    const [items, setItems] = useState(row.sales_order_items)

    const [openCommentHistory, setOpenCommentHistory] = useState(false)
    // Necessary to clear and blur the comments text box
    const commentsRef = useRef(null)

    // The inputs should be disabled while the something is being saved (be that comment or quantity)
    const [isBeingSaved, setIsBeingSaved] = useState(false)

    const highlightRowHandler = (highlight = false) => {
        setHighlight(highlight)
    }

    const handleQtyLoadedChange = (unitId, qtyLoaded) => {
        setItems((prev) => {
            const updatedItemIndex = prev.findIndex((i) => i.id === unitId)
            if (updatedItemIndex !== undefined) {
                const updatedItem = prev[updatedItemIndex]
                updatedItem.qty_loaded = qtyLoaded
                return update(prev,
                    { [updatedItemIndex]: { $set: updatedItem } }
                )
            }
        })
    }

    /* useEffect(() => {
        if (
            (row.so_status === 'loading' && user?.id === row.forklift_driver_id)
            && !isEqual((row.drop_trailer_no), dropTrailerNo)
        ) {
            handleProceedShipment()
        }
    }, [dropTrailerNo]) */

    const handleProceedShipment = async () => {
        if(!isBeingSaved) {
            setIsBeingSaved(true)
            const formData = {}

            formData['drop_trailer_no'] = dropTrailerNo
            /* formData['comments'] = comments */
            formData['sales_order_items'] = items

            await axios.put(`/api/forklift-driver/${row.id}/proceed-shipment`, formData, config)
                .then(({ data }) => {

                }).catch(({ response }) => {
                    Swal.fire({
                        text: response.data.error.description,
                        icon: "error",
                        customClass: 'error',
                        showCloseButton: true,
                        iconColor: '#FF0000'
                    })
                }).finally(() => {
                    setIsBeingSaved(false)
                })
        }
    }

    const handleSaveComment = async () => {
        if(!isBeingSaved) {
            setIsBeingSaved(true)

            const formData = {}

            formData['drop_trailer_no'] = dropTrailerNo
            formData['comments'] = comments
            formData['sales_order_items'] = items

            await axios.put(`/api/forklift-driver/${row.id}/proceed-shipment`, formData, config).then(({ data }) => {
                setComments('')
                commentsRef.current.value = ''
                commentsRef.current.blur()
            }).catch(({ response }) => {
                Swal.fire({
                    text: response.data.error.description,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            }).finally(() => {
                setIsBeingSaved(false)
            })
        }
    }

    const clickAcceptShipmentButtonHandler = () => {
        setHighlight(true)
    }

    const clickLoadShipmentButtonHandler = async () => {
        if(!isBeingSaved) {
            setIsBeingSaved(true)

            let totalLoaded = 0

            for (let item of items) {
                totalLoaded += Number(item['qty_loaded'])
                if (totalLoaded < 1) {
                    setIsBeingSaved(false)
                    setHighlight(false)
                    Swal.fire({
                        text: t('qty_loaded_cannot_be_zero'),
                        icon: "error",
                        customClass: 'error',
                        showCloseButton: true,
                        iconColor: '#FF0000'
                    })
                    return
                }
            };

            await handleProceedShipment()
                .then(() => {
                    return axios.put(`/api/forklift-driver/${row.id}/mark-shipment-loaded`, config)
                })
                .then(({ data }) => {
                    setHighlight(false)
                    sessionStorage.setItem('available_loads_latest_msg', data.success.message)
                    setOpenLatestMessage(true)
                })
                .catch(({ response }) => {
                    setHighlight(true)
                    Swal.fire({
                        text: response.data.error.description,
                        icon: "error",
                        customClass: 'error',
                        showCloseButton: true,
                        iconColor: '#FF0000'
                    })
                }).finally(() => {
                    setIsBeingSaved(false)
                })
        }
    }

    return (
        <React.Fragment >
            <TableRow selected={highlight} sx={{ '& > *': { borderBottom: 'unset !important' } }}>
                <TableCell sx={{ padding: '8px 16px', position: 'relative' }}>
                    {
                        row.type == 'BROKERED' ?
                            <Tooltip disableInteractive title={t('brokered')}>
                                <div style={{ position: 'absolute', top: 0, left: 0, border: '7.5px solid', borderColor: `rgb(255,25,25) transparent transparent rgb(255,25,25)` }}></div>
                            </Tooltip>
                            :
                            null
                    }
                    {
                        row.so_status === 'assigned_to_fork' ?
                            (
                                <AcceptShipmentButton onClick={clickAcceptShipmentButtonHandler} highlightRow={highlightRowHandler} id={row.id} config={config} />
                            ) : (
                                <LoadShipmentButton onClick={clickLoadShipmentButtonHandler} highlightRow={highlightRowHandler} id={row.id} config={config} />
                            )
                    }
                </TableCell>

                <TableCell sx={{ padding: '8px 16px' }}>
                    {row.so_number}
                </TableCell>

                <TableCell sx={{ padding: '8px 16px' }}>
                    {row.customer_name}
                </TableCell>

                <TableCell sx={{ padding: '8px 16px' }}>{
                    <ShowDate date={row.requested_delivery_date} />
                }</TableCell>

                <TableCell sx={{ padding: '8px 16px' }}>{
                    <HandleDropTrailerNo
                        dropTrailerNo={dropTrailerNo ?? ''}
                        disabled={row.so_status !== 'loading' || isBeingSaved}
                        changeDropTrailerNoHandler={setDropTrailerNo} />
                }</TableCell>

                <TableCell sx={{ padding: '8px 16px' }}>
                    {row.so_status_name}
                </TableCell>

                <TableCell sx={{ padding: '8px 16px' }}>
                    {
                        <ForklifterAssignedShipmentsActions id={row.id} />
                    }
                </TableCell>

                <TableCell sx={{ padding: '8px 16px' }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell style={{ padding: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow sx={{ fontSize: "0.8rem" }}>
                                        <TableCell sx={{ width: '15%', fontSize: "inherit" }}>{t('item_no')}</TableCell>
                                        <TableCell sx={{ width: '25%', fontSize: "inherit" }}>{t('description')}</TableCell>
                                        <TableCell sx={{ width: '20%', fontSize: "inherit" }}>{t('qty_ordered')}</TableCell>
                                        <TableCell sx={{ width: '20%', fontSize: "inherit" }}>{t('quantity_loaded')}
                                            {
                                                row.so_status === 'assigned_to_fork' ?
                                                    <span style={{ color: '#0000006f', fontStyle: 'italic' }} className='text-[12px] ml-3'><i className="fa-solid fa-circle-info text-[#0000006f] mr-[5px]"></i>{t('please_accept_load')}</span>

                                                    :
                                                    ''
                                            }
                                        </TableCell>
                                        <TableCell sx={{ width: '20%', fontSize: "inherit" }}>{t('qty_shipped')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.sales_order_items.map((historyRow) => (
                                        <TableRow key={historyRow.id}>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.item_no}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.description}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_ordered}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{
                                                <HandleQtyLoaded
                                                    itemId={historyRow.id}
                                                    qtyOrdered={historyRow.qty_ordered}
                                                    qtyLoaded={historyRow.qty_loaded}
                                                    disabled={row.so_status !== 'loading' || isBeingSaved}
                                                    changeQuantityHandler={handleQtyLoadedChange}
                                                    proceedShipmentHandler={handleProceedShipment}
                                                />
                                            }</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_shipped}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <div className='p-4'>
                                <div className='flex justify-between items-center gap-4 pb-5'>
                                    <p className='font-bold text-base roboto color-fake'>{t('comments')}</p>
                                    <CommentHistoryButton
                                        badgeContent={row.comment_history?.length ?? ''}
                                        backgroundColor='#336195'
                                        onClick={() => setOpenCommentHistory(true)}
                                    />
                                </div>
                                <Box
                                    sx={{ m: 1, position: 'relative' }}
                                    className='py-4 px-2'
                                >
                                    <HandleComments
                                        disabled={row.so_status !== 'loading' || isBeingSaved}
                                        changeCommentsHandler={setComments}
                                        ref={commentsRef}
                                    />
                                    {isBeingSaved && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                                <Button
                                    sx={{
                                        backgroundColor: '#607d8b',
                                        "&:hover": {
                                            backgroundColor: '#6c8794'
                                        }
                                    }}
                                    onClick={handleSaveComment}
                                    disabled={row.so_status !== 'loading' || isBeingSaved}
                                    variant='contained'
                                    size="small">
                                    {t('save_comment')}
                                </Button>
                            </div>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

            <CommentHistoryModal
                open={openCommentHistory}
                setOpen={setOpenCommentHistory}
                comments={row.comment_history}
            />
        </React.Fragment>
    );
}

const AcceptShipmentButton = (props) => {
    const { t } = useTranslation()

    const clickAcceptShipmentButtonHandler = () => {
        props.onClick()

        Swal.fire({
            text: t('accept_load'),
            icon: 'warning',
            customClass: 'warning2',
            iconColor: '#FEB806',
            reverseButtons: true,
            showDenyButton: true,
            showCloseButton: true,
            confirmButtonText: t('accept'),
            denyButtonText: t('negatory'),
        }).then((result) => {
            if (result.isConfirmed) {
                props.highlightRow()
                acceptShipmentHandler(props.id, props.config);
            } else if (result.isDenied || result.isDismissed) {
                props.highlightRow()
            }
        })
    }

    const acceptShipmentHandler = async (id, config) => {
        await axios.put(`/api/forklift-driver/accept-shipment/${id}`, config)
            .then(({ data }) => {

            }).catch(({ response }) => {
                Swal.fire({
                    text: response.data.error.description,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            })
    }

    return (
        <AddButton hasTooltip={true} tooltipTitle={t('accept_shipment')} onClick={clickAcceptShipmentButtonHandler}>
            <span className="flex items-center justify-center">
                <LoadingTruckIcon shouldAnimate={false} />
            </span>
        </AddButton>
    )
}

const LoadShipmentButton = (props) => {
    const { t } = useTranslation()

    const clickLoadShipmentButtonHandler = () => {
        props.highlightRow(true)
        Swal.fire({
            title: t('mark_as_loaded'),
            customClass: 'warning2',
            iconColor: '#FEB806',
            reverseButtons: true,
            showDenyButton: true,
            showCloseButton: true,
            confirmButtonText: t('accept'),
            denyButtonText: t('negatory'),
        }).then((result) => {
            if (result.isConfirmed) {
                props.onClick()
            } else if (result.isDenied || result.isDismissed) {
                props.highlightRow()
            }
        })
    }

    return (
        <AddButton hasTooltip={true} tooltipTitle={t('mark_shipment_as_loaded')} onClick={clickLoadShipmentButtonHandler}>
            <span className="flex items-center justify-center">
                <i className="fa-solid fa-truck" style={{ color: "#336195" }}></i>
            </span>
        </AddButton>
    )
}

export default function CollapsibleTable({ shipments, setOpenLatestMessage }) {
    const { t } = useTranslation()

    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(10);

    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }

    return (
        <>
            <div style={{ width: '100%', mb: 2, border: 'none' }}>
                <TableContainer>
                    <Table aria-label="collapsible table" stickyHeader>
                        <TableHead>
                            <TableRow sx={{ fontSize: "0.8rem" }}>
                                <TableCell sx={{ width: '5%', fontSize: "inherit" }} />
                                <TableCell sx={{ width: '15%', fontSize: "inherit" }}>{t('sales_order_no')}</TableCell>
                                <TableCell sx={{ width: '15%', fontSize: "inherit" }}>{t('sell_to_customer_name')}</TableCell>
                                <TableCell sx={{ width: '15%', fontSize: "inherit" }}>{t('requested_delivery_date')}</TableCell>
                                <TableCell sx={{ width: '15%', fontSize: "inherit", minWidth: '160px' }}>{t('drop_trailer_no')}</TableCell>
                                <TableCell sx={{ width: '15%', fontSize: "inherit" }}>{t('status')}</TableCell>
                                <TableCell sx={{ width: '15%', fontSize: "inherit" }}>{t('actions')}</TableCell>
                                <TableCell sx={{ width: '5%', fontSize: "inherit" }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {shipments.length <= 0 ? <TableRow><TableCell colSpan={8} sx={{ textAlign: 'center' }}>{t('no_data')}</TableCell></TableRow> : null}
                            {shipments?.slice(pg * rpg, pg *
                                rpg + rpg).map((row) => (
                                    <Row
                                        key={row.id}
                                        row={row}
                                        setOpenLatestMessage={setOpenLatestMessage}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className='table-pagination'
                    labelRowsPerPage={t('rowsPerPage')}
                    rowsPerPageOptions={[10, 15]}
                    component="div"
                    count={shipments.length}
                    rowsPerPage={rpg}
                    page={pg}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>
    );
}

const ForklifterAssignedShipmentsActions = ({ id }) => {

    const { t } = useTranslation()

    return (
        <>
            <div className='flex gap-1'>
                <a href={process.env.REACT_APP_BACKEND_URL + `/api/sales-orders/${id}/load-sheet?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}
                    target="_blank" download>
                    <button className='p-1.5 rounded text-neutral-500 hover:bg-neutral-100'>
                        <div>
                            <i className="fa-solid fa-eye"></i><span style={{ 'marginLeft': '7px' }}>{t('view_x', { x: t('load_sheet') })}</span>
                        </div>
                    </button>
                </a>
            </div>
        </>
    )
}
