import CustomCard from "../CustomCard"
import { useTranslation } from "react-i18next"
import { IconButton, Stack, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import CustomCardContent from "./utils/CustomCardContent"

export default function ActiveShipments({data = [], showActions = false, isLoading = false, isUpdating = false, clientIsBrokerOnly = false}) {
    const { t } = useTranslation()

    return (
        <CustomCard
            title={t('active_shipments')}
            showActions={showActions}
            actions={
                showActions ? (
                    <Stack direction='row' spacing={2}>
                        <Tooltip title={`${t('view_x', {x: clientIsBrokerOnly ? t('active_broker_transactions') : t('active_shipments')})}`} arrow placement="top">
                            <Link to={clientIsBrokerOnly ? '/admin-broker-transactions?get=active' : '/shipments?get=active'}>
                                <IconButton aria-label={`view-active-shipments-button`} size='small'>
                                    <i className="fa-solid fa-eye fa-sm"></i>
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </Stack>
                ) : (
                    ''
                )
            }
            isLoading={isLoading}
            isUpdating={isUpdating}
        >
            <CustomCardContent
                qty={data?.all_active_shipments_count ?? 0}
                supplementaryQty={data?.current_month_active_shipments_count ?? 0}
                pct={data?.diff_pct ?? 0}
            />
        </CustomCard>
    )
}
