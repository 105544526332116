import React, { useState, useEffect } from 'react'

import { Tooltip, Chip, Box, Checkbox, ListItemText, ListItemIcon, FormControl, MenuItem, Select, InputLabel } from '@mui/material'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { Link, useSearchParams } from 'react-router-dom'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import { useDebounce } from "use-debounce"
import { includes } from 'lodash'

import AppLayout from '../../components/Layouts/AppLayout'
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import ServerTable from '../../components/ServerTable'
import axios from '../../lib/axios'
import { useStateContext } from '../../context/ContextProvider'
import { useAuth } from '../../hooks/auth'
import { handleAxiosError } from '../../helpers/helper'

const BrokerTransactions = () => {

    const { t } = useTranslation()
    const { user } = useAuth({ middleware: 'guest' })
    const [searchParams, setSearchParams] = useSearchParams()

    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState([])
    const { config, choosesite, setChoosesite, pusher } = useStateContext()
    const [searchStatuses, setSearchStatuses] = useState([])
    const [orderDates, setOrderDates] = useState([])
    const [openItems, setOpenItems] = useState(false)
    const [random, setRandom] = useState(null)

    /* filters */
    const [soNumber, setSoNumber] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.bt_so_no || ''
        } else {
            return ''
        }
    })
    const [poNumber, setPoNumber] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.bt_po_no || ''
        } else {
            return ''
        }
    })
    const [customerName, setCustomerName] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.bt_customer_name || ''
        } else {
            return ''
        }
    })
    const [vendorName, setVendorName] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.bt_vendor_name || ''
        } else {
            return ''
        }
    })
    const [searchDate, setSearchDate] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.bt_requested_delivery_date || ''
        } else {
            return ''
        }
    })
    const [searchStatus, setSearchStatus] = useState(() => {
        if (!searchParams.has('get') && localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.bt_status || []
        } else {
            return []
        }
    })
    const [searchStatusId, setSearchStatusId] = useState(() => {
        if (!searchParams.has('get') && localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.bt_status_id || []
        } else {
            return []
        }
    })
    const [debouncedValue] = useDebounce(soNumber, 500);
    const [debouncedValue1] = useDebounce(poNumber, 500);
    const [debouncedValue2] = useDebounce(customerName, 500);
    const [debouncedValue3] = useDebounce(vendorName, 500);
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)

    const isAllSelectedShipTo = searchStatuses.length > 0 && searchStatus.length === searchStatuses.length;

    const [model, setModel] = useState({
        actions: true,
        bt_status_name: true,
        customer_name: true,
        po_number: true,
        purchase_location: true,
        requested_delivery_date: true,
        ship_to: true,
        so_number: true,
        vendor_name: true
    })

    useEffect(() => {
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-deleted-site-${localStorage.getItem('site')}`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-created-site-${localStorage.getItem('site')}`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-brokertransaction-deleted-event-site-${localStorage.getItem('site')}`, data => {
            //usporediti datume da vidimo u koji order ide
            setRandom(Math.random())
        })

        channelcreate.bind(`${localStorage.getItem('client_id')}-brokertransaction-created-event-site-${localStorage.getItem('site')}`, data => {
            setRandom(Math.random())
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-brokertransaction-updated-event-site-${localStorage.getItem('site')}`, data => {
            setRandom(Math.random())
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-created-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)
        })
    }, [choosesite])

    useEffect(() => {
        setChoosesite(localStorage.getItem('site'))
    }, [])

    useEffect(() => {
        getStatuses()
        getTableView()
    }, [])

    const getTableView = async () => {
        setIsLoading(true)
        await axios.get(`/api/get-table-view?view_name=broker_transactions_table&user_id=${user?.id}`, config)
            .then(res => {
                const model = res.data
                if (Object.keys(model?.success?.data).length > 0) {
                    setModel(model.success.data)
                }
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (choosesite) {
            getOrders()
        }
        createFilterArray(soNumber, poNumber, customerName, vendorName, searchDate, searchStatus, searchStatusId)
    }, [choosesite, searchDate, searchStatus, page, pageSize, debouncedValue, debouncedValue1, debouncedValue2, debouncedValue3, random])

    const getOrders = async () => {
        setIsLoading(true)
        await axios.get(`/api/list-orders?order_type=broker&site_id=${choosesite}&customer_name=${customerName}&vendor_name=${vendorName}&so_number=${soNumber}&po_number=${poNumber}&status_id=${searchStatusId}&requested_delivery_date=${searchDate}&page=${page + 1}&page_size=${pageSize}`, config)
            .then(res => {
                const orders = res.data?.data
                const count = res.data?.total
                setItems(orders)
                getOrderDates(orders)
                setTotal(count)
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getStatuses = async () => {

        await axios.get(`api/broker-transaction-statuses`, config)
            .then(res => {
                const statuses = res.data
                setSearchStatuses(statuses)

                if (statuses.length > 0 && searchParams.has('get')) {
                    let objects = []
                    let ids = []

                    switch (searchParams.get('get')) {
                        case 'active':
                            ids = [1, 2, 3, 5]
                            objects = statuses.filter((status) => includes(ids, status.id))
                            break
                        case 'inactive':
                            ids = [4]
                            objects = statuses.filter((status) => includes(ids, status.id))
                            break
                        default:
                            ids = []
                            objects = []
                    }

                    setSearchStatusId(ids)
                    setSearchStatus(objects)
                }
            })

    }

    /* handlers */
    const handleSoNumber = (e) => {
        setSoNumber(e.target.value)
    }

    const handlePoNumber = (e) => {
        setPoNumber(e.target.value)
    }

    const handleCustomerName = (e) => {
        setCustomerName(e.target.value)
    }

    const handleVendorName = (e) => {
        setVendorName(e.target.value)
    }

    const handleClearDate = () => {
        setSearchDate('')
        getOrderDates(items)
    }

    const handleClearStatuses = () => {
        searchParams.delete('get')
        setSearchParams(searchParams)
        setSearchStatus([])
        setSearchStatusId([])
    }

    const handleDeleteStatus = (e, id) => {
        const shouldDelete = searchStatus.find((x) => x.id === id)
        let statusIds = []
        if (shouldDelete) {
            const filtered = searchStatus.filter((x) => x.id !== shouldDelete.id)
            searchParams.delete('get')
            setSearchParams(searchParams)
            if (filtered.length > 0) {
                filtered.forEach((stl) => {
                    if (stl.hasOwnProperty('id')) searchStatusId.push(stl.id)
                })
                statusIds = filtered.map((filter) => filter.id)
                setSearchStatus(filtered)
                setSearchStatusId(statusIds)
            } else {
                setSearchStatus([])
                setSearchStatusId([])
                setOpenItems(false)
            }
        }
    }

    const handleChangeStatuses = (event) => {
        const { target: { value } } = event

        let duplicateRemoved = [];

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
                duplicateRemoved.push(item);
            }
        })

        let ids = []

        value.forEach((item) => {
            if (ids.findIndex((o) => o.id === item.id) >= 0) {
                ids = ids.filter((x) => x.id === item.id);
            } else {
                ids.push(item.id);
            }
        })


        if (value[value.length - 1] === "all") {
            setSearchStatus(searchStatus.length === searchStatuses.length ? [] : searchStatuses)
            setSearchStatusId(searchStatus.length === searchStatuses.length ? [] : searchStatuses.map(i => i.id))
            return;
        }

        searchParams.delete('get')
        setSearchParams(searchParams)
        setSearchStatus(duplicateRemoved)
        setSearchStatusId(ids)
    }


    const getOrderDates = (salesOrders) => {

        let dates = [];

        salesOrders.forEach(order => {
            let newFutureDate = order?.sales_order?.requested_delivery_date;

            let newFutureDateExists = dates.includes(newFutureDate);

            if (!newFutureDateExists) {
                dates.push(newFutureDate);
            }
        });

        dates.sort();

        setOrderDates(dates);
    }

    const createFilterArray = (soNumber, poNumber, customerName, vendorName, searchDate, searchStatus, searchStatusId) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].bt_so_no = soNumber
        filters[0].bt_po_no = poNumber
        filters[0].bt_customer_name = customerName
        filters[0].bt_vendor_name = vendorName
        filters[0].bt_requested_delivery_date = searchDate
        filters[0].bt_status = searchStatus
        filters[0].bt_status_id = searchStatusId


        localStorage.setItem('filters', JSON.stringify(filters))
    }

    const columns = [
        {
            field: 'so_number',
            headerName: t('sales_order_no'),
            flex: 0.7,
            minWidth: 200,
            renderCell: (params) => <div>{params.row.sales_order?.so_number}</div>,
            cellClassName: 'relative'
        },
        {
            field: 'po_number',
            headerName: t('purchase_order_no'),
            flex: 0.7,
            renderCell: (params) => <div>{params.row.purchase_order?.po_number}</div>,
            minWidth: 200
        },
        {
            field: 'customer_name',
            headerName: t('customer_name'),
            flex: 1,
            renderCell: (params) => <div>{params.row.customer?.name}</div>,
            minWidth: 200
        },
        {
            field: 'ship_to',
            headerName: t('ship_to'),
            flex: 1,
            renderCell: (params) => <div>{params.row.ship_address?.address}</div>,
            minWidth: 200
        },
        {
            field: 'vendor_name',
            headerName: t('vendor_name'),
            flex: 1,
            renderCell: (params) => <div>{params.row.vendor?.name}</div>,
            minWidth: 200
        },
        {
            field: 'purchase_location',
            headerName: t('purchase_location'),
            flex: 1,
            renderCell: (params) => <div>{params.row.purchase_address?.address}</div>,
            minWidth: 200
        },
        {
            field: 'requested_delivery_date',
            headerName: t('requested_delivery_date'),
            flex: 0.7,
            renderCell: (params) => <ShowDate params={params} />,
            minWidth: 200
        },
        {
            field: 'bt_status_name',
            /* field: 'bt_status_slug',
            renderCell: (params) => <div style={{textTransform: 'capitalize'}}>{t(params.row.bt_status_slug)}</div>, */
            headerName: t('status'),
            flex: 0.7,
            minWidth: 200
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => <TodaysSalesOrderAction row={params?.row} config={config} setIsLoading={setIsLoading} t={t} />,
            minWidth: 200
        }
    ]

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <div className='p-5 pt-0'>
                    <div className='pb-5 bg-white mb-2 rounded-md'>
                        <div className='border-b pb-5'>
                            <div className='flex justify-between items-end w-full'>
                                <div className='px-5 pt-5 w-full'>
                                    <div className='flex justify-between items-center search'>
                                        <input type="text" placeholder={t('search_by_sales_no')} value={soNumber} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place'  onChange={handleSoNumber} />
                                        <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                    </div>
                                </div>
                                <div className='pt-5 w-full'>
                                    <div className='flex justify-between items-center search'>
                                        <input type="text" placeholder={t('search_by_purchase_no')} value={poNumber} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={handlePoNumber} />
                                        <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                    </div>
                                </div>
                                <div className='px-5 pt-5 w-full'>
                                    <div className='flex justify-between items-center search'>
                                        <input type="text" placeholder={t('search_by_customer_name')} value={customerName} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={handleCustomerName} />
                                        <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                    </div>
                                </div>
                            </div>

                            <div className='flex justify-between items-end w-full'>

                                <div className='px-5 pt-5 w-full'>
                                    <div className='flex justify-between items-center search'>
                                        <input type="text" placeholder={t('search_by_vendor_name')} value={vendorName} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={handleVendorName} />
                                        <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                    </div>
                                </div>

                                <div className='pt-5 w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel id="demo-simple-select-standard-label">{t('search_by_request_delivery_date')}</InputLabel>
                                        <Select
                                            value={searchDate}
                                            onChange={e => setSearchDate(e.target.value)}
                                            label="Search Past Or Present Date"
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: searchDate ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={searchDate ? (<IconButton sx={{ visibility: searchDate ? "visible" : "hidden", padding: '0' }} onClick={handleClearDate}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                orderDates.length < 1 ?
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                    :
                                                    orderDates.map((date) => (
                                                        <MenuItem key={date} value={date}>{date}</MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='px-5 pt-5 w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel id="demo-simple-select-standard-label">{t('search_by_status')}</InputLabel>
                                        <Select
                                            multiple
                                            value={searchStatus}
                                            open={openItems}
                                            onOpen={() => setOpenItems(true)}
                                            onClose={() => setOpenItems(false)}
                                            onChange={handleChangeStatuses}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((x) => (
                                                        <Chip
                                                            key={`i_option-${x.id}`}
                                                            label={x.bt_name}
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                            onDelete={(e) => handleDeleteStatus(e, x.id)}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconStandard": { display: (searchStatus.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={searchStatus ? (<IconButton sx={{ visibility: (searchStatus.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearStatuses}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                searchStatuses?.length > 0 ?
                                                    <MenuItem value="all">
                                                        <ListItemIcon>
                                                            <Checkbox checked={isAllSelectedShipTo} indeterminate={searchStatus.length > 0 && searchStatus.length < searchStatuses.length} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('select_all')} />
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                            }
                                            {
                                                searchStatuses.map((it) =>
                                                    <MenuItem value={it} key={it.id}>
                                                        <Checkbox checked={searchStatus?.findIndex((i) => i.id === it.id) >= 0} />
                                                        <ListItemText primary={it?.bt_name} />
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>

                                </div>
                            </div>
                        </div>

                        <div className='pt-3'>
                            <ServerTable
                                items={items}
                                columns={columns}
                                total={total}
                                setPage={setPage}
                                setPageSize={setPageSize}
                                page={page}
                                pageSize={pageSize}
                                columnVisibilityModel={model}
                                setModel={setModel}
                                view='broker_transactions'
                            />
                        </div>
                    </div>
                </div>

            </AppLayout>
        </>
    )
}

export default BrokerTransactions


const TodaysSalesOrderAction = (params) => {
    const { row, config, setIsLoading, t } = params
    const uploaded_driver_bol = row?.type === 'shipment'
        ? row.sales_order?.uploaded_driver_bol
        : row.purchase_order?.uploaded_driver_bol

    const getPodUrl = (
        stream = 'view',
        party = 'customer'
    ) => {
        if(uploaded_driver_bol)
            return uploaded_driver_bol?.document_url

        if(row?.uploaded_bol)
            return row.uploaded_bol.document_url

        const urlStem = `broker-transactions/${row.id}`
        const urlRoot = `${process.env.REACT_APP_BACKEND_URL}/api`
        const urlLeaves = `proof-of-delivery?stream=${stream}&party=${party}&CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`

        return `${urlRoot}/${urlStem}/${urlLeaves}`
    }

    const deleteShipment = async () => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_bt') + row.bt_number + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }

        setIsLoading(true)
        await axios
            .delete(`/api/broker-transactions/${row.id}`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title="BOL/POD" placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <a href={getPodUrl()} target="_blank"><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-print"></i></span></a>
                    </div>
                </Tooltip>

                {includes(['open', 'reopened'], row.bt_status_slug) ?
                    (<Tooltip disableInteractive title={t('update')} placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <Link to={`/broker-transactions/${row.id}`} ><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-pencil"></i></span></Link>
                        </div>
                    </Tooltip>)
                    :
                    (<Tooltip disableInteractive title={t('open')} placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <Link to={`/broker-transactions/${row.id}`} ><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-eye"></i></span></Link>
                        </div>
                    </Tooltip>)
                }

                {/* Show Delete button if NOT Invoiced */}
                {row.bt_status_slug !== 'invoiced' ? (
                    <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={deleteShipment}><i className="fa-solid fa-trash"></i></span>
                        </div>
                    </Tooltip>
                ) : (
                    ''
                )}
            </div>
        </>
    )
}

export const ShowDate = (params) => {

    return (
        <div>
            <p>{dayjs(params.params.row.requested_delivery_date).format("YYYY-MM-DD")}</p>
        </div>
    )
}
