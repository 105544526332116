import React, { useState, useEffect } from 'react'

import axios from '../../lib/axios'
import { Box, Modal, Tooltip, InputLabel, FormControl, Select, MenuItem } from '@mui/material'
import Swal from 'sweetalert2'
import { Link, useNavigate } from 'react-router-dom'

import AddButton from '../AddButton'
import TableShared from '../TableShared'
import { useTranslation } from "react-i18next"
import { useStateContext } from '../../context/ContextProvider'
import style from '../../styles/style'
import SalesContractItemsTable from './SalesContractItemsTable'
import AddItem from './AddItem'

const SalesContracts = ({ customerId, setIsLoading, customerName }) => {

    const { t } = useTranslation()
    const { config, pusher } = useStateContext()
    const navigate = useNavigate()

    const [salesContract, setSalesContract] = useState([])
    const [shipToAddresses, setShipToAddresses] = useState([])
    const [open, setOpen] = useState(false)
    const [openItem, setOpenItem] = useState(false)

    const [shipToAddressId, setShipToAddressId] = useState('')
    const [items, setItems] = useState([])

    const [shipToAddress, setShipToAddress] = useState('')
    const [address, setAddress] = useState([])


    { /**
     * EDIT 27/11/2023
     *
     * Commented out the update event binding. No changes done to an existing Sales Contract will reflect on the SalesContracts view.
     * Since the Vendor and Sales Address of the Sales Contract cannot be changed after a Contract has been created,
     * that leaves the Contract Items as the only mutable properties of the Contract. These Items - however - are not seen anywhere else besides the UpdateSalesContracts view
     * so it makes sense for that component to be subscribed to the update event.
    */ }
    useEffect(() => {
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-salescontract-deleted`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-salescontract-created`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-salescontract-updated`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-salescontract-deleted-event`, data => {
            if(Number(customerId) == Number(data.customer_id)) {
                getSalesContract(customerId)
            }
        })

        channelcreate.bind(`${localStorage.getItem('client_id')}-salescontract-created-event`, data => {
            if(Number(customerId) == Number(data.customer_id)) {
                getSalesContract(customerId)
            }
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-salescontract-updated-event`, data => {
            /* if(Number(customerId) == Number(data.customer_id)) {
                getSalesContract(customerId)
            } */
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salescontract-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salescontract-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salescontract-updated`)
        })
    }, [])

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '13') {
                if (open) createSalesContract()
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [open, items, shipToAddressId]);


    useEffect(() => {
        if (customerId) {
            getSalesContract(customerId)
            getShipToAddresses(customerId)
        }
    }, [customerId])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setItems([])
        setShipToAddressId('')
        setShipToAddress('')
        setAddress([])
    }
    const handleCloseItem = () => setOpenItem(!openItem)

    useEffect(() => {
        if (shipToAddress) getShipAddress(shipToAddress)
    }, [shipToAddress])


    /* request */
    const getSalesContract = async (id) => {
        setIsLoading(true)
        await axios.get(`/api/sales-contracts?customer_id=${id}`, config)
            .then(res => {
                const salesContract = res.data
                setSalesContract(salesContract)
                setIsLoading(false)
            })
    }

    const getShipToAddresses = async (id) => {
        setIsLoading(true)
        await axios.get(`/api/ship-addresses?customer_id=${id}`, config)
            .then(res => {
                const shipTo = res.data
                setShipToAddresses(shipTo)
                setIsLoading(false)
            })
    }

    const getShipAddress = async (id) => {
        setIsLoading(true)
        await axios.get(`/api/ship-addresses/${id}`, config)
            .then(res => {
                const shipTo = res.data
                setAddress(shipTo)
                setIsLoading(false)
            })
    }

    const handleShip = async (e) => {
        setIsLoading(true)
        await axios.get(`/api/sales-contracts?ship_address_id=${e}&customer_id=${customerId}`, config)
            .then(res => {
                let shipTo = res.data
                if (shipTo.length === 0) {
                    setShipToAddress(e)
                    setShipToAddressId(e)
                } else {
                    Swal.fire({
                        title: t('sales_contract_exists'),
                        text: 'You will be transferred to the update of the existing sales contract.',
                        icon: 'warning',
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FFCA28'
                    })
                    navigate(`/sales-contracts/${shipTo[0].id}`)
                }
                setIsLoading(false)
            })
    }

    const updateItem = (id, quantity, unit_price) => {
        const itemsCopy = [...items]
        const item = itemsCopy.find(item => item.id === id)

        item.quantity = quantity
        item.unit_price = unit_price
        item.total_cost = quantity * unit_price

        setItems(itemsCopy)
    }


    const createSalesContract = async () => {

        setIsLoading(true)
        const formData = {}

        formData['customer_id'] = customerId
        formData['ship_address_id'] = shipToAddressId
        formData['sales_contract_items'] = items


        await axios.post(`/api/sales-contracts`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })

            setIsLoading(false)

            setItems([])

            handleClose()
        }).catch(({ response }) => {
            if (response.status === 422) {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            }
            setIsLoading(false)
        })
    }

    const columns = [
        {
            field: 'id',
            headerName: t('id'),
            flex: 1
        },
        {
            field: 'ship_to_code',
            headerName: t('shipToCode'),
            flex: 1
        },
        {
            field: 'ship_to_name',
            headerName: t('ship_to_name'),
            flex: 1
        },
        {
            field: 'ship_address',
            headerName: t('shipToAdd'),
            flex: 1
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 1,
            cellClassName: 'padding-0',
            renderCell: (params) => <Actions params={params} config={config} setIsLoading={setIsLoading} />
        }
    ]

    return (
        <>
            <div className='pb-5 bg-white mb-2 rounded-md'>
                <div className='flex justify-between items-center p-5 border-b'>
                    <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('sales_contracts')}</p>
                    <Tooltip disableInteractive title={t('addSalesContract')} placement='bottom'>
                        <div>
                            <AddButton onClick={handleOpen}><i className="fa-solid fa-plus"></i></AddButton>
                        </div>
                    </Tooltip>
                </div>
                <div className='pt-3'>
                    <TableShared items={salesContract} columns={columns} />
                </div>
            </div>


            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <div className='flex justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                        <div className='flex items-center justify-between w-full leading-none'>
                            <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#242F40] text-[35px] mr-4'>•</span> {t('addSalesContract')}</p>
                            <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                                <button onClick={handleClose}><i className="fa-solid fa-plus"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='p-5 pb-12 flex justify-between gap-3 flex-col lg:flex-row'>
                        <div className='bg-white p-6 pt-0 rounded-md w-full lg:w-2/5 h-fit edit-view'>
                            <div className='pb-11 pt-1'>
                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('general_information')}</p>
                            </div>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('customer')} *</p>
                                <div className='w-full'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Select value={customerId} disabled
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}>
                                            <MenuItem value={customerId}>{customerName}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className='w-full'>
                                <p className="text-[14px] text-[#718096]">{t('ship_to_location')} *</p>
                                <div className='w-full'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Select value={shipToAddress} onChange={e => handleShip(e.target.value)}
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}>
                                            {shipToAddresses?.map((item, index) => <MenuItem key={index} value={item.id}>{item.code} - {item.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {
                                shipToAddress ?
                                    <div className='w-full p-4 address mb-4'>
                                        <div className='w-full'>{address.address}</div>
                                        {shipToAddress ? <div className='w-full'>{address?.zip} {address?.city}, {address?.state}</div> : <div className='w-full'></div>}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <div className='w-full lg:w-3/5 h-fit'>
                            <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                                <div className='pt-1 pl-3'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('salesContractItems')}</p>
                                </div>
                                <div className='flex justify-end p-5 pt-0 border-b'>
                                    <Tooltip disableInteractive title={t('addsalesContractItems')} placement='bottom'>
                                        <div>
                                            <AddButton onClick={handleCloseItem}><i className="fa-solid fa-plus"></i></AddButton>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div>
                                    {
                                        items.length > 0 ?
                                            <div className='flex justify-end w-full p-2'>
                                                <button className='px-2 py-1 text-white bg-red-600 font-bold rounded-md mb-1 roboto text-xs uppercase' onClick={e => setItems([])}>{t('clear_all')}</button>
                                            </div>
                                            :
                                            null
                                    }
                                    <SalesContractItemsTable items={items} itemsLength={items.length} setItems={setItems} handler={updateItem} />
                                </div>
                            </div>
                            <div className='w-full flex justify-end'>
                                <button type="button" onClick={createSalesContract} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#DBA34B] hover:bg-[#CF7300] disabled:bg-[#B8B7BC]'>
                                    <span className='pr-2'><i className="fa-solid fa-plus"></i></span> {t('create')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <AddItem open={openItem} handleClose={handleCloseItem} items={items} setItems={setItems} />
                </Box>
            </Modal>
        </>
    )
}

export default SalesContracts


const Actions = (params) => {

    const { t } = useTranslation()

    const deleteSalesContract = async (id) => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_so') + id + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        await axios.delete(`/api/sales-contracts/${id}`, params.config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
        })
    }


    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('edit')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <Link to={`/sales-contracts/${params.params.row.id}`}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-pencil"></i></span></Link>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteSalesContract(params.params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
