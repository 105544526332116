import dayjs from "dayjs";
import { buildUrl } from "../../../helpers/apiHelper";
import { handleAxiosError } from "../../../helpers/helper";
import axios from "../../../lib/axios"

const exportBrokerTransactions = async (
    config,
    siteId,
    siteName,
    locale = 'en'
) => {
    const baseUrl = `/api/broker-transactions/export/${siteId}/${locale}`;

    return await axios
        .get(baseUrl, {
            ...config,
            responseType: 'blob'
        })
        .then(( response ) => {
            const url = window.URL.createObjectURL(new Blob(
                [response.data],
                { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
            ))
            const link = document.createElement('a')
            const timestamp = dayjs().format('YYYY-MM-DD')
            const filename = `Broker Transaction Export - ${timestamp} - ${siteName}.xlsx`
            link.href = url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
        })
        .catch(({ response }) => {
            handleAxiosError({response: response})
        })
}

const brokerTransactionAPI = {
    exportBrokerTransactions
}

export default brokerTransactionAPI
