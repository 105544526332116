import React from 'react'

import TableShared from '../TableShared'
import { useTranslation } from 'react-i18next'
import { useStateContext } from '../../context/ContextProvider'
import { LinearProgress } from '@mui/material'

const CurrencyCell = ({params, propertyName}) => {
    const { currencySymbol } = useStateContext()

    return (
        <>{currencySymbol}{params.row[propertyName].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const ShipmentsSummaryData = ({ items, isUpdating = false }) => {

    const { t } = useTranslation()

    /* [{
        id: 0,
        month: 'January',
        year: 2023,
        total_pallets: 234,
        avg_load: 34.4,
        total_value: 900,
        number_of_shipments: 3
    },
    {
        id: 2,
        month: 'April',
        year: 2022,
        total_pallets: 24,
        avg_load: 21,
        total_value: 90,
        number_of_shipments: 12
    }] */

    const columns = [

        {
            field: 'month',
            headerName: t('month'),
            flex: 1
        },
        {
            field: 'year',
            headerName: t('year'),
            flex: 1
        },
        {
            field: 'total_pallets',
            headerName: t('total_pallets'),
            flex: 1
        },
        {
            field: 'avg_load',
            headerName: t('avg_load'),
            flex: 1,
            renderCell: (params) => <CurrencyCell params={params} propertyName="avg_load" />
        },
        {
            field: 'total_value',
            headerName: t('total_value'),
            flex: 1,
            renderCell: (params) => <CurrencyCell params={params} propertyName="total_value" />
        }
        ,
        {
            field: 'number_of_shipments',
            headerName: t('number_of_shipments'),
            flex: 1
        }
    ]

    return (
        <div className=''>
            <div>
                <div className='flex justify-start items-center p-5 border-b'>
                    <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('shipments_summary_data')}</p>
                </div>
            </div>
            {isUpdating && <LinearProgress />}
            <div className='pt-3'>
                <TableShared items={items} columns={columns} rowsPerPageOptions={[5, 10]} border='none' />
            </div>
        </div>
    )
}

export default ShipmentsSummaryData
