import React from 'react'

import { TextField } from '@mui/material'
import { DemoItem } from "@mui/x-date-pickers/internals/demo"

const InventoryDocumentNoField = ({ t, documentNo, setDocumentNo, disabled }) => {
    return (
        <div className='w-full pb-4'>
            <p className="text-[14px] text-[#718096]">{t('inventory_document_no')} *</p>
            <div className='w-full'>
                <TextField type="text"
                    disabled={disabled}
                    sx={{
                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                    }}
                    className='w-full max-[560px]:w-full' value={documentNo} onChange={(e) => { setDocumentNo(e.target.value) }} />
            </div>
        </div>
    )
}

export default InventoryDocumentNoField
