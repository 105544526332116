import React from 'react'

import { Link } from 'react-router-dom'

const NavigationProductionOrders = ({
    type = 'list',
    route
}) => {
    return (
        <div className='w-full flex items-center justify-end gap-1 p-6 pt-0'>
            <div className='flex w-fit items-center justify-center rounded-md px-1 bg-[#ededf1]' style={{ boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 4px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset' }}>
                <Link to={`/${route}`} className='w-full'>
                    <div className={`w-full text-center m-1 mr-0 ml-0 ${type === 'list' ? 'bg-white rounded-sm' : ''}`}
                        style={{
                            border: type === 'list' ? '0.5px solid rgba(218, 218, 218, 0.02)' : '',
                            boxShadow: type === 'list' ? '0px 1px 0.75px 0px rgba(0, 0, 0, 0.05), 0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.10)' : ''
                        }}>
                        <p className='p-1.5 px-3'><i className="fa-solid fa-list"></i></p>
                    </div>
                </Link>
                <Link to={`/${route}-calendar`} className='w-full'>
                    <div className={`w-full text-center m-1 ml-0 ${type === 'calendar' ? 'bg-white rounded-sm' : 'hover:bg-[#D8D8D8] rounded-sm'}`}
                        style={{
                            border: type === 'calendar' ? '0.5px solid rgba(218, 218, 218, 0.02)' : '',
                            boxShadow: type === 'calendar' ? '0px 1px 0.75px 0px rgba(0, 0, 0, 0.05), 0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.10)' : ''
                        }}>
                        <p className='p-1.5 px-3'><i className="fa-regular fa-calendar"></i></p>
                    </div>
                </Link>
            </div>
        </div >
    )
}

export default NavigationProductionOrders
