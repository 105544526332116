import React from 'react'

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"

const CountDate = ({ t, countDate, handleCountDate, disabled }) => {
    return (
        <div className='w-full pb-4'>
            <p className="text-[14px] text-[#718096]">{t('count_date')} *</p>
            <div className='w-full'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["MobileDatePicker"]} >
                        <MobileDatePicker
                            value={countDate}
                            disabled={disabled}
                            views={["year", "month", "day"]}
                            sx={{
                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                            }}
                            onChange={(newValue) => handleCountDate(newValue)}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </div>
        </div>

    )
}

export default CountDate
