import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Box, TextField } from "@mui/material"
import Swal from 'sweetalert2'
import { useParams, Link, useNavigate } from "react-router-dom"
import Loading from "../../components/Loading"

import { useStateContext } from '../../context/ContextProvider'
import axios from "../../lib/axios"
import { isNull } from 'lodash'
import { handleAxiosError, isValid } from "../../helpers/helper"

const UpdateShipToAddress = () => {

    const { t } = useTranslation();
    const { config, pusher } = useStateContext()
    const params = useParams()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)


    /* FORM FIELDS */
    /* general */
    const [code, setCode] = useState('')
    const [name, setName] = useState('')
    const [address, setAddress] = useState("")
    const [address2, setAddress2] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [zip, setZip] = useState("")
    const [phone, setPhone] = useState("")
    const [contact, setContact] = useState("")
    const [customerId, setCustomerId] = useState("")
    const [deliveryInstructions, setDeliveryInstructions] = useState('')

    useEffect(() => {
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-deleted`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-shipaddress-deleted-event`, data => {
            if(Number(data.id) == Number(params.id)) {
                navigate(`/customers/${data.customer_id}?tab=ship-to-locations`)
            }
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-shipaddress-updated-event`, data => {
            if(Number(data.id) == Number(params.id)) {
                getShipToAddress()
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-customer-deleted`)
        })
    }, [])

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate(-1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    useEffect(() => {
        getShipToAddress()
    }, [])

    useEffect(() => {
        if(isValid(customerId)) {
            const channelCustomerDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-customer-deleted`)

            channelCustomerDeleted.bind(`${localStorage.getItem('client_id')}-customer-deleted-event`, data => {
                if(Number(data.id) == Number(customerId)) {
                    navigate('/customers')
                }
            })
        }

        return (() => {
            if(isValid(customerId)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-customer-deleted`)
            }
        })
    }, [customerId])

    const getShipToAddress = async (id = null) => {
        setIsLoading(true)
        await axios
            .get(`/api/ship-addresses/${isNull(id) ? params.id : id}`, config)
            .then(res => {
                const data = res.data
                setCode(data.code)
                setName(data.name)
                setAddress(data.address)
                setAddress2(data.address2)
                setPhone(data.phone)
                setCity(data.city)
                setState(data.state)
                setZip(data.zip)
                setContact(data.contact)
                setCustomerId(data.customer_id)
                setDeliveryInstructions(data.delivery_instructions)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const updateShipTo = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formData = {}

        formData['code'] = code
        formData['name'] = name
        formData['address2'] = address2
        formData['address'] = address
        formData['phone'] = phone
        formData['city'] = city
        formData['state'] = state
        formData['zip'] = zip
        formData['contact'] = contact
        formData['customer_id'] = customerId

        formData['delivery_instructions'] = deliveryInstructions

        await axios
            .put(`/api/ship-addresses/${params.id}`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                setIsLoading(false)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }
    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box>
                <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#242F40] text-[35px] mr-4'>•</span> {t('update_shipto')} - {code}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <Link to={`/customers/${customerId}?tab=ship-to-locations`}><i className="fa-solid fa-plus"></i></Link>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-white h-full'>
                    <div className='bg-white p-6 w-full'>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('code')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    disabled type="text" className='w-full' name="drop_trailer_no" value={code} onChange={(e) => { setCode(e.target.value) }} />
                            </div>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('name')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={name} onChange={(e) => { setName(e.target.value) }} />
                            </div>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('address')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={address} onChange={(e) => { setAddress(e.target.value) }} />
                            </div>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('address2')}</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={address2} onChange={(e) => { setAddress2(e.target.value) }} />
                            </div>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('city')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={city} onChange={(e) => { setCity(e.target.value) }} />
                            </div>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('state')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={state} onChange={(e) => { setState(e.target.value) }} />
                            </div>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('zip')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={zip} onChange={(e) => { setZip(e.target.value) }} />
                            </div>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('contact')}</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={contact} onChange={(e) => { setContact(e.target.value) }} />
                            </div>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('phone')}</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                            </div>
                        </div>

                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('delivery_instructions')}</p>
                            <TextField
                                multiline
                                rows={3}
                                value={deliveryInstructions}
                                onChange={e => setDeliveryInstructions(e.target.value)}
                                sx={{
                                    width: '100%',
                                    background: '#FCFCFC',
                                    '.MuiOutlinedInput-input': {
                                        padding: '9px !important'
                                    },
                                    boxShadow: 'none',
                                    '.MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #EEEFF2 !important'
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className='w-full flex justify-end px-6 pb-14'>
                        <button type="button" onClick={updateShipTo} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC]'>
                            <span className='pr-2'><i className="fa-solid fa-clock-rotate-left"></i></span> {t('update')}
                        </button>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default UpdateShipToAddress
