import React from 'react'

import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'

const Period = ({ t, period, handleChangePeriod, disabled }) => {
    return (
        <div className='w-full pb-4'>
            <p className="text-[14px] text-[#718096]">{t('period')} *</p>
            <div className='w-full'>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={period}
                        onChange={handleChangePeriod}
                        row
                    >
                        <FormControlLabel value="day" control={<Radio disabled={disabled} />} label={t('day')} />
                        <FormControlLabel value="week" control={<Radio disabled={disabled} />} label={t('week')} />
                        <FormControlLabel value="month" control={<Radio disabled={disabled} />} label={t('month')} />
                        <FormControlLabel value="quarter" control={<Radio disabled={disabled} />} label={t('quarter')} />
                        <FormControlLabel value="year" control={<Radio disabled={disabled} />} label={t('year')} />
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    )
}

export default Period
