import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Box, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Tooltip } from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import update from 'immutability-helper'

import TableSharedCustom from '../../components/TableSharedCustom'
import AppLayout from '../../components/Layouts/AppLayout'
import AddButton from '../../components/AddButton'
import Loading from '../../components/Loading'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import style from '../../styles/style'
import { useAuth } from "../../hooks/auth";

const Contacts = () => {
    const { user } = useAuth({ middleware: 'guest ' })

    const { config, pusher } = useStateContext()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [random, setRandom] = useState(null)
    const [companyId, setCompanyId] = useState(() => {
        return (user?.role === 'production_supervisor' || user?.role === 'office_manager') ? user?.company_id : ''
    })
    const [siteId, setSiteId] = useState(() => {
        return user?.role === 'production_supervisor' ? user?.site_id : ''
    })
    const [searchName, setSearchName] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.contact_name || ''
        } else {
            return ''
        }
    })
    const [searchRole, setSearchRole] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.contact_role || ''
        } else {
            return ''
        }
    })
    const [contacts, setContacts] = useState([])

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [role, setRole] = useState(9)

    const [model, setModel] = useState({
        actions: true,
        email: true,
        id: true,
        name: true,
        phone: true,
        role_name: true
    })

    useEffect(() => {
        getTableView()
    }, [])

    const getTableView = async () => {
        setIsLoading(true)
        await axios.get(`/api/get-table-view?view_name=contacts_table&user_id=${user?.id}`, config)
            .then(res => {
                const model = res.data
                if (Object.keys(model?.success?.data).length > 0) {
                    setModel(model.success.data)
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {

        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-insightscontact-deleted`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-insightscontact-created`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-insightscontact-updated`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-insightscontact-deleted-event`, data => {
            setRandom(Math.random())
        })

        channelcreate.bind(`${localStorage.getItem('client_id')}-insightscontact-created-event`, data => {
            setRandom(Math.random())
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-insightscontact-updated-event`, data => {
            setRandom(Math.random())
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-insightscontact-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-insightscontact-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-insightscontact-updated`)
        })
    }, [])

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '13') {
                if (open) createContact()
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [open, name, role, phone, siteId, companyId, email]);


    useEffect(() => {
        getContacts()
        setCompanyId(localStorage.getItem('company_id'))
        setSiteId(localStorage.getItem('site'))
    }, [searchRole, random])

    useEffect(() => {
        if(!open) {
            setName('')
            setPhone('')
            setEmail('')
            setRole(9)
        }
    }, [open])

    const getContacts = async () => {
        setIsLoading(true)
        await axios.get(`/api/insights-contacts?role=${searchRole}`, config)
            .then(res => {
                const contacts = res.data
                setContacts(contacts)
                setIsLoading(false)
            })
    }

    const createFilterArray = (contactName, contactRole) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].contact_name = contactName
        filters[0].contact_role = contactRole

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    useEffect(() => {
        createFilterArray(searchName, searchRole)
    }, [searchName, searchRole])


    const handleOpen = () => {
        setOpen(true)
    }

    const handleClearRole = () => {
        setSearchRole('')
    }


    const handleClose = () => {
        setOpen(!open)
    }

    const createContact = async () => {
        setIsLoading(true)
        const formData = {}

        formData['name'] = name
        formData['email'] = email
        formData['phone'] = phone
        formData['role'] = role
        formData['company_id'] = companyId
        formData['site_id'] = siteId

        await axios.post(`/api/insights-contacts`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            setName('')
            setEmail('')
            setPhone('')
            setRole(9)
            setIsLoading(false)
            handleClose()
        }).catch(({ response }) => {
            if (response.status === 422) {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            }
            setIsLoading(false)
        })
    }

    const columns = [
        {
            field: 'id',
            headerName: t('id'),
            flex: 0.5
        },
        {
            field: 'name',
            headerName: t('name'),
            flex: 1
        },
        {
            field: 'phone',
            headerName: t('phone'),
            flex: 1
        },
        {
            field: 'email',
            headerName: t('email'),
            flex: 1
        },
        {
            field: 'role_name',
            headerName: t('role'),
            flex: 1
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => <ContactsActions params={params} config={config} setIsLoading={setIsLoading} />
        }
    ]

    const filtered = contacts?.filter(data => {
        if (searchName === null)
            return data
        if (data?.name?.toLocaleLowerCase().includes(searchName?.toLocaleLowerCase()))
            return data
    })


    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout showFooter={!open}>
                <div className='p-5 pt-0'>
                    <div className='pb-5 bg-white mb-2 rounded-md'>
                        <div className='flex justify-between items-center p-5 border-b'>
                            <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('contacts_list')}</p>
                            <Tooltip disableInteractive title={t('create_contact')} placement='bottom'>
                                <div>
                                    <AddButton onClick={handleOpen}><i className="fa-solid fa-plus"></i></AddButton>
                                </div>
                            </Tooltip>
                        </div>
                        <div className='flex justify-between items-end w-full pb-5 border-b'>
                            <div className='px-5 pr-0 pt-5 w-full'>
                                <div className='flex justify-between items-center search'>
                                    <input type="text" value={searchName} placeholder={t('search_by_name')} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSearchName(e.target.value)} />
                                    <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                </div>
                            </div>
                            <div className='px-5 pt-5 w-full'>
                                <FormControl sx={{ width: 'inherit' }}>
                                    <InputLabel
                                        id="demo-simple-select-standard-label">{t('search_by_role')}</InputLabel>
                                    <Select
                                        value={searchRole}
                                        onChange={e => setSearchRole(e.target.value)}
                                        sx={{
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                            ".MuiSelect-iconOutlined": { display: searchRole ? 'none !important' : '' },
                                            "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                        }}
                                        endAdornment={searchRole ? (<IconButton
                                            sx={{ visibility: searchRole ? "visible" : "hidden", padding: '0' }}
                                            onClick={handleClearRole}><ClearIcon /></IconButton>) : false}
                                    >
                                        <MenuItem value="9">{t('account_manager')}</MenuItem>
                                        <MenuItem value="10">{t('accounting_contact')}</MenuItem>
                                        <MenuItem value="11">{t('customer_service_contact')}</MenuItem>
                                        <MenuItem value="12">{t('operations_manager_contact')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='pt-3'>
                            <TableSharedCustom items={filtered} columns={columns} columnVisibilityModel={model} setModel={setModel} view='contacts' />
                        </div>
                    </div>

                </div>
            </AppLayout>

            <Modal open={open} onClose={handleClose}>
                <Box sx={{...style, overflowX: 'hidden'}}>
                    <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                        <div className='flex items-center justify-between w-full leading-none'>
                            <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t('create_contact')}</p>
                            <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                                <button onClick={handleClose}><i className="fa-solid fa-plus"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='w-full bg-white h-auto min-h-full'>
                        <div className='bg-white p-6 w-full h-fit'>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('name')} *</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={name} onChange={(e) => { setName(e.target.value) }} />
                                </div>
                            </div>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('phone')} *</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                                </div>
                            </div>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('email')} *</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                </div>
                            </div>

                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('role')} *</p>
                                <div className='w-full'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Select value={role} onChange={(e) => { setRole(e.target.value) }}
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}>
                                            <MenuItem value="9">{t('account_manager')}</MenuItem>
                                            <MenuItem value="10">{t('accounting_contact')}</MenuItem>
                                            <MenuItem value="11">{t('customer_service_contact')}</MenuItem>
                                            <MenuItem value="12">{t('operations_manager_contact')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                        </div>
                        <div className='w-full flex justify-end px-6'>
                            <button type="button" onClick={createContact} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#DBA34B] hover:bg-[#CF7300] disabled:bg-[#B8B7BC]'>
                                <span className='pr-2'><i className="fa-solid fa-plus"></i></span> {t('create')}
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default Contacts

const ContactsActions = (params) => {

    const { t } = useTranslation()

    const deleteItems = async (id) => {
        const isConfirm = await Swal.fire({
            title: t('delete_contact') + params.params.row.name + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        await axios.delete(`/api/insights-contacts/${id}`, params.config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
        })
    }


    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('edit')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <Link to={`${params.params.row.id}`}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-pencil"></i></span></Link>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteItems(params.params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}

