import React from 'react'

const InfoData = ({ title, name, phone, email }) => {
    return (
        <>
            <div className='w-full'>
                <div className='p-3'>
                    <div className='flex  roboto gap-2 mb-3'><p className='text-[#808080]'>{title} - </p><p>{name}</p></div>
                    <div className='flex  roboro gap-2 mb-3'><p><i className="fa-solid fa-phone"></i> - </p><p>{phone}</p></div>
                    <div className='flex  roboto gap-2 mb-3'><p><i className="fa-solid fa-envelope"></i> - </p><p>{email}</p></div>
                </div>
                <hr className='mb-5' />
            </div>

        </>
    )
}

export default InfoData
