import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import AppLayout from '../../components/Layouts/AppLayout'
import axios from '../../lib/axios'
import AssignedShipments from '../../components/driver/AssignedShipments'
import AssignedCollections from '../../components/driver/AssignedCollections'
import update from 'immutability-helper';
import Loading from '../../components/Loading'
import { includes } from 'lodash'

const DriverDashboard = ({
    userId,
    siteId,
    config,
    pusher,
    choosesite,
    setOpenLatestMessage,
}) => {
    const { t } = useTranslation()

    const [shipments, setShipments] = useState([])
    const [collections, setCollections] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        const shipmentChannelDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${siteId}`)
        const shipmentChannelUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${siteId}`)

        const collectionChannelDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${siteId}`)
        const collectionChannelUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${siteId}`)

        // Bind shipment channel pushes
        shipmentChannelDelete.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${siteId}`, data => {
            setShipments((prev) => {
                const deletedShipment = prev.find((i) => i.id === data.id)
                const shipmentsUpdated = deletedShipment ? prev.filter((i) => i.id !== deletedShipment.id) : prev
                return shipmentsUpdated
            })
        })

        shipmentChannelUpdate.bind(`${localStorage.getItem('client_id')}-salesorder-updated-event-site-${siteId}`, data => {
            if (Number(data.current_driver) == Number(userId)) {
                getShipment(data.id, 'updated')
            } else {
                setShipments((prev) => {
                    const deletedShipment = prev.find((i) => i.id === data.id)
                    const no = deletedShipment ? deletedShipment.so_number : data.id

                    if (
                        data.retrieved
                        && deletedShipment
                        && Number(data.id) == Number(deletedShipment.id)
                    ) {
                        sessionStorage.setItem('available_loads_latest_msg', t('x_no_has_been_retrieved', {
                            x: t('sales_order'),
                            no: no
                        }))
                        setOpenLatestMessage(true)
                    }

                    const shipmentsUpdated = deletedShipment ? prev.filter((i) => i.id !== deletedShipment.id) : prev
                    return shipmentsUpdated
                })
            }
        })

        // Bind collection channel pushes

        collectionChannelDelete.bind(`${localStorage.getItem('client_id')}-purchaseorder-deleted-event-site-${siteId}`, data => {
            setCollections((prev) => {
                const deletedCollection = prev.find((i) => i.id === data.id)
                const collectionsUpdated = deletedCollection ? prev.filter((i) => i.id !== deletedCollection.id) : prev
                return collectionsUpdated
            })
        })

        collectionChannelUpdate.bind(`${localStorage.getItem('client_id')}-purchaseorder-updated-event-site-${siteId}`, data => {
            if (Number(data.current_driver) == Number(userId)) {
                getCollection(data.id, 'updated')
            } else {
                setCollections((prev) => {
                    const deletedCollection = prev.find((i) => i.id === data.id)
                    const no = deletedCollection ? deletedCollection.po_number : data.id

                    if (
                        data.retrieved
                        && deletedCollection
                        && Number(data.id) == Number(deletedCollection.id)
                    ) {
                        sessionStorage.setItem('available_loads_latest_msg', t('x_no_has_been_retrieved', {
                            x: t('purchase_order'),
                            no: no
                        }))
                        setOpenLatestMessage(true)
                    }

                    const collectionsUpdated = deletedCollection ? prev.filter((i) => i.id !== deletedCollection.id) : prev
                    return collectionsUpdated
                })
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${siteId}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${siteId}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${siteId}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${siteId}`)
        })
    }, [])

    const getShipments = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/driver/${userId}/list-shipments`, config)
            .then(res => {
                const data = res.data
                setShipments(data)
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getShipment = async (id, event) => {
        setIsLoading(true)
        await axios
            .get(`/api/sales-orders/${id}`, config)
            .then(res => {
                const shipment = res.data
                if (event === 'updated' && Number(shipment.driver_id) == Number(userId)) {
                    if (!includes(["released", "assigned_to_fork", "loading", "loaded", "assigned_to_driver", "in_transit"], shipment.so_status)) {
                        setShipments((prev) => {
                            const updatedShipment = prev.find((i) => i.id === id)
                            if (updatedShipment !== undefined) {
                                const shipmentsUpdated = prev.filter((i) => i.id !== updatedShipment.id)
                                return shipmentsUpdated
                            }
                            return prev
                        })
                    } else {
                        if (includes(["released", "assigned_to_fork", "loading", "loaded", "assigned_to_driver", "in_transit"], shipment.so_status)) {
                            setShipments((prev) => {
                                const shipmentIndex = prev.findIndex((i) => i.id === id)

                                if (shipmentIndex > -1) {
                                    return update(prev, {
                                        [shipmentIndex]: { $set: shipment }
                                    })
                                } else {
                                    return update(prev,
                                        {
                                            $push: [shipment]
                                        }
                                    )
                                }
                            })
                        } else {
                            setShipments((prev) => {
                                const shipmentIndex = prev.findIndex((i) => i.id === id)

                                return update(prev, {
                                    [shipmentIndex]: { $set: shipment }
                                })
                            })
                        }
                    }
                }
            })
            .catch(({ response }) => {
                // This accounts for cases when a Dispatcher reassigns a load to another Driver: checks if the reassigned load is shown in the state and filters it out if so
                setShipments((prev) => {
                    const updatedShipment = prev.find((i) => i.id === id)
                    if (updatedShipment !== undefined) {
                        const shipmentsUpdated = prev.filter((i) => i.id !== updatedShipment.id)
                        return shipmentsUpdated
                    }
                    return prev
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getCollections = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/driver/${userId}/list-collections`, config)
            .then(res => {
                const data = res.data
                setCollections(data)
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getCollection = async (id, event) => {
        setIsLoading(true)
        await axios
            .get(`/api/purchase-orders/${id}`, config)
            .then(res => {
                const collection = res.data
                if (event === 'updated' && Number(collection.driver_id) == Number(userId)) {
                    if (collection.po_status !== "assigned_to_driver" && collection.po_status !== "in_transit") {
                        setCollections((prev) => {
                            const updatedCollection = prev.find((i) => i.id === id)
                            if (updatedCollection !== undefined) {
                                const collectionsUpdated = prev.filter((i) => i.id !== updatedCollection.id)
                                return collectionsUpdated
                            }
                            return prev
                        })
                    } else {
                        if (collection.po_status === "assigned_to_driver" || collection.po_status === "in_transit") {

                            setCollections((prev) => {

                                const collectionIndex = prev.findIndex((i) => i.id === id)

                                if (collectionIndex > -1) {
                                    return update(prev, {
                                        [collectionIndex]: { $set: collection }
                                    })
                                } else {
                                    return update(prev,
                                        {
                                            $push: [collection]
                                        }
                                    )
                                }
                            })
                        } else {
                            setCollections((prev) => {
                                const collectionIndex = prev.findIndex((i) => i.id === id)

                                return update(prev, {
                                    [collectionIndex]: { $set: collection }
                                })
                            })
                        }
                    }
                }
            })
            .catch(({ response }) => {
                // This accounts for cases when a Dispatcher reassigns a load to another Driver: checks if the reassigned load is shown in the state and filters it out if so
                setCollections((prev) => {
                    const updatedCollection = prev.find((i) => i.id === id)
                    if (updatedCollection !== undefined) {
                        const collectionsUpdated = prev.filter((i) => i.id !== updatedCollection.id)
                        return collectionsUpdated
                    }
                    return prev
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (choosesite) {
            getShipments()
            getCollections()
        }
    }, [choosesite])

    return (
        <>
            {isLoading ? <Loading /> : null}
            <AppLayout>
                <div className='flex flex-col justify-between'>
                    <div className='p-5 w-full'>
                        <div className='bg-white mb-2 rounded-md'>
                            <div className='p-5 flex justify-between items-center border-b'>
                                <div className='flex justify-start gap-4 items-center'>
                                    <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('shipments')}</p>
                                </div>
                            </div>
                            <div className='pt-3'>
                                <AssignedShipments shipments={shipments ?? []} />
                            </div>
                        </div>

                    </div>

                    <div className='p-5 w-full'>
                        <div className='bg-white mb-2 rounded-md'>
                            <div className='p-5 flex justify-between items-center border-b'>
                                <div className='flex justify-start gap-4 items-center'>
                                    <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('collections')}</p>
                                </div>
                            </div>
                            <div className='pt-3'>
                                <AssignedCollections collections={collections ?? []} />
                            </div>
                        </div>

                    </div>
                </div>
            </AppLayout>
        </>
    )
}

export default DriverDashboard
