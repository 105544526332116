import React, { useEffect, useState } from 'react'

import { FormControl, InputLabel, Select, Box, Chip, IconButton, MenuItem, ListItemIcon, ListItemText, Checkbox, Tooltip, LinearProgress, Stack, Typography } from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear"
import Swal from 'sweetalert2'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { has, includes, round } from 'lodash'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import AppLayout from '../../../../components/Layouts/AppLayout'
import Loading from '../../../../components/Loading'
import { useStateContext } from '../../../../context/ContextProvider'
import { useAuth } from '../../../../hooks/auth'
import ServerTable from '../../../../components/ServerTable'
import { handleAxiosError, isValid } from '../../../../helpers/helper'
import axios from '../../../../lib/axios'

const ProductionOrders = () => {
    const { t } = useTranslation()
    const { choosesite, setChoosesite, config, pusher } = useStateContext()
    const { user } = useAuth({ middleware: 'guest '})
    const [searchParams, setSearchParams] = useSearchParams()

    const [productionOrders, setProductionOrders] = useState([])
    const [dueDates, setDueDates] = useState([])
    const [statuses, setStatuses] = useState([])

    const [isLoading, setIsLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [random, setRandom] = useState(null)

    const [openStatuses, setOpenStatuses] = useState(false)

    useEffect(() => {
        // Unsubscribe on unmount
        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-created-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${localStorage.getItem('site')}`)
        })
    }, [])

    useEffect(() => {
        if (isValid(choosesite)) {
            const channelProductionOrderDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${choosesite}`)
            const channelProductionOrderCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-created-site-${choosesite}`)
            const channelProductionOrderUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${choosesite}`)

            channelProductionOrderDelete.bind(`${localStorage.getItem('client_id')}-productionorder-deleted-event-site-${choosesite}`, data => {
                if(Number(user?.id) == Number(data.production_supervisor)) {
                    setRandom(Math.random())
                }
            })

            channelProductionOrderCreate.bind(`${localStorage.getItem('client_id')}-productionorder-created-event-site-${choosesite}`, data => {
                if(Number(user?.id) == Number(data.production_supervisor)) {
                    setRandom(Math.random())
                }
            })

            channelProductionOrderUpdate.bind(`${localStorage.getItem('client_id')}-productionorder-updated-event-site-${choosesite}`, data => {
                setRandom(Math.random())
            })
        }

        return (() => {
            if(isValid(choosesite)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-created-site-${choosesite}`)
            }
        })
    }, [choosesite])

    /* FILTERS */
    const [searchNo, setSearchNo] = useState(() => {
        if(localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'))
            return filters[0]?.production_orders_no || ""
        } else {
            return ""
        }
    })
    const [searchStatuses, setSearchStatuses] = useState(() => {
        if(!searchParams.has('get') && localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'))
            return filters[0]?.production_orders_statuses || []
        } else {
            return []
        }
    })
    const [searchStatusIds, setSearchStatusIds] = useState(() => {
        if(!searchParams.has('get') && localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'))
            return filters[0]?.production_orders_status_ids || []
        } else {
            return []
        }
    })
    const [searchDueDate, setSearchDueDate] = useState(() => {
        if(localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'))
            return filters[0]?.production_orders_due_date || ""
        } else {
            return ""
        }
    })

    const [debouncedSearchNo] = useDebounce(searchNo, 500)

    const [model, setModel] = useState({
        no: true,
        description: true,
        item: true,
        qty_to_produce: true,
        status: true,
        progress: true,
        due_date: true,
        actions: true
    })

    const hasSelectedAllStatuses = statuses.length > 0 && searchStatuses.length === statuses.length;

    useEffect(() => {
        getTableView()
        getProductionOrderStatuses()
    }, [])

    useEffect(() => {
        if (isValid(choosesite)) {
            getProductionOrders()
        }
        createFilterArray(debouncedSearchNo, searchStatuses, searchStatusIds, searchDueDate)
    }, [choosesite, searchDueDate, searchStatuses, debouncedSearchNo, page, pageSize, random])

    const createFilterArray = (no, statuses, statusIds, dueDate) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].production_orders_no = no
        filters[0].production_orders_statuses = statuses
        filters[0].production_orders_status_ids = statusIds
        filters[0].production_orders_due_date = dueDate

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    const getTableView = async () => {
        setIsLoading(true)
        await axios.get(`/api/get-table-view?view_name=production_order_table&user_id=${user?.id}`, config)
            .then(res => {
                const model = res.data
                if (Object.keys(model?.success?.data).length > 0) {
                    setModel(model.success.data)
                }
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getProductionOrders = async () => {
        setIsLoading(true)

        let date
        if(searchDueDate == '') {
            date = ''
        } else {
            date = dayjs(searchDueDate).format('YYYY-MM-DD')
        }

        await axios.get(`/api/list-orders?order_type=production&site_id=${choosesite}&no=${searchNo}&status_id=${searchStatusIds}&due_date=${date}&production_supervisor_id=${user?.id}&page=${page + 1}&page_size=${pageSize}`, config)
            .then(res => {
                const data = res.data?.data
                const t = res.data?.total
                setProductionOrders(data)
                getDueDates(data)
                setTotal(t)
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getProductionOrderStatuses = async () => {
        await axios.get(`api/production-order-statuses`, config)
            .then(res => {
                const data = res.data
                setStatuses(data)

                if(data.length > 0 && searchParams.has('get')) {
                    let objects = []
                    let ids = []

                    switch(searchParams.get('get')) {
                        case 'pending':
                            ids = [1]
                            objects = data.filter((status) => includes(ids, status.id))
                            break
                        default:
                            ids = []
                            objects = []
                    }

                    setSearchStatusIds(ids)
                    setSearchStatuses(objects)
                }
            })
    }

    const getDueDates = (productionOrders) => {
        let dates = [];

        productionOrders.forEach(order => {
            let newDate = order.due_date;

            let newDateExists = dates.includes(newDate);

            if (!newDateExists) {
                dates.push(newDate);
            }
        });

        dates.sort();

        setDueDates(dates);
    }

    const handleClearStatuses = () => {
        searchParams.delete('get')
        setSearchParams(searchParams)
        setSearchStatuses([])
        setSearchStatusIds([])
    }

    const handleClearDueDate = () => {
        setSearchDueDate('')

        getDueDates(productionOrders)
    }

    const handleChangedStatuses = (event) => {
        const { target: { value } } = event

        let duplicateRemoved = [];

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
                duplicateRemoved.push(item);
            }
        })

        let ids = []

        value.forEach((item) => {
            if (ids.findIndex((o) => o.id === item.id) >= 0) {
                ids = ids.filter((x) => x.id === item.id);
            } else {
                ids.push(item.id);
            }
        })


        if (value[value.length - 1] === "all") {
            setSearchStatuses(searchStatuses.length === statuses.length ? [] : statuses)
            setSearchStatusIds(searchStatuses.length === statuses.length ? [] : statuses.map(i => i.id))
            return;
        }

        searchParams.delete('get')
        setSearchParams(searchParams)
        setSearchStatuses(duplicateRemoved)
        setSearchStatusIds(ids)
    }

    const handleDeleteSearchStatus = (e, id) => {
        const shouldDelete = searchStatuses.find((x) => x.id === id)
        let statusIds = []
        if (shouldDelete) {
            const filtered = searchStatuses.filter((x) => x.id !== shouldDelete.id)
            searchParams.delete('get')
            setSearchParams(searchParams)
            if (filtered.length > 0) {
                filtered.forEach((stl) => {
                    if (stl.hasOwnProperty('id')) {
                        searchStatuses.push(stl.id)
                    }
                })
                statusIds = filtered.map((filter) => filter.id)
                setSearchStatuses(filtered)
                setSearchStatusIds(statusIds)
            } else {
                setSearchStatuses([])
                setSearchStatusIds([])
                setOpenStatuses(false)
            }
        }
    }

    const columns = [
        {
            field: 'no',
            headerName: t('order_no'),
            flex: 0.7,
            minWidth: 140,
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.4,
            minWidth: 210
        },
        {
            field: 'item',
            headerName: t('item'),
            valueGetter: (value) => {
                return value?.value.item_no ?? '' ?? ''
            },
            flex: 0.7,
            minWidth: 140
        },
        {
            field: 'qty_to_produce',
            headerName: t('qty_to_produce'),
            flex: 0.7,
            minWidth: 140
        },
        {
            field: 'status',
            headerName: t('status'),
            valueGetter: (value) => {
                return value?.value.name ?? '' ?? ''
            },
            flex: 0.7,
            minWidth: 140
        },
        {
            field: 'progress',
            headerName: t('progress'),
            valueGetter: (value) => {
                return round((Number(value.row?.qty_produced) / Number(value.row?.qty_to_produce)) * 100, 2)
            },
            renderCell: (params) => {
                return (
                    <Stack
                        direction='row'
                        spacing={1}
                        width='100%'
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <LinearProgress
                            variant='determinate'
                            value={params.value > 100 ? 100 : params.value}
                            sx={{
                                width: '75%',
                                height: '12px',
                                borderRadius: '6px',
                                backgroundColor: "#EDEDED",
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: "#71DD37"
                                }
                            }}
                        />
                        <Typography fontSize='0.875rem' textAlign='right'>
                            {params.value}%
                        </Typography>
                    </Stack>
                )
            },
            flex: 1,
            minWidth: 200
        },
        {
            field: 'due_date',
            headerName: t('due_date'),
            flex: 0.7,
            minWidth: 140
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => <ProductionOrderActions params={params} setIsLoading={setIsLoading} config={config} getProductionOrders={getProductionOrders} />,
            minWidth: 100
        }
    ]

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <div className='flex justify-between'>
                    <div className='p-5 pt-0 w-full'>
                        <div className='pb-5 bg-white mb-2 rounded-md'>
                            <div className='border-b pb-5'>
                                <div className='flex justify-between items-end w-full pt-5 px-5 gap-5'>
                                    <div className='w-full'>
                                        <div className='flex justify-between items-center search'>
                                            <input
                                                type="text"
                                                placeholder={t('search_by_production_order_no')}
                                                value={searchNo}
                                                className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place'
                                                onChange={(e) => setSearchNo(e.target.value)}
                                            />
                                            <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                        </div>
                                    </div>

                                    <div className='w-full'>
                                        <FormControl sx={{ width: 'inherit' }}>
                                            <InputLabel id="status-search-label-id">{t('search_by_status')}</InputLabel>
                                            <Select
                                                multiple
                                                value={searchStatuses}
                                                open={openStatuses}
                                                onOpen={() => setOpenStatuses(true)}
                                                onClose={() => setOpenStatuses(false)}
                                                onChange={handleChangedStatuses}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((x) => (
                                                            <Chip
                                                                key={`status_option-${x.id}`}
                                                                label={x.name}
                                                                onMouseDown={(e) => e.stopPropagation()}
                                                                onDelete={(e) => handleDeleteSearchStatus(e, x.id)}
                                                            />
                                                        ))}
                                                    </Box>
                                                )}
                                                sx={{
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: 'none !important'
                                                    },
                                                    background: '#F5F5F5',
                                                    borderRadius: '6px',
                                                    ".MuiSelect-iconOutlined": {
                                                        display: (searchStatuses.length > 0) ? 'none !important' : ''
                                                    },
                                                    "&.Mui-focused .MuiIconButton-root": {
                                                        color: 'rgba(0,0,0,.42)'
                                                    }
                                                }}
                                                endAdornment={
                                                    searchStatuses ? (
                                                        <IconButton sx={{ visibility: (searchStatuses.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearStatuses}><ClearIcon /></IconButton>
                                                    ) : false
                                                }
                                            >
                                                {
                                                    statuses?.length > 0 ?
                                                        <MenuItem value="all">
                                                            <ListItemIcon>
                                                                <Checkbox checked={hasSelectedAllStatuses} indeterminate={searchStatuses.length > 0 && searchStatuses.length < statuses.length} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={t('select_all')} />
                                                        </MenuItem>
                                                        :
                                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                                }
                                                {
                                                    statuses.map((s) =>
                                                        <MenuItem value={s} key={`status-${s.id}`}>
                                                            <Checkbox checked={searchStatuses?.findIndex((i) => i.id === s.id) >= 0} />
                                                            <ListItemText primary={s.name} />
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className='w-full'>
                                        <FormControl sx={{ width: 'inherit' }}>
                                            <InputLabel id="status-due-date-label-id">{t('search_by_due_date')}</InputLabel>
                                            <Select
                                                value={searchDueDate}
                                                onChange={e => setSearchDueDate(e.target.value)}
                                                label="Search Past Or Present Date"
                                                sx={{
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: 'none !important'
                                                    },
                                                    background: '#F5F5F5',
                                                    borderRadius: '6px',
                                                    ".MuiSelect-iconOutlined": {
                                                        display: searchDueDate ? 'none !important' : ''
                                                    },
                                                    "&.Mui-focused .MuiIconButton-root": {
                                                        color: 'rgba(0,0,0,.42)'
                                                    }
                                                }}
                                                endAdornment={
                                                    searchDueDate ? (
                                                        <IconButton sx={{ visibility: searchDueDate ? "visible" : "hidden", padding: '0' }} onClick={handleClearDueDate}><ClearIcon /></IconButton>
                                                    ) : false
                                                }
                                            >
                                                {
                                                    dueDates.length < 1 ?
                                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                        :
                                                        dueDates.map((date, index) => (
                                                            <MenuItem key={`duedate-${index}`} value={date}>{date}</MenuItem>
                                                        ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-3'>
                                <ServerTable columns={columns} items={productionOrders} total={total} setPage={setPage} setPageSize={setPageSize} page={page} pageSize={pageSize} columnVisibilityModel={model} setModel={setModel} view='production_order' />
                            </div>
                        </div>
                    </div>
                </div>
            </AppLayout>
        </>
    )
}

export default ProductionOrders

const ProductionOrderActions = (params) => {
    const { t } = useTranslation()
    const [openUpdate, setOpenUpdate] = useState(false)
    const navigate = useNavigate()

    const handleOpenUpdate = () => {
        setOpenUpdate(!openUpdate)
        localStorage.setItem('prevStatusProduction', params.params.row.status?.id || params.params.row.status)
    }

    const handleStartProductionOrder = async () => {
        await axios.put(`/api/production-orders/${params.params.row.id}/start`, params.config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })

                navigate(`/production-order/${params.params.row.id}/process`, {
                    replace: true
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
    }

    return (
        <>
            <div className='flex justify-between'>
                {includes([1], params.params.row.status?.id || params.params.row.status) && (
                    <Tooltip disableInteractive title={t('start_with_production_order')} placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <button>
                                <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={handleStartProductionOrder}><i className="fa-solid fa-play"></i></span>
                            </button>
                        </div>
                    </Tooltip>
                )}

                {includes([2], params.params.row.status?.id || params.params.row.status) && (
                    <Tooltip disableInteractive title={t('update')} placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <Link to={`/production-order/${params.params.row.id}/process`} state={{ route: '/production-order' }}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={handleOpenUpdate}><i className="fa-solid fa-pencil"></i></span></Link>
                        </div>
                    </Tooltip>
                )}
            </div>
        </>
    )
}
