import * as React from 'react'
import { useState, useEffect } from 'react'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useTranslation } from "react-i18next"
import TablePagination from "@mui/material/TablePagination"
import { t } from 'i18next'
import dayjs from 'dayjs'
import CircularProgress from '@mui/material/CircularProgress'
import { visuallyHidden } from '@mui/utils'

import { getComparator, stableSort } from '../../helpers/DailyBoardTableHelpers'

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false)

    return (
        <React.Fragment >
            <TableRow sx={{ '& > *': { borderBottom: 'unset !important' } }}>
                <TableCell sx={{ padding: '8px 16px' }}>{row.status}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{<ShowDate date={row.requested_shipment_date} />}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.customer_po_no}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.reject_reason}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.so_number}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell style={{ padding: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '20%' }}>{t('item_no')}</TableCell>
                                        <TableCell sx={{ width: '40%' }}>{t('description')}</TableCell>
                                        <TableCell sx={{ width: '13%' }}>{t('quantity_ordered')}</TableCell>
                                        <TableCell sx={{ width: '13%' }}>{t('quantity_to_ship')}</TableCell>
                                        <TableCell sx={{ width: '13%' }}>{t('quantity_shipped')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.sales_order_items.map((historyRow) => (
                                        <TableRow key={historyRow.id}>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.item_no}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.description}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_ordered}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_to_ship}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_shipped}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </React.Fragment>
    );
}

const SortableTableHeaderRow = (props) => {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {/* Status cell */}
                <TableCell
                    sortDirection={orderBy === 'status' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'status'}
                        direction={orderBy === 'status' ? order : 'asc'}
                        onClick={createSortHandler('status')}>
                        {t('status')}
                        {orderBy === 'status' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                {/* Requested Delivery Date cell */}
                <TableCell
                    sortDirection={orderBy === 'requested_shipment_date' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'requested_shipment_date'}
                        direction={orderBy === 'requested_shipment_date' ? order : 'asc'}
                        onClick={createSortHandler('requested_shipment_date')}>
                        {t('requested_delivery_date')}
                        {orderBy === 'requested_shipment_date' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                {/* customer_po_no cell */}
                <TableCell
                    sortDirection={orderBy === 'customer_po_no' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'customer_po_no'}
                        direction={orderBy === 'customer_po_no' ? order : 'asc'}
                        onClick={createSortHandler('customer_po_no')}>
                        {t('customer_po_no')}
                        {orderBy === 'customer_po_no' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                {/* reject_reason cell */}
                <TableCell
                    sortDirection={orderBy === 'reject_reason' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'reject_reason'}
                        direction={orderBy === 'reject_reason' ? order : 'asc'}
                        onClick={createSortHandler('reject_reason')}>
                        {t('reject_reason')}
                        {orderBy === 'reject_reason' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                {/* Sales Order No. cell */}
                <TableCell
                    sortDirection={orderBy === 'so_number' ? order : false}>
                    <TableSortLabel sx={{ color: '#88909C !important', fontWeight: '400' }}
                        active={orderBy === 'so_number'}
                        direction={orderBy === 'so_number' ? order : 'asc'}
                        onClick={createSortHandler('so_number')}>
                        {t('sales_order_no')}
                        {orderBy === 'so_number' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>



                <TableCell />
            </TableRow>
        </TableHead>
    )
}

export default function CollapsibleTable({ items }) {

    const { t } = useTranslation()

    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(10);
    const [loading, setLoading] = useState(true)
    const [shipments, setShipments] = useState([])
    const [orderBy, setOrderBy] = useState('created_at')
    const [order, setOrder] = useState('desc')

    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }

    const handleRequestSort = (event, property) => {
        if (order === 'desc' && orderBy === property) {
            setOrderBy('created_at')
        } else {
            const isAsc = orderBy === property && order === 'asc'
            setOrder(isAsc ? 'desc' : 'asc')
            setOrderBy(property)
        }
    }

    const visibleShipments = React.useMemo(
        () =>
            stableSort(items, getComparator(order, orderBy)).slice(
                pg * rpg,
                pg * rpg + rpg,
            ),
        [items, order, orderBy, pg, rpg],
    )

    return (
        <>

            <div style={{ width: '100%', mb: 2, border: 'none' }}>
                <TableContainer>
                    <Table aria-label="collapsible table" stickyHeader>
                        <colgroup>
                            <col width="20%" />
                            <col width="20%" />
                            <col width="20%" />
                            <col width="20%" />
                            <col width="20%" />
                            <col />
                        </colgroup>
                        <SortableTableHeaderRow
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {items.length <= 0 ? <TableRow><TableCell colSpan={6} sx={{ textAlign: 'center' }}>{t('no_data')}</TableCell></TableRow> : null}
                            {visibleShipments?.map((row) => (
                                <Row key={row.id} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className='table-pagination'
                    labelRowsPerPage={t('rowsPerPage')}
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={items.length}
                    rowsPerPage={rpg}
                    page={pg}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>
    );
}


export const ShowDate = ({ date }) => {

    const date1 = new Date(dayjs(date).format("YYYY-MM-DD"))
    const date2 = new Date(dayjs().format("YYYY-MM-DD"))

    return (
        <div>
            {
                date1 < date2 ? <p style={{ color: 'red', border: '1px solid red', padding: '5px', borderRadius: '15px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("YYYY-MM-DD")}</p> :
                    date1 == date2 ? <p style={{ color: 'blue', border: '1px solid blue', padding: '5px', borderRadius: '15px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("YYYY-MM-DD")}</p> :
                        date1 > date2 ? <p style={{ color: 'green', border: '1px solid green', padding: '5px', borderRadius: '15px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("YYYY-MM-DD")}</p> :
                            <p style={{ color: 'blue', border: '1px solid blue', padding: '5px', borderRadius: '15px', lineHeight: '1', width: 'fit-content' }}>{dayjs(date).format("YYYY-MM-DD")}</p>
            }
        </div>
    )
}
