import React, { useEffect, useState } from 'react'

import { Box, FormControl, MenuItem, Modal, Select, TextField, ListItemText, Checkbox, Chip, ListItemIcon } from '@mui/material'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import update from 'immutability-helper'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"

import style from '../../styles/style'
import Swal from "sweetalert2";
import axios from '../../lib/axios';
import { useStateContext } from "../../context/ContextProvider";
import { useAuth } from '../../hooks/auth';
import Loading from '../Loading';
import { createPortal } from 'react-dom'
import { isObject, toNumber } from 'lodash';
import { handleAxiosError, createRegexFromString } from '../../helpers/helper';

const RequestShipments = ({ open, handleClose, userId }) => {

    const { config } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })

    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation()
    const [shipToLocations, setShipToLocations] = useState([])
    const [items, setItems] = useState([])

    const [customer, setCustomer] = useState('')
    const [customerId, setCustomerId] = useState('')
    const [shipToCode, setShipToCode] = useState('')
    const [customerPO, setCustomerPO] = useState('')
    const [requestedDeliveryDate, setRequestedDeliveryDate] = useState(dayjs())
    const [item, setItem] = useState([])
    const [selectedItems, setSelectedItems] = useState([])
    const [comments, setComments] = useState('')

    const [openItems, setOpenItems] = useState(false)

    const isAllSelectedItem = items.length > 0 && selectedItems.length === items.length

    useEffect(() => {
        if (user?.role === 'customers_user') getInsightsUser()
    }, [])

    useEffect(() => {
        if (shipToCode) getItems()
    }, [shipToCode])

    const clearModal = () => {
        handleClose()
        setRequestedDeliveryDate(dayjs())
        setShipToCode('')
        setCustomerPO('')
        setComments('')
        setItem([])
        setSelectedItems([])
    }

    const getInsightsUser = async () => {
        setIsLoading(true)

        await axios
            .get(`/api/insights-users/${user?.id}`, config)
            .then(res => {
                const user = res.data
                setCustomer(user?.customer_name)
                setCustomerId(user?.customer_id)
                setShipToLocations(user?.ship_to_locations_info)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getItems = async () => {
        setIsLoading(true)

        await axios
            .get(`/api/sales-contract-items?ship_address_id=${shipToCode}`, config)
            .then(res => {
                const data = res.data
                setItems(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleItems = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = [];
        let addItems = [];

        value.forEach((item) => {
            if(isObject(item)) {
                if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                    duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
                } else {
                    duplicateRemoved.push(item);
                }
            }
        })

        value.map(i => {
            if(isObject(i)) {
                const item = items.find(it => it.id == i.id)

                const addItem = {
                    id: item.id,
                    itemId: item.item_id,
                    name: item.item_no,
                    code: item.item_no,
                    qty: 0
                }

                addItems.push(addItem)
            }
        })

        if (value[value.length - 1] === "all") {
            const duplicateAllRemoved = []
            const addAllItems = []

            items.forEach((i) => {
                if (duplicateAllRemoved.findIndex((o) => o.id === i.id) >= 0) {
                    duplicateAllRemoved = duplicateAllRemoved.filter((x) => x.id === i.id);
                } else {
                    duplicateAllRemoved.push(i);
                }

                const item = items.find(it => it.id == i.id)

                const addItem = {
                    id: item.id,
                    itemId: item.item_id,
                    name: item.item_no,
                    code: item.item_no,
                    qty: 0
                }

                addAllItems.push(addItem)
            })

            setItem(selectedItems.length === items.length ? [] : duplicateAllRemoved);
            setSelectedItems(selectedItems.length === items.length ? [] : addAllItems)
            return;
        }

        setItem(duplicateRemoved)
        setSelectedItems(addItems)
    }

    const handleClearItem = () => {
        setItem([])
        setSelectedItems([])
    }

    const handleItemsQty = (e, item) => {
        const start = 0
        const end = 5

        const regex = createRegexFromString(`/^(-?\\d{${start},${end}})$/i`)
        if (
            regex.test(e.target.value)
            && toNumber(e.target.value) > 0
        ) {
            const addItem = {
                id: item.id,
                itemId: item.itemId,
                name: item.name,
                code: item.code,
                qty: e.target.value
            }

            setSelectedItems(prev => {
                const indexFind = prev?.findIndex((i) => i.id === item.id)

                if (indexFind < 0) {
                    const items = [...prev, addItem]
                    return items
                } else {
                    return update(prev,
                        { [indexFind]: { $set: addItem } }
                    )
                }
            })
        }
    }


    const createShipmentRequest = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formData = {}

        formData['customer_id'] = customerId
        formData['ship_to_address_id'] = shipToCode
        formData['po_number'] = customerPO
        formData['requested_shipment_date'] = dayjs(requestedDeliveryDate).format('YYYY-MM-DD HH:mm:ss')
        formData['comments'] = comments
        formData['items'] = selectedItems


        await axios
            .post(`/api/shipment-requests`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                clearModal()
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleDeleteItem = (e, id) => {
        const newSelectedItems = []

        const shouldDelete = item.find((x) => x.id === id)
        if(shouldDelete) {
            const filtered = item.filter((x) => x.id !== shouldDelete.id)
            if(filtered.length > 0) {
                filtered.forEach((i) => {
                    const item = items.find((j) => j.id === i.id)
                    if(item) {
                        newSelectedItems.push({
                            id: item.id,
                            itemId: item.item_id,
                            name: item.item_no,
                            code: item.item_no,
                            qty: 0
                        })
                    }
                })
                setItem(filtered)
                setSelectedItems(newSelectedItems)
            } else {
                setItem([])
                setSelectedItems([])
                setOpenItems(false)
            }
        }
    }

    return (
        <>
            {isLoading ? createPortal(
                <Loading />,
                document.body
            ) : ''}

            <Modal
                open={open}
                onClose={clearModal}
            >
                <Box sx={style}>


                    <div className='flex justify-between items-center p-5 pr-0 pt-0 pb-0' style={{ backgroundColor: '#336195' }}>
                        <div className='flex gap-4 items-baseline'>
                            <div style={{ transform: "rotate(45deg)" }} className="font-semibold text-white">
                                <button onClick={clearModal}><i className="fa-solid fa-plus"></i></button>
                            </div>
                            <p className='text-xl roboto font-semibold text-white'>{t('request_new_delivery')}</p>
                        </div>
                        <button type="button" onClick={createShipmentRequest}
                            className='text-white px-4 py-6 uppercase self-end roboto bg-zinc-900 hover:bg-zinc-700 disabled:bg-zinc-400'>
                            {t('create')}
                        </button>
                    </div>
                    <div className='p-5 flex justify-between gap-3 flex-col lg:flex-row'>
                        <div className='p-2 shadow-md mb-2 rounded-md w-full h-fit'>
                            {/* Requested Delivery Date */}
                            <div className='w-full flex p-3 items-center'>
                                <div className='w-1/2'>{t('requested_delivery_date')}:</div>
                                <div className='w-1/2'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker sx={{ width: '100%' }} format="YYYY-MM-DD" slotProps={{ textField: { variant: 'standard' } }} value={requestedDeliveryDate} onChange={(newValue) => setRequestedDeliveryDate(newValue)} />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            {/* Customer */}
                            <div className='w-full flex p-3 bg-[#e0e0e0] items-center'>
                                <div className='w-1/2'>{t('customer')}: </div>
                                <div className='w-1/2'>
                                    <TextField type="text" disabled variant='standard' className='w-full' name="drop_trailer_no"
                                        value={customer} onChange={(e) => {
                                            setCustomer(e.target.value)
                                        }} />
                                </div>
                            </div>
                            {/* Ship to code */}
                            <div className='w-full flex p-3 items-center'>
                                <div className='w-1/2'>{t('shipToAdd')}:</div>
                                <div className='w-1/2'>
                                    <FormControl variant="standard" sx={{ width: '100%' }}>
                                        <Select value={shipToCode} onChange={(e) => { setShipToCode(e.target.value); setItem([]); setSelectedItems([]) }}>
                                            {shipToLocations?.map((location) => <MenuItem key={`req-stl${location.id}`} value={location.id}>{location.code} - {location.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {/* PO numer */}
                            <div className='w-full flex p-3 bg-[#e0e0e0] items-center'>
                                <div className='w-1/2'>{t('customer_po_no')}:</div>
                                <div className='w-1/2'>
                                    <TextField type="text" variant='standard' className='w-full' name="drop_trailer_no" value={customerPO} onChange={(e) => { setCustomerPO(e.target.value) }} />
                                </div>
                            </div>
                            {/* Address */}
                            <div className='w-full flex p-3 items-center'>
                                <div className='italic'>{t('info_shipments')}</div>
                            </div>
                            {/* Ship to code */}
                            <div className='w-full flex p-3 bg-[#e0e0e0] items-center'>
                                <div className='w-1/2'>{t('select_items')}:</div>
                                <div className='w-1/2'>
                                    <FormControl variant="standard" sx={{ width: '100%' }} focused={openItems}>
                                        <Select
                                            multiple
                                            disabled={!shipToCode}
                                            value={item}
                                            open={openItems}
                                            onOpen={() => setOpenItems(true)}
                                            onClose={() => setOpenItems(false)}
                                            onChange={handleItems}
                                            renderValue={(selected) => (
                                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                    {selected.map((x) => (
                                                        <Chip
                                                            key={`i_option-${x.id}`}
                                                            label={x.item_no}
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                            onDelete={(e) => handleDeleteItem(e, x.id)}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            sx={{ ".MuiSelect-iconStandard": { display: (item.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' } }}
                                            endAdornment={item ? (<IconButton sx={{ visibility: (item.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearItem}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                items?.length <= 0 ?
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                    :
                                                    <MenuItem value="all">
                                                        <ListItemIcon>
                                                            <Checkbox checked={isAllSelectedItem} indeterminate={selectedItems.length > 0 && selectedItems.length < items.length} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('select_all')} />
                                                    </MenuItem>
                                            }

                                            {
                                                items.map((it) =>
                                                    <MenuItem value={it} key={it.id}>
                                                        <Checkbox checked={item?.findIndex((i) => i.id === it.id) >= 0} />
                                                        <ListItemText primary={it.item_no} secondary={it.description} />
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {
                                selectedItems.map((item, index) => (<div className={`w-full flex p-3 ${index % 2 === 0 ? '' : 'bg-[#e0e0e0]'} items-center`} key={item.id}>
                                    <div className='w-1/2'>{t('qty_requested')} ({item.name}):</div>
                                    <div className='w-1/2'>
                                        <TextField
                                            type="text"
                                            variant='standard'
                                            className='w-full'
                                            name={`item-${index}-qty`}
                                            value={item?.qty || ''}
                                            onChange={e => handleItemsQty(e, item)}
                                        />
                                    </div>
                                </div>))
                            }
                        </div>
                    </div>
                    <div className='p-5 pt-1'>
                        <div className='shadow-md rounded-md w-full h-fit'>
                            <div className='w-full flex p-3'>
                                <TextField
                                    label={t('enter_comments')}
                                    multiline
                                    rows={3}
                                    variant="filled"
                                    value={comments}
                                    onChange={e => setComments(e.target.value)}
                                    className='w-full'
                                />
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal >
        </>
    )
}

export default RequestShipments
