import React, { useState, useEffect } from 'react'

import axios from '../../lib/axios'
import { Box, Modal, Tooltip, TextField } from '@mui/material'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'

import AddButton from '../AddButton'
import { useTranslation } from "react-i18next"
import { useStateContext } from '../../context/ContextProvider'
import style from '../../styles/style'
import TableShared from '../TableShared'
import { handleAxiosError } from '../../helpers/helper'



const ShipToAdresses = ({ no, id, setIsLoading }) => {


    const { t } = useTranslation()
    const { config, pusher } = useStateContext()

    /* ship to */
    const [shipTo, setShipTo] = useState([])
    const [open, setOpen] = useState(false)
    const [STname_search, setSTname_search] = useState('')
    const [STcode_search, setSTcode_search] = useState('')

    /* fields */
    const [code, setCode] = useState('')
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [address2, setAddress2] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zip, setZip] = useState('')
    const [contact, setContact] = useState('')
    const [phone, setPhone] = useState('')
    const [deliveryInstructions, setDeliveryInstructions] = useState('')

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '13') {
                if (open) createShipTo()
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [open, code, name, address, address2, city, state, zip, contact, phone, deliveryInstructions]);

    useEffect(() => {
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-deleted`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-created`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-shipaddress-deleted-event`, data => {
            if(Number(id) == Number(data.customer_id)) {
                getShipToAddresses(id)
            }
        })

        channelcreate.bind(`${localStorage.getItem('client_id')}-shipaddress-created-event`, data => {
            if(Number(id) == Number(data.customer_id)) {
                getShipToAddresses(id)
            }
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-shipaddress-updated-event`, data => {
            if(Number(id) == Number(data.customer_id)) {
                getShipToAddresses(id)
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)
        })
    }, [])

    useEffect(() => {
        if (id) getShipToAddresses(id)
    }, [id])


    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setCode('')
        setName('')
        setAddress2('')
        setAddress('')
        setZip('')
        setCity('')
        setState('')
        setPhone('')
        setContact('')
        setDeliveryInstructions('')
    }
    const getShipToAddresses = async (id) => {
        setIsLoading(true)
        await axios
            .get(`/api/ship-addresses?customer_id=${id}`, config)
            .then(res => {
                const data = res.data
                setShipTo(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const createShipTo = async () => {
        setIsLoading(true)
        const formData = {}

        formData['code'] = code
        formData['name'] = name
        formData['address'] = address
        formData['address2'] = address2
        formData['city'] = city
        formData['state'] = state
        formData['zip'] = zip
        formData['contact'] = contact
        formData['phone'] = phone
        formData['customer_id'] = id

        formData['delivery_instructions'] = deliveryInstructions

        await axios
            .post(`/api/ship-addresses`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                handleClose()
            }).catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }


    const items = shipTo.filter(data => {
        if (STname_search === null)
            return data
        if (data.name.toLocaleLowerCase().includes(STname_search.toLocaleLowerCase()))
            return data
    })

    const filtered = items.filter(data => {
        if (STcode_search === null)
            return data
        if (data.code.toLocaleLowerCase().includes(STcode_search.toLocaleLowerCase()))
            return data
    })

    const columns = [
        {
            field: 'code',
            headerName: t('code'),
            flex: 1
        },
        {
            field: 'name',
            headerName: t('name'),
            flex: 1
        },
        {
            field: 'address',
            headerName: t('address'),
            flex: 1
        },
        {
            field: 'city',
            headerName: t('city'),
            flex: 1
        },
        {
            field: 'state',
            headerName: t('state'),
            flex: 1
        },
        {
            field: 'zip',
            headerName: t('zip'),
            flex: 1
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => <ShipToActions params={params} />
        }
    ]


    return (
        <div>
            <div className='pb-5 bg-white mb-2 rounded-md'>
                <div className='flex justify-between items-center p-5 border-b '>
                    <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('ship_to')}</p>
                    <Tooltip disableInteractive title={t('addShipTo')} placement='bottom'>
                        <div>
                            <AddButton onClick={handleOpen}><i className="fa-solid fa-plus"></i></AddButton>
                        </div>
                    </Tooltip>
                </div>
                <div className='flex justify-between items-end w-full border-b pb-5'>
                    <div className='px-5 pt-5 w-full'>
                        <div className='flex justify-between items-center search'>
                            <input type="text" placeholder={t('search_by_STcode')} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSTcode_search(e.target.value)} />
                            <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                        </div>
                    </div>
                    <div className='px-5 pt-5 w-full'>
                        <div className='flex justify-between items-center search'>
                            <input type="text" placeholder={t('search_by_STname')} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSTname_search(e.target.value)} />
                            <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                        </div>
                    </div>

                </div>
                <div className='pt-3'>
                    <TableShared items={filtered} columns={columns} />
                </div>
            </div>


            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                        <div className='flex items-center justify-between w-full leading-none'>
                            <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#242F40] text-[35px] mr-4'>•</span> {t('addShipTo')}</p>
                            <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                                <button onClick={handleClose}><i className="fa-solid fa-plus"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='w-full bg-white h-full'>
                        <div className='bg-white p-6 w-full'>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('code')} *</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={code} onChange={(e) => { setCode(e.target.value) }} />
                                </div>
                            </div>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('name')} *</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={name} onChange={(e) => { setName(e.target.value) }} />
                                </div>
                            </div>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('address')} *</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={address} onChange={(e) => { setAddress(e.target.value) }} />
                                </div>
                            </div>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('address2')}</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={address2} onChange={(e) => { setAddress2(e.target.value) }} />
                                </div>
                            </div>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('city')} *</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={city} onChange={(e) => { setCity(e.target.value) }} />
                                </div>
                            </div>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('state')} *</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={state} onChange={(e) => { setState(e.target.value) }} />
                                </div>
                            </div>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('zip')} *</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={zip} onChange={(e) => { setZip(e.target.value) }} />
                                </div>
                            </div>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('contact')}</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={contact} onChange={(e) => { setContact(e.target.value) }} />
                                </div>
                            </div>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('phone')}</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                                </div>
                            </div>

                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('delivery_instructions')}</p>
                                <TextField
                                    multiline
                                    rows={3}
                                    value={deliveryInstructions}
                                    onChange={e => setDeliveryInstructions(e.target.value)}
                                    sx={{
                                        width: '100%',
                                        background: '#FCFCFC',
                                        '.MuiOutlinedInput-input': {
                                            padding: '9px !important'
                                        },
                                        boxShadow: 'none',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            border: '1px solid #EEEFF2 !important'
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className='w-full flex justify-end px-6 pb-14'>
                            <button type="button" onClick={createShipTo} className=' text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#DBA34B] hover:bg-[#CF7300] disabled:bg-[#B8B7BC]'>
                                <span className='pr-2'><i className="fa-solid fa-plus"></i></span> {t('create')}
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default ShipToAdresses


const ShipToActions = (params) => {

    const { t } = useTranslation();
    const { config } = useStateContext()

    const deleteShipTo = async (id) => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_sa') + params.params.row.code + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        await axios.delete(`/api/ship-addresses/${id}`, params.config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
        })
    }


    return (
        <>
            <div className='flex justify-start gap-1'>
                <Tooltip disableInteractive title={t('edit')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <Link to={`/ship-to-address/${params.params.row.id}`}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-pencil"></i></span></Link>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteShipTo(params.params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
