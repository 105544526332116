import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import dayjs from 'dayjs'
import Swal from 'sweetalert2'

import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import { useAuth } from '../../hooks/auth'
import Loading from '../../components/Loading'
import Period from '../../components/inventory/Period'
import DateRange from '../../components/inventory/DateRange'
import InventoryDocumentNoField from '../../components/inventory/InventoryDocumentNoField'
import CountDate from '../../components/inventory/CountDate'
import Items from '../../components/inventory/Items'
import AddButton from '../../components/AddButton'
import { includes } from 'lodash'
import { deleteZero, handleZero } from '../../helpers/helper'

const EnterCounts = () => {

    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const params = useParams()
    const { state } = useLocation()
    const { user } = useAuth({ middleware: 'guest' })
    const { config, pusher, allSites } = useStateContext()
    const navigate = useNavigate()
    const site = allSites?.find(i => localStorage.getItem('site') == i.id)
    const [count, setCount] = useState(10000)
    const [listOfItems, setListOfItems] = useState([])
    const [listOfUoM, setListOfUoM] = useState([])
    const [disabled, setDisabled] = useState(true)

    const initialItems = [{
        "id": 9999,
        "item_no": '',
        "item_id": '',
        "description": '',
        "unit_price": '',
        "qty": '',
        "unit_of_measure_id": ''
    }]

    /* form */
    const [period, setPeriod] = useState(state ? state?.data?.period : 'week')
    const [start, setStart] = useState(state ? dayjs(state?.data?.start_date) : dayjs())
    const [end, setEnd] = useState(state ? dayjs(state?.data?.end_date) : dayjs().add(7, "day"))
    const [documentNo, setDocumentNo] = useState(state ? state?.data?.inventory_document_no : '')
    const [countDate, setCountDate] = useState(state ? dayjs(state?.data?.count_date) : dayjs())
    const [items, setItems] = useState(state ? state?.data?.inventory_items : initialItems)

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '13') {
                createCountSheetData()
            }
            if (e.keyCode == '27') {
                navigate('/inventory-sheets')
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [period, start, end, countDate, documentNo, items]);



    /* hooks */
    useEffect(() => {
        getItems()
        getUoM()
    }, [])

    useEffect(() => {
        let total = 0
        items.forEach(element => {
            if (element.qty !== '') total += parseInt(element.qty)
        })
        if (total > 0) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [items])

    /* api request */
    const getItems = async () => {
        setIsLoading(true)
        await axios.get(`/api/items?blocked=0&inventory_item=1`, config)
            .then(res => {
                const items = res.data
                const duplicatedIds = state?.data?.inventory_items.map((item) => item.item_id)
                items.forEach(item => {
                    item.disabled = includes(duplicatedIds, item.id) ? true : false
                });
                setListOfItems(items)
                setIsLoading(false)
            })
    }

    const getUoM = async () => {
        setIsLoading(true)
        await axios.get(`/api/units-of-measure`, config)
            .then(res => {
                const uom = res.data
                setListOfUoM(uom)
                setIsLoading(false)
            })
    }

    const createCountSheetData = async () => {
        setIsLoading(true)
        const formData = {}

        formData['period'] = period
        formData['start_date'] = dayjs(start).format('YYYY-MM-DD')
        formData['end_date'] = dayjs(end).format('YYYY-MM-DD')
        formData['count_date'] = dayjs(countDate).format('YYYY-MM-DD')
        formData['inventory_document_no'] = documentNo
        formData['inventory_items'] = items
        formData['user_id'] = user.id
        formData['site_id'] = localStorage.getItem('site')
        formData['company_id'] = localStorage.getItem('company_id')

        await axios.post(`/api/inventories`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })

            setIsLoading(false)
            navigate('/inventory-sheets')
        }).catch(({ response }) => {
            Swal.fire({
                text: response.data.message,
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
            setIsLoading(false)
        })
    }

    /* methods */
    const handleChangePeriod = (e) => {
        setPeriod(e.target.value)
        if (e.target.value == 'day') setEnd(start)
        if (e.target.value == 'week') setEnd(start.add(7, "day"))
        if (e.target.value == 'month') setEnd(start.add(1, "month"))
        if (e.target.value == 'quarter') setEnd(start.add(3, "month"))
        if (e.target.value == 'year') setEnd(start.add(1, "year"))
    }

    const handleStart = (newValue) => {
        setStart(newValue)
        if (period == 'day') setEnd(newValue)
        if (period == 'week') setEnd(newValue.add(7, "day"))
        if (period == 'month') setEnd(newValue.add(1, "month"))
        if (period == 'quarter') setEnd(newValue.add(3, "month"))
        if (period == 'year') setEnd(newValue.add(1, "year"))
    }

    const handleEnd = (newValue) => {
        setEnd(newValue)
    }

    const handleCountDate = (newValue) => {
        setCountDate(newValue)
    }

    const handleAddItem = () => {

        let counter = 0
        let msgItem = ''
        let msgQty = ''
        let msgBoth = t('please_item_qty')
        items.forEach(element => {
            if (element.item_no == '') {
                counter += 1
                msgItem = t('please_select_item')
            }
            if (element.qty == '') {
                counter += 1
                msgQty = t('please_add_qty')
            }
        })

        if (counter > 0) {
            if (msgItem !== '' && msgQty === '') {
                Swal.fire({
                    icon: "warning",
                    text: msgItem,
                    customClass: 'warning2',
                    showCloseButton: true,
                    iconColor: '#FFCA28'
                })
            }
            if (msgQty !== '' && msgItem === '') {
                Swal.fire({
                    icon: "warning",
                    text: msgQty,
                    customClass: 'warning2',
                    showCloseButton: true,
                    iconColor: '#FFCA28'
                })
            }
            if (msgQty !== '' && msgItem !== '') {
                Swal.fire({
                    icon: "warning",
                    text: msgBoth,
                    customClass: 'warning2',
                    showCloseButton: true,
                    iconColor: '#FFCA28'
                })
            }


            return
        }

        const item = {
            "id": count,
            "item_id": '',
            "item_no": '',
            "description": '',
            "unit_price": '',
            "qty": '',
            "unit_of_measure_id": ''
        }

        setItems((prev) => [...prev, item])
        setCount(prev => prev + 1)
    }

    const handleSelectItem = (value, id) => {
        const itemsCopy = [...items]
        const item = itemsCopy.find(item => item.id === id)

        item.item_id = value.id
        item.item_no = value.item_no
        item.description = value.description
        item.unit_price = value.unit_price
        item.unit_of_measure_id = value.unit_of_measure_id

        setItems(itemsCopy)
    }

    const calculateTotalQuantity = () => {
        let total = 0
        items.forEach(element => {
            if (element.qty !== '') total += parseInt(element.qty)
        })

        return total
    }

    const handleQty = (value, id) => {
        const itemsCopy = [...items]
        const item = itemsCopy.find(item => item.id === id)

        item.qty = value

        setItems(itemsCopy)
    }

    /* const handleSelectUoM = (value, id) => {
        const itemsCopy = [...items]
        const item = itemsCopy.find(item => item.id === id)

        item.unit_of_measure_id = value.unit_of_measure_id

        setItems(itemsCopy)
        console.log(itemsCopy);
    } */

    const handleCloseModal = async () => {

        if (items[0]?.qty > 0 || Boolean(items[0]?.item_no) == true) {

            const isConfirm = await Swal.fire({
                title: t('close_modal'),
                text: t('lose_data'),
                icon: 'warning',
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000',
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t('yes'),
                cancelButtonText: t('cancel')
            }).then((result) => {
                return result.isConfirmed
            })

            if (!isConfirm) {
                return
            }

            navigate('/inventory-sheets')
        } else {
            navigate('/inventory-sheets')
        }

    }


    const columns = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 1,
            renderCell: (params) => <ItemNo params={params} listOfItems={listOfItems} handleSelectItem={handleSelectItem} setListOfItems={setListOfItems} />
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.5,
            renderCell: (params) => <Description params={params} t={t} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 1,
            renderCell: (params) => <UnitPrice params={params} t={t} />
        },
        {
            field: 'qty',
            headerName: t('qty'),
            flex: 1,
            renderCell: (params) => <Qty params={params} t={t} listOfItems={listOfItems} handleQty={handleQty} />
        },
        {
            field: 'unit_of_measure_id',
            headerName: t('unit_of_measure'),
            flex: 1,
            renderCell: (params) => <UoM params={params} listOfUoM={listOfUoM} />
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => <Actions params={params} items={items} setItems={setItems} setListOfItems={setListOfItems} />
        }
    ]

    return (
        <>
            {isLoading ? <Loading position='fixed' /> : ''}
            <Box sx={{ background: '#F5F5FD', minHeight: '100vh' }}>
                <div className='flex justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#5b5ea6] text-[35px] mr-4'>•</span> {t('inventory_count_sheet')} | {site?.code} - {site?.name}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <button onClick={handleCloseModal}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>

                <div className='p-5 flex justify-between gap-3 flex-col lg:flex-row'>
                    <div className='bg-white p-6 pt-0 rounded-md w-full h-fit lg:w-2/5'>
                        <div className='pb-11 pt-1'>
                            <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('general_information')}</p>
                        </div>
                        <div className='w-full flex'>
                            <Period t={t} period={period} handleChangePeriod={handleChangePeriod} />
                        </div>
                        <div className='w-full flex'>
                            <div className='lg:flex gap-2 w-full'>
                                <DateRange t={t} start={start} end={end} handleStart={handleStart} handleEnd={handleEnd} status='start' />
                                {period != 'day' ? (
                                    <DateRange t={t} start={start} end={end} handleStart={handleStart} handleEnd={handleEnd} status='end' />
                                ) : null}
                            </div>
                        </div>
                        <div className='w-full flex'>
                            <InventoryDocumentNoField t={t} documentNo={documentNo} setDocumentNo={setDocumentNo} />
                        </div>
                        <div className='w-full flex'>
                            <CountDate t={t} countDate={countDate} handleCountDate={handleCountDate} />
                        </div>
                    </div>
                    <div className='w-full lg:w-3/5 h-fit'>
                        <div className='bg-white pt-0 rounded-md w-full mb-3'>
                            <div className='pt-1 pl-3'>
                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('items')}</p>
                            </div>
                            <div className='flex justify-end p-5 pt-0 border-b'>
                                <Tooltip disableInteractive title={t('add_item')} placement='bottom'>
                                    <div>
                                        <AddButton onClick={handleAddItem}><i className="fa-solid fa-plus"></i></AddButton>
                                    </div>
                                </Tooltip>
                            </div>
                            <div className='border-b'>
                                {
                                    items.length > 0 ?
                                        <div className='flex justify-end w-full p-2'>
                                            <button className='px-2 py-1 text-white bg-red-600 font-bold rounded-md mb-1 roboto text-xs uppercase' onClick={e => setItems([])}>{t('clear_all')}</button>
                                        </div>
                                        :
                                        null
                                }
                                <Items items={items} columns={columns} />
                            </div>
                            <div className='flex justify-end w-full p-5'>
                                <div className='w-full lg:w-1/3'>
                                    <div className='flex justify-between text-[#333333] text-[15px]'>
                                        <p>{t('totalQty')}</p>
                                        <p className='font-[500]'>{calculateTotalQuantity()}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full flex justify-end'>
                            <button type="button" disabled={disabled} onClick={createCountSheetData} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#DBA34B] hover:bg-[#CF7300] disabled:bg-[#B8B7BC]'>
                                <span className='pr-2'><i className="fa-solid fa-plus"></i></span> {t('create')}
                            </button>
                        </div>
                    </div>
                </div>
            </Box >
        </>
    )
}

export default EnterCounts


const ItemNo = ({ params, listOfItems, handleSelectItem, setListOfItems }) => {

    const [item, setItem] = useState(' ')
    const [exists, setExists] = useState(true)
    const { t } = useTranslation()

    const handleChange = (e) => {
        setItem(prev => {
            if (prev == ' ') {
                return prev
            }
            if (prev) prev.disabled = false
            return prev
        })
        setItem(e.target.value)
        handleSelectItem(e.target.value, params.row.id)
        setListOfItems(prev => {
            const item = prev.find(i => i.id == e.target.value.id)
            item.disabled = true
            return prev
        })
    }

    useEffect(() => {
        if (listOfItems.length >= 0) {
            if(params.row.item_id !== '') {
                const target = listOfItems?.find(i => i.id === params.row.item_id)

                if(target) {
                    target.disabled = true
                    setItem(target)

                    setExists(true)
                } else {
                    setItem(' ')
                    setExists(false)
                }
            } else {
                setExists(true)
            }
        }
    }, [listOfItems])

    return (
        <>
            {exists ? (
                <FormControl variant="standard" sx={{ width: '100%' }}>
                    <Select defaultValue=' ' value={item} onChange={e => handleChange(e)}>
                        <MenuItem value=' ' disabled sx={{ fontStyle: 'italic', display: 'none' }}>{t('select_item')}</MenuItem>
                        {
                            listOfItems?.map((i, index) => (
                                <MenuItem value={i} key={`itemslist-${i.id}`} disabled={i.disabled}>{i.item_no}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            ) : (
                <TextField
                    type="text"
                    variant='standard'
                    disabled
                    className='w-full mb-5 px-0 pt-0'
                    value={params.row.item_no}
                />
            )}
        </>
    )
}

const UoM = ({ params, listOfUoM }) => {

    const [uom, setUom] = useState(params.value)
    const [item, setItem] = useState([])

    useEffect(() => {
        setUom(params.value)
    }, [params.row.unit_of_measure_id])

    useEffect(() => {
        if (uom) {
            const item = listOfUoM.find(i => i.id == uom)
            setItem(item)
        }
    }, [uom, listOfUoM])

    return (

        <TextField type="text" variant='standard' disabled className='w-full mb-5 px-0 pt-0' value={item?.code || []} />
    )
}

const Qty = ({ params, t, listOfItems, handleQty }) => {
    const [qty, setQty] = useState(params.value || '')
    const [disabled, setDisabled] = useState(false)

    const handleQuantity = (e) => {
        const regex = /^(-?\d{0,5})$/i
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty('')
            }
            else {
                setQty(e.target.value)
                handleQty(e.target.value, params.row.id)
            }
        }
    }

    useEffect(() => {
        if (listOfItems.length >= 0) {
            if(params.row.item_id !== '') {
                const target = listOfItems?.find(i => i.id === params.row.item_id)

                if(target) {
                    setDisabled(false)
                } else {
                    setDisabled(true)
                }
            } else {
                setDisabled(false)
            }
        }
    }, [listOfItems])

    return (
        <TextField
            disabled={params.row.item_id === '' || disabled}
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={qty}
            onChange={(e) => { handleQuantity(e) }}
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const Description = ({ params }) => {
    return (
        <TextField type="text" variant='standard' className='w-full mb-5 px-0 pt-0' value={params.value} disabled />
    )
}

const UnitPrice = ({ params }) => {
    return (
        <TextField type="text" variant='standard' className='w-full mb-5 px-0 pt-0' value={params.value} disabled />
    )
}

const Actions = ({ params, items, setItems, setListOfItems }) => {

    const { t } = useTranslation()

    const deleteShipment = async () => {
        const i = items.filter(i => i.id !== params.row.id)
        setItems(i)
        setListOfItems(prev => {
            const item = prev.find(i => i.id == params.row.item_id)
            if (Boolean(item)) item.disabled = false
            return prev
        })
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={deleteShipment}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
