import React, { useState, useEffect } from 'react'

import { Tooltip } from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import InputLabel from '@mui/material/InputLabel'
import { useTranslation } from "react-i18next"
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import dayjs from 'dayjs'

import AppLayout from '../../../../components/Layouts/AppLayout'
import Loading from '../../../../components/Loading'
import { useStateContext } from '../../../../context/ContextProvider'
import { useAuth } from '../../../../hooks/auth'
import { isValid } from '../../../../helpers/helper'
import NavigationProductionOrders from '../../../../components/production/NavigationProductionOrders'
import AddButton from '../../../../components/AddButton'
import axios from '../../../../lib/axios'

import ProductionOrderBoardTable from '../../../../components/production/ProductionOrderBoardTable'
import { isArray, isNull, isObject } from 'lodash'
import CreateProductionOrder from '../../Create/CreateProductionOrder'

const ProductionOrderBoard = () => {
    const { user } = useAuth({ middleware: 'guest ' })

    const { t } = useTranslation()
    const { company_id, choosesite, config, setChoosesite, pusher } = useStateContext()

    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [random, setRandom] = useState(null)

    const [productionOrderStatuses, setProductionOrderStatuses] = useState([])
    const [productionOrderStatusesFuture, setProductionOrderStatusesFuture] = useState([])
    const [productionOrderDates, setProductionOrderDates] = useState({
        starts: [],
        dues: []
    })
    const [productionOrderDatesFuture, setProductionOrderDatesFuture] = useState({
        starts: [],
        dues: []
    })

    /* Orders */
    const [orders, setOrders] = useState([])
    const [ordersFuture, setOrdersFuture] = useState([])

    /* Today's Filters */
    const [searchStatus, setSearchStatus] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.production_order_board_status || ''
        } else {
            return ''
        }
    })
    const [searchStartDate, setSearchStartDate] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.production_order_board_start_date || ''
        } else {
            return ''
        }
    })
    const [searchDueDate, setSearchDueDate] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.production_order_board_due_date || ''
        } else {
            return ''
        }
    })

    /* Future Filters */
    const [searchStatusFuture, setSearchStatusFuture] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.future_production_order_board_status || ''
        } else {
            return ''
        }
    })
    const [searchStartDateFuture, setSearchStartDateFuture] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.future_production_order_board_start_date || ''
        } else {
            return ''
        }
    })
    const [searchDueDateFuture, setSearchDueDateFuture] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.future_production_order_board_due_date || ''
        } else {
            return ''
        }
    })

    // Misc.

    useEffect(() => {
        // Unsubscribe on unmount
        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-created-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${localStorage.getItem('site')}`)
        })
    }, [])

    useEffect(() => {
        if(isValid(choosesite)) {
            const channelProductionOrderDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${choosesite}`)
            const channelProductionOrderCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-created-site-${choosesite}`)
            const channelProductionOrderUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${choosesite}`)

            channelProductionOrderDelete.bind(`${localStorage.getItem('client_id')}-productionorder-deleted-event-site-${choosesite}`, data => {
                setRandom(Math.random())
            })

            channelProductionOrderCreate.bind(`${localStorage.getItem('client_id')}-productionorder-created-event-site-${choosesite}`, data => {
                setRandom(Math.random())
            })

            channelProductionOrderUpdate.bind(`${localStorage.getItem('client_id')}-productionorder-updated-event-site-${choosesite}`, data => {
                setRandom(Math.random())
            })
        }

        return (() => {
            if(isValid(choosesite)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-created-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-status-changed-site-${choosesite}`)
            }
        })
    }, [choosesite])

    useEffect(() => {
        if (isValid(choosesite)) {
            getOrders(choosesite)
        }
    }, [choosesite, searchStatus, searchStartDate, searchDueDate, random])

    useEffect(() => {
        if (isValid(choosesite)) {
            getOrdersFuture(choosesite)
        }
    }, [choosesite, searchStatusFuture, searchStartDateFuture, searchDueDateFuture, random])

    const getOrders = async (siteId = null) => {
        setIsLoading(true)
        await axios.get(`/api/list-orders?order_type=production&site_id=${isNull(siteId) ? localStorage.get('site') : siteId}&status_id=${searchStatus}&start_date=${searchStartDate}&due_date=${searchDueDate}&pending=true`, config)
            .then(res => {
                const orders = res.data?.data.filter((i) => isObject(i.status) ? i.status?.id < 3 : i.status < 3);
                getProductionOrderStatuses(orders)
                getProductionOrderDates(orders)
                setOrders(orders.filter((item) => new Date(dayjs(item.start_date)) <= new Date(dayjs())))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getOrdersFuture = async (siteId = null) => {
        setIsLoading(true)
        await axios.get(`/api/list-orders?order_type=production&site_id=${isNull(siteId) ? localStorage.get('site') : siteId}&status_id=${searchStatusFuture}&start_date=${searchStartDateFuture}&due_date=${searchDueDateFuture}&pending=true`, config)
            .then(res => {
                const orders = res.data?.data.filter((i) => isObject(i.status) ? i.status?.id < 3 : i.status < 3)
                getProductionOrderStatusesFuture(orders)
                getProductionOrderDatesFuture(orders)
                setOrdersFuture(orders.filter((item) => new Date(dayjs(item.start_date)) > new Date(dayjs())))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getProductionOrderStatuses = (data = []) => {
        let pastPresentProductionOrders = data.filter((item) => new Date(dayjs(item.start_date)) <= new Date(dayjs()));

        let statuses = [];

        pastPresentProductionOrders.forEach(order => {
            let newStatus = {
                id: order.status?.id,
                name: order.status?.name,
            };

            let newStatusExists = statuses.some(function (status) {
                return status.id === newStatus.id
            });

            if (!newStatusExists) {
                statuses.push(newStatus);
            }
        });

        statuses.sort((a, b) => {
            return a.id - b.id
        });

        setProductionOrderStatuses(statuses);
    }

    const getProductionOrderStatusesFuture = (data = []) => {
        let futureProductionOrders = data.filter((item) => new Date(dayjs(item.start_date)) > new Date(dayjs()));

        let futureStatuses = [];

        futureProductionOrders.forEach(order => {
            let newFutureStatus = {
                id: order.status?.id,
                name: order.status?.name,
            };

            let newFutureStatusExists = futureStatuses.some(function (status) {
                return status.id === newFutureStatus.id
            });

            if (!newFutureStatusExists) {
                futureStatuses.push(newFutureStatus);
            }
        });

        futureStatuses.sort((a, b) => {
            return a.id - b.id
        });

        setProductionOrderStatusesFuture(futureStatuses);
    }

    const getProductionOrderDates = (data = []) => {
        let pastPresentProductionOrders = data.filter((item) => new Date(dayjs(item.start_date)) <= new Date(dayjs()));

        let startDates = [];
        let dueDates = [];

        pastPresentProductionOrders.forEach(order => {
            const newStartDate = order.start_date;
            const newDueDate = order.due_date;

            const newStartDateExists = startDates.includes(newStartDate);
            const newDueDateExists = dueDates.includes(newDueDate)

            if (!newStartDateExists) {
                startDates.push(newStartDate)
            }

            if (!newDueDateExists) {
                dueDates.push(newDueDate)
            }
        });

        startDates.sort();
        dueDates.sort();

        setProductionOrderDates({
            starts: startDates,
            dues: dueDates
        });
    }

    const getProductionOrderDatesFuture = (data = []) => {
        let futureProductionOrders = data.filter((item) => new Date(dayjs(item.start_date)) > new Date(dayjs()));

        let futureStartDates = [];
        let futureDueDates = [];

        futureProductionOrders.forEach(order => {
            const newStartDate = order.start_date;
            const newDueDate = order.due_date;

            const newStartDateExists = futureStartDates.includes(newStartDate);
            const newDueDateExists = futureDueDates.includes(newDueDate)

            if (!newStartDateExists) {
                futureStartDates.push(newStartDate)
            }

            if (!newDueDateExists) {
                futureDueDates.push(newDueDate)
            }
        });

        futureStartDates.sort();
        futureDueDates.sort();

        setProductionOrderDatesFuture({
            starts: futureStartDates,
            dues: futureDueDates
        });
    }

    const handleOpen = () => {
        setOpen(!open)
    }

    const handleClearSearchStatus = () => {
        setSearchStatus('')

        getProductionOrderDates(orders)
    }

    const handleClearSearchStatusFuture = () => {
        setSearchStatusFuture('')

        getProductionOrderDatesFuture(ordersFuture)
    }

    const handleClearSearchStartDate = () => {
        setSearchStartDate('')

        getProductionOrderDates(orders)
    }

    const handleClearSearchStartDateFuture = () => {
        setSearchStartDateFuture('')

        getProductionOrderDatesFuture(ordersFuture)
    }

    const handleClearSearchDueDate = () => {
        setSearchDueDate('')

        getProductionOrderDates(orders)
    }

    const handleClearSearchDueDateFuture = () => {
        setSearchDueDateFuture('')

        getProductionOrderDatesFuture(ordersFuture)
    }

    const createFilterArray = (status, startDate, dueDate, statusFuture, startDateFuture, dueDateFuture) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].production_order_board_status = status
        filters[0].production_order_board_start_date = startDate
        filters[0].production_order_board_due_date = dueDate

        filters[0].future_production_order_board_status = statusFuture
        filters[0].future_production_order_board_start_date = startDateFuture
        filters[0].future_production_order_board_due_date = dueDateFuture

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    useEffect(() => {
        createFilterArray(searchStatus, searchStartDate, searchDueDate, searchStatusFuture, searchStartDateFuture, searchDueDateFuture)
    }, [searchStatus, searchStartDate, searchDueDate, searchStatusFuture, searchStartDateFuture, searchDueDateFuture])

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout showFooter={!open}>
                <NavigationProductionOrders route='production-order-board' type='list' />

                <div className='p-6 flex w-full items-start justify-between gap-5 max-[1300px]:flex-col'>
                    <div className='w-1/2 max-[1300px]:w-full bg-white rounded-md'>
                        <div className='p-5 flex justify-between items-center border-b border-[#EEEFF2]'>
                            <div className='flex gap-2 items-center'>
                                <span style={{ fontSize: '16px', color: '#1ab800' }} className="flex items-center justify-center">
                                    <i className="fa-solid fa-arrow-up-right-dots"></i>
                                </span>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('todays_production_orders')}</p>
                            </div>
                            <div className='flex gap-5 items-center'>
                                {['master_admin', 'office_manager'].includes(user?.role) ? (
                                    <Tooltip disableInteractive title={t('create_new_production_order')} placement='bottom'>
                                        <div>
                                            <AddButton onClick={handleOpen}><i className="fa-solid fa-plus text-[#333333]"></i></AddButton>
                                        </div>
                                    </Tooltip>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className='p-5 border-b border-[#EEEFF2]'>
                            <div className='flex justify-between items-end w-full gap-3'>
                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="status-search-label">{t('search_by_status')}</InputLabel>
                                        <Select
                                            value={searchStatus}
                                            onChange={e => setSearchStatus(e.target.value)}
                                            sx={{
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: 'none !important'
                                                },
                                                background: '#F5F5F5',
                                                borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": {
                                                    display: searchStatus ? 'none !important' : ''
                                                },
                                                "&.Mui-focused .MuiIconButton-root": {
                                                    color: 'rgba(0,0,0,.42)'
                                                }
                                            }}
                                            endAdornment={searchStatus ? (<IconButton sx={{ visibility: searchStatus ? "visible" : "hidden", padding: '0' }} onClick={handleClearSearchStatus}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                productionOrderStatuses.length < 1 ? (
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                ) :
                                                    productionOrderStatuses.map((status, index) => (
                                                        <MenuItem key={`status-${status.id}-${index}`} value={status.id}>{status.name}</MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="start-date-search-label">{t('search_by_start_date')}</InputLabel>
                                        <Select
                                            value={searchStartDate}
                                            onChange={e => setSearchStartDate(e.target.value)}
                                            sx={{
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: 'none !important'
                                                },
                                                background: '#F5F5F5',
                                                borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": {
                                                    display: searchStartDate ? 'none !important' : ''
                                                },
                                                "&.Mui-focused .MuiIconButton-root": {
                                                    color: 'rgba(0,0,0,.42)'
                                                }
                                            }}
                                            endAdornment={searchStartDate ? (<IconButton sx={{ visibility: searchStartDate ? "visible" : "hidden", padding: '0' }} onClick={handleClearSearchStartDate}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                productionOrderDates.starts.length < 1 ? (
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                ) :
                                                    productionOrderDates.starts.map((date, index) => (
                                                        <MenuItem key={`startdate-${date}-${index}`} value={date}>{date}</MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="due-date-search-label">{t('search_by_due_date')}</InputLabel>
                                        <Select
                                            value={searchDueDate}
                                            onChange={e => setSearchDueDate(e.target.value)}
                                            sx={{
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: 'none !important'
                                                },
                                                background: '#F5F5F5',
                                                borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": {
                                                    display: searchDueDate ? 'none !important' : ''
                                                },
                                                "&.Mui-focused .MuiIconButton-root": {
                                                    color: 'rgba(0,0,0,.42)'
                                                }
                                            }}
                                            endAdornment={searchDueDate ? (<IconButton sx={{ visibility: searchDueDate ? "visible" : "hidden", padding: '0' }} onClick={handleClearSearchDueDate}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                productionOrderDates.dues.length < 1 ? (
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                ) :
                                                    productionOrderDates.dues.map((date, index) => (
                                                        <MenuItem key={`duedate-${date}-${index}`} value={date}>{date}</MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>

                        <div>
                            <ProductionOrderBoardTable data={orders} user={user} setIsLoading={setIsLoading}/>
                        </div>
                    </div>

                    <div className='w-1/2 max-[1300px]:w-full bg-white rounded-md'>
                        <div className='p-5 flex justify-between items-center border-b border-[#EEEFF2]'>
                            <div className='flex gap-2 items-center'>
                                <span style={{ fontSize: '16px', color: '#1ab800' }} className="flex items-center justify-center">
                                    <i className="fa-solid fa-arrow-up-right-dots"></i>
                                </span>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('future_production_orders')}</p>
                            </div>
                            <div className='flex gap-5 items-center'>
                                {['master_admin', 'office_manager'].includes(user?.role) ? (
                                    <Tooltip disableInteractive title={t('create_new_production_order')} placement='bottom'>
                                        <div>
                                            <AddButton onClick={handleOpen}><i className="fa-solid fa-plus text-[#333333]"></i></AddButton>
                                        </div>
                                    </Tooltip>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className='p-5 border-b border-[#EEEFF2]'>
                            <div className='flex justify-between items-end w-full gap-3'>
                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="future-status-search-label">{t('search_by_status')}</InputLabel>
                                        <Select
                                            value={searchStatusFuture}
                                            onChange={e => setSearchStatusFuture(e.target.value)}
                                            label="Search role"
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: searchStatusFuture ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={searchStatusFuture ? (<IconButton sx={{ visibility: searchStatusFuture ? "visible" : "hidden", padding: '0' }} onClick={handleClearSearchStatusFuture}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                productionOrderStatusesFuture.length < 1 ?
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                    :
                                                    productionOrderStatusesFuture.map((status, index) => (
                                                        <MenuItem key={`status-${status.id}-${index}-future`} value={status.id}>{status.name}</MenuItem>
                                                    ))}
                                        </Select>

                                    </FormControl>
                                </div>

                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="future-start-date-search-label">{t('search_by_start_date')}</InputLabel>
                                        <Select
                                            value={searchStartDateFuture}
                                            onChange={e => setSearchStartDateFuture(e.target.value)}
                                            label="Search Past Or Present Date"
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: searchStartDateFuture ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={searchStartDateFuture ? (<IconButton sx={{ visibility: searchStartDateFuture ? "visible" : "hidden", padding: '0' }} onClick={handleClearSearchStartDateFuture}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                productionOrderDatesFuture.starts.length < 1 ? (
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                ) :
                                                    productionOrderDatesFuture.starts.map((date,index) => (
                                                        <MenuItem key={`startdate-${date}-${index}-future`} value={date}>{date}</MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="future-due-date-search-label">{t('search_by_due_date')}</InputLabel>
                                        <Select
                                            value={searchDueDateFuture}
                                            onChange={e => setSearchDueDateFuture(e.target.value)}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: searchDueDateFuture ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={searchDueDateFuture ? (<IconButton sx={{ visibility: searchDueDateFuture ? "visible" : "hidden", padding: '0' }} onClick={handleClearSearchDueDateFuture}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                productionOrderDatesFuture.dues.length < 1 ? (
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                ) :
                                                    productionOrderDatesFuture.dues.map((date, index) => (
                                                        <MenuItem key={`duedate-${date}-${index}-future`} value={date}>{date}</MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>

                        <div>
                            <ProductionOrderBoardTable data={ordersFuture} user={user} setIsLoading={setIsLoading}/>
                        </div>
                    </div>
                </div>

                <CreateProductionOrder open={open} handleOpen={handleOpen} setIsLoading={setIsLoading} />
            </AppLayout>
        </>
    )
}

export default ProductionOrderBoard
