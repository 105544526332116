import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import AppLayout from '../../components/Layouts/AppLayout'
import { useStateContext } from '../../context/ContextProvider'
import TableSharedCustom from '../../components/TableSharedCustom'
import { useAuth } from '../../hooks/auth'
import axios from '../../lib/axios'
import Loading from '../../components/Loading'

const Companies = () => {

    const { t } = useTranslation()
    const { user } = useAuth({ middleware: 'guest' })
    const { companies, config } = useStateContext()
    const [isLoading, setIsLoading] = useState(false)
    const [model, setModel] = useState({
        address: true,
        city: true,
        name: true,
        state: true,
        zip: true
    })

    useEffect(() => {
        getTableView()
    }, [])

    const getTableView = async() => {
        setIsLoading(true)
        await axios.get(`/api/get-table-view?view_name=companies_table&user_id=${user?.id}`, config)
            .then(res => {
                const model = res.data
                if(Object.keys(model?.success?.data).length > 0) {
                    setModel(model.success.data)
                }
                setIsLoading(false)
            })
    }

    const columns = [
        {
            field: 'name',
            headerName: t('name'),
            flex: 1
        },
        {
            field: 'address',
            headerName: t('address'),
            flex: 1
        },
        {
            field: 'city',
            headerName: t('city'),
            flex: 1
        },
        {
            field: 'state',
            headerName: t('state'),
            flex: 1
        },
        {
            field: 'zip',
            headerName: t('zip'),
            flex: 1
        }
    ]


    return (
        <>
        {isLoading ? <Loading /> : null}
        <AppLayout>
            <div className='p-5 pt-0'>
                <div>
                    <TableSharedCustom items={companies} columns={columns} columnVisibilityModel={model} setModel={setModel} view='companies' />
                </div>
            </div>
        </AppLayout>
        </>
    )

}

export default Companies
